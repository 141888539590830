import React from 'react';
import styles from './Footer.module.css';

const Footer = (props) =>{
    return (
        <div className={styles.Footer}>
            
        </div>
    )
}


export default Footer;