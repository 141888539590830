import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as ActionTypes from '../../../store/actions/index';
import classes from './LineupSelector.module.css';
import Button from '../../../components/UI/Button/Button';
import Modal from '../../../components/UI/Modal/Modal';
import PlayerForm from '../../Player/PlayerForm/PlayerForm';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
//import { ComponentSource } from 'ag-grid-community/dist/lib/components/framework/userComponentFactory';

// create your cellRenderer as a React component
class PositionCellRenderer extends React.Component {

    // did you know that React passes props to your component constructor??
    constructor(props) {
        super(props);
        // from here you can access any of the props!
        //console.log('The value is ',  props.data);
        // we can even call grid API functions, if that was useful
        //props.api.selectAll();
        this.onChangeHandler = this.onChangeHandler.bind(this);
    }
    onChangeHandler = (event) =>{
        ////console.log(event);
        //console.log('props', this.props);
        //this.props.rowIndex
        //this.props.data
        //console.log('PositionCellRenderer->position select changed' , event.target.value);
        //console.log('handlePositionChange', this.props.data.handlePositionChange);
        this.props.data.handlePositionChange(this.props.rowIndex, this.props.data, event.target.value);
        
    }


    render() {
        let optionsJSX = [];
        for(let i=0;i<this.props.data.positions.length; ++i){
            const pos = this.props.data.positions[i];
            let optionJSX = <option value={pos}>{pos}</option>
            if(this.props.data && this.props.data.currentPosition ){
                if(this.props.data.currentPosition === pos){
                    optionJSX = <option selected value={pos}>{pos}</option>
                }
            }
            optionsJSX.push(optionJSX);
        }

        

    // put in render logic
    //
        return <select onChange={this.onChangeHandler}>
            <option value="null">Select</option>
        {optionsJSX}
      </select>;
    }
}


class LineupSelector extends Component{



    constructor(props){
        super(props);
        this.state = {
            roster : {
                columnDefs: [
                    {
                        headerName: "ID", field: "id", sortable: false, filter: false, hide: true
                    },
                    {
                        headerName: "#", field: "number", sortable: true, filter: true, width:100
                    },
                    {
                    headerName: "First Name", field: "firstName", sortable: true, filter: true
                    }, 
                    {
                        headerName: "Last Name", field: "lastName", sortable: true, filter: true
                    }, 
                    {
                        headerName: "Position", field: "position", sortable: true, filter: true,
                        cellRendererFramework: PositionCellRenderer
                    }
                ],
                rowData: []
            },
            lineup : {
                columnDefs: [
                    {
                        headerName: "ID", field: "id", sortable: false, filter: false, hide: true
                    },
                    {
                        headerName: "#", field: "order", sortable: true, filter: true, width:100
                    },
                    {
                        headerName: "First Name", field: "firstName", sortable: true, filter: true
                    }, 
                    {
                        headerName: "Last Name", field: "lastName", sortable: true, filter: true
                    }
                    , 
                    {
                        headerName: "Position", field: "currentPosition", sortable: true, filter: true
                    }
                ],
                rowData: []
            },
            showSave: false,
            showAddPlayer: false,
            //
            //baseball: ['P', 'C', '1B', '2B', '3B', 'SS', 'LF', 'CF', 'RF', 'EH', 'DH']
            //DH: they bat in the order like a normal field player.  There is only one
            //softball: ['P', 'C', '1B', '2B', '3B', 'SS', 'LF', 'CF', 'RF', 'EH', 'DP', 'FLEX']
            //EH in softball can have many EH - EH1, EH2, EH3
            //DP in softball only one.   Treated like a normal player
            //FLEX is #10 and doesn't bat.  BUT the Flex can bat for the DP once in a game.  There can only be one Flex
            availablePositions : [],//['P', 'C', '1B', '2B', '3B', 'SS', 'LF', 'CF', 'RF', 'EH', 'DH', 'DP'],
            homeAvailablePositions : [],//['P', 'C', '1B', '2B', '3B', 'SS', 'LF', 'CF', 'RF', 'EH', 'DH', 'DP'],
            awayAvailablePositions : [],//['P', 'C', '1B', '2B', '3B', 'SS', 'LF', 'CF', 'RF', 'EH', 'DH', 'DP'],
            homeTeamFlex : null,
            awayTeamFlex : null
            
        }
        
    }


    componentDidMount(){
        //////console.log('LineupSelector->componentDidMount', this.props.homeTeamLineup);
        if(this.props.homeTeamLineup && this.props.awayTeamLineup){
            let availablePositions = ['P', 'C', '1B', '2B', '3B', 'SS', 'LF', 'CF', 'RF', 'EH1','EH2','EH3','EH4', 'DP', 'FLEX'];
            let homeAvailablePositions = ['P', 'C', '1B', '2B', '3B', 'SS', 'LF', 'CF', 'RF', 'EH1','EH2','EH3','EH4', 'DP', 'FLEX'];
            let awayAvailablePositions = ['P', 'C', '1B', '2B', '3B', 'SS', 'LF', 'CF', 'RF', 'EH1','EH2','EH3','EH4', 'DP', 'FLEX'];
            if(this.props.game.pitchType === 'Baseball'){
                availablePositions = ['P', 'C', '1B', '2B', '3B', 'SS', 'LF', 'CF', 'RF', 'EH1','EH2','EH3','EH4', 'DH'];
                homeAvailablePositions= ['P', 'C', '1B', '2B', '3B', 'SS', 'LF', 'CF', 'RF', 'EH1','EH2','EH3','EH4', 'DH'];
                awayAvailablePositions= ['P', 'C', '1B', '2B', '3B', 'SS', 'LF', 'CF', 'RF', 'EH1','EH2','EH3','EH4', 'DH'];
            }
            this.onShowSave(this.props.homeTeamLineup,this.props.awayTeamLineup);
            let homePlayerArray = this.generateHomeRosterArray();
            let homeLineupArray = this.generateHomeLineupArray();
            let awayPlayerArray = this.generateAwayRosterArray();
            let awayLineupArray = this.generateAwayLineupArray();
         
            this.setState({
                availablePositions: availablePositions,
                homeAvailablePositions: homeAvailablePositions,
                awayAvailablePositions: awayAvailablePositions,
                homeTeamPlayers: homePlayerArray,
                homeTeamLineup : homeLineupArray,
                awayTeamPlayers: awayPlayerArray,
                awayTeamLineup : awayLineupArray,
               
            });
        }
    }

    componentDidUpdate(prevProps, prevState){
        //console.log('LineupSelector->componentDidUpdate');
        if(this.props.homeTeamLineup && this.props.awayTeamLineup){
            
           if(prevProps.team !== this.props.team){
                if(this.props.team === "home"){
                    //if(this.state.showSave && this.props.homeTeamLineup.length < 4){
                            this.onShowSave(this.props.homeTeamLineup,this.props.awayTeamLineup);
                    //}
                }
                else{
                        //if(this.state.showSave && this.props.awayTeamLineup.length < 4){
                            this.onShowSave(this.props.homeTeamLineup,this.props.awayTeamLineup);
                        //}
                }
            }
            if(prevProps.homeTeamLineup !== this.props.homeTeamLineup ||
                    prevProps.awayTeamLineup !== this.props.awayTeamLineup ||
                    prevProps.homeTeamPlayers !== this.props.homeTeamPlayers ||
                    prevProps.awayTeamPlayers !== this.props.awayTeamPlayers ||
                    prevState.homeAvailablePositions.length !== this.state.homeAvailablePositions.length ||
                    prevState.awayAvailablePositions.length !== this.state.awayAvailablePositions.length ||
                    prevProps.homeTeamFlex !== this.props.homeTeamFlex ||
                    prevProps.awayTeamFlex !== this.props.awayTeamFlex
                    
                    ){
                    let homePlayerArray = this.generateHomeRosterArray();
                    let homeLineupArray = this.generateHomeLineupArray();
                    let awayPlayerArray = this.generateAwayRosterArray();
                    let awayLineupArray = this.generateAwayLineupArray();
                    
                    this.setState({
                        homeTeamPlayers: homePlayerArray,
                        homeTeamLineup : homeLineupArray,
                        awayTeamPlayers: awayPlayerArray,
                        awayTeamLineup : awayLineupArray
                        
                    })
            }
        }
        
    }
   

    generateHomeAvailablePositions = () =>{
        let positions = [...this.state.availablePositions];
        let updatedHomeLineup = [...this.props.homeTeamLineup];
        for(let i=0;i<updatedHomeLineup.length; ++i){
            const player = updatedHomeLineup[i];
            if(player.currentPosition){
                const pos = player.currentPosition;
                const index = positions.findIndex(x=>x === pos);
                if(index >= 0){
                    positions.splice(index,1);
                }

            }
        }
        this.setState({
            homeAvailablePositions: positions
        });
        return positions;
    }


    generateAwayAvailablePositions = () =>{
        let positions = [...this.state.availablePositions];
        let updatedAwayLineup = [...this.props.awayTeamLineup];
        for(let i=0;i<updatedAwayLineup.length; ++i){
            const player = updatedAwayLineup[i];
            if(player.currentPosition){
                const pos = player.currentPosition;
                const index = positions.findIndex(x=>x === pos);
                if(index >= 0){
                    positions.splice(index,1);
                }

            }
        }
        this.setState({
            awayAvailablePositions: positions
        });
        return positions;
    }


    onRosterGridReady = params => {
        ////console.log('onGridReady!');
        this.rosterGridApi = params.api;
        this.rosterGridColumnApi = params.columnApi;
        this.rosterGridApi.sizeColumnsToFit();
    }
    onLineupGridReady = params =>{
        ////console.log('onGridReady!');
        this.lineupGridApi = params.api;
        this.lineupGridColumnApi = params.columnApi;
        this.lineupGridApi.sizeColumnsToFit();
    }

    onShowSave = (updatedHomeLineup, updatedAwayLineup) =>{
        ////console.log('calling onShowSave');
        if(this.props.team === "home"){
            ////console.log('checking home lineup', updatedHomeLineup);
            if(updatedHomeLineup.length > 8){
                this.setState({
                    showSave: true
                });
            }
            else{
                if(updatedHomeLineup.length < 9){
                    this.setState({
                        showSave:false
                    })
                }
            }
        }
        else{
            if(updatedAwayLineup.length > 8){
                this.setState({
                    showSave: true
                });
            }
            else{
                if(updatedAwayLineup.length < 9){
                    this.setState({
                        showSave:false
                    })
                }
            }
        }
    }

    onCellValueChanged = (result) =>{
        //console.log("onCellValueChanged", result);
    }

    //handlePositionChange(this.props.rowIndex, this.props.data, event.target.value)
    onPositionChange = (index, data, value) =>{
        //console.log("onPositionChange "+ index+" " +value, data);

        if(data.Team === "Away"){
            //console.log("Update Away Team Player");
            let p = this.props.awayTeamPlayers.find(x=>x.id === data.id);
            let index = this.props.awayTeamPlayers.findIndex(x=>x.id === data.id);
            
            let updatedPlayers = [...this.props.awayTeamPlayers];
            let updatedPlayer = {...p};
            updatedPlayer.currentPosition = value;
            

            //console.log('updated Player = ', updatedPlayer);
            updatedPlayers.splice(index,1,updatedPlayer);
            //this.props.onUpdatePlayer(this.props.token, updatedPlayer);
            this.props.onSetAwayTeamPlayers(updatedPlayers);

        }
        else{
            //console.log("Update Home Team Player");
            let p = this.props.homeTeamPlayers.find(x=>x.id === data.id);
            let index = this.props.homeTeamPlayers.findIndex(x=>x.id === data.id);

            let updatedPlayers = [...this.props.homeTeamPlayers];
            let updatedPlayer = {...p};
            updatedPlayer.currentPosition = value;
            //console.log('updated Player = ', updatedPlayer)
            updatedPlayers.splice(index,1,updatedPlayer);
            //this.props.onUpdatePlayer(this.props.token, updatedPlayer);
            this.props.onSetHomeTeamPlayers(updatedPlayers);
        }
    }

    onRosterCellClicked = (result) =>{
        
        //console.log(result);
        //const rowNode2 = result.api.getRowNode(result.rowIndex);
        //const rowNode2 = result.api.getDisplayedRowAtIndex(result.rowIndex)
        ////console.log(rowNode2);
        
        //let rowsSelection = result.api.getSelectedRows();
        //console.log(result.data);
        ////console.log("onRosterCellClicked", result);
        
        
        if(result.colDef.field !== 'position' && result.data.currentPosition !== 'null'){
             this.handleAddTeamLineup(result.data);
        }
    }
    onLineupCellClicked = (result) =>{
        ////console.log("onLineupCellClicked", result);
        this.handleRemoveTeamLineup(result.data.id);
    }
    addHomeTeamFlex = (p) =>{
        let updatedPlayer = {...p, atBat: false};
        this.props.onSetHomeTeamFlex(updatedPlayer);
        this.onShowSave(this.props.homeTeamLineup, this.props.awayTeamLineup);
        //let homePlayerArray = this.generateHomeLineupArray(updatedHomeLineup);
        this.setState({
            homeTeamFlex: updatedPlayer
        });

    }
    addAwayTeamFlex = (p) =>{
        let updatedPlayer = {...p, atBat: false};
        this.props.onSetAwayTeamFlex(updatedPlayer);
        this.onShowSave(this.props.homeTeamLineup, this.props.awayTeamLineup);
        //let awayPlayerArray = this.generateAwayLineupArray(updatedHomeLineup);
        this.setState({
            awayTeamFlex: updatedPlayer,
        });

    }

    addToHomeLineUp = (p) =>{
        let updatedPlayer = {...p};
        let updatedHomeLineup = [...this.props.homeTeamLineup];
        updatedHomeLineup.push(updatedPlayer);
        this.props.onSetHomeTeamLineup(updatedHomeLineup);
        this.onShowSave(updatedHomeLineup, this.props.awayTeamLineup);



        let homePlayerArray = this.generateHomeLineupArray(updatedHomeLineup);
           
        this.setState({
            homeTeamLineup: homePlayerArray,
        });
        
    }
    removeFromHomeLineUp = (index) =>{
        //console.log("removing index " + index + "length of home lineup " + this.props.homeTeamLineup.length);
        if(index === -1){
            this.props.onSetHomeTeamFlex(null);
            this.setState({
                homeTeamFlex: null
            });

        }
        else{
            let updatedHomeLineup = [...this.props.homeTeamLineup];
            updatedHomeLineup.splice(index,1);
            this.props.onSetHomeTeamLineup(updatedHomeLineup);
            this.onShowSave(updatedHomeLineup, this.props.awayTeamLineup);

            let homePlayerArray = this.generateHomeLineupArray(updatedHomeLineup);

            this.setState({
                homeTeamLineup: homePlayerArray,
            });

        }


        
    }


    addToAwayLineUp = (p) =>{
        let updatedPlayer = {...p};
        let updatedLineup = [...this.props.awayTeamLineup];
        updatedLineup.push(updatedPlayer);
        this.props.onSetAwayTeamLineup(updatedLineup);
        this.onShowSave(this.props.homeTeamLineup, updatedLineup);
    }
    removeFromAwayLineUp = (index) =>{
        //console.log("removing index " + index + "length of away lineup " + this.props.awayTeamLineup.length);
        
        if(index === -1){
            this.props.onSetAwayTeamFlex(null);
            this.setState({
                awayTeamFlex: null
            });

        }
        else{
            let updatedLineup = [...this.props.awayTeamLineup];
            updatedLineup.splice(index,1);
            this.props.onSetAwayTeamLineup(updatedLineup);
            this.onShowSave(this.props.homeTeamLineup, updatedLineup);
        }
    }

    addPlayer = ()=>{
        this.setState({
            showAddPlayer: true
        })
    }
    addPlayerCancel = ()=>{
        this.setState({
            showAddPlayer: false
        })
    }

    submitAddPlayerHandler = (event, playerData) =>{
        event.preventDefault();
        ////console.log('calling submitAddPlayerHandler in Team Container '
        //+ playerData.firstName+" "
        //+ playerData.lastName+" "
        //+ playerData.number + " "
        //+ playerData.id + " "
        //+ playerData.teamId);

        ////console.log(playerData);



        if(playerData.id !== null){
            this.props.onUpdatePlayer(this.props.token, playerData);
        }
        else{
             this.props.onCreatePlayer(this.props.token, playerData);
        }  
        
        if(playerData.teamId === this.props.homeTeam.id){

            let newTeamPlayers = [...this.props.homeTeamPlayers];
            newTeamPlayers.push(playerData);
            this.props.onSetHomeTeamPlayers(newTeamPlayers);



            let homePlayerArray = this.generateHomeRosterArray(newTeamPlayers);
           
            this.setState({
                homeTeamPlayers: homePlayerArray,
            });
        }
        else if(playerData.teamId === this.props.awayTeam.id){
            let newTeamPlayers = [...this.props.awayTeamPlayers];
            newTeamPlayers.push(playerData);
            this.props.onSetAwayTeamPlayers(newTeamPlayers);
            let awayPlayerArray = this.generateAwayRosterArray(newTeamPlayers);
           
            this.setState({
                awayTeamPlayers: awayPlayerArray,
            });
        }
        else{
            ////console.log('ERROR: Player not associated with home or away team!');
        }
        this.setState({
            showAddPlayer: false
        })
        
        //this doesn't do the trick 
        //this.props.onLoadGameState(this.props.token, this.props.gameState.atBatID);
    }


    handleAddTeamLineup = (data) =>{
        ////console.log('handleAddTeamLineup', id);
        const id = data.id;
        const currentPosition = data.currentPosition;
        if(this.props.team === "home"){

            let p = this.props.homeTeamPlayers.find(x=>x.id === id);
            p = {...p, currentPosition: currentPosition};
            if(currentPosition === 'FLEX'){
                this.addHomeTeamFlex(p);
            }
            else{
                this.addToHomeLineUp(p);
            }
        }
        else{
            let p = this.props.awayTeamPlayers.find(x=>x.id === id);
            p = {...p, currentPosition: currentPosition};
            if(currentPosition === 'FLEX'){
                this.addAwayTeamFlex(p);
            }
            else{
                this.addToAwayLineUp(p);
            }
        }
    }

    handleRemoveTeamLineup = (id) =>{
        if(this.props.team === "home"){
            let index = this.props.homeTeamLineup.findIndex(x=>x.id === id);
            this.removeFromHomeLineUp(index)
        }
        else{
            let index = this.props.awayTeamLineup.findIndex(x=>x.id === id);
            this.removeFromAwayLineUp(index)
        }
    }


      generateHomeLineupArray = (players) =>{

            let homePlayers = this.props.homeTeamLineup;
            if(typeof players !== 'undefined'){
                homePlayers = players;
            }

            let lineup = [];
            for(let i=0; i < homePlayers.length; ++i){
                let p = homePlayers[i];
                lineup.push({
                    order: i+1,
                    id: p.id,
                    number: parseInt(p.number),
                    firstName: p.firstName,
                    lastName: p.lastName,
                    currentPosition: p.currentPosition,
                    remove: "Remove"
                });
                
            }
            if(this.props.homeTeamFlex){
                let p = this.props.homeTeamFlex;
                lineup.push({
                    order: homePlayers.length+1,
                    id: p.id,
                    number: parseInt(p.number),
                    firstName: p.firstName,
                    lastName: p.lastName,
                    currentPosition: p.currentPosition,
                    remove: "Remove"
                });
                
            }
            return lineup;
      }

      generateHomeRosterArray = (players, lineupPlayers) =>{

        const availablePositions = this.generateHomeAvailablePositions();
        

        let homePlayers = this.props.homeTeamPlayers;
        if(typeof players !== 'undefined'){
            homePlayers = players;
        }
        let playerArray = [];

        if(typeof homePlayers !== 'undefined'){

            let homeLineup = this.props.homeTeamLineup;
            if(typeof lineupPlayers !== 'undefined'){
                homeLineup = lineupPlayers;
            }

            // let postSortPlayers = homePlayers.sort(function(obj1, obj2) {
            //     //sort by players by last name asc
            //     return (obj1.lastName > obj2.lastName) === true? 1 : -1;
            // });

            let postSortPlayers = homePlayers;
            

            for(let i=0;i < postSortPlayers.length; ++i){
                let p = postSortPlayers[i];

                if(typeof p.hidden !== 'undefined' && p.hidden === true){
                    continue;
                }

                if(this.props.homeTeamFlex && this.props.homeTeamFlex.id === p.id){
                    continue;
                }


                let isInLineup = homeLineup.find(x=>x.id === p.id);
                
                if(typeof isInLineup === 'undefined'){
                    playerArray.push({
                        id: p.id,
                        number: parseInt(p.number),
                        firstName: p.firstName,
                        lastName: p.lastName,
                        add: "Add",
                        Team:"Home",
                        positions: availablePositions,
                        currentPosition: p.currentPosition ? p.currentPosition : 'null',
                        handlePositionChange: this.onPositionChange.bind(this)
                    });

                }
            }
        }
        return playerArray;

      }


      //////

      generateAwayLineupArray = (players) =>{
        let awayPlayers = this.props.awayTeamLineup;
            if(typeof players !== 'undefined'){
                awayPlayers = players;
            }
        let lineup = [];
        for(let i=0; i < awayPlayers.length; ++i){
            let p = this.props.awayTeamLineup[i];
            lineup.push({
                order: i+1,
                id: p.id,
                number: parseInt(p.number),
                firstName: p.firstName,
                lastName: p.lastName,
                currentPosition: p.currentPosition,
                Remove: "Remove"
            });
            
        }
        if(this.props.awayTeamFlex){
            let p = this.props.awayTeamFlex;
            lineup.push({
                order: awayPlayers.length+1,
                id: p.id,
                number: parseInt(p.number),
                firstName: p.firstName,
                lastName: p.lastName,
                currentPosition: p.currentPosition,
                remove: "Remove"
            });
            
        }
        return lineup;
  }

  generateAwayRosterArray = (players) =>{
    const availablePositions = this.generateAwayAvailablePositions();
    let awayPlayers = this.props.awayTeamPlayers;
    if(typeof players !== 'undefined'){
        awayPlayers = players;
    }
    let playerArray = [];
    if(typeof awayPlayers !== 'undefined'){

        // let postSortPlayers = awayPlayers.sort(function(obj1, obj2) {
        //     //sort by players by last name asc
        //     return (obj1.lastName > obj2.lastName) === true? 1 : -1;
        // });
        let postSortPlayers = awayPlayers;

        for(let i=0;i < postSortPlayers.length; ++i){
            let p = postSortPlayers[i];


            if(typeof p.hidden !== 'undefined' && p.hidden === true){
                continue;
            }

            if(this.props.awayTeamFlex && this.props.awayTeamFlex.id === p.id){
                continue;
            }

            let isInLineup = this.props.awayTeamLineup.find(x=>x.id === p.id);
            
            if(typeof isInLineup === 'undefined'){
                playerArray.push({
                    id: p.id,
                    number: parseInt(p.number),
                    firstName: p.firstName,
                    lastName: p.lastName,
                    Add: "Add",
                    Team:"Away",
                    positions: availablePositions,
                    currentPosition: p.currentPosition ? p.currentPosition : 'null',
                    handlePositionChange: this.onPositionChange.bind(this)
                });

            }
        }
    }
    return playerArray;

  }

    
    render(){
        
        
        
          
        
        
       // let RosterDataGrid = null;
        //let LineupDataGrid = null;

        let rosterTableJSX = null;
        let lineupTableJSX = null;


        let title = null;
        let lineupTitle = null;
        //let players = [];
        //let lineup = [];

        let playerArray = [];

        let lineupArray = [];

        if(this.props.team === "home"){
            title = <h3>{this.props.homeTeam.name} Roster</h3>
            lineupTitle = <h3>{this.props.homeTeam.name} Lineup</h3>
            ////console.log(this.props.homeTeamPlayers.length);

            
            
            playerArray = this.state.homeTeamPlayers;
            lineupArray = this.state.homeTeamLineup;
            
            this.state.roster.rowData = playerArray;
            this.state.lineup.rowData = lineupArray;
            rosterTableJSX = <div className={classes.DatagridContainer}>
                            {title}
                            <div 
                                className="ag-theme-balham-dark"
                                style={{ 
                                height: '400px', 
                                width: '300px',
                                display:'inline-block',
                                fontFamily: 'inherit',
                                fontSize:'15px'}} 
                            >
                               
                                <AgGridReact
                                // useful for accessing the component directly via ref
                                ref="agGrid"
                                onCellClicked={this.onRosterCellClicked.bind(this)}
                                onCellValueChanged={this.onCellValueChanged.bind(this)}
                                columnDefs={this.state.roster.columnDefs}
                                rowData={this.state.roster.rowData}
                                onGridReady={this.onRosterGridReady}>
                                </AgGridReact>
                            </div>
                            </div>
            lineupTableJSX = <div className={classes.DatagridContainer}> 
                    {lineupTitle}
                            <div 
                                className="ag-theme-balham-dark"
                                style={{ 
                                height: '400px', 
                                width: '300px',
                                display:'inline-block'
                                 }} 
                            >
                                
                                <AgGridReact
                                // useful for accessing the component directly via ref
                                ref="agGrid"
                                onCellClicked={this.onLineupCellClicked.bind(this)}
                                columnDefs={this.state.lineup.columnDefs}
                                rowData={this.state.lineup.rowData}
                                onGridReady={this.onLineupGridReady}>
                                </AgGridReact>
                            </div>
                            </div>
        }
        else{
            title = <h3>{this.props.awayTeam.name} Roster</h3>
            lineupTitle = <h3>{this.props.awayTeam.name} Lineup</h3>
            ////console.log(this.props.awayTeamPlayers.length);

            playerArray = this.state.awayTeamPlayers;
            lineupArray = this.state.awayTeamLineup; 
            
            this.state.roster.rowData = playerArray;
            this.state.lineup.rowData = lineupArray;
            rosterTableJSX =
            <div className={classes.DatagridContainer}>
            {title} 
                            <div 
                                className="ag-theme-balham-dark"
                                style={{ 
                                height: '400px', 
                                width: '300px',
                                display:'inline-block'}} 
                            >
                                
                                <AgGridReact
                                // useful for accessing the component directly via ref
                                ref="agGrid"
                                onCellClicked={this.onRosterCellClicked.bind(this)}
                                columnDefs={this.state.roster.columnDefs}
                                rowData={this.state.roster.rowData}
                                onGridReady={this.onRosterGridReady}>
                                </AgGridReact>
                            </div>
                            </div>
            lineupTableJSX = 
            
                            <div className={classes.DatagridContainer}>
                                            {title}
                            <div 
                                className="ag-theme-balham-dark"
                                style={{ 
                                height: '400px', 
                                width: '300px',
                                display:'inline-block'
                                 }} 
                            >
                                
                                <AgGridReact
                                // useful for accessing the component directly via ref
                                ref="agGrid"
                                onCellClicked={this.onLineupCellClicked.bind(this)}
                                columnDefs={this.state.lineup.columnDefs}
                                rowData={this.state.lineup.rowData}
                                onGridReady={this.onLineupGridReady}>
                                </AgGridReact>
                            </div>
                            </div>
        }

        //console.log('LineupSelector ');
        let showSave = null;
        if(this.state.showSave === true){
            showSave = <div><Button  clicked={this.props.onSave}>SAVE</Button></div>
        }
        let addPlayerButton = <Button btnSize="ExtraSmall" clicked={this.addPlayer}>ADD NEW PLAYER</Button>

        return(
            <>
            <Modal show={this.state.showAddPlayer} clickBackdrop={this.addPlayerCancel}>
            <PlayerForm  
                loading = {this.props.playerLoading}
                message = {this.props.playerMessage}
                team={ this.props.team === "home" ? this.props.homeTeam : this.props.awayTeam} 
                submit={this.submitAddPlayerHandler} 
                player={null}/>
            </Modal>
            <div className={classes.LineupContainer}>
                {addPlayerButton}
                {/*RosterDataGrid*/}
                {rosterTableJSX}
                {lineupTableJSX}
                {showSave}
                {/*<div className={classes.LineupSelector}>
                    {title}
                    {addPlayerButton}
                    {players}
                </div>
                <div className={classes.Lineup}>
                    {lineupTitle}
                    {lineup}
                    {showSave}
        </div>*/}
            </div>
            </>
        )
    }
}




const mapStateToProps = state =>{
    return{
        gameState: state.atBat,
        game: state.atBat.game,
        homeTeam: state.atBat.homeTeam,
        awayTeam: state.atBat.awayTeam,
        homeTeamPlayers: state.atBat.homeTeamPlayers,
        awayTeamPlayers: state.atBat.awayTeamPlayers,
        homeTeamLineup: state.atBat.homeTeamLineup,
        awayTeamLineup: state.atBat.awayTeamLineup,
        homeTeamFlex: state.atBat.homeTeamFlex,
        awayTeamFlex: state.atBat.awayTeamFlex,
        homeTeamCurrentPitcher: state.atBat.homeTeamCurrentPitcher,
        awayTeamCurrentPitcher: state.atBat.awayTeamCurrentPitcher,
        currentHitter: state.atBat.currentHitter,
        currentPitcher: state.atBat.currentPitcher,
        totalInnings: state.atBat.totalInnings,

        playerError: state.player.error,
        playerMessage: state.player.message,
        playerLoading: state.player.loading,
        players: state.player.players,
        playerId: state.player.id,
        token: state.auth.token
    }
}

const mapDispatchToProps = dispatch =>{
    return{
        onLoadGameState: (token, id)=>dispatch(
            ActionTypes.loadGameState(token, id)
        ),
        onSetAwayTeamLineup: (data)=>dispatch(
            ActionTypes.setAwayTeamLineup(data)
        ),
        onSetHomeTeamLineup: (data)=>dispatch(
            ActionTypes.setHomeTeamLineup(data)
        ),
        onSetHomeTeamFlex: (data)=>dispatch(
            ActionTypes.setHomeTeamFlex(data)
        ),
        onSetAwayTeamFlex: (data)=>dispatch(
            ActionTypes.setAwayTeamFlex(data)
        ),
        onSetInnings: (data)=>dispatch(
            ActionTypes.setTotalInnings(data)
        ),
        onSetHomeTeamCurrentPitcher: (data)=>dispatch(
            ActionTypes.setHomeTeamCurrentPitcher(data)
        ),
        onSetAwayTeamCurrentPitcher: (data)=>dispatch(
            ActionTypes.setAwayTeamCurrentPitcher(data)
        ),
        onSetCurrentHitter: (data)=>dispatch(
            ActionTypes.setCurrentBatter(data)
        ),
        onSetCurrentPitcher: (data)=>dispatch(
            ActionTypes.setCurrentPitcher(data)
        ),
        onCreatePlayer: (token, payload)=>dispatch(
            ActionTypes.createPlayer(token, payload)
        ),
        onUpdatePlayer: (token, payload)=>dispatch(
            ActionTypes.updatePlayer(token, payload)
        ),
        onSetAwayTeamPlayers: (data) =>dispatch(
            ActionTypes.setAwayTeamPlayers(data)
        ),
        onSetHomeTeamPlayers: (data) =>dispatch(
            ActionTypes.setHomeTeamPlayers(data)
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (LineupSelector);