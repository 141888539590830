import React, {Component} from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as ActionTypes from '../../store/actions/index';
import Button from '../../components/UI/Button/Button';
import PlayerSelector from '../PlayerSelector/PlayerSelector';
import LineupPlayer from './LineupPlayer/LineupPlayer';
import Spinner from '../../components/UI/Spinner/Spinner';
import LineupSelector from './LineupSelector/LineupSelector';


import classes from './Lineup.module.css';

class Lineup extends Component{
    state = {
        currentStep: 0,
        awayStartingPitcher: null,
        currentPitcher: null,
        currentBatter: null
    }
    componentDidMount(){
        ////console.log('Lineup->componentDidMount');
        if(this.props.game){
            this.props.onSetInnings(parseInt(this.props.game.innings));
        }
        if(this.props.training){
            if(this.props.training.trainingType === 'Pitcher'){
                this.setState({
                    currentStep: 5
                })
            }
            else{
                this.setState({
                    currentStep: 6
                })
            }

        }
    }

    componentDidUpdate(prevProps, prevState){
        ////console.log('Lineup->componentDidUpdate', prevProps, this.props);
         //need to set the number of gamestate innings if the game has innings specified
         if( (this.props.game) &&
             (this.props.game || typeof this.props.game !== 'undefined') &&
             (this.props.game.innings || typeof this.props.game.innings !== 'undefined')){

                if( (prevProps.game) &&
                    (prevProps.game.innings || typeof prevProps.game.innings !== 'undefined')){
                    if(prevProps.game.innings !== this.props.game.innings){
                        this.props.onSetInnings(parseInt(this.props.game.innings));
                        this.setState({
                            currentStep: 1
                        });
                    }
                }
                else{
                    this.props.onSetInnings(parseInt(this.props.game.innings));
                    this.setState({
                        currentStep: 1
                    });
                }

        }
        if(prevProps.training !== this.props.training){
            if(this.props.training){
                if(this.props.training.trainingType === 'Pitcher'){
                    this.setState({
                        currentStep: 5
                    })
                }
                else{
                    this.setState({
                        currentStep: 6
                    })
                }
    
            }
        }
             
    }

    onChangeHandler = (event) =>{
        ////console.log('Lineup->onChangeHandler', event);
        ////console.log(event.target.value);
        this.props.onSetInnings(parseInt(event.target.value));
    }

    onSaveHandler = (event) =>{
        //console.log('Lineup->onSaveInningsHandler', event);
        let currentStep = this.state.currentStep;
        currentStep = currentStep + 1;
        //console.log('currentStep ' + currentStep);
        this.setState({
            currentStep: currentStep
        })
        if(currentStep > 4 && this.state.awayStartingPitcher){
            this.props.onSetCurrentHitter(this.props.awayTeamLineup[0]);
            this.props.onSetCurrentPitcher(this.props.homeTeamCurrentPitcher);
            this.props.onSetAwayTeamCurrentPitcher(this.state.awayStartingPitcher);
        }
        else if(currentStep > 4 && this.state.currentPitcher){
            this.props.onSetCurrentPitcher(this.state.currentPitcher);

            let demoBatter = {
                firstName : 'Demo',
                lastName: 'Demo',
                number: "0",
                teamId: null
            }
            this.props.onSetCurrentHitter(demoBatter);
        }
    }

    onSelectHomeStartingPitcherHandler = (id)=>{
        ////console.log('Lineup->onSelectHomeStartingPitcherHandler ' + id);
        let player = this.props.homeTeamPlayers.find(p =>p.id === id);
        let updatedPlayer = {...player};
        this.props.onSetHomeTeamCurrentPitcher(updatedPlayer);
    }

    onSelectTrainingPitcherHandler = (id)=>{
        //console.log('Lineup->onSelectTrainingPitcherHandler ' + id);
        let player = this.props.homeTeamPlayers.find(p =>p.id === id);
        let updatedPlayer = {...player};
        this.setState({
            currentPitcher: updatedPlayer
        })
        //this.props.onSetHomeTeamCurrentPitcher(updatedPlayer);
    }
    
    onRemoveTrainingPitcherHandler = () =>{
        this.setState({
            currentPitcher: null
        })
    }

    onSelectHomeBatterHandler = (id)=>{
        ////console.log('Lineup->onSelectHomeStartingPitcherHandler ' + id);
        let player = this.props.homeTeamPlayers.find(p =>p.id === id);
        let updatedPlayer = {...player};
        this.props.onSetCurrentHitter(updatedPlayer);
    }

    removeHomeBatter = () =>{
        this.props.onSetCurrentHitter(null);
    }

    removeHomePitcher = () =>{
        this.props.onSetHomeTeamCurrentPitcher(null);
    }


    onSelectAwayStartingPitcherHandler = (id)=>{
        ////console.log('Lineup->onSelectAwayStartingPitcherHandler ' + id);
        let player = this.props.awayTeamPlayers.find(p =>p.id === id);
        let updatedPlayer = {...player};
        this.setState({
            awayStartingPitcher: updatedPlayer
        })
    }

    removeAwayPitcher = () =>{
        this.setState({
            awayStartingPitcher: null
        })
    }

    onSelectHomePlayerHandler = (id)=>{
        ////console.log('Lineup->onSelectHomePlayerHandler ' + id);
        let player = this.props.homeTeamPlayers.find(p =>p.id === id);
        ////console.log('player ', player);
        let updatedHomeTeamLineup = [...this.props.homeTeamLineup];
        let updatedPlayer = {...player};

        let existingPlayer = this.props.homeTeamLineup.find(p => p.id === id);
        if(typeof existingPlayer === 'undefined'){
            updatedHomeTeamLineup.push(updatedPlayer);
            ////console.log('home team lineup', updatedHomeTeamLineup);
            this.props.onSetHomeTeamLineup(updatedHomeTeamLineup);
        }
        

        

    }
    homePlayerRemovedHandler = (event, index)=>{
        ////console.log('Lineup->homePlayerRemovedHandler ' + index);
        let updatedHomeTeamLineup = [...this.props.homeTeamLineup];
        updatedHomeTeamLineup.splice(index,1);
        this.props.onSetHomeTeamLineup(updatedHomeTeamLineup);

    }


    onSelectAwayPlayerHandler = (id)=>{
       // //console.log('Lineup->onSelectAwayPlayerHandler ' + id);
        let player = this.props.awayTeamPlayers.find(p =>p.id === id);
        ////console.log('player ', player);
        let updatedAwayTeamLineup = [...this.props.awayTeamLineup];
        let updatedPlayer = {...player};

        let existingPlayer = this.props.awayTeamLineup.find(p => p.id === id);
        if(typeof existingPlayer === 'undefined'){
            updatedAwayTeamLineup.push(updatedPlayer);
            ////console.log('away team lineup', updatedAwayTeamLineup);
            this.props.onSetAwayTeamLineup(updatedAwayTeamLineup);
        }

    }
    awayPlayerRemovedHandler = (event, index)=>{
        ////console.log('Lineup->awayPlayerRemovedHandler ' + index);
        let updatedAwayTeamLineup = [...this.props.awayTeamLineup];
        updatedAwayTeamLineup.splice(index,1);
        this.props.onSetAwayTeamLineup(updatedAwayTeamLineup);

    }

    showSpinner = () =>{

        if( this.props.training === null && (this.props.game === null ||
            this.props.homeTeam === null ||
            this.props.awayTeam === null ||
            this.props.homeTeamPlayers === null ||
            this.props.awayTeamPlayers === null ||
            this.props.homeTeamLineup === null ||
            this.props.awayTeamLineup=== null)){
                return true;
        }
        else if(this.props.training && this.props.homeTeam && this.props.homeTeamPlayers){
            return false;
        }
        return false;
    }

    onDeleteGame = () =>{
        if(this.props.training){
            //console.log('Delete Training!' + this.props.training.id +" " + this.props.atBat.atBatID);
        
        }
        else{
            //console.log('Delete Game!' + this.props.game.id +" " + this.props.atBat.atBatID);
            this.props.onDeleteGameState(this.props.token, this.props.atBat.atBatID);
            this.props.onDeleteGame(this.props.token,this.props.game.id);
            this.props.history.push('/games');
        }
    }

    render(){
        let renderContent = null;
        if(this.showSpinner()){
            renderContent = <Spinner />;
        }
        else if(this.state.currentStep === 0){
           
           
                renderContent = <div className={classes.Innings}>
                                <h3>Set Total Innings</h3>
                                <p>
                                <select className={classes.Select} onChange={(event)=>this.onChangeHandler(event)}>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                </select>
                                </p>
                                <Button btnType="ExtraSmall" clicked={(event)=>this.onSaveHandler(event)}>SAVE</Button>
                                </div>
                
            
            
        }
        else if(this.state.currentStep === 1){
            let battingTeamPlayers = null;
            //let battingTeam = null;
            battingTeamPlayers = this.props.homeTeamPlayers;
            //battingTeam = this.props.homeTeam;
            let selectBatter = null;
            if(battingTeamPlayers){
                selectBatter = <LineupSelector team="home" onSave={this.onSaveHandler}/>
            }
            renderContent = <>{selectBatter}</>;
        }

        else if(this.state.currentStep === 2){
            let battingTeamPlayers = null;
            //let battingTeam = null;
            battingTeamPlayers = this.props.awayTeamPlayers;
            //battingTeam = this.props.awayTeam;
            let selectBatter = null;
            if(battingTeamPlayers){
                selectBatter = <LineupSelector team="away" onSave={this.onSaveHandler}/>
            }
            renderContent = <>{selectBatter}</>;
        }
        else if(this.state.currentStep === 3){
            let teamPlayers = [...this.props.homeTeamLineup];
            if(this.props.homeTeamFlex){
                teamPlayers.push({...this.props.homeTeamFlex});
            }
            let team = this.props.homeTeam;
            let selectPitcher = null;
            if(this.props.homeTeamCurrentPitcher){
                //this.onSaveHandler();
                let label = "Select Starting Pitcher";
                if(team){
                    label = "Select " + team.name + "  Starting Pitcher";
                }
                selectPitcher= <PlayerSelector label={label}
                                players={teamPlayers} 
                                currentPlayer={this.props.homeTeamCurrentPitcher}
                                onSubmit={(id)=>this.onSelectHomeStartingPitcherHandler(id)}/>;
                let player = null;
                let saveButton = null;
                if(this.props.homeTeamCurrentPitcher){
                    player = <LineupPlayer
                    orderNumber={1}
                    player={this.props.homeTeamCurrentPitcher} 
                    removePlayer={(event) => this.removeHomePitcher()} />

                    saveButton = 
                    <div className={classes.Button}>
                    <Button clicked={(event)=>this.onSaveHandler(event)} btnType="Danger">SAVE</Button>
                    </div>
                }
                renderContent = <>{selectPitcher} {player} {saveButton}</>;
            }
            else{
                //let teamPlayers = this.props.homeTeamPlayers;
                
                if(teamPlayers){
                    let homeTeamPitcher = null;
                    for(let i=0; i < teamPlayers.length; ++ i){
                        const p = teamPlayers[i];
                        if(p.currentPosition === 'P'){
                            homeTeamPitcher = p;
                            break;
                        }
                    }
                    if(homeTeamPitcher !== null){
                        this.onSelectHomeStartingPitcherHandler(homeTeamPitcher.id);
                    }
                    else{
                        let label = "Select Starting Pitcher";
                        if(team){
                            label = "Select " + team.name + "  Starting Pitcher";
                        }
                        selectPitcher= <PlayerSelector label={label}
                                        players={teamPlayers} 
                                        currentPlayer={null}
                                        onSubmit={(id)=>this.onSelectHomeStartingPitcherHandler(id)}/>;

                        let player = null;
                        let saveButton = null;
                        if(this.props.homeTeamCurrentPitcher){
                            player = <LineupPlayer
                            orderNumber={1}
                            player={this.props.homeTeamCurrentPitcher} 
                            removePlayer={(event) => this.removeHomePitcher()} />

                            saveButton = 
                            <div className={classes.Button}>
                            <Button clicked={(event)=>this.onSaveHandler(event)} btnType="Danger">SAVE</Button>
                            </div>
                        }
                        renderContent = <>{selectPitcher} {player} {saveButton}</>;
                    
                    }
                }
                
                
            }
        }

        else if(this.state.currentStep === 4){
            let teamPlayers = [...this.props.awayTeamLineup];
            if(this.props.awayTeamFlex){
                teamPlayers.push({...this.props.awayTeamFlex});
            }
            let team = this.props.awayTeam;
            let selectPitcher = null;
            if(this.state.awayStartingPitcher){
                //this.onSaveHandler();
                let label = "Select Starting Pitcher";
                if(team){
                    label = "Select " + team.name + "  Starting Pitcher";
                }
                selectPitcher= <PlayerSelector label={label}
                                players={teamPlayers} 
                                currentPlayer={this.state.awayStartingPitcher}
                                onSubmit={(id)=>this.onSelectAwayStartingPitcherHandler(id)}/>;
                let player = null;
                let saveButton = null;
                if(this.state.awayStartingPitcher){
                    player = <LineupPlayer
                    orderNumber={1}
                    player={this.state.awayStartingPitcher} 
                    removePlayer={(event) => this.removeAwayPitcher()} />

                    saveButton = 
                    <div className={classes.Button}>
                    <Button clicked={(event)=>this.onSaveHandler(event)} btnType="Danger">SAVE</Button>
                    </div>
                }
                renderContent = <>{selectPitcher} {player} {saveButton}</>;
            }
            else{
                if(teamPlayers){

                    let awayTeamPitcher = null;
                    for(let i=0; i < teamPlayers.length; ++ i){
                        const p = teamPlayers[i];
                        if(p.currentPosition === 'P'){
                            awayTeamPitcher = p;
                            break;
                        }
                    }
                    if(awayTeamPitcher !== null){
                        this.onSelectAwayStartingPitcherHandler(awayTeamPitcher.id);
                    }
                    else{

                        let label = "Select Starting Pitcher";
                        if(team){
                            label = "Select Away " + team.name + "  Starting Pitcher";
                        }
                        selectPitcher= <PlayerSelector label={label}
                                        players={teamPlayers} 
                                        currentPlayer={this.state.awayStartingPitcher}
                                        onSubmit={(id)=>this.onSelectAwayStartingPitcherHandler(id)}/>;

                        let player = null;
                        let saveButton = null;
                        if(this.state.awayStartingPitcher){
                            player = <LineupPlayer
                            orderNumber={1}
                            player={this.state.awayStartingPitcher} 
                            removePlayer={(event) => this.removeAwayPitcher()} />

                            saveButton = 
                            <div className={classes.Button}>
                            <Button clicked={(event)=>this.onSaveHandler(event)} btnType="Danger">SAVE</Button>
                            </div>
                        }
                        renderContent = <>{selectPitcher} {player} {saveButton}</>;
                    }
                }
            }
        }
        else if(this.state.currentStep === 5){
            let teamPlayers = this.props.homeTeamPlayers;
            let team = this.props.homeTeam;
            let selectPitcher = null;
            if(teamPlayers){
                let label = "Select Pitcher";
                if(team){
                    label = "Select " + team.name + "  Pitcher";
                }
                
    
                selectPitcher= <PlayerSelector label={label}
                                players={teamPlayers} 
                                currentPlayer={null}
                                onSubmit={(id)=>this.onSelectTrainingPitcherHandler(id)}/>;

                let player = null;
                let saveButton = null;
                if(this.state.currentPitcher){
                    player = <LineupPlayer
                    orderNumber={1}
                    player={this.state.currentPitcher} 
                    removePlayer={(event) => this.onRemoveTrainingPitcherHandler()} />

                    saveButton = 
                    <div className={classes.Button}>
                    <Button clicked={(event)=>this.onSaveHandler(event)} btnType="Danger">SAVE</Button>
                    </div>
                }
                renderContent = <>{selectPitcher} {player} {saveButton}</>;
                
                
                
            }

        }
        else if(this.state.currentStep === 6){
            let teamPlayers = this.props.homeTeamPlayers;
            let team = this.props.homeTeam;
            let selectBatter = null;
            if(teamPlayers){
                let label = "Select Batter";
                if(team){
                    label = "Select " + team.name + "  Batter";
                }
                selectBatter= <PlayerSelector label={label}
                                players={teamPlayers} 
                                currentPlayer={null}
                                onSubmit={(id)=>this.onSelectHomeBatterHandler(id)}/>;

                let player = null;
                let saveButton = null;
                if(this.props.homeTeamCurrentBatter){
                    player = <LineupPlayer
                    orderNumber={1}
                    player={this.props.homeTeamCurrentBatter} 
                    removePlayer={(event) => this.removeHomeBatter()} />

                    saveButton = 
                    <div className={classes.Button}>
                    <Button clicked={(event)=>this.onSaveHandler(event)} btnType="Danger">SAVE</Button>
                    </div>
                }
                renderContent = <>{selectBatter} {player} {saveButton}</>;
                
                
                
            }
    }

        let title = null;
        if(this.props.homeTeam && this.props.awayTeam && this.props.game){
            title = <h3>{this.props.awayTeam.name} at {this.props.homeTeam.name}  -
            { new Date(this.props.game.date).toLocaleDateString("en-US")} -
            Total Innings: {this.props.totalInnings}</h3>
        }


        let deleteGame = <Button btnType="Danger" clicked={this.onDeleteGame}>Delete Game</Button> 
        if(this.props.training){
            deleteGame = null;//<Button btnType="Danger" clicked={this.onDeleteGame}>Delete Training</Button> 
        }


        return(
            <div className={classes.Lineup}>
                {title}
                {deleteGame}
                {renderContent}
            </div>
        )
    }
}

const mapStateToProps = state =>{
    return{
        game: state.atBat.game,
        atBat: state.atBat,
        token: state.auth.token,
        homeTeam: state.atBat.homeTeam,
        awayTeam: state.atBat.awayTeam,
        homeTeamPlayers: state.atBat.homeTeamPlayers,
        awayTeamPlayers: state.atBat.awayTeamPlayers,
        homeTeamLineup: state.atBat.homeTeamLineup,
        awayTeamLineup: state.atBat.awayTeamLineup,
        homeTeamFlex: state.atBat.homeTeamFlex,
        awayTeamFlex: state.atBat.awayTeamFlex,
        homeTeamCurrentPitcher: state.atBat.homeTeamCurrentPitcher,
        awayTeamCurrentPitcher: state.atBat.awayTeamCurrentPitcher,
        currentHitter: state.atBat.currentHitter,
        currentPitcher: state.atBat.currentPitcher,
        totalInnings: state.atBat.totalInnings,
        isTraining: state.atBat.isTraining,
        trainingID: state.atBat.trainingID,
        training: state.atBat.training

    }
}

const mapDispatchToProps = dispatch =>{
    return{
        onDeleteGame: (token, data)=>dispatch(
            ActionTypes.deleteGame(token, data)
        ),
        onDeleteGameState: (token, data)=>dispatch(
            ActionTypes.deleteGameState(token, data)
        ),
        onSetAwayTeamLineup: (data)=>dispatch(
            ActionTypes.setAwayTeamLineup(data)
        ),
        onSetHomeTeamLineup: (data)=>dispatch(
            ActionTypes.setHomeTeamLineup(data)
        ),
        onSetInnings: (data)=>dispatch(
            ActionTypes.setTotalInnings(data)
        ),
        onSetHomeTeamCurrentPitcher: (data)=>dispatch(
            ActionTypes.setHomeTeamCurrentPitcher(data)
        ),
        onSetAwayTeamCurrentPitcher: (data)=>dispatch(
            ActionTypes.setAwayTeamCurrentPitcher(data)
        ),
        onSetCurrentHitter: (data)=>dispatch(
            ActionTypes.setCurrentBatter(data)
        ),
        onSetCurrentPitcher: (data)=>dispatch(
            ActionTypes.setCurrentPitcher(data)
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (Lineup));