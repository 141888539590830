import * as actionTypes from './actionTypes';
import axios from '../../axios';
import axiosmap from 'axios';

export const createTeamStart = () =>{
    return{
        type:actionTypes.CREATE_TEAM_START
    };
}

export const createTeamSuccess = (id, teamData) =>{
    return{
        type:actionTypes.CREATE_TEAM_SUCCESS,
        id: id,
        teamData: teamData
    };
}

export const createTeamFail = (error) =>{
    return{
        type:actionTypes.CREATE_TEAM_FAIL,
        error: error
    };
}

export const createTeam = (token, teamData) =>{
    return dispatch =>{
        dispatch(createTeamStart());
        axios.post('teams.json?auth=' + token, teamData)
            .then(response =>{
                ////console.log("id="+response.data.name);
                dispatch(createTeamSuccess(response.data.name, teamData));
            })
            .catch(error =>{
                ////console.log(error.response);
                dispatch(createTeamFail(error.response.data.error));
            });
    }
}


export const updateTeamStart = () =>{
    return{
        type:actionTypes.UPDATE_TEAM_START
    };
}

export const updateTeamSuccess = (id, teamData) =>{
    return{
        type:actionTypes.UPDATE_TEAM_SUCCESS,
        id: id,
        teamData: teamData
    };
}

export const updateTeamFail = (error) =>{
    return{
        type:actionTypes.UPDATE_TEAM_FAIL,
        error: error
    };
}

export const updateTeam = (token, teamData) =>{
    return dispatch =>{
        dispatch(updateTeamStart());
        const teamid = teamData.id;
        //DG: 12/12/19 Why do this?  It screws up updating team notes
        //delete teamData['id'];
        axios.patch('teams/'+teamid+'.json?auth=' + token, teamData)
            .then(response =>{
                ////console.log("id="+response.data.name);
                dispatch(updateTeamSuccess(response.data.name, teamData));
            })
            .catch(error =>{
                ////console.log(error.response);
                dispatch(updateTeamFail(error.response.data.error));
            });
    }
}






export const getTeamsStart = () =>{
    return{
        type:actionTypes.GET_TEAMS_START
    }
}

export const getTeamsSuccess = (teams) =>{
    return{
        type:actionTypes.GET_TEAMS_SUCCESS,
        teams: teams
    }
}

export const getImportTeamsStart = () =>{
    return{
        type:actionTypes.GET_IMPORT_TEAMS_START
    }
}

export const getImportTeamsSuccess = (teams) =>{
    return{
        type:actionTypes.GET_IMPORT_TEAMS_SUCCESS,
        teams: teams
    }
}

export const getImportTeamsFail = () =>{
    return{
        type:actionTypes.GET_IMPORT_TEAMS_FAIL
    }
}

export const cloneTeamsSuccess = (id, teamData) =>{
    return{
        type:actionTypes.CLONE_TEAM_SUCCESS,
        id: id,
        teamData: teamData
    }
}


export const getTeamsFail = () =>{
    return{
        type:actionTypes.GET_TEAMS_FAIL
    }
}

export const deleteTeamSuccess = (id) =>{
    return{
        type:actionTypes.DELETE_TEAM_SUCCESS,
        id: id
    }
}

export const deleteTeam = (token, id) =>{
    return dispatch =>{
        //console.log('delete team ' + id);
        //dispatch(createGameStart());
        axios.delete('teams/'+id+'.json?auth=' + token)
            .then(response =>{
                //console.log("id=",response);
                dispatch(deleteTeamSuccess(id));
            })
            .catch(error =>{
                //console.log('error', error);
                //dispatch(createGameFail(error.response.data.error));
            });
    }
}

export const getTeams = (token) =>{
    return dispatch =>{
        dispatch(getTeamsStart());
        axios.get('/teams.json?auth=' + token)
        .then(response => {
            const teams = [];
            for(let key in response.data){
                let teamData = {
                    ...response.data[key],
                    id: key
                }
                teamData.teamType = typeof teamData.teamType === 'undefined' ? 'Softball' : teamData.teamType;
                teams.push(teamData);
            }
            dispatch(getTeamsSuccess(teams));
        })
        .catch(error =>{
            dispatch(getTeamsFail());
        });
    }
}

export const getOrgTeams = (token, organizationId) =>{
    return dispatch =>{
        dispatch(getTeamsStart());
        const queryParams = '?auth=' + token + '&orderBy="organizationId"&equalTo="' + organizationId + '"';
        axios.get('/teams.json' + queryParams)
        .then(response => {
            const teams = [];
            for(let key in response.data){
                let teamData = {
                    ...response.data[key],
                    id: key
                }

                teamData.teamType = typeof teamData.teamType === 'undefined' ? 'Softball' : teamData.teamType;
                teams.push(teamData);
            }
            dispatch(getTeamsSuccess(teams));
        })
        .catch(error =>{
            dispatch(getTeamsFail());
        });
    }
}

export const getImportTeams = (token, organizationId, teamType) =>{
    return dispatch =>{
        dispatch(getImportTeamsStart());
        const queryParams = '?auth=' + token + '&orderBy="primary"&equalTo=true';
        axios.get('/teams.json' + queryParams)
        .then(response => {
            const teams = [];
            //console.log('getImportTeams', response.data);
            //console.log('teamType ' + teamType );
            for(let key in response.data){
                let teamData = {
                    ...response.data[key],
                    id: key
                }
                teamData.teamType = typeof teamData.teamType === 'undefined' ? 'Softball' : teamData.teamType;
                if(response.data[key].organizationId !== organizationId && teamType === teamData.teamType){
                    teams.push(teamData);
                }
            }
            dispatch(getImportTeamsSuccess(teams));
        })
        .catch(error =>{
            dispatch(getImportTeamsFail());
        });
    }
}

export const cloneTeam = (token, teamId, organizationId)=>{
    return dispatch =>{
        dispatch(getTeamsStart());
        axios.get('/teams/'+teamId +'.json?auth=' + token)
        .then(response => {
            let team = null;
            let teamPlayers = [];
            if(response.data !== null){
                team = {
                    ...response.data,
                    organizationId: organizationId,
                    primary: false,
                    cloned: true
                };
                team.teamType = typeof team.teamType === 'undefined' ? 'Softball' : team.teamType;
                //console.log('team to clone', response.data);
                axios.post('teams.json?auth=' + token, team)
                .then(response =>{
                    //console.log("id="+response.data.name);
                    team = {...team, id: response.data.name}
                    //console.log('cloned team', team);
                    //dispatch(cloneTeamsSuccess());
                    //dispatch(createTeamSuccess(response.data.name, teamData));
                    const queryParams = '?auth=' + token + '&orderBy="teamId"&equalTo="'+teamId+'"';
                    axios.get('/players.json' + queryParams)
                    
                    .then(response => {
                        let teamPlayers = [];
                        let promises = []
                        for(let key in response.data){
                            let teamPlayer  = {
                                ...response.data[key],
                                organizationId: organizationId,
                                teamId: team.id
                                //id: key
                            }
                            promises.push(axios.post('players.json?auth=' + token, teamPlayer));
                        }
                        //console.log('promises ', promises);
                        axiosmap.all(promises).then(function(results) {
                            dispatch(cloneTeamsSuccess(team.id, team));
                        });
                    })
                })
                .catch(error =>{
                    ////console.log(error.response);
                    //dispatch(createTeamFail(error.response.data.error));
                });
                //dispatch(cloneTeamsSuccess());
                /*const queryParams = '?auth=' + token + '&orderBy="teamId"&equalTo="'+teamId+'"';
                axios.get('/players.json' + queryParams)
                .then(response => {
                    let teamPlayers = [];
                    for(let key in response.data){
                        let teamPlayer  = {
                            ...response.data[key],
                            id: key
                        }
                        teamPlayers.push(teamPlayer);
                    }
                    teamPlayers = teamPlayers.sort(function(obj1, obj2) {
                        //sort by player number asc
                        return obj1.number - obj2.number;
                    });

                    ////console.log('getTeamPlayers',players);
                    dispatch(getTeamPlayersSuccess(players));
                })*/

                //dispatch(getTeamSuccess(teams));
            }
            else{
                //dispatch(getTeamFail('no results found for id ' + id));
            }
            
        })
    }
}

        


///////



export const getTeamStart = () =>{
    return{
        type:actionTypes.GET_TEAM_START
    }
}

export const getTeamSuccess = (teams) =>{
    return{
        type:actionTypes.GET_TEAM_SUCCESS,
        teams: teams
    }
}

export const getTeamFail = (error) =>{
    return{
        type:actionTypes.GET_TEAM_FAIL,
        error:error
    }
}

export const getTeam = (token, id) =>{
    ////console.log('getTeam ' + id);
    return dispatch =>{
        dispatch(getTeamsStart());
        axios.get('/teams/'+id +'.json?auth=' + token)
        .then(response => {
            ////console.log('response from getTeam:');
            ////console.log(response.data);
            let teams = [];
            if(response.data !== null){
                teams.push({
                    ...response.data,
                    id: id
                });
                dispatch(getTeamSuccess(teams));
            }
            else{
                dispatch(getTeamFail('no results found for id ' + id));
            }
        })
        .catch(error =>{
            dispatch(getTeamFail());
        });
    }
}