import React from 'react';
import styles from './SideDrawer.module.css';
import Backdrop from '../../UI/Backdrop/Backdrop';
import NavigationItems from '../NavigationItems/NavigationItems';

const SideDrawer = (props) =>{

    let attachedClasses = [styles.SideDrawer, styles.Close];
    if(props.open){
        attachedClasses = [styles.SideDrawer, styles.Open];
    }
    return (
        <React.Fragment>
        <Backdrop show={props.open} clickBackdrop={props.closed}/>
        <div className={attachedClasses.join(' ')} onClick={props.closed}>
            <nav>
                <NavigationItems isAuth={props.isAuth} role={props.role}/>
            </nav>
        </div>
        </React.Fragment>
    )
}


export default SideDrawer;