import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';



const initialState = {
    loading: false,
    error: false,
    message: null,
    id: null,
    players: []
};


const createPlayerStart = (state, action) =>{
    return updateObject(state, {loading: true, error:false, message:null});
}

const createPlayerSuccess = (state, action) =>{
    const newPlayer = updateObject(action.playerData, {id: action.id});


    return updateObject(state, {
        loading: false, 
        error:false, 
        id: newPlayer.id,
        message:"create player success " + action.id,
        players: state.players.concat(newPlayer),
    });
}

const createPlayerFail = (state, action) =>{
    return updateObject(state, {loading: false, error:true, message:action.error});
}

const getTeamPlayersStart = (state, action) =>{
    return updateObject( state, {loading:true, error:false, message:null});
}

const getTeamPlayersFail = (state, action) =>{
    return updateObject( state, {loading:false, error:true, message:action.error});
}

const getTeamPlayersSuccess = (state, action) =>{
    return updateObject (state, {loading:false, error:false, message:null, players: action.players});
}

const updatePlayerStart = (state, action) =>{
    return updateObject( state, {loading:true, error:false, message:null});
}

const updatePlayerFail = (state, action) =>{
    return updateObject( state, {loading:false, error:true, message:action.error});
}

//updatePlayerSuccess = (id, playerData)
const updatePlayerSuccess = (state, action) =>{
    let id = action.id;
    let newPlayerArray = [...state.players];


    let index = newPlayerArray.findIndex(player =>player.id === id);

    if(index >= 0){
        newPlayerArray.splice(index,1, action.playerData);
    }
    //console.log('updatePlayerSuccess reducer '+ index+ ' ', action.playerData)

    return updateObject (state, {
        loading:false, 
        error:false, 
        message:"update player success " + action.id,
        id: action.id,
        players: newPlayerArray
    });
}


const reducer = (state = initialState, action) =>{

    switch(action.type){
        case actionTypes.CREATE_PLAYER_START:{
            return createPlayerStart(state, action);
        }
        case actionTypes.CREATE_PLAYER_SUCCESS:{
            return createPlayerSuccess(state, action);
        }
        case actionTypes.CREATE_PLAYER_FAIL:{
            return createPlayerFail(state, action);
        }
        case actionTypes.UPDATE_PLAYER_START:{
            return updatePlayerStart(state, action);
        }
        case actionTypes.UPDATE_PLAYER_SUCCESS:{
            return updatePlayerSuccess(state, action);
        }
        case actionTypes.UPDATE_PLAYER_FAIL:{
            return updatePlayerFail(state, action);
        }
        case actionTypes.GET_TEAM_PLAYERS_START:{
            return getTeamPlayersStart(state, action);
        }
        case actionTypes.GET_TEAM_PLAYERS_SUCCESS:{
            return getTeamPlayersSuccess(state, action);
        }
        case actionTypes.GET_TEAM_PLAYERS_FAIL:{
            return getTeamPlayersFail(state, action);
        }
        default:
            return state;
    }
}

export default reducer;