import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

const initialState = {
    loading: false,
    error: false,
    message: null,
    id: null,
    users: []
};

const createUserStart = (state, action) =>{
    return updateObject(state, {loading: true, error:false, message:null});
}

const createUserSuccess = (state, action) =>{
    const newTeam = updateObject(action.teamData, {id: action.id});


    return updateObject(state, {
        loading: false, 
        error:false, 
        message:"create user success " + action.id,
        users: state.users.concat(action.user),
    });
}

const createUserFail = (state, action) =>{
    return updateObject(state, {loading: false, error:true, message:action.error});
}


const getUserStart = (state, action) =>{
    return updateObject(state, {loading: true, error:false, message: null});
}

const getUserFail = (state, action) =>{
    return updateObject(state, {loading: false, error:true, message:action.error});
}

const getUserSuccess = (state, action) =>{
    return updateObject(state, {
        users: action.user,
        loading: false
    });
}


const getUsersStart = (state, action) =>{
    return updateObject(state, {loading: true, error:false, message: null});
}

const getUsersFail = (state, action) =>{
    return updateObject(state, {loading: false, error:true, message:action.error});
}

const getUsersSuccess = (state, action) =>{
    return updateObject(state, {
        users: action.data,
        loading: false
    });
}

const updateUserStart = (state, action) =>{
    return updateObject( state, {loading:true, error:false, message:null});
}

const updateUserFail = (state, action) =>{
    return updateObject( state, {loading:false, error:true, message:action.error});
}

const updateUserSuccess = (state, action) =>{
    //action.data has the user
    let updatedUsers = [...state.users];
    let index = updatedUsers.findIndex(x => x.id === action.data.id);
    updatedUsers.splice(index,1,action.data);

    //console.log('new updated users array ', updatedUsers);

    return updateObject (state, {
        loading:false, 
        error:false, 
        message:"update user success " + action.id,
        id: action.id,
        users: updatedUsers
    });
}


const reducer = (state = initialState, action) =>{

    switch(action.type){
        case actionTypes.CREATE_USER_START:{
            return createUserStart(state, action);
        }
        case actionTypes.CREATE_USER_SUCCESS:{
            return createUserSuccess(state, action);
        }
        case actionTypes.CREATE_USER_FAIL:{
            return createUserFail(state, action);
        }
        case actionTypes.GET_USER_FAIL:{
            return getUserFail(state, action);
        }
        case actionTypes.GET_USER_START:{
            return getUserStart(state, action);
        }
        case actionTypes.GET_USER_SUCCESS:{
            return getUserSuccess(state, action);
        }
        case actionTypes.GET_USERS_FAIL:{
            return getUsersFail(state, action);
        }
        case actionTypes.GET_USERS_START:{
            return getUsersStart(state, action);
        }
        case actionTypes.GET_USERS_SUCCESS:{
            return getUsersSuccess(state, action);
        }
        case actionTypes.UPDATE_USER_START:{
            return updateUserStart(state, action);
        }
        case actionTypes.UPDATE_USER_SUCCESS:{
            return updateUserSuccess(state, action);
        }
        case actionTypes.UPDATE_USER_FAIL:{
            return updateUserFail(state, action);
        }
        default:
        {
            return state;
        }
    }
}

export default reducer;