import * as actionTypes from './actionTypes';
import axios from '../../axios';



export const setGameNotes = (data) =>{
    return{
        type:actionTypes.SET_GAME_NOTES,
        data: data
    }
}

export const setHomeTeam = (data) =>{
    return{
        type:actionTypes.SET_HOME_TEAM,
        data: data
    }
}

export const setAwayTeam = (data) =>{
    return{
        type:actionTypes.SET_AWAY_TEAM,
        data: data
    }
}


export const setHomeTeamPlayers = (data) =>{
    return{
        type:actionTypes.SET_HOME_TEAM_PLAYERS,
        data: data
    }
}


export const setAwayTeamPlayers = (data) =>{
    return{
        type:actionTypes.SET_AWAY_TEAM_PLAYERS,
        data: data
    }
}

export const initializeGameStart = () =>{
    return{
        type:actionTypes.INITIALIZE_GAME_START
    };
}

export const initializeGameSuccess = (game, homePlayers, awayPlayers) =>{
    return{
        type:actionTypes.INITIALIZE_GAME_SUCCESS,
        game: game,
        homePlayers: homePlayers,
        awayPlayers: awayPlayers
    };
}

export const createGameStateSuccess = (id, gameState) =>{
    return{
        type:actionTypes.CREATE_GAME_STATE_SUCCESS,
        id: id,
        data: gameState
    }
}

export const createGameStateStart = () =>{
    return{
        type:actionTypes.CREATE_GAME_STATE_START
    }
}

export const setGameState = (gameState) =>{
    return{
        type:actionTypes.SET_GAME_STATE,
        data: gameState
    }

}

export const updateGameStateSuccess = (id, gameState) =>{
    return{
        type:actionTypes.UPDATE_GAME_STATE_SUCCESS,
        id: id,
        data: gameState
    }
}

/////////////
export const initializeTrainingStart = () =>{
    return{
        type:actionTypes.INITIALIZE_TRAINING_START
    };
}

export const initializeTrainingSuccess = (training, players) =>{
    return{
        type:actionTypes.INITIALIZE_TRAINING_SUCCESS,
        training: training,
        players: players
    };
}

export const createTrainingStateSuccess = (id, trainingState) =>{
    return{
        type:actionTypes.CREATE_TRAINING_STATE_SUCCESS,
        id: id,
        data: trainingState
    }
}

export const updateTrainingStateSuccess = (id, trainingState) =>{
    return{
        type:actionTypes.UPDATE_TRAINING_STATE_SUCCESS,
        id: id,
        data: trainingState
    }
}




export const createTrainingState = (token, data) =>{
    ////console.log('createTrainingState,', data);
    return dispatch =>{
        //dispatch(createTrainingStart());
        axios.post('/trainingstate.json?auth=' + token, data)
            .then(response =>{
                ////console.log("id="+response.data.name);
                dispatch(createTrainingStateSuccess(response.data.name, data));
            })
            .catch(error =>{
                ////console.log(error.response);
                //dispatch(createTrainingFail(error.response.data.error));
            });
    }
}

export const updateTrainingState = (token, trainingState) =>{
    //console.log('updateTrainingState,', trainingState);
    return dispatch =>{
        //dispatch(updateTeamStart());
        //const teamid = teamData.id;
        //delete teamData['id'];
        axios.patch('trainingstate/'+trainingState.atBatID+'.json?auth=' + token, trainingState)
            .then(response =>{
                ////console.log("actions->atbat->updateTrainingState id="+response.data.name);
                dispatch(updateTrainingStateSuccess(trainingState.atBatID, trainingState));
            })
            .catch(error =>{
                ////console.log(error.response);
                //dispatch(updateTeamFail(error.response.data.error));
            });
    }
}



export const saveTrainingState = (token, trainingState) =>{
    ////console.log('saveGameState',  gameState);
    if(typeof trainingState.atBatID === 'undefined'){
        ////console.log('saveGameState atBatID is undefined, returning');
        return;
    }
    if(trainingState.atBatID === null)
    {
        return dispatch =>{
            dispatch(createTrainingState(token, trainingState))
        }
    }
    else{
        ////console.log('action->atbat->saveGameState calling updateGameState')
        return dispatch =>{
            dispatch(updateTrainingState(token, trainingState))
        }
    }
}


///////////////






export const updatePitcherView = (data) =>{
    ////console.log('update pitcher', data);
    return{
        type:actionTypes.UPDATE_PITCHER_VIEW,
        data: data
    }
}

export const updateBaseRunners = (data) =>{
    return{
        type:actionTypes.UPDATE_BASE_RUNNERS,
        baseRunners: data
    }
}

export const deleteGameState = (token, id) =>{
    return dispatch =>{
        //console.log('delete game state ' + id);
        //dispatch(createGameStart());
        axios.delete('gamestate/'+id+'.json?auth=' + token)
            .then(response =>{
                //console.log("id=",response);
                //dispatch(createGameStateSuccess(response.data.name, data));
            })
            .catch(error =>{
                //console.log('error', error);
                //dispatch(createGameFail(error.response.data.error));
            });
    }
}

export const deleteTrainingState = (token, id) =>{
    return dispatch =>{
        //console.log('delete training state ' + id);
        //dispatch(createGameStart());
        axios.delete('trainingstate/'+id+'.json?auth=' + token)
            .then(response =>{
                //console.log("id=",response);
                //dispatch(createGameStateSuccess(response.data.name, data));
            })
            .catch(error =>{
                //console.log('error', error);
                //dispatch(createGameFail(error.response.data.error));
            });
    }
}

export const createGameState = (token, data) =>{
    ////console.log('createGameState,', data);
    return dispatch =>{
        dispatch(createGameStateStart());
        axios.post('/gamestate.json?auth=' + token, data)
            .then(response =>{
                ////console.log("id="+response.data.name);
                dispatch(createGameStateSuccess(response.data.name, data));
            })
            .catch(error =>{
                ////console.log(error.response);
                //dispatch(createGameFail(error.response.data.error));
            });
    }
}

export const clearPop = () =>{
    return{
        type:actionTypes.CLEAR_POP
    }
}
export const setPop = () =>{
    return{
        type:actionTypes.SET_POP
    }
}

export const updateGameState = (token, gameState) =>{
    //console.log('updateGameState,', gameState);
    return dispatch =>{

        /*let updateGameStateArray = [];

        updateGameStateArray = [...gameState.gameStateArray];
        updateGameStateArray.push({...gameState});
        gameState.gameStateArray = updateGameStateArray;*/

        //dispatch(updateTeamStart());
        //const teamid = teamData.id;
        //delete teamData['id'];
        axios.patch('gamestate/'+gameState.atBatID+'.json?auth=' + token, gameState)
            .then(response =>{
                ////console.log("actions->atbat->updateGameState id="+response.data.name);
                dispatch(updateGameStateSuccess(gameState.atBatID, gameState));
            })
            .catch(error =>{
                ////console.log(error.response);
                //dispatch(updateTeamFail(error.response.data.error));
            });
    }
}



export const saveGameState = (token, gameState) =>{
    ////console.log('saveGameState',  gameState);
    if(typeof gameState.atBatID === 'undefined'){
        ////console.log('saveGameState atBatID is undefined, returning');
        return;
    }
    
    if(gameState.atBatID === null)
    {
        return dispatch =>{
            dispatch(createGameState(token, gameState))
        }
    }
    else{
        ////console.log('action->atbat->saveGameState calling updateGameState')
        return dispatch =>{
            dispatch(updateGameState(token, gameState))
        }
    }
}

export const updateHomeSubs = (data)=>{
    ////console.log('calling action atbat->updateHomeSubs', data);
    return{
        type:actionTypes.UPDATE_HOME_SUBSTITUTIONS,
        data : data
    };
}

export const updateAwaySubs = (data)=>{

    return{
        type:actionTypes.UPDATE_AWAY_SUBSTITUTIONS,
        data : data
    };
}

export const setForceQuit = (forceQuit, forceQuitNotes)=>{
    return {
        type:actionTypes.SET_FORCE_QUIT,
        forceQuit: forceQuit,
        forceQuitNotes: forceQuitNotes
    }
}

export const clearGameState = ()=>{
    return {
        type:actionTypes.CLEAR_GAME_STATE
    }
}

export const setCurrentBottomInningScore = (data) =>{
    return{
        type:actionTypes.SET_CURRENT_BOTTOM_INNING_SCORE,
        data: data
    }
}


export const setCurrentTopInningScore = (data) =>{
    return{
        type:actionTypes.SET_CURRENT_TOP_INNING_SCORE,
        data: data
    }
}

export const setInningScores = (data) =>{
    return{
        type:actionTypes.SET_INNING_SCORES,
        data: data
    }
}

export const loadGameStateSuccess = (data, homePlayers, awayPlayers) =>{
    return{
        type:actionTypes.LOAD_GAME_STATE_SUCCESS,
        data: data,
        homePlayers: homePlayers,
        awayPlayers: awayPlayers
    }
}
export const loadGameStateStart = () =>{
    return{
        type:actionTypes.LOAD_GAME_STATE_START
    }
}

export const loadGameState = (token, gameId) =>{
    return dispatch =>{
        dispatch(loadGameStateStart());
        const queryParams = '?auth=' + token + '&orderBy="gameID"&equalTo="'+gameId+'"';
        axios.get('/gamestate.json' + queryParams)
        .then(response => {
            ////console.log('gamestate response', response);
            let gamestate = null;
            for(let key in response.data){
                gamestate = { ...response.data[key], atBatID: key}
            }
            //console.log('gamestate after spread', gamestate);
            if(gamestate){
                if(gamestate.homeTeam){
                    axios.get('/teams/'+gamestate.homeTeam.id +'.json?auth=' + token)
                    .then(response => {
                        let team = {};
                        if(response.data !== null){
                            team = {
                                ...response.data,
                                id: gamestate.homeTeam.id
                            }
                            //console.log('homeTeam', team)
                            gamestate.homeTeam = team;

                            if(gamestate.awayTeam){
                                axios.get('/teams/'+gamestate.awayTeam.id +'.json?auth=' + token)
                                .then(response => {
                                    let team = {};
                                    if(response.data !== null){
                                        team = {
                                            ...response.data,
                                            id: gamestate.awayTeam.id
                                        }
                                        //console.log('awayTeam', team);
                                        gamestate.awayTeam = team;
                                        //console.log('updated teams, dispatching loadGameStateSuccess', gamestate);



                                        let queryParams = '?auth=' + token + '&orderBy="teamId"&equalTo="'+gamestate.homeTeam.id+'"';
                                        axios.get('/players.json' + queryParams)
                                        .then(response => {
                                            let homePlayers = [];
                                            for(let key in response.data){
                                                homePlayers.push({
                                                    ...response.data[key],
                                                    id: key
                                                });
                                            }
                                            homePlayers = homePlayers.sort(function(obj1, obj2) {
                                                //sort by player number asc
                                                return obj1.number - obj2.number;
                                            });
                                            queryParams = '?auth=' + token + '&orderBy="teamId"&equalTo="'+gamestate.awayTeam.id+'"';
                                            axios.get('/players.json' + queryParams)
                                            .then(response => {
                                                let awayPlayers = [];
                                                for(let key in response.data){
                                                    awayPlayers.push({
                                                        ...response.data[key],
                                                        id: key
                                                    });
                                                }
                                                awayPlayers = awayPlayers.sort(function(obj1, obj2) {
                                                    //sort by player number asc
                                                    return obj1.number - obj2.number;
                                                });
                                                dispatch(loadGameStateSuccess(gamestate, homePlayers, awayPlayers));
                                                
                                            })  
                                        })
                                    }
                                });
                            }
                            
                        }
                    });
                }else{
                    //console.log('game state not null, dispatching loadGameStateSuccess', gamestate);
                    dispatch(loadGameStateSuccess(gamestate));
                }

                
            }
            else{
                ////console.log('game state is null, dispatching initializeGame');
                dispatch(initializeGame(token, gameId));
            }
            // let players = [];
            // for(let key in response.data){
            //     players.push({
            //         ...response.data[key],
            //         id: key
            //     });
            // }
            // players = players.sort(function(obj1, obj2) {
            //     //sort by player number asc
            //     return obj1.number - obj2.number;
            // });

            ////console.log('getTeamPlayers',players);
            
        })
        .catch(error =>{
            //dispatch(getTeamPlayersFail());
        });
    }
}


export const initializeGameFail = (error) =>{
    return{
        type:actionTypes.INITIALIZE_GAME_FAIL,
        error: error
    };
}


/////////////

export const loadTrainingStateSuccess = (data, homePlayers) =>{
    return{
        type:actionTypes.LOAD_TRAINING_STATE_SUCCESS,
        data: data,
        homePlayers: homePlayers
    }
}
export const loadTrainingStateStart = () =>{
    return{
        type:actionTypes.LOAD_TRAINING_STATE_START
    }
}

export const loadTrainingState = (token, trainingId) =>{
    return dispatch =>{
        dispatch(loadTrainingStateStart());
        const queryParams = '?auth=' + token + '&orderBy="trainingID"&equalTo="'+trainingId+'"';
        axios.get('/trainingstate.json' + queryParams)
        .then(response => {
            //console.log('trainingstate response', response);
            let trainingstate = null;
            for(let key in response.data){
                trainingstate = { ...response.data[key], atBatID: key}
            }
            //console.log('trainingstate after spread', trainingstate);
            if(trainingstate){
                //console.log('training state not null, dispatching loadTrainingStateSuccess');


                //TO DO: load root Players

                let queryParams = '?auth=' + token + '&orderBy="teamId"&equalTo="'+trainingstate.homeTeam.id+'"';
                axios.get('/players.json' + queryParams)
                .then(response => {
                    let homePlayers = [];
                    for(let key in response.data){
                        homePlayers.push({
                            ...response.data[key],
                            id: key
                        });
                    }
                    homePlayers = homePlayers.sort(function(obj1, obj2) {
                        //sort by player number asc
                        return obj1.number - obj2.number;
                    });
                    dispatch(loadTrainingStateSuccess(trainingstate, homePlayers));
                })


                
            }
            else{
                //console.log('training state is null, dispatching initializeTraining');
                dispatch(initializeTraining(token, trainingId));
            }
            
        })
        .catch(error =>{
            //dispatch(getTeamPlayersFail());
            //console.log("error ", error)
        });
    }
}


export const initializeTrainingFail = (error) =>{
    return{
        type:actionTypes.INITIALIZE_TRAINING_FAIL,
        error: error
    };
}

/////////////

export const setTotalInnings = (data) =>{
    return{
        type:actionTypes.SET_TOTAL_INNINGS,
        data: data
    }
}
  
export const setAwayTeamCurrentPitcher = (data) =>{
    return{
        type:actionTypes.SET_AWAY_TEAM_CURRENT_PITCHER,
        data: data
    }
}

export const setFirstBaseRunner = (data) =>{
    return{
        type:actionTypes.SET_FIRST_BASE_RUNNER,
        data: data
    }
}

export const setSecondBaseRunner = (data) =>{
    return{
        type:actionTypes.SET_SECOND_BASE_RUNNER,
        data: data
    }
}
export const setThirdBaseRunner = (data) =>{
    return{
        type:actionTypes.SET_THIRD_BASE_RUNNER,
        data: data
    }
}

export const setHomeTeamCurrentPitcher = (data) =>{
    return{
        type:actionTypes.SET_HOME_TEAM_CURRENT_PITCHER,
        data: data
    }
}

export const setHomeTeamFlex = (data) =>{
    return{
        type:actionTypes.SET_HOME_TEAM_FLEX,
        data: data
    }
}

export const setAwayTeamFlex = (data) =>{
    return{
        type:actionTypes.SET_AWAY_TEAM_FLEX,
        data: data
    }
}

export const setHomeTeamLineup = (data) =>{
    return{
        type:actionTypes.SET_HOME_TEAM_LINEUP,
        data: data
    }
}

export const setHomeTeamCurrentBatterIndex = (data) =>{
    return{
        type:actionTypes.SET_HOME_TEAM_CURRENT_BATTER_INDEX,
        data: data
    }
}

export const setAwayTeamCurrentBatterIndex = (data) =>{
    return{
        type:actionTypes.SET_AWAY_TEAM_CURRENT_BATTER_INDEX,
        data: data
    }
}

export const setAwayTeamLineup = (data) =>{
    return{
        type:actionTypes.SET_AWAY_TEAM_LINEUP,
        data: data
    }
}

export const setGameOver = (data) =>{
    return{
        type:actionTypes.SET_GAME_OVER,
        data: data
    }
}

export const setCurrentPitcher = (pitcher) =>{
    return{
        type:actionTypes.SET_CURRENT_PITCHER,
        pitcher: pitcher
    }
}

export const setHomeScore = (data) =>{
    return{
        type:actionTypes.SET_HOME_SCORE,
        data: data
    }
}

export const setAwayScore = (data) =>{
    return{
        type:actionTypes.SET_AWAY_SCORE,
        data: data
    }
}

export const setOuts = (outs) =>{
    return{
        type:actionTypes.SET_OUTS,
        outs: outs
    }
}

export const setCurrentInning = (inning) =>{
    return{
        type:actionTypes.SET_CURRENT_INNING,
        inning: inning
    }
}

export const setCurrentBatter = (batter) =>{
    return{
        type:actionTypes.SET_CURRENT_BATTER,
        batter: batter
    }
}

export const setBattingRight = (data) =>{
    ////console.log('action->setBattingRight');
    return{
        type:actionTypes.SET_BATTING_RIGHT,
        data: data
    }
}
export const setPitchNumber = (data) =>{
    return{
        type:actionTypes.SET_PITCH_NUMBER,
        data: data
    }
}

export const updateGameStatePitchArray = (data) =>{
    return{
        type:actionTypes.UPDATE_GAME_STATE_PITCH_ARRAY,
        data: data
    }
}

export const initializeGame = (token, id) =>{
    return dispatch =>{
        dispatch(initializeGameStart());
        axios.get('/games/'+id +'.json?auth=' + token)
        .then(response => {
            ////console.log('getGame action:', response.data);
            let game = null;
            if(response.data !== null){
                game = {
                    ...response.data,
                    id: id
                };
                let queryParams = '?auth=' + token + '&orderBy="teamId"&equalTo="'+game.homeTeam.id+'"';
                axios.get('/players.json' + queryParams)
                .then(response => {
                    let homePlayers = [];
                    for(let key in response.data){
                        homePlayers.push({
                            ...response.data[key],
                            id: key
                        });
                    }
                    homePlayers = homePlayers.sort(function(obj1, obj2) {
                        //sort by player number asc
                        return obj1.number - obj2.number;
                    });
                    queryParams = '?auth=' + token + '&orderBy="teamId"&equalTo="'+game.awayTeam.id+'"';
                    axios.get('/players.json' + queryParams)
                    .then(response => {
                        let awayPlayers = [];
                        for(let key in response.data){
                            awayPlayers.push({
                                ...response.data[key],
                                id: key
                            });
                        }
                        awayPlayers = awayPlayers.sort(function(obj1, obj2) {
                            //sort by player number asc
                            return obj1.number - obj2.number;
                        });
                        dispatch(initializeGameSuccess(game, homePlayers, awayPlayers));
                        
                    })

                    
                })


                
            }
            else{
                dispatch(initializeGameFail('no results found for id ' + id));
            }
            
        })
        .catch(error =>{
            dispatch(initializeGameFail());
        });
    }
}




export const initializeTraining = (token, id) =>{
    return dispatch =>{
        dispatch(initializeTrainingStart());
        axios.get('/trainings/'+id +'.json?auth=' + token)
        .then(response => {
            ////console.log('getTraining action:', response.data);
            let training = null;
            if(response.data !== null){
                training = {
                    ...response.data,
                    id: id
                };
                let queryParams = '?auth=' + token + '&orderBy="teamId"&equalTo="'+training.team.id+'"';
                axios.get('/players.json' + queryParams)
                .then(response => {
                    let players = [];
                    for(let key in response.data){
                        players.push({
                            ...response.data[key],
                            id: key
                        });
                    }
                    players = players.sort(function(obj1, obj2) {
                        //sort by player number asc
                        return obj1.number - obj2.number;
                    });
                    
                    dispatch(initializeTrainingSuccess(training, players));
                        
                

                    
                })


                
            }
            else{
                dispatch(initializeTrainingFail('no results found for id ' + id));
            }
            
        })
        .catch(error =>{
            dispatch(initializeTrainingFail());
        });
    }
}