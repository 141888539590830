import React from 'react';
import styles from './Toolbar.module.css';

import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';
import NavigationItems from '../NavigationItems/NavigationItems';
import menuLogo from '../../../assets/logo-new.png';
const Toolbar = (props) => {
    return (
        <header className={styles.Toolbar}>
            <DrawerToggle click={props.openSideBar} />
            
                <span className={styles.Title}>THE SCOUT BOOK</span>
            <nav className={styles.DesktopOnly}>
                <NavigationItems isAuth={props.isAuth} role={props.role}/>
                
            </nav>
        </header>
    )
}


export default Toolbar;