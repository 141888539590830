import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';


const initialState = {
    gameStart: false,
    loading: false,
    error: false,
    message: null,
    id: null,
    games: [],
    gameStates: [],
    userID: null
};


const loadGameStatesStart = (state, action) =>{
    return updateObject(state, {loading: true, error:false, message:null});
}
const loadGameStatesFail = (state, action) =>{
    return updateObject(state, {loading: false, error:true, message:null});
}

const loadGameStatesSuccess = (state, action) =>{
    return updateObject(state,
        {
            gameStates: action.data,
            loading: false,
            error: false,
            message: null
        } );
}

const loadGameStateSuccess = (state, action) =>{

    

    //const player = this.props.players.find(x=>x.id === this.props.currentPlayer.id);
    let gameStateObj = {
        atBatID: action.data.atBatID,
        gameID: action.data.gameID,
        game: action.data.game,
        homeTeam: action.data.homeTeam,
        awayTeam: action.data.awayTeam,
        homeTeamPlayers: action.data.homeTeamPlayers,
        awayTeamPlayers: action.data.awayTeamPlayers,
        pitchNumber: action.data.pitchNumber,
        currentHitter: (typeof action.data.currentHitter === 'undefined' ||
                        action.data.currentHitter === null)? null : action.data.currentHitter,
        currentPitcher: (typeof action.data.currentPitcher === 'undefined' ||
                        action.data.currentPitcher === null)? null : action.data.currentPitcher,
        battingRight: action.data.battingRight,
        
        loading: false,
        error: action.data.error,
        message: action.data.message,
        currentInning: action.data.currentInning,
        pitchArray: action.data.pitchArray === undefined? [] : action.data.pitchArray,
        outs: action.data.outs,
        baseRunners: action.data.baseRunners === undefined ? [] : action.data.baseRunners,
        homeScore: action.data.homeScore === undefined? 0 : action.data.homeScore,
        awayScore: action.data.awayScore === undefined? 0 : action.data.awayScore,
        gameOver: action.data.gameOver,
        homeTeamLineup: action.data.homeTeamLineup === undefined ? [] : action.data.homeTeamLineup,
        
        homeTeamCurrentPitcher: typeof action.data.homeTeamCurrentPitcher === 'undefined' ? null : action.data.homeTeamCurrentPitcher,
        awayTeamLineup: action.data.awayTeamLineup === undefined ? [] : action.data.awayTeamLineup,
        awayTeamCurrentPitcher: typeof action.data.awayTeamCurrentPitcher === 'undefined' ? null : action.data.awayTeamCurrentPitcher,
        totalInnings: action.data.totalInnings === undefined ? 7 : action.data.totalInnings,
        homeTeamCurrentBatterIndex: typeof action.data.homeTeamCurrentBatterIndex === 'undefined' ? 0: action.data.homeTeamCurrentBatterIndex,
        awayTeamCurrentBatterIndex: typeof action.data.awayTeamCurrentBatterIndex === 'undefined' ? 0: action.data.awayTeamCurrentBatterIndex,
        inningScores: typeof action.data.inningScores === 'undefined' ? [] : action.data.inningScores,
        currentTopInningScore: typeof action.data.currentTopInningScore === 'undefined' ? 0 : action.data.currentTopInningScore,
        currentBottomInningScore: typeof action.data.currentBottomInningScore === 'undefined' ? 0 : action.data.currentBottomInningScore,
        forceQuit: typeof action.data.forceQuit === 'undefined' ? false : action.data.forceQuit,
        forceQuitNotes: typeof action.data.forceQuitNotes === 'undefined' ? null : action.data.forceQuitNotes,
        homeSubstitutions: typeof action.data.homeSubstitutions === 'undefined' ? [] : action.data.homeSubstitutions,
        awaySubstitutions: typeof action.data.awaySubstitutions === 'undefined' ? [] : action.data.awaySubstitutions,
        pitcherView: typeof action.data.pitcherView === 'undefined' ? true : action.data.pitcherView
    }

    let gameIndex = state.games.findIndex(x=>x.id === action.data.gameID);
    let updatedGames = [...state.games];
    if(gameIndex > -1){
        let updatedGame = {...state.games[gameIndex], gameState: gameStateObj}
        ////console.log('loadGameStateSuccess - updating Game', updatedGame);
        updatedGames.splice(gameIndex, 1, updatedGame);

    }


    return updateObject(state,
        {
            games: updatedGames,
            loading: false,
            error: false,
            message: null
        } );
}

const loadGameStateStart = (state, action) =>{
    return updateObject(state, {loading: true, error:false, message:null});
}
const loadGameStateFail = (state, action) =>{
    return updateObject(state, {loading: false, error:true, message:null});
}

const gameStart = (state, action) =>{
    return updateObject(state, {gameStart: true});
}


const createGameStart = (state, action) =>{
    return updateObject(state, {loading: true, error:false, message:null});
}

const deleteGameSuccess = (state, action) =>{
    let id = action.id;
    let newGameArray = [...state.games];

    let index = newGameArray.findIndex(game =>game.id === id);

    if(index >= 0){
        newGameArray.splice(index,1);
    }
    return updateObject(state, {games: newGameArray});
            

}

const createGameSuccess = (state, action) =>{
    const newGame = updateObject(action.data, {id: action.id});

    let newGameArray = [...state.games];
    newGameArray = newGameArray.concat(newGame);
    return updateObject(state, {
        loading: false, 
        error:false, 
        message: actionTypes.CREATE_GAME_SUCCESS,
        games: newGameArray,
        id: action.id//state.games.concat(newGame),
    });
}

const createGameFail = (state, action) =>{
    return updateObject(state, {loading: false, error:true, message:action.error});
}

//


const getGamesStart = (state, action) =>{
    return updateObject(state, {loading: true, error:false, message:null});
}

const getGamesSuccess = (state, action) =>{
    return updateObject(state, {
        loading: false, 
        error:false, 
        message: null,
        games: action.data,
    });
}
const getGamesFail = (state, action) =>{
    return updateObject(state, {loading: false, error:true, message:action.error});
}

//
const getGameStart = (state, action) =>{
    return updateObject(state, {loading: true, error:false, message:null});
}

const getGameSuccess = (state, action) =>{
    //////console.log('calling getGameSuccess ' + action.data);
    return updateObject(state, {
        loading: false, 
        error:false, 
        message: null,
        games: action.data,
    });
}
const getGameFail = (state, action) =>{
    return updateObject(state, {loading: false, error:true, message:action.error});
}




const reducer = (state = initialState, action) =>{

    switch(action.type){
        case actionTypes.GAME_START:{
            return gameStart(state, action);
        }
        case actionTypes.CREATE_GAME_START:{
            return createGameStart(state, action);
        }
        case actionTypes.CREATE_GAME_FAIL:{
            return createGameFail(state, action);
        }
        case actionTypes.CREATE_GAME_SUCCESS:{
            return createGameSuccess(state, action);
        }
        case actionTypes.DELETE_GAME_SUCCESS:{
            return deleteGameSuccess(state, action);
        }

        case actionTypes.GET_GAMES_START:{
            return getGamesStart(state, action);
        }
        case actionTypes.GET_GAMES_FAIL:{
            return getGamesFail(state, action);
        }
        case actionTypes.GET_GAMES_SUCCESS:{
            return getGamesSuccess(state, action);
        }

        case actionTypes.GET_GAME_START:{
            return getGameStart(state, action);
        }
        case actionTypes.GET_GAME_FAIL:{
            return getGameFail(state, action);
        }
        case actionTypes.GET_GAME_SUCCESS:{
            return getGameSuccess(state, action);
        }
        case actionTypes.LOAD_GAME_GAME_STATE_START:{
            return loadGameStateStart(state,action)
        }
        case actionTypes.LOAD_GAME_GAME_STATE_FAIL:{
            return loadGameStateFail(state,action)
        }
        case actionTypes.LOAD_GAME_GAME_STATE_SUCCESS:{
            return loadGameStateSuccess(state,action)
        }
        case actionTypes.LOAD_GAME_STATES_START:{
            return loadGameStatesStart(state,action)
        }
        case actionTypes.LOAD_GAME_STATES_FAIL:{
            return loadGameStatesFail(state,action)
        }
        case actionTypes.LOAD_GAME_STATES_SUCCESS:{
            return loadGameStatesSuccess(state,action)
        }
        default:
        {
            return state;
        }
    }
}

export default reducer;
