import React from 'react'
import styles from './ZonesTable.module.css';



/*
let ret = {
            HAP: 0,
            HAC: 0,
            HASM: 0,
            HIP: 0,
            HIC: 0,
            HISM: 0,
            LIP: 0,
            LIC: 0,
            LISM: 0,
            LAP: 0,
            LAC: 0,
            LASM: 0,
            MMP: 0,
            MMC: 0,
            MMSM: 0,
            
        }
*/

const ZonesTable = (props) => {

    let mincolor = {
        r: 142,
        g: 169,
        b: 219
    }

    let maxcolor = {
        r: 254,
        g: 153,
        b: 102
    }

    function getColor(p){
        //Calculate the average (r',g',b') = ( (r1+r2)/2, (g1+g2)/2, (b1+b2)/2 )
        let r = mincolor.r * parseFloat( 1.0 - p) + maxcolor.r * parseFloat(p);
        let g = mincolor.g * parseFloat( 1.0 - p) + maxcolor.g * parseFloat(p);
        let b = mincolor.b * parseFloat( 1.0 - p) + maxcolor.b * parseFloat(p);

        let ret = {
            r : r,
            g: g,
            b: b
        }
        return ret
    }

    function getColorStr(p){
        let color = getColor(p);
        return "rgb("+ color.r+","+color.g+","+color.b+")"
    }
    return(
        <>
            
            <div className={styles.ZonesTable}>
               <h3>Zones</h3>
               <table>
                   <thead>
                       <tr style={{backgroundColor: '#404040'}}>
                       <th>LOC</th>
                       <th>P%</th>
                       <th>C%</th>
                       <th>SM%</th>
                       </tr>
                   </thead>
                   <tbody>
                       <tr>
                           <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterZone("HA")}>HA({props.data.HAT})</td>
                           <td style={{backgroundColor:getColorStr(props.data.HAP)}}>{props.data.HAP}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.HAC)}}>{props.data.HAC}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.HASM)}}>{props.data.HASM}%</td>
                       </tr>
                       <tr>
                           <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterZone("HI")}>HI({props.data.HIT})</td>
                           <td style={{backgroundColor:getColorStr(props.data.HIP)}}>{props.data.HIP}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.HIC)}}>{props.data.HIC}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.HISM)}}>{props.data.HISM}%</td>
                       </tr>
                       <tr>
                           <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterZone("LI")}>LI({props.data.LIT})</td>
                           <td style={{backgroundColor:getColorStr(props.data.LIP)}}>{props.data.LIP}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.LIC)}}>{props.data.LIC}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.LISM)}}>{props.data.LISM}%</td>
                       </tr>
                       <tr>
                           <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterZone("LA")}>LA({props.data.LAT})</td>
                           <td style={{backgroundColor:getColorStr(props.data.LAP)}}>{props.data.LAP}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.LAC)}}>{props.data.LAC}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.LASM)}}>{props.data.LASM}%</td>
                       </tr>
                       <tr>
                           <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterZone("HM")}>HM({props.data.HMT})</td>
                           <td style={{backgroundColor:getColorStr(props.data.HMP)}}>{props.data.HMP}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.HMC)}}>{props.data.HMC}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.HMSM)}}>{props.data.HMSM}%</td>
                       </tr>
                       
                       <tr>
                           <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterZone("MM")}>MM({props.data.MMT})</td>
                           <td style={{backgroundColor:getColorStr(props.data.MMP)}}>{props.data.MMP}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.MMC)}}>{props.data.MMC}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.MMSM)}}>{props.data.MMSM}%</td>
                       </tr>
                       <tr>
                           <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterZone("LM")}>LM({props.data.LMT})</td>
                           <td style={{backgroundColor:getColorStr(props.data.LMP)}}>{props.data.LMP}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.LMC)}}>{props.data.LMC}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.LMSM)}}>{props.data.LMSM}%</td>
                       </tr>

                       <tr>
                           <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterZone("MA")}>MA({props.data.MAT})</td>
                           <td style={{backgroundColor:getColorStr(props.data.MAP)}}>{props.data.MAP}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.MAC)}}>{props.data.MAC}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.MASM)}}>{props.data.MASM}%</td>
                       </tr>

                       <tr>
                           <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterZone("MI")}>MI({props.data.MIT})</td>
                           <td style={{backgroundColor:getColorStr(props.data.MIP)}}>{props.data.MIP}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.MIC)}}>{props.data.MIC}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.MISM)}}>{props.data.MISM}%</td>
                       </tr>
                   </tbody>
               </table>
            </div>
            
        </>
    );
};

export default ZonesTable;