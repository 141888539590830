import React, {Component} from 'react';
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import Spinner from '../../components/UI/Spinner/Spinner';
import classes from './Auth.module.css';
import { connect } from 'react-redux';
import * as ActionTypes from '../../store/actions/index';
import {Redirect} from 'react-router-dom';
import baseballImg from '../../assets/baseball.png';

class Auth extends Component{
    state = {
        loginControls: {
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: 'E-Mail Address'
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false
            },
            password: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Password'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6
                },
                valid: false,
                touched: false
            }
        },
        regControls: {
            email: {
                elementType: 'input',
                elementConfig: {
                    label:'Email',
                    type: 'email',
                    placeholder: 'E-Mail Address'
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false
            },
            password: {
                elementType: 'input',
                elementConfig: {
                    label:'Password',
                    type: 'password',
                    placeholder: 'Password'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6
                },
                valid: false,
                touched: false
            },
            firstName: {
                elementType: 'input',
                elementConfig: {
                    label: 'First Name',
                    type: 'firstName',
                    placeholder: 'First Name'
                },
                value: '',
                validation: {
                    required: false,
                },
                valid: false,
                touched: false
            }
            ,
            lastName: {
                elementType: 'input',
                elementConfig: {
                    label: 'Last Name',
                    type: 'lastName',
                    placeholder: 'Last Name'
                },
                value: '',
                validation: {
                    required: false,
                },
                valid: false,
                touched: false
            },
            city: {
                elementType: 'input',
                elementConfig: {
                    label: 'City',
                    type: 'city',
                    placeholder: 'City'
                },
                value: '',
                validation: {
                    required: false,
                },
                valid: false,
                touched: false
            },
            state: {
                elementType: 'input',
                elementConfig: {
                    label: 'State',
                    type: 'state',
                    placeholder: 'State'
                },
                value: '',
                validation: {
                    required: false,
                },
                valid: false,
                touched: false
            },
            
            zip: {
                elementType: 'input',
                elementConfig: {
                    label: 'Zip',
                    type: 'zip',
                    placeholder: 'Zip'
                },
                value: '',
                validation: {
                    required: false,
                },
                valid: false,
                touched: false
            }
            ,
            phone: {
                elementType: 'input',
                elementConfig: {
                    label: 'Phone',
                    type: 'phone',
                    placeholder: 'Phone'
                },
                value: '',
                validation: {
                    required: false,

                },
                valid: false,
                touched: false
            },
            organization:{
                
                elementType: 'select',
                elementConfig: {
                    
                    label: 'Organization',
                    options : [
                        /*{value: 'new', displayValue: 'New'},
                        {value: '-LuZjCH5K2h5jmpnqSC0', displayValue: 'FPD'}*/
                    ]
                    
                },
                value:'-LuZjCH5K2h5jmpnqSC0',
                validation: {},
                valid:true
            },
            newOrg:{
                
                elementType: 'input',
                elementConfig: {
                    
                    label: 'New Organization',
                    type: "newOrg",
                    placeholder: 'New Organization'
                    
                },
                value:'',
                show:false,
                validation: {
                    isUnique : true
                },
                valid:true
            },
            pitchType:{
                
                elementType: 'select',
                elementConfig: {
                    label: 'Team Type',
                    options : [
                        {value: 'Softball', displayValue: 'Softball'},
                        {value: 'Baseball', displayValue: 'Baseball'}
                    ]
                    
                },
                value:'Softball',
                validation: {},
                valid:true
            },
            accountType:{
                
                elementType: 'select',
                elementConfig: {
                    label: 'Account Type',
                    options : [
                        {value: 'OrgAdmin', displayValue: 'OrgAdmin'},
                        {value: 'OrgAdminTraining', displayValue: 'OrgAdminTraining'}
                    ]
                    
                },
                value:'OrgAdmin',
                validation: {},
                valid:true,
            },
        },
        formIsValid: false,
        isSignUp: false,
        allowRegister: false,
        loading: true
    }
    componentDidMount(){
        if(this.props.match.path === '/register'){
            //console.log('registering!!!!!!!!!!!!!!!');
            this.setState({
                isSignUp: true,
                allowRegister: true,
                loading:false
            })
        }
        else{
            this.setState({
                isSignUp: false,
                allowRegister: false,
                loading: false
            })
        }
        this.props.onGetOrgs();
    }

    componentDidUpdate(prevProps, prevState) {

        
        if(prevProps.orgs !== this.props.orgs){

            if(this.props.orgs.length > 0){
                const updatedForm = {
                    ...this.state.regControls
                }
                const updatedFormOrgs = {...updatedForm['organization']};
                let updatedFormAccountType = {...updatedForm['accountType']};
                updatedFormOrgs.value = this.props.orgs[0].id;
                let updatedOptions = [];
                let org;

                if(this.state.allowRegister){
                    let option = {
                        value: 'new',
                        displayValue: 'New'
                    }
                    updatedOptions.push(option);
                    updatedFormAccountType.show = true;
                }
                else{
                    updatedFormAccountType.show = false;
                }
                
                for(let i=0;i<this.props.orgs.length; ++i){
                    const org = this.props.orgs[i];
                    //console.log('props.orgs', org)
                    let option = {
                        value: this.props.orgs[i].id,
                        displayValue: this.props.orgs[i].name
                    }
                    updatedOptions.push(option);
                }
                //console.log('updated Options', updatedOptions)
                updatedFormOrgs.elementConfig.options = updatedOptions;
                updatedFormOrgs.valid = true;


                updatedForm['accountType'] = updatedFormAccountType;
                updatedForm['organization'] = updatedFormOrgs;
                
                let formIsValid = true;
                for(let inputIds in updatedForm){
                    formIsValid = updatedForm[inputIds].valid && formIsValid;
                }
                this.setState({regControls: updatedForm, formIsValid: formIsValid});
            }
        }
    }

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }
        
        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if(rules.isUnique) {
            isValid = true;
            for(let i=0;i<this.props.orgs.length; ++i){
                if(this.props.orgs[i].name === value.trim()){
                    isValid = false;
                }
            }
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        if (rules.isNumeric) {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }

    submitHandler = (event) =>{
        event.preventDefault();
        ////console.log('calling onAuth ' + this.state.controls.email.value +' '+ this.state.controls.password.value);
        if(this.state.isSignUp){
            let teamType = this.state.regControls.pitchType.value;
            let createOrganization = false;
            let organizationName = this.state.regControls.organization.value;
            let roleVal = '';
            let organizationId = this.state.regControls.organization.value;
            if(organizationName === 'new'){
                createOrganization = true;
                organizationName = this.state.regControls.newOrg.value;
                roleVal = this.state.regControls.accountType.value;
                organizationId = '';
            }
            let signUpMethod = 'Online';

            if(this.state.allowRegister){
                signUpMethod = 'SysAdmin'
            }
            let signUpDate = new Date().toJSON().slice(0, 19).replace('T', ' ');

            let userObj = {
                email : this.state.regControls.email.value,
                firstName: this.state.regControls.firstName.value,
                lastName: this.state.regControls.lastName.value,
                city: this.state.regControls.city.value,
                state: this.state.regControls.state.value,
                zip: this.state.regControls.zip.value,
                phone: this.state.regControls.phone.value,
                organizationId: organizationId,
                userId: '',
                role: roleVal,
                method: signUpMethod,
                signUpDate: signUpDate


                
            }
            //console.log('organizationName = ' + organizationName + ' create new organization = ' + createOrganization +' teamType = ' + teamType);
            //console.log('userObj', userObj)
            this.props.onRegister(
                this.state.regControls.email.value, 
                this.state.regControls.password.value,
                userObj,
                organizationName,
                createOrganization,
                teamType
                );


        
        }
        else{
            this.props.onAuth(this.state.loginControls.email.value, this.state.loginControls.password.value);
        
        }
    }

    switchSignUpHandler = () =>{
        ////console.log('switchSignUpHandler ' + this.state.isSignUp);
        this.setState(prevState => {
            return{isSignUp: !prevState.isSignUp}
        });
    }

    inputChangedHandler = (event, inputIdentifier) =>{
        //console.log('inputChangeHandler '+ inputIdentifier);
        let updatedForm = {
            ...this.state.loginControls
        }
        let newOrgFormElement = null;
        let newPitchTypeFormElement = null;
        if(this.state.isSignUp){
            updatedForm = {
                ...this.state.regControls
            }
            if(inputIdentifier === 'organization'){
                newOrgFormElement = {...updatedForm['newOrg']};
                newPitchTypeFormElement = {...updatedForm['pitchType']};

                if(event.target.value === 'new'){
                    newOrgFormElement.show = true;
                    newPitchTypeFormElement.show = true;
                }
                else{
                    newOrgFormElement.show = false;
                    newPitchTypeFormElement.show = false;
                }
            }
        }
        const updatedFormElement = {...updatedForm[inputIdentifier]};
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        //////console.log(updatedFormElement.valid, updatedFormElement.validation ? true : false);
        updatedForm[inputIdentifier] = updatedFormElement;

        if(newOrgFormElement){
            updatedForm['newOrg'] = newOrgFormElement;
        }
        if(newPitchTypeFormElement){
            updatedForm['pitchType'] = newPitchTypeFormElement;
        }

        let formIsValid = true;
        for(let inputIds in updatedForm){
            formIsValid = updatedForm[inputIds].valid && formIsValid;
        }
        if(this.state.isSignUp){
            this.setState({regControls: updatedForm, formIsValid: formIsValid});
        }
        else{
            this.setState({loginControls: updatedForm, formIsValid: formIsValid});
        }
    }
    
    render(){
        const formElementsArray = [];
        if(this.state.isSignUp){
            for(let key in this.state.regControls){
                if(this.state.regControls[key].show !== false){
                    formElementsArray.push({
                        id: key,
                        config: this.state.regControls[key],
                        validation: this.state.regControls[key].validation,
                        valid: this.state.regControls[key].valid,
                        touched: this.state.regControls[key].touched
                    });
                }
            }
        }
        else{
            for(let key in this.state.loginControls){
                formElementsArray.push({
                    id: key,
                    config: this.state.loginControls[key],
                    validation: this.state.loginControls[key].validation,
                    valid: this.state.loginControls[key].valid,
                    touched: this.state.loginControls[key].touched
                });
            }
        }

        let form = (
            <form onSubmit={this.submitHandler}>
                    {formElementsArray.map(el => (
                        <Input 
                            key={el.id}
                            elementType={el.config.elementType}
                            elementConfig={el.config.elementConfig}
                            label={el.config.elementConfig.label}
                            value={el.config.value}
                            invalid = {!el.valid}
                            shouldValidate ={el.validation && el.touched}
                            changed={(event)=> this.inputChangedHandler(event, el.id)}/>
                    ))}
                        
                    
                    <Button disabled={!this.state.formIsValid} btnType="Danger">SUBMIT</Button>
                </form>
        );
        let signupButton = <Button btnType="Danger" clicked={this.switchSignUpHandler}>{this.state.isSignUp ? "Sign In Here!" : "Sign Up Here!"}</Button>
            
        if(this.props.loading){
            form = <Spinner />;
            signupButton = null;
        }
        let errorMessage = null;
        if(this.props.error){
            errorMessage = <span className={classes.Error}>{this.props.message}</span>
        }
        let redirect = null;
        if(!this.state.loading && this.props.isAuth && !this.state.allowRegister){
            redirect = <Redirect to={this.props.redirectPath}/>
        }

        return(
            <>
            {/*<div className={classes.Logo}>
                <img src={logo} />
        </div>*/}
             <div className={classes.Background}>
                 <div className={classes.Diamond}>

                <div className={classes.Auth}>
                    <h1>THE SCOUT BOOK</h1>
                    <h2>Baseball & Softball</h2>
                    
                    <h3>ONE PITCH AHEAD</h3>
                {redirect}
                
                <h4>{this.state.isSignUp ? "Register" : "Login"} with Your Contact Data</h4>
                {errorMessage}
                {form}
                {signupButton}
                </div>
                </div>
            </div>
            </>
        )
    }
}


const mapDispatchToProps = dispatch =>{
    return{
        onAuth: (email,password)=>dispatch(
            ActionTypes.auth(email,password)
        ),
        onRegister: (email,password, userObj, orgName, createOrg, teamType)=>dispatch(
            ActionTypes.register(
                email,
                password,
                userObj,
                orgName,
                createOrg,
                teamType
                )
        ),
        onGetOrgs: ()=>dispatch(
            ActionTypes.getOrgs()
        )

    }
}

const mapStateToProps = state =>{
    return{
        error: state.auth.error,
        message: state.auth.errorMessage,
        loading: state.auth.loading,
        isAuth: state.auth.token !== null,
        redirectPath: state.auth.authRedirectPath,
        orgs: state.org.orgs
    };
}

export default connect(mapStateToProps, mapDispatchToProps) (Auth);