export const GAME_START = 'GAME_START';


export const AUTH_RESET_SUCCESS = 'AUTH_RESET_SUCCESS';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_REFRESH = 'AUTH_REFRESH';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_RESET_FAIL = 'AUTH_RESET_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOAD_USER_SUCCESS = 'AUTH_LOAD_USER_SUCCESS';

export const SET_AUTH_REDIRECT = 'SET_AUTH_REDIRECT';

export const REG_START = 'REG_START';
export const REG_SUCCESS = 'REG_SUCCESS';
export const REG_FAIL = 'REG_FAIL';

export const CREATE_TEAM_START = 'CREATE_TEAM_START';
export const CREATE_TEAM_SUCCESS = 'CREATE_TEAM_SUCCESS';
export const CREATE_TEAM_FAIL = 'CREATE_TEAM_FAIL';

export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';

export const UPDATE_TEAM_START = 'UPDATE_TEAM_START';
export const UPDATE_TEAM_SUCCESS = 'UPDATE_TEAM_SUCCESS';
export const UPDATE_TEAM_FAIL = 'UPDATE_TEAM_FAIL';

export const GET_TEAMS_START = 'GET_TEAMS_START';
export const GET_TEAMS_SUCCESS = 'GET_TEAMS_SUCCESS';
export const GET_IMPORT_TEAMS_FAIL = 'GET_IMPORT_TEAMS_FAIL';
export const GET_IMPORT_TEAMS_START = 'GET_IMPORT_TEAMS_START';
export const GET_IMPORT_TEAMS_SUCCESS = 'GET_IMPORT_TEAMS_SUCCESS';
export const GET_TEAMS_FAIL = 'GET_TEAMS_FAIL';
export const CLONE_TEAM_SUCCESS = 'CLONE_TEAM_SUCCESS';

export const GET_TEAM_START = 'GET_TEAM_START';
export const GET_TEAM_SUCCESS = 'GET_TEAM_SUCCESS';
export const GET_TEAM_FAIL = 'GET_TEAM_FAIL';

export const GET_NCAA_TEAMS_SUCCESS = 'GET_NCAA_TEAMS_SUCCESS';

export const CREATE_PLAYER_START = 'CREATE_PLAYER_START';
export const CREATE_PLAYER_SUCCESS = 'CREATE_PLAYER_SUCCESS';
export const CREATE_PLAYER_FAIL = 'CREATE_PLAYER_FAIL';

export const UPDATE_PLAYER_START = 'UPDATE_PLAYER_START';
export const UPDATE_PLAYER_SUCCESS = 'UPDATE_PLAYER_SUCCESS';
export const UPDATE_PLAYER_FAIL = 'UPDATE_PLAYER_FAIL';

export const GET_TEAM_PLAYERS_START = 'GET_TEAM_PLAYERS_START';
export const GET_TEAM_PLAYERS_SUCCESS = 'GET_TEAM_PLAYERS_SUCCESS';
export const GET_TEAM_PLAYERS_FAIL = 'GET_TEAM_PLAYERS_FAIL';

export const CREATE_GAME_START = 'CREATE_GAME_START';
export const CREATE_GAME_SUCCESS = 'CREATE_GAME_SUCCESS';
export const CREATE_GAME_FAIL = 'CREATE_GAME_FAIL';
export const DELETE_GAME_SUCCESS = 'DELETE_GAME_SUCCESS';

export const GET_GAMES_START = 'GET_GAMES_START';
export const GET_GAMES_SUCCESS = 'GET_GAMES_SUCCESS';
export const GET_GAMES_FAIL = 'GET_GAMES_FAIL';

export const GET_GAME_START = 'GET_GAME_START';
export const GET_GAME_SUCCESS = 'GET_GAME_SUCCESS';
export const GET_GAME_FAIL = 'GET_GAME_FAIL';

export const INITIALIZE_GAME_START = 'INITIALIZE_GAME_START';
export const INITIALIZE_GAME_SUCCESS = 'INITIALIZE_GAME_SUCCESS';
export const INITIALIZE_GAME_FAIL = 'INITIALIZE_GAME_FAIL';

export const SET_GAME_OVER = 'SET_GAME_OVER';
export const SET_OUTS = 'SET_OUTS';
export const SET_CURRENT_INNING = 'SET_CURRENT_INNING';
export const SET_CURRENT_PITCHER = 'SET_CURRENT_PITCHER';
export const SET_CURRENT_BATTER = 'SET_CURRENT_BATTER';
export const SET_BATTING_RIGHT = 'SET_BATTING_RIGHT';
export const SET_PITCHES = 'SET_PITCHES';
export const SET_PITCH_NUMBER = 'SET_PITCH_NUMBER';
export const SET_HOME_SCORE = 'SET_HOME_SCORE';
export const SET_AWAY_SCORE = 'SET_AWAY_SCORE';
export const CLEAR_POP = 'CLEAR_POP';
export const SET_POP = 'SET_POP';

export const UPDATE_PITCHER_VIEW = 'UPDATE_PITCHER_VIEW';
export const UPDATE_HOME_SUBSTITUTIONS = 'UPDATE_HOME_SUBSTITUTIONS';
export const UPDATE_AWAY_SUBSTITUTIONS = 'UPDATE_AWAY_SUBSTITUTIONS';
export const SAVE_GAME_STATE = 'SAVE_GAME_STATE';
export const SET_GAME_NOTES = 'SET_GAME_NOTES';

export const CREATE_GAME_STATE_SUCCESS = 'CREATE_GAME_STATE_SUCCESS';
export const CREATE_GAME_STATE_START = 'CREATE_GAME_STATE_START';

export const UPDATE_GAME_STATE_SUCCESS = 'UPDATE_GAME_STATE_SUCCESS';

export const LOAD_GAME_STATE_START = 'LOAD_GAME_STATE_START';
export const LOAD_GAME_STATE_SUCCESS = 'LOAD_GAME_STATE_SUCCESS';
export const LOAD_GAME_STATE_FAIL = 'LOAD_GAME_STATE_FAIL';

export const LOAD_GAME_STATES_START = 'LOAD_GAME_STATES_START';
export const LOAD_GAME_STATES_SUCCESS = 'LOAD_GAME_STATES_SUCCESS';
export const LOAD_GAME_STATES_FAIL = 'LOAD_GAME_STATES_FAIL';

export const LOAD_GAME_GAME_STATE_START = 'LOAD_GAME_GAME_STATE_START';
export const LOAD_GAME_GAME_STATE_SUCCESS = 'LOAD_GAME_GAME_STATE_SUCCESS';
export const LOAD_GAME_GAME_STATE_FAIL = 'LOAD_GAME_GAME_STATE_FAIL';
export const SET_GAME_STATE ='SET_GAME_STATE';

export const UPDATE_GAME_STATE_PITCH_ARRAY = 'UPDATE_GAME_STATE_PITCH_ARRAY';
export const UPDATE_BASE_RUNNERS = 'UPDATE_BASE_RUNNERS';

export const SET_FIRST_BASE_RUNNER = 'SET_FIRST_BASE_RUNNER';
export const SET_SECOND_BASE_RUNNER = 'SET_SECOND_BASE_RUNNER';
export const SET_THIRD_BASE_RUNNER = 'SET_THIRD_BASE_RUNNER';

export const SET_AWAY_TEAM_CURRENT_PITCHER = 'SET_AWAY_TEAM_CURRENT_PITCHER';
export const SET_HOME_TEAM_CURRENT_PITCHER = 'SET_HOME_TEAM_CURRENT_PITCHER';
export const SET_AWAY_TEAM_LINEUP = 'SET_AWAY_TEAM_LINEUP';
export const SET_HOME_TEAM_LINEUP = 'SET_HOME_TEAM_LINEUP';
export const SET_AWAY_TEAM_FLEX = 'SET_AWAY_TEAM_FLEX';
export const SET_HOME_TEAM_FLEX = 'SET_HOME_TEAM_FLEX';

export const SET_AWAY_TEAM = 'SET_AWAY_TEAM';
export const SET_HOME_TEAM = 'SET_HOME_TEAM';
export const SET_TOTAL_INNINGS = 'SET_TOTAL_INNINGS';
export const SET_INNING_SCORES = 'SET_INNING_SCORES';
export const SET_CURRENT_TOP_INNING_SCORE = 'SET_CURRENT_TOP_INNING_SCORE';
export const SET_CURRENT_BOTTOM_INNING_SCORE = 'SET_CURRENT_BOTTOM_INNING_SCORE';
export const SET_AWAY_TEAM_PLAYERS = 'SET_AWAY_TEAM_PLAYERS';
export const SET_HOME_TEAM_PLAYERS = 'SET_HOME_TEAM_PLAYERS';

export const CLEAR_GAME_STATE = 'CLEAR_GAME_STATE';
export const SET_FORCE_QUIT = 'SET_FORCE_QUIT';

export const SET_HOME_TEAM_CURRENT_BATTER_INDEX = 'SET_HOME_TEAM_CURRENT_BATTER_INDEX';
export const SET_AWAY_TEAM_CURRENT_BATTER_INDEX = 'SET_AWAY_TEAM_CURRENT_BATTER_INDEX';





export const TRAINING_START = 'TRAINING_START';

export const CREATE_TRAINING_START = 'CREATE_TRAINING_START';
export const CREATE_TRAINING_SUCCESS = 'CREATE_TRAINING_SUCCESS';
export const CREATE_TRAINING_FAIL = 'CREATE_TRAINING_FAIL';

export const DELETE_TRAINING_SUCCESS = 'DELETE_TRAINING_SUCCESS';

export const GET_TRAININGS_START = 'GET_TRAININGS_START';
export const GET_TRAININGS_SUCCESS = 'GET_TRAININGS_SUCCESS';
export const GET_TRAININGS_FAIL = 'GET_TRAININGS_FAIL';

export const GET_TRAINING_START = 'GET_TRAINING_START';
export const GET_TRAINING_SUCCESS = 'GET_TRAINING_SUCCESS';
export const GET_TRAINING_FAIL = 'GET_TRAINING_FAIL';

export const INITIALIZE_TRAINING_START = 'INITIALIZE_TRAINING_START';
export const INITIALIZE_TRAINING_SUCCESS = 'INITIALIZE_TRAINING_SUCCESS';
export const INITIALIZE_TRAINING_FAIL = 'INITIALIZE_TRAINING_FAIL';


export const SAVE_TRAINING_STATE = 'SAVE_TRAINING_STATE';
export const SET_TRAINING_NOTES = 'SET_TRAINING_NOTES';

export const CREATE_TRAINING_STATE_SUCCESS = 'CREATE_TRAINING_STATE_SUCCESS';
export const UPDATE_TRAINING_STATE_SUCCESS = 'UPDATE_TRAINING_STATE_SUCCESS';

export const LOAD_TRAINING_STATE_START = 'LOAD_TRAINING_STATE_START';
export const LOAD_TRAINING_STATE_SUCCESS = 'LOAD_TRAINING_STATE_SUCCESS';
export const LOAD_TRAINING_STATE_FAIL = 'LOAD_TRAINING_STATE_FAIL';

export const LOAD_TRAINING_STATES_START = 'LOAD_TRAINING_STATES_START';
export const LOAD_TRAINING_STATES_SUCCESS = 'LOAD_TRAINING_STATES_SUCCESS';
export const LOAD_TRAINING_STATES_FAIL = 'LOAD_TRAINING_STATES_FAIL';

export const LOAD_TRAINING_TRAINING_STATE_START = 'LOAD_TRAINING_TRAINING_STATE_START';
export const LOAD_TRAINING_TRAINING_STATE_SUCCESS = 'LOAD_TRAINING_TRAINING_STATE_SUCCESS';
export const LOAD_TRAINING_TRAINING_STATE_FAIL = 'LOAD_TRAINING_TRAINING_STATE_FAIL';

export const UPDATE_TRAINING_STATE_PITCH_ARRAY = 'UPDATE_TRAINING_STATE_PITCH_ARRAY';

export const CLEAR_TRAINING_STATE = 'CLEAR_TRAINING_STATE';


export const GET_USER_FAIL = 'GET_USER_FAIL';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_START = 'GET_USER_START';

export const GET_USERS_FAIL = 'GET_USERS_FAIL';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_START = 'GET_USERS_START';

export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const UPDATE_AUTH_USER = 'UPDATE_AUTH_USER';

export const CREATE_ORG_START = 'CREATE_ORG_START';
export const CREATE_ORG_SUCCESS = 'CREATE_ORG_SUCCESS';
export const CREATE_ORG_FAIL = 'CREATE_ORG_FAIL';

export const UPDATE_ORG_START = 'UPDATE_ORG_START';
export const UPDATE_ORG_SUCCESS = 'UPDATE_ORG_SUCCESS';
export const UPDATE_ORG_FAIL = 'UPDATE_ORG_FAIL';

export const GET_ORG_START = 'GET_ORG_START';
export const GET_ORG_SUCCESS = 'GET_ORG_SUCCESS';
export const GET_ORG_FAIL = 'GET_ORG_FAIL';

export const GET_ORGS_START = 'GET_ORGS_START';
export const GET_ORGS_SUCCESS = 'GET_ORGS_SUCCESS';
export const GET_ORGS_FAIL = 'GET_ORGS_FAIL';


export const CREATE_GAME_STATE_ARRAY_SUCCESS = 'CREATE_GAME_STATE_ARRAY_SUCCESS';
export const CREATE_GAME_STATE_ARRAY_START = 'CREATE_GAME_STATE_ARRAY_START';

export const UPDATE_GAME_STATE_ARRAY_SUCCESS = 'UPDATE_GAME_STATE_ARRAY_SUCCESS';

export const LOAD_GAME_STATE_ARRAY_START = 'LOAD_GAME_STATE_ARRAY_START';
export const LOAD_GAME_STATE_ARRAY_SUCCESS = 'LOAD_GAME_STATE_ARRAY_SUCCESS';
export const LOAD_GAME_STATE_ARRAY_FAIL = 'LOAD_GAME_STATE_ARRAY_FAIL';



export const INITIALIZE_GAME_STATE_ARRAY_START = 'INITIALIZE_GAME_STATE_ARRAY_START';
export const INITIALIZE_GAME_STATE_ARRAY_FAIL = 'INITIALIZE_GAME_STATE_ARRAY_FAIL';
export const INITIALIZE_GAME_STATE_ARRAY_SUCCESS = 'INITIALIZE_GAME_STATE_ARRAY_SUCCESS';