import React from 'react';
import classes from './Team.module.css';


const Team = (props) =>{
    // const ingredients = [];
    // for(let ingredientName in props.order.ingredients){

    //     ingredients.push({
    //         name: ingredientName,
    //         amount: props.order.ingredients[ingredientName]
    //     })
    // }

    // const ingredientOutput = ingredients.map(ing=>{
    //     return (<span 
    //             style={{
    //                 textTransform: 'capitalize',
    //                 display: 'inline-block',
    //                 margin: '0 8px',
    //                 border: '1px solid #ccc',
    //                 padding: '5px'
    //             }}
    //             key={ing.name}>{ing.name} ({ing.amount})</span>);
    // })

    return (
        <div className={classes.Team} onClick={props.teamClick}>
            <p>{props.team.name} {props.team.year}</p>
        </div>
    )
}

export default Team;