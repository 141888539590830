import React from 'react';
import {NavLink, Link} from 'react-router-dom';
import classes from './NavigationItem.module.css';

const NavigationItem = (props) =>{

    let navLink = <NavLink exact to={props.link} activeClassName={classes.active}>
                    {props.children}
                  </NavLink>
   if(props.external){
    navLink = <a href={props.link}  target="_blank" >{props.children}</a>
   }

    return(
       <li className={classes.NavigationItem}>
        {navLink}
        
       </li>
    )
}

export default NavigationItem;