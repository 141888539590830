import * as actionTypes from './actionTypes';
import axios from '../../axios';
import axiosmap from 'axios';

export const createPlayerStart = () =>{
    return{
        type:actionTypes.CREATE_PLAYER_START
    };
}

export const createPlayerSuccess = (id, playerData) =>{
    return{
        type:actionTypes.CREATE_PLAYER_SUCCESS,
        id: id,
        playerData: playerData
    };
}

export const createPlayerFail = (error) =>{
    return{
        type:actionTypes.CREATE_PLAYER_FAIL,
        error: error
    };
}

export const createPlayer = (token, playerData) =>{
    return dispatch =>{
        dispatch(createPlayerStart());
        axios.post('players.json?auth=' + token, playerData)
            .then(response =>{
                ////console.log("id="+response.data.name);
                dispatch(createPlayerSuccess(response.data.name, playerData));
            })
            .catch(error =>{
                ////console.log(error.response);
                dispatch(createPlayerFail(error.response.data.error));
            });
    }
}


export const getTeamPlayersStart = () =>{
    return{
        type:actionTypes.GET_TEAM_PLAYERS_START
    };
}


export const getTeamPlayersSuccess = (payload) =>{
    return{
        type:actionTypes.GET_TEAM_PLAYERS_SUCCESS,
        players: payload
    };
}

export const getTeamPlayersFail = (error) =>{
    return{
        type:actionTypes.GET_TEAM_PLAYERS_FAIL,
        error:error
    }
}

export const getTeamPlayers = (token, teamId) =>{
    return dispatch =>{
        dispatch(getTeamPlayersStart());
        const queryParams = '?auth=' + token + '&orderBy="teamId"&equalTo="'+teamId+'"';
        axios.get('/players.json' + queryParams)
        .then(response => {
            let players = [];
            for(let key in response.data){
                players.push({
                    ...response.data[key],
                    id: key
                });
            }
            players = players.sort(function(obj1, obj2) {
                //sort by player number asc
                return obj1.number - obj2.number;
            });

            ////console.log('getTeamPlayers',players);
            dispatch(getTeamPlayersSuccess(players));
        })
        .catch(error =>{
            dispatch(getTeamPlayersFail());
        });
    }
}




///

export const updatePlayerStart = (id) =>{
    return{
        type:actionTypes.UPDATE_PLAYER_START,
        id: id
    };
}

export const updatePlayerSuccess = (id, playerData) =>{
    return{
        type:actionTypes.UPDATE_PLAYER_SUCCESS,
        id: id,
        playerData: playerData
    };
}

export const updatePlayerFail = (error) =>{
    return{
        type:actionTypes.UPDATE_PLAYER_FAIL,
        error: error
    };
}

export const updatePlayer = (token, playerData) =>{
    return dispatch =>{
        dispatch(updatePlayerStart(playerData.id));
        const playerid = playerData.id;
        // why did I put this in?  It screws up multiple updates delete playerData['id'];
        axios.patch('players/'+playerid+'.json?auth=' + token, playerData)
            .then(response =>{
                ////console.log("id="+response.data.name);
                dispatch(updatePlayerSuccess(playerid, playerData));
            })
            .catch(error =>{
                ////console.log(error.response);
                dispatch(updatePlayerFail(error.response.data.error));
            });
    }
}