import React from 'react';
import styles from './PitchTable.module.css';

const PitchTable = (props) =>{
    let FBPercent = 0;
    let XCHPercent = 0;
    let CRVPercent = 0;
    let DRPPercent = 0;
    let DPCPercent = 0;
    let RISPercent = 0;
    let SCRPercent = 0;

    

    /*
    0   0-0
    1   0-1
    2   0-2
    3   1-0
    4   1-1
    5   1-2
    6   2-0
    7   2-1
    8   2-2
    9   3-0
    10  3-1
    11  3-2

    FB
    XCHANGE
    CURVE
    DROPCV
    DROP
    RISE
    SCREW
    */
   let FBCount = getCount('FB', props.pitchArray);
   let XCHCount = getCount('XCHANGE',  props.pitchArray);
   let CRVCount = getCount('CURVE',  props.pitchArray);
   let DRPCount = getCount('DROP',  props.pitchArray);
   let DPCCount = getCount('DROPCV',  props.pitchArray);
   let RISCount = getCount('RISE',  props.pitchArray);
   let SCRCount = getCount('SCREW',  props.pitchArray);
   

   FBPercent = getPercent('FB', props.pitchArray);
   XCHPercent = getPercent('XCHANGE', props.pitchArray);
   CRVPercent = getPercent('CURVE', props.pitchArray);
   DRPPercent = getPercent('DROP', props.pitchArray);
   DPCPercent = getPercent('DROPCV', props.pitchArray);
   RISPercent = getPercent('RISE', props.pitchArray);
   SCRPercent = getPercent('SCREW', props.pitchArray);

   function getCount(type, pitchArray){
       let ret = new Array(12);
       for(let i=0;i<12;++i){
           ret[i] = 0;
       }
       for(let i=0;i<pitchArray.length; ++i){
        const p = pitchArray[i];
        if(p.pitchType === type){
            if(p.ballCount === 0 && p.strikeCount === 0){
                ret[0] = ret[0] + 1;
            }
            else if(p.ballCount === 0 && p.strikeCount === 1){
                ret[1] = ret[1] + 1;
            }
            else if(p.ballCount === 0 && p.strikeCount === 2){
                ret[2] = ret[2] + 1;
            }
            else if(p.ballCount === 1 && p.strikeCount === 0){
                ret[3] = ret[3] + 1;
            }
            else if(p.ballCount ===1 && p.strikeCount === 1){
                ret[4] = ret[4] + 1;
            }
            else if(p.ballCount ===1 && p.strikeCount === 2){
                ret[5] = ret[5] + 1;
            }
            else if(p.ballCount ===2 && p.strikeCount === 0){
                ret[6] = ret[6] + 1;
            }
            else if(p.ballCount ===2 && p.strikeCount === 1){
                ret[7] = ret[7] + 1;
            }
            else if(p.ballCount ===2 && p.strikeCount === 2){
                ret[8] = ret[8] + 1;
            }
            else if(p.ballCount ===3 && p.strikeCount === 0){
                ret[9] = ret[9] + 1;
            }
            else if(p.ballCount ===3 && p.strikeCount === 1){
                ret[10] = ret[10] + 1;
            }
            else if(p.ballCount ===3 && p.strikeCount ===2){
                ret[11] = ret[11] + 1;
            }
        }
    }
    return ret;
   }

    function getPercent(type, pitchArray){
        let ret = 0;
        for(let i=0;i<pitchArray.length; ++i){
            const p = pitchArray[i];
            if(p.pitchType === type){
                ret ++;
            }
        }
        if(pitchArray.length > 0){
            ret = ret / parseFloat(pitchArray.length);
            ret = (ret * 100).toFixed(0);
        }
        return ret;
    }

    let mincolor = {
        r: 142,
        g: 169,
        b: 219
    }

    let maxcolor = {
        r: 254,
        g: 153,
        b: 102
    }

    function getColor(p){
        //Calculate the average (r',g',b') = ( (r1+r2)/2, (g1+g2)/2, (b1+b2)/2 )
        let r = mincolor.r * parseFloat( 1.0 - p) + maxcolor.r * parseFloat(p);
        let g = mincolor.g * parseFloat( 1.0 - p) + maxcolor.g * parseFloat(p);
        let b = mincolor.b * parseFloat( 1.0 - p) + maxcolor.b * parseFloat(p);

        let ret = {
            r : r,
            g: g,
            b: b
        }
        return ret
    }

    function getColorStr(p){
        let color = getColor(p);
        return "rgb("+ color.r+","+color.g+","+color.b+")"
    }

    

    return (
        <div className={styles.PitchTable}>
        <table>
            <thead>
                <tr style={{backgroundColor: '#404040'}}>
                <td>PT</td>
                <td>%P</td>
                <td>0-0</td>
                <td>0-1</td>
                <td>0-2</td>
                <td>1-0</td>
                <td>1-1</td>
                <td>1-2</td>
                <td>2-0</td>
                <td>2-1</td>
                <td>2-2</td>
                <td>3-0</td>
                <td>3-1</td>
                <td>3-2</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterPitchType("FB")}>FB</td>
                    {/*<td style={FBPercent > 0 ? {backgroundColor:'white', color:'black'} : {}}>{FBPercent}</td>*/}
                    
                    <td style={{backgroundColor:getColorStr(FBPercent)}}>{FBPercent}</td>
                    <td style={{backgroundColor:getColorStr(FBCount[0])}}>{FBCount[0]}</td>
                    <td style={{backgroundColor:getColorStr(FBCount[1])}}>{FBCount[1]}</td>
                    <td style={{backgroundColor:getColorStr(FBCount[2])}}>{FBCount[2]}</td>
                    <td style={{backgroundColor:getColorStr(FBCount[3])}}>{FBCount[3]}</td>
                    <td style={{backgroundColor:getColorStr(FBCount[4])}}>{FBCount[4]}</td>
                    <td style={{backgroundColor:getColorStr(FBCount[5])}}>{FBCount[5]}</td>
                    <td style={{backgroundColor:getColorStr(FBCount[6])}}>{FBCount[6]}</td>
                    <td style={{backgroundColor:getColorStr(FBCount[7])}}>{FBCount[7]}</td>
                    <td style={{backgroundColor:getColorStr(FBCount[8])}}>{FBCount[8]}</td>
                    <td style={{backgroundColor:getColorStr(FBCount[9])}}>{FBCount[9]}</td>
                    <td style={{backgroundColor:getColorStr(FBCount[10])}}>{FBCount[10]}</td>
                    <td style={{backgroundColor:getColorStr(FBCount[11])}}>{FBCount[11]}</td>
                </tr>
                <tr>
                    <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterPitchType("XCHANGE")}>XCH</td>
                    <td style={{backgroundColor:getColorStr(XCHPercent)}}>{XCHPercent}</td>
                    <td style={{backgroundColor:getColorStr(XCHCount[0])}}>{XCHCount[0]}</td>
                    <td style={{backgroundColor:getColorStr(XCHCount[1])}}>{XCHCount[1]}</td>
                    <td style={{backgroundColor:getColorStr(XCHCount[2])}}>{XCHCount[2]}</td>
                    <td style={{backgroundColor:getColorStr(XCHCount[3])}}>{XCHCount[3]}</td>
                    <td style={{backgroundColor:getColorStr(XCHCount[4])}}>{XCHCount[4]}</td>
                    <td style={{backgroundColor:getColorStr(XCHCount[5])}}>{XCHCount[5]}</td>
                    <td style={{backgroundColor:getColorStr(XCHCount[6])}}>{XCHCount[6]}</td>
                    <td style={{backgroundColor:getColorStr(XCHCount[7])}}>{XCHCount[7]}</td>
                    <td style={{backgroundColor:getColorStr(XCHCount[8])}}>{XCHCount[8]}</td>
                    <td style={{backgroundColor:getColorStr(XCHCount[9])}}>{XCHCount[9]}</td>
                    <td style={{backgroundColor:getColorStr(XCHCount[10])}}>{XCHCount[10]}</td>
                    <td style={{backgroundColor:getColorStr(XCHCount[11])}}>{XCHCount[11]}</td>
                </tr>

                <tr>
                    <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterPitchType("CURVE")}>CRV</td>
                    <td style={{backgroundColor:getColorStr(CRVPercent)}}>{CRVPercent}</td>
                    <td style={{backgroundColor:getColorStr(CRVCount[0])}}>{CRVCount[0]}</td>
                    <td style={{backgroundColor:getColorStr(CRVCount[1])}}>{CRVCount[1]}</td>
                    <td style={{backgroundColor:getColorStr(CRVCount[2])}}>{CRVCount[2]}</td>
                    <td style={{backgroundColor:getColorStr(CRVCount[3])}}>{CRVCount[3]}</td>
                    <td style={{backgroundColor:getColorStr(CRVCount[4])}}>{CRVCount[4]}</td>
                    <td style={{backgroundColor:getColorStr(CRVCount[5])}}>{CRVCount[5]}</td>
                    <td style={{backgroundColor:getColorStr(CRVCount[6])}}>{CRVCount[6]}</td>
                    <td style={{backgroundColor:getColorStr(CRVCount[7])}}>{CRVCount[7]}</td>
                    <td style={{backgroundColor:getColorStr(CRVCount[8])}}>{CRVCount[8]}</td>
                    <td style={{backgroundColor:getColorStr(CRVCount[9])}}>{CRVCount[9]}</td>
                    <td style={{backgroundColor:getColorStr(CRVCount[10])}}>{CRVCount[10]}</td>
                    <td style={{backgroundColor:getColorStr(CRVCount[11])}}>{CRVCount[11]}</td>
                </tr>
                

                <tr>
                    <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterPitchType("DROP")}>DRP</td>
                    <td style={{backgroundColor:getColorStr(DRPPercent)}}>{DRPPercent}</td>
                    <td style={{backgroundColor:getColorStr(DRPCount[0])}}>{DRPCount[0]}</td>
                    <td style={{backgroundColor:getColorStr(DRPCount[1])}}>{DRPCount[1]}</td>
                    <td style={{backgroundColor:getColorStr(DRPCount[2])}}>{DRPCount[2]}</td>
                    <td style={{backgroundColor:getColorStr(DRPCount[3])}}>{DRPCount[3]}</td>
                    <td style={{backgroundColor:getColorStr(DRPCount[4])}}>{DRPCount[4]}</td>
                    <td style={{backgroundColor:getColorStr(DRPCount[5])}}>{DRPCount[5]}</td>
                    <td style={{backgroundColor:getColorStr(DRPCount[6])}}>{DRPCount[6]}</td>
                    <td style={{backgroundColor:getColorStr(DRPCount[7])}}>{DRPCount[7]}</td>
                    <td style={{backgroundColor:getColorStr(DRPCount[8])}}>{DRPCount[8]}</td>
                    <td style={{backgroundColor:getColorStr(DRPCount[9])}}>{DRPCount[9]}</td>
                    <td style={{backgroundColor:getColorStr(DRPCount[10])}}>{DRPCount[10]}</td>
                    <td style={{backgroundColor:getColorStr(DRPCount[11])}}>{DRPCount[11]}</td>
                </tr>

                <tr>
                    <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterPitchType("DROPCV")}>DPC</td>
                    <td style={{backgroundColor:getColorStr(DPCPercent)}}>{DPCPercent}</td>
                    <td style={{backgroundColor:getColorStr(DPCCount[0])}}>{DPCCount[0]}</td>
                    <td style={{backgroundColor:getColorStr(DPCCount[1])}}>{DPCCount[1]}</td>
                    <td style={{backgroundColor:getColorStr(DPCCount[2])}}>{DPCCount[2]}</td>
                    <td style={{backgroundColor:getColorStr(DPCCount[3])}}>{DPCCount[3]}</td>
                    <td style={{backgroundColor:getColorStr(DPCCount[4])}}>{DPCCount[4]}</td>
                    <td style={{backgroundColor:getColorStr(DPCCount[5])}}>{DPCCount[5]}</td>
                    <td style={{backgroundColor:getColorStr(DPCCount[6])}}>{DPCCount[6]}</td>
                    <td style={{backgroundColor:getColorStr(DPCCount[7])}}>{DPCCount[7]}</td>
                    <td style={{backgroundColor:getColorStr(DPCCount[8])}}>{DPCCount[8]}</td>
                    <td style={{backgroundColor:getColorStr(DPCCount[9])}}>{DPCCount[9]}</td>
                    <td style={{backgroundColor:getColorStr(DPCCount[10])}}>{DPCCount[10]}</td>
                    <td style={{backgroundColor:getColorStr(DPCCount[11])}}>{DPCCount[11]}</td>
                </tr>

                <tr>
                    <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterPitchType("RISE")}>RIS</td>
                    <td style={{backgroundColor:getColorStr(RISPercent)}}>{RISPercent}</td>
                    <td style={{backgroundColor:getColorStr(RISCount[0])}}>{RISCount[0]}</td>
                    <td style={{backgroundColor:getColorStr(RISCount[1])}}>{RISCount[1]}</td>
                    <td style={{backgroundColor:getColorStr(RISCount[2])}}>{RISCount[2]}</td>
                    <td style={{backgroundColor:getColorStr(RISCount[3])}}>{RISCount[3]}</td>
                    <td style={{backgroundColor:getColorStr(RISCount[4])}}>{RISCount[4]}</td>
                    <td style={{backgroundColor:getColorStr(RISCount[5])}}>{RISCount[5]}</td>
                    <td style={{backgroundColor:getColorStr(RISCount[6])}}>{RISCount[6]}</td>
                    <td style={{backgroundColor:getColorStr(RISCount[7])}}>{RISCount[7]}</td>
                    <td style={{backgroundColor:getColorStr(RISCount[8])}}>{RISCount[8]}</td>
                    <td style={{backgroundColor:getColorStr(RISCount[9])}}>{RISCount[9]}</td>
                    <td style={{backgroundColor:getColorStr(RISCount[10])}}>{RISCount[10]}</td>
                    <td style={{backgroundColor:getColorStr(RISCount[11])}}>{RISCount[11]}</td>
                </tr>

                <tr>
                    <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterPitchType("SCREW")}>SCR</td>
                    <td style={{backgroundColor:getColorStr(SCRPercent)}}>{SCRPercent}</td>
                    <td style={{backgroundColor:getColorStr(SCRCount[0])}}>{SCRCount[0]}</td>
                    <td style={{backgroundColor:getColorStr(SCRCount[1])}}>{SCRCount[1]}</td>
                    <td style={{backgroundColor:getColorStr(SCRCount[2])}}>{SCRCount[2]}</td>
                    <td style={{backgroundColor:getColorStr(SCRCount[3])}}>{SCRCount[3]}</td>
                    <td style={{backgroundColor:getColorStr(SCRCount[4])}}>{SCRCount[4]}</td>
                    <td style={{backgroundColor:getColorStr(SCRCount[5])}}>{SCRCount[5]}</td>
                    <td style={{backgroundColor:getColorStr(SCRCount[6])}}>{SCRCount[6]}</td>
                    <td style={{backgroundColor:getColorStr(SCRCount[7])}}>{SCRCount[7]}</td>
                    <td style={{backgroundColor:getColorStr(SCRCount[8])}}>{SCRCount[8]}</td>
                    <td style={{backgroundColor:getColorStr(SCRCount[9])}}>{SCRCount[9]}</td>
                    <td style={{backgroundColor:getColorStr(SCRCount[10])}}>{SCRCount[10]}</td>
                    <td style={{backgroundColor:getColorStr(SCRCount[11])}}>{SCRCount[11]}</td>
                </tr>

            </tbody>
        </table>
        </div>
    )
}

export default PitchTable;