import React from 'react';
import classes from './PitchZoneArea.module.css';


const PitchZoneArea = (props) =>{

    function onFilterZone(val){
        //console.log('PitchZoneArea->filterZone', val);
        props.onFilterZone(val);
    }

    return(
        <div className={classes.PitchZoneArea} style={{backgroundColor:props.color}} onClick={()=>onFilterZone(props.zone)}>
            <span>{props.value}</span>
        </div>
    );
}

export default PitchZoneArea;