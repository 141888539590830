import React, {Component} from 'react';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import Spinner from '../../../components/UI/Spinner/Spinner';
import classes from './TeamForm.module.css';
import { connect } from 'react-redux';


class TeamForm extends Component{
    state = {
        controls: {
            primary:{
                
                elementType: 'input',
                elementConfig: {
                    
                    label: 'Primary Team',
                    type: 'checkbox',
                    
                    
                },
                value:false ,
                validation: {},
                valid:true
            },
            name: {
                elementType: 'react-select',
                elementConfig: {
                    
                   /* label: 'Team',*/
                    options : []
                    
                },
                value:'new',
                validation: {},
                valid:true
            },
            newName: {
                elementType: 'input',
                elementConfig: {
                    /*label: 'Team Name',*/
                    type: 'text',
                    placeholder: 'Team Name'
                },
                show:false,
                value: '',
                validation: {
                    
                },
                valid: true,
                touched: false
            },
            pitchType:{
                
                elementType: 'select',
                elementConfig: {
                    label: 'Team Type',
                    options : [
                        {value: 'Softball', displayValue: 'Softball'},
                        {value: 'Baseball', displayValue: 'Baseball'}
                    ]
                    
                },
                value:'Softball',
                validation: {},
                valid:true,
                disabled:true
            },
            year: {
                elementType: 'input',
                elementConfig: {
                    /*label: 'Year',*/
                    type: 'text',
                    placeholder: 'Year'
                },
                value: '',
                validation: {
                    required: true,
                    isNumeric: true,
                    minLength: 4,
                    maxLength: 4
                },
                valid: false,
                touched: false
            },
            id: {
                elementType: 'input',
                elementConfig: {
                    /*label: 'External ID',*/
                    type: 'text',
                    placeholder: 'External ID'
                },
                value: '',
                validation: {
                    required: false
                },
                valid: true,
                touched: false
            },
            
            
        },
        formIsValid: false,
        id: null,
        
    }

    componentDidMount(){
        
        //this.props.onGetNCAATeams();
        

        if(this.props.auser){

            const updatedForm = {
                ...this.state.controls
            }

            const updatedFormName = {...updatedForm['name']};
            //updatedFormOrgs.value = this.props.auser.organizationId;//this.props.orgs[0].id;
            let updatedOptions = [];
            let option = {
                value: 'new',
                label: 'New'
            }
            updatedOptions.push(option);
            
            //THIS IS MAKING IT SLOW AS FUCK
            //for(let i=0; i < this.props.ncaaTeams.length; ++i){
            for(let i=0; i < this.props.ncaaTeams.length; ++i){    
                let _option = {
                    value: this.props.ncaaTeams[i].id,
                    label: this.props.ncaaTeams[i].team
                }
                updatedOptions.push(_option);
            }

            

            /*updatedOptions = this.props.ncaaTeams.map(function(team){
                return (
                    {
                        value: team.id,
                        displayValue: team.team
                    }
                )
            })*/
            updatedFormName.elementConfig.options = updatedOptions;
            updatedFormName.valid = true;
            updatedFormName.value = {value: "new", label:"New"};
            

            const updatedFormNewTeam = {...updatedForm['newName']};
            updatedFormNewTeam.show = true;

            if(this.props.auser.org){
                //console.log('TeamForm->componentDidMount: updating pitch type to ' + this.props.auser.org.teamType);
                const updatedFormPitchType = {...updatedForm['pitchType']};
                updatedFormPitchType.value =this.props.auser.org.teamType;
                updatedForm['pitchType'] = updatedFormPitchType;
            }

            updatedForm['name'] = updatedFormName;
            updatedForm['newName'] = updatedFormNewTeam;
            
            let formIsValid = true;
            for(let inputIds in updatedForm){
                formIsValid = updatedForm[inputIds].valid && formIsValid;
            }

            //console.log('TeamForm->componentDidMount: controls', updatedForm);
            this.setState({controls: updatedForm, formIsValid: formIsValid});

        }
        if(this.props.team){
            ////console.log(this.props.team.name + ' ' + this.props.team.year + ' '+ this.props.team.id);
            
            const updatedForm = {
                ...this.state.controls
            }
            const updatedFormName = {...updatedForm['name']};
            const updatedFormNewName = {...updatedForm['newName']};
            const updatedFormPrimary = {...updatedForm['primary']};
            const updatedFormID = {...updatedForm['id']};

            if(this.props.auser.org){
                //console.log('TeamForm->componentDidMount: updating pitch type to ' + this.props.auser.org.teamType);
                const updatedFormPitchType = {...updatedForm['pitchType']};
                updatedFormPitchType.value =this.props.auser.org.teamType;
                updatedForm['pitchType'] = updatedFormPitchType;
            }
            
            let teamIndex = this.props.ncaaTeams.findIndex(x=>x.team === this.props.team.name);
            if(teamIndex >= 0){
                const team = this.props.ncaaTeams[teamIndex];
                //console.log('TEAM TEAM', team);
                updatedFormName.value = {
                    value: team.id,
                    label: team.team
                };
                updatedFormName.valid = this.checkValidity(updatedFormName.value, updatedFormName.validation);
                updatedFormNewName.show = false;
                updatedFormID.show = false;
                updatedFormID.value = team.id;
            }else{
                updatedFormName.value = {value: "new", label:"New"};
                updatedFormNewName.value = this.props.team.name;
                updatedFormNewName.valid = this.checkValidity(updatedFormNewName.value, updatedFormNewName.validation);
                updatedFormNewName.show = true;
                updatedFormID.show = true;
                updatedFormID.value = this.props.team.externalid;
                updatedFormID.valid = this.checkValidity(updatedFormID.value, updatedFormID.validation);
        
            }

            
            updatedFormPrimary.value = this.props.team.primary;
            updatedFormPrimary.valid = this.checkValidity(updatedFormPrimary.value, updatedFormPrimary.validation);
            if(this.props.team.cloned){
                updatedFormPrimary.show = false;
            }

            const updatedFormYear = {...updatedForm['year']};
            updatedFormYear.value = this.props.team.year;
            updatedFormYear.valid = this.checkValidity(updatedFormYear.value, updatedFormYear.validation);

            
            updatedForm['name'] = updatedFormName;
            updatedForm['year'] = updatedFormYear;
            updatedForm['id'] = updatedFormID;
            updatedForm['primary'] = updatedFormPrimary;
            updatedForm['newName'] = updatedFormNewName;
            

            let formIsValid = true;
            for(let inputIds in updatedForm){
                formIsValid = updatedForm[inputIds].valid && formIsValid;
            }
            this.setState({controls: updatedForm, formIsValid: formIsValid, id: this.props.team.id});
        }
    }

    componentDidUpdate(prevProps, prevState){
         if(prevProps.auser !== this.props.auser){

            const updatedForm = {
                ...this.state.controls
            }
            //console.log('TeamForm->componentDidUpdate: updating pitch type to ' + this.props.auser.org.teamType);
            if(this.props.auser.org){
                
                const updatedFormPitchType = {...updatedForm['pitchType']};
                updatedFormPitchType.value =this.props.auser.org.teamType;
                updatedForm['pitchType'] = updatedFormPitchType;
            }

            let formIsValid = true;
            for(let inputIds in updatedForm){
                formIsValid = updatedForm[inputIds].valid && formIsValid;
            }
            this.setState({controls: updatedForm, formIsValid: formIsValid, id: this.props.team.id});
         }

        /*if(prevProps.auser !== this.props.auser || prevState.teams !== this.state.teams){
            if(this.props.auser){

                const updatedForm = {
                    ...this.state.controls
                }
    
                const updatedFormName = {...updatedForm['name']};
                //updatedFormOrgs.value = this.props.auser.organizationId;//this.props.orgs[0].id;
                let updatedOptions = [];
                let team;
                let option = {
                    value: 'new',
                    displayValue: 'New'
                }
                updatedOptions.push(option);
                //console.log('UPDATE THIS STATE TEAMS', this.props.ncaaTeams)
                for(team in this.props.ncaaTeams){
                    let option = {
                        value: this.props.ncaaTeams[team].id,
                        displayValue: this.props.ncaaTeams[team].team
                    }
                    updatedOptions.push(option);
                }
                updatedFormName.elementConfig.options = updatedOptions;
                updatedFormName.valid = true;
                updatedFormName.value = '';//this.props.team.externalId;
                //console.log('set team name id to: ' + updatedFormName.value );
    
                const updatedFormNewTeam = {...updatedForm['newTeam']};
                updatedFormNewTeam.show = false;
    
    
                updatedForm['name'] = updatedFormName;
                updatedForm['newName'] = updatedFormNewTeam;
                
                let formIsValid = true;
                for(let inputIds in updatedForm){
                    formIsValid = updatedForm[inputIds].valid && formIsValid;
                }
                this.setState({controls: updatedForm, formIsValid: formIsValid});
    
            }
        }*/
    }

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }
        
        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        if (rules.isNumeric) {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }


    inputChangedHandler = (event, inputIdentifier) =>{

        const updatedForm = {
            ...this.state.controls
        }
        let targetValue = null;

        if(inputIdentifier == 'name'){
            //console.log('name event = ', event);
            if(event){
                const updatedIDFormElement = {...updatedForm['id']};
                const updatedNewNameFormElement = {...updatedForm['newName']};
                
                
                targetValue = event;
                if(targetValue.value !== 'new'){
                    //console.log('hide external id and team name ' + targetValue.value);
                    updatedIDFormElement.value = targetValue.value;
                    updatedIDFormElement.show = false;
                    
                    updatedNewNameFormElement.show = false;

                }else{
                    updatedIDFormElement.show = true;
                    updatedNewNameFormElement.show = true;

                }
                
                updatedForm['id'] = updatedIDFormElement;
                updatedForm['newName'] = updatedNewNameFormElement;

                // let formIsValid = true;
                // for(let inputIds in updatedForm){
                //     formIsValid = updatedForm[inputIds].valid && formIsValid;
                // }
                // this.setState({controls: updatedForm, formIsValid: formIsValid});
            }
            else{
                return;
            }
        }
        else{
            targetValue = event.target.value;
        }

        
        
        //console.log('inputChangeHandler event', targetValue);
       
        const updatedFormElement = {...updatedForm[inputIdentifier]};
        if(updatedFormElement.elementConfig.type === 'checkbox'){
            updatedFormElement.value = !updatedFormElement.value;
            
        }
        else{
            updatedFormElement.value = targetValue;
        }
        
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        ////console.log(updatedFormElement.valid, updatedFormElement.validation ? true : false);
        updatedForm[inputIdentifier] = updatedFormElement;

        let formIsValid = true;
        for(let inputIds in updatedForm){
            //console.log(inputIds + ' ' + updatedForm[inputIds].valid);
            formIsValid = updatedForm[inputIds].valid && formIsValid;
        }
        this.setState({controls: updatedForm, formIsValid: formIsValid});
    }

    submitHandler = (event) =>{
        event.preventDefault();
        ////console.log('calling submitHandler in TeamForm ' 
        
        //+ this.state.controls.name.value+' '
        //+ this.state.controls.year.value);

        let teamName = '';
        let teamId = '';
        //console.log('this.state.controls.name.value ', this.state.controls.name.value);
        if(this.state.controls.name.value.value === 'new'){
            //console.log('new team name = ' + this.state.controls.newName.value);
            teamName = this.state.controls.newName.value;
            teamId = this.state.controls.id.value;
        }
        else{
            let teamIndex = this.props.ncaaTeams.findIndex(x=>x.id === parseInt(this.state.controls.name.value.value));
            //console.log('teamIndex = ' + teamIndex);
            const team = this.props.ncaaTeams[teamIndex];
            teamName = team.team;
            teamId = team.id;
            //console.log(team);
            //batterIndex = this.props.awayTeamPlayers.findIndex(batter =>batter.id === this.props.currentHitter.id);
            //console.log('teamIndex ' + teamIndex);
        }
        let isPrimary = this.state.controls.primary.value;
        let year = this.state.controls.year.value;

        const teamData = {
            name: teamName,
            year: year,
            externalid: teamId,
            primary: isPrimary,
            id: this.state.id,
            teamType: this.state.controls.pitchType.value
        }
        //console.log("teamData", teamData);
        this.props.submit(event, teamData);
    }


    render(){

        const formElementsArray = [];
        for(let key in this.state.controls){
            if(this.state.controls[key].show !== false){
                formElementsArray.push({
                    id: key,
                    disabled: this.state.controls[key].disabled,
                    config: this.state.controls[key],
                    validation: this.state.controls[key].validation,
                    valid: this.state.controls[key].valid,
                    touched: this.state.controls[key].touched
                });
            }
        }

        let form = (
            <form onSubmit={(event)=>this.submitHandler(event)}>
                    {formElementsArray.map(el => 
                        
                    (
                        <Input 
                            key={el.id}
                            disabled={el.disabled}
                            elementType={el.config.elementType}
                            elementConfig={el.config.elementConfig}
                            label={el.config.elementConfig.label}
                            value={el.config.value}
                            invalid = {!el.valid}
                            styleType = {el.id === 'primary' ? 'inline' : null}
                            shouldValidate ={el.validation && el.touched}
                            changed={(event)=> this.inputChangedHandler(event, el.id)}/>
                    ))}
                        
                    
                    <Button disabled={!this.state.formIsValid} btnType="Success">SUBMIT</Button>
                </form>
        );

        if(this.props.loading){
            form = <Spinner />;
        }
        let message = null;
        if(this.props.error){
            message = <span className={classes.Error}>{this.props.message}</span>
        }
        else if(this.props.message !== null){
            message = <span className={classes.Success}>{this.props.message}</span>
        }


        return (
            <div className={classes.TeamForm}>
                <p>If you are an NCAA member institution, please select from the list.  If not, Click "New".  </p>
                {/*<h4>{this.props.team === null? "Create Team" : "Update Team"}</h4>*/}
                {message}
                {form}
            </div>
        )
    }
}
const mapDispatchToProps = dispatch =>{
    return{
        /*onGetNCAATeams: (token, payload)=>dispatch(
            ActionTypes.getNCAATeams()
        )*/
    }
}


const mapStateToProps = state =>{
    return{
        error: state.team.error,
        message: state.team.message,
        loading: state.team.loading,
        token: state.auth.token,
        teams: state.team.teams,
        auser: state.auth.user,
        ncaaTeams: state.team.ncaaTeams,
        ncaaTeamOptions: state.team.ncaaTeamOptions
    };
}

export default connect(mapStateToProps, mapDispatchToProps) (TeamForm);
