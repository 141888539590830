
import React from 'react';
import classes from './Lineup.module.css';

const Lineup = (props) =>{

    //let players = [];
    let baseRunnerIndices = [];

    let topRow = [];
    let bottomRow = [];
    // topRow.push(<td>Order</td>);
    // bottomRow.push(<td>Roster</td>)
    for(let i=0;i<props.lineup.length;++i){
        const player = props.lineup[i];
        let baseRunner = props.baseRunners.find(p => p.batter.id === player.id);

        if(typeof baseRunner !== 'undefined'){
            //let baseRunnerIndex = 0;
            for(let j=0;j<props.baseRunners.length; ++j){
                if(props.baseRunners[j] === baseRunner){
                    //baseRunnerIndex = j;
                    baseRunnerIndices.push(props.baseRunners[j].id);

                    if(props.handleReconcileBaseRunner){
                    topRow.push(<td onClick={() => props.handleReconcileBaseRunner(baseRunner)} className={classes.BaseRunner} key={'toprow-'+i}>{i+1}</td>);
                    bottomRow.push(<td onClick={() => props.handleReconcileBaseRunner(baseRunner)} className={classes.BaseRunner}  key={'toprow-'+i}> OB<br/> #{baseRunner.batter.number}<br/>{baseRunner.batter.lastName.substr(0,5)}</td>)
                    }else{
                        topRow.push(<td className={classes.BaseRunner} key={'toprow-'+i}>{i+1}</td>);
                        bottomRow.push(<td className={classes.BaseRunner}  key={'toprow-'+i}> OB<br/> #{baseRunner.batter.number}<br/>{baseRunner.batter.lastName.substr(0,5)}</td>)
                    
                    }
                }
                
            }
        }
        else{
            if(props.index === i){
                if(props.handleSub){
                    topRow.push(<td onClick={() => props.handleSub(player,i)} className={classes.Current} key={'toprow-'+i}>{i+1}</td>);
                    bottomRow.push(<td onClick={() => props.handleSub(player,i)} className={classes.Current} key={'toprow-'+i}>#{player.number} - {player.currentPosition}<br/>{player.lastName.substr(0,5)}</td>)
                }else{
                    topRow.push(<td className={classes.Current} key={'toprow-'+i}>{i+1}</td>);
                    bottomRow.push(<td className={classes.Current} key={'toprow-'+i}>#{player.number} - {player.currentPosition}<br/>{player.lastName.substr(0,5)}</td>)
                }
            }
            else{
                if(props.handleSub){
                    topRow.push(<td onClick={() => props.handleSub(player,i)} className={classes.LineupPlayer} key={'toprow-'+i}>{i+1}</td>);
                    bottomRow.push(<td onClick={() => props.handleSub(player,i)} className={classes.LineupPlayer} key={'bottomrow-'+i}>#{player.number} - {player.currentPosition}<br/>{player.lastName.substr(0,5)}</td>)
                }
                else{
                    topRow.push(<td className={classes.LineupPlayer} key={'toprow-'+i}>{i+1}</td>);
                    bottomRow.push(<td className={classes.LineupPlayer} key={'bottomrow-'+i}>#{player.number} - {player.currentPosition}<br/>{player.lastName.substr(0,5)}</td>)
                        
                }
            }
        }
    }
    if(props.flex){
        if(props.handleSub){    
            topRow.push(<td onClick={() => props.handleSub(props.flex,-1)} className={classes.LineupPlayer} key={'toprow-flex'}>FLEX</td>);
            bottomRow.push(<td onClick={() => props.handleSub(props.flex,-1)} className={classes.LineupPlayer} key={'bottomrow-flex'}>#{props.flex.number}<br/>{props.flex.lastName.substr(0,5)}</td>)
        }
        else{
            topRow.push(<td  className={classes.LineupPlayer} key={'toprow-flex'}>FLEX</td>);
            bottomRow.push(<td  className={classes.LineupPlayer} key={'bottomrow-flex'}>#{props.flex.number}<br/>{props.flex.lastName.substr(0,5)}</td>)
        }
    }

    

    return (
        <div className={classes.Lineup}>
            <h3>{props.team.name} Lineup</h3>
            <table>
                <thead>
                    <tr style={{backgroundColor: '#404040'}}>
                        {topRow}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {bottomRow}
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default Lineup;
