import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware, compose, combineReducers} from 'redux';

import thunk from 'redux-thunk';

import gameReducer from './store/reducers/game';
import authReducer from './store/reducers/auth';
import teamReducer from './store/reducers/team';
import playerReducer from './store/reducers/player';
import atBatReducer from './store/reducers/atbat';
import trainingReducer from './store/reducers/training';
import userReducer from './store/reducers/user';
import orgReducer from './store/reducers/organization';
import stateArrayReducer from './store/reducers/gameStateArray';


//import SeasonClass from './/classes/Softball/Season';
//import GameClass from './classes/Softball/Game';
//import TeamClass from './classes/Softball/Team';
//import AtBatClass from './classes/Softball/AtBat';

const rootReducer = combineReducers({
   game: gameReducer,
   auth: authReducer,
   team: teamReducer,
   player: playerReducer,
   atBat: atBatReducer,
   training: trainingReducer,
   user: userReducer,
   org: orgReducer,
   stateArray: stateArrayReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, /* preloadedState, */ composeEnhancers(
    applyMiddleware(thunk)
    ));

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
