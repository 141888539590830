import React from 'react';
import styles from './Modal.module.css';
import Aux from '../../../hoc/Aux';
import Backdrop from '../Backdrop/Backdrop';

const Modal =  (props) => {

    let style = styles.Modal;
    if(props.small === true){
        style = styles.ModalSmall;
    }
    
    return(
        <Aux>
            <Backdrop show={props.show} clickBackdrop={props.clickBackdrop}/>
        <div className={style}
            style={{
                transform: props.show ? 'translateY(0)' : 'translateY(-400vh)',
                opacity:  props.show ? '1' : '0',
                zIndex: props.topMost ? '600' : '500'
            }}
        >
            {props.children}
        </div>
        </Aux>
    )
}

export default Modal;