import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as ActionTypes from '../../store/actions/index';
import styles from './PlayerAnalysis.module.css';
import Button from '../../components/UI/Button/Button';
import PlayerSelector from '../PlayerSelector/PlayerSelector';
import PlayerStats from '../../components/GameState/PlayerStats/PlayerStats';
import PitchPlotter from './PitchPlotter/PitchPlotter';
import FieldPlotter from './FieldPlotter/FieldPlotter';
import PitchTable from './PitchTable/PitchTable';
import PitchTableBaseball from './PitchTable/PitchTableBaseball';
import Pitch from './PitchPlotter/Pitch/Pitch';
import PitchClass from '../../classes/Softball/Pitch';
import ZonesTable from './ZonesTable/ZonesTable';
import PitchTypesTable from './PitchTypesTable/PitchTypesTable';
import Modal from '../../components/UI/Modal/Modal';




class PlayerAnalysis extends Component{
    state = {

        isHomeTeam : true,
        currentBatter: null,
        batterIsNull: false,
        pitcherIsNull: false,
        currentPitcher:null,
        initialized: false,
        showPitcherBatters: 0, //0 - all, 1- right, 2- left,
        plateAppearance: 0, //0 - all

        filterPitcherPitches: "ALL",
        filterBatterPitches: "ALL",
        filterPitcherPitchLocation: "ALL",
        filterPitcherPitchType: "ALL",
        filterBatterPitchType: "ALL",
        showPitcherNotes: false,
        pitcherNotes: null,
        showBatterNotes: false,
        batterNotes: null,
        
    }

    componentDidMount(){
        //this.props.onLoadGame(this.props.token, this.props.match.params.id);
        //////console.log(this.props.match.path);
        if(this.props.match.path === '/player_training_analysis/:id'){
            //////console.log('load training data')
            this.props.onLoadTrainingState(this.props.token, this.props.match.params.id);
        }
        else{
            //////console.log('load game data')
            this.props.onLoadGameState(this.props.token, this.props.match.params.id);
        }
        
    }

    componentDidUpdate(prevProps, prevState){
        const isTop = this.isInningTop();
        if(!this.state.initialized && isTop && this.state.isHomeTeam){
            this.setState({
                initialized: true,
                isHomeTeam: false
            })
        }
        else if(prevProps.currentInning !== this.props.currentInning){
            if(!isTop && !this.state.isHomeTeam){
                this.setState({
                    initialized: true,
                    isHomeTeam: true
                })
            }
        }

        if(!this.state.batterIsNull && (this.props.currentHitter && !this.state.currentBatter ||
            this.props.currentHitter && this.state.currentBatter && this.props.currentHitter.id !== this.state.currentBatter.id)){
          //      alert('batter not equal');
        }
        
        if(this.props.currentHitter && !this.state.currentBatter && !this.state.batterIsNull){
            let batterNotes=  '';
            if(isTop){
                let index = this.props.rootAwayTeamPlayers.findIndex(player=>player.id === this.props.currentHitter.id);
                ////console.log('first if index ' + index);
                if(index >=0){
                    ////console.log('batter notes ' + this.props.rootAwayTeamPlayers[index].notes);
                    batterNotes = this.props.rootAwayTeamPlayers[index].notes ? this.props.rootAwayTeamPlayers[index].notes : ''; 
                }
            }
            else{
                
                let index = this.props.rootHomeTeamPlayers.findIndex(player=>player.id === this.props.currentHitter.id);
                ////console.log('else if index ' + index);
                if(index >=0){
                    ////console.log('batter notes ' + this.props.rootHomeTeamPlayers[index].notes);
                    batterNotes = this.props.rootHomeTeamPlayers[index].notes ? this.props.rootHomeTeamPlayers[index].notes : ''; 
                }
            }
            this.setState({
                currentBatter: this.props.currentHitter,
                //batterNotes: batterNotes
            })
        }

        if(!this.props.training && this.props.currentPitcher && !this.state.currentPitcher && !this.state.pitcherIsNull){
            let pitcherNotes = '';
            if(isTop){
                let index = this.props.rootHomeTeamPlayers.findIndex(player=>player.id === this.props.homeTeamCurrentPitcher.id);
                if(index >= 0)
                {
                    pitcherNotes = this.props.rootHomeTeamPlayers[index].notes ? this.props.rootHomeTeamPlayers[index].notes : '';
                }
            }
            else{
                let index = this.props.rootAwayTeamPlayers.findIndex(player=>player.id === this.props.rootAwayTeamPlayers.id);
                if(index >= 0)
                {
                    pitcherNotes = this.props.rootAwayTeamPlayers[index].notes ? this.props.rootAwayTeamPlayers[index].notes : '';
                }
                
            }
            this.setState({
                currentPitcher: isTop ? this.props.homeTeamCurrentPitcher : this.props.awayTeamCurrentPitcher,
                //pitcherNotes: pitcherNotes
            })
        }
        else if(this.props.training && this.props.currentPitcher && !this.state.currentPitcher && !this.state.pitcherIsNull){
            let pitcherNotes = '';
            let index = this.props.rootHomeTeamPlayers.findIndex(player=>player.id === this.props.currentPitcher.id);
            if(index >= 0)
            {
                pitcherNotes = this.props.rootHomeTeamPlayers[index].notes ? this.props.rootHomeTeamPlayers[index].notes : '';
            }
            this.setState({
                currentPitcher: this.props.currentPitcher,
                //pitcherNotes: pitcherNotes
            })
        }


        if(this.state.currentBatter && !this.state.showBatterNotes){
            let batterNotes = '';
            if(isTop){
                let index = this.props.rootAwayTeamPlayers.findIndex(player=>player.id === this.state.currentBatter.id);
                ////console.log('first if index ' + index);
                if(index >=0){
                    ////console.log('batter notes ' + this.props.rootAwayTeamPlayers[index].notes);
                    batterNotes = this.props.rootAwayTeamPlayers[index].notes ? this.props.rootAwayTeamPlayers[index].notes : ''; 
                }
            }
            else{
                let index = this.props.rootHomeTeamPlayers.findIndex(player=>player.id === this.state.currentBatter.id);
                ////console.log('first if index ' + index);
                if(index >=0){
                    ////console.log('batter notes ' + this.props.rootAwayTeamPlayers[index].notes);
                    batterNotes = this.props.rootHomeTeamPlayers[index].notes ? this.props.rootHomeTeamPlayers[index].notes : ''; 
                }

            }
            if(batterNotes !== this.state.batterNotes){
                this.setState({
                    batterNotes: batterNotes
                })
            }
        }

        if(this.state.currentPitcher && !this.state.showPitcherNotes){
            let pitcherNotes = '';
            if(isTop){
                let index = this.props.rootHomeTeamPlayers.findIndex(player=>player.id === this.state.currentPitcher.id);
                if(index >= 0)
                {
                    pitcherNotes = this.props.rootHomeTeamPlayers[index].notes ? this.props.rootHomeTeamPlayers[index].notes : '';
                }
            }
            else{
                let index = this.props.rootAwayTeamPlayers.findIndex(player=>player.id === this.state.currentPitcher.id);
                if(index >= 0)
                {
                    pitcherNotes = this.props.rootAwayTeamPlayers[index].notes ? this.props.rootAwayTeamPlayers[index].notes : '';
                }
                
            }
            if(pitcherNotes !== this.state.pitcherNotes){
                this.setState({
                    pitcherNotes: pitcherNotes
                })
            }
        }





        if(!this.props.gameStateLoading && this.props.atBatState !== prevProps.atBatState){
            ////////console.log('calling on SaveGameState in AtBat', this.props.token, this.props.atBatState);
            ////////console.log('calling on SaveGameState in AtBat this.props.pitchArray.length = ' + this.props.pitchArray.length);

            if(this.props.training){

                this.props.onSaveTrainingState(this.props.token, this.props.atBatState);
            }
            else{
                this.props.onSaveGameState(this.props.token, this.props.atBatState);
            }
            

            if(!this.props.gameOver){
                if(this.isGameOver()){
                    this.props.onSetGameOver(true);
                }
            }
        }

        if(prevState.isHomeTeam !== this.state.isHomeTeam){
            //alert('home team changed');
            if(this.props.homeTeamLineup && this.props.awayTeamLineup && this.props.rootHomeTeamPlayers && this.props.rootAwayTeamPlayers){
                if(this.state.isHomeTeam){
                    //batter is home team and pitcher is away team
                    //homeTeamLineup
                    //homeTeamCurrentBatterIndex

                    //awayTeamCurrentPitcher
                    this.setState({
                        currentBatter: {...this.props.homeTeamLineup[this.props.homeTeamCurrentBatterIndex]},
                        batterIsNull: false,
                        pitcherIsNull: false,
                        currentPitcher: {...this.props.awayTeamCurrentPitcher},
                    })
                }
                else{
                    //pitcher is home team and batter is away team
                    //awayTeamLineup
                    //awayTeamCurrentBatterIndex

                    //homeTeamCurrentPitcher

                    this.setState({
                        currentBatter: {...this.props.awayTeamLineup[this.props.awayTeamCurrentBatterIndex]},
                        batterIsNull: false,
                        pitcherIsNull: false,
                        currentPitcher: {...this.props.homeTeamCurrentPitcher}
                    })
                }
            }
        }
    }

    currentInning(){
        return Math.floor((this.props.currentInning / 2.0) + 1);
    }

    isInningTop(inning){
        let currentInning = (typeof inning !== 'undefined') ? inning : this.props.currentInning;
        // //////console.log('Current Inning: ' + Math.floor((this.props.currentInning / 2.0) + 1));
        // //////console.log('Top or bottom of inning? ' + (this.props.currentInning % 2 === 0 ? 'top' : 'bottom'));
        return currentInning % 2 === 0 ? true : false;
    }

    isGameOver = () =>{
        if(this.props.forceQuit){
            return true;
        }



        const inning = this.currentInning();
        const isTop = this.isInningTop();
        const homeScore = this.props.homeScore;
        const awayScore = this.props.awayScore;
        //if inning is this.props.totalInnings or greater and is in bottom and home team is winning, game is over
        if(inning >= this.props.totalInnings && !isTop && homeScore > awayScore){
            //this.props.onSetGameOver(true);
            return true;
        }
        //else if inning is greater than this.props.totalInnings and it is the end of the inning, if away team is winning game is over
        else if(inning > this.props.totalInnings && awayScore > homeScore){
            //this.props.onSetGameOver(true);
            return true;
        }
        return false;
    }

    toggleTeamHandler = () =>{
        const isHomeTeam = this.state.isHomeTeam;
        this.setState({
            isHomeTeam : ! isHomeTeam
        })
    }

    getBatters = () =>{
        if(this.state.isHomeTeam){
            return this.props.homeTeamPlayers;
        }
        else{
            return this.props.awayTeamPlayers;
        }
    }

    getPitchers = () =>{
        let ret = [];
        let ids = [];
        let _pitchArray = [...this.props.pitchArray];
        if(!this.state.isHomeTeam){
            
            for(let i=0;i<_pitchArray.length; ++i){
                const p = _pitchArray[i];
                if(!this.props.training && p.pitcher.teamId === this.props.homeTeam.id){
                    if(!ids.includes(p.pitcher.id)){
                        ids.push(p.pitcher.id);
                    }
                }
            }
            for(let i = 0; i< ids.length; ++i){
                const id = ids[i];
                let pitcher = this.props.homeTeamPlayers.find(pitcher =>pitcher.id === id);
                ret.push({...pitcher});
            }

            //return this.props.homeTeamPlayers;
            return ret;
        }
        else{
            //return this.props.awayTeamPlayers;
            for(let i=0;i<_pitchArray.length; ++i){
                const p = _pitchArray[i];
                if(!this.props.training && p.pitcher.teamId === this.props.awayTeam.id){
                    if(!ids.includes(p.pitcher.id)){
                        ids.push(p.pitcher.id);
                    }
                }
            }
            for(let i = 0; i< ids.length; ++i){
                const id = ids[i];
                let pitcher = this.props.awayTeamPlayers.find(pitcher =>pitcher.id === id);
                ret.push({...pitcher});
            }
            return ret;
        }
    }

    getBattingTeamID = () =>{
        if(this.props.homeTeam && this.props.awayTeam){
            if(this.state.isHomeTeam){
                return this.props.homeTeam.id;
            }
            else{
                return this.props.awayTeam.id;
            }
        }
    }

    getPitchingTeamID = () =>{
        if(this.props.homeTeam && this.props.awayTeam){
            if(!this.state.isHomeTeam){
                return this.props.homeTeam.id;
            }
            else{
                return this.props.awayTeam.id;
            }
        }
    }

    onSelectPitcherHandler = (id) =>{
        //////console.log('onSelectPitcherHandler ' + id);
        let pitcher = null;
        let rootPitcher = null;
        let pitcherNotes = '';
        if(id !== 'ALL'){
            if(!this.state.isHomeTeam){
                pitcher = this.props.homeTeamPlayers.find(pitcher =>pitcher.id === id);
                rootPitcher = this.props.rootHomeTeamPlayers.find(pitcher =>pitcher.id === id);
            }else{
                pitcher = this.props.awayTeamPlayers.find(pitcher =>pitcher.id === id);
                rootPitcher = this.props.rootAwayTeamPlayers.find(pitcher =>pitcher.id === id);
            }
            if(rootPitcher.notes){
                pitcherNotes = rootPitcher.notes;
            }
        }
        //////console.log('currentPitcher', pitcher);
        let pitcherIsNull = pitcher === null ? true : false;
        this.setState({
            currentPitcher: pitcher,
            pitcherIsNull : pitcherIsNull,
            pitcherNotes: pitcherNotes
        })
    }


    onSelectBatterHandler = (id) =>{
        ////console.log('onSelectBatterHandler ' + id);
        let batter = null;
        let rootBatter = null;
        let batterNotes = '';
        if(id !== 'ALL'){
            if(this.state.isHomeTeam){
                batter = this.props.homeTeamPlayers.find(batter =>batter.id === id);
                rootBatter = this.props.rootHomeTeamPlayers.find(batter =>batter.id === id);
            }else{
                batter = this.props.awayTeamPlayers.find(batter =>batter.id === id);
                rootBatter = this.props.rootAwayTeamPlayers.find(batter =>batter.id === id);
            }
            if(rootBatter.notes){
                batterNotes = rootBatter.notes;
            }
        }
        ////console.log('currentBatter', batter);
        let batterIsNull = batter === null ? true : false;
        this.setState({
            currentBatter: batter,
            batterIsNull : batterIsNull,
            batterNotes: batterNotes
        })
    }

    onShowGameHandler = () =>{
        this.props.history.push({
            pathname: '/game/' + this.props.game.id
        });
   }
   onShowTrainingHandler = () =>{
        this.props.history.push({
            pathname: '/training/' + this.props.training.id
        });
    }

    filterByZone = (pitch) =>{
        if(this.state.filterPitcherPitchLocation === 'ALL'){
            return true;
        }
        if(this.state.filterPitcherPitchLocation === pitch.pitchLocation){
            return true;
        }
        return false;
    }

    filterByPitchType = (pitch) =>{
        //////console.log('filterByPitchType', pitch);
        if(this.state.filterPitcherPitchType === 'ALL'){
        return true;
        }
        if(this.state.filterPitcherPitchType === pitch.pitchType){
            return true;
        }
        return false;
    }

    filterBatterByPitchType = (pitch) =>{
        ////console.log('filterByPitchType ' + this.state.filterBatterPitchType+' '+ pitch.pitchType);
        if(this.state.filterBatterPitchType === 'ALL'){
        return true;
        }
        if(this.state.filterBatterPitchType === pitch.pitchType){
            return true;
        }
        return false;
    }

    

   getPitcherPitches = () =>{
    let ret = [];
    let _pitchArray = [...this.props.pitchArray];
    if(this.state.currentPitcher !== null){

        
        for(let i=0;i<_pitchArray.length; ++i){
            const pitch = _pitchArray[i];
            if(typeof pitch.batter === 'undefined')
                continue;
            ////////console.log('batter ' + i + ' id = ' + pitch.batter.id);
            if(pitch.pitcher.id === this.state.currentPitcher.id){

                if(this.state.filterPitcherPitches === "ALL"){
                    if(this.filterByZone(pitch) && this.filterByPitchType(pitch)){
                        ret.push(pitch)
                    } 
                }
                else if(this.state.filterPitcherPitches === pitch.batAction ||
                        this.state.filterPitcherPitches === pitch.batActionType){
                    if(this.filterByZone(pitch) && this.filterByPitchType(pitch)){
                        ret.push(pitch)
                    }
                }
                else if(this.state.filterBatterPitches === pitch.batAction ||
                    this.state.filterPitcherPitches === pitch.batActionType){
                    if(this.filterByZone(pitch) && this.filterByPitchType(pitch)){
                        ret.push(pitch)
                    }
                }
                else if(this.state.filterPitcherPitches === pitch.batAction ||
                    this.state.filterPitcherPitches === pitch.batActionType){
                    if(this.filterByZone(pitch) && this.filterByPitchType(pitch)){
                        ret.push(pitch)
                    }
                }
                else if(this.state.filterPitcherPitches === pitch.batAction ||
                    this.state.filterPitcherPitches === pitch.batActionType){
                    if(this.filterByZone(pitch) && this.filterByPitchType(pitch)){
                        ret.push(pitch)
                    }
                }
                else if(this.state.filterPitcherPitches === pitch.batAction ||
                    this.state.filterPitcherPitches === pitch.batActionType){
                    if(this.filterByZone(pitch) && this.filterByPitchType(pitch)){
                        ret.push(pitch)
                    }
                }
                else if(this.state.filterPitcherPitches === pitch.batAction ||
                    this.state.filterPitcherPitches === pitch.batActionType){
                    if(this.filterByZone(pitch) && this.filterByPitchType(pitch)){
                        ret.push(pitch)
                    }
                }
                else if(pitch.batAction === this.state.filterPitcherPitches ||
                    this.state.filterPitcherPitches === pitch.batActionType){
                    if(this.filterByZone(pitch) && this.filterByPitchType(pitch)){
                        ret.push(pitch)
                    }
                }
            }
        }
    }
    else if(this.props.pitchArray){
        for(let i=0;i<_pitchArray.length; ++i){
            
            const pitch = _pitchArray[i];

            if(this.getPitchingTeamID() == pitch.pitcher.teamId){

                if(this.state.filterPitcherPitches === "ALL"){
                    if(this.filterByZone(pitch) && this.filterByPitchType(pitch)){
                        ret.push(pitch)
                    } 
                }
                else if(this.state.filterPitcherPitches === pitch.batAction ||
                        this.state.filterPitcherPitches === pitch.batActionType){
                    if(this.filterByZone(pitch) && this.filterByPitchType(pitch)){
                        ret.push(pitch)
                    }
                }
                else if(this.state.filterBatterPitches === pitch.batAction ||
                    this.state.filterPitcherPitches === pitch.batActionType){
                    if(this.filterByZone(pitch) && this.filterByPitchType(pitch)){
                        ret.push(pitch)
                    }
                }
                else if(this.state.filterPitcherPitches === pitch.batAction ||
                    this.state.filterPitcherPitches === pitch.batActionType){
                    if(this.filterByZone(pitch) && this.filterByPitchType(pitch)){
                        ret.push(pitch)
                    }
                }
                else if(this.state.filterPitcherPitches === pitch.batAction ||
                    this.state.filterPitcherPitches === pitch.batActionType){
                    if(this.filterByZone(pitch) && this.filterByPitchType(pitch)){
                        ret.push(pitch)
                    }
                }
                else if(this.state.filterPitcherPitches === pitch.batAction ||
                    this.state.filterPitcherPitches === pitch.batActionType){
                    if(this.filterByZone(pitch) && this.filterByPitchType(pitch)){
                        ret.push(pitch)
                    }
                }
                else if(this.state.filterPitcherPitches === pitch.batAction ||
                    this.state.filterPitcherPitches === pitch.batActionType){
                    if(this.filterByZone(pitch) && this.filterByPitchType(pitch)){
                        ret.push(pitch)
                    }
                }
                else if(pitch.batAction === this.state.filterPitcherPitches ||
                    this.state.filterPitcherPitches === pitch.batActionType){
                    if(this.filterByZone(pitch) && this.filterByPitchType(pitch)){
                        ret.push(pitch)
                    }
                }
            }
            
        }
    }
    //////console.log('Pitcher Pitches', ret)
    return ret;
   }

   getPlateAppearanceTotal = () =>{
        let ret = 0;
        let _pitchArray = [...this.props.pitchArray];
        if(this.state.currentBatter !== null){
            for(let i=0;i<_pitchArray.length; ++i){
                const pitch = _pitchArray[i];
                if(typeof pitch.batter === 'undefined')
                    continue;
                ////////console.log('batter ' + i + ' id = ' + pitch.batter.id);
                if(pitch.batter.id === this.state.currentBatter.id){
                    if(pitch.ballCount === 0 && pitch.strikeCount ===0){
                        ret ++;
                    }
                }
            }
        }
        return ret;
   }

   getPlateAppearanceResult = (pa) =>{
       let appearanceResult = '';
       let ret = [];
       let _pitchArray = [...this.props.pitchArray];
        let currentPlateAppearance = 1;
        
       if(this.state.currentBatter !== null){
        for(let i=0;i<_pitchArray.length; ++i){
            const pitch = _pitchArray[i];
            if(typeof pitch.batter === 'undefined')
                continue;
            ////////console.log('batter ' + i + ' id = ' + pitch.batter.id);
            if(pitch.batter.id === this.state.currentBatter.id){
                    if(pitch.ballCount === 0 && pitch.strikeCount ===0){
                        //start plate appearance
                        if(currentPlateAppearance == pa){
                            
                            for(let j=i; j < _pitchArray.length; ++j){
                                const p = _pitchArray[j];
                                if(p.batter.id === this.state.currentBatter.id){
                                    ret.push(p);
                                }
                                //
                                if(p.batter.id !== this.state.currentBatter.id || j == _pitchArray.length - 1){
                                    const resultPitch = ret.pop();
                                    if(resultPitch){
                                        appearanceResult = resultPitch.result;
                                        if(resultPitch.strikeCount === 2 && resultPitch.strike &&
                                            (resultPitch.result === "Take" || resultPitch.result === "SWING AND MISS")){
                                            appearanceResult = 'K';
                                            
                                        }
                                        if( resultPitch.pitchResult === "ball" &&
                                            resultPitch.ballCount === 3 && 
                                            resultPitch.result === "Take"){
                                                appearanceResult = 'BB';
                                            }

                                        return appearanceResult;
                                    }
                                }

                            }
                        }
                        currentPlateAppearance ++;
                    }
              
            }
        }
       }
       return appearanceResult;
   }

    getPitches = () =>{
        let ret = [];
        let _pitchArray = [...this.props.pitchArray];
        let currentPlateAppearance = 1;
        if(this.state.currentBatter !== null){

            
            for(let i=0;i<_pitchArray.length; ++i){
                const pitch = _pitchArray[i];
                if(typeof pitch.batter === 'undefined')
                    continue;
                ////////console.log('batter ' + i + ' id = ' + pitch.batter.id);
                if(pitch.batter.id === this.state.currentBatter.id){
                    if(this.state.plateAppearance > 0){
                        if(pitch.ballCount === 0 && pitch.strikeCount ===0){
                            //start plate appearance
                            if(this.state.plateAppearance == currentPlateAppearance){
                                for(let j=i; j < _pitchArray.length; ++j){
                                    const p = _pitchArray[j];
                                    if(p.batter.id === this.state.currentBatter.id){
                                        if(this.state.filterBatterPitches === 'ALL' ||
                                        this.state.filterBatterPitches === p.batAction ||
                                        this.state.filterBatterPitches === p.batActionType){
                                            if(this.filterBatterByPitchType(p)){
                                                ret.push(p);
                                            }
                                        }   
                                    }
                                    else{
                                        i=_pitchArray.length;
                                        j = _pitchArray.length;
                                    }

                                }
                            }
                            currentPlateAppearance ++;
                        }
                    }
                    else{
                        if(this.state.filterBatterPitches === 'ALL' ||
                        this.state.filterBatterPitches === pitch.batAction ||
                        this.state.filterBatterPitches === pitch.batActionType){
                            if(this.filterBatterByPitchType(pitch)){
                                ret.push(pitch);
                            }
                        }
                    }
                }
            }
        }
        else if(this.props.pitchArray){
            for(let i=0;i<_pitchArray.length; ++i){
                
                const pitch = _pitchArray[i];
                if(this.getBattingTeamID() == pitch.batter.teamId){
                    if(this.state.filterBatterPitches === 'ALL' ||
                        this.state.filterBatterPitches === pitch.batAction ||
                        this.state.filterBatterPitches === pitch.batActionType){
                            if(this.filterBatterByPitchType(pitch)){
                                ret.push(pitch);
                            }
                    }
                }
                
            }
        }
        ////console.log('Batter Pitches', ret);
        return ret;
    }

    renderPitches = (pitches) =>{
        let ret = [];
        for(let i=0;i<pitches.length; ++i){
            const p = pitches[i];
            const pitchX = p.x * 222;
            const pitchY = p.y * 222;
            ret.push(<Pitch key={i} pitch = {p} index = {i+1} x = {pitchX} y = {pitchY} strike={PitchClass.isStrike(p)} renderPitch={true}/>) 
        }
        return ret;
    }

    renderPitcherPitches = (pitches) =>{
        let ret = [];
        for(let i=0;i<pitches.length; ++i){
            const p = pitches[i];
            if(this.state.showPitcherBatters ==  0 ||
                (this.state.showPitcherBatters == 1 && p.battingRight) || //0 - all, 1- right, 2- left)
                (this.state.showPitcherBatters == 2 && !p.battingRight)){
                const pitchX = p.x * 222;
                const pitchY = p.y * 222;
                ret.push(<Pitch key={i} pitcher={true} pitch = {p} index = {i+1} x = {pitchX} y = {pitchY} strike={PitchClass.isStrike(p)} renderPitch={true}/>)
            }
        }
        return ret;
    }

    onChangePlateAppearance = (val) =>{
        this.setState({
            plateAppearance: val
        })
    }

    onChangeBattingSide = (val) =>{
        this.setState({
            showPitcherBatters: val
        })
    }

    isHit = (pitch) =>{
        if(pitch.result === "1B" || 
        pitch.result === "2B" || 
        pitch.result === "3B" || 
        pitch.result === "4B" || 
        pitch.result === "HR" || 
        pitch.result === "IPHR"){
            return true;
        }
        return false;
    }

    /*const options = [
        'FB',
        'XCHANGE',
        'CURVE',
        'DROPCV',
        'DROP',
        'RISE',
        'SCREW'
    ];

    const optionAbv = [
        'FB',
        'XCH',
        'CRV',
        'DCV',
        'DRP',
        'RIS',
        'SCRW'
    ];*/

    calculatePitchTypesTableData = (pitches) =>{
        let ret = {
             FBP: 0,
             FBC: 0,
             FBSM: 0,
             XCHP: 0,
             XCHC: 0,
             XCHSM: 0,
             CRVP: 0,
             CRVC: 0,
             CRVSM: 0,
             DCVP:0,
             DCVC:0,
             DCVSM:0,
             DRPP:0,
             DRPC:0,
             DRPSM:0,
             RISP:0,
             RISC:0,
             RISSM:0,
             SCRWP:0,
             SCRWC:0,
             SCRWSM:0
         }
         let r =  this.calculatPitchTypesTableLocation("FB", pitches);
         ret.FBP = r.P;
         ret.FBC = r.C;
         ret.FBSM = r.SM;
 
         r =  this.calculatPitchTypesTableLocation("XCHANGE", pitches);
         ret.XCHP = r.P;
         ret.XCHC = r.C;
         ret.XCHSM = r.SM;

         r =  this.calculatPitchTypesTableLocation("CURVE", pitches);
         ret.CRVP = r.P;
         ret.CRVC = r.C;
         ret.CRVSM = r.SM;

         r =  this.calculatPitchTypesTableLocation("DROPCV", pitches);
         ret.DCVP = r.P;
         ret.DCVC = r.C;
         ret.DCVSM = r.SM;

         r =  this.calculatPitchTypesTableLocation("DROP", pitches);
         ret.DRPP = r.P;
         ret.DRPC = r.C;
         ret.DRPSM = r.SM;

         r =  this.calculatPitchTypesTableLocation("RISE", pitches);
         ret.RISP = r.P;
         ret.RISC = r.C;
         ret.RISSM = r.SM;

         r =  this.calculatPitchTypesTableLocation("SCREW", pitches);
         ret.SCRWP = r.P;
         ret.SCRWC = r.C;
         ret.SCRWSM = r.SM;
 
         
 
         return ret;
 
 
 
     }

    calculatPitchTypesTableLocation = (type, pitches) =>{
        let ret = {
            P: 0,
            C: 0,
            SM: 0
        }
        //C: Contact % for that location
        //P: % Pitches by locations
        //SM: Swing/Miss % by that location
        let totalPitchesType = 0;
        let totalBallContacts = 0;
        let totalMisses = 0;
        let totalSwings = 0;
        for(let i=0;i<pitches.length; ++i){
            const p = pitches[i];
            if(p.pitchType === type){
                totalPitchesType += 1;
                if(p.result === "SWING AND MISS"){
                    totalMisses ++;
                }
                if(p.result !== "Take"){
                    totalSwings ++;
                }
                if(p.hitResult !== ""){
                    totalBallContacts ++;
                }
            }
        }
        if(pitches.length > 0){
            ret.P = totalPitchesType / parseFloat(pitches.length) * 100;
            ret.P = ret.P.toFixed(0);

            ret.C = totalBallContacts /  parseFloat(pitches.length) * 100;
            ret.C = ret.C.toFixed(0);

            ret.SM = totalMisses /  parseFloat(pitches.length) * 100;
            ret.SM = ret.SM.toFixed(0);

        }

        return ret;
    

    }

    calculatZoneTableLocation = (LOC, pitches) =>{
        let ret = {
            P: 0,
            C: 0,
            SM: 0,
            T: 0
        }
        //C: Contact % for that location
        //P: % Pitches by locations
        //SM: Swing/Miss % by that location
        let totalPitchesLOC = 0;
        let totalBallContacts = 0;
        let totalMisses = 0;
        let totalSwings = 0;
        for(let i=0;i<pitches.length; ++i){
            const p = pitches[i];
            if(p.pitchLocation === LOC){
                totalPitchesLOC += 1;
                if(p.result === "SWING AND MISS"){
                    totalMisses ++;
                }
                if(p.result !== "Take"){
                    totalSwings ++;
                }
                if(p.hitResult !== ""){
                    totalBallContacts ++;
                }
            }
        }
        if(pitches.length > 0){
            ret.T = totalPitchesLOC;
            ret.P = totalPitchesLOC / parseFloat(pitches.length) * 100;
            ret.P = ret.P.toFixed(0);

            ret.C = totalBallContacts /  parseFloat(pitches.length) * 100;
            ret.C = ret.C.toFixed(0);

            ret.SM = totalMisses /  parseFloat(pitches.length) * 100;
            ret.SM = ret.SM.toFixed(0);

        }

        return ret;
    

    }

    calculateZonesTableData = (pitches) =>{
       let ret = {
            HAP: 0,
            HAC: 0,
            HASM: 0,
            HAT: 0,
            HIP: 0,
            HIC: 0,
            HISM: 0,
            HIT: 0,
            LIP: 0,
            LIC: 0,
            LISM: 0,
            LIT: 0,
            LAP: 0,
            LAC: 0,
            LASM: 0,
            LAT: 0,
            MMP: 0,
            MMC: 0,
            MMSM: 0,
            MMT: 0,
            HMP: 0,
            HMC: 0,
            HMSM: 0,
            HMT: 0,
            LMP: 0,
            LMC: 0,
            LMSM: 0,
            LMT: 0,

            MAP: 0,
            MAC: 0,
            MASM: 0,
            MAMT: 0,

            MIP: 0,
            MIC: 0,
            MISM: 0,
            MIMT: 0,
            
        }
        let results = [];
        for( let i = 0; i < pitches.length; ++i){
            let p = pitches[i];
            if(p.pitchLocation !== 'HA' &&
            p.pitchLocation !== 'HI' &&
            p.pitchLocation !== 'LI' &&
            p.pitchLocation !== 'LA' &&
            p.pitchLocation !== 'MM' &&
            p.pitchLocation !== 'HM' &&
            p.pitchLocation !== 'LM'
            )
            results.push(p);
        }
        ////console.log('unaccounted pitches', results);
        let r =  this.calculatZoneTableLocation("HA", pitches);
        ret.HAP = r.P;
        ret.HAC = r.C;
        ret.HASM = r.SM;
        ret.HAT = r.T;

        r =  this.calculatZoneTableLocation("HI", pitches);
        ret.HIP = r.P;
        ret.HIC = r.C;
        ret.HISM = r.SM;
        ret.HIT = r.T;

        r =  this.calculatZoneTableLocation("LI", pitches);
        ret.LIP = r.P;
        ret.LIC = r.C;
        ret.LISM = r.SM;
        ret.LIT = r.T;

        r =  this.calculatZoneTableLocation("LA", pitches);
        ret.LAP = r.P;
        ret.LAC = r.C;
        ret.LASM = r.SM;
        ret.LAT = r.T;

        r =  this.calculatZoneTableLocation("MM", pitches);
        ret.MMP = r.P;
        ret.MMC = r.C;
        ret.MMSM = r.SM;
        ret.MMT = r.T;

        r =  this.calculatZoneTableLocation("HM", pitches);
        ret.HMP = r.P;
        ret.HMC = r.C;
        ret.HMSM = r.SM;
        ret.HMT = r.T;

        r =  this.calculatZoneTableLocation("LM", pitches);
        ret.LMP = r.P;
        ret.LMC = r.C;
        ret.LMSM = r.SM;
        ret.LMT = r.T;

        r =  this.calculatZoneTableLocation("MA", pitches);
        ret.MAP = r.P;
        ret.MAC = r.C;
        ret.MASM = r.SM;
        ret.MAT = r.T;

        r =  this.calculatZoneTableLocation("MI", pitches);
        ret.MIP = r.P;
        ret.MIC = r.C;
        ret.MISM = r.SM;
        ret.MIT = r.T;

        return ret;



    }

    renderPlateAppearances = () =>{
        /*
        <div className={battingSideAllStyle} onClick={() =>this.onChangePlateAppearance(0)}>ALL</div>
                                <div className={battingSideRightStyle} onClick={() => this.onChangePlateAppearance(1)}>1</div>
                                <div className={battingSideLeftStyle} onClick={() =>this.onChangePlateAppearance(2)}>2</div>
                                <div className={battingSideLeftStyle} onClick={() =>this.onChangePlateAppearance(3)}>3</div>
                                <div className={battingSideLeftStyle} onClick={() =>this.onChangePlateAppearance(4)}>4</div>
                                <div className={battingSideLeftStyle} onClick={() =>this.onChangePlateAppearance(5)}>5</div>
        */
       /*battingSideAllStyle = [styles.BattingSideButton, styles.BattingSideButtonActive].join(" ");
       */
       const totalAppearances = this.getPlateAppearanceTotal();
       let ret = [];
       if(this.state.plateAppearance === 0){
           ret.push(<div key={"pa-0"} className={[styles.PlateAppearanceButton, styles.PlateAppearanceButtonActive].join(" ")} onClick={() =>this.onChangePlateAppearance(0)}>ALL</div>)
       }
       else{
            ret.push(<div key={"pa-0"} className={styles.PlateAppearanceButton} onClick={() =>this.onChangePlateAppearance(0)}>ALL</div>);
       }
       for(let i=0;i<totalAppearances; ++i){
            const resultStr = this.getPlateAppearanceResult(i+1);
            if(i+1 === this.state.plateAppearance){
                ret.push(<div key={"pa-"+(i+1)} className={[styles.PlateAppearanceButton, styles.PlateAppearanceButtonActive].join(" ")} onClick={() =>this.onChangePlateAppearance(i+1)}>{i+1}<br/><span>{resultStr}</span></div>)
            }
            else{
                ret.push(<div key={"pa-"+(i+1)} className={styles.PlateAppearanceButton} onClick={() =>this.onChangePlateAppearance(i+1)}>{i+1}<br/><span>{resultStr}</span></div>)
            }

       }
       return ret;
    }

    /*
    const renderPitches = this.state.pitches.map((p,i) => {
            const pitchX = p.x * WIDTH;
            const pitchY = p.y * HEIGHT;
            return(
                <Pitch key={i} pitch = {p} index = {i+1} x = {pitchX} y = {pitchY} strike={PitchClass.isStrike(p)} renderPitch={true}/>
            )
        });
    */
   onFilterBatterPitches = (name) =>{
       //////console.log('onFilterBatterPitches', name);
       if(this.state.filterBatterPitches === name){
        this.setState({
            filterBatterPitches: "ALL"
        });
    }
    else{
        this.setState({
            filterBatterPitches: name
        });
    }
   }

   onFilterZone = (val) =>{
       ////console.log('onFilterZone ', val);

       if(this.state.filterPitcherPitchLocation === val){
           this.setState({
            filterPitcherPitchLocation: "ALL"
           })
       }
       else{
        this.setState({
            filterPitcherPitchLocation: val
           })
       }
   }
   onFilterBatterByPitchType = (val) =>{
    ////console.log('filterBatterByPitchType', val);
    if(this.state.filterBatterPitchType === val){
        this.setState({
            filterBatterPitchType: "ALL"
        })
    }
    else{
        this.setState({
            filterBatterPitchType: val
           })
    }
   
}

    onFilterByPitchType = (val) =>{
        //////console.log('filterByPitchType', val);
        if(this.state.filterPitcherPitchType === val){
            this.setState({
                filterPitcherPitchType: "ALL"
            })
        }
        else{
            this.setState({
                filterPitcherPitchType: val
               })
        }
       
    }

   onFilterPitcherPitches = (name) =>{
    //////console.log('onFilterPitcherPitches', name);
    if(this.state.filterPitcherPitches === name){
        this.setState({
            filterPitcherPitches: "ALL"
        });
    }
    else{
        this.setState({
            filterPitcherPitches: name
        });
    }
   }

   

   submitPitcherNotesHandler = () =>{
    
        let currentPitcher = this.state.currentPitcher;
        ////console.log('currentPitcher', currentPitcher);
        if(this.state.isHomeTeam){
            ////console.log('isHomeTeam is true, so pitcher is on the away team');
            let index = this.props.rootAwayTeamPlayers.findIndex(player=>player.id === currentPitcher.id);
            ////console.log('found index ' + index);
            if(index >=0){
                ////console.log('player', this.props.rootAwayTeamPlayers[index]);
                let updatedPlayer = {...this.props.rootAwayTeamPlayers[index], notes : this.state.pitcherNotes}
                ////console.log('updatedPlayer', updatedPlayer);
                //let players = [...this.props.awayTeamPlayers];
                //players.splice(index,1,updatedPlayer);
                //this.props.onSetAwayTeamPlayers(players);
                this.props.onUpdatePlayer(this.props.token, updatedPlayer);

            }
            
        }
        else{
            ////console.log('isHomeTeam is false, so pitcher is on the home team');
            let index = this.props.rootHomeTeamPlayers.findIndex(player=>player.id === currentPitcher.id);
            ////console.log('found index ' + index);
            if(index >=0){
                ////console.log('player', this.props.rootHomeTeamPlayers[index]);
                let updatedPlayer = {...this.props.rootHomeTeamPlayers[index], notes : this.state.pitcherNotes}
                ////console.log('updatedPlayer', updatedPlayer);
                //let players = [...this.props.homeTeamPlayers];
                //players.splice(index,1,updatedPlayer);
                //this.props.onSetHomeTeamPlayers(players);
                this.props.onUpdatePlayer(this.props.token, updatedPlayer);
            }
        }


        this.setState({
            showPitcherNotes: false
        });
    }

    pitcherNotesMessageChange = (event) =>{
        //this.props.onSetGameNotes(event.target.value);
        this.setState({
            pitcherNotes : event.target.value
        });
    }

    showPitcherNotesHandler = () =>{
        //////////console.log('showPitcherNotesHandler')
        this.setState({
            showPitcherNotes: true
        })
    }

    showPitcherNotesCancelHandler = () =>{
        this.setState({
            showPitcherNotes: false
        });
    }

    submitBatterNotesHandler = () =>{
        let currentHitter = this.state.currentBatter;
        ////console.log('currentHitter', currentHitter);
        if(!this.state.isHomeTeam){
            ////console.log('isHomeTeam is false, so hitter is on the away team');
            let index = this.props.rootAwayTeamPlayers.findIndex(player=>player.id === currentHitter.id);
            ////console.log('found index ' + index);
            if(index >=0){
                ////console.log('player', this.props.rootAwayTeamPlayers[index]);
                let updatedPlayer = {...this.props.rootAwayTeamPlayers[index], notes : this.state.batterNotes}
                ////console.log('updatedPlayer', updatedPlayer);
                //let players = [...this.props.awayTeamPlayers];
                //players.splice(index,1,updatedPlayer);
                //this.props.onSetAwayTeamPlayers(players);
                this.props.onUpdatePlayer(this.props.token, updatedPlayer);

            }
            
        }
        else{
            ////console.log('isHomeTeam is false, so hitter is on the home team');
            let index = this.props.rootHomeTeamPlayers.findIndex(player=>player.id === currentHitter.id);
            ////console.log('found index ' + index);
            if(index >=0){
                ////console.log('player', this.props.rootHomeTeamPlayers[index]);
                let updatedPlayer = {...this.props.rootHomeTeamPlayers[index], notes : this.state.batterNotes}
                ////console.log('updatedPlayer', updatedPlayer);
                //let players = [...this.props.homeTeamPlayers];
                //players.splice(index,1,updatedPlayer);
                //this.props.onSetHomeTeamPlayers(players);
                this.props.onUpdatePlayer(this.props.token, updatedPlayer);
            }
        }




        this.setState({
            showBatterNotes: false
        });

        /*if(this.state.currentPitch){

            let updatedPitch = {
                ...this.state.currentPitch
            };
            let updatedPitchArray = [...this.state.pitches];
            updatedPitchArray.pop();
            updatedPitch.batterNotes = this.state.batterNotes;
            updatedPitchArray.push(updatedPitch);
            this.setState({
                pitches: updatedPitchArray,  
                currentPitch: updatedPitch
            });
        }*/

    }

    batterNotesMessageChange = (event) =>{
        //this.props.onSetGameNotes(event.target.value);
        this.setState({
            batterNotes : event.target.value,
            
        });
    }
    
    showBatterNotesHandler = () =>{
        this.setState({
            showBatterNotes: true
        })
    }

    showBatterNotesCancelHandler = () =>{
        this.setState({
            showBatterNotes: false
        });
    }

    /*filterPitcherPitches: "ALL",
        filterBatterPitches: "ALL",
        filterPitcherPitchLocation: "ALL",
        filterPitcherPitchType: "ALL",
        filterBatterPitchType: "ALL"*/
        

    showClearFiltersButton = () =>{
        if( this.state.filterPitcherPitches !== 'ALL' || 
            this.state.filterBatterPitches !== 'ALL' || 
            this.state.filterPitcherPitchLocation !== 'ALL' ||
            this.state.filterPitcherPitchType !== 'ALL' ||
            this.state.filterBatterPitchType !== 'ALL'
        ){
            return true;
        }
        return false;
    }

    showClearBatterPitchesButton = () =>{
        if(this.state.filterBatterPitches !== 'ALL'){
            return true;
        }
        return false;
    }

    showClearPitcherPitchesButton = () =>{
        if(this.state.filterPitcherPitches !== 'ALL'){
            return true;
        }
        return false;
    }

    showClearPitcherPitchLocation = () =>{
        if(this.state.filterPitcherPitchLocation !== 'ALL'){
            return true;
        }
        return false;
    }

    showClearPitcherPitchType = () =>{
        if(this.state.filterPitcherPitchType !== 'ALL'){
            return true;
        }
        return false;
    }

    showClearBatterPitchType = () =>{
        if(this.state.filterBatterPitchType !== 'ALL'){
            return true;
        }
        return false;
    }


    
    
   

    onClearFiltersHandler = (val) =>{
        if(val === "ALL"){
            this.setState({
                filterPitcherPitches : "ALL",
                filterBatterPitches : "ALL",
                filterPitcherPitchLocation : "ALL",
                filterPitcherPitchType : "ALL",
                filterBatterPitchType : "ALL"
            });
        }
        if(val === "BP"){
            this.setState({
               
                filterBatterPitches : "ALL"
            });
        }
        if(val === "PP"){
            this.setState({
               
                filterPitcherPitches : "ALL"
            });
        }
        if(val === "PPL"){
            this.setState({
               
                filterPitcherPitchLocation : "ALL"
            });

        }
        if(val === "PPT"){
            this.setState({
               
                filterPitcherPitchType : "ALL"
            });

        }
        if(val === "BPT"){
            this.setState({
               
                filterBatterPitchType : "ALL"
            });

        }
        

        /*if(val === "HR"){
            this.setState({
               
                filterBatterPitchesByHitResult : "ALL",
                
            });

        }
        if(val === "HP"){
            this.setState({
                
                filterBatterPitchesByHitPower : "ALL",
                
            });

        }
        if(val === "HA"){
            this.setState({
                filterBatterPitchesByHitAction : "ALL",
                
            });

        }*/
    }
    


    render(){
        let pitches = this.getPitches();
        let pitcherPitches = this.getPitcherPitches();
        //console.log('pitcherPitches ' +pitcherPitches.length, pitcherPitches);
        const renderPitcherPitches = this.renderPitcherPitches(pitcherPitches);
        const zonesTableData = this.calculateZonesTableData(pitcherPitches);
        const pitchTypesTableData = this.calculatePitchTypesTableData(pitcherPitches);
        const renderPitches = this.renderPitches(pitches);
        const plateAppearanceButtons = this.renderPlateAppearances();


        let clearFiltersButton = <div style={{height:"37px"}}></div>;
        let clearBatterPitchesButton = null;
        let clearPitcherPitchesButton = null;
        let clearPitcherPitchLocationButton = null;
        let clearPitcherPitchTypeButton = null;
        let clearBatterPitchTypeButton = null;
        
        /*let clearFilterHitResultButton = null;
        let clearFilterHitPowerButton = null;
        let clearFilterHitActionButton = null;
        let clearFilterPitchButton = null;*/


       

    
   


        if(this.showClearFiltersButton()){
            clearFiltersButton = <Button  btnType="Danger" btnSize="Small" clicked={() => this.onClearFiltersHandler("ALL")}>Clear All</Button>
        }
        if(this.showClearBatterPitchesButton()){
            clearBatterPitchesButton = <Button  btnType="Danger" btnSize="Small" clicked={() => this.onClearFiltersHandler("BP")}>Clear BP</Button>
        }

        if(this.showClearPitcherPitchesButton()){
            clearPitcherPitchesButton = <Button  btnType="Danger" btnSize="Small" clicked={() => this.onClearFiltersHandler("PP")}>Clear PP</Button>
        }
        if(this.showClearPitcherPitchLocation()){
            clearPitcherPitchLocationButton = <Button  btnType="Danger" btnSize="Small" clicked={() => this.onClearFiltersHandler("PPL")}>Clear PPL</Button>
        }
        if(this.showClearPitcherPitchType()){
            clearPitcherPitchLocationButton = <Button  btnType="Danger" btnSize="Small" clicked={() => this.onClearFiltersHandler("PPT")}>Clear PPT</Button>
        }
        if(this.showClearBatterPitchType()){
            clearBatterPitchTypeButton = <Button  btnType="Danger" btnSize="Small" clicked={() => this.onClearFiltersHandler("BPT")}>Clear BPT</Button>
        }
       
    
        /*if(this.showClearFilterPitchLocationButton()){
            clearFilterPitchLocationButton = <Button  btnType="Danger" btnSize="Small" clicked={()=> this.onClearFiltersHandler("PL")}>Clear Pitch Zone</Button>
        }
        if(this.showClearFilterHitResultButton()){
            clearFilterHitResultButton = <Button  btnType="Danger" btnSize="Small" clicked={() => this.onClearFiltersHandler("HR")}>Clear Hit Result</Button>
        }
        if(this.showClearFilterHitPowerButton()){
            clearFilterHitPowerButton = <Button  btnType="Danger" btnSize="Small" btnSize="Small" clicked={() => this.onClearFiltersHandler("HP")}>Clear Hit Power</Button>
        }
        if(this.showClearFilterHitActionButton()){
            clearFilterHitActionButton= <Button  btnType="Danger" btnSize="Small" clicked={() => this.onClearFiltersHandler("HA")}>Clear Hit Action</Button>
        }
        if(this.showClearFilterPitchButton()){
            clearFilterPitchButton =  <Button  btnType="Danger" btnSize="Small" clicked={() => this.onClearFiltersHandler("BP")}>Clear Hit Type</Button>
        }*/

        let backToButton = <Button btnType="Danger" btnSize="Small" clicked={this.onShowGameHandler}>Back to Game</Button>

        if(this.props.training){
            backToButton = <Button btnType="Danger" btnSize="Small" clicked={this.onShowTrainingHandler}>Back to Training</Button>
        }

        //////console.log('pitches=', pitches);
        let teamButtons = null;
        /*if(this.props.homeTeam && this.props.awayTeam){
            teamButtons = <div>
                                <Button btnType={this.state.isHomeTeam ? "Active" : "Danger"} btnSize="Small" clicked={this.toggleTeamHandler}>{this.props.homeTeam.name}</Button>
                                <Button btnType={!this.state.isHomeTeam ? "Active" : "Danger"}  btnSize="Small"clicked={this.toggleTeamHandler}>{this.props.awayTeam.name}</Button>
                          </div>
        }*/
        if(this.props.homeTeam && this.props.awayTeam){
            teamButtons = <div>
                <Button btnType={"Danger"}  btnSize="Small"clicked={this.toggleTeamHandler}>{this.props.awayTeam.name}</Button>
            </div>
            if(this.state.isHomeTeam){
                teamButtons = <div>
                    <Button btnType={"Danger"} btnSize="Small" clicked={this.toggleTeamHandler}>{this.props.homeTeam.name}</Button>
                </div>
            }
        }
        let title = null;
        if(this.props.game){
            title = <div className={styles.TitleContainer}>
                      <h3 style={{display: 'inline'}}>{this.isGameOver() === true ? 'FINAL - ' : ''}{this.props.awayTeam.name} at {this.props.homeTeam.name}  -
                      { new Date(this.props.game.date).toLocaleDateString("en-US")}</h3>
                      {backToButton}
                    </div>
        }
        let batterSelection = [];
        let batterStats = null;
        let batterSelector = null;

        let pitcherSelection = [];
        let pitcherStats = null;
        let pitcherSelector = null;



        if(this.props.homeTeamPlayers && this.props.awayTeamPlayers){
            const batters = this.getBatters();
            const pitchers = this.getPitchers();
            //let batters = [];
            //batterSelection.push(<div>);
            //batterSelection.push(<select>);

            for(let i=0;i<batters.length;++i){
                const b = batters[i];
                batterSelection.push(<option value={b.id}>{b.firstName} {b.lastName} </option>)
            }

            for(let i=0;i<pitchers.length; ++i){
                const p = pitchers[i];
                pitcherSelection.push(<option value={p.id}>{p.firstName} {p.lastName} </option>);
            }
            //batterSelection.push(</select>);
            //batterSelection.push(</div>);
           
        }

        pitcherStats = <PlayerStats currentPitchArray={this.props.pitchArray}
        playerType={'pitcher'}
        player={this.state.currentPitcher}
        onClick={null}
        showPitcherNotesHandler = {this.showPitcherNotesHandler}
            teamID = {this.getPitchingTeamID()}
            detailed = {true}/>
        
            pitcherSelector = <PlayerSelector label="Current Pitcher"
            containAllOption={true}
            players={this.getPitchers()} 
            currentPlayer={this.state.currentPitcher}
            noButton={true}
            hideLabel={true}
            onSubmit={(id)=>this.onSelectPitcherHandler(id)}/>

            if(this.props.training){
                pitcherSelector = null;
            }


       // if(this.state.currentBatter){
            batterStats = <PlayerStats    currentPitchArray={this.props.pitchArray} 
            playerType={'batter'} 
            player={this.state.currentBatter} 
            onClick={null}
            showBatterNotesHandler = {this.showBatterNotesHandler}
            teamID = {this.getBattingTeamID()}
            detailed = {true}/>

            ////console.log('creating Player Selector for batter', this.state.currentBatter)
            batterSelector = <PlayerSelector label="Current Batter"
            containAllOption={true}
            noButton={true}
            hideLabel={true}
            players={this.getBatters()} 
            currentPlayer={this.state.currentBatter}
            onSubmit={(id)=>this.onSelectBatterHandler(id)}/>
       // }

       if(this.props.training){
            batterSelector = null;
        }

       //[styles.BattingSide, styles.BattingSideActive].join(" ")
        let battingSideAllStyle = styles.BattingSideButton;
        let battingSideRightStyle = styles.BattingSideButton;
        let battingSideLeftStyle = styles.BattingSideButton;

        switch(this.state.showPitcherBatters){
            case 0:{
                battingSideAllStyle = [styles.BattingSideButton, styles.BattingSideButtonActive].join(" ");
                break;
            }
            case 1:{
                battingSideRightStyle = [styles.BattingSideButton, styles.BattingSideButtonActive].join(" ");
                break;
            }
            default:{
                battingSideLeftStyle = [styles.BattingSideButton, styles.BattingSideButtonActive].join(" ");
                break;
            }
        }


        

        let pitchTableJSX = <PitchTable pitchArray={pitches} pitchType={this.props.pitchType} onFilterPitchType={this.onFilterBatterByPitchType}/>

        if(this.props.pitchType === 'Baseball'){
            pitchTableJSX = <PitchTableBaseball pitchArray={pitches} pitchType={this.props.pitchType} onFilterPitchType={this.onFilterBatterByPitchType}/>
        }


        let  renderBatterAnalysis = 
            <div className={styles.BatterAnalysis}>
                    <h3>Batter Analysis</h3>
                    {batterSelector}
                    {batterStats}
                
                    <div className={styles.PlotterContainer}>
                        <div className={styles.PitchPlotterContainer}>
                            <div className={styles.PlateAppearanceButtons}>
                                {/*<div className={battingSideAllStyle} onClick={() =>this.onChangePlateAppearance(0)}>ALL</div>
                                <div className={battingSideRightStyle} onClick={() => this.onChangePlateAppearance(1)}>1</div>
                                <div className={battingSideLeftStyle} onClick={() =>this.onChangePlateAppearance(2)}>2</div>
                                <div className={battingSideLeftStyle} onClick={() =>this.onChangePlateAppearance(3)}>3</div>
                                <div className={battingSideLeftStyle} onClick={() =>this.onChangePlateAppearance(4)}>4</div>
        <div className={battingSideLeftStyle} onClick={() =>this.onChangePlateAppearance(5)}>5</div>*/}
                                {plateAppearanceButtons}

                                
                            </div>
                            
                            <PitchPlotter 
                                currentPitch = {null}
                                pitchLocationName={null}
                                pitchLocation = {null}
                                updateLocation={null}
                                clickLocation={null}
                                

                                //battingRight={this.state.battingRight}
                                //battingSideChange={this.battingSideChangeHandler}
                                width={222}
                                height={222}
                                //ballCount = {this.state.ballCount}
                                //strikeCount = {this.state.strikeCount}
                                >
                                {renderPitches}
                                
                            </PitchPlotter>
                            <div className={styles.PitchTypeButtons}>
                                <div className={[styles.InPlayButton, styles.PitchButton].join(" ")} onClick={()=>this.onFilterBatterPitches("In Play")}>INPLAY</div>
                                <div className={[styles.SMButton, styles.PitchButton].join(" ")} onClick={()=>this.onFilterBatterPitches("Miss")}>SM</div>
                                <div className={[styles.TakeButton, styles.PitchButton].join(" ")} onClick={()=>this.onFilterBatterPitches("Take")}>Take</div>
                                <div className={[styles.SlapButton, styles.PitchButton].join(" ")} onClick={()=>this.onFilterBatterPitches("Slap")}>Slap</div>
                                <div className={[styles.FoulButton, styles.PitchButton].join(" ")} onClick={()=>this.onFilterBatterPitches("Foul")}>Foul</div>
                                <div className={[styles.BuntButton, styles.PitchButton].join(" ")} onClick={()=>this.onFilterBatterPitches("Bunt")}>Bunt</div>
                            </div>
                        </div>


                        <FieldPlotter pitchArray={pitches}/>
                    </div>
                    {clearFiltersButton}
                    {clearBatterPitchesButton}
                    {clearPitcherPitchesButton}
                    {clearPitcherPitchLocationButton}
                    {clearPitcherPitchTypeButton}
                    {clearBatterPitchTypeButton}
                    {pitchTableJSX}
                </div>

        let pitcherAnalysisTitle = 'Opposing Pitcher Analysis'
        if(this.props.training && this.props.training.trainingType === 'Pitcher'){
            renderBatterAnalysis = null;
            pitcherAnalysisTitle = 'Pitcher Analysis';
        }

        let renderPitcherAnalysis = 
        <div className={styles.PitcherAnalysis}>
                    <h3>{pitcherAnalysisTitle}</h3>
                    {pitcherSelector}
                    {pitcherStats}<br/>
                    {clearFiltersButton}
                    {clearBatterPitchesButton}
                    {clearPitcherPitchesButton}
                    {clearPitcherPitchLocationButton}
                    {clearPitcherPitchTypeButton}
                    {clearBatterPitchTypeButton}
                    <div className={styles.PlotterContainer}>
                        <div className={styles.PitchPlotterContainer}>
                            <div className={styles.BattingSideButtons}>
                                <div className={battingSideAllStyle} onClick={() =>this.onChangeBattingSide(0)}>ALL</div>
                                <div className={battingSideRightStyle} onClick={() => this.onChangeBattingSide(1)}>R</div>
                                <div className={battingSideLeftStyle} onClick={() =>this.onChangeBattingSide(2)}>L</div>

                                
                            </div>
                            <PitchPlotter 
                                currentPitch = {null}
                                pitchLocationName={null}
                                pitchLocation = {null}
                                updateLocation={null}
                                clickLocation={null}
                                showPitcherBatters={this.state.showPitcherBatters}

                                //battingRight={this.state.battingRight}
                                //battingSideChange={this.battingSideChangeHandler}
                                width={222}
                                height={222}
                                //ballCount = {this.state.ballCount}
                                //strikeCount = {this.state.strikeCount}
                                >
                                {renderPitcherPitches}
                                
                            </PitchPlotter>
                            <div className={styles.PitchTypeButtons}>
                                <div className={[styles.InPlayButton, styles.PitchButton].join(" ")} onClick={()=>this.onFilterPitcherPitches("In Play")}>INPLAY</div>
                                <div className={[styles.SMButton, styles.PitchButton].join(" ")} onClick={()=>this.onFilterPitcherPitches("Miss")}>SM</div>
                                <div className={[styles.TakeButton, styles.PitchButton].join(" ")} onClick={()=>this.onFilterPitcherPitches("Take")}>Take</div>
                                <div className={[styles.SlapButton, styles.PitchButton].join(" ")} onClick={()=>this.onFilterPitcherPitches("Slap")}>Slap</div>
                                <div className={[styles.FoulButton, styles.PitchButton].join(" ")} onClick={()=>this.onFilterPitcherPitches("Foul")}>Foul</div>
                                <div className={[styles.BuntButton, styles.PitchButton].join(" ")} onClick={()=>this.onFilterPitcherPitches("Bunt")}>Bunt</div>
                            </div>

                            
                        </div>
                        <ZonesTable data={zonesTableData} onFilterZone={this.onFilterZone}/>
                        <PitchTypesTable data={pitchTypesTableData} onFilterPitchType={this.onFilterByPitchType}/>
                    </div>
                </div>

        if(this.props.training && this.props.training.trainingType === 'Batter'){
            renderPitcherAnalysis = null;
        }


        return(
            <>
            <Modal show={this.state.showBatterNotes} clickBackdrop={this.showBatterNotesCancelHandler}>
                <h3>Batter Notes</h3>
                <textarea className={styles.ForceQuitMessageArea} onChange={(event)=>this.batterNotesMessageChange(event)} value={this.state.batterNotes === null ? '' : this.state.batterNotes }></textarea>
                
                <Button clicked={this.submitBatterNotesHandler}>SAVE</Button>
            </Modal>
            <Modal show={this.state.showPitcherNotes} clickBackdrop={this.showPitcherNotesCancelHandler}>
                <h3>Pitcher Notes</h3>
                <textarea className={styles.ForceQuitMessageArea} onChange={(event)=>this.pitcherNotesMessageChange(event)} value={this.state.pitcherNotes === null? '' : this.state.pitcherNotes }></textarea>
                
                <Button clicked={this.submitPitcherNotesHandler}>SAVE</Button>
            </Modal>
            <div className={styles.PlayerAnalysis}>
                {title}
               
                {teamButtons}
                {renderBatterAnalysis}
                {renderPitcherAnalysis}
                
            </div>
            </>
        )
    }
}

const mapDispatchToProps = dispatch =>{
    return{
        onLoadGameState: (token, id)=>dispatch(
            ActionTypes.loadGameState(token, id)
        ),
        onLoadTrainingState: (token, id)=>dispatch(
            ActionTypes.loadTrainingState(token, id)
        ),
        onSetAwayTeamPlayers: (data) =>dispatch(
            ActionTypes.setAwayTeamPlayers(data)
        ),
        onSetHomeTeamPlayers: (data) =>dispatch(
            ActionTypes.setHomeTeamPlayers(data)
        ),
        onSaveGameState: (token, data)=>dispatch(
            ActionTypes.saveGameState(token, data)
        ),
        onSaveTrainingState: (token, data)=>dispatch(
            ActionTypes.saveTrainingState(token, data)
        ),
        onUpdatePlayer: (token, playerData)=>dispatch(
            ActionTypes.updatePlayer(token, playerData)
        )
    }
}


const mapStateToProps = state =>{
    return{

        game: state.atBat.game,
        homeTeam: state.atBat.homeTeam,
        awayTeam: state.atBat.awayTeam,
        rootHomeTeamPlayers: state.atBat.rootHomeTeamPlayers,
        rootAwayTeamPlayers: state.atBat.rootAwayTeamPlayers,
        homeTeamPlayers: state.atBat.homeTeamPlayers,
        awayTeamPlayers: state.atBat.awayTeamPlayers,
        currentInning: state.atBat.currentInning,
        currentPitcher: state.atBat.currentPitcher,
        currentHitter: state.atBat.currentHitter,
        battingRight: state.atBat.battingRight,
        atBatState: state.atBat,
        gameStateLoading: state.atBat.loading,
        token: state.auth.token,
        pitchArray: state.atBat.pitchArray,
        outs: state.atBat.outs,
        pitchNumber: state.atBat.pitchNumber,
        homeScore: state.atBat.homeScore,
        awayScore: state.atBat.awayScore,
        gameOver: state.atBat.gameOver,
        homeTeamLineup: state.atBat.homeTeamLineup,
        homeTeamCurrentPitcher: state.atBat.homeTeamCurrentPitcher,
        awayTeamLineup: state.atBat.awayTeamLineup,
        awayTeamCurrentPitcher: state.atBat.awayTeamCurrentPitcher,
        totalInnings: state.atBat.totalInnings,
        homeTeamCurrentBatterIndex: state.atBat.homeTeamCurrentBatterIndex,
        awayTeamCurrentBatterIndex: state.atBat.awayTeamCurrentBatterIndex,
        inningScores: state.atBat.inningScores,
        currentTopInningScore: state.atBat.currentTopInningScore,
        currentBottomInningScore: state.atBat.currentBottomInningScore,
        pitcherView: state.atBat.pitcherView,
        training: state.atBat.training,
        pitchType: state.atBat.pitchType
        
    }
}




export default connect(mapStateToProps, mapDispatchToProps) (PlayerAnalysis);


