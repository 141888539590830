import React from 'react';
import classes from './FilterTables.module.css';


const FilterTables = (props) =>{

    function getHitResultTotal(type, pitchArray){
        let ret = 0;
        for(let i=0;i<pitchArray.length; ++i){
            const p = pitchArray[i];
            if(p.hitResult === type){
                ret ++;
            }
            
        }
        return ret;
    }

    function getPowerPercent(type, pitchArray){
        let ret = 0;
        for(let i=0;i<pitchArray.length; ++i){
            const p = pitchArray[i];
            if(p.hitPower === type){
                ret ++;
            }
            ////console.log(p.hitPower);
        }
        if(pitchArray.length > 0){
            ret = ret / parseFloat(pitchArray.length);
            ret = (ret * 100).toFixed(0);
        }
        return ret;
    }

    function getActionPercent(type, pitchArray){
        let ret = 0;
        for(let i=0;i<pitchArray.length; ++i){
            const p = pitchArray[i];
            if(p.hitAction === type){
                ret ++;
            }
            ////console.log(p.hitPower);
        }
        if(pitchArray.length > 0){
            ret = ret / parseFloat(pitchArray.length);
            ret = (ret * 100).toFixed(0);
        }
        return ret;
    }


    let powerHard = getPowerPercent('Hard', props.pitchArray);
    let powerWeak = getPowerPercent('Weak', props.pitchArray);
    let powerNorm = getPowerPercent('Norm', props.pitchArray);

    let actionLine = getActionPercent('Line', props.pitchArray);
    let actionFly = getActionPercent('Fly', props.pitchArray);
    let actionPop = getActionPercent('Pop', props.pitchArray);
    let actionGround = getActionPercent('Ground', props.pitchArray);

    let b1 = getHitResultTotal('1B', props.pitchArray);
    let b2 = getHitResultTotal('2B', props.pitchArray);
    let b3 = getHitResultTotal('3B', props.pitchArray);
    let hr = getHitResultTotal('HR', props.pitchArray);
    let out = getHitResultTotal('OUT', props.pitchArray);
// ? : null 
/*
filterBatterPitchesByHitPower
        filterBatterPitchesByHitResult
        filterPitcherPitchLocation
        */
    return(
        <div className={classes.FilterTables}>
            <table className={classes.HitPowerTable}>
                <thead>
                    <tr>
                        <th style={props.filterBatterPitchesByHitPower === 'Hard' ? {backgroundColor:'white', color:'black'} : {}} onClick={()=>props.onFilterByHitPower('Hard')}>Hard</th>
                        <th style={props.filterBatterPitchesByHitPower === 'Weak' ? {backgroundColor:'white', color:'black'} : {}} onClick={()=>props.onFilterByHitPower('Weak')}>Weak</th>
                        <th style={props.filterBatterPitchesByHitPower === 'Norm' ? {backgroundColor:'white', color:'black'} : {}} onClick={()=>props.onFilterByHitPower('Norm')}>Normal</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td onClick={()=>props.onFilterByHitPower('Hard')}>{powerHard}%</td>
                        <td onClick={()=>props.onFilterByHitPower('Weak')}>{powerWeak}%</td>
                        <td onClick={()=>props.onFilterByHitPower('Norm')}>{powerNorm}%</td>
                    </tr>
                </tbody>
            </table>

            <table className={classes.HitTypeTable}>
                <thead>
                    <tr>
                        <th style={props.filterBatterPitchesByHitAction === 'Line' ? {backgroundColor:'white', color:'black'} : {}}onClick={()=>props.onFilterByHitAction('Line')}>Line</th>
                        <th style={props.filterBatterPitchesByHitAction === 'Ground' ? {backgroundColor:'white', color:'black'} : {}}onClick={()=>props.onFilterByHitAction('Ground')}>GB</th>
                        <th style={props.filterBatterPitchesByHitAction === 'Fly' ? {backgroundColor:'white', color:'black'} : {}}onClick={()=>props.onFilterByHitAction('Fly')}>FB</th>
                        <th style={props.filterBatterPitchesByHitAction === 'Pop' ? {backgroundColor:'white', color:'black'} : {}}onClick={()=>props.onFilterByHitAction('Pop')}>Pop</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td onClick={()=>props.onFilterByHitAction('Line')}>{actionLine}%</td>
                        <td onClick={()=>props.onFilterByHitAction('Ground')}>{actionGround}%</td>
                        <td onClick={()=>props.onFilterByHitAction('Fly')}>{actionFly}%</td>
                        <td onClick={()=>props.onFilterByHitAction('Pop')}>{actionPop}%</td>
                    </tr>
                </tbody>
            </table>

            <table className={classes.HitResultTable}>
                <thead>
                    <tr>
                        <th style={props.filterBatterPitchesByHitResult === '1B' ? {backgroundColor:'white', color:'black'} : {}}  onClick={()=>props.onFilterByHitResult('1B')}>1B</th>
                        <th style={props.filterBatterPitchesByHitResult === '2B' ? {backgroundColor:'white', color:'black'} : {}}  onClick={()=>props.onFilterByHitResult('2B')}>2B</th>
                        <th style={props.filterBatterPitchesByHitResult === '3B' ? {backgroundColor:'white', color:'black'} : {}}  onClick={()=>props.onFilterByHitResult('3B')}>3B</th>
                        <th style={props.filterBatterPitchesByHitResult === 'HR' ? {backgroundColor:'white', color:'black'} : {}}  onClick={()=>props.onFilterByHitResult('HR')}>HR</th>
                        <th style={props.filterBatterPitchesByHitResult === 'OUT' ? {backgroundColor:'white', color:'black'} : {}}  onClick={()=>props.onFilterByHitResult('OUT')}>OUT</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td onClick={()=>props.onFilterByHitResult('1B')}>{b1}</td>
                        <td onClick={()=>props.onFilterByHitResult('2B')}>{b2}</td>
                        <td onClick={()=>props.onFilterByHitResult('3B')}>{b3}</td>
                        <td onClick={()=>props.onFilterByHitResult('HR')}>{hr}</td>
                        <td onClick={()=>props.onFilterByHitResult('OUT')}>{out}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )

}

export  default FilterTables;