import React, {Component} from 'react';
//import Input from '../../components/UI/Input/Input';
//import Button from '../../components/UI/Button/Button';
import Spinner from '../../components/UI/Spinner/Spinner';
import classes from './Teams.module.css';
import { connect } from 'react-redux';
import * as ActionTypes from '../../store/actions/index';
import TeamForm from './TeamForm/TeamForm';
import TeamImportForm from './TeamImportForm/TeamImportForm';
import Modal from '../../components/UI/Modal/Modal';
import Button from '../../components/UI/Button/Button';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

class Teams extends Component{
    constructor(props){
        super(props);
        this.state = {
            columnDefs: [
            {
                headerName: "ID", field: "id", sortable: false, filter: false, hide: true
            },
            {
                headerName: "Team Name", field: "name", sortable: true, filter: true, cellStyle: {justifyContent: 'center'},  autoHeight: true, cellStyle: {'white-space': 'normal'}
            },  {
                headerName: "Year", field: "year", sortable: true, filter: true, cellStyle: {justifyContent: 'center'},  autoHeight: true, cellStyle: {'white-space': 'normal'}
            }
            ,{
                headerName: "External ID", field: "externalid", sortable: true, filter: true, cellStyle: {justifyContent: 'center'},  autoHeight: true, cellStyle: {'white-space': 'normal'}
            }
            ,{
                headerName: "Primary", field: "primary", sortable: true, filter: true, cellStyle: {justifyContent: 'center'},  autoHeight: true, cellStyle: {'white-space': 'normal'}
            },{
                headerName: "Imported", field: "cloned", sortable: true, filter: true, cellStyle: {justifyContent: 'center'},  autoHeight: true, cellStyle: {'white-space': 'normal'}
            }
            ,{
                headerName: "Scouting Report", field: "notes", sortable: false, filter: true, cellStyle: {justifyContent: 'center'},  autoHeight: true, cellStyle: {'white-space': 'normal'}
            }
            ,{
                headerName: "Delete", field: "delete", sortable: false, filter: true, cellStyle: {justifyContent: 'center'},  autoHeight: true, cellStyle: {'white-space': 'normal'}
            }
            ],
            rowData: [],
            showCreateTeam : false,
            showImportTeam: false,
            showNotes: false,
            teamData: null,
            showDelete: false,
            reloadTeams: false

        }
        
    }

    componentDidMount(){
        ////console.log(this.props);
        //this.props.onLoadTeams(this.props.token);
        if(this.props.auser && this.props.auser.org){
            this.props.onLoadOrgTeams(this.props.token, this.props.auser.organizationId);
            this.props.onGetImportTeams(this.props.token, this.props.auser.organizationId, this.props.auser.org.teamType);
        }
        this.props.onClearGameState();
    }
    componentDidUpdate(prevProps, prevState){
        //console.log('Teams componentDidUpdate');
        if(prevProps.auser !== this.props.auser){
            if(this.props.auser && this.props.auser.org){
                this.props.onLoadOrgTeams(this.props.token, this.props.auser.organizationId);
                this.props.onGetImportTeams(this.props.token, this.props.auser.organizationId, this.props.auser.org.teamType);
            }
        }
        if(prevProps.teams !== this.props.teams){
            //console.log('RELOAD TEAMS!')
        }
    }

    canEdit = () =>{

        if(this.props.auser && (this.props.auser.role === 'SysAdmin' ||
        this.props.auser.role === 'OrgAdmin' ||
        this.props.auser.role === 'Admin' ||
        this.props.auser.role === 'OrgAdminTraining' ||
        this.props.auser.role === 'AdminTraining')){
            return true;
        }
        return false;
    }

    onGridReady = params => {
        ////console.log('onGridReady!');
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
    }

    onCreateTeamCancel = () =>{
        this.setState({
            showCreateTeam: false
        })
    }
    onCreateTeam = () =>{
        this.setState({
            showCreateTeam: true
        })
    }

    onImportTeamCancel = () =>{
        this.setState({
            showImportTeam: false
        })
    }
    onImportTeam = () =>{
        this.setState({
            showImportTeam: true
        })
    }

    showNoteCancel = () =>{
        this.setState({
            showNotes: false
        })
    }
    showDeleteCancel = () =>{
        this.setState({
            showDelete: false
        })
    }

    onCellClicked = (result) =>{
        //console.log("onCellClicked", result);
        if(result.colDef.field === 'notes'){


            /*//console.log("Show Notes for", result.data.id);
            this.setState({
                showNotes: true,
                teamData: result.data
            })*/
            this.props.history.push({
                pathname: '/team_scouting_report/' + result.data.id
            });
            
        }
        else if(result.colDef.field === 'delete'){
            if(this.canEdit()){
                //console.log("Delete Team ", result.data.id);
                this.setState({
                    showDelete: true,
                    teamData: result.data
                })
            }
        }
        else{
            this.props.history.push({
                pathname: '/team/' + result.data.id
            });
        }
    }

    submitImportHandler = (event, teamData) =>{
        event.preventDefault();
        //console.log('calling submitImportHandler ', teamData);

        //console.log('need to clone team ' + teamData.id +' for organization ' + this.props.auser.organizationId);

        this.props.onCloneTeam(this.props.token, teamData.id, this.props.auser.organizationId);
    }

    submitHandler = (event, teamData) =>{
        event.preventDefault();
        ////console.log('calling submitHandler in Team ' 
        //+ teamData.name+" "
        //+ teamData.year+" "
        //+ teamData.id);

        let updatedTeamDate = {...teamData, organizationId: this.props.auser.organizationId}

        if(teamData.id !== null){
            this.props.onUpdateTeam(this.props.token, updatedTeamDate);
        }else{
            this.props.onCreateTeam(this.props.token, updatedTeamDate);
        }
        this.setState({
            showCreateTeam: false
        })
        
    }

    
    addPlayerHandler = (event, id) =>{
        ////console.log('Teams->addPlayerHandler '+ id, event);
    }

    teamClickHandler = (event, id) =>{
        ////console.log('Teams->teamClickHandler ' + id, event);
        ////console.log('team?id=' + id);
        ////console.log(this.props.match.url);

        this.props.history.push({
            pathname: '/team/' + id
        });
    }

    onDeleteTeam = () =>{
       
        //console.log('Delete Team!' + this.state.teamData.id);
        this.setState({
            showDelete:false
        })
        //let updatedTeam = {...this.state.teamData, hidden: true};

        let updatedTeam = null;
        for(let i=0;i<this.props.teams.length; ++i){
            let t = this.props.teams[i];
            if(t.id === this.state.teamData.id){
                updatedTeam = {...t,  hidden: true}
            }
        }
        this.props.onUpdateTeam(this.props.token,updatedTeam);
        this.props.history.push('/teams');
    }

   

    render (){
        
        let form  =  
                    <Modal show={this.state.showCreateTeam} clickBackdrop={this.onCreateTeamCancel}>
                    <TeamForm team={null} submit={this.submitHandler}/>
                    </Modal>
        let importForm  =  
                    <Modal show={this.state.showImportTeam} clickBackdrop={this.onImportTeamCancel}>
                    <TeamImportForm team={null} submit={this.submitImportHandler}/>
                    </Modal>
        let noteForm = null;
        let deleteForm = null;

        if(this.state.teamData){
            const noteData = typeof this.state.teamData.noteData == "undefined" ? "" : this.state.teamData.noteData;
            noteForm = <Modal show={this.state.showNotes} clickBackdrop={this.showNoteCancel}>
                <h3>{this.state.teamData.name}</h3>
                    <textarea disabled className={classes.NotesArea} value={noteData}></textarea>
                </Modal>
            
            deleteForm = <Modal show={this.state.showDelete} clickBackdrop={this.showDeleteCancel}>
                <h3>{this.state.teamData.name} {this.state.teamData.year} </h3>
                <p>Deleting this team will remove it from your organization.  Are you sure?</p>
                <Button clicked={this.onDeleteTeam} btnType="Red">DELETE TEAM</Button>
            </Modal>
        }

        if(this.props.loading){
            form = <Spinner />;
            
        }
        if(this.props.importLoading){
            importForm = <Spinner />;
        }
        /*let message = null;
        if(this.props.error){
            message = <span className={classes.Error}>{this.props.message}</span>
        }
        else if(this.props.message !== null){
            message = <span className={classes.Success}>{this.props.message}</span>
        }*/
        
        ////console.log('Teams:');
        ////console.log(this.props.teams);

        //let teams = null;
        let teamArray = [];


        if(this.props.teams){
            for(let i=0;i<this.props.teams.length; ++i){
                let t = this.props.teams[i];
                if(typeof t.hidden !== 'undefined' && t.hidden === true){
                    continue;
                }
                else{
                    teamArray.push({
                        id: t.id,
                        name: t.name,
                        externalid: t.externalid,
                        year: t.year,
                        primary: t.primary,
                        cloned: t.cloned === true ? "Yes" : "No",
                        notes: "Report",
                        noteData: t.notes,
                        delete: "Delete"
                    });
                }
            }


            /*teams = this.props.teams.map(team =>{
                return(
                    <Team key={team.id} 
                    team={team} 
                    teamClick={(event)=> this.teamClickHandler(event, team.id)} />
                )
            });*/
        }
        else{
            //teams = <Spinner />
        }

        //this.state.rowData = teamArray;
        
        let tableJSX = <div 
                            className="ag-theme-balham-dark"
                            style={{ 
                            height: '400px', 
                            width: 'inherit',
                            marginLeft :'auto',
                            marginRight : 'auto',
                            fontSize:'15px',
                            fontFamily:'inherit'
                        }} 
                        >
                            <AgGridReact
                            // useful for accessing the component directly via ref
                            ref="agGrid"
                            onCellClicked={this.onCellClicked.bind(this)}
                            columnDefs={this.state.columnDefs}
                            //rowData={this.state.rowData}
                            rowData={teamArray}
                            onGridReady={this.onGridReady}>
                            </AgGridReact>
                        </div>

        return(
            
            <div className={classes.Team}>
                {form}
                {importForm}
                {noteForm}
                {deleteForm}
                <Button clicked={this.onCreateTeam} btnType="Danger">CREATE TEAM</Button>
                <Button clicked={this.onImportTeam} btnType="Danger">IMPORT TEAM</Button>
                
                <h3>Teams</h3>
                {tableJSX}
            </div>
        )
    }
}

const mapDispatchToProps = dispatch =>{
    return{
        onCreateTeam: (token, payload)=>dispatch(
            ActionTypes.createTeam(token, payload)
        ),
        onUpdateTeam: (token, payload)=>dispatch(
            ActionTypes.updateTeam(token,payload)
        ),
        onLoadOrgTeams: (token, orgId)=>dispatch(
            ActionTypes.getOrgTeams(token, orgId)
        ),
        onClearGameState: ()=>dispatch(
            ActionTypes.clearGameState()
        ),
        onGetImportTeams: (token, organizationId, teamType)=>dispatch(
            ActionTypes.getImportTeams(token,organizationId, teamType)
        ),
        onCloneTeam: (token, teamId, organizationId)=>dispatch(
            ActionTypes.cloneTeam(token, teamId, organizationId)
        ),
        onDeleteTeam: (token, teamId)=>dispatch(
            ActionTypes.deleteTeam(token, teamId)
        ),
        onUpdateTeam: (token, data)=>dispatch(
            ActionTypes.updateTeam(token, data)
        )
    }
}

const mapStateToProps = state =>{
    return{
        error: state.team.error,
        message: state.team.message,
        loading: state.team.loading,
        importLoading: state.team.importLoading,
        auser: state.auth.user,
        token: state.auth.token,
        teams: state.team.teams
    };
}

export default connect(mapStateToProps, mapDispatchToProps) (Teams);