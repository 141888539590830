import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import AtBat from './containers/AtBat/AtBat';
import PlayerAnalysis from './containers/PlayerAnalysis/PlayerAnalysis';
import PitcherTrainingAnalysis from './containers/PlayerAnalysis/PitcherTrainingAnalysis/PitcherTrainingAnalysis';
import BatterTrainingAnalysis from './containers/PlayerAnalysis/BatterTrainingAnalysis/BatterTrainingAnalysis';
import GameAnalysis from './containers/GameAnalysis/GameAnalysis';
import Layout from './hoc/Layout/Layout';
import Game from './containers/Games/Games';
import Trainings from './containers/Trainings/Trainings';
import Auth from './containers/Auth/Auth';
import Logout from './containers/Auth/Logout/Logout';
import Teams from './containers/Teams/Teams';
import Team from './containers/Team/Team';
import Dashboard from './components/Navigation/Dashboard/Dashboard';
import Admin from './components/Navigation/Admin/Admin';
import Users from './containers/Admin/Users/Users';
import MyAccount from './containers/MyAccount/MyAccount';
import PlayerScoutingReport from './containers/PlayerScoutingReport/PlayerScoutingReport';
import ScoutingReportSummary from './containers/PlayerScoutingReport/ScoutingReportSummary/ScoutingReportSummary';
import ResetPassword from './containers/Auth/ResetPassword/ResetPassword';
import TeamScoutingReport from './containers/Team/ScoutingReport/ScoutingReport';

import {connect} from 'react-redux';
import * as ActionTypes from './store/actions/index';


class App extends Component {
  componentDidMount(){
    this.props.authCheckState();
  }

  render(){
    
    
    let routes = (
      <Switch>
        <Route path="/auth" component={Auth} />
        <Route path="/" exact component={Dashboard} />
      </Switch>
    )

        
    if(this.props.isAuth && this.props.user && this.props.user.role === 'SysAdmin'){
      routes = (
        <Switch>
        <Route path="/admin" component={Admin} />
        <Route path="/users" component={Users} />
        <Route path="/auth" component={Auth} />
        <Route path="/register" component={Auth} />
        <Route path="/myaccount" component={MyAccount} />
        <Route path="/logout" component={Logout} />
        <Route path="/teams" component={Teams}/>
        <Route path="/game/:id" component={AtBat}/>
        <Route path="/training/:id" component={AtBat}/>
        <Route path="/game_analysis/:id" component={GameAnalysis} />
        <Route path="/player_analysis/:id" component={PlayerAnalysis} />
        <Route path="/player_scouting_report/:id" component={PlayerScoutingReport} />
        <Route path="/player_training_scouting_report/:id" component={PlayerScoutingReport} />
        <Route path="/team_scouting_report/:id" component={TeamScoutingReport} />
        

        <Route path="/scouting_report_summary/:id" component={ScoutingReportSummary} />
        <Route path="/training_scouting_report_summary/:id" component={ScoutingReportSummary} />

        <Route path="/player_training_analysis/:id" component={PlayerAnalysis} />
        <Route path="/pitcher_training_analysis/:id" component={PitcherTrainingAnalysis} />
        <Route path="/batter_training_analysis/:id" component={BatterTrainingAnalysis} />
        <Route path="/player_training_analysis/:id" render={(props) => <PlayerAnalysis {...props} isTraining={true} />} />
        <Route path="/team/:id" component={Team}/>
        <Route path="/games"  component={Game} />
        <Route path="/trainings"  component={Trainings} />
        <Route path="/reset" component={ResetPassword} />
        <Route path="/" exact component={Dashboard} />
       
        
        <Route render={() => <h1></h1>}/>
      </Switch>
      )
    }
    else if(this.props.isAuth && this.props.user && this.props.user.role === 'Admin'){
      routes = (
        <Switch>
        <Route path="/auth" component={Auth} />
        <Route path="/myaccount" component={MyAccount} />
        <Route path="/logout" component={Logout} />
        <Route path="/game/:id" component={AtBat}/>
        <Route path="/training/:id" component={AtBat}/>
        <Route path="/game_analysis/:id" component={GameAnalysis} />
        <Route path="/player_analysis/:id" component={PlayerAnalysis} />
        <Route path="/player_scouting_report/:id" component={PlayerScoutingReport} />
        <Route path="/player_training_scouting_report/:id" component={PlayerScoutingReport} />
        <Route path="/scouting_report_summary/:id" component={ScoutingReportSummary} />
        <Route path="/training_scouting_report_summary/:id" component={ScoutingReportSummary} />
        <Route path="/team_scouting_report/:id" component={TeamScoutingReport} />

        <Route path="/player_training_analysis/:id" component={PlayerAnalysis} />
        <Route path="/pitcher_training_analysis/:id" component={PitcherTrainingAnalysis} />
        <Route path="/batter_training_analysis/:id" component={BatterTrainingAnalysis} />
        <Route path="/player_training_analysis/:id" render={(props) => <PlayerAnalysis {...props} isTraining={true} />} />
        <Route path="/games"  component={Game} />
        <Route path="/trainings"  component={Trainings} />
        <Route path="/reset" component={ResetPassword} />
        <Route path="/" exact component={Dashboard} />
      
        
        <Route render={() => <h1></h1>}/>
      </Switch>
      )
    }
    else if(this.props.isAuth && this.props.user && this.props.user.role === 'OrgAdmin'){
      routes = (
        <Switch>
        <Route path="/admin" component={Admin} />
        <Route path="/users" component={Users} />
        <Route path="/auth" component={Auth} />
        <Route path="/myaccount" component={MyAccount} />
        <Route path="/logout" component={Logout} />
        <Route path="/teams" component={Teams}/>
        <Route path="/game/:id" component={AtBat}/>
        <Route path="/training/:id" component={AtBat}/>
        <Route path="/game_analysis/:id" component={GameAnalysis} />
        <Route path="/player_analysis/:id" component={PlayerAnalysis} />
        <Route path="/player_scouting_report/:id" component={PlayerScoutingReport} />
        <Route path="/player_training_scouting_report/:id" component={PlayerScoutingReport} />
        <Route path="/scouting_report_summary/:id" component={ScoutingReportSummary} />
        <Route path="/training_scouting_report_summary/:id" component={ScoutingReportSummary} />
        <Route path="/team_scouting_report/:id" component={TeamScoutingReport} />
        
        <Route path="/player_training_analysis/:id" component={PlayerAnalysis} />
        <Route path="/pitcher_training_analysis/:id" component={PitcherTrainingAnalysis} />
        <Route path="/batter_training_analysis/:id" component={BatterTrainingAnalysis} />
        <Route path="/player_training_analysis/:id" render={(props) => <PlayerAnalysis {...props} isTraining={true} />} />
        <Route path="/team/:id" component={Team}/>
        <Route path="/games"  component={Game} />
        <Route path="/trainings"  component={Trainings} />
        <Route path="/reset" component={ResetPassword} />
        <Route path="/" exact component={Dashboard} />
       
        
        <Route render={() => <h1></h1>}/>
      </Switch>
      )
    }
    else if(this.props.isAuth && this.props.user && this.props.user.role === 'OrgAdminTraining'){
      routes = (
        <Switch>
        <Route path="/admin" component={Admin} />
        <Route path="/users" component={Users} />
        <Route path="/auth" component={Auth} />
        <Route path="/myaccount" component={MyAccount} />
        <Route path="/logout" component={Logout} />
        <Route path="/teams" component={Teams}/>
        <Route path="/training/:id" component={AtBat}/>
        <Route path="/player_analysis/:id" component={PlayerAnalysis} />
        <Route path="/player_scouting_report/:id" component={PlayerScoutingReport} />
        <Route path="/player_training_scouting_report/:id" component={PlayerScoutingReport} />
        <Route path="/scouting_report_summary/:id" component={ScoutingReportSummary} />
        <Route path="/training_scouting_report_summary/:id" component={ScoutingReportSummary} />
        <Route path="/team_scouting_report/:id" component={TeamScoutingReport} />
        
        <Route path="/player_training_analysis/:id" component={PlayerAnalysis} />
        <Route path="/pitcher_training_analysis/:id" component={PitcherTrainingAnalysis} />
        <Route path="/batter_training_analysis/:id" component={BatterTrainingAnalysis} />
        <Route path="/player_training_analysis/:id" render={(props) => <PlayerAnalysis {...props} isTraining={true} />} />
        <Route path="/team/:id" component={Team}/>
        <Route path="/trainings"  component={Trainings} />
        <Route path="/reset" component={ResetPassword} />
        <Route path="/" exact component={Dashboard} />
       
        
        <Route render={() => <h1></h1>}/>
      </Switch>
      )
    }
    else if(this.props.isAuth && this.props.user && this.props.user.role === 'Viewer'){
      routes = (
        <Switch>
        <Route path="/auth" component={Auth} />
        <Route path="/myaccount" component={MyAccount} />
        <Route path="/logout" component={Logout} />
        <Route path="/teams" component={Teams}/>
        <Route path="/game/:id" component={AtBat}/>
        <Route path="/training/:id" component={AtBat}/>
        <Route path="/game_analysis/:id" component={GameAnalysis} />
        <Route path="/player_analysis/:id" component={PlayerAnalysis} />
        <Route path="/player_scouting_report/:id" component={PlayerScoutingReport} />
        <Route path="/player_training_scouting_report/:id" component={PlayerScoutingReport} />
        <Route path="/scouting_report_summary/:id" component={ScoutingReportSummary} />
        <Route path="/training_scouting_report_summary/:id" component={ScoutingReportSummary} />
        <Route path="/team_scouting_report/:id" component={TeamScoutingReport} />
        
        <Route path="/player_training_analysis/:id" component={PlayerAnalysis} />
        <Route path="/pitcher_training_analysis/:id" component={PitcherTrainingAnalysis} />
        <Route path="/batter_training_analysis/:id" component={BatterTrainingAnalysis} />
        <Route path="/player_training_analysis/:id" render={(props) => <PlayerAnalysis {...props} isTraining={true} />} />
        <Route path="/team/:id" component={Team}/>
        <Route path="/games"  component={Game} />
        <Route path="/trainings"  component={Trainings} />
        <Route path="/reset" component={ResetPassword} />
        <Route path="/" exact component={Dashboard} />
       
        
        <Route render={() => <h1></h1>}/>
      </Switch>
      )
    }
    else if(this.props.isAuth && this.props.user && this.props.user.role === 'ViewerTraining'){
      routes = (
        <Switch>
        <Route path="/auth" component={Auth} />
        <Route path="/myaccount" component={MyAccount} />
        <Route path="/logout" component={Logout} />
        <Route path="/teams" component={Teams}/>
        <Route path="/training/:id" component={AtBat}/>
        <Route path="/player_analysis/:id" component={PlayerAnalysis} />
        <Route path="/player_scouting_report/:id" component={PlayerScoutingReport} />
        <Route path="/player_training_scouting_report/:id" component={PlayerScoutingReport} />
        <Route path="/scouting_report_summary/:id" component={ScoutingReportSummary} />
        <Route path="/training_scouting_report_summary/:id" component={ScoutingReportSummary} />
        
        <Route path="/team_scouting_report/:id" component={TeamScoutingReport} />

        <Route path="/player_training_analysis/:id" component={PlayerAnalysis} />
        <Route path="/pitcher_training_analysis/:id" component={PitcherTrainingAnalysis} />
        <Route path="/batter_training_analysis/:id" component={BatterTrainingAnalysis} />
        <Route path="/player_training_analysis/:id" render={(props) => <PlayerAnalysis {...props} isTraining={true} />} />
        <Route path="/team/:id" component={Team}/>
        <Route path="/trainings"  component={Trainings} />
        <Route path="/reset" component={ResetPassword} />
        <Route path="/" exact component={Dashboard} />
       
        
        <Route render={() => <h1></h1>}/>
      </Switch>
      )
    }
    else if(this.props.isAuth && this.props.user){
      routes = (
        <Switch>
        <Route path="/auth" component={Auth} />
        <Route path="/myaccount" component={MyAccount} />
        <Route path="/logout" component={Logout} />
        <Route path="/reset" component={ResetPassword} />
        <Route path="/" exact component={Dashboard} />
        
        
        <Route render={() => <h1></h1>}/>
      </Switch>
      )
    }
    else{
      routes = (
        <Switch>
          <Route path="/auth" component={Auth} />
          <Route path="/" exact component={Game} />
          <Route render={() => <h1></h1>}/>
        </Switch>
      )
    }
    

    return(
      <Layout>
        {routes}
    </Layout>
    )
  }
}



const mapStateToProps = state =>{
  return{
    isAuth: state.auth.token !== null,
    user: state.auth.user
  }
}

const mapDispatchToProps = dispatch =>{
  return{
      authCheckState: ()=>dispatch(
          ActionTypes.authCheckState()
      )
  }
}


 export default connect(mapStateToProps, mapDispatchToProps) (App);
