import React from 'react';
import styles from './Pitch.module.css';

const Pitch = (props) => {

    /*let bgColor = 'yellow';
    if(props.pitch.result === 'Take'){
        bgColor = 'green';
    }
    else{
        if(props.pitch.batActionType === 'Bunt'){
            bgColor = '#805f01';
        }
        else if(props.pitch.batActionType === 'Slap'){
            bgColor = '#702fa0';
        }
        else if(props.pitch.batAction === 'Miss'){
            bgColor = 'red';
        }
        else if(props.pitch.batAction === 'In Play'){
            bgColor = '#6386c9';
        }
        else if(props.pitch.batAction === 'Foul'){
            bgColor = '#ffc000';
        }
    }*/
    let bgColor = props.strike ? 'red' : 'green';
    if(typeof props.pitch !== 'undefined' && props.pitch){
        if(props.pitch.result === 'Take'){
            bgColor = 'green';
        }
        else{
            if(props.pitch.batActionType === 'Bunt'){
                bgColor = '#805f01';
            }
            else if(props.pitch.batActionType === 'Slap'){
                bgColor = '#702fa0';
            }
            else if(props.pitch.batAction === 'Miss'){
                bgColor = 'red';
            }
            else if(props.pitch.batAction === 'In Play'){
                bgColor = '#6386c9';
            }
            else if(props.pitch.batAction === 'Foul'){
                bgColor = '#ffc000';
            }
        }
    }
    return(
        
        <div className={styles.Pitch} style={
            {top: props.y,
            left: props.x ,
            
            backgroundColor: bgColor,
            display: props.renderPitch ? 'block' : 'none'
            }}>
            <div>{props.index}</div>
            
            {/*
            <div>{props.pitch !== null ? props.pitch.pitchType : null}</div>
            {props.pitch !== null ? props.pitch.result : null}
            */}
            </div>

    )
}

export default Pitch;