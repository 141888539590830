import React from 'react';
import styles from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';

const NavigationItems = (props) =>{
    let showGames = false;
    let showTrainings = false;
    let showTeams = false;
    let showLogout = false;
    let showLogin = false;
    let showAdmin = false;
    let showHome = false;
    let showMyAccount = false;
    if(props.isAuth && (props.role === 'SysAdmin' ||
                        props.role === 'OrgAdmin')){
        showGames = true;
        showTrainings = true;
        showTeams = true;
        showAdmin = true;
        showMyAccount = true;
    }
    if(props.isAuth && props.role === 'Admin'){
        showGames = true;
        showTrainings = true;
        showMyAccount = true;
    }
    if(props.isAuth && props.role === 'Viewer'){
        showTrainings = true;
        showGames = true;
        showMyAccount = true;
        showTeams = true;
    }
    if(props.isAuth && props.role === 'OrgAdminTraining'){
        showTrainings = true;
        showTeams = true;
        showAdmin = true;
        showMyAccount = true;
    }
    if(props.isAuth && props.role === 'AdminTraining'){
        showTrainings = true;
        showTeams = true;
        showMyAccount = true;
    }
    if(props.isAuth && props.role === 'ViewerTraining'){
        showTrainings = true;
        showMyAccount = true;
        showTeams = true;
    }

    if(props.isAuth){
        showLogout = true;
        showHome = true;
        showMyAccount = true;
    }
    else{
        showLogin = true;
    }
    

    return(
        <ul className={styles.NavigationItems}>
            {showHome ? <NavigationItem link ="/" active>
                Home
            </NavigationItem> : null}
            {showMyAccount ? <NavigationItem link="/myaccount">
                Account
            </NavigationItem> : null}
            {showGames ? <NavigationItem link="/games">
                Games
            </NavigationItem> : null}
            {showTrainings ? <NavigationItem link="/trainings">
                Trainings
            </NavigationItem> : null}
            {showTeams ? <NavigationItem link="/teams">
                Teams
            </NavigationItem> : null}
            {showLogin ? <NavigationItem link="/auth">
                Login
            </NavigationItem> : null}
            
            {showAdmin ? <NavigationItem link="/admin">
                Admin
            </NavigationItem> : null}
            {showLogout ? 
            <NavigationItem link="/logout">
                Logout
            </NavigationItem> : null}
            <NavigationItem link="https://thescoutbook.com/overview" external={true}>
                Help
            </NavigationItem>

        </ul>
    )
}

export default NavigationItems;