import * as actionTypes from './actionTypes';
import axios from '../../axios';




export const trainingStart = () =>{
    return{
        type:actionTypes.TRAINING_START
    };
}


export const createTrainingStart = () =>{
    return{
        type:actionTypes.CREATE_TRAINING_START
    };
}

export const createTrainingSuccess = (id, data) =>{
    return{
        type:actionTypes.CREATE_TRAINING_SUCCESS,
        id: id,
        data: data
    };
}

export const createTrainingFail = (error) =>{
    return{
        type:actionTypes.CREATE_TRAINING_FAIL,
        error: error
    };
}



export const createTraining = (token, data) =>{
    return dispatch =>{
        dispatch(createTrainingStart());
        axios.post('/trainings.json?auth=' + token, data)
            .then(response =>{
                ////console.log("id="+response.data.name);
                dispatch(createTrainingSuccess(response.data.name, data));
            })
            .catch(error =>{
                //console.log(error);
                dispatch(createTrainingFail(error));
            });
    }
}


export const deleteTrainingSuccess = (id) =>{
    return{
        type:actionTypes.DELETE_TRAINING_SUCCESS,
        id: id
    }
}

export const deleteTraining = (token, id) =>{
    return dispatch =>{
        //console.log('delete training  ' + id);
        //dispatch(createGameStart());
        axios.delete('trainings/'+id+'.json?auth=' + token)
            .then(response =>{
                //console.log("id=",response);
                dispatch(deleteTrainingSuccess(id));
            })
            .catch(error =>{
                //console.log('error', error);
                //dispatch(createGameFail(error.response.data.error));
            });
    }
}






export const getTrainingsStart = () =>{
    return{
        type:actionTypes.GET_TRAININGS_START
    };
}

export const getTrainingsSuccess = (data) =>{
    return{
        type:actionTypes.GET_TRAININGS_SUCCESS,
        data: data
    };
}

export const getTrainingsFail = (error) =>{
    return{
        type:actionTypes.GET_TRAININGS_FAIL,
        error: error
    };
}

export const getTrainings = (token, userId) =>{
    return dispatch =>{
        dispatch(getTrainingsStart());

        const queryParams = '?auth=' + token + '&orderBy="userId"&equalTo="' + userId + '"';
        //axios.get('/trainings.json?auth=' + token)
        axios.get('/trainings.json' + queryParams)
        .then(response => {
            //console.log('getTrainings action:', response.data);
            const trainings = [];
            for(let key in response.data){
                trainings.push({
                    ...response.data[key],
                    id: key
                });
            }
            ////console.log(trainings);
            for(let i=0; i < trainings.length; ++i){
                let t = trainings[i];
                t.pitchType = typeof t.pitchType === 'undefined' ? 'Softball' : t.pitchType;
            }
            ////console.log('trainings, ', trainings);
            
            dispatch(getTrainingsSuccess(trainings));
        })
        .catch(error =>{
            dispatch(getTrainingsFail());
        });
    }
}

export const getOrgTrainings = (token, organizationId) =>{
    return dispatch =>{
        dispatch(getTrainingsStart());

        const queryParams = '?auth=' + token + '&orderBy="organizationId"&equalTo="' + organizationId + '"';
        //axios.get('/trainings.json?auth=' + token)
        axios.get('/trainings.json' + queryParams)
        .then(response => {
            //console.log('getTrainings action:', response.data);
            const trainings = [];
            for(let key in response.data){
                trainings.push({
                    ...response.data[key],
                    id: key
                });
            }
            ////console.log(trainings);
            for(let i=0; i < trainings.length; ++i){
                let t = trainings[i];
                t.pitchType = typeof t.pitchType === 'undefined' ? 'Softball' : t.pitchType;
            }
            ////console.log('trainings, ', trainings);
            
            dispatch(getTrainingsSuccess(trainings));
        })
        .catch(error =>{
            dispatch(getTrainingsFail());
        });
    }
}


export const loadTrainingStates = (token, organizationId) =>{
    return dispatch =>{
        dispatch(loadTrainingStatesStart());
        //console.log('loadTrainingStates', organizationId);
        //const queryParams = '?auth=' + token + '&orderBy="userID"&equalTo="'+userID+'"';
        const queryParams = '?auth=' + token;
        //const queryParams = '?auth=' + token + '&orderBy="organizationId"&equalTo="' + organizationId + '"';
        //console.log('queryParams', queryParams);
        axios.get('trainingstate.json' + queryParams)
        .then(response => {
            let trainingstates = [];
            for(let key in response.data){
                if(typeof response.data[key].training.organizationId !== 'undefined' && response.data[key].training.organizationId === organizationId){
                   
                    trainingstates.push( {...response.data[key], atBatID: key, userID: response.data[key].training.userId});
                }
            }
            //console.log('trainingstates', trainingstates)
            if(trainingstates){
                dispatch(loadTrainingStatesSuccess(trainingstates));
            }
            else{

                dispatch(loadTrainingStatesFail());
            }
            
        })
        .catch(error =>{
            //console.log(error)
            //dispatch(getTeamPlayersFail());
            dispatch(loadTrainingStatesFail());
        });
    }
}



//



export const getTrainingStart = () =>{
    return{
        type:actionTypes.GET_TRAINING_START
    };
}

export const getTrainingSuccess = (data) =>{
    return{
        type:actionTypes.GET_TRAINING_SUCCESS,
        data: data
    };
}

export const getTrainingFail = (error) =>{
    return{
        type:actionTypes.GET_TRAINING_FAIL,
        error: error
    };
}

export const getTraining = (token, id) =>{
    return dispatch =>{
        dispatch(getTrainingStart());
        axios.get('/trainings/'+id +'.json?auth=' + token)
        .then(response => {
            ////console.log('getTraining action:', response.data);
            let trainings = [];
            if(response.data !== null){
                trainings.push({
                    ...response.data,
                    userID: response.data.userId,
                    id: id
                });
                dispatch(getTrainingSuccess(trainings));
            }
            else{
                dispatch(getTrainingFail('no results found for id ' + id));
            }
            
        })
        .catch(error =>{
            dispatch(getTrainingFail());
        });
    }
}

export const loadTrainingStateStart = () =>{
    return{
        type:actionTypes.LOAD_TRAINING_TRAINING_STATE_START
    };
}

export const loadTrainingStateFail = (error) =>{
    return{
        type:actionTypes.LOAD_TRAINING_TRAINING_STATE_FAIL,
        error
    };
}

export const loadTrainingStateSuccess = (data) =>{
    return{
        type:actionTypes.LOAD_TRAINING_TRAINING_STATE_SUCCESS,
        data: data
    };
}

export const loadTrainingTrainingState = (token, trainingId) =>{
    return dispatch =>{
        dispatch(loadTrainingStateStart());
        const queryParams = '?auth=' + token + '&orderBy="trainingID"&equalTo="'+trainingId+'"';
        axios.get('/trainingstate.json' + queryParams)
        .then(response => {
            //console.log('trainingstate response', response);
            let trainingstate = null;
            for(let key in response.data){
                trainingstate = { ...response.data[key], atBatID: key}
            }
            //console.log('trainingstate after spread', trainingstate);
            if(trainingstate){
                //console.log('training state not null, dispatching loadTrainingStateSuccess');
                dispatch(loadTrainingStateSuccess(trainingstate));
            }
            else{

                dispatch(loadTrainingStateFail());
            }
            
        })
        .catch(error =>{
            //dispatch(getTeamPlayersFail());
        });
    }
}

//////



export const loadTrainingStatesStart = () =>{
    return{
        type:actionTypes.LOAD_TRAINING_STATES_START
    };
}

export const loadTrainingStatesFail = (error) =>{
    return{
        type:actionTypes.LOAD_TRAINING_STATES_FAIL,
        error
    };
}

export const loadTrainingStatesSuccess = (data) =>{
    return{
        type:actionTypes.LOAD_TRAINING_STATES_SUCCESS,
        data: data
    };
}




