import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as ActionTypes from '../../../store/actions/index';
//import * as ActionTypes from '../../../store/actions/actionTypes';
import styles from './Admin.module.css';
import Button from './../../UI/Button/Button';
import Modal from '../../UI/Modal/Modal';
import GameForm from '../../../containers/Games/GameForm/GameForm';
import TrainingForm from '../../../containers/Trainings/TrainingForm/TrainingForm';




class Dashboard extends Component{
    state = {
        showCreateGame: false,
        showCreateTraining: false,
        navigateToGame: false
    }
    componentDidMount(){
        
        /*if(this.props.isAuth){
            this.props.onClearGameState();
            this.props.onGetGames(this.props.token, this.props.userId);
            this.props.onLoadTeams(this.props.token);
        }*/
    }

    
    componentDidUpdate(prevProps,prevState){
        /*if(prevProps.gameMessage !== this.props.gameMessage){
            if(this.props.gameMessage === "CREATE_GAME_SUCCESS"){
                this.props.history.push({
                    pathname: '/game/' + this.props.gameID
                });
            }
        }
        if(prevProps.trainingMessage !== this.props.trainingMessage){
            //console.log(this.props.trainingMessage);
            if(this.props.trainingMessage === "CREATE_TRAINING_SUCCESS"){
                this.props.history.push({
                    pathname: '/training/' + this.props.trainingID
                });
            }
        }*/

    }

    handleUsers = () =>{
        this.props.history.push('/users')
    }

    handleRegister = () =>{
        this.props.history.push('/register');
    }
    
    
    render(){

        let registerButton = null;
        if(this.props.auser.role === 'SysAdmin'){
            registerButton = <Button btnType="Yellow" width={'200px'} clicked={this.handleRegister}>REGISTER</Button>
        }

       
        return (
            <>
           
            <div className={styles.Admin}>
              
                <h1>This is the Admin Area</h1>
                <Button btnType="Yellow" width={'200px'} clicked={this.handleUsers}>USERS</Button>
                {registerButton}
                
            </div>
            
            </>
        )
    }
}

const mapStateToProps = state =>{
    return{
        gameStart: state.game.gameStart,
        isAuth: state.auth.token !== null,
        userId: state.auth.userId,
        auser: state.auth.user,
        loading: state.auth.loading,
        gameLoading: state.game.loading,
        gameError: state.game.error,
        gameMessage: state.game.message,
        token: state.auth.token,
        games: state.game.games,
        gameID: state.game.id,

        trainingLoading: state.training.loading,
        trainingError: state.training.error,
        trainingMessage: state.training.message,
        trainings: state.training.trainings,
        trainingID: state.training.id
    }
}

const mapDispatchToProps = dispatch =>{
    return{
        onGameStart: (token)=>dispatch(
            ActionTypes.gameStart()
        ),
        onCreateGame: (token, data)=>dispatch(
            ActionTypes.createGame(token,data)
        ),

        onGetGames: (token, userId)=>dispatch(
            ActionTypes.getGames(token, userId)
        ),

        onLoadTeams: (token)=>dispatch(
            ActionTypes.getTeams(token)
        ),
        onClearGameState: ()=>dispatch(
            ActionTypes.clearGameState()
        ),
        onTrainingStart: (token)=>dispatch(
            ActionTypes.trainingStart()
        ),
        onCreateTraining: (token, data)=>dispatch(
            ActionTypes.createTraining(token,data)
        ),

        onGetTrainings: (token, userId)=>dispatch(
            ActionTypes.getTrainings(token, userId)
        ),

    }
};


export default connect(mapStateToProps, mapDispatchToProps) (Dashboard);