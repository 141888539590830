import React, {Component} from 'react';
import styles from './RosterStats.module.css';

class RosterStats extends Component{
    getAB = (id) =>{
        let ab = 0;
        for(let i=0;i<this.props.pitchArray.length; ++i){
            const pitch = this.props.pitchArray[i];
            if(typeof pitch.batter === 'undefined')
                continue;
            if(pitch.batter.id === id){
                if(pitch.ballCount === 0 && pitch.strikeCount ===0){
                    ab++;
                }
                if(this.isWalk(pitch) || pitch.result === 'HBP'){
                    ab -=1;
                }
            }
        }
        return ab;
    }

    

    isExtraBaseHit = (pitch) =>{
        if(
        pitch.result === "2B" || 
        pitch.result === "3B" || 
        pitch.result === "4B" || 
        pitch.result === "HR" || 
        pitch.result === "IPHR"){
            return true;
        }
        return false;
    }

     isHit = (pitch) =>{
        if(pitch.result === "1B" || 
        pitch.result === "2B" || 
        pitch.result === "3B" || 
        pitch.result === "4B" || 
        pitch.result === "HR" || 
        pitch.result === "IPHR"){
            return true;
        }
        return false;
    }
    isHomeRun = (pitch) => {
        if( pitch.batActionLocation === 'HR7' ||
            pitch.batActionLocation === 'HR8' ||
            pitch.batActionLocation === 'HR9'){
            pitch.hitResult = 'HR';   
            pitch.result = 'HR'; 
            return true; //Home Run, new batter    
        }
        return false;//in play to a fielder

    }

     RBITotal = (pitch, RBIids) =>{
        if(pitch.batter.id === '-LnQ39-wSRPhv5KRzOvh'){
            let ebug = true;
        }
        
        let ret = 0;
        if(typeof pitch.baseRunners !== 'undefined'){
            for(let i=0;i<pitch.baseRunners.length; ++i){
                const br = pitch.baseRunners[i];
                if(br.runningResult === 'SCORE'){
                    
                    if(!RBIids.includes(br.id)){
                        RBIids.push(br.id);
                        ret ++;
                    }
                }
            }
        }
        if(pitch.runningResult === 'SCORE' && this.isHomeRun(pitch)){
            ret ++;
        }
        return ret;
    }

    RunTotal = (id, pitch, Rids) =>{
        let ret = 0;
        if(typeof pitch.baseRunners !== 'undefined'){
            for(let i=0;i<pitch.baseRunners.length; ++i){
                const br = pitch.baseRunners[i];

                if(br.id === id){
                    if(br.runningResult === 'SCORE'){
                        
                        if(!Rids.includes(br.id)){
                            Rids.push(br.id);
                            ret ++;
                        }
                    }
                }
            }
        }
        if(pitch.batter.id === id && pitch.runningResult === 'SCORE'){
            ret ++;
        }
        return ret;

    }

    isWalk = (pitch) =>{
        if( pitch.pitchResult === "ball" &&
            pitch.ballCount === 3 && 
            pitch.result === "Take"){
                return true;

        }
        return false;
    }

    isStrikeout = (pitch) =>{
        if(pitch.strikeCount === 2 && pitch.strike &&
            (pitch.result === "Take" || pitch.result === "SWING AND MISS")){
                return true;
            
        }
        return false;
    }

     isHit = (pitch) =>{
        if(pitch.result === "1B" || 
        pitch.result === "2B" || 
        pitch.result === "3B" || 
        pitch.result === "4B" || 
        pitch.result === "HR" || 
        pitch.result === "IPHR"){
            return true;
        }
        return false;
    }

    getXBH = (id) =>{
        let xbh= 0;
        for(let i=0;i<this.props.pitchArray.length; ++i){
            const pitch = this.props.pitchArray[i];
            if(typeof pitch.batter === 'undefined')
                continue;
            if(pitch.batter.id === id){
                if(this.isExtraBaseHit(pitch)){
                    xbh ++;
                }

            }
        }
        return xbh;
    }

    getH = (id) =>{
        let h= 0;
        for(let i=0;i<this.props.pitchArray.length; ++i){
            const pitch = this.props.pitchArray[i];
            if(typeof pitch.batter === 'undefined')
                continue;
            if(pitch.batter.id === id){
                if(this.isHit(pitch)){
                    h ++;
                }

            }
        }
        return h;
    }

    getR = (id) =>{
        let r= 0;
        let Rids = [];
        for(let i=0;i<this.props.pitchArray.length; ++i){
            const pitch = this.props.pitchArray[i];
            if(typeof pitch.batter === 'undefined')
                continue;
            
            r += this.RunTotal(id, pitch,Rids);
            
        }
        return r;
    }

    getRBI = (id, RBIids) =>{
        let h= 0;
        
        for(let i=0;i<this.props.pitchArray.length; ++i){
            const pitch = this.props.pitchArray[i];
            if(typeof pitch.batter === 'undefined')
                continue;
            if(pitch.batter.id === id){
                h+= this.RBITotal(pitch, RBIids);
                   

            }
        }
        return h;
    }

    getBB = (id) =>{
        let h= 0;
        for(let i=0;i<this.props.pitchArray.length; ++i){
            const pitch = this.props.pitchArray[i];
            if(typeof pitch.batter === 'undefined')
                continue;
            if(pitch.batter.id === id){
                if(this.isWalk(pitch)){
                    h ++;
                }
                   

            }
        }
        return h;
    }

    getK = (id) =>{
        let h= 0;
        for(let i=0;i<this.props.pitchArray.length; ++i){
            const pitch = this.props.pitchArray[i];
            if(typeof pitch.batter === 'undefined')
                continue;
            if(pitch.batter.id === id){
                if(this.isStrikeout(pitch)){
                    h ++;
                }
                   

            }
        }
        return h;
    }

    getBA = (id, AB) =>{
        let hitCount = 0;
        let BA = 0;
        for(let i=0;i<this.props.pitchArray.length; ++i){
            const pitch = this.props.pitchArray[i];
            if(typeof pitch.batter === 'undefined')
                continue;
            if(pitch.batter.id === id){
                if(this.isHit(pitch)){
                    hitCount ++;
                }
                   

            }
        }

        if(hitCount > 0){
            ////console.log("BA " + hitCount +" "+ PA)
            BA = hitCount / parseFloat(AB);

            if(BA < 1){
                BA = "." + ((BA % 1).toFixed(3) + "").split(".")[1]
            }else{
                BA = BA.toFixed(2) + "";
            }
        }
        return BA;
    }

    getTotalBA = (AB) =>{
        let hitCount = 0;
        let BA = 0;
        for(let i=0;i<this.props.pitchArray.length; ++i){
            const pitch = this.props.pitchArray[i];
            if(typeof pitch.batter === 'undefined')
                continue;
            if(pitch.batter.teamId === this.props.team.id){
                if(this.isHit(pitch)){
                    hitCount ++;
                }
                   

            }
        }

        if(hitCount > 0){
            ////console.log("BA " + hitCount +" "+ PA)
            BA = hitCount / parseFloat(AB);

            if(BA < 1){
                BA = "." + ((BA % 1).toFixed(3) + "").split(".")[1]
            }else{
                BA = BA.toFixed(2) + "";
            }
        }
        return BA;
    }


    getOBP = (id) =>{
        let OBP = 0;
        let PA = 0;
        let BB = 0;
        let HBPs = 0;
        let hitCount = 0;
        for(let i=0;i<this.props.pitchArray.length; ++i){
            const pitch = this.props.pitchArray[i];
            if(typeof pitch.batter === 'undefined')
                continue;
            if(pitch.batter.id === id){
                if(pitch.ballCount === 0 && pitch.strikeCount ===0){
                    PA += 1;
                }
                if(this.isHit(pitch)){
                    hitCount ++;
                }
                if(this.isWalk(pitch)){
                    BB ++;
                }
                if(pitch.result === 'HBP'){
                    HBPs ++;
                }
            }
        }

        if(PA > 0){
            OBP = (hitCount + BB + HBPs)/ parseFloat(PA);
            if(OBP < 1){
                OBP = "." + ((OBP % 1).toFixed(3) + "").split(".")[1]
            }else{
                OBP = OBP.toFixed(2) + "";
            }
        }
        return OBP;
    }

    getTotalOBP = () =>{
        let OBP = 0;
        let PA = 0;
        let BB = 0;
        let HBPs = 0;
        let hitCount = 0;
        for(let i=0;i<this.props.pitchArray.length; ++i){
            const pitch = this.props.pitchArray[i];
            if(typeof pitch.batter === 'undefined')
                continue;
            if(pitch.batter.teamId === this.props.team.id){
                if(pitch.ballCount === 0 && pitch.strikeCount ===0){
                    PA += 1;
                }
                if(this.isHit(pitch)){
                    hitCount ++;
                }
                if(this.isWalk(pitch)){
                    BB ++;
                }
                if(pitch.result === 'HBP'){
                    HBPs ++;
                }
            }
        }

        if(PA > 0){
            OBP = (hitCount + BB + HBPs)/ parseFloat(PA);
            if(OBP < 1){
                OBP = "." + ((OBP % 1).toFixed(3) + "").split(".")[1]
            }else{
                OBP = OBP.toFixed(2) + "";
            }
        }
        return OBP;
    }

    pitchInnings = (id) =>{
        let IP = 0;
        let innings = [];
        for(let i=0;i<this.props.pitchArray.length; ++i){
            const pitch = this.props.pitchArray[i];
            if(typeof pitch.pitcher === 'undefined')
                continue;

            if(pitch.pitcher.id === id){
                let pi = pitch.inning;
                let po = pitch.outs;
                let result = pitch.result;
                innings.push({
                    pi,
                    po,
                    result,
                    pitch
                });
            }
        }

        if(id === '-Lv6tgAfSjoBp9DssQ-D'){
            //console.log('pitchInnings innings', innings)
        }

        let uniqueInnings = [];
        for(let i=0;i<innings.length; ++i){
            if(!uniqueInnings.includes(innings[i].pi)){
                uniqueInnings.push(innings[i].pi);
            }
        }

        let offset = 0;
        if(innings.length > 0){
            offset = innings[0].pi;
        }
        for(let i=0; i < innings.length; ++i){
            for(let j=1; j<200; ++j){
                if(innings[i].pi === j){
                    IP = (j-offset);
                    if(innings[i].po < 2 || 
                        (innings[i].po == 2 && innings[i].result !== 'OUT'))
                        IP = IP +"."+innings[i].po;
                    if(innings[i].result === "OUT"){
                        IP = (j-offset) + "."+ (parseInt(innings[i].po) + parseInt(1));
                    }
                    if(innings[i].po < 2 && innings[i].pitch.strikeCount == 2 && innings[i].pitch.strike){
                        IP = (j-offset) + "."+ (parseInt(innings[i].po) + parseInt(1));
                    }
                    if(innings[i].po == 2 && innings[i].pitch.strikeCount == 2 && innings[i].pitch.strike){
                        IP = (j);
                    }
                }
            }
        }

        /*for(let i=0; i < innings.length; ++i){
            for(let j=1; j<200; ++j){
                if(innings[i].pi === j){
                    IP = (j-1);
                    if(innings[i].po < 2 || 
                        (innings[i].po == 2 && innings[i].result !== 'OUT'))
                        IP = IP +"."+innings[i].po;
                    if(innings[i].result === "OUT"){
                        IP = (j-1) + "."+ (parseInt(innings[i].po) + parseInt(1));
                    }
                    if(innings[i].po < 2 && innings[i].pitch.strikeCount == 2 && innings[i].pitch.strike){
                        IP = (j-1) + "."+ (parseInt(innings[i].po) + parseInt(1));
                    }
                    if(innings[i].po == 2 && innings[i].pitch.strikeCount == 2 && innings[i].pitch.strike){
                        IP = (j);
                    }
                }
            }
        }*/

        if(id === '-Lv6tgAfSjoBp9DssQ-D'){
            //console.log('pitchInnings IP', IP);
            //console.log('total Innings ' + uniqueInnings.length);
        }
        return IP;

    }

    pitchCount = (id) =>{
        let ret = 0;
        for(let i=0;i<this.props.pitchArray.length; ++i){
            const pitch = this.props.pitchArray[i];
            if(typeof pitch.pitcher === 'undefined')
                continue;

            if(pitch.pitcher.id === id){
                ret ++;
            }
        }
        return ret;
    }

    strikePercentage = (id) =>{
        let SPercentageCount = 0;
        let SPercentage = 0;
        let pitchCount = 0;
        for(let i=0;i<this.props.pitchArray.length; ++i){
            const pitch = this.props.pitchArray[i];
            if(typeof pitch.pitcher === 'undefined')
                continue;

            if(pitch.pitcher.id === id){
                pitchCount ++;
                if(pitch.strike  || (pitch.batAction === 'Foul' && !pitch.strike)){
                    SPercentageCount += 1;
                }
            }
        }
        if(SPercentageCount > 0){
            SPercentage = SPercentageCount / parseFloat(pitchCount) * 100;
            SPercentage = SPercentage.toFixed(0) + "%";
        }
        return SPercentage;
    }

    totalStrikePercentage = () =>{
        let SPercentageCount = 0;
        let SPercentage = 0;
        let pitchCount = 0;
        for(let i=0;i<this.props.pitchArray.length; ++i){
            const pitch = this.props.pitchArray[i];
            if(typeof pitch.pitcher === 'undefined')
                continue;

            if(pitch.pitcher.teamId === this.props.team.id){
                pitchCount ++;
                if(pitch.strike  || (pitch.batAction === 'Foul' && !pitch.strike)){
                    SPercentageCount += 1;
                }
            }
        }
        if(SPercentageCount > 0){
            SPercentage = SPercentageCount / parseFloat(pitchCount) * 100;
            SPercentage = SPercentage.toFixed(0) + "%";
        }
        return SPercentage;
    }
    
    hitCount = (id) =>{
        let hitCount = 0;
       
        for(let i=0;i<this.props.pitchArray.length; ++i){
            const pitch = this.props.pitchArray[i];
            if(typeof pitch.pitcher === 'undefined')
                continue;

            if(pitch.pitcher.id === id){
                if(this.isHit(pitch)){
                    hitCount+= 1;
                }
            }
        }
       
        return hitCount;
    }

    strikeOuts = (id) =>{
        let K = 0;
       
        for(let i=0;i<this.props.pitchArray.length; ++i){
            const pitch = this.props.pitchArray[i];
            if(typeof pitch.pitcher === 'undefined')
                continue;

            if(pitch.pitcher.id === id){
                if(pitch.strikeCount === 2 && pitch.strike &&
                    (pitch.result === "Take" || pitch.result === "SWING AND MISS" || pitch.result === "DRP3-OUT")){
                        K++
                    
                }
            }
        }
       
        return K;
    }

    walks = (id) =>{
        let BB = 0;
       
        for(let i=0;i<this.props.pitchArray.length; ++i){
            const pitch = this.props.pitchArray[i];
            if(typeof pitch.pitcher === 'undefined')
                continue;

            if(pitch.pitcher.id === id){
                if(this.isWalk(pitch)){
                    BB ++;
                   
                }
            }
        }
       
        return BB;
    }

    SMPercentage = (id) =>{
        let smp = 0;
        let PS = 0;
        let totalMisses = 0;
        for(let i=0;i<this.props.pitchArray.length; ++i){
            const pitch = this.props.pitchArray[i];
            if(typeof pitch.pitcher === 'undefined')
                continue;

            if(pitch.pitcher.id === id){
                PS ++;
                if(pitch.result === "SWING AND MISS"){
                    totalMisses ++;
                }
            }
        }
        smp = totalMisses/ parseFloat(PS) * 100; 
        smp = smp.toFixed(0) + "%";
       
        return smp;
    }

    TotalSMPercentage = () =>{
        let smp = 0;
        let PS = 0;
        let totalMisses = 0;
        for(let i=0;i<this.props.pitchArray.length; ++i){
            const pitch = this.props.pitchArray[i];
            if(typeof pitch.pitcher === 'undefined')
                continue;

            if(pitch.pitcher.teamId === this.props.team.id){
                PS ++;
                if(pitch.result === "SWING AND MISS"){
                    totalMisses ++;
                }
            }
        }
        if(PS > 0){
            smp = totalMisses/ parseFloat(PS) * 100; 
        }
        smp = smp.toFixed(0) + "%";
       
        return smp;
    }

    /*
    
            */

    allowedRuns = (id) =>{
        let hitCount = 0;
        let RecordedScores = [];
        let allowedRuns = 0;
        let earnedRuns = 0;
        for(let i=0;i<this.props.pitchArray.length; ++i){
            const pitch = this.props.pitchArray[i];
            if(typeof pitch.pitcher === 'undefined')
                continue;
            if(pitch.pitcher.id === id){
                if(pitch.runningResult === "SCORE" && pitch.result !== "ROE"){

                    if(!RecordedScores.includes[pitch.id]){
                        RecordedScores.push(pitch.id);
                        earnedRuns ++;
                        allowedRuns ++;
                    }
                }
                else if(pitch.runningResult === "SCORE"){
                    if(!RecordedScores.includes[pitch.id]){
                        RecordedScores.push(pitch.id);
                        allowedRuns ++;
                    }
                }
            }
        }
       
        return allowedRuns;
    }
    earnedRuns = (id) =>{
        let hitCount = 0;
        let RecordedScores = [];
        let allowedRuns = 0;
        let earnedRuns = 0;
        for(let i=0;i<this.props.pitchArray.length; ++i){
            const pitch = this.props.pitchArray[i];
            if(typeof pitch.pitcher === 'undefined')
                continue;
            if(pitch.pitcher.id === id){
                if(pitch.runningResult === "SCORE" && pitch.result !== "ROE"){

                    if(!RecordedScores.includes[pitch.id]){
                        RecordedScores.push(pitch.id);
                        earnedRuns ++;
                        allowedRuns ++;
                    }
                }
                else if(pitch.runningResult === "SCORE"){
                    if(!RecordedScores.includes[pitch.id]){
                        RecordedScores.push(pitch.id);
                        allowedRuns ++;
                    }
                }
            }
        }
       
        return earnedRuns;
    }
    /**/


    render(){

        let pitchers = [];
        let pitcherIDs = [];
        let pitchTotals = {
            IP: 0,
            P: 0,
            S: 0,
            H: 0,
            R: 0,
            ER: 0,
            K: 0,
            BB: 0,
            SM: 0
        }

        for(let i=0; i < this.props.pitchArray.length; ++i){
            const pitch = {...this.props.pitchArray[i]};
            if(pitch.pitcher.teamId === this.props.team.id){
                if(!pitcherIDs.includes(pitch.pitcher.id)){

                    let pitcher = {
                        number: 0,
                        name: null,
                        IP: 0,
                        P: 0,
                        S: 0,
                        H: 0,
                        R: 0,
                        ER: 0,
                        K: 0,
                        BB: 0,
                        SM: 0
                    }


                    pitcherIDs.push(pitch.pitcher.id);

                    pitcher.number = pitch.pitcher.number;
                    pitcher.name = pitch.pitcher.firstName +' ' + pitch.pitcher.lastName[0] + '.';
                    pitcher.IP = this.pitchInnings(pitch.pitcher.id);
                    pitcher.P = this.pitchCount(pitch.pitcher.id);
                    pitcher.S = this.strikePercentage(pitch.pitcher.id);
                    pitcher.H = this.hitCount(pitch.pitcher.id);
                    pitcher.R = this.allowedRuns(pitch.pitcher.id);
                    pitcher.ER = this.earnedRuns(pitch.pitcher.id);
                    pitcher.K = this.strikeOuts(pitch.pitcher.id);
                    pitcher.BB = this.walks(pitch.pitcher.id);
                    pitcher.SM = this.SMPercentage(pitch.pitcher.id);
                    pitchers.push(pitcher);

                    pitchTotals.IP += parseFloat(pitcher.IP);
                    //console.log('pitchTotals.IP '+ pitchTotals.IP);

                    

                    pitchTotals.P += pitcher.P;
                    pitchTotals.H += pitcher.H;
                    pitchTotals.R += pitcher.R;
                    pitchTotals.ER += pitcher.ER;
                    pitchTotals.K += pitcher.K;
                    pitchTotals.BB += pitcher.BB;

                }
            }
        }

        let ipTotalFloor = Math.floor(pitchTotals.IP);
        let ipTotalDecimal = "." + (pitchTotals.IP+"").split(".")[1];
        //ipTotalDecimal = parseFloat(ipTotalDecimal);
        //console.log('pitchTotals.IP '+ pitchTotals.IP+ ' ' +ipTotalFloor +' '+ ipTotalDecimal);
        if(ipTotalDecimal === '.3'){
            //console.log('update pitch total IP ' + (ipTotalFloor + 1));
            pitchTotals.IP = (ipTotalFloor + 1);
        }
        pitchTotals.SM = this.TotalSMPercentage();
        pitchTotals.S = this.totalStrikePercentage();

        let pitcherTotalsJSX = 
            <tr>
                    <td></td>
                    <td><b>Totals</b></td>
                    <td>{pitchTotals.IP}</td>
                    <td>{pitchTotals.P}</td>
                    <td>{pitchTotals.S}</td>
                    <td>{pitchTotals.H}</td>
                    <td>{pitchTotals.R}</td>
                    <td>{pitchTotals.ER}</td>
                    <td>{pitchTotals.K}</td>
                    <td>{pitchTotals.BB}</td>
                    <td>{pitchTotals.SM}</td>

                </tr>

        let pitchersJSX = [];
        for(let i=0;i<pitchers.length; ++i){
            const pitcher = pitchers[i];
            let ip =  Math.floor(pitcher.IP);
            let ipTotalDecimal = "." + (pitcher.IP+"").split(".")[1];
            if(ipTotalDecimal === '.3'){
                //console.log('update pitch total IP ' + (ipTotalFloor + 1));
                pitcher.IP = (ipTotalFloor + 1);
            }



            let pitcherJSX = 
                <tr key={i}>
                    <td>{pitcher.number}</td>
                    <td>{pitcher.name}</td>
                    <td>{pitcher.IP}</td>
                    <td>{pitcher.P}</td>
                    <td>{pitcher.S}</td>
                    <td>{pitcher.H}</td>
                    <td>{pitcher.R}</td>
                    <td>{pitcher.ER}</td>
                    <td>{pitcher.K}</td>
                    <td>{pitcher.BB}</td>
                    <td>{pitcher.SM}</td>

                </tr>

            pitchersJSX.push(pitcherJSX);
        }



        let lineupStats = [];
        let totals  = {
            AB: 0,
            R: 0,
            XBH: 0,
            H:0,
            RBI: 0,
            BB: 0,
            K: 0,
            BA: 0,
            OBP: 0
        }
        for(let i=0; i < this.props.lineup.length; ++i){
            let player = {
                number: 0,
                position: '',
                name: '',
                AB: 0,
                R: 0,
                XBH: 0,
                H:0,
                RBI: 0,
                BB: 0,
                K: 0,
                BA: 0,
                OBP: 0
            }
            const currentPlayer = this.props.lineup[i];
            let RBIids = [];
            player.position = currentPlayer.currentPosition;
            player.number = currentPlayer.number;
            player.name = currentPlayer.firstName +' ' + currentPlayer.lastName + '.';
            player.AB = this.getAB(currentPlayer.id);
            player.R = this.getR(currentPlayer.id);
            player.XBH = this.getXBH(currentPlayer.id);
            player.H = this.getH(currentPlayer.id);
            player.RBI = this.getRBI(currentPlayer.id, RBIids);
            player.BB = this.getBB(currentPlayer.id);
            player.K = this.getK(currentPlayer.id);
            player.BA = this.getBA(currentPlayer.id, player.AB);
            player.OBP = this.getOBP(currentPlayer.id);
            lineupStats.push(player);

            totals.AB += player.AB;
            totals.R += player.R;
            totals.XBH += player.XBH;
            totals.H += player.H;
            totals.RBI += player.RBI;
            totals.BB += player.BB;
            totals.K += player.K;
            //totals.BA += parseFloat(player.BA);
            //totals.OBP += parseFloat(player.OBP);
        }
        totals.OBP = this.getTotalOBP();

        totals.BA = this.getTotalBA(totals.AB)
        



        let lineupJSX = [];
        for(let i=0; i<lineupStats.length; ++i){
            const player = lineupStats[i];
            let playerJSX = 
                <tr key={i}>
                    <td>{player.number}</td>
                    <td>{player.position}</td>
                    <td>{player.name}</td>
                    <td>{player.AB}</td>
                    <td>{player.R}</td>
                    <td>{player.XBH}</td>
                    <td>{player.H}</td>
                    <td>{player.RBI}</td>
                    <td>{player.BB}</td>
                    <td>{player.K}</td>
                    <td>{player.BA}</td>
                    <td>{player.OBP}</td>
                </tr>
            lineupJSX.push(playerJSX);
        }
        let totalsJSX = 
        <tr>
                    <td></td>
                    <td><b>Totals</b></td>
                    <td></td>
                    <td>{totals.AB}</td>
                    <td>{totals.R}</td>
                    <td>{totals.XBH}</td>
                    <td>{totals.H}</td>
                    <td>{totals.RBI}</td>
                    <td>{totals.BB}</td>
                    <td>{totals.K}</td>
                    <td>{totals.BA}</td>
                    <td>{totals.OBP}</td>
                </tr>


        return(
            <div className={styles.RosterStats}>
            {this.props.team.name}

            <table className={styles.RosterTable}>
                <thead>
                    <tr style={{backgroundColor: '#404040'}}>
                    <th></th>
                    <th>Pos</th>
                    <th>Lineup</th>
                    <th>AB</th>
                    <th>R</th>
                    <th>XBH</th>
                    <th>H</th>
                    <th>RBI</th>
                    <th>BB</th>
                    <th>K</th>
                    <th>BA</th>
                    <th>OBP</th>
                    </tr>
                </thead>
                <tbody>
                {lineupJSX}
                {totalsJSX}

                <tr>
                    <td></td>
                    <td><b>Pitching</b></td>
                    <td><b>IP</b></td>
                    <td><b>#P</b></td>
                    <td><b>S%</b></td>
                    <td><b>H</b></td>
                    <td><b>R</b></td>
                    <td><b>ER</b></td>
                    <td><b>K</b></td>
                    <td><b>BB</b></td>
                    <td><b>SM%</b></td>
                    
                </tr>
                {pitchersJSX}
                {pitcherTotalsJSX}
                </tbody>
            </table>

            
            </div>
        )
    }

}

export default RosterStats;