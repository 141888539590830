
import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as ActionTypes from '../../store/actions/index';
import {Redirect} from 'react-router-dom';

import TrainingForm from './TrainingForm/TrainingForm';
import Modal from '../../components/UI/Modal/Modal';
import Button from '../../components/UI/Button/Button';
import classes from './Trainings.module.css';
import Spinner from '../../components/UI/Spinner/Spinner';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';


 
function convertDateToTicks(date){
    return((date.getTime() * 10000) + 621355968000000000);
}

function dateComparator(date1, date2){
    var date1Number = convertDateToTicks(new Date(date1));
    var date2Number = convertDateToTicks(new Date(date2));

    if (date1Number===null && date2Number===null) {
        return 0;
    }
    if (date1Number===null) {
        return -1;
    }
    if (date2Number===null) {
        return 1;
    }

    return date1Number - date2Number;
}


class Trainings extends Component{
    constructor(props){
        super(props);
        this.state = {
            columnDefs: [
                {
                    headerName: "ID", field: "id", sortable: false, filter: false, hide: true
                },
                {
                    headerName: "Team", field: "team", sortable: true, filter: true, cellStyle: {justifyContent: 'center'}
                },  
                {
                    headerName: "Date", field: "date", sortable: true, filter: true, comparator: dateComparator
                }
                ,
                {
                    headerName: "Description", field: "description", sortable: true, filter: true, cellStyle: {justifyContent: 'center'}
                }, 
                ,
                {
                    headerName: "Player", field: "player", sortable: true, filter: true, cellStyle: {justifyContent: 'center'}
                }, 
                ,{
                    headerName: "Training Type", field: "trainingType", sortable: true, filter: true
                }
                ,{
                    headerName: "Pitch Type", field: "pitchType", sortable: true, filter: true, width:150
                }
                ],
                rowData: [],
                showCreateTraining : false
        }
        
    }
    onGridReady = params => {
        ////console.log('onGridReady!');
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
    }

    componentWillMount(){
        
        if(this.props.isAuth){
            this.props.onClearGameState();
            this.props.onGetOrgTrainings(this.props.token, this.props.auser.organizationId);
            this.props.onLoadOrgTeams(this.props.token, this.props.auser.organizationId);
            this.props.onLoadTrainingStates(this.props.token, this.props.auser.organizationId);
        }
    }
    componentDidMount(){
        //this.state.columnDefs.api.sizeColumnsToFit();
    }
    submitAddTrainingHandler = (event, data) =>{
        event.preventDefault();
        ////console.log("Game->submitAddGameHandler ", data);

        let updatedDate = {...data, organizationId: this.props.auser.organizationId}

        
        this.props.onCreateTraining(this.props.token, updatedDate);
        this.setState({
            showCreateTraining: false
        });

       
        //this.props.onGetGames(this.props.token, this.props.userId);
    }


    trainingClickHandler = (event, id) =>{
        ////console.log('Teams->gameClickHandler ' + id, event);
        ////console.log('game?id=' + id);
        ////console.log(this.props.match.url);

        this.props.history.push({
            pathname: '/training/' + id
        });
    }
    
    onCreateTrainingCancel = () =>{
        this.setState({
            showCreateTraining: false
        })
    }
    onCreateTraining = () =>{
        this.setState({
            showCreateTraining: true
        })
    }

    formatDateStr = (date) =>{
        let formatDate = new Date(date).toLocaleDateString("en-US") + " "+ new Date(date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
        return formatDate;//.substr(0,formatDate.length-6);
    }


    onCellClicked = (result) =>{
        ////console.log("onCellClicked", result);
         this.props.history.push({
             pathname: '/training/' + result.data.id
         });
    }
    

    render(){
        //let games = null;

        let trainingRows = [];

        let spinnerJSX = <Spinner/>
        if(this.props.trainings){
            spinnerJSX = null;
            //sort games by date
            let postSortTrainings = this.props.trainings.sort(function(obj1, obj2) {
                //sort by players by last name asc
                return (obj1.date < obj2.date) === true? 1 : -1;
            });

            //console.log('post sort traiings', postSortTrainings);
            
            for(let i=0; i< postSortTrainings.length; ++i){
                let g = postSortTrainings[i];
                const trainingState = this.props.trainingStates.find(x=>x.training.id === g.id);


                let state = "";//"Bottom of 7th, Complete, Force Quit";
                let playerName = "";

                if(this.props.trainingLoading){
                    state = "Fetching Data..";
                }
                else{
                    if(typeof trainingState !== 'undefined'){

                        if(g.trainingType === 'Pitcher' && 
                            trainingState.currentPitcher !== null &&
                            typeof trainingState.currentPitcher !== 'undefined'

                        ){
                            playerName = trainingState.currentPitcher.firstName +' '+ trainingState.currentPitcher.lastName;
                        }
                        else if(g.trainingType === 'Batter' && 
                            trainingState.currentHitter !== null &&
                            typeof trainingState.currentHitter !== 'undefined'
                        ){
                            playerName = trainingState.currentHitter.firstName +' '+ trainingState.currentHitter.lastName;
                        }
                        else if(g.trainingType === 'BatterPractice' && 
                        trainingState.currentHitter !== null &&
                        typeof trainingState.currentHitter !== 'undefined'
                        ){
                            playerName = trainingState.currentHitter.firstName +' '+ trainingState.currentHitter.lastName;
                        }
                        // if(trainingState.currentPitcher === null ||
                        //     trainingState.currentHitter === null ||
                        //     typeof trainingState.currentPitcher === 'undefined' ||
                        //     typeof trainingState.currentHitter === 'undefined'
                        //     ){
                        //         state = "Not Started";
                        // }
                        // else{
                        //     //state = trainingState.forceQuit ? "Force Quit - " + trainingState.forceQuitNotes : (trainingState.gameOver ? "Complete" : this.getInningString(gameState.currentInning))
                        //     if(g.trainingType === 'Pitcher'){
                        //         playerName = trainingState.currentPitcher.firstName +' '+ trainingState.currentPitcher.lastName;
                        //     }
                        //     else{
                        //         playerName = trainingState.currentHitter.firstName +' '+ trainingState.currentHitter.lastName;
                        //     }
                        // }
                    }
                    else{
                        state = "";
                    }
                }
                trainingRows.push({
                    id: g.id,
                    team: typeof g.team === 'undefined' ? 'no team' : g.team.name,
                    trainingType: g.trainingType,
                    date: this.formatDateStr(g.date),
                    description: g.description,
                    pitchType: g.pitchType,
                    player: playerName,
                    state: state
                })
            }
        }

        


        let authRedirect = null;
        if(!this.props.loading && !this.props.isAuth){
            authRedirect = <Redirect to="/auth" />
        }

        /*let gameRedirect = null;
        if(this.props.gameid){
            let gameURL = "/games/" + this.props.gameid;
            gameRedirect = <Redirect to={gameURL} />
        }*/

        ////console.log('Games:');
        ////console.log(games);

        
        this.state.rowData = trainingRows;
        
        let tableJSX = <div 
                            className="ag-theme-balham-dark"
                            style={{ 
                            height: '400px', 
                            width: 'inherit',
                            marginLeft :'auto',
                            marginRight : 'auto',
                            fontSize:'15px',
                            fontFamily:'inherit'
                        }} 
                        >
                            <AgGridReact
                            // useful for accessing the component directly via ref
                            ref="agGrid"
                            onCellClicked={this.onCellClicked.bind(this)}
                            columnDefs={this.state.columnDefs}
                            rowData={this.state.rowData}
                            onGridReady={this.onGridReady}>
                            </AgGridReact>
                        </div>

        return(
            
            <div className={classes.Trainings}>
                
                {authRedirect}
                <Modal show={this.state.showCreateTraining} clickBackdrop={this.onCreateTrainingCancel}>
                <TrainingForm training={null}
                loading={this.props.trainingLoading}
                error={this.props.trainingError}
                message={this.props.trainingMessage}
                submit={this.submitAddTrainingHandler} />
                </Modal>
                <Button clicked={this.onCreateTraining} btnType="Danger">CREATE TRAINING</Button>
                {spinnerJSX}
                <h3>Trainings</h3>
                {/*games*/}
                {tableJSX}
            </div>
        )
    }
}

const mapStateToProps = state =>{
    return{
        trainingStart: state.training.trainingStart,
        isAuth: state.auth.token !== null,
        userId: state.auth.userId,
        loading: state.auth.loading,
        trainingLoading: state.training.loading,
        trainingError: state.training.error,
        trainingMessage: state.training.message,
        token: state.auth.token,
        trainings: state.training.trainings,
        trainingStates: state.training.trainingStates,
        trainingid: state.training.id,
        auser: state.auth.user
    }
}



const mapDispatchToProps = dispatch =>{
    return{
        onTrainingStart: (token)=>dispatch(
            ActionTypes.trainingStart()
        ),
        onCreateTraining: (token, data)=>dispatch(
            ActionTypes.createTraining(token,data)
        ),

        onGetOrgTrainings: (token, orgId)=>dispatch(
            ActionTypes.getOrgTrainings(token, orgId)
        ),

        onLoadOrgTeams: (token, orgId)=>dispatch(
            ActionTypes.getOrgTeams(token, orgId)
        ),
        onClearGameState: ()=>dispatch(
            ActionTypes.clearGameState()
        ),
        onLoadTrainingStates: (token, orgId)=>dispatch(
            ActionTypes.loadTrainingStates(token,orgId)
        )

    }
};



export default connect(mapStateToProps, mapDispatchToProps) (Trainings);

