import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as ActionTypes from '../../../store/actions/index';
import styles from './ScoutingReportSummary.module.css';
import Button from '../../../components/UI/Button/Button';
import PencilImg from '../../../assets/pencil.png';
import Modal from '../../../components/UI/Modal/Modal';

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';




class ScoutingReportSummary extends Component{
    state = {

        isHomeTeam : true,
        currentBatter: null,
        batterIsNull: false,
        pitcherIsNull: false,
        currentPitcher:null,
        initialized: false,
        showPitcherBatters: 0, //0 - all, 1- right, 2- left,
        plateAppearance: 0, //0 - all

        filterPitcherPitches: "ALL",
        filterBatterPitches: "ALL",

        filterPitcherPitchLocation: "ALL",
        filterPitcherPitchType: "ALL",
        currentPlayer: null,
        showEditTeamNotes: false,
        homeNotes: '',
        homeNotesLoaded: false,
        awayNotes: '',
        awayNotesLoaded: false,
        showEditPlayerNotes: false,
        playerNotes: '',
        playerId: null,
        player:null,
        reload: false,
        showGameNotes: false,
        gameNotes: ''
    }

    componentDidMount(){
        //this.props.onLoadGame(this.props.token, this.props.match.params.id);
        //console.log(this.props.match.path);
        if(this.props.match.path === '/training_scouting_report_summary/:id'){
            //console.log('load training data')
            this.props.onLoadTrainingState(this.props.token, this.props.match.params.id);
        }
        else{
            //console.log('load game data')
            this.props.onLoadGameState(this.props.token, this.props.match.params.id);
        }
        
    }

    componentDidUpdate(prevProps, prevState){
        const isTop = this.isInningTop();
        if(!this.state.initialized && isTop && this.state.isHomeTeam){
            this.setState({
                initialized: true,
                isHomeTeam: false
            })
        }

        if(!this.state.showGameNotes && this.props.gameNotes !== this.state.gameNotes){
            this.setState({
                gameNotes: this.props.gameNotes
            })
        }

        if(!this.state.showEditTeamNotes && this.props.homeTeam && this.props.homeTeam.notes !== this.state.homeNotes){
            this.setState({
                homeNotes: this.props.homeTeam.notes
            })
        }
        if(!this.state.showEditTeamNotes && this.props.awayTeam && this.props.awayTeam.notes !== this.state.awayNotes){
            this.setState({
                awayNotes: this.props.awayTeam.notes
            })
        }
        
        if(this.props.currentHitter && !this.state.currentBatter && !this.state.batterIsNull){
            this.setState({
                currentBatter: this.props.currentHitter
            })
        }

        if(!this.props.training && this.props.currentPitcher && !this.state.currentPitcher && !this.state.pitcherIsNull){
            this.setState({
                currentPitcher: isTop ? this.props.homeTeamCurrentPitcher : this.props.awayTeamCurrentPitcher
            })
        }
        else if(this.props.training && this.props.currentPitcher && !this.state.currentPitcher && !this.state.pitcherIsNull){
            this.setState({
                currentPitcher: this.props.currentPitcher
            })
        }
        
        if(!this.props.gameStateLoading && this.props.atBatState !== prevProps.atBatState){
            //////console.log('calling on SaveGameState in AtBat', this.props.token, this.props.atBatState);
            //////console.log('calling on SaveGameState in AtBat this.props.pitchArray.length = ' + this.props.pitchArray.length);

            if(this.props.training){

                this.props.onSaveTrainingState(this.props.token, this.props.atBatState);
            }
            else{
                this.props.onSaveGameState(this.props.token, this.props.atBatState);
            }
            

            if(!this.props.gameOver){
                if(this.isGameOver()){
                    this.props.onSetGameOver(true);
                }
            }
        }
        if(this.state.reload){
            if(this.props.training){
                this.props.onLoadTrainingState(this.props.token, this.props.atBatState.training.id);
            }
            else{
                this.props.onLoadGameState(this.props.token, this.props.atBatState.game.id);
            }

            this.setState({
                reload:false
            })
        }
        
    }

    currentInning(){
        return Math.floor((this.props.currentInning / 2.0) + 1);
    }

    isInningTop(inning){
        //console.log('isInningTop ' + inning);
        let currentInning = (typeof inning !== 'undefined') ? inning : this.props.currentInning;
        // //console.log('Current Inning: ' + Math.floor((this.props.currentInning / 2.0) + 1));
        // //console.log('Top or bottom of inning? ' + (this.props.currentInning % 2 === 0 ? 'top' : 'bottom'));
        return currentInning % 2 === 0 ? true : false;
    }

    isGameOver = () =>{
        if(this.props.forceQuit){
            return true;
        }



        const inning = this.currentInning();
        const isTop = this.isInningTop();
        const homeScore = this.props.homeScore;
        const awayScore = this.props.awayScore;
        //if inning is this.props.totalInnings or greater and is in bottom and home team is winning, game is over
        if(inning >= this.props.totalInnings && !isTop && homeScore > awayScore){
            //this.props.onSetGameOver(true);
            return true;
        }
        //else if inning is greater than this.props.totalInnings and it is the end of the inning, if away team is winning game is over
        else if(inning > this.props.totalInnings && awayScore > homeScore){
            //this.props.onSetGameOver(true);
            return true;
        }
        return false;
    }

    toggleTeamHandler = () =>{
        const isHomeTeam = this.state.isHomeTeam;
        this.setState({
            isHomeTeam : ! isHomeTeam
        })
    }

    getPlayers = () =>{
        if(this.props.training){
            if(this.props.training.trainingType === 'Pitcher'){
                return [{...this.props.currentPitcher}];
            }
            else{
                return [{...this.props.currentHitter}];
            }
        }
        else{
            if(this.state.isHomeTeam){
                return this.props.homeTeamPlayers;
            }
            else{
                return this.props.awayTeamPlayers;
            }
        }
    }

    getPlayerNotes = (player) =>{
        let notes = [];
        let _pitchArray = [...this.props.pitchArray];
        for(let i=0;i<_pitchArray.length; ++i){
            const p = _pitchArray[i];
            if(p.pitcher.id === player.id){
                if(p.pitcherNotes && p.pitcherNotes !== ''){
                    let note = {
                        pitchID: p.id,
                        note: p.pitcherNotes,
                        type:'pitcher'
                    }
                    notes.push(note);
                }
            }
            if(p.batter.id === player.id){
                if(p.batterNotes && p.batterNotes !== ''){
                    let note = {
                        pitchID: p.id,
                        note: p.batterNotes,
                        type:'batter'
                    }
                    notes.push(note);
                }

            }
        }
        return notes;
    }


    onSelectPlayerHandler = (id) =>{
        //console.log('onSelectPlayerHandler ' + id);

        let player = null;
        if(this.props.training){
            if(this.props.training.trainingType === 'Pitcher'){
                player = {...this.props.currentPitcher};
            }
            else{
                player = {...this.props.currentHitter};
            }
        }
        else{
            if(this.state.isHomeTeam){
                player = this.props.homeTeamPlayers.find(player =>player.id === id);
            }else{
                player = this.props.awayTeamPlayers.find(player =>player.id === id);
            }
        }
        //console.log('currentPlayer', player);
        //let pitcherIsNull = player === null ? true : false;
        this.setState({
            currentPlayer: player,
           
        })
    }

    saveScoutingReport = (text) =>{
        if(this.state.currentPlayer){
            if(this.props.training){
                let players = [...this.props.homeTeamPlayers];
                let index = players.findIndex(player =>player.id === this.state.currentPlayer.id);

                let updatedPlayer = {...players[index], scoutReport: text}
                players.splice(index,1,updatedPlayer);
                this.props.onSetHomeTeamPlayers(players);

                if(this.props.training.trainingType === 'Pitcher'){
                    this.props.onSetPitcher(updatedPlayer);
                }
                else{
                    this.props.onSetBatter(updatedPlayer);
                }
                
            }
            else{
                let players = [...this.props.awayTeamPlayers];
                if(this.state.isHomeTeam){
                    players = [...this.props.homeTeamPlayers]
                }
                let index = players.findIndex(player =>player.id === this.state.currentPlayer.id);

                let updatedPlayer = {...players[index], scoutReport: text}
                players.splice(index,1,updatedPlayer);

                if(this.state.isHomeTeam){
                    //console.log('update home team player array');
                    //console.log(players);
                    this.props.onSetHomeTeamPlayers(players);
                }
                else{
                    //console.log('update away team player array');
                    //console.log(players);
                    this.props.onSetAwayTeamPlayers(players);
                }
            }
        }
    }
    onShowGameHandler = () =>{
        this.props.history.push({
            pathname: '/game/' + this.props.game.id
        });
   }
   onShowTrainingHandler = () =>{
        this.props.history.push({
            pathname: '/training/' + this.props.training.id
        });
    }


    onShowSummaryHandler = () =>{
        if(this.props.training){
            this.props.history.push({
                pathname: '/player_training_scouting_report/' + this.props.game.id
            });
        }
        else{
            this.props.history.push({
                pathname: '/player_scouting_report/' + this.props.game.id
            });
        }

    }

    getPlayerNotes = (player) =>{
        let notes = [];
        let _pitchArray = [...this.props.pitchArray];
        /*for(let i=0;i<_pitchArray.length; ++i){
            const p = _pitchArray[i];
            if(p.pitcher.id === player.id){
                if(p.pitcherNotes && p.pitcherNotes !== ''){
                    // let note = {
                    //     pitchID: p.id,
                    //     note: p.pitcherNotes,
                    //     type:'pitcher'
                    // }
                    notes.push('p-'+p.pitcherNotes);
                }
            }
            if(p.batter.id === player.id){
                if(p.batterNotes && p.batterNotes !== ''){
                    // let note = {
                    //     pitchID: p.id,
                    //     note: p.batterNotes,
                    //     type:'batter'
                    // }
                    notes.push('b-'+p.batterNotes);
                }

            }
        }*/
        let p = null;
        let rp = null;
        if(this.state.isHomeTeam){
            p = this.props.homeTeamPlayers.find(p =>p.id === player.id);
            rp = this.props.rootHomeTeamPlayers.find(rp =>rp.id === player.id);
        }else{
            p = this.props.awayTeamPlayers.find(p =>p.id === player.id);
            rp = this.props.rootAwayTeamPlayers.find(rp =>rp.id === player.id);
        }

        /*if(typeof p !== 'undefined' && p){
            notes.push(typeof p.notes === 'undefined' ? '' : p.notes.replace(/(?:\r\n|\r|\n)/g, '<br>'));
            //notes.push(typeof p.scoutReport === 'undefined' ? '' : p.scoutReport.replace(/(?:\r\n|\r|\n)/g, '<br>'));
            
        }*/
        if(typeof rp !== 'undefined' && rp){
            notes.push(typeof rp.notes === 'undefined' ? '' : rp.notes.replace(/(?:\r\n|\r|\n)/g, '<br>'));
            
        }
        return notes;
    }

    loadTrainingPlayers = () =>{
        let players = [];
        if(this.props.training){
            let player = null;
            if(this.props.training.trainingType === 'BatterPractice'){
                player = {...this.props.currentHitter};
                

            }
            else if(this.props.training.trainingType === 'Batter'){
                player = {...this.props.currentHitter};
            }
            else if(this.props.training.trainingType === 'Pitcher'){
                player = {...this.props.currentPitcher};
            }
            if(player){
                let rootPlayer = this.props.rootHomeTeamPlayers.find(p => p.id === player.id);
                if(rootPlayer){
                    player = {...player, notes: rootPlayer.notes, gradYear : rootPlayer.gradYear, battingSide: rootPlayer.battingSide, speed: rootPlayer.speed}
                }
                players.push(player);

            }

            
        }
        return players;
    }

    loadHomeTeamPlayers = () =>{
        let homeTeamPlayers = [...this.props.homeTeamLineup];
        //console.log('home team players', homeTeamPlayers);
        let indexOffset = 1;
        for(let i=0;i<this.props.homeSubstitutions.length; ++i){
            let sub = {...this.props.homeSubstitutions[i].index};
            if(typeof sub.number === 'undefined'){
                sub = {...this.props.homeSubstitutions[i]};
            }
            sub.number = 'S- ' + sub.number;
            sub.firstName = 'S- ' + sub.firstName;
            //homeTeamPlayers.push(sub);
            homeTeamPlayers.splice(sub.index+indexOffset, 0, sub);
            indexOffset += 1;
        }

        ////console.log('home subs', this.props.homeSubstitutions);
        if(this.props.homeTeamFlex){
            let flex = {...this.props.homeTeamFlex}
            homeTeamPlayers.push(flex);
        }

        let updatedPlayers = [];
        for(let i=0; i< homeTeamPlayers.length; ++i){
            let player = {...homeTeamPlayers[i]};
            let playerNotes = this.getPlayerNotes(player).join(" / ");

            let updatedPlayer = {...player, notes: playerNotes};

            let rootPlayer = this.props.rootHomeTeamPlayers.find(p => p.id === player.id);
            if(rootPlayer){
                updatedPlayer = {...updatedPlayer, gradYear : rootPlayer.gradYear, battingSide: rootPlayer.battingSide, speed: rootPlayer.speed}
            }

            updatedPlayers.push(updatedPlayer)
            
        }

        //console.log('home team players', updatedPlayers);

        return updatedPlayers;
    }
    
    loadAwayTeamPlayers = () =>{

        let awayTeamPlayers = [...this.props.awayTeamLineup];
        let indexOffset = 1;
        for(let i=0;i<this.props.awaySubstitutions.length; ++i){
            let sub = {...this.props.awaySubstitutions[i].index};
            if(typeof sub.number === 'undefined'){
                sub = {...this.props.awaySubstitutions[i]};
            }
            //awayTeamPlayers.push(sub);
            sub.number = 'S- ' + sub.number;
            sub.firstName = 'S- ' + sub.firstName;
            awayTeamPlayers.splice(sub.index+indexOffset, 0, sub);
            indexOffset += 1;
        }

        if(this.props.awayTeamFlex){
            let flex = {...this.props.awayTeamFlex}
            awayTeamPlayers.push(flex);
        }
        //awayTeamPlayers.push([...this.props.awaySubstitutions]);

        

        let updatedPlayers = [];
        for(let i=0; i< awayTeamPlayers.length; ++i){
            let player = {...awayTeamPlayers[i]};
            let playerNotes = this.getPlayerNotes(player).join(" / ");

            let updatedPlayer = {...player, notes: playerNotes};


            let rootPlayer = this.props.rootAwayTeamPlayers.find(p => p.id === player.id);
            if(rootPlayer){
                updatedPlayer = {...updatedPlayer, gradYear : rootPlayer.gradYear, battingSide: rootPlayer.battingSide, speed: rootPlayer.speed}
            }

            
            updatedPlayers.push(updatedPlayer)
            
        }
        //console.log('away team players', updatedPlayers);
        return updatedPlayers;
    }

    onClickEditPlayerNotes = (id, notes, player) =>{
        if(this.canEdit()){
        
            this.setState({
                showEditPlayerNotes: true,
                playerNotes: notes,
                playerId: id,
                player: player
            })
        }
    }

    onEditPlayerNotesCancel = () =>{
        this.setState({
            showEditPlayerNotes: false
        })
    }

    onClickEditTeamNotes = () =>{

        if(this.canEdit()){
            this.setState({
                showEditTeamNotes: true
            })
        }
    }

    onClickGameNotes= () =>{
        if(this.canEdit()){
            this.setState({
                showGameNotes:true
            })
        }
    }

    onEditTeamNotesCancel = () =>{
        this.setState({
            showEditTeamNotes: false
        })
    }

    onGameNotesCancel = () =>{
        this.setState({
            showGameNotes: false,
            reload:true
        })
    }

    gameNotesMessageChange = (event) =>{
        /*//console.log(event.target.value);
        this.setState({
            gameNotes: event.target.value
        })*/
        this.props.onSetGameNotes(event.target.value);
    }

    teamNotesMessageChange = (event) =>{
        
        if(this.state.isHomeTeam){
            this.setState({
                homeNotes : event.target.value
            })
        }
        else{
            this.setState({
                awayNotes : event.target.value
            })
        }
    }


    playerNotesMessageChange = (event) =>{

        this.setState({
            playerNotes : event.target.value
        })
    }

    submitPlayerNotesHandler = () =>{

        let updatedPlayer = {...this.state.player, notes: this.state.playerNotes}

        this.props.onUpdatePlayer(this.props.token, updatedPlayer);
        this.setState({
            showEditPlayerNotes: false,
            reload:true
        });
    }

    submitGameNotesHandler = () =>{

    }

    submitTeamNotesHandler = () =>{
        let teamNote = this.state.awayNotes;
        let teamID = this.props.awayTeam.id;
        let updatedTeam = {...this.props.awayTeam};
        if(this.state.isHomeTeam){
            teamNote = this.state.homeNotes;
            teamID = this.props.homeTeam.id;
            updatedTeam = {...this.props.homeTeam}
        }
        
        //console.log('save note for team id = ' + teamID);
        //console.log(teamNote);
        updatedTeam = {...updatedTeam, notes: teamNote}
        //console.log('updated Team', updatedTeam);

        this.props.onUpdateTeam(this.props.token, updatedTeam);

        this.setState({
            showEditTeamNotes: false,
            reload:true
        });
    }


    canEdit = () =>{

        if(this.props.auser && (this.props.auser.role === 'SysAdmin' ||
        this.props.auser.role === 'OrgAdmin' ||
        this.props.auser.role === 'Admin' ||
        this.props.auser.role === 'OrgAdminTraining' ||
        this.props.auser.role === 'AdminTraining')){
            return true;
        }
        return false;
    }

    getPitchers = () =>{
        let pa = [...this.props.pitchArray];
        //console.log('pitch array', pa);
        let pitchers = [];
        //console.log('pitch array length ' + pa.length)
        for(let i=0;i<pa.length; ++i){
            let pitch = pa[i];
            //console.log('pitch inning '+ pitch.inning + ' '+ ' ln ' + pitch.pitcher.lastName)
            if(this.state.isHomeTeam){
                //console.log('home Team')
                let pitcher = pitch.pitcher;
                let rootPlayer = this.props.rootHomeTeamPlayers.find(p => p.id === pitcher.id);
                if(rootPlayer){
                    
                    let index = pitchers.findIndex(pi => pi.id === pitcher.id);
                    if(index < 0){
                        
                        let  updatedPlayer = {...pitcher};
                        if(rootPlayer){
                            updatedPlayer = {...updatedPlayer, notes: rootPlayer.notes, gradYear : rootPlayer.gradYear, battingSide: rootPlayer.battingSide, speed: rootPlayer.speed}
                        }
                        pitchers.push({...updatedPlayer});
                    }
                }
                
               
            }
            else{
                let pitcher = pitch.pitcher;
                let rootPlayer = this.props.rootAwayTeamPlayers.find(p => p.id === pitcher.id);
                if(rootPlayer){
                    
                    let index = pitchers.findIndex(pi => pi.id === pitcher.id);
                    if(index < 0){
                        
                        let  updatedPlayer = {...pitcher};
                        if(rootPlayer){
                            updatedPlayer = {...updatedPlayer, notes: rootPlayer.notes, gradYear : rootPlayer.gradYear, battingSide: rootPlayer.battingSide, speed: rootPlayer.speed}
                        }
                        pitchers.push({...updatedPlayer});
                    }
                }
                
                
            }
        }
        //console.log('pitchers', pitchers);
        return pitchers;
    }

    getRemainingRoster(players){
        let ret = [];

        if(this.state.isHomeTeam){
            for(let  i=0;i<this.props.rootHomeTeamPlayers.length; ++i){
                let player = this.props.rootHomeTeamPlayers[i];
                if(typeof player.hidden !== 'undefined' && player.hidden === true)
                    continue;

                let index = players.findIndex(pi => pi.id === player.id);
                if(index < 0){
                    ret.push({...player})
                }
            }
        }
        else{
            for(let  i=0;i<this.props.rootAwayTeamPlayers.length; ++i){
                let player = this.props.rootAwayTeamPlayers[i];
                if(typeof player.hidden !== 'undefined' && player.hidden === true)
                    continue;
                
                let index = players.findIndex(pi => pi.id === player.id);
                if(index < 0){
                    ret.push({...player})
                }
            }

        }
        //console.log('remaining players', ret);
        return ret; 

    }
    

    render(){
        let scoutingReportForm = null;

        let players = null;
        let pitchers = null;
        let remainingPlayers = null;

        let gameNotes = null;
        let teamNotes = null;
        if(this.state.isHomeTeam){
            teamNotes = this.state.homeNotes;
        }
        else{
            teamNotes = this.state.awayNotes;
        }

        let editStyle = {width: '20px', display: 'inline', marginLeft: '0px'};

        if(!this.canEdit()){
            editStyle = {display:'none'};
        }

        

        if(this.props.gameNotes){
            if(this.props.training){
            gameNotes = <>
                        <h1>Training Notes <img onClick={this.onClickGameNotes} style={editStyle} src={PencilImg} alt="training note" /></h1>
                        <p>{ReactHtmlParser(this.props.gameNotes.replace(/(?:\r\n|\r|\n)/g, '<br>'))}</p>
                        </>
            }
            else{
                gameNotes = <>
                <h1>Game Notes - {this.isGameOver() === true ? 'FINAL - ' : ''}{this.props.awayTeam.name} at {this.props.homeTeam.name}  -
                      { new Date(this.props.game.date).toLocaleDateString("en-US")} <img onClick={this.onClickGameNotes} style={editStyle} src={PencilImg} alt="game note" />
                      </h1>
                <p>{ReactHtmlParser(this.props.gameNotes.replace(/(?:\r\n|\r|\n)/g, '<br>'))}</p>
                </>

            }
        }
        else if(this.props.homeTeam && this.props.awayTeam){
            if(!this.props.training){
                gameNotes = <>
                <h1>Game Notes - {this.isGameOver() === true ? 'FINAL - ' : ''}{this.props.awayTeam.name} at {this.props.homeTeam.name}  -
                        { new Date(this.props.game.date).toLocaleDateString("en-US")} <img onClick={this.onClickGameNotes} style={editStyle} src={PencilImg} alt="game note" />
                        </h1>
                <p></p>
                </>
            }
           
        }
        else if(this.props.training){
            if(this.props.training){
                gameNotes = <>
                            <h1>Training Notes <img onClick={this.onClickGameNotes} style={editStyle} src={PencilImg} alt="training note" /></h1>
                            <p></p>
                            </>
                }
        }
        
        /*let playerNote = null;
        let notesJSX = null;
        if(this.state.currentPlayer){
            playerNote = this.state.currentPlayer.notes ? this.state.currentPlayer.notes : null;
            let notes = this.getPlayerNotes(this.state.currentPlayer);
            if(notes.length === 0){
                notesJSX = <div>There are no pitch notes for this player</div>
            }
            else{
                let noteItems = [];
                for(let i=0;i<notes.length;++i){
                    noteItems.push(<li key={i}>{notes[i].type} - {notes[i].note}</li>)
                }
                notesJSX = <ul style={{listStyleType: 'none'}}>{noteItems}</ul>
            }
            
        }

        let playerNoteJSX = null;
        if(playerNote){
            playerNoteJSX = <><h3>Player Notes</h3><p>{playerNote}</p></>
        }
*/
        let teamButtons = null;
        let teamReport = null;
        if(this.props.homeTeam && this.props.awayTeam){
            if(this.state.isHomeTeam){
                let teamNotes = typeof this.props.homeTeam.notes === 'undefined' ? '' : this.props.homeTeam.notes.replace(/(?:\r\n|\r|\n)/g, '<br>');
                teamButtons = <div>
                                <Button btnType={ "Danger"}  btnSize="Small" clicked={this.toggleTeamHandler}>{this.props.homeTeam.name}</Button>
                                
                          </div>
                teamReport = <div><h1>Team Notes - {this.props.homeTeam.name} <img onClick={this.onClickEditTeamNotes} style={editStyle} src={PencilImg} alt="team note" /></h1><p>{ReactHtmlParser(teamNotes)}</p></div>
                players = this.loadHomeTeamPlayers();
                remainingPlayers=  this.getRemainingRoster(players);
            }
            else{
                let teamNotes = typeof this.props.awayTeam.notes === 'undefined' ? '' : this.props.awayTeam.notes.replace(/(?:\r\n|\r|\n)/g, '<br>');
                
                teamButtons = <div>
                                
                                <Button btnType={"Danger"} btnSize="Small" clicked={this.toggleTeamHandler}>{this.props.awayTeam.name}</Button>
                          </div>
                 teamReport = <div><h1>Team Notes - {this.props.awayTeam.name} <img onClick={this.onClickEditTeamNotes} style={editStyle} src={PencilImg} alt="team note" /></h1><p>{ReactHtmlParser(teamNotes)}</p></div>
                
                 players = this.loadAwayTeamPlayers();
                 remainingPlayers=  this.getRemainingRoster(players);
            }
            pitchers = this.getPitchers();
           
        }
        else if(this.props.training){
            players = this.loadTrainingPlayers();
        }
        let rJSX = [];
        //console.log('remaining players', remainingPlayers);
        if(remainingPlayers){
            for(let i=0;i<remainingPlayers.length;++i){
                let player = remainingPlayers[i];
                let jsx = <><tr key={'rp_' + i} className={styles.Player}>
                        <td>{player.number}</td>
                       
                        <td>{player.firstName} {player.lastName}</td>
                        <td>{player.currentPosition}</td>
                        <td>{player.gradYear}</td>
                        <td>{player.battingSide}</td>
                        <td>{player.speed}</td>
                        

                        <td></td>
                        </tr>
                        <tr key={'rpnote_' + i}>
                        <td colspan="6" style={{textAlign:"center"}}>{ReactHtmlParser(player.notes)}</td>
                        <td><img onClick={() =>this.onClickEditPlayerNotes(player.id, player.notes, player)} style={editStyle} src={PencilImg} alt="player note" /></td>
                        </tr>
                        </>
                rJSX.push(jsx);
            }
        }

        let pitchersJSX = [];
        if(pitchers){
            for(let i=0;i<pitchers.length;++i){
                let player = pitchers[i];
                let jsx = <><tr key={'pitchers_' + i} className={styles.Player}>
                        <td>{player.number}</td>
                       
                        <td>{player.firstName} {player.lastName}</td>
                        <td>{player.currentPosition}</td>
                        <td>{player.gradYear}</td>
                        <td>{player.battingSide}</td>
                        <td>{player.speed}</td>
                        

                        <td></td>
                        </tr>
                        <tr key={'pitcher_notes_' + i}>
                        <td colspan="6" style={{textAlign:"center"}}>{ReactHtmlParser(player.notes)}</td>
                        <td><img onClick={() =>this.onClickEditPlayerNotes(player.id, player.notes, player)} style={editStyle} src={PencilImg} alt="player note" /></td>
                        </tr>
                        </>
                pitchersJSX.push(jsx);
            }
           
        }
        let playersJSX = [];
        if(players){
            for(let i=0;i<players.length;++i){
                let player = players[i];
                let jsx = <><tr key={i} className={styles.Player}>
                        <td>{player.number}</td>
                       
                        <td>{player.firstName} {player.lastName}</td>
                        <td>{player.currentPosition}</td>
                        <td>{player.gradYear}</td>
                        <td>{player.battingSide}</td>
                        <td>{player.speed}</td>
                        

                        <td></td>
                        </tr>
                        <tr key={'notes_' + i}>
                        <td colspan="6" style={{textAlign:"center"}}>{ReactHtmlParser(player.notes)}</td>
                        <td><img onClick={() =>this.onClickEditPlayerNotes(player.id, player.notes, player)} style={editStyle} src={PencilImg} alt="player note" /></td>
                        </tr>
                        </>
                playersJSX.push(jsx);

            }
        }

        let playerTableJSX = null;

        if(this.props.game){
            playerTableJSX = 
        <>
        <h1>Player Notes</h1>
        <table className={styles.PlayerTable}>
            <tr style={{backgroundColor: "rgb(64, 64, 64)", color: "#FFC000"}}>
                <td>#</td>
                <td>Name</td>
                <td>POS</td>
                <td>Grad Yr</td>
                <td>Bats</td>
                <td>Speed</td>
                
                <td></td>
            </tr>
            {playersJSX}
        </table>

        <h1>Pitcher Notes</h1>
        <table className={styles.PlayerTable}>
            <tr style={{backgroundColor: "rgb(64, 64, 64)", color: "#FFC000"}}>
                <td>#</td>
                <td>Name</td>
                <td>POS</td>
                <td>Grad Yr</td>
                <td>Bats</td>
                <td>Speed</td>
                
                <td></td>
            </tr>
            {pitchersJSX}
        </table>


        <h1>Remaining Roster Notes</h1>
        <table className={styles.PlayerTable}>
            <tr style={{backgroundColor: "rgb(64, 64, 64)", color: "#FFC000"}}>
                <td>#</td>
                <td>Name</td>
                <td>POS</td>
                <td>Grad Yr</td>
                <td>Bats</td>
                <td>Speed</td>
                
                <td></td>
            </tr>
            {rJSX}
        </table>
        
        </>
        }
        else if (this.props.training){
            playerTableJSX = 
            <>
        <h1>Player Notes</h1>
        <table className={styles.PlayerTable}>
            <tr style={{backgroundColor: "rgb(64, 64, 64)", color: "#FFC000"}}>
                <td>#</td>
                <td>Name</td>
                <td>POS</td>
                <td>Grad Yr</td>
                <td>Bats</td>
                <td>Speed</td>
                
                <td></td>
            </tr>
            {playersJSX}
        </table>
        </>
        }

        let backToButton = <Button btnType="Danger" btnSize="Small" clicked={this.onShowGameHandler}>Back to Game</Button>
        let summaryButton = null;//<Button btnType="Danger" btnSize="Small" clicked={this.onShowSummaryHandler}>Scouting Report</Button>
        
        

        let title = null;
        if(this.props.game){
            title = <div className={styles.TitleContainer}>
                      <h3 style={{display: 'inline'}}>{this.isGameOver() === true ? 'FINAL - ' : ''}{this.props.awayTeam.name} at {this.props.homeTeam.name}  -
                      { new Date(this.props.game.date).toLocaleDateString("en-US")}</h3>
                      {backToButton}
                      {summaryButton}
                    </div>
        }

        
        
        if(this.props.training){
            backToButton = <Button btnType="Danger" clicked={this.onShowTrainingHandler}>Back to Training</Button>

            if(this.props.training.trainingType === 'BatterPractice'){
                title = <div className={styles.TitleContainer}>
                <h3 style={{display: 'inline'}}>Batter Practice {this.props.currentHitter.firstName} {this.props.currentHitter.lastName}  -
                { new Date(this.props.training.date).toLocaleDateString("en-US")}</h3>
                {backToButton}
                {summaryButton}
              </div>

            }
            else if(this.props.training.trainingType === 'Batter'){
                title = <div className={styles.TitleContainer}>
                <h3 style={{display: 'inline'}}>Batter Training {this.props.currentHitter.firstName} {this.props.currentHitter.lastName}  -
                { new Date(this.props.training.date).toLocaleDateString("en-US")}</h3>
                {backToButton}
                {summaryButton}
              </div>

            }
            else if(this.props.training.trainingType === 'Pitcher'){

                title = <div className={styles.TitleContainer}>
                <h3 style={{display: 'inline'}}>Pitcher Training {this.props.currentPitcher.firstName} {this.props.currentPitcher.lastName}  -
                { new Date(this.props.training.date).toLocaleDateString("en-US")}</h3>
                {backToButton}
                {summaryButton}
              </div>

            }

           
        }

        let playerHeader = null;
        if(this.state.player){
            playerHeader = <h2>{this.state.player.firstName} {this.state.player.lastName}</h2>
        }

        
        

    return(
        <>
        <Modal show={this.state.showGameNotes} clickBackdrop={this.onGameNotesCancel}>
            <div>
                <textarea className={styles.NotesArea} onChange={(event)=>this.gameNotesMessageChange(event)} value={this.props.gameNotes}></textarea>
            
                <Button type="Danger" clicked={this.onGameNotesCancel}>SAVE</Button>
            </div>
        </Modal>
        <Modal show={this.state.showEditTeamNotes} clickBackdrop={this.onEditTeamNotesCancel}>
            <div>
                <textarea className={styles.NotesArea} onChange={(event)=>this.teamNotesMessageChange(event)} value={teamNotes}></textarea>
            
                <Button type="Danger" clicked={this.submitTeamNotesHandler}>SAVE</Button>
            </div>
        </Modal>
        <Modal show={this.state.showEditPlayerNotes} clickBackdrop={this.onEditPlayerNotesCancel}>
            <div>
                {playerHeader}
                <textarea className={styles.NotesArea} onChange={(event)=>this.playerNotesMessageChange(event)} value={this.state.playerNotes}></textarea>
            
                <Button type="Danger" clicked={this.submitPlayerNotesHandler}>SAVE</Button>
            </div>
        </Modal>
        <div className={styles.ScoutingReportSummary}>
            {title}
            
            {teamButtons}
            <div className={styles.ReportContainer}>
            {playerTableJSX}
                {teamReport}
                {gameNotes}
                
                {/*playerNoteJSX*/}
                {/*notesJSX*/}
                {scoutingReportForm}
                
            </div>
        
        </div>
        </>
    )
    }
}


const mapDispatchToProps = dispatch =>{
    return{
        onLoadGameState: (token, id)=>dispatch(
            ActionTypes.loadGameState(token, id)
        ),
        onLoadTrainingState: (token, id)=>dispatch(
            ActionTypes.loadTrainingState(token, id)
        ),
        onSetAwayTeamPlayers: (data) =>dispatch(
            ActionTypes.setAwayTeamPlayers(data)
        ),
        onSetHomeTeamPlayers: (data) =>dispatch(
            ActionTypes.setHomeTeamPlayers(data)
        ),
        onSaveGameState: (token, data)=>dispatch(
            ActionTypes.saveGameState(token, data)
        ),
        onSaveTrainingState: (token, data)=>dispatch(
            ActionTypes.saveTrainingState(token, data)
        ),
        onSetPitcher: (data)=>dispatch(
            ActionTypes.setCurrentPitcher(data)
        ),
        onSetBatter: (data)=>dispatch(
            ActionTypes.setCurrentBatter(data)
        ),
        onUpdatePlayer: (token, payload)=>dispatch(
            ActionTypes.updatePlayer(token, payload)
        ),
        onUpdateTeam: (token, payload)=>dispatch(
            ActionTypes.updateTeam(token,payload)
        ),
        onSetGameNotes: (data)=>dispatch(
            ActionTypes.setGameNotes(data)
        ),
    }
}


const mapStateToProps = state =>{
    return{
        auser: state.auth.user,
        game: state.atBat.game,
        gameNotes: state.atBat.gameNotes,
        homeTeam: state.atBat.homeTeam,
        awayTeam: state.atBat.awayTeam,
        rootHomeTeamPlayers: state.atBat.rootHomeTeamPlayers,
        rootAwayTeamPlayers: state.atBat.rootAwayTeamPlayers,
        homeTeamPlayers: state.atBat.homeTeamPlayers,
        awayTeamPlayers: state.atBat.awayTeamPlayers,
        currentInning: state.atBat.currentInning,
        currentPitcher: state.atBat.currentPitcher,
        currentHitter: state.atBat.currentHitter,
        battingRight: state.atBat.battingRight,
        atBatState: state.atBat,
        gameStateLoading: state.atBat.loading,
        token: state.auth.token,
        pitchArray: state.atBat.pitchArray,
        outs: state.atBat.outs,
        pitchNumber: state.atBat.pitchNumber,
        homeScore: state.atBat.homeScore,
        awayScore: state.atBat.awayScore,
        gameOver: state.atBat.gameOver,
        homeTeamLineup: state.atBat.homeTeamLineup,
        homeTeamCurrentPitcher: state.atBat.homeTeamCurrentPitcher,
        awayTeamLineup: state.atBat.awayTeamLineup,
        awayTeamCurrentPitcher: state.atBat.awayTeamCurrentPitcher,
        homeTeamFlex: state.atBat.homeTeamFlex,
        awayTeamFlex: state.atBat.awayTeamFlex,
        totalInnings: state.atBat.totalInnings,
        homeTeamCurrentBatterIndex: state.atBat.homeTeamCurrentBatterIndex,
        awayTeamCurrentBatterIndex: state.atBat.awayTeamCurrentBatterIndex,
        inningScores: state.atBat.inningScores,
        currentTopInningScore: state.atBat.currentTopInningScore,
        currentBottomInningScore: state.atBat.currentBottomInningScore,
        pitcherView: state.atBat.pitcherView,
        training: state.atBat.training,
        pitchType: state.atBat.pitchType,
        homeSubstitutions: state.atBat.homeSubstitutions,
        awaySubstitutions: state.atBat.awaySubstitutions,
        
    }
}




export default connect(mapStateToProps, mapDispatchToProps) (ScoutingReportSummary);
