import React from 'react';
import classes from './PitchZoneArea.module.css';


const PitchZoneArea = (props) =>{
    let style = {backgroundColor:props.color};
    if(props.noOutline){
        style = {...style, outline:'none'}
    }
    if(props.borderLeft){
        style = {...style, borderLeft:'1px solid black'}
    }
    if(props.borderRight){
        style = {...style, borderRight:'1px solid black'}
    }
    if(props.borderTop){
        style = {...style, borderTop:'1px solid black'}
    }
    if(props.borderBottom){
        style = {...style, borderBottom:'1px solid black'}
    }

    return(
        <div className={classes.PitchZoneArea} style={style} onClick={() => props.onFilterZone(props.zone)}>
            <span>{props.value}</span>
        </div>
    );
}

export default PitchZoneArea;