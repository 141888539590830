import React from 'react';
import styles from './HitPowerButtons.module.css';
import HitPowerButton from './HitPowerButton/HitPowerButton';

const HitPowerButtons = (props) => {
    const options = [
        'Norm',
        'Hard',
        'Weak'
    ];

    const buttons = options.map((o,i) => {
        
        return (
            <HitPowerButton key={i} type={o} click={props.click(o)}
           
            />
        )
    });

    return (
    <div className={styles.HitPowerButtons}>
    {buttons}
    </div>
    )
}

export default HitPowerButtons;