import React, {Component} from 'react';
import { connect } from 'react-redux';
import Spinner from '../../../components/UI/Spinner/Spinner';
import * as ActionTypes from '../../../store/actions/index';
import TeamComponent from '../../../components/Team/Team';
import TeamForm from '../../../containers/Teams/TeamForm/TeamForm';
import PlayerForm from '../../../containers/Player/PlayerForm/PlayerForm';
import classes from './ScoutingReport.module.css';
import Aux from '../../../hoc/Aux';
import Modal from '../../../components/UI/Modal/Modal';
import Button from '../../../components/UI/Button/Button';
import PencilImg from '../../../assets/pencil.png';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';



import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

class ScoutingReport extends Component{

    constructor(props){
        super(props);
        this.state = {
            reloadComponent: false,
            reloadCount: 0,
            showEditTeamNotes: false,
            notes: '',
            notesLoaded: false,
            showEditPlayerNotes: false,
            playerNotes: '',
            playerId: null,
            player:null
        }
        
    }
    
    componentDidMount(){
        this.props.onLoadTeam(this.props.token, this.props.match.params.id);
        this.props.onGetTeamPlayers(this.props.token, this.props.match.params.id);
    }
    componentDidUpdate(prevProps, prevState){
        //console.log('calling componentDidUpdate');
        let reload = false;

        if(this.state.reloadComponent){
            let reloadCount = this.state.reloadCount;

            if(reloadCount< 2){
                reload = true;
            }
            reloadCount = reloadCount + 1;

            this.setState({
                reloadComponent: reload,
                reloadCount: reloadCount
            });
        }
        if(prevProps.players && this.props.players && prevProps.players.length !== this.props.players.length){
            reload = true;

        }
        else if(this.props.players && prevProps.players){
            for(let i=0; i < this.props.players.length; ++i){
                const pp = prevProps.players[i];
                const p = this.props.players[i];
                if(pp.firstName !== p.firstName || 
                pp.lastName !== p.lastName ||
                pp.number !== p.number){
                    reload = true;
                }
            }
        }
        if(this.props.teams.length === 1 && !this.state.notesLoaded && this.state.notes !== this.props.teams[0].notes){
            //console.log('set notes ' + this.props.teams[0].notes);
            this.setState({
                notes: this.props.teams[0].notes,
                notesLoaded:true
            })
        }


        if(reload){
            this.props.onLoadTeam(this.props.token, this.props.match.params.id);
            this.props.onGetTeamPlayers(this.props.token, this.props.match.params.id);
        }

        

       
        /*//console.log('should update players');   
        this.props.onLoadTeam(this.props.token, this.props.match.params.id);
        this.props.onGetTeamPlayers(this.props.token, this.props.match.params.id);
          */ 
        
    }

    onClickEditPlayerNotes = (id, notes, player) =>{
        //console.log('edit player id ' + id + 'notes ' + notes +' player', player);
        
        this.setState({
            showEditPlayerNotes: true,
            playerNotes: typeof notes !== 'undefined' ? notes : '',
            playerId: id,
            player: player
        })
    }

    onEditPlayerNotesCancel = () =>{
        this.setState({
            showEditPlayerNotes: false
        })
    }

    onClickEditTeamNotes = () =>{
        this.setState({
            showEditTeamNotes: true
        })
    }

    onEditTeamNotesCancel = () =>{
        this.setState({
            showEditTeamNotes: false
        })
    }

    teamNotesMessageChange = (event) =>{

        this.setState({
            notes : event.target.value
        })
    }


    playerNotesMessageChange = (event) =>{

        this.setState({
            playerNotes : event.target.value
        })
    }

    submitPlayerNotesHandler = () =>{

        if(typeof this.state.player.id === 'undefined' || this.state.player.id === 'undefined'){
            alert("Error saving player note.  Player ID is undefined");
            return;
        }
        else{
            let updatedPlayer = {...this.state.player, notes: this.state.playerNotes}
            this.props.onUpdatePlayer(this.props.token, updatedPlayer);
        }
        this.setState({
            showEditPlayerNotes: false
        });
    }

    submitTeamNotesHandler = () =>{
        let teamNote = this.state.notes;
        let teamID = this.props.teams[0].id;
        //console.log('save note for team id = ' + teamID);
        //console.log(teamNote);
        let updatedTeam = {...this.props.teams[0], notes: teamNote}
        //console.log('updated Team', updatedTeam);

        this.props.onUpdateTeam(this.props.token, updatedTeam);

        this.setState({
            showEditTeamNotes: false
        });
    }

    canEdit = () =>{

        if(this.props.auser && (this.props.auser.role === 'SysAdmin' ||
        this.props.auser.role === 'OrgAdmin' ||
        this.props.auser.role === 'Admin' ||
        this.props.auser.role === 'OrgAdminTraining' ||
        this.props.auser.role === 'AdminTraining')){
            return true;
        }
        return false;
    }



    

    render(){
        


        let teamNotes = null;
        let header = null;
        if(this.props.teams && this.props.teams.length > 0){
            teamNotes = typeof this.props.teams[0].notes !== 'undefined' ? this.props.teams[0].notes.replace(/(?:\r\n|\r|\n)/g, '<br>') : '';
        header = <h1>Scouting Report for {this.props.teams[0].name} {this.props.teams[0].year}</h1>
        }
        
        let editStyle = {width: '20px', display: 'inline', marginLeft: '0px'};

        if(!this.canEdit()){
            editStyle = {display:'none'};
        }

        ////console.log('Players:');
        ////console.log(this.props.players);

        //let players = null;
        let postSortPlayers = null;
        let playerArray = [];
        if(this.props.players){

            
            ////console.log('sorting players by number');
            postSortPlayers = this.props.players.sort(function(obj1, obj2) {
                //sort by player number asc
                return obj1.number - obj2.number;
            });
        

            for(let i=0;i<postSortPlayers.length; ++i){
                let player = postSortPlayers[i];
                let playerNotes = null;

                if(typeof player.notes !== 'undefined' && player.notes ){
                    playerNotes = player.notes.replace(/(?:\r\n|\r|\n)/g, '<br>')
                }
                if(typeof player.hidden !== 'undefined' && player.hidden === true){
                    continue;
                }
                else{
                   /* playerArray.push({
                        id: p.id,
                        number: p.number,
                        firstName: p.firstName,
                        lastName : p.lastName,
                        delete: "Delete"
                    })*/
                    /*let pJSX = <tr className={classes.Player} key={p.id}>
                            <td>{p.number}</td>
                            <td>{p.firstName}</td>
                            <td>{p.lastName}</td>
                            <td>{p.gradYear}</td>
                            <td>{p.battingSide}</td>
                            <td>{p.speed}</td>
                            <td></td>
                            
                            <td>{p.notes}</td>
                            <td><img onClick={() =>this.onClickEditPlayerNotes(p.id, p.notes, p)} style={{width: '20px', display: 'inline', marginLeft: '0px'}} src={PencilImg} alt="player note" /></td>
                            </tr>
                    playerArray.push(pJSX);*/

                    let jsx = <><tr key={i} className={classes.Player}>
                        <td>{player.number}</td>
                       
                        <td>{player.firstName} {player.lastName}</td>
                        <td>{player.currentPosition}</td>
                        <td>{player.gradYear}</td>
                        <td>{player.battingSide}</td>
                        <td>{player.speed}</td>
                        

                        <td></td>
                        </tr>
                        <tr key={'notes_' + i}>
                        <td colSpan="6" style={{textAlign:"center"}}>{ReactHtmlParser(playerNotes)}</td>
                        <td><img onClick={() =>this.onClickEditPlayerNotes(player.id, player.notes, player)} style={editStyle} src={PencilImg} alt="player note" /></td>
                        </tr>
                        </>
                    playerArray.push(jsx);
                }
            }
        }
        else{
            //players = <Spinner />
        }



        let playerHeader = null;
        if(this.state.player){
            playerHeader = <h2>{this.state.player.firstName} {this.state.player.lastName}</h2>
        }



        return (
            <>
            <Modal show={this.state.showEditTeamNotes} clickBackdrop={this.onEditTeamNotesCancel}>
                <div>
                    <textarea className={classes.NotesArea} onChange={(event)=>this.teamNotesMessageChange(event)} value={this.state.notes}></textarea>
                
                    <Button type="Danger" clicked={this.submitTeamNotesHandler}>SAVE</Button>
               </div>
            </Modal>
            <Modal show={this.state.showEditPlayerNotes} clickBackdrop={this.onEditPlayerNotesCancel}>
                <div>
                    {playerHeader}
                    <textarea className={classes.NotesArea} onChange={(event)=>this.playerNotesMessageChange(event)} value={this.state.playerNotes}></textarea>
                
                    <Button type="Danger" clicked={this.submitPlayerNotesHandler}>SAVE</Button>
               </div>
            </Modal>

            <div className={classes.ScoutingReport}>            
            {header}

            <div className={classes.Container}>
            <h1>Team Notes <img onClick={this.onClickEditTeamNotes} style={editStyle} src={PencilImg} alt="team note" /></h1>
            <div className={classes.Notes}>
            {ReactHtmlParser(teamNotes)}
            </div>
            
            <h1>Player Notes</h1>
            <table className={classes.PlayerTable} cellSpacing="0" cellPadding="0">
                <thead>
                <tr style={{backgroundColor: "rgb(64, 64, 64)", color: "#FFC000"}}>
                    <td>#</td>
                    <td>Name</td>
                    <td>POS</td>
                    <td>Grad Yr</td>
                    <td>Bats</td>
                    <td>Speed</td>
                    <td></td>
                </tr>
                </thead>
                <tbody>
                {playerArray}
                </tbody>
            </table>    
            </div>
            </div>
            </>
        )
    }
}




const mapDispatchToProps = dispatch =>{
    return{
        onLoadTeam: (token, id) =>dispatch(
            ActionTypes.getTeam(token, id)
        ),
        onUpdateTeam: (token, payload)=>dispatch(
            ActionTypes.updateTeam(token,payload)
        ),
        onCreatePlayer: (token, payload)=>dispatch(
            ActionTypes.createPlayer(token, payload)
        ),
        onUpdatePlayer: (token, payload)=>dispatch(
            ActionTypes.updatePlayer(token, payload)
        ),
        onGetTeamPlayers: (token, payload)=>dispatch(
            ActionTypes.getTeamPlayers(token, payload)
        )
    }
}

const mapStateToProps = state =>{
    return{
        error: state.team.error,
        message: state.team.message,
        loading: state.team.loading,
        teams: state.team.teams,
        
        playerError: state.player.error,
        playerMessage: state.player.message,
        playerLoading: state.player.loading,
        players: state.player.players,
        playerId: state.player.id,
        token: state.auth.token,
        auser: state.auth.user,

        
        
        
    };
}

export default connect(mapStateToProps, mapDispatchToProps) (ScoutingReport);