import React from 'react';
import styles from './HitPowerButton.module.css';

const HitPowerButton = (props) => {
    return (
    <div className={styles.HitPowerButton}
        onClick={() => props.click(props.type)}
        >
    {props.type}
    </div>
    )
}

export default HitPowerButton;