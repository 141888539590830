import React, {Component} from 'react';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import Spinner from '../../../components/UI/Spinner/Spinner';
import classes from './GameForm.module.css';
import { connect } from 'react-redux';

class GameForm extends Component{
    state = {
        controls: {
            homeTeam:{
                
                elementType: 'select',
                elementConfig: {
                    label: 'Home Team',
                    options : [
                       
                    ]
                    
                },
                value:'-LiefE4Km_BjOpc58MIK',
                validation: {},
                valid:true
            },
            awayTeam:{
                
                elementType: 'select',
                elementConfig: {
                    label: 'Away Team',
                    options : [
                       
                    ]
                    
                },
                value:'-LiefE4Km_BjOpc58MIK',
                validation: {},
                valid:true
            },

            pitchType:{
                
                elementType: 'select',
                elementConfig: {
                    label: 'Pitch Type',
                    options : [
                        {value: 'Softball', displayValue: 'Softball'},
                        {value: 'Baseball', displayValue: 'Baseball'}
                    ]
                    
                },
                value:'Softball',
                validation: {},
                valid:true,
                disabled:true
            },
            innings:{
                
                elementType: 'select',
                elementConfig: {
                    label: 'Innings',
                    options : [
                        {value: '1', displayValue: '1'},
                        {value: '2', displayValue: '2'},
                        {value: '3', displayValue: '3'},
                        {value: '4', displayValue: '4'},
                        {value: '5', displayValue: '5'},
                        {value: '6', displayValue: '6'},
                        {value: '7', displayValue: '7'},
                        {value: '8', displayValue: '8'},
                        {value: '9', displayValue: '9'},
                    ]
                    
                },
                value:'7',
                validation: {},
                valid:true
            },
            gameType:{
                
                elementType: 'select',
                elementConfig: {
                    label: 'Game Type',
                    options : [
                        {value: 'League/Conference', displayValue: 'League/Conference'},
                        {value: 'Non-League', displayValue: 'Non-League'},
                        {value: 'Scouting Opposing Team', displayValue: 'Scouting Opposing Team'},
                    ]
                    
                },
                value:'League/Conference',
                validation: {},
                valid:true
            },
            year: {
                elementType: 'datepicker',
                elementConfig: {
                    label: 'Date',
                    type: 'text',
                    placeholder: 'Year'
                },
                value: '',
                validation: {
                    required: true,
                },
                valid: false,
                touched: false
            },
            
            
            
        },
        formIsValid: false,
        id: null
    }

    componentDidUpdate(prevProps, prevState) {

        
        if(prevProps.teams !== this.props.teams || prevProps.auser !== this.props.auser){
            ////console.log('GameForm->componentDidUpdate');
            ////console.log(this.props.teams);
            if(this.props.teams.length > 0){
                const updatedForm = {
                    ...this.state.controls
                }
                let updatedPitchType  = null;
                
                if(this.props.auser.org){
                    updatedPitchType = {...updatedForm['pitchType']};
                    updatedPitchType.value = this.props.auser.org.teamType;
                }

                const updatedFormAwayTeam = {...updatedForm['awayTeam']};
                updatedFormAwayTeam.value = this.props.teams[0].id;
                let updatedOptions = [];
                let team;
                for(team in this.props.teams){
                    let t = this.props.teams[team];
                    if(typeof t.hidden !== 'undefined' && t.hidden === true){
                        continue;
                    }
                    let option = {
                        value: this.props.teams[team].id,
                        displayValue: this.props.teams[team].name +' '+ this.props.teams[team].year
                    }
                    updatedOptions.push(option);
                }
                updatedFormAwayTeam.elementConfig.options = updatedOptions;
                updatedFormAwayTeam.valid = true;

                const updatedFormHomeTeam = {...updatedForm['homeTeam']};
                updatedFormHomeTeam.value = this.props.teams[0].id;
                updatedFormHomeTeam.elementConfig.options = updatedOptions;
                updatedFormHomeTeam.valid = true;

                if(updatedPitchType){
                    updatedForm['pitchType'] = updatedPitchType;
                }

                updatedForm['awayTeam'] = updatedFormAwayTeam;
                updatedForm['homeTeam'] = updatedFormHomeTeam;

                let formIsValid = true;
                for(let inputIds in updatedForm){
                    formIsValid = updatedForm[inputIds].valid && formIsValid;
                }
                this.setState({controls: updatedForm, formIsValid: formIsValid});
            }
        
        }
    }

    componentDidMount(){
        if(this.props.teams){
            ////console.log('GameForm->componentDidMount');
            ////console.log(this.props.teams);
        
            // const updatedForm = {
            //     ...this.state.controls
            // }
            // const updatedFormAwayTeam = {...updatedForm['awayTeam']};
            // updatedFormAwayTeam.value = this.props.teams[0].id;
            // updatedFormName.valid = this.checkValidity(updatedFormName.value, updatedFormName.validation);
        
            // const updatedFormYear = {...updatedForm['year']};
            // updatedFormYear.value = this.props.team.year;
            // updatedFormYear.valid = this.checkValidity(updatedFormYear.value, updatedFormYear.validation);
        
            // updatedForm['name'] = updatedFormName;
            // updatedForm['year'] = updatedFormYear;

            // let formIsValid = true;
            // for(let inputIds in updatedForm){
            //     formIsValid = updatedForm[inputIds].valid && formIsValid;
            // }
            // this.setState({controls: updatedForm, formIsValid: formIsValid, id: this.props.team.id});
        }
    }

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }
        
        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        if (rules.isNumeric) {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }


    inputChangedHandler = (event, inputIdentifier, type) =>{
        ////console.log('GameForm->inputChangeHandler ' + type);
        const updatedForm = {
            ...this.state.controls
        }
        const updatedFormElement = {...updatedForm[inputIdentifier]};
        let formIsValid = true;
        if(type === 'datepicker'){
            updatedFormElement.value = new Date(event);
            updatedFormElement.valid = true;
            ////console.log(updatedFormElement.value);
        }
        else{
            updatedFormElement.value = event.target.value;
            updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        
        }
        updatedFormElement.touched = true;
        updatedForm[inputIdentifier] = updatedFormElement;

        ////console.log(updatedForm);
        for(let inputIds in updatedForm){
            formIsValid = updatedForm[inputIds].valid && formIsValid;
        }
        this.setState({controls: updatedForm, formIsValid: formIsValid});
    }

    submitHandler = (event) =>{
        event.preventDefault();
        ////console.log('calling submitHandler in GameForm ' 
        
        //+ this.state.controls.homeTeam.value+' '
        //+ this.state.controls.awayTeam.value+' '
        //+ this.state.controls.year.value);

        let homeTeam = this.props.teams.find(team => team.id === this.state.controls.homeTeam.value);
        ////console.log(this.props.teams);
        ////console.log('home team:');
        ////console.log(homeTeam);

        let awayTeam = this.props.teams.find(team => team.id === this.state.controls.awayTeam.value);
        


        const data = {
            homeTeam: homeTeam,
            awayTeam: awayTeam,
            date: this.state.controls.year.value,
            innings: this.state.controls.innings.value,
            userId: this.props.userId,
            gameType: this.state.controls.gameType.value,
            pitchType: this.state.controls.pitchType.value,
            organizationId: this.props.auser.organizationId
        }
       
        //console.log('Game Data', data);
        this.props.submit(event, data);
    }


    render(){

        const formElementsArray = [];
        for(let key in this.state.controls){
            formElementsArray.push({
                id: key,
                config: this.state.controls[key],
                validation: this.state.controls[key].validation,
                valid: this.state.controls[key].valid,
                touched: this.state.controls[key].touched,
                disabled: this.state.controls[key].disabled,
            });
        }

        let form = (
            <form onSubmit={(event)=>this.submitHandler(event)}>
                    {formElementsArray.map(el => (
                        <Input 
                            key={el.id}
                            disabled={el.disabled}
                            elementType={el.config.elementType}
                            elementConfig={el.config.elementConfig}
                            label={el.config.elementConfig.label}
                            value={el.config.value}
                            invalid = {!el.valid}
                            shouldValidate ={el.validation && el.touched}
                            changed={(event)=> this.inputChangedHandler(event, el.id, el.config.elementType)}/>
                    ))}
                        
                    
                    <Button disabled={!this.state.formIsValid} btnType="Success">SUBMIT</Button>
                </form>
        );

        if(this.props.loading){
            form = <Spinner />;
        }
        /*let message = null;
        if(this.props.error){
            message = <span className={classes.Error}>{this.props.message}</span>
        }
        else if(this.props.message !== null){
            message = <span className={classes.Success}>{this.props.message}</span>
        }*/


        return (
            <div className={classes.GameForm}>
                <h3>{this.props.game === null? "Create Game" : "Update Game"}</h3>
                {form}
            </div>
        )
    }
}

const mapStateToProps = state =>{
    return{
        token: state.auth.token,
        userId: state.auth.userId,
        games: state.game.games,
        teams: state.team.teams,
        auser: state.auth.user
    };
}

export default connect(mapStateToProps, null) (GameForm);
