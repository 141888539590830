import React from 'react';
import styles from './FieldPlotter.module.css';
import FieldLocations from './FieldLocations/FieldLocations';

const FieldPlotter = (props) =>{
    function field_getCoordinates(e)
    {
        //let area = document.getElementById('fieldarea');
        //const rect = area.getBoundingClientRect();
        ////console.log(rect.top, rect.right, rect.bottom, rect.left);
        ////console.log('mouse move! ' + e.clientX +' ' + e.clientY);
        // let x=e.clientX;
        // let y=e.clientY;
        // x = (e.clientX - rect.left);
        // y = (e.clientY - rect.top);
        ////console.log(x +' '+ y);
    }
    return (
        <div id='fieldarea' className={styles.FieldPlotter}>
        <canvas id="myCanvas" 
            onMouseMove={field_getCoordinates} 
            width="280" height="280" 
            ></canvas>
        <FieldLocations clicked={props.clicked} showMiss={props.showMiss}/>
        </div>
    )
}

export default FieldPlotter;