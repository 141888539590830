import React, {Component} from 'react';
import {connect} from 'react-redux';
import classes from './StateArray.module.css';
import * as ActionTypes from '../../../store/actions/index';
import Button from '../../../components/UI/Button/Button';

class StateArray extends Component{


    onGoBack =()=>{
        //console.log('GO BACK');

        if(this.props.popState === true){
            //console.log('need to pop the state pitch!');
            this.props.popPitchArrayHandler();
        }
        else{
            let lastIndex = this.props.gameStateArray.stateArray.length-1;
            let newState = {...this.props.gameStateArray.stateArray[lastIndex]}


            let updatedGameStateArray = {...this.props.gameStateArray};
            updatedGameStateArray.stateArray.splice(lastIndex,1);
            updatedGameStateArray.currentIndex  =  updatedGameStateArray.stateArray.length - 1;
            //console.log('NEW STATE', newState);
            this.props.onSetPop();
            this.props.onSetGameState(newState);
            //this.props.onSaveGameState(this.props.token, newState);
            this.props.onSaveGameStateArray(this.props.token,updatedGameStateArray );
        }
    }
    

    render(){
        let backButton = null;
        let forwardButton = null;
        let pitchTotal = '';
        if(this.props.gameStateArray &&
           typeof this.props.gameStateArray.stateArray !== 'undefined'){

            

            
        }
        if(this.props.gameStateArray && this.props.popState || 
            (typeof this.props.gameStateArray.stateArray !== 'undefined' &&
            this.props.gameStateArray.stateArray.length > 0)){
            backButton = <Button  btnType="Red" btnSize="Small" clicked={() => this.onGoBack()}>Go Back</Button>

            if(typeof this.props.gameStateArray.stateArray !== 'undefined'){
                pitchTotal = this.props.gameStateArray.stateArray.length;
            }
        }
        
        
        


        return(
        
        <div className={classes.StateArray}>
            {backButton}
            {pitchTotal}
        </div>
        
        )
    }
};




const mapStateToProps = state =>{
    return{
        gameStateArray: state.stateArray,
        gameState : state.atBat,
        token: state.auth.token
    }
}


const mapDispatchToProps = dispatch =>{
    return{
        onSaveGameState: (token, data)=>dispatch(
            ActionTypes.saveGameState(token, data)
        ),
        onSaveGameStateArray: (token, data)=>dispatch(
            ActionTypes.saveGameStateArray(token, data)
        ),
        onSetGameState: (data)=>dispatch(
            ActionTypes.setGameState(data)
        ),
        onSetPop: () =>dispatch(
            ActionTypes.setPop()
        )
        
    }
};



export default connect(mapStateToProps, mapDispatchToProps) (StateArray);
