import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as ActionTypes from '../../store/actions/index';

import PitchPlotter from '../../components/PitchPlotter/PitchPlotter';
import styles from './AtBat.module.css';
import PitchClass from '../../classes/Softball/Pitch';
//import AtBatClass from '../../classes/Softball/AtBat';
import PitchTypeButtons from '../../components/PitchTypeButtons/PitchTypeButtons';
import BattingSide from '../../components/PitchPlotter/BattingSide/BattingSide';
import Pitch from '../../components/PitchPlotter/Pitch/Pitch';
import PitchList from '../../components/PitchList/PitchList';
import PitchResultButtons from '../../components/PitchResultButtons/PitchResultButtons';
import BatterActionButtons from '../../components/BatterActionButtons/BatterActionButtons';
import FieldPlotter from '../../components/FieldPlotter/FieldPlotter';
import HitActionButtons from '../../components/FieldPlotter/HitActionButtons/HitActionButtons';
import HitPowerButtons from '../../components/FieldPlotter/HitPowerButtons/HitPowerButtons';
import HitResultButtons from '../../components/FieldPlotter/HitResultButtons/HitResultButtons';
import FoulResultButtons from '../../components/FieldPlotter/FoulResultButtons/FoulResultButtons';
import PlayerSelector from '../PlayerSelector/PlayerSelector';
import PlayerPosition from '../PlayerPosition/PlayerPosition';
import GameState from '../../components/GameState/GameState';
import PitchHistory from '../../components/PitchHistory/PitchHistory';
import Modal from '../../components/UI/Modal/Modal';
import BaseRunners from '../../components/BaseRunners/BaseRunners';
import BaseRunner from '../../components/BaseRunners/BaseRunner/BaseRunner';
import Button from '../../components/UI/Button/Button';
import Spinner from '../../components/UI/Spinner/Spinner';
import Lineup from '../Lineup/Lineup';
import LineupComponent from '../../components/Lineup/Lineup';
import PitchLocation from '../../components/PitchPlotter/PitchLocation/PitchLocation';
import BaseRunnersContainer from '../BaseRunners/BaseRunners';
import PencilImg from '../../assets/pencil.png';
import BatterTrainingPlayerStats from '../PlayerAnalysis/BatterTrainingAnalysis/PlayerStats/PlayerStats';
import BatterZoneTable from   '../PlayerAnalysis/BatterTrainingAnalysis/BatterZoneTable/BatterZoneTable';
import BatterTrainingFilterTables from '../PlayerAnalysis/BatterTrainingAnalysis/FilterTables/FilterTables';
import BatterTrainingPitchPlotter from '../PlayerAnalysis/PitchPlotter/PitchPlotter';
import BatterTrainingFieldPlotter from '../PlayerAnalysis/FieldPlotter/FieldPlotter';
import TrainingNote from '../PlayerAnalysis/BatterTrainingAnalysis/TrainingNote/TrainingNote';

import PitcherTrainingPlayerStats from '../PlayerAnalysis/PitcherTrainingAnalysis/PlayerStats/PlayerStats';
import PitcherTrainingZoneTable from '../PlayerAnalysis/PitcherTrainingAnalysis/PitchZoneTable/PitchZoneTable';
import PitcherTrainingPitchPlotter from '../PlayerAnalysis/PitchPlotter/PitchPlotter';
import StateArray from './StateArray/StateArray';

const WIDTH = 220;
const HEIGHT = 220;
class AtBat extends Component{
    state = {
        pitchLocation: {
            x:0,
            y:0
        },
        locationName: '',
        pitches: [],
        currentStep: 0,
        currentPitch: null,
        ballCount: 0,
        strikeCount: 0,
        reconcilingBaseRunners: false,
        reconcileIncrementBatter: true,
        incrementInningAfterReconcile: false,
        showPitchHistory: false,
        showPlayerSubstitution: false,
        reconcileBaseRunner: false,
        baseRunnersToReconcile: null,
        substituteIndex: 0,
        playerToSub: null,
        showForceQuit: false,
        forceQuitMessage:null,
        showDelete: false,
        showPitcherSelection: false,
        showBatterSelection: false,
        showGameNotes: false,
        showPitcherNotes: false,
        pitcherNotes: null,
        showBatterNotes: false,
        batterNotes: null,
        pitchVelocity: '',
        handleFC: false,
        FCdata: null,
        filterPitcherPitches: "ALL",
        filterBatterPitches: "ALL",
        filterPitcherPitchLocation: "ALL",
        filterPitcherPitchType: "ALL",


        filterBatterPitchesByHitPower: "ALL",
        filterBatterPitchesByHitAction: "ALL",
        filterBatterPitchesByHitResult: "ALL",
        showPitcherBatters: 0, //0 - all, 1- right, 2- left,
        dontSetNextBatter: false,
        showSwitchFlex: false,
        showHomeTeamNotes: false,
        homeTeamNotes: '',
        homeTeamNotesLoaded:false,
        showAwayTeamNotes: false,
        awayTeamNotes: '',
        awayTeamNotesLoaded:false
  
    }

    componentDidMount(){
        //////console.log(this.props.match);
        if(this.props.match.path === '/training/:id'){
            //////console.log('This is a training, not a game');
            this.props.onLoadTrainingState(this.props.token, this.props.match.params.id);
            this.props.onLoadGameStateArray(this.props.token, this.props.match.params.id);
        }
        else{
            //////console.log('This is a game, not a training');
            this.props.onLoadGameState(this.props.token, this.props.match.params.id);
            this.props.onLoadGameStateArray(this.props.token, this.props.match.params.id)
        }
        //
    }

    componentDidUpdate(prevProps, prevState){

        
        /*if(this.props.gameStateArray && !this.props.gameStateArray.loading &&
            (this.props.gameStateArray.atBatID !== prevProps.gameStateArray.atBatID ||
                this.props.gameStateArray.gameID !== prevProps.gameStateArray.gameID ||
                this.props.gameStateArray.stateArray !== prevProps.gameStateArray.stateArray ||
                this.props.gameStateArray.currentIndex !== prevProps.gameStateArray.currentIndex)){
           this.props.onSaveGameStateArray(this.props.token, this.props.gameStateArray);
        }*/

        if(!this.props.gameStateLoading && this.props.atBatState !== prevProps.atBatState){
            //////console.log('calling on SaveGameState in AtBat', this.props.token, this.props.atBatState);
            //////console.log('calling on SaveGameState in AtBat this.props.pitchArray.length = ' + this.props.pitchArray.length);

            if(this.props.training){

                this.props.onSaveTrainingState(this.props.token, this.props.atBatState);

                //if(this.props.gameStateArray.stateArray.length > 0){
                //    if(this.props.gameStateArray.stateArray[0].training.id !== this.props.training.id)
                //}

                /*
if(this.props.training){
            if(this.props.training.trainingType === 'Pitcher'){
                player = {...this.props.currentPitcher};
            }
            else{
                player = {...this.props.currentHitter};
            }
            rootPlayer = this.props.rootHomeTeamPlayers.find(player => player.id === player.id);
        }
        else{
            if(this.state.isHomeTeam){
                player = this.props.homeTeamPlayers.find(player =>player.id === id);
                rootPlayer = this.props.rootHomeTeamPlayers.find(player => player.id === id);
            }else{
                player = this.props.awayTeamPlayers.find(player =>player.id === id);
                rootPlayer = this.props.rootAwayTeamPlayers.find(player => player.id === id);
            }
        }
                */

                if(this.props.currentHitter){
                    const rootPlayer = this.props.rootHomeTeamPlayers.find(player => player.id === this.props.currentHitter.id);
                    if(rootPlayer){
                        this.setState({
                            batterNotes : rootPlayer.notes ? rootPlayer.notes : ''
                        })
                    }
                }
                if(this.props.currentPitcher){
                    const rootPlayer = this.props.rootHomeTeamPlayers.find(player => player.id === this.props.currentPitcher.id);
                    if(rootPlayer){
                        this.setState({
                            pitcherNotes : rootPlayer.notes ? rootPlayer.notes : ''
                        })
                    }
                }
            }
            else{
                this.props.onSaveGameState(this.props.token, this.props.atBatState);
                //console.log('ComponentDidUpdate!!!!!!!');
                //console.log(prevProps.atBatState.wasPopped +' '+this.props.atBatState.wasPopped );
                //console.log('gameStateArray', this.props.gameStateArray );
                //console.log('this.props.gameStateArray.loading',this.props.gameStateArray.loading);
                //console.log('this.props.atBatState.wasPopped', this.props.atBatState.wasPopped);

                let batterNotes = '';
                let pitcherNotes = '';

                if(this.props.currentHitter){
                    let rootPlayer = null;
                    if(this.isInningTop()){
                        //current hitter is away team
                        rootPlayer = this.props.rootAwayTeamPlayers.find(player => player.id === this.props.currentHitter.id);
                        
                    }
                    else{
                        //current hitter is home team
                        rootPlayer = this.props.rootHomeTeamPlayers.find(player => player.id === this.props.currentHitter.id);
                    }
                    if(rootPlayer){
                        this.setState({
                            batterNotes: rootPlayer.notes
                        });
                    }
                }

                if(this.props.currentPitcher){
                    let rootPlayer = null;
                    if(this.isInningTop()){
                        //current pitcher is home team
                        rootPlayer = this.props.rootHomeTeamPlayers.find(player => player.id === this.props.currentPitcher.id);
                    }
                    else{
                        //current pitcher is away team
                        rootPlayer = this.props.rootAwayTeamPlayers.find(player => player.id === this.props.currentPitcher.id);
                    }
                    if(rootPlayer){
                        this.setState({
                            pitcherNotes: rootPlayer.notes
                        });
                    }
                }


                /*if(this.props.currentHitter){
                    this.setState({
                        batterNotes : this.props.currentHitter.notes ? this.props.currentHitter.notes : ''
                    })
                }
                if(this.props.currentPitcher){
                    this.setState({
                        pitcherNotes : this.props.currentPitcher.notes ? this.props.currentPitcher.notes : ''
                    })
                }*/
            
            }
            
            if(!this.state.homeTeamNotesLoaded && this.props.homeTeam && this.props.homeTeam.notes !== this.state.homeTeamNotes){
                this.setState({
                    homeTeamNotes: this.props.homeTeam.notes,
                    homeTeamNotesLoaded: true
                })
            }

            if(!this.state.awayTeamNotesLoaded && this.props.awayTeam && this.props.awayTeam.notes !== this.state.awayTeamNotes){
                this.setState({
                    awayTeamNotes: this.props.awayTeam.notes,
                    awayTeamNotesLoaded: true
                })
            }

            if(!this.props.gameOver){
                if(this.isGameOver()){
                    this.props.onSetGameOver(true);
                }
            }
        }
    }

    isStrike= () => {
        if(this.state.pitchLocation.x >= 0.175  &&
           this.state.pitchLocation.x <= 0.825  &&
           this.state.pitchLocation.y >= 0.175 &&
           this.state.pitchLocation.y <= 0.825) {
           return true;
        }
        return false;
    }

    LocationName = (updatedLocation) =>{
        //IN/OUT/MIDDLE
        let battingRight = this.props.battingRight;
        let ret = '';
        var x = updatedLocation.x;
        var y = updatedLocation.y;
        if(!battingRight){
            x = 1.0 - x;
        }
        if(x < 0.175){
            ret = 'IN';
        }
        else if(x > 0.825){
            ret = 'OUT';
        }
        else if(x < 0.4){
            ret = 'MIDDLE-IN';
        }
        else if(x > 0.6){
            ret = 'MIDDLE-OUT';
        }
        else{
            ret = 'MIDDLE';
        }
        ret += '/';
        if(y < 0.175){
            ret += 'HIGH';
        }
        else if (y > 0.825){
            ret += 'LOW';
        }
        else if(y < 0.4){
            ret += 'MIDDLE-HIGH';
        }
        else if(y > 0.6){
            ret += 'MIDDLE-LOW';
        }
        else{
            ret += 'MIDDLE';
        }
        this.setState({
            locationName: ret
        });
    }

    pitchLocationHandler = (newX, newY) =>{
        if(!this.canEdit() && !this.props.isTraining){
            return;
        }
      
        if(this.state.currentStep !== 0)
            return;

        const newPitchLocation = {
            ...this.state.pitchLocation
        };
        newPitchLocation.x = newX;
        newPitchLocation.y = newY;    

       

        ////////console.log(newX,newY);
        //let newPitchLocation = [...this.state.pitchLocation];
        

        var id = 'p_' + this.state.pitches.length + 1;
        const x = newX;
        const y = newY;

        let updatedCurrentPitch = null;

        if(newX !== 0 && newY !== 0){
            let baseRunners = [...this.props.baseRunners];
            updatedCurrentPitch = new PitchClass(id,
                this.state.pitches.length + 1,
                x,
                y,
                1, 
                this.props.battingRight,
                this.props.currentPitcher,
                this.props.currentHitter,
                this.currentInning(),
                this.props.outs,
                this.state.ballCount,
                this.state.strikeCount,
                this.props.pitcherView,
                baseRunners, '', '');

            updatedCurrentPitch = PitchClass.SetCount(updatedCurrentPitch, this.state.ballCount, this.state.strikeCount);
        }

    
        
        this.setState({
            pitchLocation: newPitchLocation,
            currentPitch: updatedCurrentPitch
        });
        this.LocationName(newPitchLocation);


    }

        
    pitchClickHandler = (_x,_y) =>{
        if(!this.canEdit() && !this.props.isTraining){
            return;
        }
        ////console.log('pitchClickHandler this.props.pitchArray.length = ' + this.props.pitchArray.length);
        
        //////console.log('current step = ' + this.state.currentStep);
        //////console.log('clicked pitch at location ' , this.state.pitchLocation);
        var id = 'p_' + this.state.pitches.length + 1;
        const x = _x;//this.state.pitchLocation.x;
        const y = _y;//this.state.pitchLocation.y;
        const updatedPitchArray = [...this.state.pitches];
        let baseRunners = [...this.props.baseRunners];
        let currentPitch = new PitchClass(id,
                                            this.state.pitches.length + 1,
                                            x,
                                            y,
                                            1, 
                                            this.props.battingRight,
                                            this.props.currentPitcher,
                                            this.props.currentHitter,
                                            this.currentInning(),
                                            this.props.outs,
                                            this.state.ballCount,
                                            this.state.strikeCount,
                                            this.props.pitcherView,
                                            baseRunners, '', '');

        currentPitch.pitchLocation = PitchClass.LocationAbbrv(currentPitch);
        currentPitch = PitchClass.SetCount(currentPitch, this.state.ballCount, this.state.strikeCount);
        updatedPitchArray.push(currentPitch);
        ////console.log('updatedPitchArray = ', updatedPitchArray);
        
        
        
        //DG SCREWS UP UNDO
        //this.props.onSetPitchNumber(this.props.pitchArray.length + updatedPitchArray.length);
        
        
        
        let updatedStep = this.state.currentStep + 1;
        //console.log('ptichClickHanlder setting step to: ' + updatedStep);
        if(this.props.training && this.props.training.trainingType === 'BatterPractice'){
            updatedStep = 2;
        }

        this.setState({
            pitches: updatedPitchArray,
            currentStep: updatedStep,
            currentPitch: currentPitch
        });

    }
    battingSideChangeHandler = () =>{
        ////////console.log('batting side changed');
        let newBattingSide = this.props.battingRight
        
        newBattingSide = !newBattingSide;
        
        this.props.onSetBattingRight(newBattingSide);
        // this.setState({
        //     battingRight:newBattingSide
        // });
    }

    popPitchHandler = () =>{
        ////console.log('popPitchHandler this.props.pitchArray.length = ' + this.props.pitchArray.length);
        const updatedPitchArray = [...this.state.pitches];




        
        let upatedStrikeCount = 0;
        let upatedBallCount = 0;



        if(updatedPitchArray.length > 0){
            upatedStrikeCount = updatedPitchArray[updatedPitchArray.length - 1].strikeCount;
            upatedBallCount = updatedPitchArray[updatedPitchArray.length - 1].ballCount;
        }
        updatedPitchArray.pop();


        const updatedStep = 0;

        const newPitchLocation = {
            ...this.state.pitchLocation
        };
        newPitchLocation.x = 0;
        newPitchLocation.y = 0;

        //this.props.onSetPitchNumber(this.props.pitchArray.length + updatedPitchArray.length);


        this.setState({
            currentPitch: null,
            strikeCount: upatedStrikeCount,
            ballCount: upatedBallCount,
            pitchLocation: newPitchLocation,
            pitches: updatedPitchArray,
            currentStep: updatedStep
        });
    }

    onPitchTypeHandler = (type) =>{
        ////////console.log('onPitchTypeHandler ',  type);
        let updatedPitch = {
            ...this.state.currentPitch
        };
        let updatedPitchArray = [...this.state.pitches];
        updatedPitchArray.pop();

        updatedPitch = PitchClass.LoadPitchType(updatedPitch, type);
        ////////console.log('[AtBat.js] updatedPitch type = ' + updatedPitch.pitchType);
        updatedPitchArray.push(updatedPitch);
        const updatedStep = 2;

        this.setState({
            currentPitch: updatedPitch,
            pitches: updatedPitchArray,
            currentStep: updatedStep,
        })
    }
    reconcileBaseRunnerCancel = () =>{
        this.setState({
            reconcileBaseRunner: false,
            baseRunnersToReconcile: null
        })
    }

    handleShowReconcileBaseRunner = (player) =>{
        //////console.log('handleShowReconcileBaseRunner', player);
        let updatedPlayer = {...player};
        this.setState({
            reconcileBaseRunner: true,
            baseRunnersToReconcile: [{...updatedPlayer}]
        })
    }

    handleHomeRun = (updatedPitchArray) =>{

        //everyone currently on base plus current batter score
        /*let numRunnersScore = this.props.baseRunners.length + 1;
        
        //clear the current base runners
        let baseRunnersArray = [];
        this.props.onUpdateBaseRunners(baseRunnersArray);

        //add to the score of the current batting team
        if(this.isInningTop()){
            //away team is at bat
            let currentRunTotal = parseInt(this.props.awayScore);
            currentRunTotal = parseInt(numRunnersScore) + currentRunTotal;
            this.props.onSetAwayScore(currentRunTotal);
        }
        else{
            //home team is at bat
            let currentRunTotal = parseInt(this.props.homeScore);
            currentRunTotal = parseInt(numRunnersScore) + currentRunTotal;
            this.props.onSetHomeScore(currentRunTotal);
        }*/
        

        if(!this.props.training)
        {
            this.setState({
                reconcilingBaseRunners: true
            });
        
        
            this.setNextBatter();
        }
        //this.props.onSetBatter(null);
        this.updateGameStatePitchArray(updatedPitchArray);

        this.setState({
            currentPitch: null,
            pitches: [],
            currentStep: 0,//newStep,
            strikeCount: 0,
            ballCount: 0,
            batterNotes: null,
            pitcherNotes: null
        })

    }

    addBaseRunner = (pitch) =>{
        if(this.props.training){
            return;
        }
        let baseRunnersArray = [
            ...this.props.baseRunners
        ];


        

        baseRunnersArray.push(pitch);
        this.props.onUpdateBaseRunners(baseRunnersArray);

        let fcDataFirstBase = null;
        let fcDataSecondBase = null;
        let fcDataThirdBase = null;

        if(this.props.firstBaseRunner){
            fcDataFirstBase = {...this.props.firstBaseRunner};
        }
        if(this.props.secondBaseRunner){
            fcDataSecondBase = {...this.props.secondBaseRunner};
        }
        if(this.props.thirdBaseRunner){
            fcDataThirdBase = {...this.props.thirdBaseRunner};
        }

        let FCdata = {
            batter: {...pitch},
            firstbase: fcDataFirstBase,
            secondbase: fcDataSecondBase,
            thirdbase: fcDataThirdBase
        }

        //if fielders choice, someone is out, and need to figure out how to do base runners
        //if the batter is thrown out, base runners stay the same
        //if the first baseman is thrown out, the batter takes first and second and third stay the same
        //if the second baseman is thrown out, first goes to second, batter goes to first, third stays the same
        //if third baseman is thrown out, batter goes to first, first goes to second, second goes to third
        if(pitch.result === 'FC')
        {
            this.setState({
                reconcileBaseRunner: true,
                baseRunnersToReconcile: baseRunnersArray,
                handleFC: true,
                FCdata: FCdata
            });
            //this.props.onSetFirstBaseRunner({...pitch});

        }


        else if(pitch.result === '1B' || this.isWalk(pitch) || pitch.pitchResult === 'HBP'
        || pitch.result === 'ROE' || pitch.pitchResult === 'DRP3-PB/WP'
        ){
            if(this.props.firstBaseRunner && this.props.secondBaseRunner && this.props.thirdBaseRunner){
                //bases are loaded
                //first must go to second
                //second must go to third
                //third must go home or out
                this.setState({
                    reconcileBaseRunner: true,
                    baseRunnersToReconcile: [{...this.props.thirdBaseRunner}]
                })
                let newThirdBase = {...this.props.secondBaseRunner}
                let newSecondBase = {...this.props.firstBaseRunner}
                this.props.onSetThirdBaseRunner(newThirdBase);
                this.props.onSetSecondBaseRunner(newSecondBase);
                this.props.onSetFirstBaseRunner({...pitch});
            }
            else if(this.props.secondBaseRunner && this.props.thirdBaseRunner){
                //runners on second and third
                //second stays
                //third stays
                this.props.onSetFirstBaseRunner({...pitch});
            }
            else if(this.props.firstBaseRunner && this.props.secondBaseRunner){
                //runners on first and second
                //first goes home
                //second goes home
                let newThirdBase = {...this.props.secondBaseRunner};
                let newSecondBase =  {...this.props.firstBaseRunner};
                this.props.onSetThirdBaseRunner(newThirdBase);
                this.props.onSetSecondBaseRunner(newSecondBase);
                this.props.onSetFirstBaseRunner({...pitch});
            }
            else if(this.props.firstBaseRunner && this.props.thirdBaseRunner){
                //runners on first and third
                //third stays
                //first goes to second
                let newSecondBase = {...this.props.firstBaseRunner};
                this.props.onSetSecondBaseRunner(newSecondBase);
                this.props.onSetFirstBaseRunner({...pitch});
            }
            else if(this.props.firstBaseRunner){
                //runner on first
                let newSecondBase = {...this.props.firstBaseRunner};
                this.props.onSetSecondBaseRunner(newSecondBase);
                this.props.onSetFirstBaseRunner({...pitch});
            }
            else if(this.props.secondBaseRunner){
                //runner on second
                //runnner at second stays
                this.props.onSetFirstBaseRunner({...pitch});
            }
            else if(this.props.thirdBaseRunner){
                //runner on third stays
                this.props.onSetFirstBaseRunner({...pitch});
            }
            else{
                this.props.onSetFirstBaseRunner({...pitch});
            }
        
        }
        
        else if(pitch.result === '2B'){
            if(this.props.firstBaseRunner && this.props.secondBaseRunner && this.props.thirdBaseRunner){
                //bases are loaded
                //first must go to third or score
                //third must go home or out
                //second must go home or out
                //first base is open
                
                this.setState({
                    reconcileBaseRunner: true,
                    baseRunnersToReconcile: [{...this.props.secondBaseRunner}, {...this.props.thirdBaseRunner}]
                })
                let newThirdBase = {...this.props.firstBaseRunner};
                this.props.onSetThirdBaseRunner(newThirdBase);
                this.props.onSetSecondBaseRunner({...pitch});
                this.props.onSetFirstBaseRunner(null);
            }
            else if(this.props.secondBaseRunner && this.props.thirdBaseRunner){
                //runners on second and third
                //second goes to third
                //third goes home
                this.setState({
                    reconcileBaseRunner: true,
                    baseRunnersToReconcile: [{...this.props.thirdBaseRunner}]
                });
                let newThirdBase = {...this.props.secondBaseRunner}
                this.props.onSetThirdBaseRunner(newThirdBase);
                this.props.onSetSecondBaseRunner({...pitch});
                this.props.onSetFirstBaseRunner(null);
            }
            else if(this.props.firstBaseRunner && this.props.secondBaseRunner){
                //runners on first and second
                //first goes to third
                //second goes home
                this.setState({
                    reconcileBaseRunner: true,
                    baseRunnersToReconcile: [{...this.props.secondBaseRunner}]
                });
                let newThirdBase = {...this.props.firstBaseRunner}
                this.props.onSetThirdBaseRunner(newThirdBase);
                this.props.onSetSecondBaseRunner({...pitch});
                this.props.onSetFirstBaseRunner(null);
            }
            else if(this.props.firstBaseRunner && this.props.thirdBaseRunner){
                //runners on first and third
                //third goes home
                //first goes to third
                this.setState({
                    reconcileBaseRunner: true,
                    baseRunnersToReconcile: [{...this.props.thirdBaseRunner}]
                });
                let newThirdBase = {...this.props.firstBaseRunner}
                this.props.onSetThirdBaseRunner(newThirdBase);
                this.props.onSetSecondBaseRunner({...pitch});
                this.props.onSetFirstBaseRunner(null);
            
            }
            else if(this.props.firstBaseRunner){
                //runner on first

                let newThirdBase = {...this.props.firstBaseRunner}
                this.props.onSetThirdBaseRunner(newThirdBase);
                this.props.onSetSecondBaseRunner({...pitch});
                this.props.onSetFirstBaseRunner(null);
            }
            else if(this.props.secondBaseRunner){
                //runner on second
                //runnner at second goes to third
                let newThirdBase = {...this.props.secondBaseRunner}
                this.props.onSetThirdBaseRunner(newThirdBase);
                this.props.onSetSecondBaseRunner({...pitch});
                this.props.onSetFirstBaseRunner(null);
            }
            else if(this.props.thirdBaseRunner){
                //runner on third
                //keep that runner put
                this.props.onSetSecondBaseRunner({...pitch});
            }else{
                this.props.onSetSecondBaseRunner({...pitch});
            }
        }
        else if(pitch.result === '3B'){
            if(this.props.firstBaseRunner && this.props.secondBaseRunner && this.props.thirdBaseRunner){
                //bases are loaded
                //first must go home or score
                //third must go home or out
                //second must go home or out
                //first base is open
                
                this.setState({
                    reconcileBaseRunner: true,
                    baseRunnersToReconcile: [{...this.props.firstBaseRunner},{...this.props.secondBaseRunner}, {...this.props.thirdBaseRunner}]
                })
                this.props.onSetThirdBaseRunner({...pitch});
                this.props.onSetSecondBaseRunner(null);
                this.props.onSetFirstBaseRunner(null);
            }
            else if(this.props.secondBaseRunner && this.props.thirdBaseRunner){
                //runners on second and third
                //second goes home
                //third goes home
                this.setState({
                    reconcileBaseRunner: true,
                    baseRunnersToReconcile: [{...this.props.secondBaseRunner}, {...this.props.thirdBaseRunner}]
                });
                this.props.onSetThirdBaseRunner({...pitch});
                this.props.onSetSecondBaseRunner(null);
                this.props.onSetFirstBaseRunner(null);
            }
            else if(this.props.firstBaseRunner && this.props.secondBaseRunner){
                //runners on first and second
                //first goes home
                //second goes home
                this.setState({
                    reconcileBaseRunner: true,
                    baseRunnersToReconcile: [{...this.props.firstBaseRunner}, {...this.props.secondBaseRunner}]
                });
                this.props.onSetThirdBaseRunner({...pitch});
                this.props.onSetSecondBaseRunner(null);
                this.props.onSetFirstBaseRunner(null);
            }
            else if(this.props.firstBaseRunner && this.props.thirdBaseRunner){
                //runners on first and third
                //third goes home
                //first goes home
                this.setState({
                    reconcileBaseRunner: true,
                    baseRunnersToReconcile: [{...this.props.firstBaseRunner}, {...this.props.thirdBaseRunner}]
                });
                this.props.onSetThirdBaseRunner({...pitch});
                this.props.onSetSecondBaseRunner(null);
                this.props.onSetFirstBaseRunner(null);
            
            }
            else if(this.props.firstBaseRunner){
                //runner on first
                this.setState({
                    reconcileBaseRunner: true,
                    baseRunnersToReconcile: [{...this.props.firstBaseRunner}]
                });
                this.props.onSetThirdBaseRunner({...pitch});
                this.props.onSetSecondBaseRunner(null);
                this.props.onSetFirstBaseRunner(null);
            }
            else if(this.props.secondBaseRunner){
                //runner on second
                //runnner at second goes hoome
                this.setState({
                    reconcileBaseRunner: true,
                    baseRunnersToReconcile: [{...this.props.secondBaseRunner}]
                });
                this.props.onSetThirdBaseRunner({...pitch});
                this.props.onSetSecondBaseRunner(null);
                this.props.onSetFirstBaseRunner(null);
            }
            else if(this.props.thirdBaseRunner){
                //runner on third goes home
                this.setState({
                    reconcileBaseRunner: true,
                    baseRunnersToReconcile: [{...this.props.thirdBaseRunner}]
                });
                this.props.onSetThirdBaseRunner({...pitch});
                this.props.onSetSecondBaseRunner(null);
                this.props.onSetFirstBaseRunner(null);
            }
            else{
                this.props.onSetThirdBaseRunner({...pitch});
            }
        
        }
        else if(pitch.result === 'HR' || pitch.result === 'IPHR'){
            if(this.props.firstBaseRunner && this.props.secondBaseRunner && this.props.thirdBaseRunner){
                
                
                this.setState({
                    reconcileBaseRunner: true,
                    baseRunnersToReconcile: [{...this.props.firstBaseRunner},{...this.props.secondBaseRunner}, {...this.props.thirdBaseRunner}, {...pitch}]
                })
                this.props.onSetThirdBaseRunner(null);
                this.props.onSetSecondBaseRunner(null);
                this.props.onSetFirstBaseRunner(null);
            }
            else if(this.props.secondBaseRunner && this.props.thirdBaseRunner){
                //runners on second and third
                this.setState({
                    reconcileBaseRunner: true,
                    baseRunnersToReconcile: [{...this.props.secondBaseRunner}, {...this.props.thirdBaseRunner}, {...pitch}]
                });
                this.props.onSetThirdBaseRunner(null);
                this.props.onSetSecondBaseRunner(null);
                this.props.onSetFirstBaseRunner(null);
            }
            else if(this.props.firstBaseRunner && this.props.secondBaseRunner){
                //runners on first and second
                
                this.setState({
                    reconcileBaseRunner: true,
                    baseRunnersToReconcile: [{...this.props.firstBaseRunner}, {...this.props.secondBaseRunner},{...pitch}]
                });
                this.props.onSetThirdBaseRunner(null);
                this.props.onSetSecondBaseRunner(null);
                this.props.onSetFirstBaseRunner(null);
            }
            else if(this.props.firstBaseRunner && this.props.thirdBaseRunner){
                //runners on first and third
                
                this.setState({
                    reconcileBaseRunner: true,
                    baseRunnersToReconcile: [{...this.props.firstBaseRunner}, {...this.props.thirdBaseRunner},{...pitch}]
                });
                this.props.onSetThirdBaseRunner(null);
                this.props.onSetSecondBaseRunner(null);
                this.props.onSetFirstBaseRunner(null);
            
            }
            else if(this.props.firstBaseRunner){
                //runner on first
                this.setState({
                    reconcileBaseRunner: true,
                    baseRunnersToReconcile: [{...this.props.firstBaseRunner},{...pitch}]
                });
                this.props.onSetThirdBaseRunner(null);
                this.props.onSetSecondBaseRunner(null);
                this.props.onSetFirstBaseRunner(null);
            }
            else if(this.props.secondBaseRunner){
                //runner on second
                //runnner at second goes hoome
                this.setState({
                    reconcileBaseRunner: true,
                    baseRunnersToReconcile: [{...this.props.secondBaseRunner},{...pitch}]
                });
                this.props.onSetThirdBaseRunner(null);
                this.props.onSetSecondBaseRunner(null);
                this.props.onSetFirstBaseRunner(null);
            }
            else if(this.props.thirdBaseRunner){
                //runner on third goes home
                this.setState({
                    reconcileBaseRunner: true,
                    baseRunnersToReconcile: [{...this.props.thirdBaseRunner},{...pitch}]
                });
                this.props.onSetThirdBaseRunner(null);
                this.props.onSetSecondBaseRunner(null);
                this.props.onSetFirstBaseRunner(null);
            }
            else{
                this.setState({
                    reconcileBaseRunner: true,
                    baseRunnersToReconcile: [{...pitch}]
                });
                
            }
        
        }
    }

    handleOnBase = (updatedPitchArray) =>{
        if(!this.props.training){
            //const lastPitch = updatedPitchArray[updatedPitchArray.length-1];
            //if(lastPitch.hitResult === 'FC' && lastPitch.)
            this.setNextBatter();
            
        }
        //this.props.onSetBatter(null);
        this.updateGameStatePitchArray(updatedPitchArray);

        this.setState({
            currentPitch: null,
            pitches: [],
            currentStep: 0,//newStep,
            strikeCount: 0,
            ballCount: 0,
            batterNotes: null,
            pitcherNotes: null
        })
    }

    saveInningScores = () =>{
        let updatedInningScores = [...this.props.inningScores];

        let inningScore = this.props.currentTopInningScore;
           // bottom: this.props.currentBottomInningScore
        if(!this.isInningTop()){
            inningScore = this.props.currentBottomInningScore;
        }

        updatedInningScores.push(inningScore);
        this.props.onSetInningScores(updatedInningScores);

        //clear out the current top/bottom inning scores
        this.props.onSetCurrentBottomInningScore(0);
        this.props.onSetCurrentTopInningScore(0);
    }

    postInningReconcileComplete = () =>{
        this.saveInningScores();
        let updatedInning = this.props.currentInning + 1;
        this.props.onSetCurrentInning(updatedInning);
        this.props.onSetOuts(0);
        //this.props.onSetBatter(null);

        this.props.onSetFirstBaseRunner(null);
        this.props.onSetSecondBaseRunner(null);
        this.props.onSetThirdBaseRunner(null);

        //this is wrong.  It increments the batter of the new half inning and skips the batter who should up.
        //I think what is needed here is to set the batter for the new inning

        //if the last pitch was FC, then the next batter has already been set.  Don't do it again.
        if(this.props.pitchArray[this.props.pitchArray.length-1].result !== 'FC' &&
            this.state.reconcileIncrementBatter){
            this.setNextBatter(updatedInning - 1);
        }
        this.setBatter(updatedInning);
        this.setPitcher(updatedInning);
        //this.props.onSetPitcher(null);
        if(this.isGameOver()){
            this.props.onSetGameOver(true);
        }
        this.setState({
            incrementInningAfterReconcile : false,
            reconcilingBaseRunners: false,
            reconcileIncrementBatter: true
        })

    }

    setPitcher = (currentInning) =>{
        if(this.isInningTop(currentInning)){
            if(this.props.homeTeamCurrentPitcher){
                //////console.log('homeTeamCurrentPitcher is not null');
                let pitcher = {...this.props.homeTeamCurrentPitcher};
                this.props.onSetPitcher(pitcher);
            }
            
        }
        else{
            if(this.props.awayTeamCurrentPitcher){
                //////console.log('awayTeamCurrentPitcher is not null');
                let pitcher = {...this.props.awayTeamCurrentPitcher};
                this.props.onSetPitcher(pitcher);
            }
            
        }
    }

    setBatter = (currentInning)=>{
        if(this.isInningTop(currentInning)){
            if(this.props.awayTeamLineup && this.props.awayTeamLineup.length > 0){
                let batterIndex = this.props.awayTeamCurrentBatterIndex;
                let nextBatter = this.props.awayTeamLineup[batterIndex];
                this.props.onSetBatter(nextBatter);
            }
            
        }
        else{
            if(this.props.homeTeamLineup && this.props.homeTeamLineup.length > 0){
                let batterIndex = this.props.homeTeamCurrentBatterIndex;
                let nextBatter = this.props.homeTeamLineup[batterIndex];
                this.props.onSetBatter(nextBatter);
            }
            

        }
    }

    setNextBatter = (currentInning)=>{
        if(this.props.training){
            return;
        }

        let updatedGameStatePitchArray = [
            ...this.props.pitchArray
            
        ];
        let lastPitch = updatedGameStatePitchArray[updatedGameStatePitchArray.length-1];

        let updatedPitchArray = [...this.state.pitches];
        if(updatedPitchArray.length > 0){
            lastPitch = updatedPitchArray[updatedPitchArray.length-1];
        }


        //if true, the away team batter needs to be the next one in the lineup
        if(this.isInningTop(currentInning)){
            let batterIndex = this.props.awayTeamCurrentBatterIndex;

            let lastBatter = this.props.awayTeamLineup[batterIndex];
            if(lastBatter.id !== lastPitch.batter.id){
                //alert('Last Batter ID does not match lastPitch batter id');
                //This player hasn't had an at bat, so don't increment the lineup
                return;
            }
            /*else if(lastBatter.id === lastPitch.batter.id && lastPitch.result !== 'OUT'){
                alert('Last Pitch did not result in an out');
                return;
            }*/

            batterIndex = (batterIndex + 1) % this.props.awayTeamLineup.length;
            //////console.log('next Batter Index = ' + batterIndex);
            let nextBatter = this.props.awayTeamLineup[batterIndex];
    
            
            this.props.onSetAwayTeamCurrentBatterIndex(batterIndex);
            this.props.onSetBatter(nextBatter);
        }
        else{
            let batterIndex = this.props.homeTeamCurrentBatterIndex;
            let lastBatter = this.props.homeTeamLineup[batterIndex];
            if(lastBatter.id !== lastPitch.batter.id){
                //alert('Last Batter ID does not match lastPitch batter id');
                //This player hasn't had an at bat, so don't increment the lineup
                return;
            }
            /*else if(lastBatter.id === lastPitch.batter.id && lastPitch.result !== 'OUT'){
                alert('Last Pitch did not result in an out');
                return;
            }*/
            batterIndex = (batterIndex + 1) % this.props.homeTeamLineup.length;
            //////console.log('next Batter Index = ' + batterIndex);
            let nextBatter = this.props.homeTeamLineup[batterIndex];

            this.props.onSetHomeTeamCurrentBatterIndex(batterIndex);
            this.props.onSetBatter(nextBatter);

        }
    }

    addOut = (baseRunners, clearBatter) =>{
        if(this.props.training){
            return;
        }
        //////console.log('calling addOut');
        //if there is less than two outs, increment it
        if(this.props.outs < 2){
            let updatedOuts = this.props.outs + 1;
            this.props.onSetOuts(updatedOuts);
            
            //this line below was commented out.  But if a batter is out, a new batter needs to be selected, and this is
            //accomplished by setting the current batter to null
            //if there are runners on base and the batter hits and is out, a new batter is selected.
            //if you then mark a current base runner as out (double play out), you have to re-select the batter.
            //This additional step was why the line below was commented out.
            if(clearBatter === true){

                //using the lineups, we can automatically select the batter
                this.setNextBatter()
                

                
            }
            
        }
        //otherwise, it's a new inning
        else{
            //if the current batter has seen pitches but isn't out, save the pitches and do not increment the batter index
            if(!clearBatter){
                let updatedPitchArray = [...this.state.pitches];
                if(updatedPitchArray.length > 0){
                    //alert('add state pitches to game state pitch array');
                    this.updateGameStatePitchArray(updatedPitchArray); 
                    this.setState({
                        currentPitch: null,
                        pitches: [],
                        currentStep: 0,//newStep,
                        strikeCount: 0,
                        ballCount: 0,
                        batterNotes: null,
                        pitcherNotes: null
                    })
                }
            }
            
            //first, check if there are baserunners  that need to be reconciled
            if(baseRunners.length > 0){
                let baseRunnersArray = [
                    ...this.props.baseRunners
                ];
               
                //////console.log('there are baserunners, so need to reconcile');
                this.setState({
                    reconcilingBaseRunners: true,
                    incrementInningAfterReconcile : true,
                    reconcileIncrementBatter: clearBatter,
                    baseRunnersToReconcile: baseRunnersArray
                })
            }
            else{
                const currentInning = this.props.currentInning;
                this.setState({
                    reconcilingBaseRunners: false
                });
                if(this.state.incrementInningAfterReconcile){
                    this.postInningReconcileComplete();
                }else{
                    this.saveInningScores();

//DG if the current batter has received pitches but is not out and is still at bat
                    if(clearBatter === true){
                        this.setNextBatter(currentInning);
                    }
                }

                

                let updatedInning = this.props.currentInning + 1;
                this.props.onSetCurrentInning(updatedInning);
                this.props.onSetOuts(0);
                this.setBatter(updatedInning);
                this.setPitcher(updatedInning);
                //this.props.onSetBatter(null);
                //this.props.onSetPitcher(null);
                
                



                if(this.isGameOver()){
                    this.props.onSetGameOver(true);
                }
            }
        }
    }

    isWalk = (pitch) =>{
        if(pitch.ballCount === 3 && pitch.result === 'Take' && pitch.pitchResult === 'ball'){
            return true;
        }
        
        return false;
    }
    handleOut = (updatedPitchArray) =>{

        this.updateGameStatePitchArray(updatedPitchArray);
        
        if(!this.props.training){
            this.addOut(this.props.baseRunners, true);
        }
        
        
        

        this.setState({
            currentPitch: null,
            pitches: [],
            currentStep: 0,//newStep,
            strikeCount: 0,
            ballCount: 0,
            batterNotes: null,
            pitcherNotes: null
        })
    }

        

    onPitchResultHandler = (result) =>{
        //console.log('onPitchResultHandler ',  result);
        let updatedPitch = {
            ...this.state.currentPitch
        };
        let updatedPitchArray = [...this.state.pitches];
        updatedPitchArray.pop();
        updatedPitch = PitchClass.LoadResult(updatedPitch, result);
        const resultState = PitchClass.GetResultType(updatedPitch);
        updatedPitch.batActionType = result;
        //TODO: Bunt, SLAP is being lost.  Need to 
        /*
         'Take',
        'Swing',
        'Bunt',
        'Slap',
        'HBP'
        */

        //DRP3-OUT
        //DRP3-PB/WP
        ////////console.log('resultState = ' + resultState);
        let currentStep = this.state.currentStep;
        let newStep = currentStep;
        let strikeCount = this.state.strikeCount;
        let ballCount = this.state.ballCount;
        //1 - strike
        //2 - ball
        //3 - in play or foul
        //4 - hit by pitch
        //5 - fielders choice
        //6 - reached on error
        //DRP3-OUT - GetResultType returns 7
        //DRP3-PB/WP - GetResultType returns 8
        if(resultState === 7){
            updatedPitch.pitchResult = 'strike';
            strikeCount ++;
            if(strikeCount >= 3){
                //alert('strikeout!');
                //window.location.reload();
                updatedPitchArray.push(updatedPitch);
                return this.handleOut(updatedPitchArray);
            }
            newStep = 0;
        }
        if(resultState === 8){
            updatedPitch.pitchResult = 'DRP3-PB/WP';
            updatedPitchArray.push(updatedPitch);
            this.addBaseRunner(updatedPitch);
            return this.handleOnBase(updatedPitchArray);
        }
        if(resultState === 5){
            updatedPitch.pitchResult = 'FC';
            updatedPitchArray.push(updatedPitch);
            this.addBaseRunner(updatedPitch);
            return this.handleOnBase(updatedPitchArray);
            //return this.handleOut(updatedPitchArray);
        }
        else if(resultState === 4){
            updatedPitch.pitchResult = 'HBP';
            updatedPitchArray.push(updatedPitch);
            this.addBaseRunner(updatedPitch);
            return this.handleOnBase(updatedPitchArray);
        }
        else if(resultState === 1){
            updatedPitch.pitchResult = 'strike';
            
            if(!this.props.training){
                strikeCount ++;
            }
            if(strikeCount >= 3){
                //alert('strikeout!');
                //window.location.reload();
                updatedPitchArray.push(updatedPitch);
                return this.handleOut(updatedPitchArray);



            }
            if(this.props.training && this.props.training.trainingType === 'Pitcher'){
                //save the pitch
                updatedPitchArray.push(updatedPitch);
                this.updateGameStatePitchArray(updatedPitchArray);
                updatedPitchArray = [];
            }

            newStep = 0;
        }
        else if(resultState === 2){
            updatedPitch.pitchResult += 'ball';
            if(!this.props.training){
                ballCount ++;
            }
            if(ballCount >= 4){
                updatedPitchArray.push(updatedPitch);
                this.addBaseRunner(updatedPitch);
                return this.handleOnBase(updatedPitchArray);
            }

            if(this.props.training && this.props.training.trainingType === 'Pitcher'){
                //save the pitch
                updatedPitchArray.push(updatedPitch);
                this.updateGameStatePitchArray(updatedPitchArray);
                updatedPitchArray = [];
            }
            newStep = 0;
        }
        else{
            //change state to show in play or foul options
            updatedPitch.pitchResult = 'in play/foul';
            
            //step 3 was onBatterActionResultHandler.  We are removing that.  Go to step 4
            //newStep = 3;
            newStep = 4;
            
        }
        //updatedPitch = updatedPitch.SetCount(ballCount, strikeCount);
        if(!this.props.training || this.props.training.trainingType !== 'Pitcher'){
            updatedPitchArray.push(updatedPitch);
        }
        this.setState({
            currentPitch: updatedPitch,
            pitches: updatedPitchArray,
            currentStep: newStep,
            strikeCount: strikeCount,
            ballCount: ballCount
        })

    }

    //onBatterActionResultHandler handles IN PLAY/FOUL/MISS

    /*onBatterActionResultHandler = (result) => {
        ////////console.log('onBatterActionResultHandler ',  result);
        let updatedPitch = {
            ...this.state.currentPitch
        };
        let updatedPitchArray = [...this.state.pitches];
        updatedPitchArray.pop();
        updatedPitch = PitchClass.LoadBatAction(updatedPitch, result);
        const ret = PitchClass.GetBatActionType(updatedPitch);
        ////////console.log('ret = ' + ret);
        let currentStep = this.state.currentStep;
        let newStep = currentStep;
        let strikeCount = this.state.strikeCount;
        let ballCount = this.state.ballCount;
        if(ret === 1){
            //swing and a miss.  Next pitch
            if(!this.props.training){
                strikeCount ++;
            }
            if(strikeCount >= 3){
                // alert('strikeout!');
                // window.location.reload();
                updatedPitchArray.push(updatedPitch);
                return this.handleOut(updatedPitchArray);
            }
            newStep = 0;
        }
        else if(ret === 2){
            //foul.  Limited field values?
            newStep = 4;
        }
        else if(ret === 3){
            //in play.  Limited field values?
            newStep = 4;
        }
        updatedPitch = PitchClass.SetCount(updatedPitch, ballCount, strikeCount);
        updatedPitchArray.push(updatedPitch);
        this.setState({
            currentPitch: updatedPitch,
            pitches: updatedPitchArray,
            currentStep: newStep,
            strikeCount: strikeCount,
            ballCount: ballCount
        })
    }*/


    onBatterActionLocationHandler = (e, result) =>{
        //console.log('onBatterActionLocationHandler ' + result);
        let area = document.getElementById('fieldarea');
        const rect = area.getBoundingClientRect();
        ////////console.log(rect.top, rect.right, rect.bottom, rect.left);
        ////////console.log('hit location! ' + e.clientX +' ' + e.clientY);
        let x=e.clientX;
        let y=e.clientY;
        x = (e.clientX - rect.left);
        y = (e.clientY - rect.top);
        ////////console.log('final hit location: ' + x +' '+ y);
        let updatedPitch = {
            ...this.state.currentPitch
        };
        let updatedPitchArray = [...this.state.pitches];
        updatedPitchArray.pop();
        updatedPitch = PitchClass.LoadBatActionLocation(updatedPitch, result);
        let locationType = PitchClass.GetBatActionLocationType(updatedPitch);
        
        updatedPitch.hitLocation.x = x;
        updatedPitch.hitLocation.y = y;

        var canvas = document.getElementById('myCanvas');
        var ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.beginPath();
       // top: 262px;
        //left: 161px;
        //top={209}
          //      left={128}
        ctx.moveTo(140, 221);
        ctx.lineTo(x, y);
        ctx.lineWidth = 1;
        ctx.strokeStyle = '#000';
        ctx.stroke();

        let currentStep = this.state.currentStep;
        let newStep = currentStep;
        let strikeCount = this.state.strikeCount;
        let ballCount = this.state.ballCount;

        
        
        
        if(locationType === 1){//home run
            //alert('home run!');
            newStep = 0;
            strikeCount = 0;
            ballCount = 0;
            updatedPitch.batAction  = "In Play";
            updatedPitch = PitchClass.SetCount(updatedPitch, ballCount, strikeCount);
            updatedPitchArray.push(updatedPitch);
            ////////console.log('baseRunners: ', this.props.baseRunners);


            if(!this.props.training){
                let baseRunnersArray = [
                    ...this.props.baseRunners
                ];

                baseRunnersArray.push(updatedPitch);
                this.props.onUpdateBaseRunners(baseRunnersArray);
                this.addBaseRunner(updatedPitch);
            }
            return this.handleHomeRun(updatedPitchArray);

        }
        else if(locationType === 2){//foul
            if(!this.props.training){
                if(strikeCount < 2){
                    strikeCount ++;
                }
            }


            updatedPitch.batAction  = "Foul";

            if(this.props.training){
                newStep = 0; //does something else need to happen here?

                //if training we need to save the pitch and clear the state pitch array
                //save the pitch
                let updatedPitchArray = [...this.state.pitches];
                updatedPitchArray.pop();
                updatedPitchArray.push(updatedPitch);
                this.updateGameStatePitchArray(updatedPitchArray);
                updatedPitchArray = [];
                this.setState({
                    currentPitch: null,
                    pitches: [],
                    currentStep: 0,
                });
                return;
            }
            else{
                newStep = 8; // go to foul result handler
            }
        }
        else if(locationType === 4){//miss
            //swing and a miss.  Next pitch
            updatedPitch.batAction = 'Miss';
            updatedPitch.batActionLocation = "";
            updatedPitch.batActionType = "Swing";
            if(!this.props.training){
                strikeCount ++;
            }
            if(strikeCount >= 3){
                // alert('strikeout!');
                // window.location.reload();
                updatedPitchArray.push(updatedPitch);
                return this.handleOut(updatedPitchArray);
            }
            newStep = 0;
            
        }
        else{
            //$(this).css('border', '2px solid black');
            //$(this).css('border-radius', '50%');
            //$('.hitActionButtons').show();
            updatedPitch.batAction  = "In Play";
            newStep = 5;
        }
        updatedPitch = PitchClass.SetCount(updatedPitch, ballCount, strikeCount);
        updatedPitchArray.push(updatedPitch);
        this.setState({
            currentPitch: updatedPitch,
            pitches: updatedPitchArray,
            currentStep: newStep,
            strikeCount: strikeCount,
            ballCount: ballCount
        })
    }

    onHitActionResultHandler = (result) =>{
        ////////console.log('onHitActionResultHandler ' + result);
        let updatedPitch = {
            ...this.state.currentPitch
        };
        let updatedPitchArray = [...this.state.pitches];
        updatedPitchArray.pop();

        let currentStep = this.state.currentStep;
        let newStep = currentStep;
        
        
        var canvas = document.getElementById('myCanvas');
        var ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        if(result === 'Fly' || result === 'Pop'){
            const quadPoint = PitchClass.GetBatActionLocationQuadPoint(updatedPitch);
            ctx.beginPath();
            ctx.moveTo(140, 221);
            ctx.quadraticCurveTo(quadPoint.x, 
                                 quadPoint.y,  
                                 updatedPitch.hitLocation.x , 
                                 updatedPitch.hitLocation.y );
            ctx.lineWidth = 5;
            ctx.strokeStyle = '#003300';
            ctx.setLineDash([0,0]);
            ctx.stroke();
        }
        else if(result === 'Ground'){
            ctx.lineCap = 'round';
            ctx.lineWidth = 4;
            
            ctx.strokeStyle = 'rgba(50,30,120,0.5)';
            ctx.beginPath();
            ctx.moveTo(140, 221);
            ctx.setLineDash([8,8]);
            ctx.lineTo(updatedPitch.hitLocation.x, updatedPitch.hitLocation.y);
            ctx.stroke();
        }
        else if(result === 'Line'){
            ctx.lineCap = 'round';
            ctx.lineWidth = 4;
            
            ctx.strokeStyle = 'rgba(50,30,120,0.5)';
            ctx.beginPath();
            ctx.moveTo(140, 221);
            ctx.setLineDash([0,0]);
            ctx.lineTo(updatedPitch.hitLocation.x, updatedPitch.hitLocation.y);
            ctx.stroke();
        }
        newStep = 6;
        updatedPitch = PitchClass.LoadHitAction(updatedPitch, result);
        updatedPitchArray.push(updatedPitch);
        this.setState({
            currentPitch: updatedPitch,
            pitches: updatedPitchArray,
            currentStep: newStep
        })
    }

    onHitPowerResultHandler = (result) => {
        ////////console.log('onHitPowerResultHandler ' + result);
        let updatedPitch = {
            ...this.state.currentPitch
        };
        let updatedPitchArray = [...this.state.pitches];
        updatedPitchArray.pop();
        let newStep = this.state.currentStep;
        updatedPitch = PitchClass.LoadHitPower(updatedPitch, result);
        updatedPitchArray.push(updatedPitch);

        //if(!this.props.training){
            newStep = 7;
            this.setState({
                currentPitch: updatedPitch,
                pitches: updatedPitchArray,
                currentStep: newStep
            })
        //}
        /*else{
            this.updateGameStatePitchArray(updatedPitchArray);
            this.setState({
                currentPitch: null,
                pitches: [],
                currentStep: 0,
                ballCount: 0,
                strikeCount: 0,
                batterNotes: null,
                pitcherNotes: null
            });
        }*/
    }

    updateGameStatePitchArray = (updatedPitchArray) =>{
        ///TO DO: Add the updatedPitchArray to the pitch array in redux game state
        ////console.log('updateGameStatePitchArray');
        let updatedGameStatePitchArray = [
            ...this.props.pitchArray
            
        ];
        const pitchArray = updatedGameStatePitchArray.concat(updatedPitchArray);
        this.props.onSetPitchNumber(this.props.pitchArray.length);
        this.props.onUpdatePitchArray(pitchArray);

               //if(!this.state.reconcilingBaseRunners && !this.state.reconcilingBaseRunner){
                    //alert(prevProps.atBatState.wasPopped +' '+this.props.atBatState.wasPopped);
        let newGameState = {...this.props.atBatState}

        let updatedGameStateArray = {...this.props.gameStateArray};
        if(typeof updatedGameStateArray.stateArray !== 'undefined'){
            updatedGameStateArray.stateArray.push(newGameState);
        }
        else{
            updatedGameStateArray.stateArray = [];
            updatedGameStateArray.stateArray.push(newGameState);

        }
        if(updatedGameStateArray.stateArray.length > 12){
            updatedGameStateArray.stateArray.splice(0,1);
        }

        updatedGameStateArray.currentIndex = updatedGameStateArray.stateArray.length-1;
        updatedGameStateArray.atBatID = this.props.atBatState.atBatID;
        this.props.onSaveGameStateArray(this.props.token,updatedGameStateArray );
    
            
            //}
        


    }

    onBatterPracticeSaveHandler = () =>{
        let updatedPitch = {
            ...this.state.currentPitch
        };
        let updatedPitchArray = [...this.state.pitches];
        updatedPitchArray.pop();
        let newStep = this.state.currentStep;
        updatedPitchArray.push(updatedPitch);
        newStep = 0;
        this.updateGameStatePitchArray(updatedPitchArray);
        this.setState({
            currentPitch: null,
            pitches: [],
            currentStep: newStep,
            ballCount: 0,
            strikeCount: 0,
            batterNotes: null,
            pitcherNotes: null
        });

    }

    onHitResultHandler = (result) => {
        ////////console.log('onHitResultHanlder ' + result);
        let updatedPitch = {
            ...this.state.currentPitch
        };
        let updatedPitchArray = [...this.state.pitches];
        updatedPitchArray.pop();
        let newStep = this.state.currentStep;
        updatedPitch = PitchClass.LoadHitResult(updatedPitch, result);
        updatedPitchArray.push(updatedPitch);
        newStep = 0;

        //TO DO: Save the AtBat
        //const currentAtBat = new AtBatClass(this.props.pitcherid, this.props.batterid, updatedPitchArray);
        ////////console.log(currentAtBat);

        if(!this.props.training){

            if(result === "OUT" || result === "SAC"){
                this.handleOut(updatedPitchArray);
            }else{
                this.addBaseRunner(updatedPitch);
                this.handleOnBase(updatedPitchArray);
            }
        }
        else{
            this.updateGameStatePitchArray(updatedPitchArray);
        }


        this.setState({
            currentPitch: null,
            pitches: [],
            currentStep: newStep,
            ballCount: 0,
            strikeCount: 0,
            batterNotes: null,
            pitcherNotes: null
        });

        //this.updateGameStatePitchArray(updatedPitchArray);
    }


    onFoulResultHandler = (result) =>{
        //////console.log('onFoulResultHandler ' + result);

        if(result === "Foul"){

            //if training we need to save the pitch and clear the state pitch array
            if(this.props.training){//} && this.props.training.trainingType === 'Pitcher'){
                //save the pitch
                let updatedPitch = {
                    ...this.state.currentPitch
                };
                let updatedPitchArray = [...this.state.pitches];
                updatedPitchArray.pop();
                updatedPitchArray.push(updatedPitch);
                this.updateGameStatePitchArray(updatedPitchArray);
                updatedPitchArray = [];
                this.setState({
                    currentPitch: null,
                    pitches: [],
                    currentStep: 0,
                });
            }

            this.setState({
                currentStep: 0,
            })
        }
        else{ // Foul Out
            let updatedPitch = {
                ...this.state.currentPitch
            };
            let updatedPitchArray = [...this.state.pitches];
            updatedPitchArray.pop();
            let newStep = this.state.currentStep;
            updatedPitch = PitchClass.LoadHitResult(updatedPitch, "OUT");
            updatedPitchArray.push(updatedPitch);
            newStep = 0;
    
            this.handleOut(updatedPitchArray);

            this.setState({
                currentPitch: null,
                pitches: [],
                currentStep: newStep,
                ballCount: 0,
                strikeCount: 0,
                batterNotes: null,
                pitcherNotes: null
            });
        }
    }


    currentInning(){
        return Math.floor((this.props.currentInning / 2.0) + 1);
    }

    isInningTop(inning){
        let currentInning = (typeof inning !== 'undefined') ? inning : this.props.currentInning;
        // //////console.log('Current Inning: ' + Math.floor((this.props.currentInning / 2.0) + 1));
        // //////console.log('Top or bottom of inning? ' + (this.props.currentInning % 2 === 0 ? 'top' : 'bottom'));
        return currentInning % 2 === 0 ? true : false;
    }

    onSelectPitcherHandler = (id) =>{
        ////////console.log('onSelectPitcherHandler ' + id);
        let pitcher = null;
        if(this.isInningTop()){
            //let obj = objArray.find(obj => obj.id == 3);
            pitcher = this.props.homeTeamPlayers.find(pitcher =>pitcher.id === id);
            this.props.onSetHomeTeamCurrentPitcher(pitcher);
            
        }else{
            pitcher = this.props.awayTeamPlayers.find(pitcher =>pitcher.id === id);
            this.props.onSetAwayTeamCurrentPitcher(pitcher);

            
        }
        ////////console.log(pitcher);
        this.props.onSetPitcher(pitcher);

        this.setState({
            showPitcherSelection: false
        })
    }


    //This should be the same as a substitution?????


    //TO DO: FINISH THIS FUNCTION!!!!!!!!!!
    onSelectBatterHandler = (id) =>{
        //console.log('onSelectBatterHandler ' + id);
        let batter = null;
        let batterIndex = -1;
        let lineupIndex = -1;
        if(this.isInningTop()){ //away Team!!
            ////console.log('top of inning. so batter is away team?');
            //let obj = objArray.find(obj => obj.id == 3);
            batter = this.props.awayTeamPlayers.find(batter =>batter.id === id);

            let updatedBatter = {...batter};

            //batterIndex here is the index in the awayTeamPlayers array, I think it should instead 
            batterIndex = this.props.awayTeamPlayers.findIndex(batter =>batter.id === this.props.currentHitter.id);
            lineupIndex = this.props.awayTeamLineup.findIndex(batter => batter.id === this.props.currentHitter.id);

            let playerToSubOut  = this.props.awayTeamLineup[lineupIndex];
            
            //index in updatedPlayerToSubOut should be the index in the lineup, not the index in awayTeamPlayers array
            let updatedPlayerToSubOut = {...playerToSubOut,
                index: lineupIndex,
                inning: this.currentInning()
             };

             
            let updatedAwaySubstitutions = [...this.props.awaySubstitutions];
            updatedAwaySubstitutions.push(updatedPlayerToSubOut);
            this.props.onUpdateAwaySubs(updatedAwaySubstitutions);
            
            
            let updatedTeamLineup = [...this.props.awayTeamLineup];
            updatedTeamLineup.splice(lineupIndex,1, updatedBatter);
            this.props.onSetAwayTeamLineup(updatedTeamLineup);

        }else{ //home Team!!
            ////console.log('botttom of inning, so batter is home team');
            batter = this.props.homeTeamPlayers.find(batter =>batter.id === id);

            ////console.log('batter to sub out', this.props.currentHitter);
            ////console.log('batter to sub in', batter)
            let updatedBatter = {...batter};


            batterIndex = this.props.homeTeamPlayers.findIndex(batter => batter.id === this.props.currentHitter.id);
            lineupIndex = this.props.homeTeamLineup.findIndex(batter => batter.id === this.props.currentHitter.id);



            ////console.log('batterIndex', batterIndex);

            let playerToSubOut  = this.props.homeTeamLineup[lineupIndex];
            
            let updatedPlayerToSubOut = {...playerToSubOut,
                index: lineupIndex,
                inning: this.currentInning()
             };
            

             ////console.log('player to sub out object', updatedPlayerToSubOut);

             let updatedSubstitutions = [...this.props.homeSubstitutions];
            updatedSubstitutions.push(updatedPlayerToSubOut);
            this.props.onUpdateHomeSubs(updatedSubstitutions);
            
            
            let updatedTeamLineup = [...this.props.homeTeamLineup];
            updatedTeamLineup.splice(lineupIndex,1, updatedBatter);
            this.props.onSetHomeTeamLineup(updatedTeamLineup);

        }
        this.props.onSetBatter(batter);

        this.setState({
            showBatterSelection: false
        })
    }

    


    addScoreToBattingTeam = (score) =>{
         //add to the score of the current batting team
         if(this.isInningTop()){
            //away team is at bat
            let currentRunTotal = parseInt(this.props.awayScore);
            currentRunTotal = parseInt(score) + currentRunTotal;

            let topInningScore = parseInt(this.props.currentTopInningScore);
            topInningScore = topInningScore + parseInt(score);
            this.props.onSetCurrentTopInningScore(topInningScore);
            this.props.onSetAwayScore(currentRunTotal);
        }
        else{
            //home team is at bat
            let currentRunTotal = parseInt(this.props.homeScore);
            currentRunTotal = parseInt(score) + currentRunTotal;
            let bottomInningScore = parseInt(this.props.currentBottomInningScore);
            bottomInningScore = bottomInningScore + parseInt(score);
            this.props.onSetCurrentBottomInningScore(bottomInningScore);
            this.props.onSetHomeScore(currentRunTotal);
        }
    }


    handleLineupSubstitutionCancel = () =>{
        this.setState({
            showPlayerSubstitution: false,
            substituteIndex: 0,
            playerToSub: null
        });
    }

    handleLineupSubstitution = (player, index) =>{
        //////console.log('handleLineupSubstitution', index, player);

        
        this.setState({
            showPlayerSubstitution: true,
            substituteIndex: index,
            playerToSub: player
        });
    }

    onHandlePlayerPosition = (pos)=>{
        //this.state.playerToSub
        //console.log("position " + pos);
        //console.log("player ", this.state.playerToSub);
        let playerToUpdate= {...this.state.playerToSub};
        playerToUpdate.currentPosition = pos;

        if(this.isInningTop()){
            //changing away team player position
            if(playerToUpdate.currentPosition === 'FLEX'){
                //switch out the FLEX player
                let updatedFlex = {...this.props.awayTeamFlex};
                playerToUpdate = {...this.playerToUpdate, atBat: updatedFlex.atBat}
                this.props.onSetAwayTeamFlex(playerToUpdate);
            }
            else{
                let updatedAwayTeamLineup = [...this.props.awayTeamLineup];
                let index = updatedAwayTeamLineup.findIndex(batter =>batter.id === playerToUpdate.id);
                updatedAwayTeamLineup.splice(index,1, playerToUpdate);
                this.props.onSetAwayTeamLineup(updatedAwayTeamLineup);
            }
        }
        else{
            //changing home team player position
            if(playerToUpdate.currentPosition === 'FLEX'){
                let updatedFlex = {...this.props.homeTeamFlex};
                playerToUpdate = {...this.playerToUpdate, atBat: updatedFlex.atBat}
                this.props.onSetHomeTeamFlex(playerToUpdate);
                
            }
            else{
                let updatedHomeTeamLineup = [...this.props.homeTeamLineup];
                let index = updatedHomeTeamLineup.findIndex(batter =>batter.id === playerToUpdate.id);
                updatedHomeTeamLineup.splice(index,1, playerToUpdate);
                this.props.onSetHomeTeamLineup(updatedHomeTeamLineup);
            }

        }


        this.setState({
            showPlayerSubstitution: false,
            substituteIndex: 0,
            playerToSub: null
        });
    }

    onHandlePlayerSubstitution = (id)=>{
        //console.log('onHandlePlayerSubstitution', id);
        //need to save:
        //playerToSub      - the player subbed out
        //substituteIndex  - the player subbed out index in the lineup




        //this.state.substituteIndex
        //this.state.playerToSub
        //this.state.showPlayerSubstitution
        if(this.isInningTop()){
            //subbing an away team player roster
            let batter = this.props.awayTeamPlayers.find(batter =>batter.id === id);
            let updatedAwayTeamLineup = [...this.props.awayTeamLineup];
            let updatedPlayerToSubIn = {...batter}
            updatedAwayTeamLineup.splice(this.state.substituteIndex,1, updatedPlayerToSubIn);

            //////console.log('updated Away Team Lineup', updatedAwayTeamLineup);
            this.props.onSetAwayTeamLineup(updatedAwayTeamLineup);
            if(this.state.playerToSub.id === this.props.currentHitter.id){
                this.props.onSetBatter(updatedPlayerToSubIn);
            }

            let playerToSubOut = {...this.state.playerToSub,
                                    index: this.state.substituteIndex,
                                    inning: this.currentInning()
                                 };
            let updatedAwaySubstitutions = [...this.props.awaySubstitutions];
            updatedAwaySubstitutions.push(playerToSubOut);
            this.props.onUpdateAwaySubs(updatedAwaySubstitutions);


            //busroute
//             homeSubstitutions
// awaySubstitutions
// onUpdateAwaySubs
//         onUpdateHomeSubs
        }
        else{
            let batter = this.props.homeTeamPlayers.find(batter =>batter.id === id);
            let updatedHomeTeamLineup = [...this.props.homeTeamLineup];
            let updatedPlayerToSubIn = {...batter};
            updatedHomeTeamLineup.splice(this.state.substituteIndex, 1, updatedPlayerToSubIn);
            //////console.log('updated Home Team Lineup', updatedHomeTeamLineup)
            this.props.onSetHomeTeamLineup(updatedHomeTeamLineup);
            if(this.state.playerToSub.id === this.props.currentHitter.id){
                this.props.onSetBatter(updatedPlayerToSubIn);
            }

            let playerToSubOut = {...this.state.playerToSub,
                                    index: this.state.substituteIndex
                                 };
            let updatedAwaySubstitutions = [...this.props.homeSubstitutions];
            updatedAwaySubstitutions.push(playerToSubOut);
            this.props.onUpdateHomeSubs(updatedAwaySubstitutions);

        }
        this.setState({
            showPlayerSubstitution: false,
            substituteIndex: 0,
            playerToSub: null
        });
    }

    

   


    baseRunnerScoreHandler = (runner) =>{
        ////console.log('baseRunnerScoreHandler ', runner);

        //TO DO:  A base runner is scoring.  Which pitch causes the score?  The last pitch thrown.
        //This runner may not be the last pitch thrown; it could be someone on base.
        //See if this runner is a base runner in the last pitch thrown.  
        //If so, update that base runner object in the last pitch thrown to set running result to "SCORE"
        //onUpdatePitchArray
        //pitchArray

        let updatedPitchArray = [
            ...this.props.pitchArray
        ];
        let updatedPitch = null;
        if(updatedPitchArray.length > 0){
            updatedPitch = {...updatedPitchArray[updatedPitchArray.length - 1]};
        }

        if(updatedPitch){
            //find this base runner  - this runner id === updatedPitch.baseRunners[i].id
            if(updatedPitch.baseRunners && typeof updatedPitch.baseRunners !== 'undefined'){
                for(let i=0; i< updatedPitch.baseRunners.length; ++i){
                    let br =  {...updatedPitch.baseRunners[i]};
                    if(br.id === runner.id){
                        br.runningResult= "SCORE";
                        //this.setBaseRunningResult(br, "SCORE");
                        updatedPitch.baseRunners.splice(i,1,br);
                    }

                }
            }

            this.setBaseRunningResult(updatedPitch, "SCORE");
            updatedPitchArray.pop();
            updatedPitchArray.push(updatedPitch);
            ////console.log("updatedPitch", updatedPitch);
            this.props.onUpdatePitchArray(updatedPitchArray);
        }


        
        let baseRunnersArray = [
            ...this.props.baseRunners
        ];
        let index = -1;
        for(let i=0;i<baseRunnersArray.length;++i){
            if(runner.id === baseRunnersArray[i].id){
                index = i;
            }
        }
        // if(index < 0){
        //     index = baseRunnersArray.findIndex(x=>x.batter.id === baseRunnersArray[index].id);
        // }
        //let runner =  baseRunnersArray[index];

        if(index < 0){
            //something went wrong
            return;
        }

        if(this.props.firstBaseRunner && runner.id === this.props.firstBaseRunner.id){
            this.props.onSetFirstBaseRunner(null);
        }
        else if(this.props.secondBaseRunner && runner.id === this.props.secondBaseRunner.id){
            this.props.onSetSecondBaseRunner(null);
        }
        else if(this.props.thirdBaseRunner && runner.id === this.props.thirdBaseRunner.id){
            this.props.onSetThirdBaseRunner(null);
        }

        this.setBaseRunningResult(runner, "SCORE");

        ////////console.log('base runners array pre-splice ', baseRunnersArray);
        //remove the base runner from the baseRunner array
        baseRunnersArray.splice(index,1);
        this.props.onUpdateBaseRunners(baseRunnersArray);

        ////////console.log('current base runners array ', baseRunnersArray);

        this.addScoreToBattingTeam(1);
        
        if(baseRunnersArray.length === 0){
            if(this.state.incrementInningAfterReconcile){
                this.postInningReconcileComplete();
            }
        }

        let updatedBaseRunnersToReconcile = [];
        if(this.state.baseRunnersToReconcile){
            updatedBaseRunnersToReconcile = [...this.state.baseRunnersToReconcile];
        }
        index = updatedBaseRunnersToReconcile.findIndex(x=>x.id === runner.id);
        updatedBaseRunnersToReconcile.splice(index,1);
        this.setState({
            baseRunnersToReconcile: updatedBaseRunnersToReconcile
        });
        if(updatedBaseRunnersToReconcile.length === 0){
            this.setState({
                reconcilingBaseRunners: false,
                baseRunnersToReconcile: null,
                reconcileBaseRunner: false
            })
        }
       
        //why did I comment this out?
        // if(baseRunnersArray.length > 0){
        //     this.setState({
        //         reconcilingBaseRunners: true
        //     })
        // }
        // else{
        //     this.setState({
        //         reconcilingBaseRunners: false
        //     })
        //     if(this.state.incrementInningAfterReconcile){
        //         this.postInningReconcileComplete();
        //     }
        // }
    }

    setBaseRunningResult = (runner, value) =>{
        let pitchIndex = 0;
        let pitch = null;

        for(let i=0; i< this.props.pitchArray.length; ++i){
            if(this.props.pitchArray[i].id === runner.id){
                pitchIndex = i;
                pitch = this.props.pitchArray[i];
            }
        }
        if(pitch == null){
            return null;
        }
        let updatedPitch = {...pitch};
        updatedPitch.runningResult= value;
        ////console.log('setting running result to '+value+' for pitch ' + updatedPitch.id);
        let updatedPitchArray = [...this.props.pitchArray];
        updatedPitchArray.splice(pitchIndex, 1, updatedPitch);
        this.props.onUpdatePitchArray(updatedPitchArray);
    }

    

    baseRunnerOutHandler = (runner) =>{
        let baseRunnersArray = [
            ...this.props.baseRunners
        ];

        let index = -1;
        for(let i=0;i<baseRunnersArray.length;++i){
            if(runner.id === baseRunnersArray[i].id){
                index = i;
            }
        }

        runner =  baseRunnersArray[index];

        if(index < 0){
            //something went wrong
            return;
        }


         //if fielders choice, someone is out, and need to figure out how to do base runners
        //if the batter is thrown out, base runners stay the same
        //if the first baseman is thrown out, the batter takes first and second and third stay the same
        //if the second baseman is thrown out, first goes to second, batter goes to first, third stays the same
        //if third baseman is thrown out, batter goes to first, first goes to second, second goes to third
        // if(pitch.result === 'FC')
        // {
        //     this.setState({
        //         reconcileBaseRunner: true,
        //         baseRunnersToReconcile: baseRunnersArray,
        //         handleFC: true,
        //         FCdata: FCdata
        //     });
        //     //this.props.onSetFirstBaseRunner({...pitch});
        //     // let FCdata = {
        //     //     batter: {...pitch},
        //     //     firstbase: {...this.props.firstBaseRunner},
        //     //     secondbase: {...this.props.secondBaseRunner},
        //     //     thirdbase: {...this.props.thirdBaseRunner}
        //     // }
        // }

        if(this.state.handleFC === true){
            //if the first baseman is thrown out, the batter takes first and second and third stay the same
            if(this.state.FCdata.firstbase && this.state.FCdata.firstbase.id === runner.id){
                this.props.onSetFirstBaseRunner({...this.state.FCdata.batter})
            }
            //if the second baseman is thrown out, first goes to second, batter goes to first, third stays the same
            else if(this.state.FCdata.secondbase &&this.state.FCdata.secondbase.id === runner.id){
                this.props.onSetFirstBaseRunner({...this.state.FCdata.batter});
                this.props.onSetSecondBaseRunner({...this.state.FCdata.firstbase});
            }
            //if third baseman is thrown out, batter goes to first, first goes to second, second goes to third
            else if(this.state.FCdata.thirdbase && this.state.FCdata.thirdbase.id === runner.id){
                this.props.onSetFirstBaseRunner({...this.state.FCdata.batter});
                this.props.onSetSecondBaseRunner({...this.state.FCdata.firstbase});
                this.props.onSetThirdBaseRunner({...this.state.FCdata.secondbase});
            }
            this.setState({
                        handleFC: false,
                        FCdata: null
            });

        }
        else{

            if(this.props.firstBaseRunner && runner.id === this.props.firstBaseRunner.id){
                this.props.onSetFirstBaseRunner(null);
            }
            else if(this.props.secondBaseRunner && runner.id === this.props.secondBaseRunner.id){
                this.props.onSetSecondBaseRunner(null);
            }
            else if(this.props.thirdBaseRunner && runner.id === this.props.thirdBaseRunner.id){
                this.props.onSetThirdBaseRunner(null);
            }
        }

        this.setBaseRunningResult(runner, "OUT");

        //remove the base runner from the baseRunner array
        baseRunnersArray.splice(index,1);
        this.props.onUpdateBaseRunners(baseRunnersArray);


        //add out.  Do not clear batter when called from this function
        this.addOut(baseRunnersArray, false);

        if(baseRunnersArray.length === 0){
            if(this.state.incrementInningAfterReconcile){
                this.postInningReconcileComplete();
            }
        }
        
        let updatedBaseRunnersToReconcile = [];
        if(this.state.baseRunnersToReconcile){
            //updatedBaseRunnersToReconcile = [...this.state.baseRunnersToReconcile];
            updatedBaseRunnersToReconcile = [...baseRunnersArray];
        }
        index = updatedBaseRunnersToReconcile.findIndex(x=>x.id === runner.id);
        if(index >= 0){
            updatedBaseRunnersToReconcile.splice(index,1);
        }
        this.setState({
            baseRunnersToReconcile: updatedBaseRunnersToReconcile
        });
        if(updatedBaseRunnersToReconcile.length === 0){
            this.setState({
                reconcilingBaseRunners: false,
                baseRunnersToReconcile: null,
                reconcileBaseRunner: false
            })
        }
    }

    baseRunnerSafeHandler = (runner) =>{
        let baseRunnersArray = [
            ...this.props.baseRunners
        ];

        let index = -1;
        for(let i=0;i<baseRunnersArray.length;++i){
            if(runner.id === baseRunnersArray[i].id){
                index = i;
            }
        }

        runner =  baseRunnersArray[index];

        if(index < 0){
            //something went wrong
            return;
        }

        

        if(this.state.handleFC === true){
            //baseRunnersArray.push({...this.state.FCdata.batter});
            //this.props.onUpdateBaseRunners(baseRunnersArray);
            if(this.state.FCdata.thirdbase && this.state.FCdata.secondbase && this.state.FCdata.firstbase){
                //bases are loaded
                //first must go to second
                //second must go to third
                //third must go home or out
                this.setState({
                    reconcileBaseRunner: true,
                    baseRunnersToReconcile: [{...this.state.FCdata.thirdbase}]
                })
                let newThirdBase = {...this.state.FCdata.secondbase}
                let newSecondBase = {...this.state.FCdata.firstbase}
                this.props.onSetThirdBaseRunner(newThirdBase);
                this.props.onSetSecondBaseRunner(newSecondBase);
                this.props.onSetFirstBaseRunner({...this.state.FCdata.batter});
            }
            else if(this.state.FCdata.thirdbase && this.state.FCdata.secondbase){
                //runners on second and third
                //second stays
                //third stays
                this.props.onSetFirstBaseRunner({...this.state.FCdata.batter});
            }
            else if(this.state.FCdata.firstbase && this.state.FCdata.secondbase){
                //runners on first and second
                //first goes home
                //second goes home
                let newThirdBase = {...this.state.FCdata.secondbase};
                let newSecondBase =  {...this.state.FCdata.firstbase};
                this.props.onSetThirdBaseRunner(newThirdBase);
                this.props.onSetSecondBaseRunner(newSecondBase);
                this.props.onSetFirstBaseRunner({...this.state.FCdata.batter});
            }
            else if(this.state.FCdata.firstbase && this.state.FCdata.thirdbase){
                //runners on first and third
                //third stays
                //first goes to second
                let newSecondBase = {...this.state.FCdata.firstbase};
                this.props.onSetSecondBaseRunner(newSecondBase);
                this.props.onSetFirstBaseRunner({...this.state.FCdata.batter});
            }
            else if(this.state.FCdata.firstbase){
                //runner on first
                let newSecondBase = {...this.state.FCdata.firstbase};
                this.props.onSetSecondBaseRunner(newSecondBase);
                this.props.onSetFirstBaseRunner({...this.state.FCdata.batter});
            }
            else if(this.state.FCdata.secondbase){
                //runner on second
                //runnner at second stays
                this.props.onSetFirstBaseRunner({...this.state.FCdata.batter});
            }
            else if(this.props.thirdbase){
                //runner on third stays
                this.props.onSetFirstBaseRunner({...this.state.FCdata.batter});
            }
            else{
                this.props.onSetFirstBaseRunner({...this.state.FCdata.batter});
            }

            this.setState({
                handleFC: false,
                FCdata: null
            });
        }
    }

    baseRunnerLeftOnBaseHandler = (runner) =>{
        let baseRunnersArray = [
            ...this.props.baseRunners
        ];

        let index = -1;
        for(let i=0;i<baseRunnersArray.length;++i){
            if(runner.id === baseRunnersArray[i].id){
                index = i;
            }
        }

        runner =  baseRunnersArray[index];

        if(index < 0){
            //something went wrong
            return;
        }

        this.setBaseRunningResult(runner, "LOB");
/*
if(this.state.FCdata.firstbase.id === runner.id){
                this.props.onSetFirstBaseRunner({...this.state.FCdata.batter})
            }
            //if the second baseman is thrown out, first goes to second, batter goes to first, third stays the same
            else if(this.state.FCdata.secondbase.id === runner.id){
                this.props.onSetFirstBaseRunner({...this.state.FCdata.batter});
                this.props.onSetSecondBaseRunner({...this.state.FCdata.firstbase});
            }
            //if third baseman is thrown out, batter goes to first, first goes to second, second goes to third
            else if(this.state.FCdata.thirdbase.id === runner.id){
                this.props.onSetFirstBaseRunner({...this.state.FCdata.batter});
                this.props.onSetSecondBaseRunner({...this.state.FCdata.firstbase});
                this.props.onSetThirdBaseRunner({...this.state.FCdata.secondbase});
            }
            this.setState({
                        handleFC: false,
                        FCdata: null
            });*/
        



        //remove the base runner from the baseRunner array
        baseRunnersArray.splice(index,1);
        this.props.onUpdateBaseRunners(baseRunnersArray);

        if(baseRunnersArray.length === 0){
            if(this.state.incrementInningAfterReconcile){
                this.postInningReconcileComplete();
            }
        }

        let updatedBaseRunnersToReconcile = [];
        if(this.state.baseRunnersToReconcile){
            updatedBaseRunnersToReconcile = [...this.state.baseRunnersToReconcile];
        }
        index = updatedBaseRunnersToReconcile.findIndex(x=>x.id === runner.id);
        updatedBaseRunnersToReconcile.splice(index,1);
        this.setState({
            baseRunnersToReconcile: updatedBaseRunnersToReconcile
        });
        if(updatedBaseRunnersToReconcile.length === 0){
            this.setState({
                reconcilingBaseRunners: false,
                baseRunnersToReconcile: null,
                reconcileBaseRunner: false
            })
        }
    }

    handlePitcherSelectionCancel = () =>{
        this.setState({
            showPitcherSelection: false
        })
    }

    onChangePitcher = () =>{
        this.setState({
            showPitcherSelection: true
        })
    }

    handleBatterSelectionCancel = () =>{
        this.setState({
            showBatterSelection: false
        })
    }

    

    onChangeBatter = () =>{
        this.setState({
            showBatterSelection: true
        })
    }

    

    onShowPitchHistoryHandler = () =>{
        if(this.isGameOver()){
            this.props.onSetGameOver(true);
        }
        this.setState({
            showPitchHistory: !this.state.showPitchHistory
        });
        
    }

    onShowGameStatsHandler = () =>{
        if(this.props.training === null){
            this.props.history.push({
                pathname: '/game_analysis/' + this.props.game.id
            });
        }
    }
    /* <Route path="/player_scouting_report/:id" component={PlayerScoutingReport} />
        <Route path="/player_training_scouting_report/:id" component={PlayerScoutingReport} />
       */

    onShowScoutReport = () =>{
        if(this.props.training === null){
            this.props.history.push({
                pathname: '/scouting_report_summary/' + this.props.game.id
            });
        }
        else{
            this.props.history.push({
                pathname: '/training_scouting_report_summary/' + this.props.training.id
            });
        }
    }

    onShowPlayerStatsHandler = () =>{
        if(this.props.training === null){
            this.props.history.push({
                pathname: '/player_analysis/' + this.props.game.id
            });
        }
        else{
            if(this.props.training.trainingType === 'Pitcher'){
                this.props.history.push({
                    pathname: '/pitcher_training_analysis/' + this.props.training.id
                });
            }
            else{
                this.props.history.push({
                    pathname: '/batter_training_analysis/' + this.props.training.id
                });
            }
        }
    }

    isGameOver = () =>{
        if(this.props.forceQuit){
            return true;
        }



        const inning = this.currentInning();
        const isTop = this.isInningTop();
        const homeScore = this.props.homeScore;
        const awayScore = this.props.awayScore;
        //if inning is this.props.totalInnings or greater and is in bottom and home team is winning, game is over
        if(inning >= this.props.totalInnings && !isTop && homeScore > awayScore){
            //this.props.onSetGameOver(true);
            return true;
        }
        //else if inning is greater than this.props.totalInnings and it is the end of the inning, if away team is winning game is over
        else if(inning > this.props.totalInnings && awayScore > homeScore){
            //this.props.onSetGameOver(true);
            return true;
        }
        return false;
    }


    showLineup = () =>{
        if( this.props.training == null && (this.props.homeTeamLineup === null || 
            this.props.homeTeamLineup.length === 0 ||
            this.props.awayTeamLineup === null ||
            this.props.awayTeamLineup.length === 0 ||
            this.props.homeTeamCurrentPitcher === null ||
            this.props.awayTeamCurrentPitcher === null ||
            this.props.totalInnings === 0)){
                return true;
        }
        else if(this.props.training && this.props.training.trainingType === 'Pitcher' && this.props.currentPitcher === null){
            return true;
        }
        else if(this.props.training && this.props.training.trainingType === 'Batter' && this.props.currentHitter === null){
            return true;
        }
        else if(this.props.training && this.props.training.trainingType === 'BatterPractice' && this.props.currentHitter === null){
            return true;
        }
        return false;
    }

    
    handleForceQuitCancel = () =>{
        this.setState({
            showForceQuit: false
        })
    }

    forceQuitMessageChange = (event) =>{
        //////console.log(event.target.value);
        this.setState({
            forceQuitMessage: event.target.value
        })

    }
    handleShowForceQuit = () =>{
        this.setState({
            showForceQuit: true
        })
    }

    handleShowDelete = () =>{
        this.setState({
            showDelete: true
        })
    }

    handleDeleteCancel = () =>{
        this.setState({
            showDelete: false
        })
    }

   

    handleSaveForceQuit = () =>{
        this.props.onSetForceQuit(true, this.state.forceQuitMessage);
        this.setState({
            showForceQuit: true
        })
        this.setState({
            showForceQuit: false
        })
    }

    togglePitcherView = () =>{
        let updatedPitcherView = !this.props.pitcherView;
        this.props.onUpdatePitcherView(updatedPitcherView);

        let updatedPitches = [...this.state.pitches];

        for(let i = 0 ; i < updatedPitches.length; ++i){

            let updatedPitch = updatedPitches[i];
            updatedPitch.x = 1.0 - updatedPitch.x;


        }

        this.setState({
            pitches: updatedPitches
        })


    }


    submitGameNotesHandler = (note) =>{
        this.props.onSetGameNotes(note);
        this.setState({
            showGameNotes: false
        });

    }

    gameNotesMessageChange = (event) =>{
        this.props.onSetGameNotes(event.target.value);
    }

    homeTeamNotesMessageChange = (event) =>{

        this.setState({
            homeTeamNotes : event.target.value
        })
    }

    submitHomeTeamNotesHandler = () =>{
        let homeTeamNote = this.state.homeTeamNotes;
        let homeTeamID = this.props.homeTeam.id;
        //console.log('save note for team id = ' + homeTeamID);
        //console.log(homeTeamNote);
        let updatedHomeTeam = {...this.props.homeTeam, notes: homeTeamNote}
        //console.log('updated Home Team', updatedHomeTeam);

        this.props.onSetHomeTeam(updatedHomeTeam);
        this.props.onUpdateTeam(this.props.token, updatedHomeTeam);

        this.setState({
            showHomeTeamNotes: false
        });
    }

    showHomeTeamNotesHandler = () =>{
            
        if(this.canEdit() || this.props.isTraining){
            this.setState({
                showHomeTeamNotes: true
            })
        }
    }

    showHomeTeamNotesCancelHandler = () =>{
        this.setState({
            showHomeTeamNotes: false
        });
    }
    ////


    awayTeamNotesMessageChange = (event) =>{

        this.setState({
            awayTeamNotes : event.target.value
        })
    }

    submitAwayTeamNotesHandler = () =>{
        let awayTeamNote = this.state.awayTeamNotes;
        let awayTeamID = this.props.awayTeam.id;
        //console.log('save note for team id = ' + awayTeamID);
        //console.log(awayTeamNote);
        let updatedAwayTeam = {...this.props.awayTeam, notes: awayTeamNote}
        //console.log('updated Away Team', updatedAwayTeam);

        this.props.onSetAwayTeam(updatedAwayTeam);
        this.props.onUpdateTeam(this.props.token, updatedAwayTeam);

        this.setState({
            showAwayTeamNotes: false
        });
    }

    showAwayTeamNotesHandler = () =>{
            
        if(this.canEdit() || this.props.isTraining){
            this.setState({
                showAwayTeamNotes: true
            })
        }
    }

    showAwayTeamNotesCancelHandler = () =>{
        this.setState({
            showAwayTeamNotes: false
        });
    }
    
    showGameNotesHandler = () =>{
        
        if(this.canEdit() || this.props.isTraining){
            this.setState({
                showGameNotes: true
            })
        }
    }

    showGameNotesCancelHandler = () =>{
        this.setState({
            showGameNotes: false
        });
    }

    //


    submitPitcherNotesHandler = (note) =>{
        
        if(this.props.training){

            const rootPlayer = this.props.rootHomeTeamPlayers.find(player => player.id === this.props.currentPitcher.id);
            let updatedPlayer = {...rootPlayer, notes: this.state.pitcherNotes};
            this.props.onUpdatePlayer(this.props.token, updatedPlayer);

            /*let currentPitcher = this.props.currentPitcher;
            //console.log('currentPitcher', currentPitcher);
        
            //console.log('isHomeTeam is false, so pitcher is on the home team');
            let index = this.props.homeTeamPlayers.findIndex(player=>player.id === currentPitcher.id);
            //console.log('found index ' + index);
            if(index >=0){
                //console.log('player', this.props.homeTeamPlayers[index]);
                let updatedPlayer = {...this.props.homeTeamPlayers[index], notes : this.state.pitcherNotes}
                //console.log('updatedPlayer', updatedPlayer);
                let players = [...this.props.homeTeamPlayers];
                players.splice(index,1,updatedPlayer);
                this.props.onSetHomeTeamPlayers(players);
                this.props.onSetPitcher(updatedPlayer);
            }*/
        }
        else{
            if(this.isInningTop()){
                //home team is pitching
                const rootPlayer = this.props.rootHomeTeamPlayers.find(player => player.id === this.props.currentPitcher.id);
                let updatedPlayer = {...rootPlayer, notes: this.state.pitcherNotes};
                this.props.onUpdatePlayer(this.props.token, updatedPlayer);

            }
            else{
                //away team is pitching
               
                const rootPlayer = this.props.rootAwayTeamPlayers.find(player => player.id === this.props.currentPitcher.id);
                let updatedPlayer = {...rootPlayer, notes: this.state.pitcherNotes};
                //console.log('rootPlayer update', updatedPlayer);
                this.props.onUpdatePlayer(this.props.token, updatedPlayer);

            }
            /*let currentPitcher = this.props.currentPitcher;
            if(this.isInningTop()){
                //home team is pitching
                let index = this.props.homeTeamPlayers.findIndex(player=>player.id === currentPitcher.id);
                //console.log('found index ' + index);
                if(index >=0){
                    //console.log('player', this.props.homeTeamPlayers[index]);
                    let updatedPlayer = {...this.props.homeTeamPlayers[index], notes : this.state.pitcherNotes}
                    //console.log('updatedPlayer', updatedPlayer);
                    let players = [...this.props.homeTeamPlayers];
                    players.splice(index,1,updatedPlayer);
                    this.props.onSetHomeTeamPlayers(players);
                    this.props.onSetPitcher(updatedPlayer);
                }
            }
            else{
                //away team is pitching
                let index = this.props.awayTeamPlayers.findIndex(player=>player.id === currentPitcher.id);
                //console.log('found index ' + index);
                if(index >=0){
                    //console.log('player', this.props.awayTeamPlayers[index]);
                    let updatedPlayer = {...this.props.awayTeamPlayers[index], notes : this.state.pitcherNotes}
                    //console.log('updatedPlayer', updatedPlayer);
                    let players = [...this.props.awayTeamPlayers];
                    players.splice(index,1,updatedPlayer);
                    this.props.onSetAwayTeamPlayers(players);
                    this.props.onSetPitcher(updatedPlayer);
                }
            }*/
        }
        

        /*if(this.state.currentPitch){

            let updatedPitch = {
                ...this.state.currentPitch
            };
            let updatedPitchArray = [...this.state.pitches];
            updatedPitchArray.pop();
            updatedPitch.pitcherNotes = this.state.pitcherNotes;
            updatedPitchArray.push(updatedPitch);
            this.setState({
                pitches: updatedPitchArray,  
                currentPitch: updatedPitch
            });
        }*/

        this.setState({
            showPitcherNotes: false
        });

    }

    pitcherNotesMessageChange = (event) =>{
        //this.props.onSetGameNotes(event.target.value);
        this.setState({
            pitcherNotes : event.target.value
        });
    }
    
    showPitcherNotesHandler = () =>{
        //////console.log('showPitcherNotesHandler')
        this.setState({
            showPitcherNotes: true
        })
    }

    showPitcherNotesCancelHandler = () =>{
        this.setState({
            showPitcherNotes: false
        });
    }




    //////////




    submitBatterNotesHandler = (note) =>{

        if(this.props.training){
            const rootPlayer = this.props.rootHomeTeamPlayers.find(player => player.id === this.props.currentHitter.id);
            let updatedPlayer = {...rootPlayer, notes: this.state.batterNotes};
            this.props.onUpdatePlayer(this.props.token, updatedPlayer);
            /*//console.log('submit batter notes for training ' + this.state.batterNotes);
            let currentHitter = this.props.currentHitter;
            //console.log('currentHitter', currentHitter);
            
            //console.log('isHomeTeam is false, so hitter is on the home team');
            let index = this.props.homeTeamPlayers.findIndex(player=>player.id === currentHitter.id);
            //console.log('found index ' + index);
            if(index >=0){
                //console.log('player', this.props.homeTeamPlayers[index]);
                let updatedPlayer = {...this.props.homeTeamPlayers[index], notes : this.state.batterNotes}
                //console.log('updatedPlayer', updatedPlayer);
                let players = [...this.props.homeTeamPlayers];
                players.splice(index,1,updatedPlayer);
                this.props.onSetHomeTeamPlayers(players);
                this.props.onSetBatter(updatedPlayer);
            }*/
            

        }
        else{
            if(this.isInningTop()){
                //away team is batting
                const rootPlayer = this.props.rootAwayTeamPlayers.find(player => player.id === this.props.currentHitter.id);
                let updatedPlayer = {...rootPlayer, notes: this.state.batterNotes};
                this.props.onUpdatePlayer(this.props.token, updatedPlayer);
            }
            else{
                //home team is batting
                const rootPlayer = this.props.rootHomeTeamPlayers.find(player => player.id === this.props.currentHitter.id);
                let updatedPlayer = {...rootPlayer, notes: this.state.batterNotes};
                this.props.onUpdatePlayer(this.props.token, updatedPlayer);


            }

            /*let currentHitter = this.props.currentHitter;
            
            if(this.isInningTop()){
                //away team is batting
                let index = this.props.awayTeamPlayers.findIndex(player=>player.id === currentHitter.id);
                //console.log('found index ' + index);
                if(index >=0){
                    //console.log('player', this.props.awayTeamPlayers[index]);
                    let updatedPlayer = {...this.props.awayTeamPlayers[index], notes : this.state.batterNotes}
                    //console.log('updatedPlayer', updatedPlayer);
                    let players = [...this.props.awayTeamPlayers];
                    players.splice(index,1,updatedPlayer);
                    this.props.onSetAwayTeamPlayers(players);
                    this.props.onSetBatter(updatedPlayer);
                }
            }
            else{
                //home team is batting
                let index = this.props.homeTeamPlayers.findIndex(player=>player.id === currentHitter.id);
                //console.log('found index ' + index);
                if(index >=0){
                    //console.log('player', this.props.homeTeamPlayers[index]);
                    let updatedPlayer = {...this.props.homeTeamPlayers[index], notes : this.state.batterNotes}
                    //console.log('updatedPlayer', updatedPlayer);
                    let players = [...this.props.homeTeamPlayers];
                    players.splice(index,1,updatedPlayer);
                    this.props.onSetHomeTeamPlayers(players);
                    this.props.onSetBatter(updatedPlayer);
                }
            }*/

        }

        
        this.setState({
            showBatterNotes: false
        });

        

    }

    batterNotesMessageChange = (event) =>{
        //this.props.onSetGameNotes(event.target.value);
        this.setState({
            batterNotes : event.target.value
        });
    }
    
    showBatterNotesHandler = () =>{
        this.setState({
            showBatterNotes: true
        })
    }

    showBatterNotesCancelHandler = () =>{
        this.setState({
            showBatterNotes: false
        });
    }

    isNumeric = (value) => {
        return /^\d+$/.test(value);
    }

    handleVelocityChange = event =>{
        ////console.log('handleVelocityChange', event.target.value);
        let newVelocity = event.target.value;
        if(!this.isNumeric(newVelocity)){
            newVelocity = '';
        }
        let updatedPitch = {...this.state.currentPitch};
        updatedPitch.velocity = newVelocity;
        this.setState({ 
            currentPitch : updatedPitch
        });

    }

    getBattingTeamID = () =>{
        if(this.props.homeTeam && this.props.awayTeam){
            if(this.state.isHomeTeam){
                return this.props.homeTeam.id;
            }
            else{
                return this.props.awayTeam.id;
            }
        }
    }

    getPitches = () =>{
        let ret = [];
        let _pitchArray = [...this.props.pitchArray];
        let currentPlateAppearance = 1;
        if(this.props.currentHitter !== null){

            
            for(let i=0;i<_pitchArray.length; ++i){
                const pitch = _pitchArray[i];
                if(typeof pitch.batter === 'undefined')
                    continue;
                //////console.log('batter ' + i + ' id = ' + pitch.batter.id);
                if(pitch.batter.id === this.props.currentHitter.id){
                    if(this.state.plateAppearance > 0){
                        if(pitch.ballCount === 0 && pitch.strikeCount ===0){
                            //start plate appearance
                            if(this.state.plateAppearance == currentPlateAppearance){
                                
                                for(let j=i; j < _pitchArray.length; ++j){
                                    const p = _pitchArray[j];
                                    if(p.batter.id === this.props.currentHitter.id){


                                    if(this.state.filterBatterPitches === "ALL"){
                                        if(this.filterPitches(pitch)){
                                            ret.push(p);
                                        }
                                    }
                                    else if(this.state.filterBatterPitches === "SM"){
                                        if(p.batAction === 'Miss'){
                                            if(this.filterPitches(pitch)){
                                                ret.push(p);
                                            }
                                        }
                                    }
                                    else if(this.state.filterBatterPitches === "In Play"){
                                        if(p.batAction === 'In Play'){
                                            if(this.filterPitches(pitch)){
                                                ret.push(p);
                                            }
                                        }
                                    }
                                    else if(this.state.filterBatterPitches === "Take"){
                                        if(pitch.result === "Take"){
                                            if(this.filterPitches(pitch)){
                                                ret.push(p);
                                            }
                                        }
                                    }
                                    else if(this.state.filterBatterPitches === "Slap"){
                                        if(pitch.batActionType === "Slap"){
                                            if(this.filterPitches(pitch)){
                                                ret.push(p);
                                            }
                                        }
                                    }
                                    else if(this.state.filterBatterPitches === "Foul"){
                                        if(pitch.batAction === "Foul"){
                                            if(this.filterPitches(pitch)){
                                                ret.push(p);
                                            }
                                        }
                                    }
                                    else if(this.state.filterBatterPitches === "Bunt"){
                                        if(pitch.batActionType === "Bunt"){
                                            if(this.filterPitches(pitch)){
                                                ret.push(p);
                                            }
                                        }
                                    }
                                    else if(pitch.batAction === this.state.filterBatterPitches){
                                        if(this.filterPitches(pitch)){
                                            ret.push(p);
                                        }
                                    }



                                    }
                                    else{
                                        i=_pitchArray.length;
                                        j = _pitchArray.length;
                                    }

                                }
                            }
                            currentPlateAppearance ++;
                        }
                    }
                    else{
                        if(this.state.filterBatterPitches === "ALL"){
                            if(this.filterPitches(pitch)){
                                ret.push(pitch);
                            }
                        }
                        else if(this.state.filterBatterPitches === "In Play"){
                            if(pitch.batAction === 'In Play'){
                                if(this.filterPitches(pitch)){
                                    ret.push(pitch);
                                }
                            }
                        }
                        else if(this.state.filterBatterPitches === "SM"){
                            if(pitch.batAction === 'Miss'){
                                if(this.filterPitches(pitch)){
                                    ret.push(pitch);
                                }
                            }
                        }
                        else if(this.state.filterBatterPitches === "Take"){
                            if(pitch.result === "Take"){
                                if(this.filterPitches(pitch)){
                                    ret.push(pitch);
                                }
                            }
                        }
                        else if(this.state.filterBatterPitches === "Slap"){
                            if(pitch.batActionType === "Slap"){
                                if(this.filterPitches(pitch)){
                                    ret.push(pitch);
                                }
                            }

                        }
                        else if(this.state.filterBatterPitches === "Foul"){
                            if(pitch.batAction === "Foul"){
                                if(this.filterPitches(pitch)){
                                    ret.push(pitch);
                                }
                            }
                        }
                        else if(this.state.filterBatterPitches === "Bunt"){
                            if(pitch.batActionType === "Bunt"){
                                if(this.filterPitches(pitch)){
                                    ret.push(pitch);
                                }
                            }
                        }
                        else if(pitch.batAction === this.state.filterBatterPitches){
                            if(this.filterPitches(pitch)){
                                ret.push(pitch);
                            }
                        }
                    }
                }
            }
        }
        else if(this.props.pitchArray){
            for(let i=0;i<_pitchArray.length; ++i){
                
                const pitch = _pitchArray[i];
                if(this.getBattingTeamID() == pitch.batter.teamId){
                    ret.push(pitch);
                }
                
            }
        }
        return ret;
    }
    showClearPitcherFiltersButton = () =>{
        if( this.state.filterPitcherPitchType !== 'ALL' || 
            this.state.filterPitcherPitchLocation !== 'ALL' ||
            this.state.filterPitcherPitches !== 'ALL'
        ){
            return true;
        }
        return false;
    }

    showClearFiltersButton = () =>{
        if( this.state.filterBatterPitchesByHitAction !== 'ALL' || 
            this.state.filterBatterPitchesByHitPower !== 'ALL' || 
            this.state.filterBatterPitchesByHitResult !== 'ALL' ||
            this.state.filterPitcherPitchLocation !== 'ALL' ||
            this.state.filterBatterPitches !== 'ALL'
        ){
            return true;
        }
        return false;
    }

    showClearFilterPitchButton = () =>{
        if( this.state.filterBatterPitches !== 'ALL' 
        ){
            return true;
        }
        return false;
    }
    

    showClearFilterHitActionButton = () =>{
        if( this.state.filterBatterPitchesByHitAction !== 'ALL' 
        ){
            return true;
        }
        return false;
    }

    showClearFilterHitPowerButton = () =>{
        if( 
            this.state.filterBatterPitchesByHitPower !== 'ALL'
        ){
            return true;
        }
        return false;
    }

    showClearFilterHitResultButton = () =>{
        if( 
            this.state.filterBatterPitchesByHitResult !== 'ALL' 
        ){
            return true;
        }
        return false;
    }

    showClearFilterPitchLocationButton = () =>{
        if( 
            this.state.filterPitcherPitchLocation !== 'ALL'
        ){
            return true;
        }
        return false;
    }

    filterPitches = (pitch) =>{
        if( this.filterByHitAction(pitch) &&
            this.filterByHitPower(pitch) &&
            this.filterByHitResult(pitch) &&
            this.filterByZone(pitch)
        ){
            return true;
        }
        return false;
    }

    filterByHitPower = (pitch) =>{
        //////console.log('filterByHitPower', pitch);
        if(this.state.filterBatterPitchesByHitPower === 'ALL'){
            return true;
        }
        if(this.state.filterBatterPitchesByHitPower === pitch.hitPower){
            return true;
        }
        return false;

    }
   
    filterByHitAction = (pitch) =>{
        //////console.log('filterByHitAction', pitch);
        if(this.state.filterBatterPitchesByHitAction === 'ALL'){
            return true;
        }
        if(this.state.filterBatterPitchesByHitAction === pitch.hitAction){
            return true;
        }
        return false;
    }

    filterByHitResult = (pitch) =>{
        //////console.log('filterByHitResult', pitch);
        if(this.state.filterBatterPitchesByHitResult === 'ALL'){
            return true;
        }
        if(this.state.filterBatterPitchesByHitResult === pitch.hitResult){
            return true;
        }
        return false;
    }

    filterByZone = (pitch) =>{
        if(this.state.filterPitcherPitchLocation === 'ALL'){
            return true;
        }
        if(this.state.filterPitcherPitchLocation === pitch.pitchLocation){
            return true;
        }
        return false;
    }

    onFilterByPitchType = (val) =>{
        //console.log('filterByPitchType', val);
        if(this.state.filterPitcherPitchType === val){
            this.setState({
                filterPitcherPitchType: "ALL"
            })
        }
        else{
            this.setState({
                filterPitcherPitchType: val
               })
        }
       
    }

    showClearPitchLocationButton = () =>{
        if(this.state.filterPitcherPitchLocation !== 'ALL'){
            return true;
        }
        return false;
    }

    onFilterZone = (val) =>{
        //////console.log('onFilterZone ', val);
 
        if(this.state.filterPitcherPitchLocation === val){
            this.setState({
             filterPitcherPitchLocation: "ALL"
            })
        }
        else{
         this.setState({
             filterPitcherPitchLocation: val
            })
        }
    }

    renderPitches = (pitches) =>{
        let ret = [];
        for(let i=0;i<pitches.length; ++i){
            const p = pitches[i];
            const pitchX = p.x * 222;
            const pitchY = p.y * 222;
            ret.push(<Pitch key={i} pitch = {p} index = {i+1} x = {pitchX} y = {pitchY} strike={PitchClass.isStrike(p)} renderPitch={true}/>) 
        }
        return ret;
    }

    renderPitcherPitches = (pitches) =>{
        let ret = [];
        for(let i=0;i<pitches.length; ++i){
            const p = pitches[i];
            if(this.state.showPitcherBatters ==  0 ||
                (this.state.showPitcherBatters == 1 && p.battingRight) || //0 - all, 1- right, 2- left)
                (this.state.showPitcherBatters == 2 && !p.battingRight)){
                const pitchX = p.x * 222;
                const pitchY = p.y * 222;
                ret.push(<Pitch key={i} pitcher={true} pitch = {p} index = {i+1} x = {pitchX} y = {pitchY} strike={PitchClass.isStrike(p)} renderPitch={true}/>)
            }
        }
        return ret;
    }

    onFilterByHitPower = (name) =>{
        //filterPitcherPitchType
            //////console.log('onFilterByHitPower', name);
            if(this.state.filterBatterPitchesByHitPower === name){
                this.setState({
                    filterBatterPitchesByHitPower: "ALL"
                })
            }
            else{
                this.setState({
                    filterBatterPitchesByHitPower: name
                })
            }
        } 
        
    
        onFilterByHitResult = (name) =>{
        //filterPitcherPitchType
            //////console.log('onFilterByHitResult', name);
            if(this.state.filterBatterPitchesByHitResult === name){
                this.setState({
                    filterBatterPitchesByHitResult: "ALL"
                })
            }
            else{
                this.setState({
                    filterBatterPitchesByHitResult: name
                })
            }
        } 
    
        onFilterByHitAction = (name) =>{
        //filterPitcherPitchType
            //////console.log('onFilterByHitAction', name);
            if(this.state.filterBatterPitchesByHitAction === name){
                this.setState({
                    filterBatterPitchesByHitAction: "ALL"
                })
            }
            else{
                this.setState({
                    filterBatterPitchesByHitAction: name
                })
            }
        } 
    
    
       onFilterBatterPitches = (name) =>{
           //////console.log('onFilterBatterPitches', name);
           if(this.state.filterBatterPitches === name){
            this.setState({
                filterBatterPitches: "ALL"
            });
            }
            else{
                this.setState({
                    filterBatterPitches: name
                });
            }
       }
    
       onFilterZone = (val) =>{
           //////console.log('onFilterZone ', val);
    
           if(this.state.filterPitcherPitchLocation === val){
               this.setState({
                filterPitcherPitchLocation: "ALL"
               })
           }
           else{
            this.setState({
                filterPitcherPitchLocation: val
               })
           }
       }

       onClearPitcherFiltersHandler = (val) =>{
        if(val === "ALL"){
            this.setState({
                filterPitcherPitches: "ALL",
                filterPitcherPitchType: "ALL",
                filterPitcherPitchLocation: "ALL"
    
            });
        }
        if(val === 'Type'){
            this.setState({
                filterPitcherPitchType: "ALL"
            });
        }
        if(val === 'Location'){
            this.setState({
                filterPitcherPitchLocation: "ALL"
    
            });
        }
       }

        /*
        ALL
        PL
        HR
        HP
        HA
        */
    onClearFiltersHandler = (val) =>{
        if(val === "ALL"){
            this.setState({
                filterBatterPitchesByHitAction : "ALL",
                filterBatterPitchesByHitPower : "ALL",
                filterBatterPitchesByHitResult : "ALL",
                filterPitcherPitchLocation : "ALL",
                filterBatterPitches : "ALL"
            });
        }
        if(val === "BP"){
            this.setState({
               
                filterBatterPitches : "ALL"
            });
        }
        if(val === "PL"){
            this.setState({
               
                filterPitcherPitchLocation : "ALL"
            });

        }
        if(val === "HR"){
            this.setState({
               
                filterBatterPitchesByHitResult : "ALL",
                
            });

        }
        if(val === "HP"){
            this.setState({
                
                filterBatterPitchesByHitPower : "ALL",
                
            });

        }
        if(val === "HA"){
            this.setState({
                filterBatterPitchesByHitAction : "ALL",
                
            });

        }
    }

    getPitchingTeamID = () =>{
        if(this.props.homeTeam && this.props.awayTeam){
            if(!this.state.isHomeTeam){
                return this.props.homeTeam.id;
            }
            else{
                return this.props.awayTeam.id;
            }
        }
    }

    filterByZone = (pitch) =>{
        if(this.state.filterPitcherPitchLocation === 'ALL'){
            return true;
        }
        if(this.state.filterPitcherPitchLocation === pitch.pitchLocation){
            return true;
        }
        return false;
    }

    filterByPitchType = (pitch) =>{
        ////console.log('filterByPitchType', pitch);
        if(this.state.filterPitcherPitchType === 'ALL'){
        return true;
        }
        if(this.state.filterPitcherPitchType === pitch.pitchType){
            return true;
        }
        return false;
    }


    getPitcherPitches = () =>{
        let ret = [];
        let _pitchArray = [...this.props.pitchArray];
        if(this.state.currentPitcher !== null){
    
            
            for(let i=0;i<_pitchArray.length; ++i){
                const pitch = _pitchArray[i];
                if(typeof pitch.batter === 'undefined')
                    continue;
                //////console.log('batter ' + i + ' id = ' + pitch.batter.id);
                if(pitch.pitcher.id === this.props.currentPitcher.id){
    
                    if(this.state.filterPitcherPitches === "ALL"){
                        if(this.filterByZone(pitch) && this.filterByPitchType(pitch)){
                            ret.push(pitch)
                        } 
                    }
                    else if(this.state.filterPitcherPitches === "SM"){
                        if(this.filterByZone(pitch) && this.filterByPitchType(pitch)){
                            ret.push(pitch)
                        }
                    }
                    else if(this.state.filterBatterPitches === "In Play"){
                        if(this.filterByZone(pitch) && this.filterByPitchType(pitch)){
                            ret.push(pitch)
                        }
                    }
                    else if(this.state.filterPitcherPitches === "Take"){
                        if(this.filterByZone(pitch) && this.filterByPitchType(pitch)){
                            ret.push(pitch)
                        }
                    }
                    else if(this.state.filterPitcherPitches === "Slap"){
                        if(this.filterByZone(pitch) && this.filterByPitchType(pitch)){
                            ret.push(pitch)
                        }
                    }
                    else if(this.state.filterPitcherPitches === "Foul"){
                        if(this.filterByZone(pitch) && this.filterByPitchType(pitch)){
                            ret.push(pitch)
                        }
                    }
                    else if(this.state.filterPitcherPitches === "Bunt"){
                        if(this.filterByZone(pitch) && this.filterByPitchType(pitch)){
                            ret.push(pitch)
                        }
                    }
                    else if(pitch.batAction === this.state.filterPitcherPitches){
                        if(this.filterByZone(pitch) && this.filterByPitchType(pitch)){
                            ret.push(pitch)
                        }
                    }
                }
            }
        }
        else if(this.props.pitchArray){
            for(let i=0;i<_pitchArray.length; ++i){
                
                const pitch = _pitchArray[i];
                if(this.props.training || this.getPitchingTeamID() == pitch.pitcher.teamId){
                    ret.push(pitch);
                }
                
            }
        }
        return ret;
       }
    onChangeBattingSide = (val) =>{
        this.setState({
            showPitcherBatters: val
        })
    }

    onDeleteGame = () =>{
        if(this.props.training){
            //console.log('Delete Training!' + this.props.training.id +" " + this.props.atBatState.atBatID);
            this.props.onDeleteTrainingState(this.props.token, this.props.atBatState.atBatID);
            this.props.onDeleteTraining(this.props.token,this.props.training.id);
            this.props.history.push('/trainings');
        
        }
        else{
            //console.log('Delete Game!' + this.props.game.id +" " + this.props.atBatState.atBatID);
            this.props.onDeleteGameState(this.props.token, this.props.atBatState.atBatID);
            this.props.onDeleteGame(this.props.token,this.props.game.id);
            this.props.history.push('/games');
        }
    }
   
    showClearPitchTypeButton = () =>{
        if(this.state.filterPitcherPitchType !== 'ALL'){
            return true;
        }
        return false;
    }

    showSwitchFlexBatter = () =>{
        this.setState({
            showSwitchFlex: true
        })
    }
    submitSwitchFlex = () =>{
        const isTop = this.isInningTop();
        if(isTop){
            //console.log('Switch DH for FLEX for away team');
            if(this.props.awayTeamFlex){
                let updatedFlex = {...this.props.awayTeamFlex};
                updatedFlex.atBat = true;
                this.props.onSetAwayTeamFlex(updatedFlex);
                this.props.onSetBatter(updatedFlex);
            }
        }
        else{
            //console.log('Switch DH for FLEX for home team');
            if(this.props.homeTeamFlex){
                let updatedFlex = {...this.props.homeTeamFlex};
                updatedFlex.atBat = true;
                this.props.onSetHomeTeamFlex(updatedFlex);
                this.props.onSetBatter(updatedFlex);
            }
        }

        this.setState({
            showSwitchFlex: false
        })


    }
    showSwitchFlexCancel = () =>{
        this.setState({
            showSwitchFlex: false
        })
    }

    canEdit = () =>{
        if(this.props.auser && (this.props.auser.role === 'SysAdmin' ||
        this.props.auser.role === 'OrgAdmin' ||
        this.props.auser.role === 'Admin' ||
        this.props.auser.role === 'OrgAdminTraining' ||
        this.props.auser.role === 'AdminTraining')){
            return true;
        }
        return false;
    }


    render(){
        let pitches = [];
        let pitcherPitches = [];
        let renderTrainingPitches = []
        if(this.props.isTraining && this.props.training.trainingType === 'Pitcher'){
            pitcherPitches =  this.getPitcherPitches();
            renderTrainingPitches= this.renderPitcherPitches(pitcherPitches);
        }
        else if(this.props.isTraining){
            pitches = this.getPitches();
            renderTrainingPitches= this.renderPitches(pitches);
        }

        let battingPractice = false;
        if(this.props.training && this.props.training.trainingType === 'BatterPractice'){
            battingPractice = true;
        }
         
        //////console.log('IS GAME OVER? ', this.isGameOver());
        ////////console.log('Games:');
        ////////console.log(this.props.games);
        let buttons = null;
        let mainArea = null;

        const pitchX = this.state.pitchLocation.x * WIDTH;
        const pitchY = this.state.pitchLocation.y * HEIGHT;
        const renderPitch = this.state.pitchLocation.x > 0 &&
                            this.state.pitchLocation.x < 1 &&
                            this.state.pitchLocation.y > 0 &&
                            this.state.pitchLocation.y < 1;
        const renderCurrentPitch = this.state.currentStep === 0 ?
        (<Pitch pitch = {null} x = {pitchX} y = {pitchY} strike={this.isStrike()} renderPitch={renderPitch}/>) : null;
        
        const renderPitches = this.state.pitches.map((p,i) => {
            const pitchX = p.x * WIDTH;
            const pitchY = p.y * HEIGHT;
            return(
                <Pitch key={i} pitch = {p} index = {i+1} x = {pitchX} y = {pitchY} strike={PitchClass.isStrike(p)} renderPitch={true}/>
            )
        });

        if(this.state.currentStep === 4 || 
           this.state.currentStep === 5 || 
           this.state.currentStep === 6 ||
           this.state.currentStep === 7 ){
            mainArea = (
                <FieldPlotter 
                    clicked={this.onBatterActionLocationHandler}
                    width={WIDTH}
                    height={HEIGHT}
                    showMiss={true}
                />
            );
        }
        else{
            mainArea = (
                <PitchPlotter 
                    currentPitch = {this.state.currentPitch}
                    pitchLocationName={this.state.locationName}
                    pitchLocation = {this.state.pitchLocation}
                    updateLocation={this.pitchLocationHandler}
                    clickLocation={this.state.currentStep === 0 ? this.pitchClickHandler : null}

                    //battingRight={this.state.battingRight}
                    //battingSideChange={this.battingSideChangeHandler}
                    width={WIDTH}
                    height={HEIGHT}
                    //ballCount = {this.state.ballCount}
                    //strikeCount = {this.state.strikeCount}
                    >
                    {renderCurrentPitch}
                    {renderPitches}
                </PitchPlotter>
            );
        }

        if(this.state.currentStep === 1){
            buttons = (
                <PitchTypeButtons pitchType= {this.props.pitchType} click={() => this.onPitchTypeHandler}/>
            );

        }
        else if(this.state.currentStep === 2){
            /*this.props.training.trainingType === 'Pitcher'*/
            
            buttons = (
                <PitchResultButtons strikeCount={this.state.strikeCount} battingPractice={battingPractice} training = {this.props.training} pitchType= {this.props.pitchType} click={() =>  this.onPitchResultHandler}/>
            )
        }

        //DG - eliminate Batter Action Buttons
        /*else if(this.state.currentStep === 3){
            buttons = (
                <BatterActionButtons click={() => this.onBatterActionResultHandler}/>
            )
        }*/
        else if(this.state.currentStep === 5){
            buttons = (
                <HitActionButtons click={() => this.onHitActionResultHandler}/>
            )
        }
        else if(this.state.currentStep === 6){
            if(this.props.training && this.props.training.trainingType === 'BatterPractice'){
                this.onBatterPracticeSaveHandler();
            }
            else{
                buttons = (
                    <HitPowerButtons click={() => this.onHitPowerResultHandler}/>
                )
            }
        }
        else if(this.state.currentStep === 7){
            buttons = (
                <HitResultButtons click={() => this.onHitResultHandler} training = {this.props.training}/>
            )
        }
        else if(this.state.currentStep === 8){
            buttons = (
                <FoulResultButtons click={() => this.onFoulResultHandler} training = {this.props.training}/>
            )
        }
        
        
        // const buildControls = controls.map((control, i) => {
        //     ////////console.log(control.label +' '+ control.type+' '+i);
        //     return <BuildControl 
        //                 key={i} 
        //                 label={control.label} 
        //                 added={()=> props.ingredientAdded(control.type)}
        //                 removed={()=> props.ingredientRemoved(control.type)}
        //                 disabled={props.disabled[control.type]}
        //                 />
        // });
        let title = null;
        let batterTrainingPlayerStatsJSX = null;
        let batterTrainingZonesTableJSX = null;
        let batterTrainingFiltersJSX = null;
        let batterPitchPlotterJSX = null;
        let trainingNotesJSX = null;

        let pitcherStatsJSX = null;
        let pitcherZoneTableJSX = null;
        let pitcherPitchPlotter = null;
        let  pitcherTrainingNotesJSX = null;
        let deleteTrainingButtonJSX = null;
        let quitButtonJSX = null;
        let pitcherTrainingFiltersJSX = null;

        
        if(this.props.game){
            title = <div className={styles.TitleContainer}>
                      <h3 style={{display: 'inline'}}>{this.isGameOver() === true ? 'FINAL - ' : ''}
                      {this.props.awayTeam.name} <img onClick={this.showAwayTeamNotesHandler} style={{width: '20px', display: 'inline', marginLeft: '0px'}} src={PencilImg} alt="away team note" />
                      
                      &nbsp; at &nbsp;
                      
                      {this.props.homeTeam.name}  <img onClick={this.showHomeTeamNotesHandler} style={{width: '20px', display: 'inline', marginLeft: '0px'}} src={PencilImg} alt="home team note" />
                      
                      -
                      { new Date(this.props.game.date).toLocaleDateString("en-US")}</h3>
                      <img onClick={this.showGameNotesHandler} style={{width: '20px', display: 'inline', marginLeft: '10px'}} src={PencilImg} alt="game note" />
                      <p>{this.props.forceQuitMessage}</p>
                    </div>
        }

        
        else if(this.props.isTraining){

            if(this.isGameOver() !== true){

                quitButtonJSX = <span className={styles.QuitButton}>
                        <Button clicked={this.handleShowForceQuit} btnSize="Small" btnType="Red">END TRAINING</Button>
                        </span>
            }

            if(this.canEdit()){
            deleteTrainingButtonJSX = <span className={styles.DeleteButton}>
                                <Button clicked={this.handleShowDelete} btnSize="Small" btnType="Red">DELETE TRAINING</Button>
                              </span>
            }

            let playerName = null;
            if(this.props.training.trainingType === 'Pitcher' && this.props.currentPitcher){
                playerName = this.props.currentPitcher.firstName+' '+ this.props.currentPitcher.lastName +' #'+this.props.currentPitcher.number;
            }
            else if(this.props.currentHitter){
                playerName = this.props.currentHitter.firstName +' '+this.props.currentHitter.lastName +' #'+ this.props.currentHitter.number;
            }

            title = <div className={styles.TitleContainer}>
            <h3 style={{display: 'inline'}}>{this.isGameOver() === true ? 'FINAL - ' : ''}{this.props.homeTeam.name}  -
            { new Date(this.props.training.date).toLocaleDateString("en-US")} - 
            Type: {this.props.training.trainingType} - 
            Pitch Type: {this.props.training.pitchType}
            </h3>
            <img onClick={this.showGameNotesHandler} style={{width: '20px', display: 'inline', marginLeft: '10px'}} src={PencilImg} alt="game note" />
            <p>{this.props.forceQuitMessage}</p>
            {/*<p>{playerName}</p> */}
           
          </div>

            if(this.props.training.trainingType !== 'Pitcher'){
                batterTrainingPlayerStatsJSX = <BatterTrainingPlayerStats
                pitchArray={pitches} 
                playerType={'batter'} 
                player={this.props.currentHitter} 
                onClick={null}
                onFilterPitchType={this.onFilterByPitchType}
                showBatterNotesHandler={this.showBatterNotesHandler}

                showPitcherNotesHandler = {null}
                teamID = {this.getBattingTeamID()}
                detailed = {true}
            />

            
       

            batterTrainingZonesTableJSX = 
            <BatterZoneTable 
                pitches={pitches} 
                battingPractice={battingPractice}
                onFilterZone={this.onFilterZone}/>
            
            let clearFiltersButton = <div style={{height:"37px"}}></div>;
            let clearFilterPitchLocationButton = null;
            let clearFilterHitResultButton = null;
            let clearFilterHitPowerButton = null;
            let clearFilterHitActionButton = null;
            let clearFilterPitchButton = null;
    
    
            if(this.showClearFiltersButton()){
                clearFiltersButton = <Button  btnType="Danger" btnSize="Small" clicked={() => this.onClearFiltersHandler("ALL")}>Clear All</Button>
            }
            if(this.showClearFilterPitchLocationButton()){
                clearFilterPitchLocationButton = <Button  btnType="Danger" btnSize="Small" clicked={()=> this.onClearFiltersHandler("PL")}>Clear Pitch Zone</Button>
            }
            if(this.showClearFilterHitResultButton()){
                clearFilterHitResultButton = <Button  btnType="Danger" btnSize="Small" clicked={() => this.onClearFiltersHandler("HR")}>Clear Hit Result</Button>
            }
            if(this.showClearFilterHitPowerButton()){
                clearFilterHitPowerButton = <Button  btnType="Danger" btnSize="Small" btnSize="Small" clicked={() => this.onClearFiltersHandler("HP")}>Clear Hit Power</Button>
            }
            if(this.showClearFilterHitActionButton()){
                clearFilterHitActionButton= <Button  btnType="Danger" btnSize="Small" clicked={() => this.onClearFiltersHandler("HA")}>Clear Hit Action</Button>
            }
            if(this.showClearFilterPitchButton()){
                clearFilterPitchButton =  <Button  btnType="Danger" btnSize="Small" clicked={() => this.onClearFiltersHandler("BP")}>Clear Hit Type</Button>
            }



            batterTrainingFiltersJSX = <div className={styles.BatterFilters}>
           
                    {clearFiltersButton}
                    {clearFilterPitchLocationButton}
                    {clearFilterHitResultButton}
                    {clearFilterHitPowerButton}
                    {clearFilterHitActionButton}
                    {clearFilterPitchButton}
                    <BatterTrainingFilterTables pitchArray = {pitches} 
                    onFilterByHitPower={this.onFilterByHitPower}
                    onFilterByHitResult={this.onFilterByHitResult}
                    onFilterByHitAction={this.onFilterByHitAction}
                    filterBatterPitchesByHitAction = {this.state.filterBatterPitchesByHitAction}
                    filterBatterPitchesByHitPower = {this.state.filterBatterPitchesByHitPower}
                    filterBatterPitchesByHitResult = {this.state.filterBatterPitchesByHitResult}
                    filterPitcherPitchLocation = {this.state.filterPitcherPitchLocation}
                    />
            </div>

            batterPitchPlotterJSX = 
            <div className={styles.PlotterContainer}>
                        <div className={styles.PitchPlotterContainer}>

                        </div>
            </div>


            batterPitchPlotterJSX = <div className={styles.PlotterContainer}>
                        <div className={styles.PitchPlotterContainer}>
                            
                            
                            <BatterTrainingPitchPlotter 
                                currentPitch = {null}
                                pitchLocationName={null}
                                pitchLocation = {null}
                                updateLocation={null}
                                clickLocation={null}
                                hideAtBatButtons={true}

                                //battingRight={this.state.battingRight}
                                //battingSideChange={this.battingSideChangeHandler}
                                width={222}
                                height={222}
                                //ballCount = {this.state.ballCount}
                                //strikeCount = {this.state.strikeCount}
                                >
                                {renderTrainingPitches}
                                
                            </BatterTrainingPitchPlotter>
                            <div className={styles.PitchTypeButtons}>
                                <div className={[styles.InPlayButton, styles.PitchButton].join(" ")} onClick={()=>this.onFilterBatterPitches("In Play")}>INPLAY</div>
                                <div className={[styles.SMButton, styles.PitchButton].join(" ")} onClick={()=>this.onFilterBatterPitches("SM")}>SM</div>
                                <div className={[styles.TakeButton, styles.PitchButton].join(" ")} onClick={()=>this.onFilterBatterPitches("Take")}>Take</div>
                                <div className={[styles.SlapButton, styles.PitchButton].join(" ")} onClick={()=>this.onFilterBatterPitches("Slap")}>Slap</div>
                                <div className={[styles.FoulButton, styles.PitchButton].join(" ")} onClick={()=>this.onFilterBatterPitches("Foul")}>Foul</div>
                                <div className={[styles.BuntButton, styles.PitchButton].join(" ")} onClick={()=>this.onFilterBatterPitches("Bunt")}>Bunt</div>
                            </div>
                        </div>
                       
                        <BatterTrainingFieldPlotter pitchArray={pitches}/>
                    </div>

                {/*trainingNotesJSX = <><h3>Training Notes</h3>
                <TrainingNote notes={this.props.atBatState.gameNotes}/></>*/}
            }
            else{

                let battingSideAllStyle = styles.BattingSideButton;
                let battingSideRightStyle = styles.BattingSideButton;
                let battingSideLeftStyle = styles.BattingSideButton;

                switch(this.state.showPitcherBatters){
                    case 0:{
                        battingSideAllStyle = [styles.BattingSideButton, styles.BattingSideButtonActive].join(" ");
                        break;
                    }
                    case 1:{
                        battingSideRightStyle = [styles.BattingSideButton, styles.BattingSideButtonActive].join(" ");
                        break;
                    }
                    default:{
                        battingSideLeftStyle = [styles.BattingSideButton, styles.BattingSideButtonActive].join(" ");
                        break;
                    }
                }

                let clearFiltersButton = <div style={{height:"37px"}}></div>;
                let clearFilterPitchTypeButton = null;
                let clearFilterPitchLocationButton = null;
        
                if(this.showClearPitchTypeButton()){
                    clearFilterPitchTypeButton = <Button  btnType="Danger" btnSize="Small" clicked={() => this.onClearPitcherFiltersHandler("Type")}>Clear Pitch Type</Button>
                }
        
                if(this.showClearPitcherFiltersButton()){
                    clearFiltersButton = <Button  btnType="Danger" btnSize="Small" clicked={() => this.onClearPitcherFiltersHandler("ALL")}>Clear All</Button>
                }
        
                if(this.showClearPitchLocationButton()){
                    clearFilterPitchLocationButton = <Button  btnType="Danger" btnSize="Small" clicked={() => this.onClearPitcherFiltersHandler("Location")}>Clear Location</Button>
                }

                let headerStyle = {backgroundColor: '#666',
                display: 'table',
                padding: '5px',
                marginLeft: 'auto',
                marginRight: 'auto'}

                pitcherTrainingFiltersJSX = <div>{/*<h3 style={headerStyle}>Filters</h3>*/}
                    {clearFiltersButton}
                    {clearFilterPitchTypeButton}
                    {clearFilterPitchLocationButton}</div>


                pitcherStatsJSX = <PitcherTrainingPlayerStats currentPitchArray={pitcherPitches}
                onFilterPitchType={this.onFilterByPitchType}
                                    playerType={'pitcher'}
                                    player={this.props.currentPitcher}
                                    onClick={null}
                                    showPitcherNotesHandler = {this.showPitcherNotesHandler}
                                        teamID = {this.getPitchingTeamID()}
                                        detailed = {true}
                                        filterPitcherPitchType = {this.state.filterPitcherPitchType}/>

                pitcherZoneTableJSX = <PitcherTrainingZoneTable pitches={pitcherPitches} onFilterZone={this.onFilterZone}/>
            
                pitcherPitchPlotter = 
                
                <>
                <div className={styles.PlateAppearanceButtons}>
                <div className={styles.BattingSideButtons}>
                                <div className={battingSideAllStyle} onClick={() =>this.onChangeBattingSide(0)}>ALL</div>
                                <div className={battingSideRightStyle} onClick={() => this.onChangeBattingSide(1)}>R</div>
                                <div className={battingSideLeftStyle} onClick={() =>this.onChangeBattingSide(2)}>L</div>

                                
                            </div>
                <PitcherTrainingPitchPlotter
                                            currentPitch = {null}
                                            pitchLocationName={null}
                                            pitchLocation = {null}
                                            updateLocation={null}
                                            clickLocation={null}
                                            

                                            //battingRight={this.state.battingRight}
                                            //battingSideChange={this.battingSideChangeHandler}
                                            showPitcherBatters={this.state.showPitcherBatters}
                                            width={222}
                                            height={222}
                                            //ballCount = {this.state.ballCount}
                                            //strikeCount = {this.state.strikeCount}
                                            >
                                            {renderTrainingPitches}
                                            
                                        </PitcherTrainingPitchPlotter>
                                        </div>
                                        </>
                pitcherTrainingNotesJSX = <><h3>Training Notes</h3>
                <TrainingNote notes={this.props.atBatState.gameNotes}/></>
            
            }
        }

        ////////console.log('Is Top of Inning? ' + this.isInningTop());
        ////////console.log('Current Inning ' + this.currentInning());
        let battingTeamPlayers = null;
        let pitchingTeamPlayers = null;

        let battingTeamLineup = null;
        let battingTeam = null;
        let pitchingTeam = null;
        let battingLineup = null;
        let playerSubstitution = null;

        let flexBatter = null;

        if(this.props.awayTeamLineup && this.props.homeTeamLineup){
            if(this.props.training === null && this.isInningTop()){
                battingTeamLineup = this.props.awayTeamLineup;
                //////console.log('top of inning so lineup is away team');
                //TODO: add batting lineup component
                if(this.props.baseRunners){
                battingLineup = <LineupComponent 
                                    team={this.props.awayTeam}
                                    baseRunners={this.props.baseRunners}
                                    subs={this.props.awaySubstitutions}
                                    lineup={this.props.awayTeamLineup}
                                    flex = {this.props.awayTeamFlex}
                                    index={this.props.awayTeamCurrentBatterIndex} 
                                    handleReconcileBaseRunner={this.canEdit() === true ? this.handleShowReconcileBaseRunner : null}
            //                      handleScore={this.baseRunnerScoreHandler}
            //                      handleOut={this.baseRunnerOutHandler}
            //                      handleLeftOnBase={this.baseRunnerLeftOnBaseHandler}
                                    handleSub={this.canEdit() === true ? this.handleLineupSubstitution : null}
                                    />
                }

                battingTeamPlayers = this.props.awayTeamPlayers;
                pitchingTeamPlayers = this.props.homeTeamPlayers;
                battingTeam = this.props.awayTeam;
                pitchingTeam = this.props.homeTeam;

                if( this.props.currentHitter &&
                    this.props.awayTeamFlex && 
                   !this.props.awayTeamFlex.atBat &&
                   this.props.currentHitter.currentPosition === 'DP'){
                       flexBatter = <Button btnType="Danger" clicked={this.showSwitchFlexBatter}>FLEX BATTER</Button>
                }
            }
            else if(this.props.training === null){
                battingTeamLineup = this.props.homeTeamLineup;
                //////console.log('bottom of inning so lineup is home team');
                //TODO: add batting lineup component
                battingLineup = <LineupComponent 
                                    team={this.props.homeTeam}
                                    baseRunners={this.props.baseRunners}
                                    subs={this.props.homeSubstitutions}
                                    lineup={this.props.homeTeamLineup} 
                                    flex = {this.props.homeTeamFlex}
                                    index={this.props.homeTeamCurrentBatterIndex} 
                                    handleReconcileBaseRunner={this.handleShowReconcileBaseRunner}
                                    handleScore={this.baseRunnerScoreHandler}
                                    handleOut={this.baseRunnerOutHandler}
                                    handleLeftOnBase={this.baseRunnerLeftOnBaseHandler}
                                    handleSub={this.handleLineupSubstitution}
                                    />


                battingTeamPlayers = this.props.homeTeamPlayers;
                pitchingTeamPlayers = this.props.awayTeamPlayers;
                battingTeam = this.props.homeTeam;
                pitchingTeam = this.props.awayTeam;

                if(  this.props.currentHitter &&
                    this.props.homeTeamFlex && 
                    !this.props.homeTeamFlex.atBat &&
                    this.props.currentHitter.currentPosition === 'DP'){
                        flexBatter = <Button btnType="Danger" clicked={this.showSwitchFlexBatter}>FLEX BATTER</Button>
                }
                 
            }
        }

        //DG Taking this out.   JP doesn't think it's needed
        flexBatter = null;
        
        let selectPitcher = null;
        if(pitchingTeamPlayers){

           




            let pitchLabel = "Select Pitcher";
            if(pitchingTeam){
                pitchLabel = "Select " + pitchingTeam.name + " Pitcher";
                if(this.props.currentPitcher){
                    pitchLabel = pitchingTeam.name +" Pitcher Selected";
                }
            }
            let playersWhoCanSub = [];
            for(let i=0;i<pitchingTeamPlayers.length; ++i){
                let p = pitchingTeamPlayers[i];
                if(typeof p.hidden !== 'undefined' && p.hidden === true){
                    continue;
                }
                playersWhoCanSub.push({...p});
            }



            //{this.props.currentPitcher ? {pitchingTeam.name}  + " Pitcher Selected" : "Select Pitcher"} 
            selectPitcher= <PlayerSelector label={pitchLabel}
                            players={playersWhoCanSub} 
                            currentPlayer={this.props.currentPitcher}
                            onSubmit={(id)=>this.onSelectPitcherHandler(id)}/>;
        }
        let selectBatter = null;
        if(battingTeamPlayers){
            let batLabel = "Select Batter";
            if(battingTeam){
                batLabel = "Select " + battingTeam.name + "  Batter";
                if(this.props.currentHitter){
                    batLabel = battingTeam.name +" Batter Selected";
                }
            }

            //remove the current lineup players from the eligible players to sub
            //let playersWhoCanSub = [...battingTeamPlayers];
            let playersWhoCanSub = [];
            for(let i=0;i<battingTeamPlayers.length; ++i){
                let p = battingTeamPlayers[i];
                if(typeof p.hidden !== 'undefined' && p.hidden === true){
                    continue;
                }
                playersWhoCanSub.push({...p});
            }
            

            for(let i=0;i<battingTeamLineup.length; ++i){
                let p = battingTeamLineup[i];
                //let player = battingTeamLineup.find(x=>x.id === p.id);
                //if(typeof player !== "undefined"){
                //    playersWhoCanSub.splice(i,1);
                //    i=0;
                //}
                playersWhoCanSub = playersWhoCanSub.filter(function(value, index, arr){

                    return value.id !== p.id;
                
                });
                //let isInLineup = this.props.homeTeamLineup.find(x=>x.id === p.id);
            }



            selectBatter= <PlayerSelector label={batLabel}
                            players={playersWhoCanSub} 
                            currentPlayer={this.props.currentHitter}
                            onSubmit={(id)=>this.onSelectBatterHandler(id)}/>;

             /*
            showPlayerSubstitution: true,
            substituteIndex: 0,
            playerToSub: null*/

            
            

            if(this.state.playerToSub){               
                let playerSubLabel = "Substitute " + this.state.playerToSub.firstName + ' ' + this.state.playerToSub.lastName
                let playerPosLabel = "Position " + this.state.playerToSub.firstName + ' ' + this.state.playerToSub.lastName +' '+this.state.playerToSub.currentPosition
                playerSubstitution = 
                                <>
                                <PlayerSelector label={playerSubLabel}
                                players={playersWhoCanSub}
                                currentPlayer={this.state.playerToSub}
                                onSubmit={(id)=>this.onHandlePlayerSubstitution(id)} />
                                <PlayerPosition label={playerPosLabel}
                                currentPlayer={this.state.playerToSub}
                                onSubmit={(id)=>this.onHandlePlayerPosition(id)} />
                                </>
                
            }
        }
        let pitchLocation = <PitchLocation 
                                pitch = {this.state.currentPitch}
                            />;
        let battingSide = <BattingSide battingRight={this.props.battingRight} change={this.battingSideChangeHandler}/>;
        let playerMatchup = null;

        let gameState = null;

        let deleteTitle = "Delete Game";
        let deleteMessage = "This will permanently Delete the Game.  Are you sure?";
        let deleteButtonText = "DELETE THE GAME";
        
        if(this.props.training === null && battingTeam && pitchingTeam){
            gameState = <GameState  
                                    battingTeam={battingTeam.name} 
                                    pitchingTeam={pitchingTeam.name} 
                                    pitcher = {this.props.currentPitcher}
                                    batter  = {this.props.currentHitter}
                                    changePlayer = {this.onChangePlayersHandler}
                                    inning={this.currentInning()} 
                                    topBottom={this.isInningTop() ? 'TOP' : 'BOTTOM'}
                                    outs={this.props.outs}
                                    balls={this.state.ballCount}
                                    strikes={this.state.strikeCount}
                                    pitchNumber={this.props.pitchArray.length + this.state.pitches.length}
                                    baseRunners={this.props.baseRunners}
                                    homeScore={this.props.homeScore}
                                    awayScore={this.props.awayScore} 
                                    totalInnings = {this.props.totalInnings}
                                    handleScore={this.baseRunnerScoreHandler}
                                    handleOut={this.baseRunnerOutHandler}
                                    handleLeftOnBase={this.baseRunnerLeftOnBaseHandler}
                                    onChangePitcher={this.onChangePitcher}
                                    onChangeBatter={this.onChangeBatter}
                                    handleShowForceQuit = {this.handleShowForceQuit}
                                    showQuitButton = {!this.isGameOver()}
                                    showDeleteGame = {true/*!this.isGameOver()*/}
                                    handleDelete = {this.handleShowDelete}
                                    currentPitchArray = {this.state.pitches}
                                    showPitcherNotesHandler = {this.showPitcherNotesHandler/*this.state.currentPitch ? this.showPitcherNotesHandler : null*/}
                                    showBatterNotesHandler = {this.showBatterNotesHandler/*this.state.currentPitch ? this.showBatterNotesHandler : null*/}
                                    />

                                    
                                    
        }

        if(this.props.training == null && (this.props.currentPitcher === null || this.props.currentHitter === null)){
            mainArea = null;
            
           
            battingSide = null;
        }else{

            //////console.log('current pitcher');
            //////console.log(this.props.currentPitcher);
            //////console.log('current batter');
            //////console.log(this.props.currentBatter);
            /*playerMatchup = 
            <>
            <h3>Pitcher: #{this.props.currentPitcher.number} - {this.props.currentPitcher.firstName} {this.props.currentPitcher.lastName}</h3>
            <h3>Batter: #{this.props.currentHitter.number} - {this.props.currentHitter.firstName} {this.props.currentHitter.lastName}</h3>
            </>*/
        }

        let pitchHistory = null;
        if(this.props.pitchArray && this.state.showPitchHistory){
            pitchHistory = <PitchHistory pitches={this.props.pitchArray} />
        }
        //this.state.showPlayerSelection

        let selectPlayersButton = null;

        let gameStatsLink = null;
      

        let forceQuitTitle = 'Force Quit Game';
        let forceQuitMessage = 'Please summarize the reason for force quitting the game';
        let forceQuitButtonText = 'FORCE QUIT THE GAME';

        if(this.props.training === null){
            gameStatsLink = <Button btnType="Danger" clicked={this.onShowGameStatsHandler}>Game Stats</Button>
            
        }
        else{
            deleteTitle = "Delete Training";
            deleteMessage = "This will permanently Delete the Training.  Are you sure?";
            deleteButtonText = "DELETE THE TRAINING";

            forceQuitTitle = "End Training";
            forceQuitMessage = 'Please summarize the reason for force ending the training';
            forceQuitButtonText = 'END THE TRAINING';
        }

        let playerStatsButton = null;
        let stateArray = null;
        if(!this.props.training){
            playerStatsButton = <Button btnType="Danger" clicked={this.onShowPlayerStatsHandler}>Player Stats</Button>
            if(this.canEdit() && this.state.pitches.length === 0){
                stateArray = <StateArray popState={false}/>
            }
            else if(this.canEdit() && this.state.pitches.length > 0){
                stateArray = <StateArray popState={true} popPitchArrayHandler={this.popPitchHandler}/>
            }
        }
        else{
            if(this.canEdit() && this.state.pitches.length === 0){
                stateArray = <StateArray popState={false}/>
            }
            else if(this.canEdit() && this.state.pitches.length > 0){
                stateArray = <StateArray popState={true} popPitchArrayHandler={this.popPitchHandler}/>
            }

        }

        let scoutingReportButton = null;

        //if(this.canEdit()){
            scoutingReportButton =<Button btnType="Danger" clicked={this.onShowScoutReport}>Scouting Reports</Button>

        //}
        
        

        let pitchHistoryContainer =  <div className={styles.PitchHistory}>

                                <div className={styles.ButtonWrapper}>
                                    <Button btnType="Danger" clicked={this.onShowPitchHistoryHandler}>Pitch History</Button>
                                    {playerStatsButton}
                                    {gameStatsLink}
                                    {scoutingReportButton}
                                    {/*stateArray*/}
                                    
                
                                </div>
                                {pitchHistory}
                            </div>


        let baseRunners = null; 
        
        if(!this.props.training){
            baseRunners = <BaseRunnersContainer handleReconcileBaseRunner={this.handleShowReconcileBaseRunner}/>
        }

        let pitcherViewButton = <Button clicked={this.togglePitcherView} btnSize="Small">
                                    {this.props.pitcherView === true ? "PITCHER VIEW" : "BATTER VIEW"}
                                </Button>

        let velocityInput = <><span className={styles.VelocitySpan}>Velocity</span><input className={styles.Velocity}
                            type="text"
                            name="velocity"
                            value={""}
                            onChange={this.handleVelocityChange}
                            />
                            </>; 
        
        if(this.state.currentPitch){
            velocityInput = <><span className={styles.VelocitySpan}>Velocity</span><input className={styles.Velocity}
                                    type="text"
                                    name="velocity"
                                    value={this.state.currentPitch.velocity || ''}
                                    onChange={this.handleVelocityChange}
                                    />
                                    </>
        }
        let showSpinner = null;
        if(mainArea === null || (gameState === null && this.props.training === null)){
            showSpinner = <Spinner />
            mainArea = null;
            title = null;
            gameState = null;
            selectPlayersButton = null;
            playerMatchup = null;
           
            
            battingSide = null;
            buttons = null;
            pitchHistoryContainer = null;
            battingLineup = null;
            flexBatter = null;
            baseRunners = null;
            pitcherViewButton = null;
            velocityInput = null;

        }

        if(this.isGameOver()){
            mainArea = null;
            buttons = null;
            playerMatchup = null;
           
            
            battingSide = null;
            selectPlayersButton = null;
            battingLineup = null;
            flexBatter = null;
            pitcherViewButton = null;
            baseRunners = null;
            velocityInput = null;
        }

        
        pitchLocation = null;

let wrapperStyle = {display:'inline-block'};
if(this.isGameOver()){
    wrapperStyle = {display: 'none'};
}
        
        let aggregateView = <>
                                
                                {gameState}
                                
                                {showSpinner}
                                
                                <div className={styles.Wrapper}>
                                    <div style={wrapperStyle}>
                                    {selectPlayersButton}
                                    {playerMatchup}
                                    {pitchLocation}
                                    
                                    {battingLineup}
                                    {flexBatter}
                                    {baseRunners}
                                    <div style={{
                                        display: 'inline-block',
                                        verticalAlign:'top', width:'281px'}}>
                                    
                                    
                                        {battingSide}
                                        {pitcherViewButton}
                                        {velocityInput}
                                        {mainArea}
                                        {buttons}
                                
                                    </div>
                                    <div style={{
                                        width: '100%',
                                        display: 'inline-block'
                                    }}>
                                        <PitchList 
                                            pitches = {this.state.pitches}
                                            popPitchHandler = {this.popPitchHandler}
                                        />
                                        {stateArray}
                                     </div>
                                    </div>
                                 {pitcherPitchPlotter}
                                 
                                 
                                 {batterTrainingZonesTableJSX}
                                 {batterTrainingFiltersJSX}
                                 {batterPitchPlotterJSX}
                                 {trainingNotesJSX}
                                </div>
                               
                                <div style={{display : 'inline-block', verticalAlign: 'top', marginTop: '26px', marginRight:'0px'}}>
                                {pitcherZoneTableJSX}
                                </div>
                                {/*<div style={{display : 'inline-block'}}>
                                {pitcherTrainingNotesJSX}
                                    </div>*/}
                               
                                
                                
                            </>
        
        if(this.showLineup()){
            aggregateView = <Lineup />
        }
        let reconcileBaseRunnerJSX = null;
        


        if(this.state.baseRunnersToReconcile){
            if(this.state.handleFC === true){
            reconcileBaseRunnerJSX = <BaseRunner runners={this.state.baseRunnersToReconcile} 
            handleScore={this.baseRunnerScoreHandler}
            handleOut={this.baseRunnerOutHandler}
            handleSafe={this.baseRunnerSafeHandler}
            
            />
            }
            else{
                reconcileBaseRunnerJSX = <BaseRunner runners={this.state.baseRunnersToReconcile} 
            handleScore={this.baseRunnerScoreHandler}
            handleOut={this.baseRunnerOutHandler}
            /*
            I'm not sure this makes sense as an option if the inning isn't over
            handleLeftOnBase={this.baseRunnerLeftOnBaseHandler}
            */
            
            />
            }
        }

        let baseRunnersComponent = null;

        if(this.props.baseRunners){
        baseRunnersComponent = <BaseRunners 
        runners={this.props.baseRunners} 
        handleScore={this.baseRunnerScoreHandler}
        handleOut={this.baseRunnerOutHandler}
        handleLeftOnBase={this.baseRunnerLeftOnBaseHandler}/>
        }
              //this.state.showHomeTeamNotes
//homeTeamNotesHeader
//homeTeamNotesMessageChange
//showHomeTeamNotesCancelHandler

        let homeTeamNotesHeader = '';
        let awayTeamNotesHeader = '';
        
        
        if(this.props.homeTeam){
            homeTeamNotesHeader = this.props.homeTeam.name +' Notes';
        }

        if(this.props.awayTeam){
            awayTeamNotesHeader = this.props.awayTeam.name +' Notes';
        }

        let showHomeTeamNotes = true;
        if(this.props.training){
            showHomeTeamNotes = false;
        }

        let notesHeader = "Game Notes";
        if(this.props.training && this.props.training.trainingType === 'Pitcher'){
            notesHeader = "Pitcher Training Notes";
        }
        else if(this.props.training && this.props.training.trainingType === 'Batter'){
            notesHeader = "Batter Training Notes";
        }
        else if(this.props.training && this.props.training.trainingType === 'BatterPractice'){
            notesHeader = "Batter Training Notes";
        }

        //////console.log("GAME NOTES ", this.props.gameNotes)
        
        return(
            <>
            <Modal show={this.state.showSwitchFlex} clickBackdrop={this.showSwitchFlexCancel}>
                <h3>Switch Current DH Batter for FLEX?</h3>
                <Button clicked={this.submitSwitchFlex}>SWITCH</Button>

            </Modal>
            <Modal show={this.state.showBatterNotes} clickBackdrop={this.showBatterNotesCancelHandler}>
                <h3>Batter Notes</h3>
                <textarea className={styles.ForceQuitMessageArea} onChange={(event)=>this.batterNotesMessageChange(event)} value={this.state.batterNotes === null ? '' : this.state.batterNotes }></textarea>
                
                <Button clicked={this.submitBatterNotesHandler}>SAVE</Button>
            </Modal>
            <Modal show={this.state.showPitcherNotes} clickBackdrop={this.showPitcherNotesCancelHandler}>
                <h3>Pitcher Notes</h3>
                <textarea className={styles.ForceQuitMessageArea} onChange={(event)=>this.pitcherNotesMessageChange(event)} value={this.state.pitcherNotes === null? '' : this.state.pitcherNotes }></textarea>
                
                <Button clicked={() => this.submitPitcherNotesHandler()}>SAVE</Button>
            </Modal>
            <Modal show={this.state.showGameNotes} clickBackdrop={this.showGameNotesCancelHandler}>
                <h3>{notesHeader}</h3>
                <textarea className={styles.ForceQuitMessageArea} onChange={(event)=>this.gameNotesMessageChange(event)} value={this.props.gameNotes === null ? '' : this.props.gameNotes}></textarea>
                <Button clicked={this.showGameNotesCancelHandler}>SAVE</Button>
            </Modal>




            <Modal show={this.state.showHomeTeamNotes} clickBackdrop={this.showHomeTeamNotesCancelHandler}>
                <h3>{homeTeamNotesHeader}</h3>
                <textarea className={styles.ForceQuitMessageArea} onChange={(event)=>this.homeTeamNotesMessageChange(event)} value={this.state.homeTeamNotes}></textarea>
                <Button clicked={this.submitHomeTeamNotesHandler}>SAVE</Button>
            </Modal>

            <Modal show={this.state.showAwayTeamNotes} clickBackdrop={this.showAwayTeamNotesCancelHandler}>
                <h3>{awayTeamNotesHeader}</h3>
                <textarea className={styles.ForceQuitMessageArea} onChange={(event)=>this.awayTeamNotesMessageChange(event)} value={this.state.awayTeamNotes}></textarea>
                <Button clicked={this.submitAwayTeamNotesHandler}>SAVE</Button>
            </Modal>



            <Modal show={this.state.reconcileBaseRunner} clickBackdrop={this.reconcileBaseRunnerCancel} small={true}>
                <h3>Reconcile Base Runners-1</h3>
                {reconcileBaseRunnerJSX}
            </Modal>
            
            <Modal show={this.state.showForceQuit} clickBackdrop={this.handleForceQuitCancel}>
                <h3>{forceQuitTitle}</h3>
                <p>{forceQuitMessage}</p>
                <textarea className={styles.ForceQuitMessageArea} onChange={(event)=>this.forceQuitMessageChange(event)}>{this.state.forceQuitMessage}</textarea>
                <Button clicked={this.handleSaveForceQuit} btnType="Red">{forceQuitButtonText}</Button>
            </Modal>
           
            <Modal show={this.state.showDelete} clickBackdrop={this.handleDeleteCancel}>
                <h3>{deleteTitle}</h3>
                <p>{deleteMessage}</p>
                <Button clicked={this.onDeleteGame} btnType="Red">{deleteButtonText}</Button>
            </Modal>
            
            <Modal show={this.state.showPlayerSubstitution} clickBackdrop={this.handleLineupSubstitutionCancel}>
                {playerSubstitution}
            </Modal>
            <Modal topMost={true} show={this.state.reconcilingBaseRunners} clickBackdrop={null} small={true}>
               <h3>Reconcile Base Runners-2</h3>
               {baseRunnersComponent}
            </Modal>
            <Modal show={this.state.showPitcherSelection} clickBackdrop={this.handlePitcherSelectionCancel}>
                {selectPitcher}
            </Modal>
            <Modal show={this.state.showBatterSelection} clickBackdrop={this.handleBatterSelectionCancel}>
                {selectBatter}
            </Modal>
            <div className={styles.MainContainer}>
                <div style={{position : 'absolute', right: '10px'}}>
                {quitButtonJSX}
                {deleteTrainingButtonJSX}
                </div>
                {title}
                {batterTrainingFiltersJSX}
                {pitcherTrainingFiltersJSX}
                {batterTrainingPlayerStatsJSX}
                {pitcherStatsJSX}
                
                
                
                {aggregateView}
            </div>
            {pitchHistoryContainer}
            </>
        );
    };
};


const mapStateToProps = state =>{
    return{
        /*gameStart: state.game.gameStart,
        isAuth: state.auth.token !== null,
        loading: state.auth.loading,*/
        rootHomeTeamPlayers: state.atBat.rootHomeTeamPlayers,
        rootAwayTeamPlayers: state.atBat.rootAwayTeamPlayers,
        gameLoading: state.game.loading,
        gameError: state.game.error,
        gameMessage: state.game.message,
        //games: state.game.games,
        auser: state.auth.user,
        game: state.atBat.game,
        homeTeam: state.atBat.homeTeam,
        awayTeam: state.atBat.awayTeam,
        homeTeamPlayers: state.atBat.homeTeamPlayers,
        awayTeamPlayers: state.atBat.awayTeamPlayers,
        currentInning: state.atBat.currentInning,
        currentPitcher: state.atBat.currentPitcher,
        currentHitter: state.atBat.currentHitter,
        battingRight: state.atBat.battingRight,
        atBatState: state.atBat,
        gameStateLoading: state.atBat.loading,
        token: state.auth.token,
        pitchArray: state.atBat.pitchArray,
        outs: state.atBat.outs,
        pitchNumber: state.atBat.pitchNumber,
        baseRunners: state.atBat.baseRunners,
        homeScore: state.atBat.homeScore,
        awayScore: state.atBat.awayScore,
        gameOver: state.atBat.gameOver,
        homeTeamLineup: state.atBat.homeTeamLineup,
        homeTeamCurrentPitcher: state.atBat.homeTeamCurrentPitcher,
        awayTeamLineup: state.atBat.awayTeamLineup,
        awayTeamCurrentPitcher: state.atBat.awayTeamCurrentPitcher,
        homeTeamFlex: state.atBat.homeTeamFlex,
        awayTeamFlex: state.atBat.awayTeamFlex,
        totalInnings: state.atBat.totalInnings,
        homeTeamCurrentBatterIndex: state.atBat.homeTeamCurrentBatterIndex,
        awayTeamCurrentBatterIndex: state.atBat.awayTeamCurrentBatterIndex,
        inningScores: state.atBat.inningScores,
        currentTopInningScore: state.atBat.currentTopInningScore,
        currentBottomInningScore: state.atBat.currentBottomInningScore,
        forceQuit: state.atBat.forceQuit,
        forceQuitMessage: state.atBat.forceQuitNotes,
        homeSubstitutions: state.atBat.homeSubstitutions,
        awaySubstitutions: state.atBat.awaySubstitutions,
        pitcherView: state.atBat.pitcherView,
        firstBaseRunner: state.atBat.firstBaseRunner,
        secondBaseRunner: state.atBat.secondBaseRunner,
        thirdBaseRunner: state.atBat.thirdBaseRunner,
        gameNotes: state.atBat.gameNotes,
        isTraining: state.atBat.isTraining,
        trainingID: state.atBat.trainingID,
        training: state.atBat.training,
        pitchType: state.atBat.pitchType,
        gameStateArray: state.stateArray
    }
}


const mapDispatchToProps = dispatch =>{
    return{
        onClearPop:()=>dispatch(
            ActionTypes.clearPop()
        ),
        onSetHomeTeam: (data) =>dispatch(
            ActionTypes.setHomeTeam(data)
        ),
        onSetAwayTeam: (data) =>dispatch(
            ActionTypes.setAwayTeam(data)
        ),
        onUpdateTeam: (token, data)=>dispatch(
            ActionTypes.updateTeam(token,data)
        ),
        onDeleteTraining: (token, data)=>dispatch(
            ActionTypes.deleteTraining(token, data)
        ),
        onDeleteTrainingState: (token, data)=>dispatch(
            ActionTypes.deleteTrainingState(token, data)
        ),
        onDeleteGame: (token, data)=>dispatch(
            ActionTypes.deleteGame(token, data)
        ),
        onDeleteGameState: (token, data)=>dispatch(
            ActionTypes.deleteGameState(token, data)
        ),
        onSetGameNotes: (data)=>dispatch(
            ActionTypes.setGameNotes(data)
        ),
        onUpdatePitcherView: (data)=>dispatch(
            ActionTypes.updatePitcherView(data)
        ),
        onLoadGame:  (token, id)=>dispatch(
            ActionTypes.initializeGame(token,id)
        ),
        onSetPitcher: (data)=>dispatch(
            ActionTypes.setCurrentPitcher(data)
        ),
        onSetBatter: (data)=>dispatch(
            ActionTypes.setCurrentBatter(data)
        ),
        onSetBattingRight: (data)=>dispatch(
            ActionTypes.setBattingRight(data)
        ),
        onSaveGameState: (token, data)=>dispatch(
            ActionTypes.saveGameState(token, data)
        ),
        onSaveGameStateArray: (token, data)=>dispatch(
            ActionTypes.saveGameStateArray(token, data)
        ),
        onSaveTrainingState: (token, data)=>dispatch(
            ActionTypes.saveTrainingState(token, data)
        ),
        onLoadGameState: (token, id)=>dispatch(
            ActionTypes.loadGameState(token, id)
        ),
        onLoadGameStateArray: (token, id)=>dispatch(
            ActionTypes.loadGameStateArray(token, id)
        ),
        onLoadTrainingState: (token, id)=>dispatch(
            ActionTypes.loadTrainingState(token, id)
        ),
        onUpdatePitchArray: (data)=>dispatch(
            ActionTypes.updateGameStatePitchArray(data)
        ),
        onSetCurrentInning: (data)=>dispatch(
            ActionTypes.setCurrentInning(data)
        ),
        onSetOuts: (data)=>dispatch(
            ActionTypes.setOuts(data)
        ),
        onSetPitchNumber: (data)=>dispatch(
            ActionTypes.setPitchNumber(data)
        ),
        onUpdateBaseRunners: (data)=>dispatch(
            ActionTypes.updateBaseRunners(data)
        ),
        onSetHomeScore: (data)=>dispatch(
            ActionTypes.setHomeScore(data)
        ),
        onSetAwayScore: (data)=>dispatch(
            ActionTypes.setAwayScore(data)
        ),
        onSetGameOver: (data)=>dispatch(
            ActionTypes.setGameOver(data)
        ),
        onSetHomeTeamCurrentBatterIndex: (data)=>dispatch(
            ActionTypes.setHomeTeamCurrentBatterIndex(data)
        ),
        onSetAwayTeamCurrentBatterIndex: (data)=>dispatch(
            ActionTypes.setAwayTeamCurrentBatterIndex(data)
        ),
        onSetInningScores: (data)=>dispatch(
            ActionTypes.setInningScores(data)
        ),
        onSetCurrentTopInningScore: (data)=>dispatch(
            ActionTypes.setCurrentTopInningScore(data)
        ),
        onSetCurrentBottomInningScore: (data)=>dispatch(
            ActionTypes.setCurrentBottomInningScore(data)
        ),
        onSetAwayTeamLineup: (data)=>dispatch(
            ActionTypes.setAwayTeamLineup(data)
        ),
        onSetHomeTeamLineup: (data)=>dispatch(
            ActionTypes.setHomeTeamLineup(data)
        ),
        onSetAwayTeamCurrentPitcher: (data)=>dispatch(
            ActionTypes.setAwayTeamCurrentPitcher(data)
        ),
        onSetHomeTeamCurrentPitcher: (data)=>dispatch(
            ActionTypes.setHomeTeamCurrentPitcher(data)
        ),
        onSetForceQuit: (forceQuit, forceQuitNotes)=>dispatch(
            ActionTypes.setForceQuit(forceQuit, forceQuitNotes)
        ),
        onUpdateHomeSubs: (data) =>dispatch(
            ActionTypes.updateHomeSubs(data)
        ),
        onUpdateAwaySubs: (data) =>dispatch(
            ActionTypes.updateAwaySubs(data)
        ),
        onSetAwayTeamPlayers: (data) =>dispatch(
            ActionTypes.setAwayTeamPlayers(data)
        ),
        onSetHomeTeamPlayers: (data) =>dispatch(
            ActionTypes.setHomeTeamPlayers(data)
        ),
        
        onSetFirstBaseRunner: (data)=>dispatch(
            ActionTypes.setFirstBaseRunner(data)
        ),
        onSetSecondBaseRunner: (data)=>dispatch(
            ActionTypes.setSecondBaseRunner(data)
        ),
        onSetThirdBaseRunner: (data)=>dispatch(
            ActionTypes.setThirdBaseRunner(data)
        ),
        onSetHomeTeamFlex: (data)=>dispatch(
            ActionTypes.setHomeTeamFlex(data)
        ),
        onSetAwayTeamFlex: (data)=>dispatch(
            ActionTypes.setAwayTeamFlex(data)
        ),
        onUpdatePlayer: (token, payload)=>dispatch(
            ActionTypes.updatePlayer(token, payload)
        ),
        
    }
};



export default connect(mapStateToProps, mapDispatchToProps) (AtBat);
