import React from 'react';
import FoulResultButton from './FoulResultButton/FoulResultButton';
import styles from './FoulResultButtons.module.css';

const FoulResultButtons = (props) => {
    let options = [
        'Foul',
        'Foul Out'
    ];

    if(props.training !== null){//} && props.training.trainingType === 'Pitcher'){
        options = [
            'Foul'
        ];
    }

    const buttons = options.map((o,i) => {
        
        return (
            <FoulResultButton key={i} type={o} click={props.click(o)}
           
            />
        )
    });

    return(
        <div className={styles.FoulResultButtons}>
        {buttons}
        </div>

    )
}

export default FoulResultButtons;
