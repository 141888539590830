import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as ActionTypes from '../../store/actions/index';
import styles from './PlayerScoutingReport.module.css';
import Button from '../../components/UI/Button/Button';
import PlayerSelector from '../PlayerSelector/PlayerSelector';
import PlayerScoutingReportForm from './PlayerScoutingReportForm/PlayerScoutingReportForm';





class PlayerScoutingReport extends Component{
    state = {

        isHomeTeam : true,
        currentBatter: null,
        batterIsNull: false,
        pitcherIsNull: false,
        currentPitcher:null,
        initialized: false,
        showPitcherBatters: 0, //0 - all, 1- right, 2- left,
        plateAppearance: 0, //0 - all

        filterPitcherPitches: "ALL",
        filterBatterPitches: "ALL",

        filterPitcherPitchLocation: "ALL",
        filterPitcherPitchType: "ALL",
        currentPlayer: null,
        rootCurrentPlayer: null
    }

    componentDidMount(){
        //this.props.onLoadGame(this.props.token, this.props.match.params.id);
        //console.log(this.props.match.path);
        if(this.props.match.path === '/player_training_scouting_report/:id'){
            //console.log('load training data')
            this.props.onLoadTrainingState(this.props.token, this.props.match.params.id);
        }
        else{
            //console.log('load game data')
            this.props.onLoadGameState(this.props.token, this.props.match.params.id);
        }
        
    }

    componentDidUpdate(prevProps, prevState){
        const isTop = this.isInningTop();
        if(!this.state.initialized && isTop && this.state.isHomeTeam){
            this.setState({
                initialized: true,
                isHomeTeam: false
            })
        }
        
        if(this.props.currentHitter && !this.state.currentBatter && !this.state.batterIsNull){
            this.setState({
                currentBatter: this.props.currentHitter
            })
        }

        if(!this.props.training && this.props.currentPitcher && !this.state.currentPitcher && !this.state.pitcherIsNull){
            this.setState({
                currentPitcher: isTop ? this.props.homeTeamCurrentPitcher : this.props.awayTeamCurrentPitcher
            })
        }
        else if(this.props.training && this.props.currentPitcher && !this.state.currentPitcher && !this.state.pitcherIsNull){
            this.setState({
                currentPitcher: this.props.currentPitcher
            })
        }
        
        if(!this.props.gameStateLoading && this.props.atBatState !== prevProps.atBatState){
            //////console.log('calling on SaveGameState in AtBat', this.props.token, this.props.atBatState);
            //////console.log('calling on SaveGameState in AtBat this.props.pitchArray.length = ' + this.props.pitchArray.length);

            if(this.props.training){

                this.props.onSaveTrainingState(this.props.token, this.props.atBatState);
            }
            else{
                this.props.onSaveGameState(this.props.token, this.props.atBatState);
            }
            

            if(!this.props.gameOver){
                if(this.isGameOver()){
                    this.props.onSetGameOver(true);
                }
            }
        }
        
    }

    currentInning(){
        return Math.floor((this.props.currentInning / 2.0) + 1);
    }

    isInningTop(inning){
        let currentInning = (typeof inning !== 'undefined') ? inning : this.props.currentInning;
        // //console.log('Current Inning: ' + Math.floor((this.props.currentInning / 2.0) + 1));
        // //console.log('Top or bottom of inning? ' + (this.props.currentInning % 2 === 0 ? 'top' : 'bottom'));
        return currentInning % 2 === 0 ? true : false;
    }

    isGameOver = () =>{
        if(this.props.forceQuit){
            return true;
        }



        const inning = this.currentInning();
        const isTop = this.isInningTop();
        const homeScore = this.props.homeScore;
        const awayScore = this.props.awayScore;
        //if inning is this.props.totalInnings or greater and is in bottom and home team is winning, game is over
        if(inning >= this.props.totalInnings && !isTop && homeScore > awayScore){
            //this.props.onSetGameOver(true);
            return true;
        }
        //else if inning is greater than this.props.totalInnings and it is the end of the inning, if away team is winning game is over
        else if(inning > this.props.totalInnings && awayScore > homeScore){
            //this.props.onSetGameOver(true);
            return true;
        }
        return false;
    }

    toggleTeamHandler = () =>{
        const isHomeTeam = this.state.isHomeTeam;
        this.setState({
            isHomeTeam : ! isHomeTeam
        })
    }

    getPlayers = () =>{
        if(this.props.training){
            if(this.props.training.trainingType === 'Pitcher'){
                return [{...this.props.currentPitcher}];
            }
            else{
                return [{...this.props.currentHitter}];
            }
        }
        else{
            if(this.state.isHomeTeam){
                return this.props.homeTeamPlayers;
            }
            else{
                return this.props.awayTeamPlayers;
            }
        }
    }

    getPlayerNotes = (player) =>{
        let notes = [];
        let _pitchArray = [...this.props.pitchArray];
        for(let i=0;i<_pitchArray.length; ++i){
            const p = _pitchArray[i];
            if(p.pitcher.id === player.id){
                if(p.pitcherNotes && p.pitcherNotes !== ''){
                    let note = {
                        pitchID: p.id,
                        note: p.pitcherNotes,
                        type:'pitcher'
                    }
                    notes.push(note);
                }
            }
            if(p.batter.id === player.id){
                if(p.batterNotes && p.batterNotes !== ''){
                    let note = {
                        pitchID: p.id,
                        note: p.batterNotes,
                        type:'batter'
                    }
                    notes.push(note);
                }

            }
        }
        return notes;
    }


    onSelectPlayerHandler = (id) =>{
        //console.log('onSelectPlayerHandler ' + id);

        let player = null;
        let rootPlayer = null;
        if(this.props.training){
            if(this.props.training.trainingType === 'Pitcher'){
                player = {...this.props.currentPitcher};
            }
            else{
                player = {...this.props.currentHitter};
            }
            rootPlayer = this.props.rootHomeTeamPlayers.find(player => player.id === player.id);
        }
        else{
            if(this.state.isHomeTeam){
                player = this.props.homeTeamPlayers.find(player =>player.id === id);
                rootPlayer = this.props.rootHomeTeamPlayers.find(player => player.id === id);
            }else{
                player = this.props.awayTeamPlayers.find(player =>player.id === id);
                rootPlayer = this.props.rootAwayTeamPlayers.find(player => player.id === id);
            }
        }
        //console.log('currentPlayer', player);
        //let pitcherIsNull = player === null ? true : false;
        this.setState({
            currentPlayer: player,
            rootCurrentPlayer: rootPlayer
           
        })
    }

    saveScoutingReport = (text) =>{
        if(this.state.rootCurrentPlayer){

                let updatedPlayer = {...this.state.rootCurrentPlayer, notes: text};
                this.props.onUpdatePlayer(this.props.token, updatedPlayer);
            /*if(this.props.training){
                let players = [...this.props.rootHomeTeamPlayers];
                let index = players.findIndex(player =>player.id === this.state.rootCurrentPlayer.id);

                let updatedPlayer = {...players[index], scoutReport: text}
                players.splice(index,1,updatedPlayer);
                this.props.onSetHomeTeamPlayers(players);

                if(this.props.training.trainingType === 'Pitcher'){
                    this.props.onSetPitcher(updatedPlayer);
                }
                else{
                    this.props.onSetBatter(updatedPlayer);
                }
                
            }
            else{
                let players = [...this.props.awayTeamPlayers];
                if(this.state.isHomeTeam){
                    players = [...this.props.homeTeamPlayers]
                }
                let index = players.findIndex(player =>player.id === this.state.currentPlayer.id);

                let updatedPlayer = {...players[index], scoutReport: text}
                players.splice(index,1,updatedPlayer);

                if(this.state.isHomeTeam){
                    //console.log('update home team player array');
                    //console.log(players);
                    this.props.onSetHomeTeamPlayers(players);
                }
                else{
                    //console.log('update away team player array');
                    //console.log(players);
                    this.props.onSetAwayTeamPlayers(players);
                }
            }*/
        }
    }
    onShowSummaryHandler = () =>{
        if(this.props.training){
            this.props.history.push({
                pathname: '/training_scouting_report_summary/' + this.props.training.id
            });
        }
        else{
            this.props.history.push({
                pathname: '/scouting_report_summary/' + this.props.game.id
            });
        }

    }
    onShowGameHandler = () =>{
        this.props.history.push({
            pathname: '/game/' + this.props.game.id
        });
    }
    onShowTrainingHandler = () =>{
        this.props.history.push({
            pathname: '/training/' + this.props.training.id
        });
    }
    

    render(){
        let scoutingReportForm = null;

        let gameNotes = null;
        if(this.props.gameNotes){
            if(this.props.training){
            gameNotes = <>
                        <h3>Training Notes</h3>
                        <p>{this.props.gameNotes}</p>
                        </>
            }
            else{
                gameNotes = <>
                <h3>Game Notes</h3>
                <p>{this.props.gameNotes}</p>
                </>

            }
        }
        let playerNote = null;
        let notesJSX = null;
        if(this.state.currentPlayer){
            playerNote = this.state.currentPlayer.notes ? this.state.currentPlayer.notes : null;
            let notes = this.getPlayerNotes(this.state.currentPlayer);
            if(notes.length === 0){
                notesJSX = <div>There are no pitch notes for this player</div>
            }
            else{
                let noteItems = [];
                for(let i=0;i<notes.length;++i){
                    noteItems.push(<li key={i}>{notes[i].type} - {notes[i].note}</li>)
                }
                notesJSX = <ul style={{listStyleType: 'none'}}>{noteItems}</ul>
            }
            scoutingReportForm = <PlayerScoutingReportForm 
                                    player={this.state.rootCurrentPlayer} 
                                    onSubmit={this.saveScoutingReport}
                                    />
        }

        let playerNoteJSX = null;
        if(playerNote){
            playerNoteJSX = <><h3>Player Notes</h3><p>{playerNote}</p></>
        }

        let teamButtons = null;
        if(this.props.homeTeam && this.props.awayTeam){
            if(this.state.isHomeTeam){
                teamButtons = <div>
                                <Button btnType={ "Danger"}  btnSize="Small" clicked={this.toggleTeamHandler}>{this.props.homeTeam.name}</Button>
                                
                          </div>
            }
            else{
                teamButtons = <div>
                                
                                <Button btnType={"Danger"} btnSize="Small" clicked={this.toggleTeamHandler}>{this.props.awayTeam.name}</Button>
                          </div>
            }
           
        }
        let backToButton = <Button btnType="Danger" btnSize="Small" clicked={this.onShowGameHandler}>Back to Game</Button>

        if(!this.props.game){
            backToButton = <Button btnType="Danger" btnSize="Small" clicked={this.onShowTrainingHandler}>Back to Training</Button>
        }
        let summaryButton = <Button btnType="Danger" btnSize="Small" clicked={this.onShowSummaryHandler}>Scouting Report Summary</Button>
        

        let title = null;
        if(this.props.game){
            title = <div className={styles.TitleContainer}>
                      <h3 style={{display: 'inline'}}>{this.isGameOver() === true ? 'FINAL - ' : ''}{this.props.awayTeam.name} at {this.props.homeTeam.name}  -
                      { new Date(this.props.game.date).toLocaleDateString("en-US")}</h3>
                      {backToButton}
                      {summaryButton}
                    </div>
        }
        else{
            title = <div className={styles.TitleContainer}>
                {backToButton}
                      {summaryButton}
            </div>
        }

        
        if(this.props.training){
            backToButton = <Button btnType="Danger" clicked={this.onShowTrainingHandler}>Back to Training</Button>
        }

        let playerSelector = <PlayerSelector label="Current Player"
            containAllOption={false}
            players={this.getPlayers()} 
            currentPlayer={this.state.currentPlayer}
            onSubmit={(id)=>this.onSelectPlayerHandler(id)}/>

    return(
        <div className={styles.PlayerScoutingReport}>
            {title}
            
            {teamButtons}
            {playerSelector}
            {gameNotes}
            {/*playerNoteJSX*/}
            {/*notesJSX*/}
    {scoutingReportForm}
        
        </div>
    )
    }
}


const mapDispatchToProps = dispatch =>{
    return{
        onLoadGameState: (token, id)=>dispatch(
            ActionTypes.loadGameState(token, id)
        ),
        onLoadTrainingState: (token, id)=>dispatch(
            ActionTypes.loadTrainingState(token, id)
        ),
        onSetAwayTeamPlayers: (data) =>dispatch(
            ActionTypes.setAwayTeamPlayers(data)
        ),
        onSetHomeTeamPlayers: (data) =>dispatch(
            ActionTypes.setHomeTeamPlayers(data)
        ),
        onSaveGameState: (token, data)=>dispatch(
            ActionTypes.saveGameState(token, data)
        ),
        onSaveTrainingState: (token, data)=>dispatch(
            ActionTypes.saveTrainingState(token, data)
        ),
        onSetPitcher: (data)=>dispatch(
            ActionTypes.setCurrentPitcher(data)
        ),
        onSetBatter: (data)=>dispatch(
            ActionTypes.setCurrentBatter(data)
        ),
        onUpdatePlayer: (token, playerData)=>dispatch(
            ActionTypes.updatePlayer(token, playerData)
        )
    }
}


const mapStateToProps = state =>{
    return{

        game: state.atBat.game,
        gameNotes: state.atBat.gameNotes,
        homeTeam: state.atBat.homeTeam,
        awayTeam: state.atBat.awayTeam,
        rootHomeTeamPlayers: state.atBat.rootHomeTeamPlayers,
        rootAwayTeamPlayers: state.atBat.rootAwayTeamPlayers,
        homeTeamPlayers: state.atBat.homeTeamPlayers,
        awayTeamPlayers: state.atBat.awayTeamPlayers,
        currentInning: state.atBat.currentInning,
        currentPitcher: state.atBat.currentPitcher,
        currentHitter: state.atBat.currentHitter,
        battingRight: state.atBat.battingRight,
        atBatState: state.atBat,
        gameStateLoading: state.atBat.loading,
        token: state.auth.token,
        pitchArray: state.atBat.pitchArray,
        outs: state.atBat.outs,
        pitchNumber: state.atBat.pitchNumber,
        homeScore: state.atBat.homeScore,
        awayScore: state.atBat.awayScore,
        gameOver: state.atBat.gameOver,
        homeTeamLineup: state.atBat.homeTeamLineup,
        homeTeamCurrentPitcher: state.atBat.homeTeamCurrentPitcher,
        awayTeamLineup: state.atBat.awayTeamLineup,
        awayTeamCurrentPitcher: state.atBat.awayTeamCurrentPitcher,
        totalInnings: state.atBat.totalInnings,
        homeTeamCurrentBatterIndex: state.atBat.homeTeamCurrentBatterIndex,
        awayTeamCurrentBatterIndex: state.atBat.awayTeamCurrentBatterIndex,
        inningScores: state.atBat.inningScores,
        currentTopInningScore: state.atBat.currentTopInningScore,
        currentBottomInningScore: state.atBat.currentBottomInningScore,
        pitcherView: state.atBat.pitcherView,
        training: state.atBat.training,
        pitchType: state.atBat.pitchType
        
    }
}




export default connect(mapStateToProps, mapDispatchToProps) (PlayerScoutingReport);
