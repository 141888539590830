import Util from '../Util/Util';

class Analysis{

    static calculatZoneTableLocation = (LOC, pitches) =>{
        let ret = {
            P: 0,
            C: 0,
            SM: 0
        }
        //C: Contact % for that location
        //P: % Pitches by locations
        //SM: Swing/Miss % by that location
        let totalPitchesLOC = 0;
        let totalBallContacts = 0;
        let totalMisses = 0;
        let totalSwings = 0;
        for(let i=0;i<pitches.length; ++i){
            const p = pitches[i];
            if(p.pitchLocation === LOC){
                totalPitchesLOC += 1;
                if(p.result === "SWING AND MISS"){
                    totalMisses ++;
                }
                if(p.result !== "Take"){
                    totalSwings ++;
                }
                if(p.hitResult !== ""){
                    totalBallContacts ++;
                }
            }
        }
        if(pitches.length > 0){
            ret.P = totalPitchesLOC / parseFloat(pitches.length) * 100;
            ret.P = ret.P.toFixed(0);

            ret.C = totalBallContacts /  parseFloat(pitches.length) * 100;
            ret.C = ret.C.toFixed(0);

            ret.SM = totalMisses /  parseFloat(pitches.length) * 100;
            ret.SM = ret.SM.toFixed(0);

        }

        return ret;
    

    }
    static calculateZonesTableData = (pitches) =>{
        let ret = {
             HAP: 0,
             HAC: 0,
             HASM: 0,
             HIP: 0,
             HIC: 0,
             HISM: 0,
             LIP: 0,
             LIC: 0,
             LISM: 0,
             LAP: 0,
             LAC: 0,
             LASM: 0,
             MMP: 0,
             MMC: 0,
             MMSM: 0,
             
         }
         let r =  this.calculatZoneTableLocation("HA", pitches);
         ret.HAP = r.P;
         ret.HAC = r.C;
         ret.HASM = r.SM;
 
         r =  this.calculatZoneTableLocation("HI", pitches);
         ret.HIP = r.P;
         ret.HIC = r.C;
         ret.HISM = r.SM;
 
         r =  this.calculatZoneTableLocation("LI", pitches);
         ret.LIP = r.P;
         ret.LIC = r.C;
         ret.LISM = r.SM;
 
         r =  this.calculatZoneTableLocation("LA", pitches);
         ret.LAP = r.P;
         ret.LAC = r.C;
         ret.LASM = r.SM;
 
         r =  this.calculatZoneTableLocation("MM", pitches);
         ret.MMP = r.P;
         ret.MMC = r.C;
         ret.MMSM = r.SM;
 
         return ret;
 
 
 
     }

     static calculatePitchTypesTableData = (pitches) =>{
        let ret = {
             FBP: 0,
             FBC: 0,
             FBSM: 0,
             XCHP: 0,
             XCHC: 0,
             XCHSM: 0,
             CRVP: 0,
             CRVC: 0,
             CRVSM: 0,
             DCVP:0,
             DCVC:0,
             DCVSM:0,
             DRPP:0,
             DRPC:0,
             DRPSM:0,
             RISP:0,
             RISC:0,
             RISSM:0,
             SCRWP:0,
             SCRWC:0,
             SCRWSM:0
         }
         let r =  this.calculatPitchTypesTableLocation("FB", pitches);
         ret.FBP = r.P;
         ret.FBC = r.C;
         ret.FBSM = r.SM;
 
         r =  this.calculatPitchTypesTableLocation("XCHANGE", pitches);
         ret.XCHP = r.P;
         ret.XCHC = r.C;
         ret.XCHSM = r.SM;

         r =  this.calculatPitchTypesTableLocation("CURVE", pitches);
         ret.CRVP = r.P;
         ret.CRVC = r.C;
         ret.CRVSM = r.SM;

         r =  this.calculatPitchTypesTableLocation("DROPCV", pitches);
         ret.DCVP = r.P;
         ret.DCVC = r.C;
         ret.DCVSM = r.SM;

         r =  this.calculatPitchTypesTableLocation("DROP", pitches);
         ret.DRPP = r.P;
         ret.DRPC = r.C;
         ret.DRPSM = r.SM;

         r =  this.calculatPitchTypesTableLocation("RISE", pitches);
         ret.RISP = r.P;
         ret.RISC = r.C;
         ret.RISSM = r.SM;

         r =  this.calculatPitchTypesTableLocation("SCREW", pitches);
         ret.SCRWP = r.P;
         ret.SCRWC = r.C;
         ret.SCRWSM = r.SM;
 
         
 
         return ret;
 
 
 
     }

     static calculatPitchTypesTableLocation = (type, pitches) =>{
        let ret = {
            P: 0,
            C: 0,
            SM: 0
        }
        //C: Contact % for that location
        //P: % Pitches by locations
        //SM: Swing/Miss % by that location
        let totalPitchesType = 0;
        let totalBallContacts = 0;
        let totalMisses = 0;
        let totalSwings = 0;
        for(let i=0;i<pitches.length; ++i){
            const p = pitches[i];
            if(p.pitchType === type){
                totalPitchesType += 1;
                if(p.result === "SWING AND MISS"){
                    totalMisses ++;
                }
                if(p.result !== "Take"){
                    totalSwings ++;
                }
                if(p.hitResult !== ""){
                    totalBallContacts ++;
                }
            }
        }
        if(pitches.length > 0){
            ret.P = totalPitchesType / parseFloat(pitches.length) * 100;
            ret.P = ret.P.toFixed(0);

            ret.C = totalBallContacts /  parseFloat(pitches.length) * 100;
            ret.C = ret.C.toFixed(0);

            ret.SM = totalMisses /  parseFloat(pitches.length) * 100;
            ret.SM = ret.SM.toFixed(0);

        }

        return ret;
    

    }
}

export default Analysis;