import React from 'react';
import styles from './BatterIcon.module.css';

const BatterIcon = (props) =>{

    
    /*
    style={
            top: '{props.top}',
            left: {props.left},
            width: {props.width},
            height: {props.height},
            backgroundColor: {props.color}
        }
    */

    let classname = styles.BatterViewIcon;
    
    if(!props.pitcherView){
        if(props.battingSide === "left"){
            classname = styles.BatterIconLeft;
        }
        else{
            classname = styles.BatterIcon;
        }
    }
    else{
        if(props.battingSide !== "left"){
            classname = styles.PitcherIconLeft;
        }
        else{
            classname = styles.PitcherIcon;
        }
    }

    return(
        <div className={classname} style={
            {top: props.top,
            left: props.left,
            width: 90,
            height: 225,
            /*backgroundColor: props.color,
            borderTop: props.borderTop,
            borderRight: props.borderRight,
            borderBottom: props.borderBottom,
            borderLeft: props.borderLeft*/}
           
            
        }></div>
    )
}

export default BatterIcon;