import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';


import Util from '../../classes/Util/Util'; //../Util/Util';









const initialState = {
    atBatID: null,
    gameID: null,
    game: null,
    pitchType: 'Softball',
    homeTeam: null,
    awayTeam: null,
    homeTeamPlayers: [],
    awayTeamPlayers: [],
    pitchNumber: 0,
    currentHitter: null,
    currentPitcher: null,
    battingRight: true,
    loading: false,
    error: false,
    message: null,
    currentInning: 0,
    pitchArray: [],
    outs: 0,
    baseRunners: [],
    inningScores: [],
    homeScore: 0,
    awayScore: 0,
    gameOver: false,
    homeTeamLineup: [],
    homeTeamCurrentPitcher: null,
    awayTeamLineup: [],
    awayTeamCurrentPitcher: null,
    totalInnings: 7,
    homeTeamCurrentBatterIndex: 0,
    awayTeamCurrentBatterIndex: 0,
    currentTopInningScore: 0,
    currentBottomInningScore: 0,
    forceQuit: false,
    forceQuitNotes: null,
    homeSubstitutions: [],
    awaySubstitutions: [],
    pitcherView: true,
    firstBaseRunner: null,
    secondBaseRunner: null,
    thirdBaseRunner: null,
    gameNotes: null,
    isTraining: false,
    trainingID: null,
    training: null,
    awayTeamFlex: null,
    homeTeamFlex : null,
    gameStateArray : [],
    currentGameStateIndex: 0,
    wasPopped: false,
    gamestateid: '',
    rootHomePlayers: [],
    rootAwayPlayers: []
    
};

const setHomeTeam = (state, action) =>{
    return updateObject(state, {
        homeTeam: action.data,
        gamestateid: Util.newID()
    })
}

const setAwayTeam = (state, action) =>{
    return updateObject(state, {
        awayTeam: action.data,
        gamestateid: Util.newID()
    })
}

const setGameNotes = (state, action) =>{
    return updateObject(state, {
        gameNotes: action.data,
        gamestateid: Util.newID()
    })
}

const setFirstBaseRunner = (state, action) =>{
    return updateObject(state, {
        firstBaseRunner: action.data,
        gamestateid: Util.newID()
    });

}
const setSecondBaseRunner = (state, action) =>{
    return updateObject(state, {
        secondBaseRunner: action.data,
        gamestateid: Util.newID()
    });

}

const setThirdBaseRunner = (state, action) =>{
    return updateObject(state, {
        thirdBaseRunner: action.data,
        gamestateid: Util.newID()
    });

}

const setHomeTeamPlayers = (state, action) =>{
    return updateObject(state, {
        homeTeamPlayers: action.data,
        gamestateid: Util.newID()
    })
}

const setAwayTeamPlayers = (state, action) =>{
    return updateObject(state, {
        awayTeamPlayers: action.data,
        gamestateid: Util.newID()
    })
}

const updatePitcherView = (state, action) =>{
    return updateObject(state, {
        pitcherView: action.data,
        gamestateid: Util.newID()
    });
}

const updateHomeSubstitutions = (state, action) =>{
    return updateObject(state, {
        homeSubstitutions: action.data,
        gamestateid: Util.newID()
    })
}

const updateAwaySubstitutions = (state, action) =>{
    return updateObject(state, {
        awaySubstitutions: action.data,
        gamestateid: Util.newID()
    })
}



const setForceQuit = (state, action) =>{
    return updateObject(state, {
        forceQuit: action.forceQuit,
        forceQuitNotes: action.forceQuitNotes,
        gamestateid: Util.newID()
    });
}



const clearGameState = (state, action) =>{
    return updateObject(state,initialState);
}

const initializeGameStart = (state, action) =>{
    return updateObject(state, {loading: true, error:false, message:null});
}

const initializeGameFail = (state, action) =>{
    return updateObject(state, {loading: false, error:false, message:null});
}

const initializeGameSuccess = (state, action) =>{
    return updateObject(state, {
        loading: false, 
        error:false, 
        message:null,
        gameID: action.game.id,
        game: action.game,
        homeTeam: action.game.homeTeam,
        awayTeam: action.game.awayTeam,
        homeTeamPlayers: action.homePlayers,
        awayTeamPlayers: action.awayPlayers,
        isTraining: false
    });
}

//////
const initializeTrainingStart = (state, action) =>{
    return updateObject(state, {loading: true, error:false, message:null});
}

const initializeTrainingFail = (state, action) =>{
    return updateObject(state, {loading: false, error:false, message:null});
}

const initializeTrainingSuccess = (state, action) =>{
    return updateObject(state, {
        loading: false, 
        error:false, 
        message:null,
        trainingID: action.training.id,
        training: action.training,
        homeTeam: action.training.team,
        homeTeamPlayers: action.players,
        isTraining: true
    });
}
//////




const updateBaseRunners = (state, action) =>{
    return updateObject(state, {
        baseRunners: action.baseRunners,
        gamestateid: Util.newID()
    })
}

const setOuts = (state, action) =>{
    return updateObject(state, {
        outs: action.outs,
        gamestateid: Util.newID()
    });
}

const setGameOver = (state, action) =>{
    return updateObject(state, {
        gameOver: action.data,
        gamestateid: Util.newID()
    });
}

const setCurrentInning = (state, action) =>{
    return updateObject(state, {
        currentInning: action.inning,
        gamestateid: Util.newID()
    });
}


const setTotalInnings = (state, action) =>{
    return updateObject(state, {
        totalInnings: action.data,
        gamestateid: Util.newID()
    });
}

const setHomeTeamLineup = (state, action) =>{
    return updateObject(state, {
        homeTeamLineup: action.data,
        gamestateid: Util.newID()
    });
}

const setHomeTeamFlex = (state, action) =>{
    return updateObject(state, {
        homeTeamFlex: action.data,
        gamestateid: Util.newID()
    });
}

const setAwayTeamFlex = (state, action) =>{
    return updateObject(state, {
        awayTeamFlex: action.data,
        gamestateid: Util.newID()
    });
}

const setAwayTeamCurrentBatterIndex = (state, action) =>{
    return updateObject(state, {
        awayTeamCurrentBatterIndex: action.data,
        gamestateid: Util.newID()
    });
}

const setHomeTeamCurrentBatterIndex = (state, action) =>{
    return updateObject(state, {
        homeTeamCurrentBatterIndex: action.data,
        gamestateid: Util.newID()
    });
}

const setAwayTeamLineup = (state, action) =>{
    return updateObject(state, {
        awayTeamLineup: action.data,
        gamestateid: Util.newID()
    });
}

const setHomeTeamCurrentPitcher = (state, action) =>{
    return updateObject(state, {
        homeTeamCurrentPitcher: action.data,
        gamestateid: Util.newID()
    });
}

const setAwayTeamCurrentPitcher = (state, action) =>{
    return updateObject(state, {
        awayTeamCurrentPitcher: action.data,
        gamestateid: Util.newID()
    });
}


const setCurrentPitcher = (state, action) =>{
    ////console.log('setCurrentPitcher', action.pitcher);
    return updateObject(state, {
        currentPitcher: action.pitcher,
        gamestateid: Util.newID()
    });
}

const setCurrentBatter = (state, action) =>{
    ////console.log('setCurrentBatter', action.batter);
    return updateObject(state, {
        currentHitter: action.batter,
        gamestateid: Util.newID()
    });
}

const setBattingRight = (state, action) =>{
    return updateObject(state, {
        battingRight: action.data,
        gamestateid: Util.newID()
    });
}

const setPitchNumber = (state, action) =>{
    return updateObject(state, {
        pitchNumber: action.data,
        gamestateid: Util.newID()
    });
}

const setHomeScore = (state, action) =>{
    return updateObject(state, {
        homeScore: action.data,
        gamestateid: Util.newID()
    });
}

const setAwayScore = (state, action) =>{
    return updateObject(state, {
        awayScore: action.data,
        gamestateid: Util.newID()
    });
}

const setCurrentTopInningScore = (state, action)=>{
    return updateObject(state, {
        currentTopInningScore: action.data,
        gamestateid: Util.newID()
    })
}

const setCurrentBottomInningScore = (state, action)=>{
    return updateObject(state, {
        currentBottomInningScore: action.data,
        gamestateid: Util.newID()
    })
}

const createGameStateStart = (state, action) =>{
    return updateObject(state, {
        loading:true
    })
}

const createGameStateSuccess = (state, action) =>{
    return updateObject(state, {
        atBatID: action.id,
        gameID: action.data.gameID,
        game: action.data.game,
        pitchType: typeof action.data.game.pitchType === 'undefined' ? 'Softball' : action.data.game.pitchType,
        homeTeam: action.data.homeTeam,
        awayTeam: action.data.awayTeam,
        homeTeamPlayers: action.data.homeTeamPlayers,
        awayTeamPlayers: action.data.awayTeamPlayers,
        pitchNumber: action.data.pitchNumber,
        currentHitter: action.data.currentHitter,
        currentPitcher: action.data.currentPitcher,
        battingRight: action.data.battingRight,
        loading: action.data.loading,
        error: action.data.error,
        message: action.data.message,
        currentInning: action.data.currentInning
    });
}

const loadGameStateStart = (state, action) =>{
    return updateObject(state, {loading: true, error:false, message:null});
}
const loadGameStateFail = (state, action) =>{
    return updateObject(state, {loading: false, error:true, message:null});
}

const loadGameStateSuccess = (state, action) =>{
    return updateObject(state, {
        rootHomeTeamPlayers: action.homePlayers,
        rootAwayTeamPlayers: action.awayPlayers,
        atBatID: action.data.atBatID,
        gameID: action.data.gameID,
        game: action.data.game,
        userID: action.data.game.userID,
        homeTeam: action.data.homeTeam,
        awayTeam: action.data.awayTeam,
        homeTeamPlayers: action.data.homeTeamPlayers,
        awayTeamPlayers: action.data.awayTeamPlayers,
        pitchNumber: action.data.pitchNumber,
        currentHitter: (typeof action.data.currentHitter === 'undefined' ||
                        action.data.currentHitter === null)? null : action.data.currentHitter,
        currentPitcher: (typeof action.data.currentPitcher === 'undefined' ||
                        action.data.currentPitcher === null)? null : action.data.currentPitcher,
        battingRight: action.data.battingRight,
        
        loading: false,
        error: action.data.error,
        message: action.data.message,
        currentInning: action.data.currentInning,
        pitchArray: typeof action.data.pitchArray === 'undefined'? [] : action.data.pitchArray,
        outs: action.data.outs,
        baseRunners: typeof action.data.baseRunners === 'undefined' ? [] : action.data.baseRunners,
        homeScore: typeof action.data.homeScore === 'undefined'? 0 : action.data.homeScore,
        awayScore: typeof action.data.awayScore === 'undefined'? 0 : action.data.awayScore,
        gameOver: action.data.gameOver,
        homeTeamLineup: action.data.homeTeamLineup === undefined ? [] : action.data.homeTeamLineup,
        
        homeTeamCurrentPitcher: typeof action.data.homeTeamCurrentPitcher === 'undefined' ? null : action.data.homeTeamCurrentPitcher,
        awayTeamLineup: action.data.awayTeamLineup === undefined ? [] : action.data.awayTeamLineup,
        awayTeamCurrentPitcher: typeof action.data.awayTeamCurrentPitcher === 'undefined' ? null : action.data.awayTeamCurrentPitcher,
        totalInnings: action.data.totalInnings === undefined ? 7 : action.data.totalInnings,
        homeTeamCurrentBatterIndex: typeof action.data.homeTeamCurrentBatterIndex === 'undefined' ? 0: action.data.homeTeamCurrentBatterIndex,
        awayTeamCurrentBatterIndex: typeof action.data.awayTeamCurrentBatterIndex === 'undefined' ? 0: action.data.awayTeamCurrentBatterIndex,
        inningScores: typeof action.data.inningScores === 'undefined' ? [] : action.data.inningScores,
        currentTopInningScore: typeof action.data.currentTopInningScore === 'undefined' ? 0 : action.data.currentTopInningScore,
        currentBottomInningScore: typeof action.data.currentBottomInningScore === 'undefined' ? 0 : action.data.currentBottomInningScore,
        forceQuit: typeof action.data.forceQuit === 'undefined' ? false : action.data.forceQuit,
        forceQuitNotes: typeof action.data.forceQuitNotes === 'undefined' ? null : action.data.forceQuitNotes,
        homeSubstitutions: typeof action.data.homeSubstitutions === 'undefined' ? [] : action.data.homeSubstitutions,
        awaySubstitutions: typeof action.data.awaySubstitutions === 'undefined' ? [] : action.data.awaySubstitutions,
        pitcherView: typeof action.data.pitcherView === 'undefined' ? true : action.data.pitcherView,
        firstBaseRunner: typeof action.data.firstBaseRunner === 'undefined' ? null : action.data.firstBaseRunner,
        secondBaseRunner: typeof action.data.secondBaseRunner === 'undefined' ? null : action.data.secondBaseRunner,
        thirdBaseRunner: typeof action.data.thirdBaseRunner === 'undefined' ? null : action.data.thirdBaseRunner,
        gameNotes: typeof action.data.gameNotes === 'undefined' ? null : action.data.gameNotes,
        isTraining: typeof action.data.isTraining === 'undefined' ? false : action.data.isTraining,
        trainingID: typeof action.data.trainingID === 'undefined' ? null : action.data.trainingID,
        training: typeof action.data.training === 'undefined' ? null : action.data.training,
        pitchType: typeof action.data.pitchType === 'undefined' ? 'Softball' : action.data.pitchType,
        awayTeamFlex: typeof action.data.awayTeamFlex === 'undefined' ? null : action.data.awayTeamFlex,
        homeTeamFlex: typeof action.data.homeTeamFlex === 'undefined' ? null : action.data.homeTeamFlex,
        gamestateid: Util.newID()
    });
}
const setGameState = (state, action) =>{
    return updateObject(state, {
        atBatID: action.data.atBatID,
        gameID: action.data.gameID,
        game: action.data.game,
        userID: typeof action.data.game === 'undefined' ? null : action.data.game.userID,
        homeTeam: action.data.homeTeam,
        awayTeam: action.data.awayTeam,
        homeTeamPlayers: action.data.homeTeamPlayers,
        awayTeamPlayers: action.data.awayTeamPlayers,
        pitchNumber: action.data.pitchNumber,
        currentHitter: (typeof action.data.currentHitter === 'undefined' ||
                        action.data.currentHitter === null)? null : action.data.currentHitter,
        currentPitcher: (typeof action.data.currentPitcher === 'undefined' ||
                        action.data.currentPitcher === null)? null : action.data.currentPitcher,
        battingRight: action.data.battingRight,
        
        loading: false,
        error: action.data.error,
        message: action.data.message,
        currentInning: action.data.currentInning,
        pitchArray: typeof action.data.pitchArray === 'undefined'? [] : action.data.pitchArray,
        outs: action.data.outs,
        baseRunners: typeof action.data.baseRunners === 'undefined' ? [] : action.data.baseRunners,
        homeScore: typeof action.data.homeScore === 'undefined'? 0 : action.data.homeScore,
        awayScore: typeof action.data.awayScore === 'undefined'? 0 : action.data.awayScore,
        gameOver: action.data.gameOver,
        homeTeamLineup: action.data.homeTeamLineup === undefined ? [] : action.data.homeTeamLineup,
        
        homeTeamCurrentPitcher: typeof action.data.homeTeamCurrentPitcher === 'undefined' ? null : action.data.homeTeamCurrentPitcher,
        awayTeamLineup: action.data.awayTeamLineup === undefined ? [] : action.data.awayTeamLineup,
        awayTeamCurrentPitcher: typeof action.data.awayTeamCurrentPitcher === 'undefined' ? null : action.data.awayTeamCurrentPitcher,
        totalInnings: action.data.totalInnings === undefined ? 7 : action.data.totalInnings,
        homeTeamCurrentBatterIndex: typeof action.data.homeTeamCurrentBatterIndex === 'undefined' ? 0: action.data.homeTeamCurrentBatterIndex,
        awayTeamCurrentBatterIndex: typeof action.data.awayTeamCurrentBatterIndex === 'undefined' ? 0: action.data.awayTeamCurrentBatterIndex,
        inningScores: typeof action.data.inningScores === 'undefined' ? [] : action.data.inningScores,
        currentTopInningScore: typeof action.data.currentTopInningScore === 'undefined' ? 0 : action.data.currentTopInningScore,
        currentBottomInningScore: typeof action.data.currentBottomInningScore === 'undefined' ? 0 : action.data.currentBottomInningScore,
        forceQuit: typeof action.data.forceQuit === 'undefined' ? false : action.data.forceQuit,
        forceQuitNotes: typeof action.data.forceQuitNotes === 'undefined' ? null : action.data.forceQuitNotes,
        homeSubstitutions: typeof action.data.homeSubstitutions === 'undefined' ? [] : action.data.homeSubstitutions,
        awaySubstitutions: typeof action.data.awaySubstitutions === 'undefined' ? [] : action.data.awaySubstitutions,
        pitcherView: typeof action.data.pitcherView === 'undefined' ? true : action.data.pitcherView,
        firstBaseRunner: typeof action.data.firstBaseRunner === 'undefined' ? null : action.data.firstBaseRunner,
        secondBaseRunner: typeof action.data.secondBaseRunner === 'undefined' ? null : action.data.secondBaseRunner,
        thirdBaseRunner: typeof action.data.thirdBaseRunner === 'undefined' ? null : action.data.thirdBaseRunner,
        gameNotes: typeof action.data.gameNotes === 'undefined' ? null : action.data.gameNotes,
        isTraining: typeof action.data.isTraining === 'undefined' ? false : action.data.isTraining,
        trainingID: typeof action.data.trainingID === 'undefined' ? null : action.data.trainingID,
        training: typeof action.data.training === 'undefined' ? null : action.data.training,
        pitchType: typeof action.data.pitchType === 'undefined' ? 'Softball' : action.data.pitchType,
        awayTeamFlex: typeof action.data.awayTeamFlex === 'undefined' ? null : action.data.awayTeamFlex,
        homeTeamFlex: typeof action.data.homeTeamFlex === 'undefined' ? null : action.data.homeTeamFlex,
        gamestateid: typeof action.data.gamestateid === 'undefined' ? Util.newID() : action.data.gamestateid
        
    });
}

///////////////

const createTrainingStateSuccess = (state, action) =>{
    return updateObject(state, {
        atBatID: action.id,
        gameID: action.data.gameID,
        game: action.data.game,
        pitchType: typeof action.data.training.pitchType === 'undefined' ? 'Softball' : action.data.training.pitchType,
        isTraining: typeof action.data.isTraining === 'undefined' ? false : action.data.isTraining,
        trainingID: typeof action.data.trainingID === 'undefined' ? null : action.data.trainingID,
        training: typeof action.data.training === 'undefined' ? null : action.data.training,
        homeTeam: action.data.homeTeam,
        awayTeam: action.data.awayTeam,
        homeTeamPlayers: action.data.homeTeamPlayers,
        awayTeamPlayers: action.data.awayTeamPlayers,
        pitchNumber: action.data.pitchNumber,
        currentHitter: action.data.currentHitter,
        currentPitcher: action.data.currentPitcher,
        battingRight: action.data.battingRight,
        loading: action.data.loading,
        error: action.data.error,
        message: action.data.message,
        currentInning: action.data.currentInning
    });
}

const loadTrainingStateStart = (state, action) =>{
    return updateObject(state, {loading: true, error:false, message:null});
}
const loadTrainingStateFail = (state, action) =>{
    return updateObject(state, {loading: false, error:true, message:null});
}



const loadTrainingStateSuccess = (state, action) =>{
    //console.log('loadTrainingStateSuccess in reducer ', action.data);
    return updateObject(state, {
        rootHomeTeamPlayers: action.homePlayers,
        atBatID: action.data.atBatID,
        gameID: action.data.gameID,
        game: action.data.game,
        userID: action.data.training.userID,
        homeTeam: action.data.homeTeam,
        awayTeam: action.data.awayTeam,
        homeTeamPlayers: action.data.homeTeamPlayers,
        awayTeamPlayers: action.data.awayTeamPlayers,
        pitchNumber: action.data.pitchNumber,
        currentHitter: (typeof action.data.currentHitter === 'undefined' ||
                        action.data.currentHitter === null)? null : action.data.currentHitter,
        currentPitcher: (typeof action.data.currentPitcher === 'undefined' ||
                        action.data.currentPitcher === null)? null : action.data.currentPitcher,
        battingRight: action.data.battingRight,
        
        loading: false,
        error: action.data.error,
        message: action.data.message,
        currentInning: action.data.currentInning,
        pitchArray: typeof action.data.pitchArray === 'undefined'? [] : action.data.pitchArray,
        outs: action.data.outs,
        baseRunners: typeof action.data.baseRunners === 'undefined' ? [] : action.data.baseRunners,
        homeScore: typeof action.data.homeScore === 'undefined'? 0 : action.data.homeScore,
        awayScore: typeof action.data.awayScore === 'undefined'? 0 : action.data.awayScore,
        gameOver: action.data.gameOver,
        homeTeamLineup: action.data.homeTeamLineup === undefined ? [] : action.data.homeTeamLineup,
        homeTeamCurrentPitcher: typeof action.data.homeTeamCurrentPitcher === 'undefined' ? null : action.data.homeTeamCurrentPitcher,
        awayTeamLineup: action.data.awayTeamLineup === undefined ? [] : action.data.awayTeamLineup,
        awayTeamCurrentPitcher: typeof action.data.awayTeamCurrentPitcher === 'undefined' ? null : action.data.awayTeamCurrentPitcher,
        totalInnings: action.data.totalInnings === undefined ? 7 : action.data.totalInnings,
        homeTeamCurrentBatterIndex: typeof action.data.homeTeamCurrentBatterIndex === 'undefined' ? 0: action.data.homeTeamCurrentBatterIndex,
        awayTeamCurrentBatterIndex: typeof action.data.awayTeamCurrentBatterIndex === 'undefined' ? 0: action.data.awayTeamCurrentBatterIndex,
        inningScores: typeof action.data.inningScores === 'undefined' ? [] : action.data.inningScores,
        currentTopInningScore: typeof action.data.currentTopInningScore === 'undefined' ? 0 : action.data.currentTopInningScore,
        currentBottomInningScore: typeof action.data.currentBottomInningScore === 'undefined' ? 0 : action.data.currentBottomInningScore,
        forceQuit: typeof action.data.forceQuit === 'undefined' ? false : action.data.forceQuit,
        forceQuitNotes: typeof action.data.forceQuitNotes === 'undefined' ? null : action.data.forceQuitNotes,
        homeSubstitutions: typeof action.data.homeSubstitutions === 'undefined' ? [] : action.data.homeSubstitutions,
        awaySubstitutions: typeof action.data.awaySubstitutions === 'undefined' ? [] : action.data.awaySubstitutions,
        pitcherView: typeof action.data.pitcherView === 'undefined' ? true : action.data.pitcherView,
        firstBaseRunner: typeof action.data.firstBaseRunner === 'undefined' ? null : action.data.firstBaseRunner,
        secondBaseRunner: typeof action.data.secondBaseRunner === 'undefined' ? null : action.data.secondBaseRunner,
        thirdBaseRunner: typeof action.data.thirdBaseRunner === 'undefined' ? null : action.data.thirdBaseRunner,
        gameNotes: typeof action.data.gameNotes === 'undefined' ? null : action.data.gameNotes,
        isTraining: typeof action.data.isTraining === 'undefined' ? false : action.data.isTraining,
        trainingID: typeof action.data.trainingID === 'undefined' ? null : action.data.trainingID,
        training: typeof action.data.training === 'undefined' ? null : action.data.training,
        pitchType: typeof action.data.pitchType === 'undefined' ? 'Softball' : action.data.pitchType
    });
}


const updateTrainingStatePitchArray = (state, action) =>{
    return updateObject(state,{
        pitchArray: action.data,
        gamestateid: Util.newID()
    });
}
///////////////



const updateGameStatePitchArray = (state, action) =>{
    return updateObject(state,{
        pitchArray: action.data,
        gamestateid: Util.newID()
    });
}

const setInningScores = (state, action) =>{
    return updateObject(state,{
        inningScores: action.data,
        gamestateid: Util.newID()
    })
}

const clearPop = (state, action)=>{
    return updateObject(state, {wasPopped: false});
}

const setPop = (state, action)=>{
    return updateObject(state, {wasPopped: true});
}

const reducer = (state = initialState, action) =>{

    switch(action.type){
        case actionTypes.SET_HOME_TEAM:{
            return setHomeTeam(state,action);
        }
        case actionTypes.SET_AWAY_TEAM:{
            return setAwayTeam(state,action);
        }
        case actionTypes.INITIALIZE_GAME_START:{
            return initializeGameStart(state, action);
        }
        case actionTypes.INITIALIZE_GAME_FAIL:{
            return initializeGameFail(state, action);
        }
        case actionTypes.INITIALIZE_GAME_SUCCESS:{
            return initializeGameSuccess(state, action);
        }
        case actionTypes.INITIALIZE_TRAINING_START:{
            return initializeTrainingStart(state, action);
        }
        case actionTypes.INITIALIZE_TRAINING_FAIL:{
            return initializeTrainingFail(state, action);
        }
        case actionTypes.INITIALIZE_TRAINING_SUCCESS:{
            return initializeTrainingSuccess(state, action);
        }
        case actionTypes.SET_CURRENT_PITCHER:{
            return setCurrentPitcher(state,  action);
        }
        case actionTypes.SET_CURRENT_BATTER:{
            return setCurrentBatter(state,  action);
        }
        case actionTypes.SET_BATTING_RIGHT:{
            return setBattingRight(state, action);
        }

        case actionTypes.CREATE_GAME_STATE_START:{
            return createGameStateStart(state,action);
        }
        case actionTypes.CREATE_GAME_STATE_SUCCESS:{
            return createGameStateSuccess(state, action);
        }
        case actionTypes.LOAD_GAME_STATE_SUCCESS:{
            
            return loadGameStateSuccess(state, action);
        }
        case actionTypes.LOAD_GAME_STATE_START:{
            return loadGameStateStart(state,action);
        }
        case actionTypes.LOAD_GAME_STATE_FAIL:{
            return loadGameStateFail(state,action);
        }
        case actionTypes.UPDATE_GAME_STATE_PITCH_ARRAY:{
            return updateGameStatePitchArray(state,action);
        }
        case actionTypes.SET_GAME_STATE:{
            return setGameState(state,action);
        }

        /////
        case actionTypes.CREATE_TRAINING_STATE_SUCCESS:{
            return createTrainingStateSuccess(state, action);
        }
        case actionTypes.LOAD_TRAINING_STATE_SUCCESS:{
            //console.log('LOAD_TRAINING_STATE_SUCCESS in atbat switch');
            return loadTrainingStateSuccess(state, action);
        }
        case actionTypes.LOAD_TRAINING_STATE_START:{
            return loadTrainingStateStart(state,action);
        }
        case actionTypes.LOAD_TRAINING_STATE_FAIL:{
            return loadTrainingStateFail(state,action);
        }
        case actionTypes.UPDATE_TRAINING_STATE_PITCH_ARRAY:{
            return updateTrainingStatePitchArray(state,action);
        }
        /////
        case actionTypes.CLEAR_POP:{
            return clearPop(state,action)
        }
        case actionTypes.SET_POP:{
            return setPop(state,action)
        }



        case actionTypes.SET_OUTS:{
            return setOuts(state,action);
        }
        case actionTypes.SET_CURRENT_INNING:{
            return setCurrentInning(state,action);
        }
        case actionTypes.SET_PITCH_NUMBER:{
            return setPitchNumber(state,action);
        }
        case actionTypes.UPDATE_BASE_RUNNERS:{
            return updateBaseRunners(state,action);
        }
        case actionTypes.SET_HOME_SCORE:{
            return setHomeScore(state,action);
        }
        case actionTypes.SET_AWAY_SCORE:{
            return setAwayScore(state,action);
        }
        case actionTypes.SET_GAME_OVER:{
            return setGameOver(state,action);
        }
        case actionTypes.SET_AWAY_TEAM_CURRENT_PITCHER:{
            return setAwayTeamCurrentPitcher(state,action);
        }
        case actionTypes.SET_HOME_TEAM_CURRENT_PITCHER:{
            return setHomeTeamCurrentPitcher(state,action);
        }
        case actionTypes.SET_AWAY_TEAM_LINEUP:{
            return setAwayTeamLineup(state,action);
        }
        case actionTypes.SET_HOME_TEAM_LINEUP:{
            return setHomeTeamLineup(state,action);
        }
        case actionTypes.SET_HOME_TEAM_FLEX:{
            return setHomeTeamFlex(state,action);
        }
        case actionTypes.SET_AWAY_TEAM_FLEX:{
            return setAwayTeamFlex(state,action);
        }
        case actionTypes.SET_TOTAL_INNINGS:{
            return setTotalInnings(state, action);
        }
        case actionTypes.SET_AWAY_TEAM_CURRENT_BATTER_INDEX:{
            return setAwayTeamCurrentBatterIndex(state,action);
        }
        case actionTypes.SET_HOME_TEAM_CURRENT_BATTER_INDEX:{
            return setHomeTeamCurrentBatterIndex(state,action);
        }
        case actionTypes.SET_INNING_SCORES:{
            return setInningScores(state, action);
        }
        case actionTypes.SET_CURRENT_BOTTOM_INNING_SCORE:{
            return setCurrentBottomInningScore(state,action);
        }
        case actionTypes.SET_CURRENT_TOP_INNING_SCORE:{
            return setCurrentTopInningScore(state, action);
        }
        case actionTypes.CLEAR_GAME_STATE:{
            return clearGameState(state, action);
        }
        case actionTypes.SET_FORCE_QUIT:{
            return setForceQuit(state,action);
        }
        case actionTypes.UPDATE_HOME_SUBSTITUTIONS:{
            return updateHomeSubstitutions(state,action);
        }
        case actionTypes.UPDATE_AWAY_SUBSTITUTIONS:{
            return updateAwaySubstitutions(state,action);
        }
        case actionTypes.UPDATE_PITCHER_VIEW:{
            return updatePitcherView(state,action);
        }
        case actionTypes.SET_AWAY_TEAM_PLAYERS:{
            return setAwayTeamPlayers(state, action);
        }
        case actionTypes.SET_HOME_TEAM_PLAYERS:{
            return setHomeTeamPlayers(state, action);
        }
        case actionTypes.SET_FIRST_BASE_RUNNER:{
            return setFirstBaseRunner(state,action);
        }
        case actionTypes.SET_SECOND_BASE_RUNNER:{
            return setSecondBaseRunner(state,action);
        }
        case actionTypes.SET_THIRD_BASE_RUNNER:{
            return setThirdBaseRunner(state,action);
        }
        case actionTypes.SET_GAME_NOTES:{
            return setGameNotes(state,action);
        }
        default:{
            return state;
        }
    }
};

export default reducer;