import React, {Component} from 'react';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import Spinner from '../../../components/UI/Spinner/Spinner';
import classes from './ResetPassword.module.css';
import { connect } from 'react-redux';
import * as ActionTypes from '../../../store/actions/index';
import {Redirect} from 'react-router-dom';

class ResetPassword extends Component{
    state = {
        controls: {
            currentPassword: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Current Password'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6
                },
                valid: false,
                touched: false
            },
            password: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'New Password'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6
                },
                valid: false,
                touched: false
            }
        },
        
        formIsValid: false,
        isSignUp: false
    }
    componentDidMount(){
        
    }

    componentDidUpdate(prevProps, prevState) {


    }

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }
        
        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if(rules.isUnique) {
            isValid = true;
            for(let i=0;i<this.props.orgs.length; ++i){
                if(this.props.orgs[i].name === value.trim()){
                    isValid = false;
                }
            }
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        if (rules.isNumeric) {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }

    submitHandler = (event) =>{
        event.preventDefault();
        //console.log('current password ' + this.state.controls.currentPassword.value);
        //console.log('calling reset password ' + this.props.auser.email +' '+ this.state.controls.password.value +' ' + this.props.token);
        //this.props.onAuth(this.props.token, this.state.controls.password.value);
        this.props.onResetPassword(this.props.token, this.state.controls.currentPassword.value, this.state.controls.password.value, this.props.auser.email)
    }


    inputChangedHandler = (event, inputIdentifier) =>{
        //console.log('inputChangeHandler '+ inputIdentifier);
        let updatedForm = {
            ...this.state.controls
        }
        let newOrgFormElement = null;
        if(this.state.isSignUp){
            updatedForm = {
                ...this.state.regControls
            }
            if(inputIdentifier === 'organization'){
                newOrgFormElement = {...updatedForm['newOrg']};
                if(event.target.value === 'new'){
                    newOrgFormElement.show = true;
                }
                else{
                    newOrgFormElement.show = false;
                }
            }
        }
        const updatedFormElement = {...updatedForm[inputIdentifier]};
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        //////console.log(updatedFormElement.valid, updatedFormElement.validation ? true : false);
        updatedForm[inputIdentifier] = updatedFormElement;

        if(newOrgFormElement){
            updatedForm['newOrg'] = newOrgFormElement;
        }

        let formIsValid = true;
        for(let inputIds in updatedForm){
            formIsValid = updatedForm[inputIds].valid && formIsValid;
        }
        
        this.setState({controls: updatedForm, formIsValid: formIsValid});
        
    }
    
    render(){
        const formElementsArray = [];
        
        for(let key in this.state.controls){
            formElementsArray.push({
                id: key,
                config: this.state.controls[key],
                validation: this.state.controls[key].validation,
                valid: this.state.controls[key].valid,
                touched: this.state.controls[key].touched
            });
        }
        

        let form = (
            <form onSubmit={this.submitHandler}>
                    {formElementsArray.map(el => (
                        <Input 
                            key={el.id}
                            elementType={el.config.elementType}
                            elementConfig={el.config.elementConfig}
                            label={el.config.elementConfig.label}
                            value={el.config.value}
                            invalid = {!el.valid}
                            shouldValidate ={el.validation && el.touched}
                            changed={(event)=> this.inputChangedHandler(event, el.id)}/>
                    ))}
                        
                    
                    <Button disabled={!this.state.formIsValid} btnType="Danger">SUBMIT</Button>
                </form>
        );
            
        let errorMessage = null;
        if(this.props.error){
            errorMessage = <span className={classes.Error}>{this.props.errorMessage}</span>
        }
        

        return(
            <>
            {/*<div className={classes.Logo}>
                <img src={logo} />
        </div>*/}
             
                 

                <div className={classes.ResetPassword}>
                <h4>Reset Password</h4>
                {this.props.message}
                {errorMessage}
                {form}
                </div>
                
            
            </>
        )
    }
}


const mapDispatchToProps = dispatch =>{
    return{
        onResetPassword: (token,currentPassword, password,email)=>dispatch(
            ActionTypes.resetPassword(token,currentPassword, password, email)
        )

    }
}

const mapStateToProps = state =>{
    return{
        errorMessage: state.auth.errorMessage,
        error: state.auth.error,
        message: state.auth.updateMessage,
        loading: state.auth.loading,
        isAuth: state.auth.token !== null,
        token: state.auth.token,
        auser: state.auth.user
    };
}

export default connect(mapStateToProps, mapDispatchToProps) (ResetPassword);