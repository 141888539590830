import React from 'react';
import styles from './FieldPlotter.module.css';
import PitchClass from '../../../classes/Softball/Pitch';

const FieldPlotter = (props) =>{
   
    function renderPitches(){
        var canvas = document.getElementById('myTrainingCanvas');
        ////console.log('renderPitches, canvas', canvas);
        if(!canvas){
            return;
        }
        var ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        for(let i=0;i<props.pitchArray.length; ++i){
            const p = props.pitchArray[i];
            if(p.hitAction === 'Fly' || p.hitAction === 'Pop' || p.result === 'HR' || p.result === 'FOUL'){
                const quadPoint = PitchClass.GetBatActionLocationQuadPoint(p);
                ctx.beginPath();
                ctx.moveTo(140, 221);
                ctx.quadraticCurveTo(quadPoint.x, 
                                    quadPoint.y,  
                                    p.hitLocation.x , 
                                    p.hitLocation.y );
                ctx.lineWidth = 5;
                if(p.result === 'FOUL' || p.result === 'OUT'){
                    ctx.strokeStyle = 'red';
                }
                else{
                    ctx.strokeStyle = '#6386c9';
                }
                
                ctx.setLineDash([0,0]);
                ctx.stroke();
            }
            else if(p.hitAction === 'Ground'){
                ctx.lineCap = 'round';
                ctx.lineWidth = 4;
                
                //ctx.strokeStyle = 'rgba(50,30,120,0.5)';
                if(p.result === 'FOUL' || p.result === 'OUT'){
                    ctx.strokeStyle = 'red';
                }
                else{
                    ctx.strokeStyle = '#6386c9';
                }

                ctx.beginPath();
                ctx.moveTo(140, 221);
                ctx.setLineDash([8,8]);
                ctx.lineTo(p.hitLocation.x, p.hitLocation.y);
                ctx.stroke();
            }
            else if(p.hitAction === 'Line'){
                ctx.lineCap = 'round';
                ctx.lineWidth = 4;
                
                //ctx.strokeStyle = 'rgba(50,30,120,0.5)';
                if(p.result === 'FOUL' || p.result === 'OUT'){
                    ctx.strokeStyle = 'red';
                }
                else{
                    ctx.strokeStyle = '#6386c9';
                }
                

                ctx.beginPath();
                ctx.moveTo(140, 221);
                ctx.setLineDash([0,0]);
                ctx.lineTo(p.hitLocation.x, p.hitLocation.y);
                ctx.stroke();
            }

        }
        /*
        if(result === 'Fly' || result === 'Pop'){
            const quadPoint = PitchClass.GetBatActionLocationQuadPoint(updatedPitch);
            ctx.beginPath();
            ctx.moveTo(140, 221);
            ctx.quadraticCurveTo(quadPoint.x, 
                                 quadPoint.y,  
                                 updatedPitch.hitLocation.x , 
                                 updatedPitch.hitLocation.y );
            ctx.lineWidth = 5;
            ctx.strokeStyle = '#003300';
            ctx.setLineDash([0,0]);
            ctx.stroke();
        }
        else if(result === 'Ground'){
            ctx.lineCap = 'round';
            ctx.lineWidth = 4;
            
            ctx.strokeStyle = 'rgba(50,30,120,0.5)';
            ctx.beginPath();
            ctx.moveTo(140, 221);
            ctx.setLineDash([8,8]);
            ctx.lineTo(updatedPitch.hitLocation.x, updatedPitch.hitLocation.y);
            ctx.stroke();
        }
        else if(result === 'Line'){
            ctx.lineCap = 'round';
            ctx.lineWidth = 4;
            
            ctx.strokeStyle = 'rgba(50,30,120,0.5)';
            ctx.beginPath();
            ctx.moveTo(140, 221);
            ctx.setLineDash([0,0]);
            ctx.lineTo(updatedPitch.hitLocation.x, updatedPitch.hitLocation.y);
            ctx.stroke();
        }*/
    }

    renderPitches();

    return (
        <div id='fieldarea' className={styles.FieldPlotter}>
        <canvas id="myTrainingCanvas" 
            width="280" height="280" >

        </canvas>
        {/*<FieldLocations clicked={props.clicked}/>*/}
        </div>
    )
}

export default FieldPlotter;