import React from 'react';
import classes from './Button.module.css';
const Button = (props) => {
    return (
    <button
        style={{width:props.width}}
        className={[classes.Button, classes[props.btnType], classes[props.btnSize]].join(' ')}
        onClick={props.clicked}
        disabled={props.disabled}
    >{props.children}</button>
    )

};


export default Button;