import React from 'react';
import classes from './BatterZoneTable.module.css';
import PitchZoneArea from './PitchZoneArea/PitchZoneArea';


const BatterZoneTable = (props) =>{

    /*
    0-0.2
    0.2-0.4
    0.4-0.6
    0.6-0.8
    0.8-1.0
    */

    function createZones(){
        let ret = [];
        let inc = 0.2;

    

        let cx = 0;
        let cy = 0;
        for(let y=0;y<5;++y){
            for(let x=0; x < 5; ++x){
                let v = {
                    x1: cx.toFixed(1),
                    y1: cy.toFixed(1),
                    x2: (cx + inc).toFixed(1),
                    y2: (cy + inc).toFixed(1),

                }
                cx += inc;
                ret.push(v);
            }
            cy += inc;
            cx %=1;
            cy %=1;
        }
        return ret;
        
    }
    let zones = createZones();


    function getZone(x,y, zones){
        for(let i=0; i< zones.length; ++i){
            const z = zones[i];
            if(x >= z.x1 && x < z.x2 && y >= z.y1 && y < z.y2){
                return i;
            }
        }
        return -1;
    }

    
    let mincolor = {
        r: 142,
        g: 169,
        b: 219
    }

    let maxcolor = {
        r: 254,
        g: 153,
        b: 102
    }

    function isHit(pitch){
        if(props.battingPractice){
            if(pitch.result !== 'SWING AND MISS' && pitch.result !== 'Take'){
                return true;
            }
            
        }
        else{
            if(pitch.result === "1B" || 
            pitch.result === "2B" || 
            pitch.result === "3B" || 
            pitch.result === "4B" || 
            pitch.result === "HR" || 
            pitch.result === "IPHR"){
                return true;
            }
        }
        return false;
    }

    function getColor(p){
        //Calculate the average (r',g',b') = ( (r1+r2)/2, (g1+g2)/2, (b1+b2)/2 )
        let r = mincolor.r * parseFloat( 1.0 - p) + maxcolor.r * parseFloat(p);
        let g = mincolor.g * parseFloat( 1.0 - p) + maxcolor.g * parseFloat(p);
        let b = mincolor.b * parseFloat( 1.0 - p) + maxcolor.b * parseFloat(p);

        let ret = {
            r : r,
            g: g,
            b: b
        }
        return ret
    }

    function loadPercentages(){
        let pAndC = [];
        let pitches = [25];
        for(let i=0; i < 25; ++i){
            pitches[i] = [];
        }

        for(let i=0; i < props.pitches.length; ++i){
            const pitch = props.pitches[i];
            const z = getZone(pitch.x, pitch.y, zones);
            pitches[z].push(pitch);
        }
       

        for(let i=0; i < 25; ++i){
            let total = pitches[i].length;
            let hitTotal =  0;
            for(let j=0; j < total; ++j){
                const pitch = pitches[i][j];
                if(isHit(pitch)){
                    hitTotal ++;
                }
            }


            let percent = hitTotal / parseFloat(total);
            if(total < 1){
                percent = 0;
            }
            let color = getColor(percent);

            let colorVal = "rgb("+ color.r+","+color.g+","+color.b+")";
            ////console.log('color ' + colorVal);
            percent = percent * 100; 
            percent = percent.toFixed(0) + "%";
            let pc = {
                percent :percent,
                color : colorVal
            }
            pAndC.push(pc);

        }

        //console.log('loadPercentages', pAndC);

        return pAndC;


    }
    function onFilterZone(val){
        ////console.log('PitchZoneTable->onFilterZone', val);
        props.onFilterZone(val);
    }


    ////console.log("ZONES", zones);
    let renderZones = [];

    const pAndCs = loadPercentages();
    //onClick={()=>props.onFilterZone("LA")}
    let zoneStr = "LA"
    for(let i=0; i < pAndCs.length; ++i )
    {
        if(i >= 0 && i < 2){
            zoneStr = "HA";
        }
        else if( i === 2){
            zoneStr = "HM";
        }
        else if (i > 2 && i < 5){
            zoneStr = "HI";
        }
        else if( i>= 5 && i < 7){
            zoneStr = "HA";
        }
        else if( i === 7){
            zoneStr = "HM";
        }
        else if( i > 7 && i < 10){
            zoneStr = "HI";
        }
        else if (i >=10 && i < 12){
            zoneStr = "MA";
        }
        else if (i === 12){
            zoneStr = "MM";
        }
        else if (i > 12 && i < 15){
            zoneStr = "MI";
        }
        else if (i >=15 && i < 17){
            zoneStr = "LA";
        }
        else if (i === 17){
            zoneStr = "LM";
        }
        else if (i > 17 && i < 20){
            zoneStr = "LI";
        }
        else if (i >= 20 && i < 22){
            zoneStr = "LA";
        }
        else if (i === 22){
            zoneStr = "LM";
        }
        else if (i > 22){
            zoneStr = "LI";
        }
        let title = pAndCs[i].percent; //+ ' ' + zones[i].x2 + ', ' + zones[i].y1 + ' ' + zones[i].y2;
        let noOutline = false;
        let borderLeft = false;
        let borderTop = false;
        let borderRight = false;
        let borderBottom = false;

        if((i >=6 && i < 9) ||
           (i >=11 && i < 14) ||
           (i >=16 && i < 19)){
               noOutline = true;
           }
        // if(i===6 || i===11 || i===16){
        //     borderLeft = true;
        // }   
        // if(i===6 || i===7 || i===8){
        //     //borderTop = true;
        // }
        // if(i===8 || i === 15 || i === 18){
        //     borderRight = true;
        // }

        let r = <PitchZoneArea 
                    key={i}
                    value={title} 
                    color={pAndCs[i].color} 
                    zone={zoneStr} 
                    onFilterZone={onFilterZone}
                    noOutline={noOutline}
                    borderLeft={borderLeft}
                    borderRight={borderRight}
                    borderTop={borderTop}
                    borderBottom={borderBottom}/>

        renderZones.push(r);
    }

 /*
        6  border top, border left
        7 border top
        8 border top, border right
        11 border right
        13 border left
        16 border left, border bottom
        17 border bottom
        18 border bottom, border right
        */





    

    return(
        
        <div className={classes.PitchZoneTable}>
            <h3>Hot/Cold Zones</h3>
            <div className={classes.StrikeZone} />
            {renderZones}
        </div>
        
    );
}

export default BatterZoneTable;