import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as ActionTypes from '../../../store/actions/index';
import {Redirect} from 'react-router-dom';
//import * as ActionTypes from '../../../store/actions/actionTypes';
import styles from './Dashboard.module.css';
import Button from './../../UI/Button/Button';
import Modal from '../../UI/Modal/Modal';
import GameForm from '../../../containers/Games/GameForm/GameForm';
import TrainingForm from '../../../containers/Trainings/TrainingForm/TrainingForm';




class Dashboard extends Component{
    state = {
        showCreateGame: false,
        showCreateTraining: false,
        navigateToGame: false
    }
    componentDidMount(){
        
        if(this.props.isAuth){
            this.props.onClearGameState();
            this.props.onGetOrgGames(this.props.token, this.props.auser.organizationId);
            this.props.onLoadOrgTeams(this.props.token, this.props.auser.organizationId);
        }
    }

    
    componentDidUpdate(prevProps,prevState){
        if(prevProps.gameMessage !== this.props.gameMessage){
            if(this.props.gameMessage === "CREATE_GAME_SUCCESS"){
                this.props.history.push({
                    pathname: '/game/' + this.props.gameID
                });
            }
        }
        if(prevProps.trainingMessage !== this.props.trainingMessage){
            //console.log(this.props.trainingMessage);
            if(this.props.trainingMessage === "CREATE_TRAINING_SUCCESS"){
                this.props.history.push({
                    pathname: '/training/' + this.props.trainingID
                });
            }
        }

    }


    handleMyGames = () =>{
        this.props.history.push('/games')
    }

    handleMyTeams = () =>{
        this.props.history.push('/teams');
    }

    handleMyTrainings = () =>{
        this.props.history.push('/trainings');
    }
    handleAdmin = () =>{
        this.props.history.push('/admin');
    }


    onCreateGameCancel = () =>{
        this.setState({
            showCreateGame: false
        })
    }
    onCreateGame = () =>{
        this.setState({
            showCreateGame: true
        })
    }

    onCreateTrainingCancel = () =>{
        this.setState({
            showCreateTraining: false
        })
    }

    onCreateTraining = () =>{
        this.setState({
            showCreateTraining: true
        })
    }

    submitAddGameHandler = (event, data) =>{
        event.preventDefault();
        ////console.log("Dashboard->submitAddGameHandler ", data);
        
        this.props.onCreateGame(this.props.token, data);
        //this.props.onGetGames(this.props.token, this.props.userId);
    }

    submitAddTrainingHandler = (event, data) =>{
        event.preventDefault();
        //console.log("Dashboard->submitAddTrainingHandler ", data);
        this.props.onCreateTraining(this.props.token, data);
    }

    render(){
        let userRole = null;
        let roleMessage = null;
        let orgMessage = null;
        if(this.props.auser){
            roleMessage = "Hello "+ this.props.auser.firstName+" "+this.props.auser.lastName+", Your Role is " + this.props.auser.role;
            userRole = this.props.auser.role;
            if( userRole !== 'SysAdmin' && 
                userRole !== 'Admin' && 
                userRole !== 'OrgAdmin' && 
                userRole !== 'OrgAdminTraining' && 
                userRole !== 'Viewer' &&
                userRole !== 'ViewerTraining'){
                roleMessage = "Your Organization Admin needs to grant you access";
            }
            if(this.props.auser.org){
                orgMessage = this.props.auser.org.name +' - ' + this.props.auser.org.teamType;
            }
        }
        
        let adminButton = null;
        let createGameButton = null;
        let createTrainingButton = null;
        let myGamesButton = null;
        let myTrainingsButton = null;
        let teamsButton = null;
        if(userRole === 'OrgAdmin' || userRole === 'SysAdmin'){
            adminButton = <Button btnType="Yellow" width={'200px'} clicked={this.handleAdmin}>ADMIN</Button>
            createGameButton = <Button btnType="Yellow" width={'200px'} clicked={this.onCreateGame}>CREATE GAME</Button>
            createTrainingButton = <><Button btnType="Yellow" width={'200px'} clicked={this.onCreateTraining}>CREATE TRAINING</Button><br/></>
            myGamesButton = <Button btnType="Yellow" width={'200px'} clicked={this.handleMyGames}>MY GAMES</Button>
            myTrainingsButton = <Button btnType="Yellow" width={'200px'} clicked={this.handleMyTrainings}>MY TRAININGS</Button>
            teamsButton = <Button btnType="Yellow" width={'200px'} clicked={this.handleMyTeams}>TEAMS</Button>
        
            
            //This is how to grey out for the new role AdminTraining
            //createGameButton = <Button btnType="Grey" disabled={true} width={'200px'} clicked={this.onCreateGame}>CREATE GAME</Button>
            //myGamesButton = <Button btnType="Grey" disabled={true} width={'200px'} clicked={this.handleMyGames}>MY GAMES</Button>
            
        
        }
        if(userRole === 'OrgAdminTraining'){
            adminButton = <Button btnType="Yellow" width={'200px'} clicked={this.handleAdmin}>ADMIN</Button>
            //createGameButton = <Button btnType="Yellow" width={'200px'} clicked={this.onCreateGame}>CREATE GAME</Button>
            createTrainingButton = <><Button btnType="Yellow" width={'200px'} clicked={this.onCreateTraining}>CREATE TRAINING</Button><br/></>
            //myGamesButton = <Button btnType="Yellow" width={'200px'} clicked={this.handleMyGames}>MY GAMES</Button>
            myTrainingsButton = <Button btnType="Yellow" width={'200px'} clicked={this.handleMyTrainings}>MY TRAININGS</Button>
            teamsButton = <Button btnType="Yellow" width={'200px'} clicked={this.handleMyTeams}>TEAMS</Button>
        
            
            //This is how to grey out for the new role AdminTraining
            createGameButton = <Button btnType="Grey" disabled={true} width={'200px'} clicked={null}>CREATE GAME</Button>
            myGamesButton = <Button btnType="Grey" disabled={true} width={'200px'} clicked={null}>MY GAMES</Button>

        }
        if(userRole === 'AdminTraining'){
            //adminButton = <Button btnType="Yellow" width={'200px'} clicked={this.handleAdmin}>ADMIN</Button>
            //createGameButton = <Button btnType="Yellow" width={'200px'} clicked={this.onCreateGame}>CREATE GAME</Button>
            createTrainingButton = <><Button btnType="Yellow" width={'200px'} clicked={this.onCreateTraining}>CREATE TRAINING</Button><br/></>
            //myGamesButton = <Button btnType="Yellow" width={'200px'} clicked={this.handleMyGames}>MY GAMES</Button>
            myTrainingsButton = <Button btnType="Yellow" width={'200px'} clicked={this.handleMyTrainings}>MY TRAININGS</Button>
            teamsButton = <Button btnType="Yellow" width={'200px'} clicked={this.handleMyTeams}>TEAMS</Button>
        
            
            //This is how to grey out for the new role AdminTraining
            createGameButton = <Button btnType="Grey" disabled={true} width={'200px'} clicked={null}>CREATE GAME</Button>
            myGamesButton = <Button btnType="Grey" disabled={true} width={'200px'} clicked={null}>MY GAMES</Button>

        }
        if(userRole === 'Admin'){
            createGameButton = <Button btnType="Yellow" width={'200px'} clicked={this.onCreateGame}>CREATE GAME</Button>
            createTrainingButton = <><Button btnType="Yellow" width={'200px'} clicked={this.onCreateTraining}>CREATE TRAINING</Button><br/></>
            myGamesButton = <Button btnType="Yellow" width={'200px'} clicked={this.handleMyGames}>MY GAMES</Button>
            myTrainingsButton = <Button btnType="Yellow" width={'200px'} clicked={this.handleMyTrainings}>MY TRAININGS</Button>
        }
        if(userRole === 'Viewer'){
            myGamesButton = <Button btnType="Yellow" width={'200px'} clicked={this.handleMyGames}>MY GAMES</Button>
            myTrainingsButton = <Button btnType="Yellow" width={'200px'} clicked={this.handleMyTrainings}>MY TRAININGS</Button>
            
        }
        if(userRole === 'ViewerTraining'){
            //myGamesButton = <Button btnType="Yellow" width={'200px'} clicked={this.handleMyGames}>MY GAMES</Button>
            myTrainingsButton = <Button btnType="Yellow" width={'200px'} clicked={this.handleMyTrainings}>MY TRAININGS</Button>
            
        }

        //let showCreateGame = null;
        //let navigateToGame = null;
        /*if(this.state.navigateToGame){
            navigateToGame = null;
        }*/
        return (
            <>
            <Modal show={this.state.showCreateGame} clickBackdrop={this.onCreateGameCancel}>
                    <GameForm game={null}
                    loading={this.props.gameLoading}
                    error={this.props.gameError}
                    message={this.props.gameMessage}
                    submit={this.submitAddGameHandler} />
            </Modal>
            <Modal show={this.state.showCreateTraining} clickBackdrop={this.onCreateTrainingCancel}>
                    <TrainingForm game={null}
                    loading={this.props.gameLoading}
                    error={this.props.gameError}
                    message={this.props.gameMessage}
                    submit={this.submitAddTrainingHandler} />
            </Modal>
            <div className={styles.Background}>
            <div className={styles.Dashboard}>
                {/*<div className={styles.Logo}>
                    <img src={logo}/>
        </div>*/}
                <h1>{orgMessage}</h1>
                <h3>{roleMessage}</h3>
                {createGameButton}
                {createTrainingButton}
                {myGamesButton}
                {myTrainingsButton}
                {teamsButton}
                {adminButton}
            </div>
            </div>
            
            </>
        )
    }
}

const mapStateToProps = state =>{
    return{
        gameStart: state.game.gameStart,
        isAuth: state.auth.token !== null,
        userId: state.auth.userId,
        auser: state.auth.user,
        loading: state.auth.loading,
        gameLoading: state.game.loading,
        gameError: state.game.error,
        gameMessage: state.game.message,
        token: state.auth.token,
        games: state.game.games,
        gameID: state.game.id,

        trainingLoading: state.training.loading,
        trainingError: state.training.error,
        trainingMessage: state.training.message,
        trainings: state.training.trainings,
        trainingID: state.training.id
    }
}

const mapDispatchToProps = dispatch =>{
    return{
        onGameStart: (token)=>dispatch(
            ActionTypes.gameStart()
        ),
        onCreateGame: (token, data)=>dispatch(
            ActionTypes.createGame(token,data)
        ),

        onGetOrgGames: (token, orgId)=>dispatch(
            ActionTypes.getOrgGames(token, orgId)
        ),
        onLoadOrgTeams: (token, orgId)=>dispatch(
            ActionTypes.getOrgTeams(token, orgId)
        ),
        onClearGameState: ()=>dispatch(
            ActionTypes.clearGameState()
        ),
        onTrainingStart: (token)=>dispatch(
            ActionTypes.trainingStart()
        ),
        onCreateTraining: (token, data)=>dispatch(
            ActionTypes.createTraining(token,data)
        ),

        onGetTrainings: (token, userId)=>dispatch(
            ActionTypes.getTrainings(token, userId)
        ),

    }
};


export default connect(mapStateToProps, mapDispatchToProps) (Dashboard);