import React, {Component} from 'react';
import classes from './PlayerSelector.module.css';
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import Spinner from '../../components/UI/Spinner/Spinner';
import { isThisQuarter } from 'date-fns/esm';



class PlayerSelector extends Component{
    state = {
        controls: {
            
            players:{
                
                elementType: 'select',
                elementConfig: {
                    label: 'Players',
                    options : [
                        
                    ]
                    
                },
                value:'',
                validation: {},
                valid:true
            },
            
        },
        formIsValid: false,
        id: null
    }
    componentDidMount(){

        if(this.props.players && this.props.players.length > 0){
            const updatedForm = {
                ...this.state.controls
            }
            const updatedFormPlayers = {...updatedForm['players']};
            updatedFormPlayers.value = this.props.players[0].id;
            if(this.props.currentPlayer){
                const player = this.props.players.find(x=>x.id === this.props.currentPlayer.id);
                if(typeof player !== 'undefined'){
                    updatedFormPlayers.value = this.props.currentPlayer.id;
                }
            }
            let updatedOptions = [];
            let x;
            if(this.props.containAllOption){
                let option = {
                    value: 'ALL',
                    displayValue: 'All'
                }
                updatedOptions.push(option);
            }
            for(x in this.props.players){
                let option = {
                    value: this.props.players[x].id,
                    displayValue: this.props.players[x].firstName +' '+ 
                    this.props.players[x].lastName +' #' +
                    this.props.players[x].number
                }
                updatedOptions.push(option);
            }
            ////console.log('updatedOptions',updatedOptions);
            updatedFormPlayers.elementConfig.options = updatedOptions;
            updatedFormPlayers.valid = true;

            updatedForm['players'] = updatedFormPlayers;

            let formIsValid = true;
            for(let inputIds in updatedForm){
                formIsValid = updatedForm[inputIds].valid && formIsValid;
            }
            this.setState({controls: updatedForm, formIsValid: formIsValid});
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if((prevProps.players !== this.props.players && this.props.players.length > 0) ||
            (prevProps.currentPlayer !== this.props.currentPlayer)){
            const updatedForm = {
                ...this.state.controls
            }
            const updatedFormPlayers = {...updatedForm['players']};
            let x;
            if(this.props.players.length > 0){
                for(x in this.props.players){
                    let p = this.props.players[x];
                    if(typeof p.hidden !== 'undefined' && p.hidden === true){
                        continue;
                    }
                    updatedFormPlayers.value = p.id;
                    break;
                }

                
            }

            

            if(this.props.currentPlayer){
                const player = this.props.players.find(x=>x.id === this.props.currentPlayer.id);
                if(typeof player !== 'undefined'){
                    updatedFormPlayers.value = this.props.currentPlayer.id;
                }
            }
            else if(!this.props.currentPlayer && this.props.containAllOption){
                //console.log('updatedFormPlayers value to ALL');
                updatedFormPlayers.value = 'ALL';
            }
            let updatedOptions = [];
            
            if(this.props.containAllOption){
                let option = {
                    value: 'ALL',
                    displayValue: 'All'
                }
                updatedOptions.push(option);
            }
            for(x in this.props.players){
                let p = this.props.players[x];
                if(typeof p.hidden !== 'undefined' && p.hidden === true){
                    continue;
                }

                let positionStr = this.props.players[x].currentPosition ? (' P- ' + this.props.players[x].currentPosition) : '';
                ////console.log('positionStr ' + positionStr);
                let option = {
                    value: this.props.players[x].id,
                    displayValue: this.props.players[x].firstName +' '+ 
                    this.props.players[x].lastName +' #' +
                    this.props.players[x].number + positionStr
                }
                updatedOptions.push(option);
            }
            ////console.log('updatedOptions',updatedOptions);
            updatedFormPlayers.elementConfig.options = updatedOptions;
            updatedFormPlayers.valid = true;

            updatedForm['players'] = updatedFormPlayers;

            let formIsValid = true;
            for(let inputIds in updatedForm){
                formIsValid = updatedForm[inputIds].valid && formIsValid;
            }
            this.setState({controls: updatedForm, formIsValid: formIsValid});
        }
    }
        
    

    checkValidity(value, rules) {
        let isValid = true;
        return isValid;
    }

    inputChangedHandler = (event, inputIdentifier, type) =>{
        //console.log('PlayerSelector->inputChangeHandler ' + event.target.value);
        const updatedForm = {
            ...this.state.controls
        }
        const updatedFormElement = {...updatedForm[inputIdentifier]};
        let formIsValid = true;
        if(type === 'datepicker'){
            updatedFormElement.value = new Date(event);
            updatedFormElement.valid = true;
            ////console.log(updatedFormElement.value);
        }
        else{
            updatedFormElement.value = event.target.value;
            updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        
        }
        updatedFormElement.touched = true;
        updatedForm[inputIdentifier] = updatedFormElement;

        ////console.log(updatedForm);
        for(let inputIds in updatedForm){
            formIsValid = updatedForm[inputIds].valid && formIsValid;
        }
        this.setState({controls: updatedForm, formIsValid: formIsValid});
        if(this.props.noButton){
            //console.log('calling submitHandler in PlayerSelector ',updatedFormElement
            //this.state.controls.players.value
            //);
            this.props.onSubmit(event.target.value);
        }
    }

    submitHandler = (event) =>{
        event.preventDefault();
        // //console.log('calling submitHandler in PlayerSelector '+
        // this.state.controls.players.value
        // );
        this.props.onSubmit(this.state.controls.players.value);
    }

    render(){
        
        const formElementsArray = [];
        for(let key in this.state.controls){
            formElementsArray.push({
                id: key,
                config: this.state.controls[key],
                validation: this.state.controls[key].validation,
                valid: this.state.controls[key].valid,
                touched: this.state.controls[key].touched
            });
        }


        let formButton = null;
        if(!this.props.noButton){
            formButton = <Button disabled={!this.state.formIsValid} btnType="Success" btnSize="Small">SUBMIT</Button>
        }

        let form = (
            <form onSubmit={(event)=>this.submitHandler(event)}>
                    {formElementsArray.map(el => (
                        <Input 
                            key={el.id}
                            elementType={el.config.elementType}
                            elementConfig={el.config.elementConfig}
                            label={!this.props.hideLabel && el.config.elementConfig.label ?el.config.elementConfig.label : null}
                            value={el.config.value}
                            invalid = {!el.valid}
                            shouldValidate ={el.validation && el.touched}
                            changed={(event)=> this.inputChangedHandler(event, el.id, el.config.elementType)}/>
                    ))}
                        
                    {formButton}
                    
                </form>
        );

        if(this.props.loading){
            form = <Spinner />;
        }
        let message = null;
        if(this.props.error){
            message = <span className={classes.Error}>{this.props.message}</span>
        }
        else if(this.props.message !== null){
            message = <span className={classes.Success}>{this.props.message}</span>
        }


        return (
            <div className={classes.PlayerSelector}>
                <h4>{this.props.label}</h4>
                {message}
                {form}
            </div>
        )
    }
}


export default PlayerSelector;
