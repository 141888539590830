import React from 'react';
import styles from './HitResultButtons.module.css';
import HitResultButton from './HitResultButton/HitResultButton';

const HitResultButtons = (props) => {
    let options = [
        'OUT',
        '1B',
        '2B',
        '3B',
        'SAC',
        'IPHR',
        'ROE',
        'FC'
    ];

    if(props.training !== null){//} && props.training.trainingType === 'Pitcher'){
        options = [
            'OUT',
            '1B',
            '2B',
            '3B',
            'IPHR',
        ];
    }

    const buttons = options.map((o,i) => {
        
        return (
            <HitResultButton key={i} type={o} click={props.click(o)}
           
            />
        )
    });

    return (
        <div className={styles.HitResultButtons}>
        {buttons}
        </div>
    )


}

export default HitResultButtons;