
import React from 'react';
import classes from './Input.module.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { List } from 'react-virtualized';
//import Select from 'react-virtualized-select';
//import createFilterOptions from 'react-select-fast-filter-options';
//import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';

const MenuList = props => {
    const rows = props.children;
    const rowRenderer = ({ key, index, isScrolling, isVisible, style }) => (
      <div key={key} style={style}>{rows[index]}</div>
    );
  
    return (
      <List
        style={{ width: '100%' }}
        width={500}
        height={300}
        rowHeight={30}
        rowCount={rows.length}
        rowRenderer={rowRenderer}
      />
    )
  }

const Input = (props) =>{

    let inputElement = null;

    const inputClasses = [classes.InputElement];

    if(props.invalid && props.shouldValidate){
        inputClasses.push(classes.Invalid);
    }

    let inputStyle = classes.Input;
    ////console.log('CLASSES ' + inputClasses.join(' '));
    if(props.styleType === 'inline'){
        inputStyle = classes.InlineInput;
    }
    
   
    switch(props.elementType){
        case('input'):
            inputElement = <input className={inputClasses.join(' ')} 
                            {...props.elementConfig} 
                            value={props.value}
                            onChange={props.changed}/>;
            if(props.elementConfig.type === 'checkbox'){
                
                    inputElement = <input className={inputClasses.join(' ')} 
                            {...props.elementConfig} 
                            value='checked'
                            onChange={props.changed}
                            checked={!!props.value}/>;
                
            }
            
            break;
        case ('textarea'):
            inputElement = <textarea className={inputClasses.join(' ')} 
                            {...props.elementConfig}
                            value={props.value}
                            onChange={props.changed}/>;
            break;
        case ('react-select'):{
            
            inputElement = (<Select
                className={inputClasses.join(' ')}
                components={{ MenuList }}
                value={props.value}
                defaultValue={props.value}
                options={props.elementConfig.options}
               
                onChange={props.changed}
            />);
            break;
        }
        case ('select'):
            inputElement = (<select className={inputClasses.join(' ')} disabled={props.disabled === true? true : null}
                            value={props.value}
                            onChange={props.changed}>
                            {props.elementConfig.options.map(op => (
                                <option key={op.value} value={op.value}>
                                    {op.displayValue}
                                </option>
                            ))}
                            </select>   
                            );
            break;
        case ('datepicker'):
            
            inputElement = (
                <DatePicker style={{textAlign:"center"}}className={inputClasses.join(' ')}
                    inputStyle={{ textAlign: 'center' }}
                    selected={props.value}
                    showTimeSelect
                    timeIntervals ={15}
                    onChange={props.changed}
                />
            );
            break;
        default:
                inputElement = <input className={inputClasses.join(' ')} 
                            {...props.elementConfig}
                            value={props.value}
                            onChange={props.changed}/>;
                break;


    }
    return (
        <div className={inputStyle}>
            <label className={classes.Label}>{props.label}</label>
            {inputElement}
        </div>
    )
}

export default Input;