import React from 'react'
import styles from './PitchTypesTable.module.css';



/*
let ret = {
             FBP: 0,
             FBC: 0,
             FBSM: 0,
             XCHP: 0,
             XCHC: 0,
             XCHSM: 0,
             CRVP: 0,
             CRVC: 0,
             CRVSM: 0,
             DCVP:0,
             DCVC:0,
             DCVSM:0,
             DRPP:0,
             DRPC:0,
             DRPSM:0,
             RISP:0,
             RISC:0,
             RISSM:0,
             SCRWP:0,
             SCRWC:0,
             SCRWSM:0
         }
*/

const PitchTypesTable = (props) => {

    let mincolor = {
        r: 142,
        g: 169,
        b: 219
    }

    let maxcolor = {
        r: 254,
        g: 153,
        b: 102
    }

    function getColor(p){
        //Calculate the average (r',g',b') = ( (r1+r2)/2, (g1+g2)/2, (b1+b2)/2 )
        let r = mincolor.r * parseFloat( 1.0 - p) + maxcolor.r * parseFloat(p);
        let g = mincolor.g * parseFloat( 1.0 - p) + maxcolor.g * parseFloat(p);
        let b = mincolor.b * parseFloat( 1.0 - p) + maxcolor.b * parseFloat(p);

        let ret = {
            r : r,
            g: g,
            b: b
        }
        return ret
    }

    function getColorStr(p){
        let color = getColor(p);
        return "rgb("+ color.r+","+color.g+","+color.b+")"
    }
    return(
        <>
            
            <div className={styles.PitchTypesTable}>
               <h3>Pitch Types</h3>
               <table>
                   <thead>
                       <tr style={{backgroundColor: '#404040'}}>
                       <th>PT</th>
                       <th>P%</th>
                       <th>C%</th>
                       <th>SM%</th>
                       </tr>
                   </thead>
                   <tbody>
                       <tr>
                           <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterPitchType("FB")}>FB</td>
                           <td style={{backgroundColor:getColorStr(props.data.FBP)}}>{props.data.FBP}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.FBC)}}>{props.data.FBC}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.FBSM)}}>{props.data.FBSM}%</td>
                       </tr>
                       <tr>
                           <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterPitchType("XCHANGE")}>XCH</td>
                           <td style={{backgroundColor:getColorStr(props.data.XCHP)}}>{props.data.XCHP}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.XCHC)}}>{props.data.XCHC}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.XCHSM)}}>{props.data.XCHSM}%</td>
                       </tr>
                       
                       <tr>

                           <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterPitchType("CURVE")}>CRV</td>
                           <td style={{backgroundColor:getColorStr(props.data.CRVP)}}>{props.data.CRVP}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.CRVC)}}>{props.data.CRVC}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.CRVSM)}}>{props.data.CRVSM}%</td>
                       </tr>
                       <tr>
                           <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterPitchType("DROP")}>DRP</td>
                           <td style={{backgroundColor:getColorStr(props.data.DRPP)}}>{props.data.DRPP}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.DRPC)}}>{props.data.DRPC}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.DRPSM)}}>{props.data.DRPSM}%</td>
                       </tr>
                       <tr>
                           <td  style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterPitchType("DROPCV")}>DPC</td>
                           <td style={{backgroundColor:getColorStr(props.data.DCVP)}}>{props.data.DCVP}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.DCVC)}}>{props.data.DCVC}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.DCVSM)}}>{props.data.DCVSM}%</td>
                       </tr>
                      
                       <tr>
                           <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterPitchType("RISE")}>RIS</td>
                           <td style={{backgroundColor:getColorStr(props.data.RISP)}}>{props.data.RISP}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.RISC)}}>{props.data.RISC}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.RISSM)}}>{props.data.RISSM}%</td>
                       </tr>
                       <tr>
                           <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterPitchType("SCREW")}>SCR</td>
                           <td style={{backgroundColor:getColorStr(props.data.SCRWP)}}>{props.data.SCRWP}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.SCRWC)}}>{props.data.SCRWC}%</td>
                           <td style={{backgroundColor:getColorStr(props.data.SCRWSM)}}>{props.data.SCRWSM}%</td>
                       </tr>
                   </tbody>
               </table>
            </div>
            
        </>
    );
};

export default PitchTypesTable;