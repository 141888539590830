import React from 'react';
import styles from './BattingSide.module.css';
import Button  from '../../UI/Button/Button';
const BattingSide = (props) =>{

   let battingLeft=<Button clicked={props.change} btnSize="Small" btnType="Danger">LEFT</Button>
   let battingRight=<Button clicked={props.change} btnSize="Small" btnType="Danger">RIGHT</Button>

   if(props.battingRight === true){
        battingRight=<Button btnSize="Small" clicked={props.change} btnType="Invert">RIGHT</Button>
   }
   else{
        battingLeft=<Button btnSize="Small" clicked={props.change} btnType="Invert">LEFT</Button>
   }

   let battingSide = <Button clicked={props.change} btnSize="Small" btnType="Danger">LEFT</Button>

   if(props.battingRight === true){
    battingSide = <Button clicked={props.change} btnSize="Small" btnType="Danger">RIGHT</Button>
   }

    return(
        <div className={styles.BattingSide}>

            {battingSide}
            {/*{battingRight}
            {battingLeft}*/}
        </div>
    )
}

export default BattingSide;