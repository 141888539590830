
import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as ActionTypes from '../../store/actions/index';
import {Redirect} from 'react-router-dom';

import GameForm from './GameForm/GameForm';
import Modal from '../../components/UI/Modal/Modal';
import Button from '../../components/UI/Button/Button';
import classes from './Games.module.css';
import Spinner from '../../components/UI/Spinner/Spinner';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';


 
function convertDateToTicks(date){
    return((date.getTime() * 10000) + 621355968000000000);
}

function dateComparator(date1, date2){
    var date1Number = convertDateToTicks(new Date(date1));
    var date2Number = convertDateToTicks(new Date(date2));

    if (date1Number===null && date2Number===null) {
        return 0;
    }
    if (date1Number===null) {
        return -1;
    }
    if (date2Number===null) {
        return 1;
    }

    return date1Number - date2Number;
}


class Games extends Component{
    constructor(props){
        super(props);
        this.state = {
            columnDefs: [
                {
                    headerName: "ID", field: "id", sortable: false, filter: false, hide: true,  autoHeight: true, cellClass: 'cell-wrap-text'
                },
                {
                headerName: "Home Team", field: "homeTeam", sortable: true, filter: true, cellStyle: {justifyContent: 'center'},  autoHeight: true, cellStyle: {'white-space': 'normal'}
            }, {
                headerName: "Away Team", field: "awayTeam", sortable: true, filter: true,  autoHeight: true, cellStyle: {'white-space': 'normal'}
            }, {
                headerName: "Date", field: "date", sortable: true, filter: true, comparator: dateComparator,  autoHeight: true, cellStyle: {'white-space': 'normal'}
            }
            ,{
                headerName: "Type", field: "gameType", sortable: true, filter: true,  autoHeight: true, cellStyle: {'white-space': 'normal'}
            }
            ,{
                headerName: "Pitch Type", field: "pitchType", sortable: true, filter: true,  autoHeight: true, cellStyle: {'white-space': 'normal'}
            }
            ,{
                headerName: "Away Score", field: "awayScore", sortable: true, filter: true, width:150,  autoHeight: true, cellStyle: {'white-space': 'normal'}
            },
            {
                headerName: "Home Score", field: "homeScore", sortable: true, filter: true, width:150,  autoHeight: true, cellStyle: {'white-space': 'normal'}
            },
            {
                headerName: "State", field: "state", sortable: true, filter: true,  autoHeight: true, cellStyle: {'white-space': 'normal'}
            }
            ],
            rowData: [],
            showCreateGame : false
        }
        
    }
    onGridReady = params => {
        ////console.log('onGridReady!');
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
    }

    componentWillMount(){
        
        if(this.props.isAuth){
            this.props.onClearGameState();
            if(this.props.auser){
                this.props.onGetOrgGames(this.props.token, this.props.auser.organizationId);
                //this.props.onLoadTeams(this.props.token);
                this.props.onLoadOrgTeams(this.props.token, this.props.auser.organizationId)
                this.props.onLoadGameStates(this.props.token, this.props.auser.organizationId);
            }
        }
    }
    componentDidMount(){
        //this.state.columnDefs.api.sizeColumnsToFit();
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.games && this.props.games && prevProps.games.length !== this.props.games.length){
            this.props.onClearGameState();
            this.props.onGetOrgGames(this.props.token, this.props.auser.organizationId);
            //this.props.onLoadTeams(this.props.token);
            this.props.onLoadOrgTeams(this.props.token, this.props.auser.organizationId)
            this.props.onLoadGameStates(this.props.token, this.props.auser.organizationId);
        }
    }

    canCreateGames = () =>{
        if(this.props.auser && (this.props.auser.role === 'SysAdmin' ||
        this.props.auser.role === 'OrgAdmin' ||
        this.props.auser.role === 'Admin')){
            return true;
        }
        return false;
    }


    submitAddGameHandler = (event, data) =>{
        event.preventDefault();
        ////console.log("Game->submitAddGameHandler ", data);
        
        this.props.onCreateGame(this.props.token, data);
        this.setState({
            showCreateGame: false
        });

       
        //this.props.onGetGames(this.props.token, this.props.userId);
    }


    gameClickHandler = (event, id) =>{
        ////console.log('Teams->gameClickHandler ' + id, event);
        ////console.log('game?id=' + id);
        ////console.log(this.props.match.url);

        this.props.history.push({
            pathname: '/game/' + id
        });
    }
    
    onCreateGameCancel = () =>{
        this.setState({
            showCreateGame: false
        })
    }
    onCreateGame = () =>{
        this.setState({
            showCreateGame: true
        })
    }

    formatDateStr = (date) =>{
        let formatDate = new Date(date).toLocaleDateString("en-US") + " "+ new Date(date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
        return formatDate;//.substr(0,formatDate.length-6);
    }

    isInningTop(inning){
        
        // //console.log('Current Inning: ' + Math.floor((this.props.currentInning / 2.0) + 1));
        // //console.log('Top or bottom of inning? ' + (this.props.currentInning % 2 === 0 ? 'top' : 'bottom'));
        return inning % 2 === 0 ? true : false;
    }

   
    getInningString = (inning) =>{
        let inningEnd = null;
        let actualInning =  Math.floor((inning / 2.0) + 1);
        let inningStart = this.isInningTop(inning) ? "T- " : "B- "
        if(actualInning  === 1){
            inningEnd = 'st';
        }
        else if (actualInning === 2){
            inningEnd = 'nd';
        }
        else if (actualInning === 3){
            inningEnd = 'rd';
        }
        else if (actualInning  >= 4){
            inningEnd = 'th';
        }

        return inningStart+ actualInning + inningEnd;
    }

    onCellClicked = (result) =>{
        ////console.log("onCellClicked", result);
         this.props.history.push({
             pathname: '/game/' + result.data.id
         });
    }
    

    render(){
        //let games = null;

        let gameRows = [];

        let spinnerJSX = <Spinner/>
        if(this.props.games){
            spinnerJSX = null;
            //sort games by date
            let postSortGames = this.props.games.sort(function(obj1, obj2) {
                //sort by players by last name asc
                return (obj1.date < obj2.date) === true? 1 : -1;
            });


            /*games = postSortGames.map(game =>{
                let gameCode = null;
                if(game.homeTeam && game.awayTeam){
                gameCode = <Game key={game.id} 
                    game={game} 
                    gameClick={(event)=> this.gameClickHandler(event, game.id)} />
                }
                return(
                    gameCode
                )
            });*/

            
            for(let i=0; i< postSortGames.length; ++i){
                let g = postSortGames[i];
                //const gameState = this.props.gameStates.find(x=>x.game.id === g.id);
                let gameState = null;
                if(g.id === '-Lm75oHNbZ7DsczjnDWU'){
                    let debug = 0;
                }
                let totalMatches = 0;
                for(let j=0;j<this.props.gameStates.length; ++j){
                    if(this.props.gameStates[j].game.id === g.id){
                        totalMatches ++;
                        gameState = this.props.gameStates[j]
                    }
                }

                
                let awayScore = 0;
                let homeScore = 0;
                let state = "";//"Bottom of 7th, Complete, Force Quit";


                if(this.props.gameLoading){
                    state = "Fetching Data..";
                }
                else{
                    if(gameState !== null && typeof gameState !== 'undefined'){
                        awayScore = gameState.awayScore;
                        homeScore = gameState.homeScore;
                        if(gameState.homeTeamCurrentPitcher === null ||
                            gameState.awayTeamCurrentPitcher === null ||
                            typeof gameState.awayTeamLineup === 'undefined' ||
                            typeof gameState.homeTeamLineup === 'undefined' ||
                            gameState.awayTeamLineup.length === 0 ||
                            gameState.homeTeamLineup.length === 0){
                                state = "Not Started";
                        }
                        else{
                            state = gameState.forceQuit ? "Force Quit - " + gameState.forceQuitNotes : (gameState.gameOver ? "Complete" : this.getInningString(gameState.currentInning))
                    
                        }
                    }
                    else{
                        //console.log('Fetching Data?', gameState);
                        state = "Fetching Data..";
                        //console.log('GameStates', this.props.gameStates);
                    }
                }
                if(g.homeTeam && g.awayTeam){
                    let gt = g.gameType;
                    if(gt === 'League/Conference'){
                        gt = 'League / Conference';
                    }
                    gameRows.push({
                        id: g.id,
                        homeTeam: g.homeTeam.name,
                        awayTeam: g.awayTeam.name,
                        gameType: gt,
                        pitchType: g.pitchType,
                        date: this.formatDateStr(g.date),
                        awayScore: awayScore,
                        homeScore: homeScore,
                        state: state
                    })
                }
            }
        }

        


        let authRedirect = null;
        if(!this.props.loading && !this.props.isAuth){
            authRedirect = <Redirect to="/auth" />
        }

        /*let gameRedirect = null;
        if(this.props.gameid){
            let gameURL = "/games/" + this.props.gameid;
            gameRedirect = <Redirect to={gameURL} />
        }*/

        ////console.log('Games:');
        ////console.log(games);

        
        //this.state.rowData = gameRows;
        
        let tableJSX = <div 
                            className="ag-theme-balham-dark"
                            style={{ 
                            height: '400px', 
                            width: 'inherit',
                            marginLeft :'auto',
                            marginRight : 'auto',
                            fontSize:'15px',
                            fontFamily:'inherit'
                        }} 
                        >
                            <AgGridReact
                            // useful for accessing the component directly via ref
                            ref="agGrid"
                            onCellClicked={this.onCellClicked.bind(this)}
                            columnDefs={this.state.columnDefs}
                            //rowData={this.state.rowData}
                            rowData={gameRows}
                            onGridReady={this.onGridReady}>
                            </AgGridReact>
                        </div>

        let createGameJSX = null;
        if(this.canCreateGames()){
            createGameJSX = <>
                            <Modal show={this.state.showCreateGame} clickBackdrop={this.onCreateGameCancel}>
                                <GameForm game={null}
                                loading={this.props.gameLoading}
                                error={this.props.gameError}
                                message={this.props.gameMessage}
                                submit={this.submitAddGameHandler} />
                                </Modal>
                                <Button clicked={this.onCreateGame} btnType="Danger">CREATE GAME</Button>
                            </>
        }

        return(
            
            <div className={classes.Games}>
                
                {authRedirect}
                {createGameJSX}
                {spinnerJSX}
                <h3>Games</h3>
                {/*games*/}
                {tableJSX}
            </div>
        )
    }
}

const mapStateToProps = state =>{
    return{
        gameStart: state.game.gameStart,
        isAuth: state.auth.token !== null,
        userId: state.auth.userId,
        loading: state.auth.loading,
        gameLoading: state.game.loading,
        gameError: state.game.error,
        gameMessage: state.game.message,
        token: state.auth.token,
        games: state.game.games,
        gameStates: state.game.gameStates,
        gameid: state.game.id,
        auser: state.auth.user
    }
}



const mapDispatchToProps = dispatch =>{
    return{
        onGameStart: (token)=>dispatch(
            ActionTypes.gameStart()
        ),
        onCreateGame: (token, data)=>dispatch(
            ActionTypes.createGame(token,data)
        ),

        onGetOrgGames: (token, orgId)=>dispatch(
            ActionTypes.getOrgGames(token, orgId)
        ),
        onLoadOrgTeams: (token, orgId)=>dispatch(
            ActionTypes.getOrgTeams(token, orgId)
        ),
        onClearGameState: ()=>dispatch(
            ActionTypes.clearGameState()
        ),
        onLoadGameStates: (token, orgId)=>dispatch(
            ActionTypes.loadGameStates(token,orgId)
        )

    }
};



export default connect(mapStateToProps, mapDispatchToProps) (Games);

