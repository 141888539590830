import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as ActionTypes from '../../store/actions/index';
import styles from './GameAnalysis.module.css';
import Button from '../../components/UI/Button/Button';
import InningScoreTable from '../../components/GameState/InningScoreTable/InningScoreTable';
import RosterStats from './RosterStats/RosterStats';


class GameAnalysis extends Component{


    componentDidMount(){
        //this.props.onLoadGame(this.props.token, this.props.match.params.id);
        //console.log(this.props.match.path);
        this.props.onLoadGameState(this.props.token, this.props.match.params.id);
        
    }

    currentInning(){
        return Math.floor((this.props.currentInning / 2.0) + 1);
    }

    isInningTop(inning){
        let currentInning = (typeof inning !== 'undefined') ? inning : this.props.currentInning;
        // //console.log('Current Inning: ' + Math.floor((this.props.currentInning / 2.0) + 1));
        // //console.log('Top or bottom of inning? ' + (this.props.currentInning % 2 === 0 ? 'top' : 'bottom'));
        return currentInning % 2 === 0 ? true : false;
    }

    isGameOver = () =>{
        if(this.props.forceQuit){
            return true;
        }



        const inning = this.currentInning();
        const isTop = this.isInningTop();
        const homeScore = this.props.homeScore;
        const awayScore = this.props.awayScore;
        //if inning is this.props.totalInnings or greater and is in bottom and home team is winning, game is over
        if(inning >= this.props.totalInnings && !isTop && homeScore > awayScore){
            //this.props.onSetGameOver(true);
            return true;
        }
        //else if inning is greater than this.props.totalInnings and it is the end of the inning, if away team is winning game is over
        else if(inning > this.props.totalInnings && awayScore > homeScore){
            //this.props.onSetGameOver(true);
            return true;
        }
        return false;
    }

    onShowGameHandler = () =>{
        this.props.history.push({
            pathname: '/game/' + this.props.game.id
        });
    }

    render(){

        let homeTeamPlayers = [...this.props.homeTeamLineup];
        let indexOffset = 1;
        for(let i=0;i<this.props.homeSubstitutions.length; ++i){
            let sub = {...this.props.homeSubstitutions[i].index};
            if(typeof sub.number === 'undefined'){
                sub = {...this.props.homeSubstitutions[i]};
            }
            sub.number = 'S- ' + sub.number;
            sub.firstName = 'S- ' + sub.firstName;
            //homeTeamPlayers.push(sub);
            homeTeamPlayers.splice(sub.index+indexOffset, 0, sub);
            indexOffset += 1;
        }

        //console.log('home subs', this.props.homeSubstitutions);
        if(this.props.homeTeamFlex){
            let flex = {...this.props.homeTeamFlex}
            homeTeamPlayers.push(flex);
        }


        let awayTeamPlayers = [...this.props.awayTeamLineup];
        indexOffset = 1;
        for(let i=0;i<this.props.awaySubstitutions.length; ++i){
            let sub = {...this.props.awaySubstitutions[i].index};
            if(typeof sub.number === 'undefined'){
                sub = {...this.props.awaySubstitutions[i]};
            }
            //awayTeamPlayers.push(sub);
            sub.number = 'S- ' + sub.number;
            sub.firstName = 'S- ' + sub.firstName;
            awayTeamPlayers.splice(sub.index+indexOffset, 0, sub);
            indexOffset += 1;
        }

        if(this.props.awayTeamFlex){
            let flex = {...this.props.awayTeamFlex}
            awayTeamPlayers.push(flex);
        }
        //awayTeamPlayers.push([...this.props.awaySubstitutions]);

        //console.log('away subs', this.props.awaySubstitutions);
        //console.log('away players', awayTeamPlayers);

        let title = null;
        if(this.props.game){
            title = <div className={styles.TitleContainer}>
                      <h3 style={{display: 'inline'}}>{this.isGameOver() === true ? 'FINAL - ' : ''}{this.props.awayTeam.name} at {this.props.homeTeam.name}  -
                      { new Date(this.props.game.date).toLocaleDateString("en-US")}</h3>
                      
                    </div>
        }
        let backToButton = <Button btnType="Danger" clicked={this.onShowGameHandler}>Back to Game</Button>


        
        const inning = this.currentInning();
        const isTop = this.isInningTop();

        let inningScoreTable = null;
        let homeTeamRoster = null;
        let awayTeamRoster = null;
        if(this.props.homeTeam && this.props.awayTeam && this.props.awayTeamLineup && this.props.homeTeamLineup){
            inningScoreTable = <InningScoreTable inning={inning} topBottom={isTop ? "TOP" : "BOTTOM"} />
            homeTeamRoster = <RosterStats team={this.props.homeTeam} lineup={homeTeamPlayers} pitchArray={this.props.pitchArray} outs={this.props.outs}/>
            awayTeamRoster = <RosterStats team={this.props.awayTeam} lineup={awayTeamPlayers} pitchArray={this.props.pitchArray} outs={this.props.outs}/>
        }

        


        return(
            <div className={styles.GameAnalysis}>
            <h1>Game Analysis</h1>
            {title}
            {backToButton}
            {inningScoreTable}
            {homeTeamRoster}
            {awayTeamRoster}
            </div>
        );
    }
}


const mapDispatchToProps = dispatch =>{
    return{
        onLoadGameState: (token, id)=>dispatch(
            ActionTypes.loadGameState(token, id)
        ),
        onLoadTrainingState: (token, id)=>dispatch(
            ActionTypes.loadTrainingState(token, id)
        )
    }
}


const mapStateToProps = state =>{
    return{

        game: state.atBat.game,
        homeTeam: state.atBat.homeTeam,
        awayTeam: state.atBat.awayTeam,
        homeTeamPlayers: state.atBat.homeTeamPlayers,
        awayTeamPlayers: state.atBat.awayTeamPlayers,
        currentInning: state.atBat.currentInning,
        currentPitcher: state.atBat.currentPitcher,
        currentHitter: state.atBat.currentHitter,
        battingRight: state.atBat.battingRight,
        atBatState: state.atBat,
        token: state.auth.token,
        pitchArray: state.atBat.pitchArray,
        outs: state.atBat.outs,
        pitchNumber: state.atBat.pitchNumber,
        homeScore: state.atBat.homeScore,
        awayScore: state.atBat.awayScore,
        gameOver: state.atBat.gameOver,
        homeTeamLineup: state.atBat.homeTeamLineup,
        homeTeamCurrentPitcher: state.atBat.homeTeamCurrentPitcher,
        awayTeamLineup: state.atBat.awayTeamLineup,
        homeTeamFlex: state.atBat.homeTeamFlex,
        awayTeamFlex: state.atBat.awayTeamFlex,
        awayTeamCurrentPitcher: state.atBat.awayTeamCurrentPitcher,
        totalInnings: state.atBat.totalInnings,
        homeTeamCurrentBatterIndex: state.atBat.homeTeamCurrentBatterIndex,
        awayTeamCurrentBatterIndex: state.atBat.awayTeamCurrentBatterIndex,
        inningScores: state.atBat.inningScores,
        currentTopInningScore: state.atBat.currentTopInningScore,
        currentBottomInningScore: state.atBat.currentBottomInningScore,
        pitcherView: state.atBat.pitcherView,
        training: state.atBat.training,
        homeSubstitutions: state.atBat.homeSubstitutions,
        awaySubstitutions: state.atBat.awaySubstitutions
        
    }
}




export default connect(mapStateToProps, mapDispatchToProps) (GameAnalysis);

