import * as actionTypes from './actionTypes';
import axios from '../../axios';

export const createOrgStart = () =>{
    return{
        type:actionTypes.CREATE_ORG_START
    };
}

export const createOrgSuccess = (id, data) =>{
    return{
        type:actionTypes.CREATE_ORG_SUCCESS,
        id: id,
        data: data
    };
}

export const createOrgFail = (error) =>{
    return{
        type:actionTypes.CREATE_ORG_FAIL,
        error: error
    };
}

export const createOrg = (token, data) =>{
    return dispatch =>{
        dispatch(createOrgStart());
        axios.post('organizations.json?auth=' + token, data)
            .then(response =>{
                ////console.log("id="+response.data.name);
                dispatch(createOrgSuccess(response.data.name, data));
            })
            .catch(error =>{
                ////console.log(error.response);
                dispatch(createOrgFail(error.response.data.error));
            });
    }
}

export const updateOrgStart = (id) =>{
    return{
        type:actionTypes.UPDATE_ORG_START,
        id: id
    };
}

export const updateOrgSuccess = (id, data) =>{
    return{
        type:actionTypes.UPDATE_ORG_SUCCESS,
        id: id,
        data: data
    };
}

export const updateOrgFail = (error) =>{
    return{
        type:actionTypes.UPDATE_ORG_FAIL,
        error: error
    };
}

export const updateOrg = (token, data) =>{
    return dispatch =>{
        dispatch(updateOrgStart(data.id));
        const id = data.id;
        delete data['id'];
        data.teamType = typeof data.teamType === 'undefined' ? 'Softball' : data.teamType;
        axios.patch('organizations/'+id+'.json?auth=' + token, data)
            .then(response =>{
                ////console.log("id="+response.data.name);
                dispatch(updateOrgSuccess(id, data));
            })
            .catch(error =>{
                ////console.log(error.response);
                dispatch(updateOrgFail(error.response.data.error));
            });
    }
}


//

export const getOrgStart = () =>{
    return{
        type:actionTypes.GET_ORG_START
    };
}

export const getOrgSuccess = (data) =>{
    return{
        type:actionTypes.GET_ORG_SUCCESS,
        data: data
    };
}

export const getOrgFail = (error) =>{
    return{
        type:actionTypes.GET_ORG_FAIL,
        error: error
    };
}

export const getOrg = (token, id) =>{
    return dispatch =>{
        dispatch(getOrgStart());
        axios.get('/organizations/'+id +'.json?auth=' + token)
        .then(response => {
            ////console.log('getOrg action:', response.data);
            let orgs = [];
            if(response.data !== null){

                let newOrg = {
                    ...response.data,
                    userID: response.data.userId,
                    id: id
                }
                newOrg.teamType = typeof newOrg.teamType === 'undefined' ? 'Softball' : newOrg.teamType;
                
                orgs.push(newOrg);
                dispatch(getOrgSuccess(orgs));
            }
            else{
                dispatch(getOrgFail('no results found for id ' + id));
            }
            
        })
        .catch(error =>{
            dispatch(getOrgFail());
        });
    }
}


////


export const getOrgsStart = () =>{
    return{
        type:actionTypes.GET_ORGS_START
    };
}

export const getOrgsSuccess = (data) =>{
    return{
        type:actionTypes.GET_ORGS_SUCCESS,
        data: data
    };
}

export const getOrgsFail = (error) =>{
    return{
        type:actionTypes.GET_ORGS_FAIL,
        error: error
    };
}

export const getOrgs = () =>{
    return dispatch =>{
        dispatch(getOrgsStart());

        //const queryParams = '?auth=' + token;
        //axios.get('/Orgs.json?auth=' + token)
        axios.get('/organizations.json')
        .then(response => {
            //console.log('getOrgs action:', response.data);
            const orgs = [];
            for(let key in response.data){
                let newOrg = {
                    ...response.data[key],
                    id: key
                }


                newOrg.teamType = typeof newOrg.teamType === 'undefined' ? 'Softball' : newOrg.teamType; 

                orgs.push(newOrg);
            }
           
            dispatch(getOrgsSuccess(orgs));
        })
        .catch(error =>{
            dispatch(getOrgsFail());
        });
    }
}