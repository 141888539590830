import React from 'react';
import styles from './HitActionButton.module.css';

const HitActionButton = (props) => {
    return (
    <div className={styles.HitActionButton}
        onClick={() => props.click(props.type)}
        >
    {props.type}
    </div>
    )
}

export default HitActionButton;