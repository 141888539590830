import React from 'react'
import styles from './PitchList.module.css';

import Button from '../../components/UI/Button/Button';

import PitchClass from '../../classes/Softball/Pitch';

const PitchList = (props) => {

    /*const renderPitches = props.pitches.map((p,i) => {
        return(

            <PitchListItem key={i} pitch={p} />
            
        )
    });*/

    let totalPitchesinTable = 10;

    let firstRow = [];
    let secondRow = [];
    // firstRow.push(<td>P</td>);
    // secondRow.push(<td>LOC</td>);
    let totalPitchTables = 0;

    if(props.pitches.length > 0){
        totalPitchTables = Math.ceil(props.pitches.length / parseFloat(totalPitchesinTable));
    }

    let start = 0;
    for(let j=0;j<totalPitchTables; ++j){

        let _firstRow = [];
        let _secondRow = [];
        // _firstRow.push(<td>P</td>);
        // _secondRow.push(<td>LOC</td>);
        //let start = j;
        //let finish = (start + totalPitchesinTable) > props.pitches.length ? props.pitches.length : totalPitchesinTable;
        let finish = (start + totalPitchesinTable) > props.pitches.length ? props.pitches.length : (start + totalPitchesinTable);
        
        for(let i=start; i< finish; ++i){
            const pitch = props.pitches[i];
             
    
            if(PitchClass.isStrike(pitch)){
                _firstRow.push(<td key={'firstRow'+ i} className={styles.Strike}>{PitchClass.PitchTypeAbbrv(pitch)}</td>);
                _secondRow.push(<td key={'secondRow' + i} className={styles.Strike}>{PitchClass.LocationAbbrv(pitch)}</td>);
            }
            else{
                _firstRow.push(<td key={'firstRow'+ i} className={styles.Ball}>{PitchClass.PitchTypeAbbrv(pitch)}</td>);
                _secondRow.push(<td key={'secondRow' + i} className={styles.Ball}>{PitchClass.LocationAbbrv(pitch)}</td>);
            } 
            start ++;
        }
        for(let i = finish; i < totalPitchesinTable; ++i){
            _firstRow.push(<td key={'firstRow'+ i}></td>);
            _secondRow.push(<td key={'secondRow' + i}></td>);
        }
        firstRow.push(_firstRow);
        secondRow.push(_secondRow);

    }

    

    // for(let i = props.pitches.length; i < 10; ++i){
    //     firstRow.push(<td key={'firstRow'+ i}></td>);
    //     secondRow.push(<td key={'secondRow' + i}></td>);
    // }

    const renderPop = props.pitches.length > 0 ? 
    (
        <Button customStyle={{    verticalAlign: "top",
            width: "51px",
            height: "37px",
            padding: "0px",
            margin: "0px",
            marginLeft: "10px"}}
            
            clicked={props.popPitchHandler} btnType="Red">UNDO</Button>
    ): null;

    let renderInningColumns = [];
    //renderInningColumns.push(<td>P#</td>)
    for(let i=0;i<totalPitchesinTable; ++i){
        renderInningColumns.push(<td key={i}>{i+1}</td>)
    }


    let renderTables = [];
    for(let i=0; i< totalPitchTables; ++i){
        renderTables.push(
            <table key={"renderTables-"+ i}>
                <thead>
                    <tr>
                        {renderInningColumns}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {firstRow[i]}
                    </tr>
                    <tr>
                        {secondRow[i]}
                    </tr>
                </tbody>
            </table>
        )

    }


    return (
        <div className={styles.PitchList}>
            
           
            <div className={styles.PopContainer}>
                {renderTables}
            
            </div>
            {/*renderPop*/}
        </div>
    )
}


export default PitchList;


