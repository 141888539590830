import React from 'react';
import styles from './HitResultButton.module.css';

const HitResultButton = (props) =>{
    return (
        <div className={styles.HitResultButton}
            onClick={() => props.click(props.type)}
        >
            {props.type}
        </div>
    )
}

export default HitResultButton;
