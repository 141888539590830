import React, {Component} from 'react';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import Spinner from '../../../components/UI/Spinner/Spinner';
import classes from './TeamImportForm.module.css';
import { connect } from 'react-redux';
import * as ActionTypes from '../../../store/actions/index';


class TeamImportForm extends Component{
    state = {
        controls: {
           
            name: {
                elementType: 'react-select',
                elementConfig: {
                    
                    label: 'Team',
                    options : []
                    
                },
                value:'new',
                validation: {},
                valid:true
            },
           
            
           
            
            
        },
        formIsValid: false,
        id: null,
        
    }

    componentDidMount(){
        
        //this.props.onGetNCAATeams();
        //console.log('TeamImportForm componentDidMount');

        if(this.props.auser){
           
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.teams !== this.props.teams){
            const updatedForm = {
                ...this.state.controls
            }

            const updatedFormName = {...updatedForm['name']};
            //updatedFormOrgs.value = this.props.auser.organizationId;//this.props.orgs[0].id;
            let updatedOptions = [];
            let team;
            //console.log('UPDATE THIS STATE IMPORT TEAMS', this.props.teams)
            for(team in this.props.teams){
                let option = {
                    value: this.props.teams[team].id,
                    label: this.props.teams[team].name +' '+ this.props.teams[team].year
                }
                updatedOptions.push(option);
            }
            //console.log('updatedOptions', updatedOptions);
            updatedFormName.elementConfig.options = updatedOptions;
            updatedFormName.valid = true;
            updatedFormName.value = '';
            updatedForm['name'] = updatedFormName;
            let formIsValid = true;
            for(let inputIds in updatedForm){
                formIsValid = updatedForm[inputIds].valid && formIsValid;
            }
            this.setState({controls: updatedForm, formIsValid: formIsValid});
                
        }
        
    }

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }
        
        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        if (rules.isNumeric) {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }


    inputChangedHandler = (event, inputIdentifier) =>{

        const updatedForm = {
            ...this.state.controls
        }
        let targetValue = null;
        targetValue = event;

        
        //console.log('inputChangeHandler event', targetValue);
       
        const updatedFormElement = {...updatedForm[inputIdentifier]};
        
        updatedFormElement.value = targetValue;
        
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        ////console.log(updatedFormElement.valid, updatedFormElement.validation ? true : false);
        updatedForm[inputIdentifier] = updatedFormElement;

        let formIsValid = true;
        for(let inputIds in updatedForm){
            //console.log(inputIds + ' ' + updatedForm[inputIds].valid);
            formIsValid = updatedForm[inputIds].valid && formIsValid;
        }
        this.setState({controls: updatedForm, formIsValid: formIsValid});
    }

    submitHandler = (event) =>{
        event.preventDefault();
        ////console.log('calling submitHandler in TeamForm ' 
        
        //+ this.state.controls.name.value+' '
        //+ this.state.controls.year.value);

        let teamName = '';
        let teamId = '';
        //console.log('this.state.controls.name.value ', this.state.controls.name.value);

        const teamData = {
           id: this.state.controls.name.value.value
        }
        //console.log("teamData", teamData);
        this.props.submit(event, teamData);
    }


    render(){

        const formElementsArray = [];
        for(let key in this.state.controls){
            if(this.state.controls[key].show !== false){
                formElementsArray.push({
                    id: key,
                    config: this.state.controls[key],
                    validation: this.state.controls[key].validation,
                    valid: this.state.controls[key].valid,
                    touched: this.state.controls[key].touched
                });
            }
        }

        let form = (
            <form onSubmit={(event)=>this.submitHandler(event)}>
                    {formElementsArray.map(el => (
                        <Input 
                            key={el.id}
                            elementType={el.config.elementType}
                            elementConfig={el.config.elementConfig}
                            label={el.config.elementConfig.label}
                            value={el.config.value}
                            invalid = {!el.valid}
                            shouldValidate ={el.validation && el.touched}
                            changed={(event)=> this.inputChangedHandler(event, el.id)}/>
                    ))}
                        
                    
                    <Button disabled={!this.state.formIsValid} btnType="Success">SUBMIT</Button>
                </form>
        );

        if(this.props.loading){
            form = <Spinner />;
        }
        let message = null;
        if(this.props.error){
            message = <span className={classes.Error}>{this.props.message}</span>
        }
        else if(this.props.message !== null){
            message = <span className={classes.Success}>{this.props.message}</span>
        }


        return (
            <div className={classes.TeamForm}>
                <h4>Import Teams</h4>
                {message}
                {form}
            </div>
        )
    }
}
const mapDispatchToProps = dispatch =>{
    return{
        
    }
}


const mapStateToProps = state =>{
    return{
        error: state.team.error,
        message: state.team.message,
        loading: state.team.loading,
        token: state.auth.token,
        teams: state.team.importTeams,
        auser: state.auth.user,
        ncaaTeams: state.team.ncaaTeams,
        ncaaTeamOptions: state.team.ncaaTeamOptions
    };
}

export default connect(mapStateToProps, mapDispatchToProps) (TeamImportForm);
