import React from 'react';
import styles from './PitchTable.module.css';

const PitchTableBaseball = (props) =>{
    let FB4SPercent = 0;
    let FB2SPercent = 0;
    let XCHPercent = 0;
    let CRVPercent = 0;
    let SLDPercent = 0;
    let CUTPercent = 0;
    let OTHERPercent = 0;

    // options = [
    //     'FB-4S',
    //     'FB-2S',
    //     'XCHANGE',
    //     'CURVE',
    //     'SLIDER',
    //     'CUTTER',
    //     'OTHER'
    // ];

   let FB4SCount = getCount('FB-4S', props.pitchArray);
   let FB2SCount = getCount('FB-2S',  props.pitchArray);
   let XCHCount = getCount('XCHANGE',  props.pitchArray);
   let CRVCount = getCount('CURVE',  props.pitchArray);
   let SLDCount = getCount('SLIDER',  props.pitchArray);
   let CUTCount = getCount('CUTTER',  props.pitchArray);
   let OTHERCount = getCount('OTHER',  props.pitchArray);
   

   FB4SPercent = getPercent('FB-4S', props.pitchArray);
   FB2SPercent = getPercent('FB-2S', props.pitchArray);
   XCHPercent = getPercent('XCHANGE', props.pitchArray);
   CRVPercent = getPercent('CURVE', props.pitchArray);
   SLDPercent = getPercent('SLIDER', props.pitchArray);
   CUTPercent = getPercent('CUTTER', props.pitchArray);
   OTHERPercent = getPercent('OTHER', props.pitchArray);



   function getCount(type, pitchArray){
       let ret = new Array(12);
       for(let i=0;i<12;++i){
           ret[i] = 0;
       }
       for(let i=0;i<pitchArray.length; ++i){
        const p = pitchArray[i];
        if(p.pitchType === type){
            if(p.ballCount ==0 && p.strikeCount == 0){
                ret[0] = ret[0] + 1;
            }
            else if(p.ballCount ==0 && p.strikeCount == 1){
                ret[1] = ret[1] + 1;
            }
            else if(p.ballCount ==0 && p.strikeCount == 2){
                ret[2] = ret[2] + 1;
            }
            else if(p.ballCount ==1 && p.strikeCount == 0){
                ret[3] = ret[3] + 1;
            }
            else if(p.ballCount ==1 && p.strikeCount == 1){
                ret[4] = ret[4] + 1;
            }
            else if(p.ballCount ==1 && p.strikeCount == 2){
                ret[5] = ret[5] + 1;
            }
            else if(p.ballCount ==2 && p.strikeCount == 0){
                ret[6] = ret[6] + 1;
            }
            else if(p.ballCount ==2 && p.strikeCount == 1){
                ret[7] = ret[7] + 1;
            }
            else if(p.ballCount ==2 && p.strikeCount == 2){
                ret[8] = ret[8] + 1;
            }
            else if(p.ballCount ==3 && p.strikeCount == 0){
                ret[9] = ret[9] + 1;
            }
            else if(p.ballCount ==3 && p.strikeCount == 1){
                ret[10] = ret[10] + 1;
            }
            else if(p.ballCount ==3 && p.strikeCount == 2){
                ret[11] = ret[11] + 1;
            }
        }
    }
    return ret;
   }

    function getPercent(type, pitchArray){
        let ret = 0;
        for(let i=0;i<pitchArray.length; ++i){
            const p = pitchArray[i];
            if(p.pitchType === type){
                ret ++;
            }
        }
        if(pitchArray.length > 0){
            ret = ret / parseFloat(pitchArray.length);
            ret = (ret * 100).toFixed(0);
        }
        return ret;
    }

    let mincolor = {
        r: 142,
        g: 169,
        b: 219
    }

    let maxcolor = {
        r: 254,
        g: 153,
        b: 102
    }

    function getColor(p){
        //Calculate the average (r',g',b') = ( (r1+r2)/2, (g1+g2)/2, (b1+b2)/2 )
        let r = mincolor.r * parseFloat( 1.0 - p) + maxcolor.r * parseFloat(p);
        let g = mincolor.g * parseFloat( 1.0 - p) + maxcolor.g * parseFloat(p);
        let b = mincolor.b * parseFloat( 1.0 - p) + maxcolor.b * parseFloat(p);

        let ret = {
            r : r,
            g: g,
            b: b
        }
        return ret
    }

    function getColorStr(p){
        let color = getColor(p);
        return "rgb("+ color.r+","+color.g+","+color.b+")"
    }

    return (
        <div className={styles.PitchTable}>
        <table>
            <thead>
                <tr style={{backgroundColor: '#404040'}}>
                <td>PT</td>
                <td>%P</td>
                <td>0-0</td>
                <td>0-1</td>
                <td>0-2</td>
                <td>1-0</td>
                <td>1-1</td>
                <td>1-2</td>
                <td>2-0</td>
                <td>2-1</td>
                <td>2-2</td>
                <td>3-0</td>
                <td>3-1</td>
                <td>3-2</td>
                </tr>
            </thead>
            <tbody>
           
                <tr>
                    <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterPitchType("FB-4S")}>FB-4S</td>
                    <td style={{backgroundColor:getColorStr(FB4SPercent)}}>{FB4SPercent}</td>
                    <td style={{backgroundColor:getColorStr(FB4SCount[0])}}>{FB4SCount[0]}</td>
                    <td style={{backgroundColor:getColorStr(FB4SCount[1])}}>{FB4SCount[1]}</td>
                    <td style={{backgroundColor:getColorStr(FB4SCount[2])}}>{FB4SCount[2]}</td>
                    <td style={{backgroundColor:getColorStr(FB4SCount[3])}}>{FB4SCount[3]}</td>
                    <td style={{backgroundColor:getColorStr(FB4SCount[4])}}>{FB4SCount[4]}</td>
                    <td style={{backgroundColor:getColorStr(FB4SCount[5])}}>{FB4SCount[5]}</td>
                    <td style={{backgroundColor:getColorStr(FB4SCount[6])}}>{FB4SCount[6]}</td>
                    <td style={{backgroundColor:getColorStr(FB4SCount[7])}}>{FB4SCount[7]}</td>
                    <td style={{backgroundColor:getColorStr(FB4SCount[8])}}>{FB4SCount[8]}</td>
                    <td style={{backgroundColor:getColorStr(FB4SCount[9])}}>{FB4SCount[9]}</td>
                    <td style={{backgroundColor:getColorStr(FB4SCount[10])}}>{FB4SCount[10]}</td>
                    <td style={{backgroundColor:getColorStr(FB4SCount[11])}}>{FB4SCount[11]}</td>
                </tr>
                <tr >
                    <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterPitchType("FB-2S")}>FB-2S</td>
                    <td style={{backgroundColor:getColorStr(FB2SPercent)}}>{FB2SPercent}</td>
                    <td style={{backgroundColor:getColorStr(FB2SCount[0])}}>{FB2SCount[0]}</td>
                    <td style={{backgroundColor:getColorStr(FB2SCount[1])}}>{FB2SCount[1]}</td>
                    <td style={{backgroundColor:getColorStr(FB2SCount[2])}}>{FB2SCount[2]}</td>
                    <td style={{backgroundColor:getColorStr(FB2SCount[3])}}>{FB2SCount[3]}</td>
                    <td style={{backgroundColor:getColorStr(FB2SCount[4])}}>{FB2SCount[4]}</td>
                    <td style={{backgroundColor:getColorStr(FB2SCount[5])}}>{FB2SCount[5]}</td>
                    <td style={{backgroundColor:getColorStr(FB2SCount[6])}}>{FB2SCount[6]}</td>
                    <td style={{backgroundColor:getColorStr(FB2SCount[7])}}>{FB2SCount[7]}</td>
                    <td style={{backgroundColor:getColorStr(FB2SCount[8])}}>{FB2SCount[8]}</td>
                    <td style={{backgroundColor:getColorStr(FB2SCount[9])}}>{FB2SCount[9]}</td>
                    <td style={{backgroundColor:getColorStr(FB2SCount[10])}}>{FB2SCount[10]}</td>
                    <td style={{backgroundColor:getColorStr(FB2SCount[11])}}>{FB2SCount[11]}</td>
                </tr>
                <tr>
                    <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterPitchType("XCH")}>XCH</td>
                    <td style={{backgroundColor:getColorStr(XCHPercent)}}>{XCHPercent}</td>
                    <td style={{backgroundColor:getColorStr(XCHCount[0])}}>{XCHCount[0]}</td>
                    <td style={{backgroundColor:getColorStr(XCHCount[1])}}>{XCHCount[1]}</td>
                    <td style={{backgroundColor:getColorStr(XCHCount[2])}}>{XCHCount[2]}</td>
                    <td style={{backgroundColor:getColorStr(XCHCount[3])}}>{XCHCount[3]}</td>
                    <td style={{backgroundColor:getColorStr(XCHCount[4])}}>{XCHCount[4]}</td>
                    <td style={{backgroundColor:getColorStr(XCHCount[5])}}>{XCHCount[5]}</td>
                    <td style={{backgroundColor:getColorStr(XCHCount[6])}}>{XCHCount[6]}</td>
                    <td style={{backgroundColor:getColorStr(XCHCount[7])}}>{XCHCount[7]}</td>
                    <td style={{backgroundColor:getColorStr(XCHCount[8])}}>{XCHCount[8]}</td>
                    <td style={{backgroundColor:getColorStr(XCHCount[9])}}>{XCHCount[9]}</td>
                    <td style={{backgroundColor:getColorStr(XCHCount[10])}}>{XCHCount[10]}</td>
                    <td style={{backgroundColor:getColorStr(XCHCount[11])}}>{XCHCount[11]}</td>
                </tr>
               
                <tr>
                    <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterPitchType("CRV")}>CRV</td>
                    <td style={{backgroundColor:getColorStr(CRVPercent)}}>{CRVPercent}</td>
                    <td style={{backgroundColor:getColorStr(CRVCount[0])}}>{CRVCount[0]}</td>
                    <td style={{backgroundColor:getColorStr(CRVCount[1])}}>{CRVCount[1]}</td>
                    <td style={{backgroundColor:getColorStr(CRVCount[2])}}>{CRVCount[2]}</td>
                    <td style={{backgroundColor:getColorStr(CRVCount[3])}}>{CRVCount[3]}</td>
                    <td style={{backgroundColor:getColorStr(CRVCount[4])}}>{CRVCount[4]}</td>
                    <td style={{backgroundColor:getColorStr(CRVCount[5])}}>{CRVCount[5]}</td>
                    <td style={{backgroundColor:getColorStr(CRVCount[6])}}>{CRVCount[6]}</td>
                    <td style={{backgroundColor:getColorStr(CRVCount[7])}}>{CRVCount[7]}</td>
                    <td style={{backgroundColor:getColorStr(CRVCount[8])}}>{CRVCount[8]}</td>
                    <td style={{backgroundColor:getColorStr(CRVCount[9])}}>{CRVCount[9]}</td>
                    <td style={{backgroundColor:getColorStr(CRVCount[10])}}>{CRVCount[10]}</td>
                    <td style={{backgroundColor:getColorStr(CRVCount[11])}}>{CRVCount[11]}</td>
                </tr>
                

                <tr>
                    <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterPitchType("SLD")}>SLD</td>
                    <td style={{backgroundColor:getColorStr(SLDPercent)}}>{SLDPercent}</td>
                    <td style={{backgroundColor:getColorStr(SLDCount[0])}}>{SLDCount[0]}</td>
                    <td style={{backgroundColor:getColorStr(SLDCount[1])}}>{SLDCount[1]}</td>
                    <td style={{backgroundColor:getColorStr(SLDCount[2])}}>{SLDCount[2]}</td>
                    <td style={{backgroundColor:getColorStr(SLDCount[3])}}>{SLDCount[3]}</td>
                    <td style={{backgroundColor:getColorStr(SLDCount[4])}}>{SLDCount[4]}</td>
                    <td style={{backgroundColor:getColorStr(SLDCount[5])}}>{SLDCount[5]}</td>
                    <td style={{backgroundColor:getColorStr(SLDCount[6])}}>{SLDCount[6]}</td>
                    <td style={{backgroundColor:getColorStr(SLDCount[7])}}>{SLDCount[7]}</td>
                    <td style={{backgroundColor:getColorStr(SLDCount[8])}}>{SLDCount[8]}</td>
                    <td style={{backgroundColor:getColorStr(SLDCount[9])}}>{SLDCount[9]}</td>
                    <td style={{backgroundColor:getColorStr(SLDCount[10])}}>{SLDCount[10]}</td>
                    <td style={{backgroundColor:getColorStr(SLDCount[11])}}>{SLDCount[11]}</td>
                </tr>
 
                <tr>
                    <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterPitchType("CUT")}>CUT</td>
                    <td style={{backgroundColor:getColorStr(CUTPercent)}}>{CUTPercent}</td>
                    <td style={{backgroundColor:getColorStr(CUTCount[0])}}>{CUTCount[0]}</td>
                    <td style={{backgroundColor:getColorStr(CUTCount[1])}}>{CUTCount[1]}</td>
                    <td style={{backgroundColor:getColorStr(CUTCount[2])}}>{CUTCount[2]}</td>
                    <td style={{backgroundColor:getColorStr(CUTCount[3])}}>{CUTCount[3]}</td>
                    <td style={{backgroundColor:getColorStr(CUTCount[4])}}>{CUTCount[4]}</td>
                    <td style={{backgroundColor:getColorStr(CUTCount[5])}}>{CUTCount[5]}</td>
                    <td style={{backgroundColor:getColorStr(CUTCount[6])}}>{CUTCount[6]}</td>
                    <td style={{backgroundColor:getColorStr(CUTCount[7])}}>{CUTCount[7]}</td>
                    <td style={{backgroundColor:getColorStr(CUTCount[8])}}>{CUTCount[8]}</td>
                    <td style={{backgroundColor:getColorStr(CUTCount[9])}}>{CUTCount[9]}</td>
                    <td style={{backgroundColor:getColorStr(CUTCount[10])}}>{CUTCount[10]}</td>
                    <td style={{backgroundColor:getColorStr(CUTCount[11])}}>{CUTCount[11]}</td>
                </tr>

                <tr>
                    <td style={{backgroundColor: '#404040'}} onClick={()=>props.onFilterPitchType("OTHER")}>OTHER</td>
                    <td style={{backgroundColor:getColorStr(OTHERPercent)}}>{OTHERPercent}</td>
                    <td style={{backgroundColor:getColorStr(OTHERCount[0])}}>{OTHERCount[0]}</td>
                    <td style={{backgroundColor:getColorStr(OTHERCount[1])}}>{OTHERCount[1]}</td>
                    <td style={{backgroundColor:getColorStr(OTHERCount[2])}}>{OTHERCount[2]}</td>
                    <td style={{backgroundColor:getColorStr(OTHERCount[3])}}>{OTHERCount[3]}</td>
                    <td style={{backgroundColor:getColorStr(OTHERCount[4])}}>{OTHERCount[4]}</td>
                    <td style={{backgroundColor:getColorStr(OTHERCount[5])}}>{OTHERCount[5]}</td>
                    <td style={{backgroundColor:getColorStr(OTHERCount[6])}}>{OTHERCount[6]}</td>
                    <td style={{backgroundColor:getColorStr(OTHERCount[7])}}>{OTHERCount[7]}</td>
                    <td style={{backgroundColor:getColorStr(OTHERCount[8])}}>{OTHERCount[8]}</td>
                    <td style={{backgroundColor:getColorStr(OTHERCount[9])}}>{OTHERCount[9]}</td>
                    <td style={{backgroundColor:getColorStr(OTHERCount[10])}}>{OTHERCount[10]}</td>
                    <td style={{backgroundColor:getColorStr(OTHERCount[11])}}>{OTHERCount[11]}</td>
                </tr>

            </tbody>
        </table>
        </div>
    )
}

export default PitchTableBaseball;