import React, {Component} from 'react';
import { connect } from 'react-redux';
import Spinner from '../../components/UI/Spinner/Spinner';
import * as ActionTypes from '../../store/actions/index';
import TeamComponent from '../../components/Team/Team';
import TeamForm from '../../containers/Teams/TeamForm/TeamForm';
import PlayerForm from '../../containers/Player/PlayerForm/PlayerForm';
import classes from './Team.module.css';
import Aux from '../../hoc/Aux';
import Modal from '../../components/UI/Modal/Modal';
import Button from '../../components/UI/Button/Button';


import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

class Team extends Component{

    constructor(props){
        super(props);
        this.state = {
            columnDefs: [
            {
                headerName: "ID", field: "id", sortable: false, filter: false, hide: true
            },
            {
                headerName: "Number", field: "number", sortable: true, filter: true
            },  {
                headerName: "First Name", field: "firstName", sortable: true, filter: true
            }
            ,{
                headerName: "Last Name", field: "lastName", sortable: true, filter: true
            }
            ,{
                headerName: "Grad Yr", field: "gradYear", sortable: true, filter: true
            }
            ,{
                headerName: "Bats", field: "battingSide", sortable: true, filter: true
            }
            ,{
                headerName: "Speed", field: "speed", sortable: true, filter: true
            }
            ,{
                headerName: "Delete", field: "delete", sortable: false, filter: true
            }
            ],
            rowData: [],
            editing : false,
            id: null,
            showUpdateTeam: false,
            editPlayer: null,
            reloadComponent: false,
            reloadCount: 0,
            notes: '',
            noteLoaded:false,
            showDelete: false,
            playerData: null,
            teamId: null
            
        }
        
    }
    
    componentDidMount(){
        this.props.onLoadTeam(this.props.token, this.props.match.params.id);
        this.props.onGetTeamPlayers(this.props.token, this.props.match.params.id);
        this.setState({
            teamId:  this.props.match.params.id
        })
    }
    componentDidUpdate(prevProps, prevState){
        //console.log('calling componentDidUpdate');
        let reload = false;

        if(this.state.reloadComponent){
            let reloadCount = this.state.reloadCount;

            if(reloadCount< 2){
                reload = true;
            }
            reloadCount = reloadCount + 1;

            this.setState({
                reloadComponent: reload,
                reloadCount: reloadCount
            });
        }
        if(prevProps.players && this.props.players && prevProps.players.length !== this.props.players.length){
            reload = true;

        }
        else if(this.props.players && prevProps.players){
            for(let i=0; i < this.props.players.length; ++i){
                const pp = prevProps.players[i];
                const p = this.props.players[i];
                if(pp.firstName !== p.firstName || 
                pp.lastName !== p.lastName ||
                pp.number !== p.number){
                    reload = true;
                }
            }
        }
        if(this.props.teams.length === 1 && !this.state.noteLoaded && this.state.notes !== this.props.teams[0].notes){
            //console.log('set notes ' + this.props.teams[0].notes);
            this.setState({
                notes: this.props.teams[0].notes,
                noteLoaded:true
            })
        }


        if(reload){
            this.props.onLoadTeam(this.props.token, this.props.match.params.id);
            this.props.onGetTeamPlayers(this.props.token, this.props.match.params.id);
            this.setState({
                teamId:  this.props.match.params.id
            })
        }

       
        /*//console.log('should update players');   
        this.props.onLoadTeam(this.props.token, this.props.match.params.id);
        this.props.onGetTeamPlayers(this.props.token, this.props.match.params.id);
          */ 
        
    }

    onGridReady = params => {
        ////console.log('onGridReady!');
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
    }

    canEdit = () =>{

        if(this.props.auser && (this.props.auser.role === 'SysAdmin' ||
        this.props.auser.role === 'OrgAdmin' ||
        this.props.auser.role === 'Admin' ||
        this.props.auser.role === 'OrgAdminTraining' ||
        this.props.auser.role === 'AdminTraining')){
            return true;
        }
        return false;
    }

    submitHandler = (event, teamData) =>{
        event.preventDefault();
        ////console.log('calling submitHandler in Team Container ' 
        //+ teamData.name+" "
        //+ teamData.year+" "
       // + teamData.id);

        if(teamData.id !== null){
            this.props.onUpdateTeam(this.props.token, teamData);
        }else{
            this.props.onCreateTeam(this.props.token, teamData);
        }
        
        
    }

    submitAddPlayerHandler = (event, playerData) =>{
        event.preventDefault();
        //console.log('calling submitAddPlayerHandler in Team Container '
        //+ playerData.firstName+" "
        //+ playerData.lastName+" "
        //+ playerData.number + " "
        //+ playerData.id + " "
        //+ playerData.teamId);


        let updatedPlayer = {...playerData, organizationId: this.props.auser.organizationId}




        //console.log(updatedPlayer);

        if(playerData.id !== null){
            this.props.onUpdatePlayer(this.props.token, updatedPlayer);
        }else{
            this.props.onCreatePlayer(this.props.token, updatedPlayer);
        }

        this.setState({
            editing : false,
            editPlayer: null,
            reloadComponent: true,
            reloadCount: 0
        });



        // + teamData.year+" "
        // + teamData.id);

        // if(teamData.id !== null){
        //     this.props.onUpdateTeam(this.props.token, teamData);
        // }else{
        //     this.props.onCreateTeam(this.props.token, teamData);
        // }
        
        
    }

    playerClickHandler = (event, id) =>{
        ////console.log('Team->playerClickHandler ' + id, event);
        ////console.log('player?id=' + id);
        ////console.log(this.props.match.url);
        this.setState({
            editing: true,
            id: id
        })

        // this.props.history.push({
        //     pathname: '/player/' + id
        // });
    }
    editPlayerCancelHandler = () => {
        ////console.log('editPlayerCancelHandler');
        this.setState({
            editing: false
        })
    }

    onCellClicked = (result) =>{
        //console.log("onCellClicked", result);
        //console.log("player clicked", result.data);

        if(result.colDef.field === 'delete'){
            //console.log("Delete Player ", result.data.id);
            this.setState({
                showDelete: true,
                playerData: result.data
            })
        }
        else{


            this.setState({
                editing : true,
                editPlayer: result.data
            });
        }
    
    }

   
    noteMessageChange = (event) =>{
        //console.log(event.target.value);
        this.setState({
            notes: event.target.value
        })
    }
    
    handleSaveNote = () =>{
        if(this.props.teams.length === 1){
            let updatedTeam = {...this.props.teams[0], notes: this.state.notes};
            //console.log('updatedTeam',updatedTeam);
            this.props.onUpdateTeam(this.props.token, updatedTeam);
        }
    }

    showDeleteCancel = () =>{
        this.setState({
            showDelete: false
        })
    }

    onDeletePlayer = () =>{
       
        //console.log('Delete Player!' + this.state.playerData.id);

        let updatedPlayer = null;
        for(let i=0;i<this.props.players.length; ++i){
            let t = this.props.players[i];
            if(t.id === this.state.playerData.id){
                updatedPlayer = {...t,  hidden: true}
            }
        }

        this.props.onUpdatePlayer(this.props.token, updatedPlayer);


        this.setState({
            showDelete:false
        });
        //this.props.onDeleteTeam(this.props.token,this.state.teamData.id);
        //this.props.history.push('/teams');
    }

    onTeamScoutingReport = () =>{
        this.props.history.push({
            pathname: '/team_scouting_report/' + this.state.teamId
        });
    }

    render(){
        let deleteForm = null;
        if(this.state.playerData){
            
            deleteForm = <Modal show={this.state.showDelete} clickBackdrop={this.showDeleteCancel}>
                <div style={{textAlign:'center'}}> 
                <h3>{this.state.playerData.firstName} {this.state.playerData.lastName} </h3>
                <p>Are you sure?</p>
                <Button clicked={this.onDeletePlayer} btnType="Red">DELETE PLAYER</Button>
                </div>
            </Modal>
        }



        ////console.log('Players:');
        ////console.log(this.props.players);
        let teamDetails = <Spinner />;
        let teamNotes = '';
        if(this.props.error){
            teamDetails = <h1>{this.props.message}</h1>
        }
        else if(this.props.teams.length === 1){
            const team = this.props.teams[0];
            teamDetails =  <TeamComponent key={this.props.teams[0].id} 
            team={this.props.teams[0]} />

            teamDetails = <TeamForm key={team.id}  title="Edit Team" submit={this.submitHandler} team={this.props.teams[0]}/>
            teamNotes = this.props.teams[0].notes;
           
        }
        else if(!this.props.loading && this.props.teams.length !== 1){
            teamDetails = <h1>More or less than 1 Team returned</h1>
        }

        let createPlayerForm = <PlayerForm 
            loading={this.props.playerId === null? this.props.playerLoading : null}
            message ={this.props.playerId === null ? this.props.playerMessage : null} 
            player={null} team={this.props.teams[0]} 
            submit={this.submitAddPlayerHandler}/>;
        if(this.props.playerLoading){
            createPlayerForm = <Spinner />;
        }


        ////console.log('Players:');
        ////console.log(this.props.players);

        //let players = null;
        let postSortPlayers = null;
        let playerArray = [];
        if(this.props.players){

            
            ////console.log('sorting players by number');
            postSortPlayers = this.props.players.sort(function(obj1, obj2) {
                //sort by player number asc
                return obj1.number - obj2.number;
            });
        

            for(let i=0;i<postSortPlayers.length; ++i){
                let p = postSortPlayers[i];
                if(typeof p.hidden !== 'undefined' && p.hidden === true){
                    continue;
                }
                else{
                    playerArray.push({
                        id: p.id,
                        number: p.number,
                        firstName: p.firstName,
                        lastName : p.lastName,
                        gradYear: p.gradYear,
                        battingSide: p.battingSide,
                        speed: p.speed,
                        delete: "Delete"
                    })
                }
            }

            /*players = postSortPlayers.map(player =>{
                let playerCode = <Player key={player.id} 
                player={player} 
                playerClick={(event)=> this.playerClickHandler(event, player.id)} />
                return(
                    playerCode
                )
            });*/
        }
        else{
            //players = <Spinner />
        }

        let scoutingReportButton = null;

        
        scoutingReportButton = <Button clicked={this.onTeamScoutingReport} btnType="Danger">SCOUTING REPORT</Button>
        


        let updatePlayer = null;
        if(this.state.editing){
            ////console.log('editing trying to retrieve player with id ' + this.state.id);
            ////console.log(this.props.players);
            updatePlayer = this.props.players.find(player => player.id === this.state.id);
            ////console.log('update player = ');
            ////console.log(updatePlayer);
        }

        //this.state.rowData = playerArray;
        
        let tableJSX = <div 
                            className="ag-theme-balham-dark"
                            style={{ 
                            height: '400px', 
                            width: '700px',
                            marginLeft :'auto',
                            marginRight : 'auto',
                            fontSize:'15px',
                            fontFamily:'inherit'
                        }} 
                        >
                            <AgGridReact
                            // useful for accessing the component directly via ref
                            ref="agGrid"
                            onCellClicked={this.onCellClicked.bind(this)}
                            columnDefs={this.state.columnDefs}
                            //rowData={this.state.rowData}
                            rowData={playerArray}
                            onGridReady={this.onGridReady}>
                            </AgGridReact>
                        </div>

        let notesArea = <>
<h3>Notes</h3>
            <textarea className={classes.NotesArea} onChange={(event)=>this.noteMessageChange(event)}
            value={this.state.notes}></textarea>
            <Button clicked={this.handleSaveNote} btnType="Red">Save Note</Button>
        </>
        if(!this.canEdit()){
            teamDetails = null;
            createPlayerForm = null;
            notesArea = <>
            <h3>Notes</h3>
                        <textarea className={classes.NotesArea} onChange={(event)=>this.noteMessageChange(event)}
                        value={this.state.notes}></textarea>
                        
                    </>

        }


        return (
            <Aux>
            <Modal show={this.state.editing} clickBackdrop={this.editPlayerCancelHandler}>
               <PlayerForm  
                loading = {this.props.playerLoading}
                message = {this.props.playerMessage}
                team={this.props.teams[0]} 
                submit={this.submitAddPlayerHandler} 
                player={this.state.editPlayer}/>
            </Modal>
            {deleteForm}
            <div className={classes.Team}>            
            {scoutingReportButton}
            
            {teamDetails}
            {createPlayerForm}
            <h3>Players</h3>
            {/*<Button clicked={() => this.orderByNameHandler()} btnType="Success">Order By Name</Button>
            <Button clicked={() => this.orderByNumberHandler()} btnType="Success">Order By Number</Button>
            {players}
        */}
            {tableJSX}
            {notesArea}
            </div>
            </Aux>

        )
    }
}




const mapDispatchToProps = dispatch =>{
    return{
        onLoadTeam: (token, id) =>dispatch(
            ActionTypes.getTeam(token, id)
        ),
        onUpdateTeam: (token, payload)=>dispatch(
            ActionTypes.updateTeam(token,payload)
        ),
        onCreatePlayer: (token, payload)=>dispatch(
            ActionTypes.createPlayer(token, payload)
        ),
        onUpdatePlayer: (token, payload)=>dispatch(
            ActionTypes.updatePlayer(token, payload)
        ),
        onGetTeamPlayers: (token, payload)=>dispatch(
            ActionTypes.getTeamPlayers(token, payload)
        )
    }
}

const mapStateToProps = state =>{
    return{
        error: state.team.error,
        message: state.team.message,
        loading: state.team.loading,
        teams: state.team.teams,
        
        playerError: state.player.error,
        playerMessage: state.player.message,
        playerLoading: state.player.loading,
        players: state.player.players,
        playerId: state.player.id,
        token: state.auth.token,
        auser: state.auth.user,

        
        
        
    };
}

export default connect(mapStateToProps, mapDispatchToProps) (Team);