

class NCAA{
     
    static getTeams = () =>{
        
        return  [
            {id:2, team:'Abilene Christian'},
            {id:30123, team:'Academy of Art'},
            {id:929, team:'Adams St.'},
            {id:3, team:'Adelphi'},
            {id:4, team:'Adrian'},
            {id:11324, team:'Agnes Scott'},
            {id:5, team:'Akron'},
            {id:506337, team:'Akron-Wayne'},
            {id:8, team:'Alabama'},
            {id:6, team:'Alabama A&M'},
            {id:10, team:'Alabama Huntsville'},
            {id:7, team:'Alabama St.'},
            {id:9, team:'UAB'},
            {id:11, team:'Alas. Anchorage'},
            {id:12, team:'Alas. Fairbanks'},
            {id:14, team:'Albany (NY)'},
            {id:500075, team:'Albany Pharmacy'},
            {id:13, team:'Albany St. (GA)'},
            {id:506450, team:'Alberta (CAN)'},
            {id:2798, team:'Albertus Magnus'},
            {id:15, team:'Albion'},
            {id:16, team:'Albright'},
            {id:17, team:'Alcorn'},
            {id:934, team:'Alderson Broaddus'},
            {id:30170, team:'Alfred St.'},
            {id:18, team:'Alfred'},
            {id:506430, team:'Algoma (CAN)'},
            {id:500112, team:'Alice Lloyd'},
            {id:19, team:'Allegheny'},
            {id:500120, team:'Allen'},
            {id:21, team:'Alma'},
            {id:938, team:'Alvernia'},
            {id:28746, team:'Alverno'},
            {id:506413, team:'American (PR)'},
            {id:22, team:'American Int\'l'},
            {id:23, team:'American'},
            {id:24, team:'Amherst'},
            {id:506365, team:'Ana G. M�ndez (PR)'},
            {id:939, team:'Anderson (IN)'},
            {id:13028, team:'Anderson (SC)'},
            {id:506268, team:'Andrews'},
            {id:25, team:'Angelo St.'},
            {id:26, team:'Anna Maria'},
            {id:506476, team:'Antelope Valley'},
            {id:500174, team:'Appalachian Bible'},
            {id:27, team:'Appalachian St.'},
            {id:506125, team:'Apprentice'},
            {id:500180, team:'Aquinas'},
            {id:966, team:'Arcadia'},
            {id:29, team:'Arizona'},
            {id:500187, team:'Arizona Bible'},
            {id:504994, team:'Arizona Christian'},
            {id:28, team:'Arizona St.'},
            {id:30, team:'Arkansas St.'},
            {id:947, team:'Arkansas Tech'},
            {id:31, team:'Arkansas'},
            {id:30105, team:'Ark.-Fort Smith'},
            {id:32, team:'Little Rock'},
            {id:8411, team:'Ark.-Monticello'},
            {id:2678, team:'Ark.-Pine Bluff'},
            {id:500232, team:'Arlington Baptist'},
            {id:506029, team:'Asbury'},
            {id:35, team:'Ashland'},
            {id:36, team:'Assumption'},
            {id:37, team:'Auburn'},
            {id:30093, team:'AUM'},
            {id:38, team:'Augsburg'},
            {id:39, team:'Augusta'},
            {id:40, team:'Augustana (IL)'},
            {id:41, team:'Augustana (SD)'},
            {id:42, team:'Aurora'},
            {id:953, team:'Austin'},
            {id:43, team:'Austin Peay'},
            {id:506346, team:'Ave Maria'},
            {id:44, team:'Averett'},
            {id:500322, team:'Avila'},
            {id:30146, team:'Azusa Pacific'},
            {id:45, team:'Babson'},
            {id:506174, team:'Bacone'},
            {id:506059, team:'Baker'},
            {id:46, team:'Baldwin Wallace'},
            {id:47, team:'Ball St.'},
            {id:500365, team:'Baptist Bible (MO)'},
            {id:500381, team:'Barclay'},
            {id:962, team:'Bard'},
            {id:49, team:'Barry'},
            {id:15646, team:'Barton'},
            {id:58, team:'Baruch'},
            {id:50, team:'Bates'},
            {id:26520, team:'Bay Path'},
            {id:51, team:'Baylor'},
            {id:26107, team:'Becker'},
            {id:30197, team:'Belhaven'},
            {id:52, team:'Bellarmine'},
            {id:500415, team:'Bellevue'},
            {id:2683, team:'Belmont Abbey'},
            {id:14927, team:'Belmont'},
            {id:53, team:'Beloit'},
            {id:54, team:'Bemidji St.'},
            {id:55, team:'Benedict'},
            {id:506015, team:'Benedictine (KS)'},
            {id:506498, team:'Benedictine Mesa'},
            {id:296, team:'Benedictine (IL)'},
            {id:56, team:'Bentley'},
            {id:30171, team:'Berea'},
            {id:506167, team:'Berkeley (NJ)'},
            {id:506384, team:'Berkeley (NY)'},
            {id:973, team:'Berry'},
            {id:506014, team:'Bethany (KS)'},
            {id:59, team:'Bethany (WV)'},
            {id:30038, team:'Bethany Lutheran'},
            {id:500485, team:'Bethel (IN)'},
            {id:506120, team:'Bethel (KS)'},
            {id:500500, team:'Bethel (TN)'},
            {id:60, team:'Bethel (MN)'},
            {id:506379, team:'Bethesda (CA)'},
            {id:61, team:'Bethune-Cookman'},
            {id:62, team:'Binghamton'},
            {id:30220, team:'Biola'},
            {id:28593, team:'Birmingham-So.'},
            {id:30134, team:'Black Hills St.'},
            {id:64, team:'Blackburn'},
            {id:984, team:'Bloomfield'},
            {id:65, team:'Bloomsburg'},
            {id:500551, team:'Blue Mountain (MS)'},
            {id:500552, team:'Bluefield Col.'},
            {id:985, team:'Bluefield St.'},
            {id:987, team:'Bluffton'},
            {id:30232, team:'Bob Jones'},
            {id:66, team:'Boise St.'},
            {id:67, team:'Boston College'},
            {id:68, team:'Boston U.'},
            {id:69, team:'Bowdoin'},
            {id:70, team:'Bowie St.'},
            {id:71, team:'Bowling Green'},
            {id:506316, team:'Boyce'},
            {id:72, team:'Bradley'},
            {id:73, team:'Brandeis'},
            {id:506451, team:'Brandon (CAN)'},
            {id:506178, team:'Brenau'},
            {id:500610, team:'Brescia'},
            {id:30094, team:'Brevard'},
            {id:500612, team:'Brewton-Parker'},
            {id:500615, team:'Briar Cliff (IA)'},
            {id:74, team:'Bridgeport'},
            {id:75, team:'Bridgewater (VA)'},
            {id:76, team:'Bridgewater St.'},
            {id:506366, team:'Briercrest (CAN)'},
            {id:77, team:'BYU'},
            {id:506196, team:'British Colum. (CAN)'},
            {id:506452, team:'B.C.-Okanagan (CAN)'},
            {id:506438, team:'Brock (CAN)'},
            {id:78, team:'Brockport'},
            {id:79, team:'Brooklyn'},
            {id:80, team:'Brown'},
            {id:506542, team:'Bry. & Strat. (Roc.)'},
            {id:506538, team:'Bry. & Strat. (Alb.)'},
            {id:506496, team:'Bry. & Strat. (Buf.)'},
            {id:506539, team:'Bry. & Strat. (Syr.)'},
            {id:500660, team:'Bryan'},
            {id:81, team:'Bryant'},
            {id:30188, team:'Bryn Athyn'},
            {id:82, team:'Bryn Mawr'},
            {id:83, team:'Bucknell'},
            {id:84, team:'Buena Vista'},
            {id:85, team:'Buffalo St.'},
            {id:86, team:'Buffalo'},
            {id:87, team:'Butler'},
            {id:88, team:'Cabrini'},
            {id:1305, team:'Cairn'},
            {id:91, team:'Cal Poly Pomona'},
            {id:995, team:'Caldwell'},
            {id:506453, team:'Calgary (CAN)'},
            {id:30135, team:'California Baptist'},
            {id:105, team:'Cal Lutheran'},
            {id:500745, team:'Cal Maritime'},
            {id:506371, team:'UC Merced'},
            {id:90, team:'Cal Poly'},
            {id:30055, team:'Cal St. Monterey Bay'},
            {id:94, team:'CSU Bakersfield'},
            {id:95, team:'Chico St.'},
            {id:92, team:'Cal St. Dom. Hills'},
            {id:98, team:'Cal St. East Bay'},
            {id:96, team:'Fresno St.'},
            {id:97, team:'Cal St. Fullerton'},
            {id:101, team:'CSUN'},
            {id:102, team:'Sacramento St.'},
            {id:93, team:'Cal St. San B\'dino'},
            {id:103, team:'Stanislaus St.'},
            {id:100, team:'Cal State LA'},
            {id:30198, team:'Cal St. San Marcos'},
            {id:106, team:'Cal U (PA)'},
            {id:107, team:'California'},
            {id:108, team:'UC Davis'},
            {id:109, team:'UC Irvine'},
            {id:110, team:'UCLA'},
            {id:111, team:'UC Riverside'},
            {id:112, team:'UC San Diego'},
            {id:104, team:'UC Santa Barbara'},
            {id:113, team:'UC Santa Cruz'},
            {id:89, team:'Caltech'},
            {id:506194, team:'Calumet Col.'},
            {id:500768, team:'Calvary'},
            {id:114, team:'Calvin'},
            {id:996, team:'Cameron'},
            {id:506461, team:'Camosun (CAN)'},
            {id:506534, team:'Camp. Harrodsburg'},
            {id:506535, team:'Camp. Somerset'},
            {id:115, team:'Campbell'},
            {id:500785, team:'Campbellsville'},
            {id:506490, team:'Canadian Mennonite'},
            {id:116, team:'Canisius'},
            {id:30165, team:'SUNY Canton'},
            {id:506409, team:'Capilano (CAN)'},
            {id:117, team:'Capital'},
            {id:506030, team:'Cardinal Stritch'},
            {id:118, team:'Carleton'},
            {id:500802, team:'Carlow'},
            {id:119, team:'Carnegie Mellon'},
            {id:506056, team:'Carroll (MT)'},
            {id:120, team:'Carroll (WI)'},
            {id:1000, team:'Carson-Newman'},
            {id:121, team:'Carthage'},
            {id:506006, team:'Carver'},
            {id:122, team:'CWRU'},
            {id:123, team:'Castleton'},
            {id:1001, team:'Catawba'},
            {id:500855, team:'Catholic (PR)'},
            {id:124, team:'Catholic'},
            {id:23624, team:'Cazenovia'},
            {id:14795, team:'Cedar Crest'},
            {id:30124, team:'Cedarville'},
            {id:125, team:'Centenary (LA)'},
            {id:12884, team:'Centenary (NJ)'},
            {id:1004, team:'Central Ark.'},
            {id:506117, team:'Central Baptist'},
            {id:500402, team:'Central Bayamon'},
            {id:506327, team:'Cent. Christian (KS)'},
            {id:500879, team:'Cent. Christian Bib.'},
            {id:126, team:'Central (IA)'},
            {id:127, team:'Central Conn. St.'},
            {id:128, team:'UCF'},
            {id:506026, team:'Central Methodist'},
            {id:129, team:'Central Mich.'},
            {id:130, team:'Central Mo.'},
            {id:1009, team:'Central Okla.'},
            {id:506372, team:'Central Penn'},
            {id:30049, team:'Central St. (OH)'},
            {id:1010, team:'Central Wash.'},
            {id:132, team:'Centre'},
            {id:1012, team:'Chadron St.'},
            {id:133, team:'Chaminade'},
            {id:506304, team:'Champion Chris.'},
            {id:134, team:'Chapman'},
            {id:1014, team:'Col. of Charleston'},
            {id:1013, team:'Charleston (WV)'},
            {id:48, team:'Charleston So.'},
            {id:10972, team:'Chatham'},
            {id:21323, team:'Chestnut Hill'},
            {id:506537, team:'Cheyney'},
            {id:137, team:'Chicago'},
            {id:136, team:'Chicago St.'},
            {id:8875, team:'Chowan'},
            {id:500958, team:'Christendom'},
            {id:1015, team:'Christian Brothers'},
            {id:139, team:'Chris. Newport'},
            {id:140, team:'Cincinnati'},
            {id:500975, team:'Cincinnati Christian'},
            {id:141, team:'The Citadel'},
            {id:480, team:'CCNY'},
            {id:30058, team:'Claflin'},
            {id:142, team:'Claremont-M-S'},
            {id:143, team:'Clarion'},
            {id:144, team:'Clark Atlanta'},
            {id:958, team:'Clarks Summit'},
            {id:145, team:'Clark (MA)'},
            {id:506382, team:'Clarke'},
            {id:146, team:'Clarkson'},
            {id:20576, team:'Clayton St.'},
            {id:506322, team:'Cleary'},
            {id:147, team:'Clemson'},
            {id:148, team:'Cleveland St.'},
            {id:149, team:'Coastal Carolina'},
            {id:502747, team:'Coastal Ga.'},
            {id:30083, team:'Cobleskill St.'},
            {id:150, team:'Coe'},
            {id:1023, team:'Coker'},
            {id:151, team:'Colby'},
            {id:152, team:'Colby-Sawyer'},
            {id:153, team:'Colgate'},
            {id:157, team:'Colorado'},
            {id:11403, team:'Colo. Christian'},
            {id:154, team:'Colorado Col.'},
            {id:11416, team:'Colorado Mesa'},
            {id:155, team:'Colo. Sch. of Mines'},
            {id:156, team:'Colorado St.'},
            {id:2720, team:'Colorado St.-Pueblo'},
            {id:9754, team:'UC-Colo. Springs'},
            {id:501097, team:'Columbia (MO)'},
            {id:501098, team:'Columbia (SC)'},
            {id:506287, team:'Columbia Bible (CAN)'},
            {id:506478, team:'Columbia Int\'l'},
            {id:158, team:'Columbia'},
            {id:159, team:'Columbus St.'},
            {id:1028, team:'Concord'},
            {id:506112, team:'Concordia (AL)'},
            {id:501123, team:'Concordia (MI)'},
            {id:506061, team:'Concordia (NE)'},
            {id:161, team:'Concordia-M\'head'},
            {id:162, team:'Concordia (NY)'},
            {id:160, team:'Concordia Chicago'},
            {id:30199, team:'Concordia (CA)'},
            {id:30200, team:'Concordia Portland'},
            {id:24400, team:'Concordia (TX)'},
            {id:1036, team:'Concordia Wisconsin'},
            {id:506358, team:'Concordia-Montreal'},
            {id:9081, team:'Concordia-St. Paul'},
            {id:164, team:'UConn'},
            {id:163, team:'Connecticut Col.'},
            {id:8935, team:'Converse'},
            {id:501167, team:'Cooper Union'},
            {id:165, team:'Coppin St.'},
            {id:505690, team:'Corban'},
            {id:166, team:'Cornell College'},
            {id:167, team:'Cornell'},
            {id:501182, team:'Cornerstone'},
            {id:168, team:'SUNY Cortland'},
            {id:506533, team:'Cottey'},
            {id:30111, team:'Covenant'},
            {id:169, team:'Creighton'},
            {id:506350, team:'Crowley\'s Ridge'},
            {id:30035, team:'Crown (MN)'},
            {id:506352, team:'Culinary Institute'},
            {id:506032, team:'Culver-Stockton'},
            {id:501207, team:'Cumberland (TN)'},
            {id:506101, team:'Cumberlands (KY)'},
            {id:170, team:'Curry'},
            {id:1059, team:'D\'Youville'},
            {id:30174, team:'Daemen'},
            {id:506066, team:'Dakota St.'},
            {id:506033, team:'Dakota Wesleyan'},
            {id:1044, team:'Dallas'},
            {id:1045, team:'DBU'},
            {id:501242, team:'Dallas Christian'},
            {id:506472, team:'Dalton St.'},
            {id:172, team:'Dartmouth'},
            {id:30221, team:'Davenport'},
            {id:173, team:'Davidson'},
            {id:504197, team:'Davis'},
            {id:174, team:'Davis & Elkins'},
            {id:506376, team:'Dayspring Bible'},
            {id:175, team:'Dayton'},
            {id:30219, team:'Dean'},
            {id:1050, team:'Defiance'},
            {id:180, team:'Delaware'},
            {id:178, team:'Delaware St.'},
            {id:179, team:'Delaware Valley'},
            {id:30225, team:'SUNY Delhi'},
            {id:181, team:'Delta St.'},
            {id:182, team:'Denison'},
            {id:183, team:'Denver'},
            {id:176, team:'DePaul'},
            {id:177, team:'DePauw'},
            {id:20, team:'DeSales'},
            {id:184, team:'Detroit Mercy'},
            {id:185, team:'Dickinson'},
            {id:506123, team:'Dickinson St.'},
            {id:501365, team:'Dillard'},
            {id:506505, team:'Din�'},
            {id:186, team:'Dist. Columbia'},
            {id:30095, team:'Dixie St.'},
            {id:506020, team:'Doane'},
            {id:30118, team:'Dominican (CA)'},
            {id:8956, team:'Dominican (NY)'},
            {id:1332, team:'Dominican (IL)'},
            {id:501390, team:'Dordt'},
            {id:506462, team:'Douglas (CAN)'},
            {id:189, team:'Drake'},
            {id:190, team:'Drew'},
            {id:191, team:'Drexel'},
            {id:1057, team:'Drury'},
            {id:192, team:'Dubuque'},
            {id:193, team:'Duke'},
            {id:194, team:'Duquesne'},
            {id:195, team:'Earlham'},
            {id:196, team:'East Carolina'},
            {id:8965, team:'East Central'},
            {id:197, team:'East Stroudsburg'},
            {id:198, team:'ETSU'},
            {id:1062, team:'East Tex. Baptist'},
            {id:506193, team:'East-West U.'},
            {id:200, team:'Eastern Conn. St.'},
            {id:201, team:'Eastern Ill.'},
            {id:202, team:'Eastern Ky.'},
            {id:203, team:'East. Mennonite'},
            {id:204, team:'Eastern Mich.'},
            {id:1064, team:'Eastern Nazarene'},
            {id:206, team:'Eastern N.M.'},
            {id:506406, team:'Eastern Ore.'},
            {id:8968, team:'Eastern'},
            {id:207, team:'Eastern Wash.'},
            {id:506323, team:'Ecclesia'},
            {id:208, team:'Eckerd'},
            {id:1066, team:'Edgewood'},
            {id:209, team:'Edinboro'},
            {id:501555, team:'Edward Waters'},
            {id:210, team:'Elizabeth City St.'},
            {id:211, team:'Elizabethtown'},
            {id:212, team:'Elmhurst'},
            {id:213, team:'Elmira'},
            {id:532, team:'Elms'},
            {id:1068, team:'Elon'},
            {id:506341, team:'Embry-Riddle (AZ)'},
            {id:9680, team:'Embry-Riddle (FL)'},
            {id:214, team:'Emerson'},
            {id:30193, team:'Emmanuel (GA)'},
            {id:215, team:'Emmanuel (MA)'},
            {id:506096, team:'Emmaus'},
            {id:216, team:'Emory & Henry'},
            {id:217, team:'Emory'},
            {id:1071, team:'Emporia St.'},
            {id:8981, team:'Endicott'},
            {id:1072, team:'Erskine'},
            {id:218, team:'Eureka'},
            {id:506080, team:'Evangel'},
            {id:219, team:'Evansville'},
            {id:506184, team:'Evergreen St.'},
            {id:220, team:'Fairfield'},
            {id:221, team:'FDU-Florham'},
            {id:222, team:'Fairleigh Dickinson'},
            {id:1076, team:'Fairmont St.'},
            {id:501657, team:'Faith Bapt. Bible'},
            {id:506541, team:'Fanshawe (CAN)'},
            {id:27892, team:'Farmingdale St.'},
            {id:501659, team:'Faulkner'},
            {id:223, team:'Fayetteville St.'},
            {id:23157, team:'Felician'},
            {id:224, team:'Ferris St.'},
            {id:225, team:'Ferrum'},
            {id:1079, team:'Findlay'},
            {id:30032, team:'Finlandia'},
            {id:501678, team:'Fisher'},
            {id:506381, team:'Fisk'},
            {id:227, team:'Fitchburg St.'},
            {id:506170, team:'Five Towns'},
            {id:30092, team:'Flagler'},
            {id:235, team:'Florida'},
            {id:228, team:'Florida A&M'},
            {id:229, team:'Fla. Atlantic'},
            {id:506300, team:'Florida Col.'},
            {id:28755, team:'FGCU'},
            {id:230, team:'Florida Tech'},
            {id:231, team:'FIU'},
            {id:501725, team:'Fla. Memorial'},
            {id:506479, team:'Fla. National'},
            {id:233, team:'Fla. Southern'},
            {id:234, team:'Florida St.'},
            {id:11538, team:'Fontbonne'},
            {id:236, team:'Fordham'},
            {id:9011, team:'Fort Hays St.'},
            {id:1083, team:'Fort Lewis'},
            {id:237, team:'Fort Valley St.'},
            {id:238, team:'Framingham St.'},
            {id:1085, team:'Francis Marion'},
            {id:30069, team:'Franciscan'},
            {id:239, team:'Franklin & Marshall'},
            {id:2694, team:'Franklin'},
            {id:241, team:'Franklin Pierce'},
            {id:506454, team:'Fraser Valley (CAN)'},
            {id:242, team:'Fredonia'},
            {id:501810, team:'Freed-Hardeman'},
            {id:30147, team:'Fresno Pacific'},
            {id:501826, team:'Friends'},
            {id:243, team:'Frostburg St.'},
            {id:244, team:'Furman'},
            {id:245, team:'Gallaudet'},
            {id:246, team:'Gannon'},
            {id:1092, team:'Gardner-Webb'},
            {id:247, team:'SUNY Geneseo'},
            {id:30084, team:'Geneva'},
            {id:1094, team:'George Fox'},
            {id:248, team:'George Mason'},
            {id:249, team:'George Washington'},
            {id:506003, team:'Georgetown (KY)'},
            {id:251, team:'Georgetown'},
            {id:257, team:'Georgia'},
            {id:1096, team:'Georgia College'},
            {id:506449, team:'Georgia Gwinnett'},
            {id:255, team:'Georgia Tech'},
            {id:253, team:'Ga. Southern'},
            {id:1097, team:'Ga. Southwestern'},
            {id:254, team:'Georgia St.'},
            {id:9004, team:'Georgian Court'},
            {id:258, team:'Gettysburg'},
            {id:1098, team:'Glenville St.'},
            {id:8366, team:'Goldey-Beacom'},
            {id:260, team:'Gonzaga'},
            {id:1099, team:'Gordon'},
            {id:501955, team:'Goshen'},
            {id:1467, team:'Goucher'},
            {id:506495, team:'Governors St.'},
            {id:501960, team:'Grace (IN)'},
            {id:501962, team:'Grace (NE)'},
            {id:501961, team:'Grace Christian (MI)'},
            {id:506062, team:'Graceland (IA)'},
            {id:261, team:'Grambling'},
            {id:1104, team:'Grand Canyon'},
            {id:262, team:'Grand Valley St.'},
            {id:501982, team:'Grand View'},
            {id:501987, team:'Great Lake Christian'},
            {id:506531, team:'Green Mountain'},
            {id:263, team:'Greensboro'},
            {id:1111, team:'Greenville'},
            {id:264, team:'Grinnell'},
            {id:265, team:'Grove City'},
            {id:506437, team:'Guelph (CAN)'},
            {id:1112, team:'Guilford'},
            {id:266, team:'Gust. Adolphus'},
            {id:12399, team:'Gwynedd Mercy'},
            {id:267, team:'Hamilton'},
            {id:268, team:'Hamline'},
            {id:269, team:'Hampden-Sydney'},
            {id:506036, team:'Hampshire'},
            {id:270, team:'Hampton'},
            {id:502043, team:'Hannibal-La Grange'},
            {id:1115, team:'Hanover'},
            {id:271, team:'Hardin-Simmons'},
            {id:1116, team:'Harding'},
            {id:502060, team:'Harris-Stowe'},
            {id:272, team:'Hartford'},
            {id:273, team:'Hartwick'},
            {id:275, team:'Harvard'},
            {id:506153, team:'Haskell'},
            {id:506007, team:'Hastings'},
            {id:276, team:'Haverford'},
            {id:277, team:'Hawaii'},
            {id:2697, team:'Hawaii Hilo'},
            {id:2696, team:'Hawaii Pacific'},
            {id:278, team:'Heidelberg'},
            {id:1123, team:'Henderson St.'},
            {id:1124, team:'Hendrix'},
            {id:19651, team:'High Point'},
            {id:8398, team:'Hilbert'},
            {id:280, team:'Hillsdale'},
            {id:281, team:'Hiram'},
            {id:506367, team:'Hiwassee'},
            {id:282, team:'Hobart'},
            {id:283, team:'Hofstra'},
            {id:284, team:'Hollins'},
            {id:506345, team:'Holy Cross (IN)'},
            {id:285, team:'Holy Cross'},
            {id:1126, team:'Holy Family'},
            {id:504805, team:'Holy Fam. Col. (WI)'},
            {id:30175, team:'Holy Names'},
            {id:8404, team:'Hood'},
            {id:286, team:'Hope'},
            {id:504017, team:'Hope Int\'l'},
            {id:30160, team:'Houghton'},
            {id:288, team:'Houston'},
            {id:287, team:'Houston Baptist'},
            {id:506338, team:'Houston-Victoria'},
            {id:2741, team:'Howard Payne'},
            {id:290, team:'Howard'},
            {id:506492, team:'Humber (CAN)'},
            {id:291, team:'Humboldt St.'},
            {id:292, team:'Hunter'},
            {id:1130, team:'Huntingdon'},
            {id:502195, team:'Huntington'},
            {id:1133, team:'Husson'},
            {id:502215, team:'Huston-Tillotson'},
            {id:295, team:'Idaho'},
            {id:294, team:'Idaho St.'},
            {id:500088, team:'Col. of Idaho'},
            {id:297, team:'Illinois Col.'},
            {id:30172, team:'IIT'},
            {id:299, team:'Illinois St.'},
            {id:300, team:'Ill. Wesleyan'},
            {id:302, team:'UIC'},
            {id:30050, team:'Ill. Springfield'},
            {id:301, team:'Illinois'},
            {id:303, team:'Immaculata'},
            {id:2743, team:'UIW'},
            {id:502288, team:'Ind.-East'},
            {id:502278, team:'Ind.-Kokomo'},
            {id:502290, team:'Ind.-Northwest'},
            {id:502302, team:'Ind.-South Bend'},
            {id:502300, team:'Ind.-Southeast'},
            {id:305, team:'Indiana St.'},
            {id:502330, team:'Indiana Tech'},
            {id:307, team:'Indiana (PA)'},
            {id:502332, team:'Ind. Wesleyan'},
            {id:306, team:'Indiana'},
            {id:2699, team:'IUPUI'},
            {id:309, team:'UIndy'},
            {id:502335, team:'Int. Am.-San German'},
            {id:310, team:'Iona'},
            {id:312, team:'Iowa'},
            {id:311, team:'Iowa St.'},
            {id:30173, team:'Iowa Wesleyan'},
            {id:313, team:'Ithaca'},
            {id:314, team:'Jackson St.'},
            {id:315, team:'Jacksonville St.'},
            {id:316, team:'Jacksonville'},
            {id:317, team:'James Madison'},
            {id:506057, team:'Jamestown'},
            {id:506004, team:'Jarvis Christian'},
            {id:502395, team:'John Brown'},
            {id:320, team:'John Carroll'},
            {id:321, team:'John Jay'},
            {id:506488, team:'John Wesley'},
            {id:322, team:'Johns Hopkins'},
            {id:30046, team:'JWU (Denver)'},
            {id:22626, team:'JWU (Providence)'},
            {id:501712, team:'Johnson (FL)'},
            {id:502420, team:'Johnson (TN)'},
            {id:323, team:'Johnson C. Smith'},
            {id:502441, team:'Judson (AL)'},
            {id:502440, team:'Judson (IL)'},
            {id:325, team:'Juniata'},
            {id:506375, team:'JWU (Charlotte)'},
            {id:506232, team:'JWU (North Miami)'},
            {id:326, team:'Kalamazoo'},
            {id:328, team:'Kansas'},
            {id:506254, team:'Kansas Christian'},
            {id:327, team:'Kansas St.'},
            {id:506103, team:'Kan. Wesleyan'},
            {id:329, team:'Kean'},
            {id:330, team:'Keene St.'},
            {id:506200, team:'Keiser'},
            {id:1157, team:'Kennesaw St.'},
            {id:331, team:'Kent St.'},
            {id:506423, team:'Kent St.-Tuscarawas'},
            {id:334, team:'Kentucky'},
            {id:502500, team:'Ky. Christian'},
            {id:332, team:'Kentucky St.'},
            {id:333, team:'Ky. Wesleyan'},
            {id:335, team:'Kenyon'},
            {id:1159, team:'Keuka'},
            {id:30047, team:'Keystone'},
            {id:30051, team:'King (TN)'},
            {id:502530, team:'King\'s (NY)'},
            {id:336, team:'King\'s (PA)'},
            {id:506500, team:'King\'s U. (CAN)'},
            {id:337, team:'Knox'},
            {id:339, team:'Kutztown'},
            {id:506435, team:'Kwantlen Tech (CAN)'},
            {id:1163, team:'La Roche'},
            {id:340, team:'La Salle'},
            {id:502567, team:'La Sierra'},
            {id:341, team:'La Verne'},
            {id:342, team:'Lafayette'},
            {id:1162, team:'LaGrange'},
            {id:2746, team:'Lake Erie'},
            {id:344, team:'Lake Forest'},
            {id:345, team:'Lake Superior St.'},
            {id:506152, team:'Lakehead (CAN)'},
            {id:1164, team:'Lakeland'},
            {id:346, team:'Lamar University'},
            {id:506477, team:'Lambton (CAN)'},
            {id:30085, team:'Lancaster Bible'},
            {id:1167, team:'Lander'},
            {id:506491, team:'Landmark Col.'},
            {id:347, team:'Lane'},
            {id:506507, team:'Langara (CAN)'},
            {id:506054, team:'Langston'},
            {id:8486, team:'Lasell'},
            {id:506404, team:'Lawrence Tech'},
            {id:348, team:'Lawrence'},
            {id:349, team:'Le Moyne'},
            {id:351, team:'Lebanon Valley'},
            {id:30013, team:'Lee'},
            {id:13302, team:'Lees-McRae'},
            {id:352, team:'Lehigh'},
            {id:279, team:'Lehman'},
            {id:350, team:'LeMoyne-Owen'},
            {id:1170, team:'Lenoir-Rhyne'},
            {id:24317, team:'Lesley'},
            {id:506429, team:'Lethbridge (CAN)'},
            {id:8490, team:'LeTourneau'},
            {id:22235, team:'Lewis & Clark'},
            {id:354, team:'Lewis'},
            {id:506318, team:'Lewis-Clark St.'},
            {id:355, team:'Liberty'},
            {id:502694, team:'Life (GA)'},
            {id:502695, team:'LIFE Pacific'},
            {id:1174, team:'Limestone'},
            {id:506532, team:'Lincoln (IL)'},
            {id:502715, team:'Lincoln Christian'},
            {id:1176, team:'Lincoln Memorial'},
            {id:356, team:'Lincoln (MO)'},
            {id:357, team:'Lincoln (PA)'},
            {id:506373, team:'Lindenwood (IL)'},
            {id:30136, team:'Lindenwood (MO)'},
            {id:502727, team:'Lindsey Wilson'},
            {id:1179, team:'Linfield'},
            {id:28600, team:'Lipscomb'},
            {id:359, team:'Livingstone'},
            {id:360, team:'Lock Haven'},
            {id:99, team:'Long Beach St.'},
            {id:361, team:'LIU'},
            {id:363, team:'Longwood'},
            {id:364, team:'Loras'},
            {id:1182, team:'Louisiana Col.'},
            {id:365, team:'LSU'},
            {id:506339, team:'LSU-Alexandria'},
            {id:502787, team:'LSU-Shreveport'},
            {id:366, team:'Louisiana Tech'},
            {id:671, team:'Louisiana'},
            {id:498, team:'La.-Monroe'},
            {id:367, team:'Louisville'},
            {id:506385, team:'Lourdes'},
            {id:502812, team:'Loyola (LA)'},
            {id:370, team:'LMU (CA)'},
            {id:371, team:'Loyola Chicago'},
            {id:369, team:'Loyola Maryland'},
            {id:30177, team:'Lubbock Christian'},
            {id:372, team:'Luther'},
            {id:373, team:'Lycoming'},
            {id:374, team:'Lynchburg'},
            {id:20794, team:'Lynn'},
            {id:506408, team:'Lyon'},
            {id:376, team:'Macalester'},
            {id:377, team:'MacMurray'},
            {id:502867, team:'Madonna'},
            {id:502877, team:'Me.-Augusta'},
            {id:502885, team:'Me.-Fort Kent'},
            {id:502890, team:'Me.-Machias'},
            {id:378, team:'Maine Maritime'},
            {id:379, team:'Me.-Farmington'},
            {id:380, team:'Maine'},
            {id:30040, team:'Me.-Presque Isle'},
            {id:30137, team:'Malone'},
            {id:1192, team:'Manchester'},
            {id:381, team:'Manhattan'},
            {id:502922, team:'Manhattan Chrst.'},
            {id:382, team:'Manhattanville'},
            {id:506363, team:'Manitoba (CAN)'},
            {id:384, team:'Mansfield'},
            {id:1194, team:'Maranatha Baptist'},
            {id:502945, team:'Marian (IN)'},
            {id:1196, team:'Marian (WI)'},
            {id:385, team:'Marietta'},
            {id:386, team:'Marist'},
            {id:478, team:'SUNY Maritime'},
            {id:387, team:'Marquette'},
            {id:1199, team:'Mars Hill'},
            {id:388, team:'Marshall'},
            {id:8597, team:'Martin Luther'},
            {id:502981, team:'Martin Methodist'},
            {id:30075, team:'Mary'},
            {id:389, team:'Mary Baldwin'},
            {id:1203, team:'Mary Hardin-Baylor'},
            {id:390, team:'Mary Washington'},
            {id:505182, team:'Marycrest Int\'l'},
            {id:393, team:'UMES'},
            {id:391, team:'UMBC'},
            {id:392, team:'Maryland'},
            {id:506383, team:'Marymount (CA)'},
            {id:394, team:'Marymount (VA)'},
            {id:396, team:'Maryville (TN)'},
            {id:395, team:'Maryville (MO)'},
            {id:397, team:'Marywood'},
            {id:486, team:'MCLA'},
            {id:398, team:'MIT'},
            {id:399, team:'Mass. Maritime'},
            {id:400, team:'Massachusetts'},
            {id:401, team:'UMass Boston'},
            {id:656, team:'UMass Dartmouth'},
            {id:368, team:'UMass Lowell'},
            {id:503033, team:'Master\'s'},
            {id:502225, team:'Mayville St.'},
            {id:773, team:'McDaniel'},
            {id:503037, team:'McGill (CAN)'},
            {id:30138, team:'McKendree'},
            {id:506510, team:'McMaster (CAN)'},
            {id:8530, team:'McMurry'},
            {id:402, team:'McNeese'},
            {id:506039, team:'McPherson'},
            {id:23725, team:'Medaille'},
            {id:403, team:'Medgar Evers'},
            {id:404, team:'Memphis'},
            {id:506392, team:'Menlo'},
            {id:406, team:'Mercer'},
            {id:407, team:'Mercy'},
            {id:408, team:'Mercyhurst'},
            {id:409, team:'Meredith'},
            {id:410, team:'Merrimack'},
            {id:411, team:'Messiah'},
            {id:412, team:'Methodist'},
            {id:413, team:'MSU Denver'},
            {id:415, team:'Miami (FL)'},
            {id:506146, team:'Miami-Hamilton'},
            {id:503122, team:'Miami-Middletown'},
            {id:414, team:'Miami (OH)'},
            {id:418, team:'Michigan'},
            {id:503130, team:'Mich.-Dearborn'},
            {id:416, team:'Michigan St.'},
            {id:417, team:'Michigan Tech'},
            {id:503153, team:'Mid-Amer. Christian'},
            {id:506199, team:'Mid-Atlantic Christ.'},
            {id:506041, team:'MidAmerica Naz.'},
            {id:506486, team:'Middle Ga. St.'},
            {id:419, team:'Middle Tenn.'},
            {id:420, team:'Middlebury'},
            {id:506060, team:'Midland'},
            {id:503175, team:'Midway'},
            {id:1222, team:'Midwestern St.'},
            {id:422, team:'Miles'},
            {id:423, team:'Millersville'},
            {id:503195, team:'Milligan'},
            {id:424, team:'Millikin'},
            {id:425, team:'Mills'},
            {id:426, team:'Millsaps'},
            {id:20220, team:'MSOE'},
            {id:383, team:'Minnesota St.'},
            {id:1240, team:'MSU Moorhead'},
            {id:27854, team:'Minn.-Crookston'},
            {id:427, team:'Minn. Duluth'},
            {id:428, team:'Minnesota'},
            {id:1226, team:'Minn.-Morris'},
            {id:30125, team:'Minot St.'},
            {id:16142, team:'Misericordia'},
            {id:433, team:'Ole Miss'},
            {id:429, team:'Mississippi Col.'},
            {id:430, team:'Mississippi St.'},
            {id:431, team:'MUW'},
            {id:432, team:'Mississippi Val.'},
            {id:503262, team:'Mo. Baptist'},
            {id:435, team:'Missouri S&T'},
            {id:9012, team:'Mo. Southern St.'},
            {id:669, team:'Missouri St.'},
            {id:506068, team:'Missouri Valley'},
            {id:9013, team:'Missouri Western'},
            {id:434, team:'Missouri'},
            {id:2707, team:'Kansas City'},
            {id:436, team:'Mo.-St. Louis'},
            {id:30042, team:'Mitchell'},
            {id:503297, team:'Mobile'},
            {id:506431, team:'Mohawk (CAN)'},
            {id:437, team:'Molloy'},
            {id:438, team:'Monmouth (IL)'},
            {id:439, team:'Monmouth'},
            {id:441, team:'Montana'},
            {id:205, team:'Mont. St. Billings'},
            {id:440, team:'Montana St.'},
            {id:503735, team:'Mont. St.-Northern'},
            {id:506022, team:'Montana Tech'},
            {id:506024, team:'Montana Western'},
            {id:442, team:'Montclair St.'},
            {id:1238, team:'Montevallo'},
            {id:503331, team:'Montreat'},
            {id:503332, team:'Moody Bible'},
            {id:443, team:'Moravian'},
            {id:444, team:'Morehead St.'},
            {id:445, team:'Morehouse'},
            {id:446, team:'Morgan St.'},
            {id:506400, team:'Morningside'},
            {id:503365, team:'Morris'},
            {id:30067, team:'Morrisville St.'},
            {id:30036, team:'Mount Aloysius'},
            {id:449, team:'Mount Holyoke'},
            {id:503374, team:'Mt. Marty'},
            {id:30033, team:'Mount Mary'},
            {id:503377, team:'Mt. Mercy'},
            {id:1245, team:'Mount Olive'},
            {id:506455, team:'Mt. Royal (CAN)'},
            {id:8567, team:'Mt. St. Joseph'},
            {id:451, team:'CMSV'},
            {id:1468, team:'Mt. St. Mary (NY)'},
            {id:450, team:'Mount St. Mary\'s'},
            {id:452, team:'Mount Union'},
            {id:503405, team:'Mt. Vernon Naz.'},
            {id:453, team:'Muhlenberg'},
            {id:503415, team:'Multnomah'},
            {id:454, team:'Murray St.'},
            {id:455, team:'Muskingum'},
            {id:461, team:'Nazareth'},
            {id:503447, team:'Neb. Christian'},
            {id:464, team:'Omaha'},
            {id:462, team:'Neb. Wesleyan'},
            {id:1156, team:'Neb.-Kearney'},
            {id:463, team:'Nebraska'},
            {id:1254, team:'Neumann'},
            {id:465, team:'UNLV'},
            {id:466, team:'Nevada'},
            {id:1255, team:'U New England'},
            {id:467, team:'New England Col.'},
            {id:469, team:'New Hampshire'},
            {id:470, team:'New Haven'},
            {id:501620, team:'New Hope Christian'},
            {id:319, team:'New Jersey City'},
            {id:471, team:'NJIT'},
            {id:712, team:'TCNJ'},
            {id:473, team:'New Mexico'},
            {id:8577, team:'N.M. Highlands'},
            {id:472, team:'New Mexico St.'},
            {id:474, team:'New Orleans'},
            {id:475, team:'SUNY New Paltz'},
            {id:506480, team:'New School'},
            {id:477, team:'New York Tech'},
            {id:479, team:'NYU'},
            {id:1257, team:'Newberry'},
            {id:30054, team:'Newman'},
            {id:482, team:'Niagara'},
            {id:483, team:'Nicholls St.'},
            {id:484, team:'Nichols'},
            {id:485, team:'Norfolk St.'},
            {id:487, team:'North Ala.'},
            {id:456, team:'UNC Asheville'},
            {id:488, team:'N.C. A&T'},
            {id:489, team:'N.C. Central'},
            {id:490, team:'NC State'},
            {id:491, team:'N.C. Wesleyan'},
            {id:460, team:'UNCW'},
            {id:457, team:'North Carolina'},
            {id:458, team:'Charlotte'},
            {id:459, team:'UNC Greensboro'},
            {id:537, team:'UNC Pembroke'},
            {id:492, team:'North Central (IL)'},
            {id:30039, team:'North Central (MN)'},
            {id:494, team:'North Dakota'},
            {id:493, team:'North Dakota St.'},
            {id:2711, team:'North Florida'},
            {id:30052, team:'North Georgia'},
            {id:9223, team:'North Greenville'},
            {id:496, team:'North Park'},
            {id:497, team:'North Texas'},
            {id:30065, team:'NVU-Lyndon'},
            {id:12810, team:'Northeastern St.'},
            {id:500, team:'Northeastern'},
            {id:501, team:'Northern Ariz.'},
            {id:506456, team:'Northern B.C. (CAN)'},
            {id:502, team:'Northern Colo.'},
            {id:503, team:'Northern Ill.'},
            {id:504, team:'UNI'},
            {id:505, team:'Northern Ky.'},
            {id:506, team:'Northern Mich.'},
            {id:506343, team:'Northern N.M.'},
            {id:1266, team:'Northern St.'},
            {id:324, team:'NVU-Johnson'},
            {id:1267, team:'Northland'},
            {id:503796, team:'Northwest (WA)'},
            {id:503780, team:'Northwest Chrst.'},
            {id:506481, team:'Northwest Indian'},
            {id:507, team:'Northwest Mo. St.'},
            {id:1274, team:'Northwest Nazarene'},
            {id:506072, team:'Northwestern (IA)'},
            {id:506330, team:'Northwestern Ohio'},
            {id:30178, team:'Northwestern Okla.'},
            {id:508, team:'Northwestern St.'},
            {id:509, team:'Northwestern'},
            {id:30031, team:'Northwestern-St. Paul'},
            {id:510, team:'Northwood'},
            {id:511, team:'Norwich'},
            {id:513, team:'Notre Dame'},
            {id:8600, team:'Notre Dame (MD)'},
            {id:30126, team:'Notre Dame (OH)'},
            {id:30056, team:'Notre Dame de Namur'},
            {id:24639, team:'Nova Southeastern'},
            {id:1277, team:'Nyack'},
            {id:506501, team:'Oak Hills Christian'},
            {id:1278, team:'Oakland City'},
            {id:514, team:'Oakland'},
            {id:506086, team:'Oakwood'},
            {id:515, team:'Oberlin'},
            {id:516, team:'Occidental'},
            {id:506463, team:'Oglala Lakota'},
            {id:2713, team:'Oglethorpe'},
            {id:506333, team:'Ohio Christian'},
            {id:30119, team:'Ohio Dominican'},
            {id:517, team:'Ohio Northern'},
            {id:506088, team:'Ohio St. Lima'},
            {id:518, team:'Ohio St.'},
            {id:506336, team:'Ohio St.-Mansfield'},
            {id:519, team:'Ohio'},
            {id:15207, team:'Ohio Valley'},
            {id:520, team:'Ohio Wesleyan'},
            {id:503863, team:'Ohio-Chillicothe'},
            {id:503865, team:'Ohio-Eastern'},
            {id:506281, team:'Ohio-Lancaster'},
            {id:506233, team:'Ohio-Zanesville'},
            {id:506515, team:'Okla. Panhandle'},
            {id:522, team:'Oklahoma'},
            {id:30202, team:'Okla. Baptist'},
            {id:30179, team:'Okla. Christian'},
            {id:503905, team:'Oklahoma City'},
            {id:521, team:'Oklahoma St.'},
            {id:506189, team:'Okla. Wesleyan'},
            {id:523, team:'Old Dominion'},
            {id:524, team:'Old Westbury'},
            {id:525, team:'Olivet'},
            {id:506045, team:'Olivet Nazarene'},
            {id:526, team:'SUNY Oneonta'},
            {id:527, team:'Oral Roberts'},
            {id:529, team:'Oregon'},
            {id:528, team:'Oregon St.'},
            {id:500078, team:'Oregon Tech'},
            {id:530, team:'Oswego St.'},
            {id:506513, team:'Ottawa (AZ)'},
            {id:506046, team:'Ottawa (KS)'},
            {id:531, team:'Otterbein'},
            {id:1289, team:'Ouachita Baptist'},
            {id:503987, team:'Our Lady of the Lake'},
            {id:503990, team:'Ozark Christian'},
            {id:504000, team:'Ozarks (MO)'},
            {id:23008, team:'Ozarks (AR)'},
            {id:533, team:'Pace'},
            {id:534, team:'Pacific'},
            {id:1297, team:'Pacific Lutheran'},
            {id:506090, team:'Pacific Union'},
            {id:2751, team:'Pacific (OR)'},
            {id:535, team:'Paine'},
            {id:30045, team:'Palm Beach Atl.'},
            {id:506544, team:'Park (AZ)'},
            {id:504050, team:'Park (MO)'},
            {id:504055, team:'Paul Quinn'},
            {id:506239, team:'Paul Smiths'},
            {id:30190, team:'Penn College'},
            {id:25719, team:'Penn St.-Altoona'},
            {id:538, team:'Penn St.-Behrend'},
            {id:30081, team:'Penn St. Harrisburg'},
            {id:506540, team:'Penn St.-Shenango'},
            {id:30044, team:'Penn St.-Berks'},
            {id:540, team:'Penn'},
            {id:30114, team:'Penn St.-Abington'},
            {id:539, team:'Penn St.'},
            {id:506277, team:'Penn St.-Beaver'},
            {id:504077, team:'Penn St.-Brandywine'},
            {id:506280, team:'Penn St.-Du Bois'},
            {id:506278, team:'Penn St.-Fayette'},
            {id:506261, team:'Penn St-Gr Allegheny'},
            {id:506297, team:'Penn St.-Hazleton'},
            {id:506298, team:'Penn St.-Lehigh Val.'},
            {id:506331, team:'Penn St.-Mont Alto'},
            {id:506264, team:'Penn St.-New Kens.'},
            {id:506302, team:'Penn St.-Schuylkill'},
            {id:506324, team:'Penn St.-Scranton'},
            {id:506332, team:'Penn St-Wilkes Barre'},
            {id:506370, team:'Penn St-Worthington'},
            {id:506326, team:'Penn St.-York'},
            {id:504062, team:'Pensacola Christian'},
            {id:541, team:'Pepperdine'},
            {id:506008, team:'Peru St.'},
            {id:542, team:'Pfeiffer'},
            {id:504115, team:'Philander Smith'},
            {id:21830, team:'Piedmont'},
            {id:504130, team:'Piedmont Int\'l'},
            {id:504135, team:'Pikeville'},
            {id:544, team:'Pine Manor'},
            {id:1314, team:'Pittsburg St.'},
            {id:545, team:'Pittsburgh'},
            {id:1315, team:'Pitt.-Bradford'},
            {id:11839, team:'Pitt.-Greensburg'},
            {id:546, team:'Pitt.-Johnstown'},
            {id:547, team:'Plattsburgh St.'},
            {id:548, team:'Plymouth St.'},
            {id:30149, team:'Point Loma'},
            {id:504175, team:'Point Park'},
            {id:500270, team:'Point U.'},
            {id:506464, team:'Polytechnic (P.R.)'},
            {id:9500, team:'SUNY Poly'},
            {id:549, team:'Pomona-Pitzer'},
            {id:551, team:'Portland'},
            {id:504187, team:'Portland Bible'},
            {id:550, team:'Portland St.'},
            {id:1318, team:'Post'},
            {id:552, team:'SUNY Potsdam'},
            {id:553, team:'Prairie View'},
            {id:2715, team:'Pratt'},
            {id:1320, team:'Presbyterian'},
            {id:504212, team:'Presentation'},
            {id:554, team:'Princeton'},
            {id:555, team:'Principia'},
            {id:506169, team:'Providence (MT)'},
            {id:506493, team:'Providence Christian'},
            {id:556, team:'Providence'},
            {id:506307, team:'Providence-Manitoba'},
            {id:2682, team:'P.R.-Bayamon'},
            {id:24361, team:'P.R.-Mayaguez'},
            {id:2716, team:'P.R.-Rio Piedras'},
            {id:504226, team:'P.R.-Arecibo'},
            {id:506503, team:'P.R.-Carolina'},
            {id:506419, team:'P.R.-Cayey'},
            {id:506420, team:'P.R.-Humacao'},
            {id:504231, team:'P.R.-Ponce'},
            {id:557, team:'Puget Sound'},
            {id:30029, team:'Purchase'},
            {id:308, team:'Purdue Fort Wayne'},
            {id:559, team:'Purdue'},
            {id:30222, team:'Purdue Northwest'},
            {id:504260, team:'Purdue-North Cent.'},
            {id:560, team:'Queens (NY)'},
            {id:11504, team:'Queens (NC)'},
            {id:561, team:'Quincy'},
            {id:562, team:'Quinnipiac'},
            {id:506387, team:'Quest (CAN)'},
            {id:563, team:'Radford'},
            {id:564, team:'Ramapo'},
            {id:502132, team:'Randall'},
            {id:566, team:'Randolph'},
            {id:565, team:'Randolph-Macon'},
            {id:567, team:'Redlands'},
            {id:506514, team:'Regent'},
            {id:506457, team:'Regina (CAN)'},
            {id:569, team:'Regis (MA)'},
            {id:568, team:'Regis (CO)'},
            {id:506141, team:'Reinhardt'},
            {id:570, team:'Rensselaer'},
            {id:572, team:'Rhode Island'},
            {id:571, team:'Rhode Island Col.'},
            {id:573, team:'Rhodes'},
            {id:574, team:'Rice'},
            {id:575, team:'Richmond'},
            {id:576, team:'Rider'},
            {id:504350, team:'Rio Grande'},
            {id:577, team:'Ripon'},
            {id:18422, team:'Rivier'},
            {id:578, team:'Roanoke'},
            {id:504364, team:'Robert Morris (IL)'},
            {id:579, team:'Robert Morris'},
            {id:506377, team:'Robert Morris-Peoria'},
            {id:506279, team:'Robert Morris-S\'fiel'},
            {id:30150, team:'Roberts Wesleyan'},
            {id:581, team:'Rochester (NY)'},
            {id:503129, team:'Rochester College'},
            {id:580, team:'RIT'},
            {id:582, team:'Rockford'},
            {id:1328, team:'Rockhurst'},
            {id:506025, team:'Rocky Mountain'},
            {id:583, team:'Roger Williams'},
            {id:30180, team:'Rogers St.'},
            {id:584, team:'Rollins'},
            {id:504410, team:'Roosevelt'},
            {id:585, team:'Rose-Hulman'},
            {id:15882, team:'Rosemont'},
            {id:259, team:'Rowan'},
            {id:506512, team:'Rust'},
            {id:588, team:'Rutgers-Camden'},
            {id:589, team:'Rutgers-Newark'},
            {id:587, team:'Rutgers'},
            {id:506432, team:'Ryerson (CAN)'},
            {id:506353, team:'S.C. Beaufort'},
            {id:504446, team:'Sacred Heart (PR)'},
            {id:590, team:'Sacred Heart'},
            {id:11036, team:'Sage Colleges'},
            {id:591, team:'Saginaw Valley'},
            {id:500153, team:'SAGU American Indian'},
            {id:506002, team:'St. Ambrose'},
            {id:506422, team:'St. Andrews'},
            {id:595, team:'Saint Benedict'},
            {id:9684, team:'St. Elizabeth'},
            {id:506180, team:'St. Francis (IL)'},
            {id:504500, team:'St. Francis (IN)'},
            {id:504563, team:'St. Joseph (VT)'},
            {id:605, team:'St. Joseph\'s (ME)'},
            {id:506468, team:'St. Katherine'},
            {id:504578, team:'St. Louis Pharmacy'},
            {id:506064, team:'St. Mary (KS)'},
            {id:504607, team:'St. Mary (NE)'},
            {id:504592, team:'St. Mary-Woods'},
            {id:506474, team:'St. Thomas (CAN)'},
            {id:504653, team:'St. Thomas (FL)'},
            {id:504660, team:'St. Xavier'},
            {id:506499, team:'SAIT Poly (CAN)'},
            {id:30041, team:'Salem (NC)'},
            {id:621, team:'Salem St.'},
            {id:19117, team:'Salem (WV)'},
            {id:622, team:'Salisbury'},
            {id:623, team:'Salve Regina'},
            {id:624, team:'Sam Houston St.'},
            {id:625, team:'Samford'},
            {id:627, team:'San Diego'},
            {id:502122, team:'San Diego Christian'},
            {id:626, team:'San Diego St.'},
            {id:629, team:'San Francisco'},
            {id:628, team:'San Fran. St.'},
            {id:630, team:'San Jose St.'},
            {id:631, team:'Santa Clara'},
            {id:30163, team:'Sarah Lawrence'},
            {id:506458, team:'Saskatchewan (CAN)'},
            {id:632, team:'Savannah St.'},
            {id:506483, team:'SCAD Atlanta'},
            {id:506402, team:'SCAD Savannah'},
            {id:1355, team:'Schreiner'},
            {id:506082, team:'Science & Arts Okla.'},
            {id:28594, team:'USciences'},
            {id:633, team:'Scranton'},
            {id:634, team:'Seattle Pacific'},
            {id:1356, team:'Seattle U'},
            {id:635, team:'Seton Hall'},
            {id:30063, team:'Seton Hill'},
            {id:652, team:'Sewanee'},
            {id:636, team:'Shaw'},
            {id:504767, team:'Shawnee St.'},
            {id:637, team:'Shenandoah'},
            {id:506509, team:'Shepherd (CA)'},
            {id:10870, team:'Shepherd'},
            {id:506099, team:'Sheridan (CAN)'},
            {id:638, team:'Shippensburg'},
            {id:30151, team:'Shorter'},
            {id:639, team:'Siena'},
            {id:504800, team:'Siena Heights'},
            {id:506497, team:'Sierra Nevada'},
            {id:506511, team:'Simmons (KY)'},
            {id:640, team:'Simmons'},
            {id:30127, team:'Simon Fraser'},
            {id:504809, team:'Simpson (CA)'},
            {id:641, team:'Simpson'},
            {id:30128, team:'Sioux Falls'},
            {id:642, team:'Skidmore'},
            {id:643, team:'Slippery Rock'},
            {id:644, team:'Smith'},
            {id:506364, team:'Soka'},
            {id:645, team:'Sonoma St.'},
            {id:646, team:'South Alabama'},
            {id:648, team:'South Carolina'},
            {id:11276, team:'S.C. Aiken'},
            {id:647, team:'South Carolina St.'},
            {id:10411, team:'USC Upstate'},
            {id:650, team:'South Dakota'},
            {id:30139, team:'South Dakota Mines'},
            {id:649, team:'South Dakota St.'},
            {id:651, team:'South Fla.'},
            {id:654, team:'Southeast Mo. St.'},
            {id:506151, team:'Southeastern (FL)'},
            {id:504906, team:'Southeastern Bapt.'},
            {id:655, team:'Southeastern La.'},
            {id:1371, team:'Southeastern Okla.'},
            {id:1376, team:'Southern Ark.'},
            {id:657, team:'Southern California'},
            {id:658, team:'Southern Conn. St.'},
            {id:659, team:'Southern Ill.'},
            {id:660, team:'SIUE'},
            {id:661, team:'Southern Ind.'},
            {id:662, team:'Southern Me.'},
            {id:663, team:'SMU'},
            {id:664, team:'Southern Miss.'},
            {id:30152, team:'Southern Nazarene'},
            {id:468, team:'Southern N.H.'},
            {id:506027, team:'Southern Ore.'},
            {id:665, team:'Southern U.'},
            {id:667, team:'Southern Utah'},
            {id:30164, team:'Southern Va.'},
            {id:30192, team:'Southern Wesleyan'},
            {id:504980, team:'Southern-N.O.'},
            {id:506012, team:'Southwest (NM)'},
            {id:2755, team:'Southwest Baptist'},
            {id:8736, team:'Southwest Minn. St.'},
            {id:506063, team:'Southwestern (KS)'},
            {id:505028, team:'S\'western Adventist'},
            {id:506149, team:'S\'western Assemblies'},
            {id:506285, team:'Southwestern Christ.'},
            {id:8744, team:'Southwestern Okla.'},
            {id:8746, team:'Southwestern (TX)'},
            {id:23260, team:'Spalding'},
            {id:505040, team:'Spring Arbor'},
            {id:30191, team:'Spring Hill'},
            {id:673, team:'Springfield'},
            {id:593, team:'St. Anselm'},
            {id:594, team:'Saint Augustine\'s'},
            {id:596, team:'St. Bonaventure'},
            {id:597, team:'St. Catherine'},
            {id:598, team:'St. Cloud St.'},
            {id:1334, team:'St. Edward\'s'},
            {id:599, team:'St. Francis Brooklyn'},
            {id:600, team:'Saint Francis (PA)'},
            {id:601, team:'St. John Fisher'},
            {id:603, team:'St. John\'s (NY)'},
            {id:602, team:'Saint John\'s (MN)'},
            {id:8687, team:'St. Joseph (CT)'},
            {id:30072, team:'St. Joseph\'s (Brkln)'},
            {id:1340, team:'St. Joseph\'s (L.I.)'},
            {id:606, team:'Saint Joseph\'s'},
            {id:607, team:'St. Lawrence'},
            {id:608, team:'Saint Leo'},
            {id:609, team:'Saint Louis'},
            {id:1343, team:'Saint Martin\'s'},
            {id:612, team:'Saint Mary\'s (MN)'},
            {id:1346, team:'St. Mary\'s (TX)'},
            {id:610, team:'Saint Mary\'s (CA)'},
            {id:8688, team:'St. Mary\'s (IN)'},
            {id:611, team:'St. Mary\'s (MD)'},
            {id:613, team:'Saint Michael\'s'},
            {id:614, team:'St. Norbert'},
            {id:615, team:'St. Olaf'},
            {id:617, team:'Saint Peter\'s'},
            {id:1347, team:'Saint Rose'},
            {id:618, team:'St. Scholastica'},
            {id:2810, team:'St. Thomas (TX)'},
            {id:1348, team:'St. Thomas Aquinas'},
            {id:620, team:'St. Thomas (MN)'},
            {id:30064, team:'Saint Vincent'},
            {id:674, team:'Stanford'},
            {id:675, team:'Staten Island'},
            {id:676, team:'SFA'},
            {id:506407, team:'Stephens College'},
            {id:506073, team:'Sterling (KS)'},
            {id:678, team:'Stetson'},
            {id:679, team:'Stevens'},
            {id:21852, team:'Stevenson'},
            {id:506508, team:'Stillman'},
            {id:681, team:'Stockton'},
            {id:682, team:'Stonehill'},
            {id:683, team:'Stony Brook'},
            {id:684, team:'Suffolk'},
            {id:1390, team:'Sul Ross St.'},
            {id:506380, team:'SUNY ESF'},
            {id:685, team:'Susquehanna'},
            {id:686, team:'Swarthmore'},
            {id:687, team:'Sweet Briar'},
            {id:688, team:'Syracuse'},
            {id:506050, team:'Tabor'},
            {id:505160, team:'Talladega'},
            {id:689, team:'Tampa'},
            {id:1395, team:'Tarleton St.'},
            {id:506071, team:'Taylor (IN)'},
            {id:506394, team:'TBA'},
            {id:690, team:'Temple'},
            {id:691, team:'Tennessee St.'},
            {id:692, team:'Tennessee Tech'},
            {id:505215, team:'Tenn. Temple'},
            {id:505220, team:'Tenn. Wesleyan'},
            {id:693, team:'Chattanooga'},
            {id:694, team:'Tennessee'},
            {id:695, team:'UT Martin'},
            {id:697, team:'Texas A&M'},
            {id:30090, team:'Tex. A&M Int\'l'},
            {id:199, team:'Tex. A&M-Commerce'},
            {id:26172, team:'A&M-Corpus Christi'},
            {id:696, team:'Tex. A&M-Kingsville'},
            {id:506487, team:'Tex. A&M-Texarkana'},
            {id:702, team:'UT Arlington'},
            {id:698, team:'TCU'},
            {id:505260, team:'Texas Col.'},
            {id:1400, team:'Texas Lutheran'},
            {id:30088, team:'Tex. Permian Basin'},
            {id:536, team:'UTRGV'},
            {id:699, team:'Texas Southern'},
            {id:670, team:'Texas St.'},
            {id:700, team:'Texas Tech'},
            {id:506403, team:'Texas Wesleyan'},
            {id:701, team:'Texas Woman\'s'},
            {id:703, team:'Texas'},
            {id:506321, team:'Texas-Brownsville'},
            {id:23121, team:'Texas-Dallas'},
            {id:704, team:'UTEP'},
            {id:706, team:'UTSA'},
            {id:30028, team:'Texas-Tyler'},
            {id:707, team:'Thiel'},
            {id:506315, team:'Thomas (GA)'},
            {id:2758, team:'Thomas (ME)'},
            {id:543, team:'Jefferson'},
            {id:506433, team:'Thompson Rivers (CN)'},
            {id:1403, team:'Tiffin'},
            {id:506224, team:'To Be Announced'},
            {id:505303, team:'Toccoa Falls'},
            {id:709, team:'Toledo'},
            {id:506362, team:'Toronto (CAN)'},
            {id:505310, team:'Tougaloo'},
            {id:506502, team:'Touro'},
            {id:711, team:'Towson'},
            {id:28749, team:'Transylvania'},
            {id:30002, team:'Trevecca Nazarene'},
            {id:30037, team:'Trine'},
            {id:505352, team:'Trinity (FL)'},
            {id:505332, team:'Trinity Baptist'},
            {id:505334, team:'Trinity Bible (ND)'},
            {id:505350, team:'Trinity Christian'},
            {id:713, team:'Trinity (CT)'},
            {id:506107, team:'Trinity Int\'l'},
            {id:715, team:'Trinity (TX)'},
            {id:23413, team:'Trinity Washington'},
            {id:506459, team:'Trinity Western (CN)'},
            {id:716, team:'Troy'},
            {id:506361, team:'Truett-McConnell'},
            {id:499, team:'Truman'},
            {id:717, team:'Tufts'},
            {id:718, team:'Tulane'},
            {id:719, team:'Tulsa'},
            {id:505378, team:'Turabo'},
            {id:12830, team:'Tusculum'},
            {id:720, team:'Tuskegee'},
            {id:506543, team:'Thomas More'},
            {id:721, team:'Air Force'},
            {id:722, team:'Coast Guard'},
            {id:724, team:'Merchant Marine'},
            {id:725, team:'Army West Point'},
            {id:726, team:'Navy'},
            {id:506105, team:'Union (KY)'},
            {id:505398, team:'Union (NE)'},
            {id:727, team:'Union (NY)'},
            {id:30153, team:'Union (TN)'},
            {id:505410, team:'Unity'},
            {id:506176, team:'UC Clermont'},
            {id:728, team:'Upper Iowa'},
            {id:30104, team:'Urbana'},
            {id:730, team:'Ursinus'},
            {id:30140, team:'Ursuline'},
            {id:732, team:'Utah'},
            {id:731, team:'Utah St.'},
            {id:30024, team:'Utah Valley'},
            {id:733, team:'Utica'},
            {id:734, team:'Valdosta St.'},
            {id:506055, team:'Valley City St.'},
            {id:30166, team:'Valley Forge'},
            {id:735, team:'Valparaiso'},
            {id:506434, team:'Vancouver Island'},
            {id:736, team:'Vanderbilt'},
            {id:504925, team:'Vanguard'},
            {id:737, team:'Vassar'},
            {id:506348, team:'Vaughn'},
            {id:738, team:'Vermont'},
            {id:505487, team:'Vermont Tech'},
            {id:506436, team:'Victoria (CAN)'},
            {id:505489, team:'Villa Maria'},
            {id:739, team:'Villanova'},
            {id:506190, team:'Virgin Islands'},
            {id:746, team:'Virginia'},
            {id:30181, team:'UVa-Wise'},
            {id:740, team:'VCU'},
            {id:741, team:'VMI'},
            {id:742, team:'Virginia Tech'},
            {id:743, team:'Virginia St.'},
            {id:744, team:'Virginia Union'},
            {id:745, team:'Va. Wesleyan'},
            {id:506391, team:'Virginia-Lynchburg'},
            {id:505533, team:'Viterbo'},
            {id:505535, team:'Voorhees'},
            {id:747, team:'Wabash'},
            {id:748, team:'Wagner'},
            {id:749, team:'Wake Forest'},
            {id:506242, team:'Waldorf'},
            {id:506100, team:'Walla Walla'},
            {id:30141, team:'Walsh'},
            {id:505562, team:'Warner'},
            {id:505560, team:'Warner Pacific'},
            {id:505565, team:'Warren Wilson'},
            {id:30236, team:'Warren Wilson'},
            {id:750, team:'Wartburg'},
            {id:2814, team:'Washburn'},
            {id:756, team:'Washington'},
            {id:752, team:'Wash. & Lee'},
            {id:506471, team:'Washington Adventist'},
            {id:751, team:'Wash. & Jeff.'},
            {id:753, team:'Washington Col.'},
            {id:754, team:'Washington St.'},
            {id:755, team:'WashU'},
            {id:506177, team:'Waterloo (CAN)'},
            {id:505610, team:'Wayland Baptist'},
            {id:757, team:'Wayne St. (MI)'},
            {id:1433, team:'Wayne St. (NE)'},
            {id:1434, team:'Waynesburg'},
            {id:505630, team:'Webb Inst.'},
            {id:505632, team:'Webber Int\'l'},
            {id:758, team:'Weber St.'},
            {id:759, team:'Webster'},
            {id:506469, team:'Welch'},
            {id:760, team:'Wellesley'},
            {id:761, team:'Wells'},
            {id:762, team:'Wentworth'},
            {id:763, team:'Wesley'},
            {id:764, team:'Wesleyan (CT)'},
            {id:14718, team:'Wesleyan (GA)'},
            {id:358, team:'West Ala.'},
            {id:765, team:'West Chester'},
            {id:506319, team:'West Coast Baptist'},
            {id:11740, team:'West Florida'},
            {id:766, team:'West Ga.'},
            {id:1438, team:'West Liberty'},
            {id:767, team:'West Tex. A&M'},
            {id:1439, team:'West Virginia St.'},
            {id:506393, team:'West Va. Tech'},
            {id:768, team:'West Virginia'},
            {id:9630, team:'West Va. Wesleyan'},
            {id:506536, team:'Westcliff'},
            {id:769, team:'Western Caro.'},
            {id:2759, team:'Western Colo.'},
            {id:770, team:'Western Conn. St.'},
            {id:771, team:'Western Ill.'},
            {id:772, team:'Western Ky.'},
            {id:774, team:'Western Mich.'},
            {id:775, team:'Western New Eng.'},
            {id:1445, team:'Western N.M.'},
            {id:506443, team:'W\'ernw Ontario (CAN)'},
            {id:1446, team:'Western Ore.'},
            {id:1448, team:'Western Wash.'},
            {id:777, team:'Westfield St.'},
            {id:1450, team:'Westminster (MO)'},
            {id:1451, team:'Westminster (PA)'},
            {id:30209, team:'Westminster (UT)'},
            {id:505775, team:'Westmont'},
            {id:778, team:'Wheaton (IL)'},
            {id:779, team:'Wheaton (MA)'},
            {id:12799, team:'Wheeling'},
            {id:780, team:'Whitman'},
            {id:781, team:'Whittier'},
            {id:1454, team:'Whitworth'},
            {id:782, team:'Wichita St.'},
            {id:783, team:'Widener'},
            {id:505825, team:'Wilberforce'},
            {id:500077, team:'Wiley'},
            {id:784, team:'Wilkes'},
            {id:785, team:'Willamette'},
            {id:786, team:'William & Mary'},
            {id:505850, team:'William Carey'},
            {id:506290, team:'William Jessup'},
            {id:30120, team:'William Jewell'},
            {id:787, team:'Wm. Paterson'},
            {id:17094, team:'William Peace'},
            {id:506401, team:'William Penn'},
            {id:30073, team:'William Smith'},
            {id:506076, team:'William Woods'},
            {id:505872, team:'Williams Baptist'},
            {id:789, team:'Williams'},
            {id:1460, team:'Wilmington (DE)'},
            {id:1461, team:'Wilmington (OH)'},
            {id:12973, team:'Wilson'},
            {id:506389, team:'Windsor (CAN)'},
            {id:1462, team:'Wingate'},
            {id:506425, team:'Winnipeg (CAN)'},
            {id:790, team:'Winona St.'},
            {id:791, team:'Winston-Salem'},
            {id:792, team:'Winthrop'},
            {id:8911, team:'Wis. Lutheran'},
            {id:793, team:'Wis.-Eau Claire'},
            {id:794, team:'Green Bay'},
            {id:795, team:'Wis.-La Crosse'},
            {id:796, team:'Wisconsin'},
            {id:797, team:'Milwaukee'},
            {id:798, team:'Wis.-Oshkosh'},
            {id:799, team:'Wis.-Parkside'},
            {id:800, team:'Wis.-Platteville'},
            {id:801, team:'UW-River Falls'},
            {id:802, team:'Wis.-Stevens Point'},
            {id:803, team:'Wis.-Stout'},
            {id:804, team:'Wis.-Superior'},
            {id:805, team:'Wis.-Whitewater'},
            {id:806, team:'Wittenberg'},
            {id:2915, team:'Wofford'},
            {id:807, team:'Wooster'},
            {id:808, team:'WPI'},
            {id:809, team:'Worcester St.'},
            {id:506325, team:'Wright St.-Lake'},
            {id:810, team:'Wright St.'},
            {id:811, team:'Wyoming'},
            {id:506000, team:'Xavier (LA)'},
            {id:812, team:'Xavier'},
            {id:813, team:'Yale'},
            {id:506504, team:'Yellowstone Chris.'},
            {id:814, team:'Yeshiva'},
            {id:506494, team:'York (CAN)'},
            {id:500575, team:'York (NE)'},
            {id:815, team:'York (PA)'},
            {id:2730, team:'York (NY)'},
            {id:30154, team:'Young Harris'},
            {id:817, team:'Youngstown St.'},
            ];;
    }
}

export default NCAA;
    