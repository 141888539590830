import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

const initialState = {
    atBatID: null,
    gameID: null,
    stateArray: [],
    currentIndex: 0,
    loading: false,
    error: null,
}

const createGameStateArrayStart = (state, action) =>{
    return updateObject(state, {
        loading:true
    })
}

const loadGameStateArrayStart = (state, action) =>{
    return updateObject(state, {
        loading:true
    })
}

const loadGameStateArrayFail = (state, action) =>{
    return updateObject(state, {loading: false, error:true, message:null});
}

const createGameStateArraySuccess = (state, action) =>{
    return updateObject(state, {
        loading:false,
        gameID: action.data.gameID
    })
}

const updateGameStateArraySuccess = (state,action) =>{
    return updateObject(state, {
        loading:false,
        gameID: action.data.gameID,
        atBatID: action.data.atBatID,
        stateArray: action.data.stateArray,
        currentIndex: action.data.currentIndex,
        error:null
    })
}

const loadGameStateArraySuccess = (state,action) =>{
    return updateObject(state, {
        loading:false,
        gameID: action.data.gameID,
        atBatID: action.data.atBatID,
        stateArray: action.data.stateArray,
        currentIndex: action.data.currentIndex,
        error:null
    })
}

const initializeGameStateArrayStart = (state, action) =>{
    return updateObject(state, {
        loading:true
    })
}

const initializeGameStateArrayFail = (state, action) =>{
    return updateObject(state, {
        loading:false
    })
}

const initializeGameStateArraySuccess = (state, action) =>{
    return updateObject(state, {
        loading:false,
        gameID: action.gameID
    })
}

const reducer = (state = initialState, action) =>{

    switch(action.type){
        case actionTypes.CREATE_GAME_STATE_ARRAY_SUCCESS:{
           return createGameStateArraySuccess(state,action);
        }
        case actionTypes.CREATE_GAME_STATE_ARRAY_START:{
           return createGameStateArrayStart(state,action);
        }
        case actionTypes.UPDATE_GAME_STATE_ARRAY_SUCCESS:{
            return updateGameStateArraySuccess(state,action);
        }
        case actionTypes.LOAD_GAME_STATE_ARRAY_START:{
           return loadGameStateArrayStart(state,action);
        }
        case actionTypes.LOAD_GAME_STATE_ARRAY_FAIL:{
            return loadGameStateArrayFail(state,action);
         }
        case actionTypes.LOAD_GAME_STATE_ARRAY_SUCCESS:{
           return loadGameStateArraySuccess(state,action);
        }
        case actionTypes.INITIALIZE_GAME_STATE_ARRAY_START:{
           return initializeGameStateArrayStart(state,action);
        }
        case actionTypes.INITIALIZE_GAME_STATE_ARRAY_FAIL:{
           return initializeGameStateArrayFail(state,action);
        }
        case actionTypes.INITIALIZE_GAME_STATE_ARRAY_SUCCESS:{
           return initializeGameStateArraySuccess(state,action);
        }
        default:{
            return state;
        }
    }
};

export default reducer;