import React from 'react'


const PitchHistoryItem = (props) => {

    let pitcher = props.pitch.pitcher;
    let batter = props.pitch.batter;
    if(!pitcher){
        pitcher = {
            firstName: 'Demo',
            lastName: 'Demo',
            number: '0'
        }
    }
    if(!batter){
        batter = {
            firstName: 'Demo',
            lastName: 'Demo',
            number: '0'
        }
    }
    return (
        <tr>
            <td>{props.pitch.index}</td>
            <td>{props.pitch.id}</td>
            <td>{props.pitch.inning}</td>
            <td>{props.pitch.pitchLocation}</td>
            <td>{props.pitch.strike ? "STRIKE" : "BALL"}</td>
            <td>{props.pitch.ballCount}</td>
            <td>{props.pitch.strikeCount}</td>
            <td>{props.pitch.batAction}</td>
            <td>{props.pitch.batActionLocation}</td>
            <td>{batter.firstName} {batter.lastName} #{batter.number}</td>
            <td>{props.pitch.battingRight ? "RIGHT" : "LEFT"}</td>
            <td>{pitcher.firstName} {pitcher.lastName} #{pitcher.number}</td>
            <td>{props.pitch.batActionType}</td>
            <td>{props.pitch.hitAction}</td>
            <td>{props.pitch.pitchType}</td>
            <td>{props.pitch.pitchResult}</td>
            <td>{props.pitch.result}</td>
            <td>{props.pitch.runningResult}</td>
        </tr>
    )
}

export default PitchHistoryItem;