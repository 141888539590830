import React, {Component} from 'react';
import classes from './BaseRunner.module.css';
class BaseRunner extends Component{



    render(){
        let base = this.props.base;
        let coords = {
            left:0,
            top:0
        }
        if(base === 1){
            coords.left = 185;
            coords.top = 151;
        }
        else if(base === 2){
            coords.left = 124;
            coords.top = 101;
        }
        else if(base === 3){
            coords.left = 69;
            coords.top = 151;
        }

        let baseRunnerNumber = null;
        if(this.props.runner.batter){
            baseRunnerNumber = <>#{this.props.runner.batter.number}</>
        }

        return (
            <div className={classes.BaseRunner} style={
                {top: coords.top,
                left: coords.left ,
                
                display: 'block'
                }}
                onClick={() => this.props.handleReconcileBaseRunner(this.props.runner)}
                >
                {baseRunnerNumber}<br/>    
            </div>
        )
    }

};


export default BaseRunner;