import React, {Component} from 'react';
import {connect} from 'react-redux';
import classes from './TrainingNote.module.css';
import * as ActionTypes from '../../../../store/actions/index';
import Button from '../../../../components/UI/Button/Button';

class TrainingNote extends Component{

    state = {
        note: ''
    }

    componentDidMount(){
        this.setState({
            note: this.props.gameNotes
        })
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.gameNotes !== prevProps.gameNotes){
            this.setState({
                note: this.props.gameNotes
            });
            this.props.onSaveTrainingState(this.props.token, this.props.atBatState);
        }
    }

    handleNoteChange = event =>{
        let newNote = event.target.value;
        this.setState({ 
            note : newNote
        });

    }
    handleNoteUpdate = () =>{
        //console.log('handle note update ', this.state.note);
        this.props.onSetGameNotes(this.state.note);
        
    }


    render(){
        return (
            <div className={classes.TrainingNote}>
            <textarea className={classes.TextArea} value={this.state.note} 
                                    onChange={this.handleNoteChange}>
            </textarea>
            <br/>
            <Button btnType="Danger" clicked={this.handleNoteUpdate}>Save Notes</Button>
            </div>
        )
    }
}


const mapStateToProps = state =>{
    return{
        gameNotes: state.atBat.gameNotes,
        atBatState: state.atBat,
        token: state.auth.token,
    }
}
const mapDispatchToProps = dispatch =>{
    return{
        onSetGameNotes: (data)=>dispatch(
            ActionTypes.setGameNotes(data)
        ),
        onSaveTrainingState: (token, data)=>dispatch(
            ActionTypes.saveTrainingState(token, data)
        ),
    }
}
export default connect(mapStateToProps, mapDispatchToProps) (TrainingNote);