import React from 'react'
import styles from './PitchResultButtons.module.css';
import PitchResultButton from './PitchResultButton/PitchResultButton';

const PitchResultButtons = (props) =>{

   


    let options = [
        'Take',
        'Swing',
        'Bunt',
        'Slap',
        'HBP'
    ];

    let titles = [
        'Take',
        'Swing',
        'Bunt',
        'Slap',
        'HBP'
    ];

    if(props.strikeCount >= 2){
        options.push("DRP3-OUT");
        titles.push("DRP3-OUT");
        options.push("DRP3-PB/WP");
        titles.push("DRP3-PB/WP")
    }

    if(props.pitchType === 'Baseball'){
        if(props.battingPractice){
            options = [
                'Take',
                'Swing',
                'Bunt'
            ];
    
            titles = [
                'Take',
                'Swing',
                'Bunt'
            ];
        }
        else{
            
            options = [
                'Take',
                'Swing',
                'Bunt',
                'HBP'
            ];

            titles = [
                'Take',
                'Swing',
                'Bunt',
                'HBP'
            ];
 
            if(props.strikeCount >= 2){
                options.push("DRP3-OUT");
                titles.push("DRP3-OUT");
                options.push("DRP3-PB/WP");
                options.push("DRP3-PB/WP")
            }
        }
    }else if(props.battingPractice){
        options = [
            'Take',
            'Swing',
            'Bunt',
            'Slap'
        ];
    
        let titles = [
            'Take',
            'Swing',
            'Bunt',
            'Slap'
        ];
    }

       

    if(props.training !== null && props.training.trainingType === 'Pitcher'){
        options = [
            'Take'
        ]
        titles = [
            'Save'
        ]
    }

    let buttons = [];

    for(let i=0;i<options.length; ++i){
        let color= null;
        let width = '50px';
        if(i === 0){
            color = 'blue';
        }
        else if (i === 1){
            color = 'red';
        }
        else if (i === 2){
            color = 'orange';
        }
        else if (i === 3){
            color = 'purple';
        }
        if( i > 4){
            width = '100px';
        }
        let button =  <PitchResultButton key={i} width={width} type={options[i]} title={titles[i]}  color={color} click={props.click(options[i])}
        
        />

        if(options[i] === "HBP"){
            button =  <PitchResultButton key={i} width={width} type={options[i]} title={titles[i]}  color={color} click={props.click(options[i])}
        />
        }

        buttons.push(button)
    }

    // const buttons = options.map((o,i) => {
    //     let color= null;
    //     if(i === 0){
    //         color = 'blue';
    //     }
    //     else if (i === 1){
    //         color = 'red';
    //     }
    //     else if (i === 2){
    //         color = 'orange';
    //     }
    //     else if (i === 3){
    //         color = 'purple';
    //     }
    //     return (
    //         <PitchResultButton key={i} type={o}  color={color} click={props.click(o)}
           
    //         />
    //     )
    // });

    return(
        <div className={styles.PitchResultButtons}>
        {buttons}
        </div>

    )


    
}


export default PitchResultButtons;