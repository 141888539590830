
import React from 'react';
import classes from './LineupPlayer.module.css';
import Button from '../../../components/UI/Button/Button';

const LineupPlayer = (props) =>{

    return (
        <div className={classes.LineupPlayer}>
            <p>{props.orderNumber} - #<span className={classes.Number}>{props.player.number}</span> 
            {props.player.firstName} {props.player.lastName} {props.player.currentPosition}
            </p>
            <span className={classes.ButtonSpan}><Button btnType="Danger" clicked={props.removePlayer}>REMOVE</Button></span>
        </div>
    )
}

export default LineupPlayer;