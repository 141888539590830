import React from 'react'
import Aux from '../../hoc/Aux';
import styles from './PitchPlotter.module.css';
import StrikeZone from './StrikeZone/StrikeZone';





const PitchPlotter = (props) => {

    
    function mouseMoveHandler(e){
        let pitchArea = document.getElementById('pitcharea');
        const rect = pitchArea.getBoundingClientRect();
        let x=e.clientX;
        let y=e.clientY;
        x = (e.clientX - rect.left) / props.width;
        y = (e.clientY - rect.top) / props.height;
        props.updateLocation(x,y);
        
    }

    function mouseOutHandler(e){
        props.updateLocation(0,0);
    }

    function onClickHandler(e){
        let pitchArea = document.getElementById('pitcharea');
        const rect = pitchArea.getBoundingClientRect();
        ////console.log(rect.top, rect.right, rect.bottom, rect.left);
        ////console.log('clicked! ' + e.clientX +' ' + e.clientY);
        let x=e.clientX;
        let y=e.clientY;
        x = (e.clientX - rect.left) / props.width;
        y = (e.clientY - rect.top) / props.height;
        ////console.log(props);
        ////console.log('normalized coordinates ' + x +' ' + y)
        //props.updateLocation(x,y);
        if(props.clickLocation){
            props.clickLocation(x,y);
        }
    }

    

    return(
        <Aux>
            
            <div id="pitcharea" className={styles.PitchArea} onClick={onClickHandler} onMouseMove={mouseMoveHandler} onMouseOut={mouseOutHandler}>
                <StrikeZone width={props.width} height={props.height} />
                {props.children}
            </div>
            
        </Aux>
    );
};

export default PitchPlotter;