import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as ActionTypes from '../../../store/actions/index';
import {Redirect} from 'react-router-dom';



import Modal from '../../../components/UI/Modal/Modal';
import Button from '../../../components/UI/Button/Button';
import classes from './Users.module.css';
import Spinner from '../../../components/UI/Spinner/Spinner';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

// create your cellRenderer as a React component
class RoleCellRenderer extends React.Component {

    // did you know that React passes props to your component constructor??
    constructor(props) {
        super(props);
        // from here you can access any of the props!
        //console.log('RoleCellRenderer The value is ',  props.data);
        // we can even call grid API functions, if that was useful
        //props.api.selectAll();
        this.onChangeHandler = this.onChangeHandler.bind(this);
    }
    onChangeHandler = (event) =>{
        ////console.log('props', this.props);
        ////console.log('PositionCellRenderer->position select changed' , event.target.value);
        ////console.log('handlePositionChange', this.props.data.handlePositionChange);
        this.props.data.handleRoleChange(this.props.rowIndex, this.props.data, event.target.value);
    }


    render() {
        let roles = ['SysAdmin', 'OrgAdmin', 'OrgAdminTraining', 'Admin', 'Viewer', ''];

        if(this.props.data.currentUser.role === 'OrgAdmin'){
            roles = ['OrgAdmin', 'Admin', 'Viewer', ''];
        }
        if(this.props.data.currentUser.role === 'OrgAdminTraining'){
            roles = ['OrgAdminTraining', 'AdminTraining', 'ViewerTraining', ''];
        }

        let disabled = false;
        if(this.props.data.currentUser.id === this.props.data.id){
            disabled = true;
        }
        
        let optionsJSX = [];
        let roleSet = false;
        for(let i=0;i<roles.length; ++i){
            
            let option = <option  value={roles[i]}>{roles[i] == '' ? 'None' : roles[i]}</option>;
            //console.log('this.props.data.role = '  + this.props.data.role + ' ' + roles[i]);
            if(this.props.data.role === roles[i]){
                option = <option  selected value={roles[i]}>{roles[i] == '' ? 'None' : roles[i]}</option>;
                roleSet = true;
            }
            optionsJSX.push(option);
        }
        //this person has a role the auser doesn't have rights to edit
        if(!roleSet){
            disabled = true;
            optionsJSX.push(<option  selected value={this.props.data.role}>{this.props.data.role}</option>)
        }
        
        return <select onChange={this.onChangeHandler} disabled={disabled}>
            
        {optionsJSX}
      </select>;
    }
}


class AliasCellRenderer extends React.Component {

    // did you know that React passes props to your component constructor??
    constructor(props) {
        super(props);
        // from here you can access any of the props!
        //console.log('AliasCellRenderer The value is ',  props.data);
        // we can even call grid API functions, if that was useful
        //props.api.selectAll();
        this.onChangeHandler = this.onChangeHandler.bind(this);
    }
    onChangeHandler = (event) =>{
        ////console.log('props', this.props);
        ////console.log('PositionCellRenderer->position select changed' , event.target.value);
        ////console.log('handlePositionChange', this.props.data.handlePositionChange);
        this.props.data.handleRoleChange(this.props.rowIndex, this.props.data, event.target.value);
    }


    render() {
        
        /*let disabled = false;
        if(this.props.data.currentUser.id === this.props.data.id){
            disabled = true;
        }
        
        let optionsJSX = [];
        let roleSet = false;
        for(let i=0;i<roles.length; ++i){
            
            let option = <option  value={roles[i]}>{roles[i] == '' ? 'None' : roles[i]}</option>;
            //console.log('this.props.data.role = '  + this.props.data.role + ' ' + roles[i]);
            if(this.props.data.role === roles[i]){
                option = <option  selected value={roles[i]}>{roles[i] == '' ? 'None' : roles[i]}</option>;
                roleSet = true;
            }
            optionsJSX.push(option);
        }
        //this person has a role the auser doesn't have rights to edit
        if(!roleSet){
            disabled = true;
            optionsJSX.push(<option  selected value={this.props.data.role}>{this.props.data.role}</option>)
        }
        
        return <select onChange={this.onChangeHandler} disabled={disabled}>
            
        {optionsJSX}
      </select>;*/
        let alias = null;
        if(this.props.data.alias === "True"){
            alias = <span className={classes.Link}>{this.props.data.alias}</span>
        }
        else{
            alias = <span classes={classes.NoLink}>{this.props.data.alias}</span>
        }
        return( <>{alias}</>
        )
    }
}


class Users extends Component{
    constructor(props){
        super(props);
        this.state = {
            columnDefs: [
                {
                    headerName: "ID", field: "id", sortable: false, filter: false, hide: true
                },
                {
                    headerName: "First", field: "firstname", sortable: true, filter: true, cellStyle: {justifyContent: 'center'}
                },
                {
                    headerName: "Role", field: "role", sortable: true, filter: true, cellStyle: {justifyContent: 'center'},
                    cellRendererFramework: RoleCellRenderer
                }
                ,
                {
                    headerName: "Org", field: "organization", sortable: true, filter: true, cellStyle: {justifyContent: 'center'}
                }, 
                {
                    headerName: "Type", field: "teamType", sortable: true, filter: true, cellStyle: {justifyContent: 'center'}, width:100
                }, 
                {
                    headerName: "Alias", field: "alias", sortable: true, filter: true, cellStyle: {justifyContent: 'center'},  width:100,
                    cellRendererFramework: AliasCellRenderer
                }, 
            ],
            rowData: []
        }
        
    }
    onGridReady = params => {
        ////console.log('onGridReady!');
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
    }
    componentWillMount(){   
        if(this.props.isAuth){
            this.props.onClearGameState();

            if(this.props.auser.role === 'SysAdmin'){
                this.props.onGetAllUsers(this.props.token);
            }
            else if(this.props.auser.role === 'OrgAdmin' ||
            this.props.auser.role === 'OrgAdminTraining'){
                this.props.onGetOrgUsers(this.props.token, this.props.auser.organizationId);
            }
            
        }
    }
    componentDidMount(){
        if(this.props.auser.role === 'SysAdmin'){
            this.setState({
                columnDefs: [
                    {
                        headerName: "ID", field: "id", sortable: false, filter: false, hide: true
                    }
                    ,
                    {
                        headerName: "Name", field: "firstname", sortable: true, filter: true, cellStyle: {justifyContent: 'center'}
                    },
                    {
                            headerName: "Email", field: "email", sortable: true, filter: true, cellStyle: {justifyContent: 'center'}
                    },
                    {
                        headerName: "Role", field: "role", sortable: true, filter: true, cellStyle: {justifyContent: 'center'},
                        cellRendererFramework: RoleCellRenderer
                    }
                    ,
                    {
                        headerName: "Org", field: "organization", sortable: true, filter: true, cellStyle: {justifyContent: 'center'}
                    }, 
                    {
                        headerName: "Type", field: "teamType", sortable: true, filter: true, cellStyle: {justifyContent: 'center'}, width:100
                    }, 
                    {
                        headerName: "Alias", field: "alias", sortable: true, filter: true, cellStyle: {justifyContent: 'center'}, width:100,
                        cellRendererFramework: AliasCellRenderer
                    } ,
                    {
                        headerName: "Method", field: "method", sortable: true, filter: true, cellStyle: {justifyContent: 'center'}, width:100
                    },
                    {
                        headerName: "Date", field: "date", sortable: true, filter: true, cellStyle: {justifyContent: 'center'}, width:100
                    },
                ]
            })
        }
        else if(this.props.auser.role === 'OrgAdmin'){
            this.setState({
                columnDefs: [
                    {
                        headerName: "ID", field: "id", sortable: false, filter: false, hide: true
                    },
                    {
                        headerName: "Name", field: "firstname", sortable: true, filter: true, cellStyle: {justifyContent: 'center'}
                    },
                    {
                        headerName: "Email", field: "email", sortable: true, filter: true, cellStyle: {justifyContent: 'center'}
                    },
                    {
                        headerName: "Role", field: "role", sortable: true, filter: true, cellStyle: {justifyContent: 'center'},
                        cellRendererFramework: RoleCellRenderer
                    }
                    ,
                    {
                        headerName: "Org", field: "organization", sortable: true, filter: true, cellStyle: {justifyContent: 'center'}
                    },
                    {
                        headerName: "Type", field: "teamType", sortable: true, filter: true, cellStyle: {justifyContent: 'center'}, width:100
                    }, 
                ]
            })

        }
    }
    componentDidUpdate(prevProps, prevState){
        if(this.props.auser !== prevProps.auser){
            this.props.onClearGameState();
            if(this.props.auser.role === 'SysAdmin'){
                this.props.onGetAllUsers(this.props.token);
            }
            else if(this.props.auser.role === 'OrgAdmin'){
                this.props.onGetOrgUsers(this.props.token, this.props.auser.organizationId);
            }
            if(this.props.auser.role === 'SysAdmin'){
                this.setState({
                    columnDefs: [
                        {
                            headerName: "ID", field: "id", sortable: false, filter: false, hide: true
                        },
                        {
                            headerName: "Name", field: "firstname", sortable: true, filter: true, cellStyle: {justifyContent: 'center'}
                        },
                        {
                            headerName: "Email", field: "email", sortable: true, filter: true, cellStyle: {justifyContent: 'center'}
                        },
                        {
                            headerName: "Role", field: "role", sortable: true, filter: true, cellStyle: {justifyContent: 'center'},
                            cellRendererFramework: RoleCellRenderer
                        },
                        {
                            headerName: "Org", field: "organization", sortable: true, filter: true, cellStyle: {justifyContent: 'center'}
                        }, 
                        {
                            headerName: "Type", field: "teamType", sortable: true, filter: true, cellStyle: {justifyContent: 'center'}, width:100
                        }, 
                        {
                            headerName: "Alias", field: "alias", sortable: true, filter: true, cellStyle: {justifyContent: 'center'}, width:100,
                            cellRendererFramework: AliasCellRenderer
                        },
                        {
                            headerName: "Method", field: "method", sortable: true, filter: true, cellStyle: {justifyContent: 'center'}, width:100
                        },
                        {
                            headerName: "Date", field: "date", sortable: true, filter: true, cellStyle: {justifyContent: 'center'}, width:100
                        },
                    
                    ]
                })

                
            }
            else if(this.props.auser.role === 'OrgAdmin'){
                this.setState({
                    columnDefs: [
                        {
                            headerName: "ID", field: "id", sortable: false, filter: false, hide: true
                        },
                        {
                            headerName: "Name", field: "firstname", sortable: true, filter: true, cellStyle: {justifyContent: 'center'}
                        }
                        ,
                        {
                            headerName: "Email", field: "email", sortable: true, filter: true, cellStyle: {justifyContent: 'center'}
                        },
                        {
                            headerName: "Role", field: "role", sortable: true, filter: true, cellStyle: {justifyContent: 'center'},
                            cellRendererFramework: RoleCellRenderer
                        }
                        ,
                        {
                            headerName: "Org", field: "organization", sortable: true, filter: true, cellStyle: {justifyContent: 'center'}
                        },
                        {
                            headerName: "Type", field: "teamType", sortable: true, filter: true, cellStyle: {justifyContent: 'center'}, width:100
                        }, 
                    ]
                })

                
                
    
            }
        }
       /* if(!this.props.loading){
            if(prevProps.users && this.props.users && prevProps.users !== this.props.users){
                this.props.onClearGameState();
                let reloadUsers = false;
                if(prevProps.users.length !== this.props.users.length){
                    reloadUsers = true;
                }
                else{
                    for(let i=0;i<this.props.users.length; ++i){
                        if(prevProps.users[i].role !== this.props.users[i].role){
                            reloadUsers = true;
                            break;
                        }
                    }
                }
                if(reloadUsers){
                    this.props.onGetAllUsers(this.props.token);
                }
                //
            }
        }*/
    }

    onCellClicked = (result) =>{
        //console.log("onCellClicked", result);

        //console.log(result.colDef.field);

        if(result.colDef.field === 'alias'){
            //console.log('trying to alias account ', result.data);
            if(result.data.alias === "True"){
                //console.log('Hooray!  You can alias this account!');
                //console.log('calling alias with id=' + result.data.id);
                this.props.onAlias(this.props.token, result.data.id);

            }
            else{
                //console.log('Too bad!  You can\'t alias this account!');
            }
       }
    }

    //handlePositionChange(this.props.rowIndex, this.props.data, event.target.value)
    onRoleChange = (index, data, value) =>{
        //console.log("onRoleChange "+ index+" " +value, data);

        let user = this.props.users.find(x=>x.id === data.id);
        //let index = this.props.users.findIndex(x=>x.id === data.id);

        let updateUser = {...user};
        updateUser.role = value;

        //console.log('update role to' + value+' for user', updateUser);

        this.props.onUpdateUser(this.props.token,updateUser);

       /* if(data.Team === "Away"){
            //console.log("Update Away Team Player");
            let p = this.props.awayTeamPlayers.find(x=>x.id === data.id);
            let index = this.props.awayTeamPlayers.findIndex(x=>x.id === data.id);
            
            let updatedPlayers = [...this.props.awayTeamPlayers];
            let updatedPlayer = {...p};
            updatedPlayer.currentPosition = value;
            

            //console.log('updated Player = ', updatedPlayer);
            updatedPlayers.splice(index,1,updatedPlayer);
            //this.props.onUpdatePlayer(this.props.token, updatedPlayer);
            this.props.onSetAwayTeamPlayers(updatedPlayers);

        }
        else{
            //console.log("Update Home Team Player");
            let p = this.props.homeTeamPlayers.find(x=>x.id === data.id);
            let index = this.props.homeTeamPlayers.findIndex(x=>x.id === data.id);

            let updatedPlayers = [...this.props.homeTeamPlayers];
            let updatedPlayer = {...p};
            updatedPlayer.currentPosition = value;
            //console.log('updated Player = ', updatedPlayer)
            updatedPlayers.splice(index,1,updatedPlayer);
            //this.props.onUpdatePlayer(this.props.token, updatedPlayer);
            this.props.onSetHomeTeamPlayers(updatedPlayers);
        }*/
    }

    render(){
        let userRows = [];
        let spinnerJSX = <Spinner/>

        if(this.props.users && !this.props.loading){
            spinnerJSX = null;
            //console.log('this.props.users', this.props.users);
            //console.log('this.props.users[0]', this.props.users[0]);
            //let users = [...this.props.users];
            for(let i=0; i< this.props.users.length; ++i){
                let g = this.props.users[i];

                if(this.props.users[i].org !== null){
                    //console.log('ORG NOT NULL');
                }
                //console.log('user ', this.props.users[i]);
                userRows.push({
                    id: g.id,
                    role: g.role,
                    organization: g.org !== null ? (g.org.name) : g.organizationId,
                    teamType: g.org !== null ? g.org.teamType : '',
                    firstname: g.lastName + ', '+ g.firstName,
                    email: g.email,
                    method: g.method,
                    date: typeof g.signUpDate !== 'undefined' ? new Date(g.signUpDate).toLocaleDateString("en-US") : '',
                    currentUser : this.props.auser,
                    alias: g.alias && g.alias === true ? 'True' : 'False',
                    handleRoleChange: this.onRoleChange.bind(this)

                })
            }
        }

        let tableJSX = <div 
                            className="ag-theme-balham-dark"
                            style={{ 
                            height: '600px', 
                            width: 'inherit',
                            marginLeft :'auto',
                            marginRight : 'auto',
                            fontSize:'15px',
                            fontFamily:'inherit'
                        }} 
                        >
                            <AgGridReact
                            // useful for accessing the component directly via ref
                            ref="agGrid"
                            onCellClicked={this.onCellClicked.bind(this)}
                            columnDefs={this.state.columnDefs}
                            rowData={userRows}
                            onGridReady={this.onGridReady}>
                            </AgGridReact>
                        </div>

        let userCount = 0;
        if(this.props.users){
            userCount = this.props.users.length;
        }
        let userMessage = "Users";
        if(this.props.auser.role === 'SysAdmin'){
            userMessage = "All Users";
        }
        else if(this.props.auser.role === 'OrgAdmin'){
            if(this.props.auser.org){
                userMessage = this.props.auser.org.name +" Users";
            }
        }

        return(
            <div className={classes.Users}>
                {spinnerJSX}
                <h3>{userMessage}</h3>
                {/*games*/}
                {tableJSX}
            </div>
        );
    }
}


const mapStateToProps = state =>{
    return{
        isAuth: state.auth.token !== null,
        userId: state.auth.userId,
        auser: state.auth.user,
        loading: state.user.loading,
        error: state.user.error,
        message: state.user.message,
        token: state.auth.token,
        users: state.user.users
    }
}



const mapDispatchToProps = dispatch =>{
    return{
        
        //onGetOrganizationUsers(this.props.token, this.props.auser.organizationId);
        onGetOrgUsers: (token, orgId)=>dispatch(
            ActionTypes.getOrgUsers(token, orgId)
        ),
        
        onGetAllUsers: (token, userId)=>dispatch(
            ActionTypes.getAllUsers(token, userId)
        ),
        onClearGameState: ()=>dispatch(
            ActionTypes.clearGameState()
        ),
        onUpdateUser: (token, data)=>dispatch(
            ActionTypes.updateUser(token,data,'',false)
        ),
        onAlias: (token, id)=>dispatch(
            ActionTypes.alias(token,id)
        )

    }
};



export default connect(mapStateToProps, mapDispatchToProps) (Users);