import React, {Component} from 'react';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import Spinner from '../../../components/UI/Spinner/Spinner';
import classes from './TrainingForm.module.css';
import { connect } from 'react-redux';

class TrainingForm extends Component{
    state = {
        controls: {
            team:{
                
                elementType: 'select',
                elementConfig: {
                    label: 'Team',
                    options : [
        
                    ]
                    
                },
                value:'',
                validation: {},
                valid:true
            },
            
            trainingType:{
                
                elementType: 'select',
                elementConfig: {
                    label: 'Training Type',
                    options : [
                        {value: 'Pitcher', displayValue: 'Pitcher Practice'},
                        {value: 'Batter', displayValue: 'Batter Scrimmage'},
                        {value: 'BatterPractice', displayValue: 'Batter Practice'}
                    ]
                    
                },
                value:'Pitcher',
                validation: {},
                valid:true
            },

            description:{
                
                elementType: 'text',
                elementConfig: {
                    label: 'Description'
                },
                value:'',
                validation: {},
                valid:true
            },

            pitchType:{
                
                elementType: 'select',
                elementConfig: {
                    label: 'Pitch Type',
                    options : [
                        {value: 'Softball', displayValue: 'Softball'},
                        {value: 'Baseball', displayValue: 'Baseball'}
                    ]
                    
                },
                value:'Softball',
                validation: {},
                valid:true,
                disabled:true
            },

            year: {
                elementType: 'datepicker',
                elementConfig: {
                    label: 'Date',
                    type: 'text',
                    placeholder: 'Year'
                },
                value: '',
                validation: {
                    required: true,
                },
                valid: false,
                touched: false
            },
        },
        formIsValid: false,
        id: null
    }

    componentDidUpdate(prevProps, prevState) {

        
        if(prevProps.teams !== this.props.teams){
            if(this.props.teams.length > 0){
                ////console.log('GameForm->componentDidUpdate');
                ////console.log(this.props.teams);
                const updatedForm = {
                    ...this.state.controls
                }
                const updatedFormTeam = {...updatedForm['team']};
                
                updatedFormTeam.value = this.props.teams[0].id;
                let updatedOptions = [];
                let team;
                for(team in this.props.teams){
                    let option = {
                        value: this.props.teams[team].id,
                        displayValue: this.props.teams[team].name +' '+ this.props.teams[team].year
                    }
                    updatedOptions.push(option);
                }
                updatedFormTeam.elementConfig.options = updatedOptions;
                updatedFormTeam.valid = true;

                if(this.props.auser.org){
                    //console.log('TeamForm->componentDidMount: updating pitch type to ' + this.props.auser.org.teamType);
                    const updatedFormPitchType = {...updatedForm['pitchType']};
                    updatedFormPitchType.value =this.props.auser.org.teamType;
                    updatedForm['pitchType'] = updatedFormPitchType;
                }

                
                updatedForm['team'] = updatedFormTeam;
                
                let formIsValid = true;
                for(let inputIds in updatedForm){
                    formIsValid = updatedForm[inputIds].valid && formIsValid;
                }
                this.setState({controls: updatedForm, formIsValid: formIsValid});
            
            }
        }
    }

    componentDidMount(){
        if(this.props.teams){
            
        }
    }

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }
        
        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        if (rules.isNumeric) {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }


    inputChangedHandler = (event, inputIdentifier, type) =>{
        ////console.log('GameForm->inputChangeHandler ' + type);
        const updatedForm = {
            ...this.state.controls
        }
        const updatedFormElement = {...updatedForm[inputIdentifier]};
        let formIsValid = true;
        if(type === 'datepicker'){
            updatedFormElement.value = new Date(event);
            updatedFormElement.valid = true;
            ////console.log(updatedFormElement.value);
        }
        else{
            updatedFormElement.value = event.target.value;
            updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        
        }
        updatedFormElement.touched = true;
        updatedForm[inputIdentifier] = updatedFormElement;

        ////console.log(updatedForm);
        for(let inputIds in updatedForm){
            formIsValid = updatedForm[inputIds].valid && formIsValid;
        }
        this.setState({controls: updatedForm, formIsValid: formIsValid});
    }

    submitHandler = (event) =>{
        event.preventDefault();
        ////console.log('calling submitHandler in GameForm ' 
        
        //+ this.state.controls.homeTeam.value+' '
        //+ this.state.controls.awayTeam.value+' '
        //+ this.state.controls.year.value);

        let team = this.props.teams.find(team => team.id === this.state.controls.team.value);
       
        

        const data = {
            team: team,
            userId: this.props.userId,
            description: this.state.controls.description.value,
            date: this.state.controls.year.value,
            trainingType: this.state.controls.trainingType.value,
            pitchType: this.state.controls.pitchType.value
        }
       
        this.props.submit(event, data);
    }


    render(){

        const formElementsArray = [];
        for(let key in this.state.controls){
            formElementsArray.push({
                id: key,
                disabled: this.state.controls[key].disabled,
                config: this.state.controls[key],
                validation: this.state.controls[key].validation,
                valid: this.state.controls[key].valid,
                touched: this.state.controls[key].touched
            });
        }

        let form = (
            <form onSubmit={(event)=>this.submitHandler(event)}>
                    {formElementsArray.map(el => (
                        <Input 
                            key={el.id}
                            disabled = {el.config.disabled}
                            elementType={el.config.elementType}
                            elementConfig={el.config.elementConfig}
                            label={el.config.elementConfig.label}
                            value={el.config.value}
                            invalid = {!el.valid}
                            shouldValidate ={el.validation && el.touched}
                            changed={(event)=> this.inputChangedHandler(event, el.id, el.config.elementType)}/>
                    ))}
                        
                    
                    <Button disabled={!this.state.formIsValid} btnType="Success">SUBMIT</Button>
                </form>
        );

        if(this.props.loading){
            form = <Spinner />;
        }
       
        return (
            <div className={classes.GameForm}>
                <h3>{this.props.game === null? "Create Training" : "Update Training"}</h3>
                {form}
            </div>
        )
    }
}

const mapStateToProps = state =>{
    return{
        token: state.auth.token,
        userId: state.auth.userId,
        //games: state.game.games,
        trainings: state.training.trainings,
        teams: state.team.teams,
        auser: state.auth.user,
    };
}

export default connect(mapStateToProps, null) (TrainingForm);
