import React from 'react'
import styles from './PitchResultButton.module.css';

const PitchResultButton = (props) =>{

    //clickType
    
    //clickType
    let buttonJSX = 
        <div className={styles.PitchResultButton} 
        onClick={() => props.click(props.type)}
        style={{
            backgroundColor: props.color,
            width: props.width
        }}>
        {props.title}
        </div>
    if(props.clickType === "double"){
        buttonJSX = <div className={styles.PitchResultButton} 
        onDoubleClick={() => props.click(props.type)}
        style={{
            backgroundColor: props.color,
            width: props.width
        }}>
        {props.title}
        </div>
    }

    return(
        <>
        {buttonJSX}
        </>
    )
}


export default PitchResultButton;