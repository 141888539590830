import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';
import { auth } from '../actions';
const initialState = {
    error: false,
    errorMessage: '',
    updateMessage: '',
    token: null,
    userId: null,
    loading:false,
    authRedirectPath: '/',
    user: null
};



const setAuthLoadUserSuccess = (state, action) =>{
    //console.log('setAuthLoadUserSuccess', action);
    return updateObject(state, {
        user: action.user,
        updateMessage: 'User Updated'
    });
}

const setAuthRedirect = (state, action) =>{
    return updateObject(state, {authRedirectPath: action.path});
}

const authStart = (state, action) =>{
    return updateObject(state, {error:false, errorMessage:'',loading:true});
}

const resetFail = (state, action) =>{
    return updateObject(state, {
        error:true, 
        errorMessage: filterError(action.error), 
        updateMessage: '',
        loading:false
    });
}

const authFail = (state, action) =>{
    return updateObject(state, {
        error:true, 
        errorMessage: filterError(action.error), 
        updateMessage: '',
        loading:false,
        userId: null,
        token:null
    });
}

const authSuccess = (state, action) =>{
    return updateObject(state, {
        token:action.token,
        userId: action.userId,
        updateMessage: '',
        error:false, 
        errorMessage: '', 
        loading:false});
}

const authRefresh = (state, action) =>{
    return updateObject(state, {
        token:action.token,
        error:false, 
        errorMessage: '', 
        updateMessage: '',
        loading:false});
}

const authLogout = (state, action) =>{
    return updateObject(state, {
        token:null,
        userId: null,
        error:false, 
        errorMessage: '', 
        updateMessage: '',
        loading:false});
}

const regStart = (state, action) =>{
    return updateObject(state, {error:false, errorMessage:'',updateMessage: '',loading:true});
}

const filterError = (err) =>{
    switch(err){
        case "EMAIL_NOT_FOUND":
        {
            return "Email not found.";
        }
        case "INVALID_PASSWORD":
        {
            return "The password is invalid.";
        }
        case "EMAIL_EXISTS":
        {
            return "The email address is already in use by another account.";
        }
        case "TOO_MANY_ATTEMPTS_TRY_LATER":
        {
            return "We have blocked all requests from this device due to unusual activity. Try again later.";
        }
        case "USER_DISABLED":
        {
            return "The user account has been disabled by an administrator.";
        }
        default:
        {
            return err;
        }
    }
}

const regFail = (state, action) =>{
    return updateObject(state, {
        error:true, 
        errorMessage: filterError(action.error), 
        updateMessage: '',
        loading:false,
        userId: null,
        token:null
    });
}

const regSuccess = (state, action) =>{
    return updateObject(state, {
        token:action.token,
        userId: action.userId,
        updateMessage: '',
        error:false, 
        errorMessage: '', 
        loading:false});
}

const authResetSuccess = (state, action) =>{
    return updateObject(state, {
        updateMessage: 'Reset password successful',
        error:false, 
        errorMessage: '', 
        loading:false});

}

const reducer = (state = initialState, action) =>{

    switch(action.type){
        case actionTypes.AUTH_RESET_SUCCESS:{
            return authResetSuccess(state,action);
        }
        case actionTypes.UPDATE_AUTH_USER:{
            return setAuthLoadUserSuccess(state,action);
        }
        case actionTypes.AUTH_LOAD_USER_SUCCESS:{
            return setAuthLoadUserSuccess(state,action);
        }
        case actionTypes.SET_AUTH_REDIRECT:{
            return setAuthRedirect(state, action);
        }
        case actionTypes.AUTH_START:{
            return authStart(state,action);
        }
        case actionTypes.AUTH_RESET_FAIL:{
            return resetFail(state,action);
        }
        case actionTypes.AUTH_FAIL:{
            return authFail(state, action);
        }
        case actionTypes.AUTH_SUCCESS:{
            return authSuccess(state, action);
        }
        case actionTypes.AUTH_REFRESH:{
            return authRefresh(state, action);
        }
        case actionTypes.AUTH_LOGOUT:{
            return authLogout(state, action);
        }
        case actionTypes.REG_START:{
            return regStart(state, action);
        }
        case actionTypes.REG_FAIL:{
            return regFail(state, action);
        }
        case actionTypes.REG_SUCCESS:{
            return regSuccess(state, action);
        }
        default:
        {
            return state;
        }
    }
}

export default reducer;