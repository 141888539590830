import React from 'react';
import styles from './FieldLocation.module.css';

const FieldLocation = (props) =>{

    let lStyle = {
        top: props.top,
        left: props.left,
        width: props.width
    };
    if(props.color){
        lStyle = {...lStyle, backgroundColor: props.color}
    }
    return (
        <div className={styles.FieldLocation} style={lStyle}
        onClick={(e) => props.clicked(e, props.text)}>
        {props.text}
        </div>
    )
}

export default FieldLocation;