import * as actionTypes from './actionTypes';
import axios from '../../axios';




export const authStart = () =>{
    ////console.log('dispatched authStart');
    return{
        type:actionTypes.AUTH_START
    };
}

export const authSuccess = (token,userId) =>{
    return{
        type:actionTypes.AUTH_SUCCESS,
        token: token,
        userId: userId
    }
}

export const resetPasswordSuccess = () =>{
    return{
        type:actionTypes.AUTH_RESET_SUCCESS,
        
    }
}

export const loadUserDataSuccess = (data) =>{
    return{
        type:actionTypes.AUTH_LOAD_USER_SUCCESS,
        user: data
    }
}

export const updateAuthUser = (data)=>{
    return{
        type:actionTypes.UPDATE_AUTH_USER,
        user:data
    }
}

export const loadUserObject = (token, userId, email) =>{
    return dispatch =>{
        //console.log('calling loadUserObject with email ' + email);
        const queryParams = '?auth=' + token + '&orderBy="userId"&equalTo="'+userId+'"';
        axios.get('/users.json' + queryParams)
        .then(response => {
            //console.log('response from loadUserObject:');
            //console.log(response);
            let users = [];
            if(response.data && Object.entries(response.data).length !== 0 && response.data.constructor === Object){
                let userObj = null;
                for(let key in response.data){
                    userObj = { ...response.data[key], id: key}
                }
                //localStorage.setItem('user', JSON.stringify(userObj));
                dispatch(loadUserDataSuccess(userObj));

                axios.get('/organizations/'+userObj.organizationId +'.json?auth=' + token)
                    .then(response => {
                        ////console.log('getOrg action:', response.data);
                        let org = null;
                        if(response.data !== null){
                            org ={
                                ...response.data,
                                     id: userObj.organizationId
                                };
                            org.teamType = typeof org.teamType === 'undefined' ? 'Softball' : org.teamType;
                            userObj = {...userObj, org: org};
                            dispatch(loadUserDataSuccess(userObj));
                            //dispatch(getOrgSuccess(orgs));
                        }
                        else{
                            //dispatch(getOrgFail('no results found for id ' + id));
                        }
                        
                    })

                //DG - THIS IS TEMPORARY TO ADD AN ORGANIZATION TO THE USER
                /*if(userObj.organizationId === ''){
                    let orgData = {
                        name: 'FPD'
                    }
                    axios.post('organizations.json?auth=' + token, orgData)
                    .then(response =>{
                        //console.log("id="+response.data.name);
                        userObj.organizationId = response.data.name;
                        
                        axios.patch('users/'+userObj.id+'.json?auth=' + token, userObj)
                        .then(response =>{
                            //console.log("id="+response.data.name);
                            //dispatch(updateTeamSuccess(response.data.name, teamData));
                        })
                        .catch(error =>{
                            //console.log(error.response);
                            //dispatch(updateTeamFail(error.response.data.error));
                        });
                        //dispatch(createOrgSuccess(response.data.name, data));
                    })
                    .catch(error =>{
                        //console.log(error.response);
                        //dispatch(createOrgFail(error.response.data.error));
                    });
                }*/
            }
            else{
                //dispatch(getUserFail('no results found for userId ' + userId));
                let userObj = {
                    userId : userId,
                    role: '',
                    email: email,
                    organizationId: '-LuZjCH5K2h5jmpnqSC0',
                }
                axios.post('users.json?auth=' + token, userObj)
                .then(response =>{
                    userObj = {...userObj, id: response.data.name}
                    ////console.log("id="+response.data.name);
                    //dispatch(createTeamSuccess(response.data.name, teamData));
                    //JSON.stringify(myObj)
                    //localStorage.setItem('user', JSON.stringify(userObj));
                    dispatch(loadUserDataSuccess(userObj));
                })
                .catch(error =>{
                    //console.log(error.response);
                    //dispatch(createTeamFail(error.response.data.error));
                });

            }
        })
        .catch(error =>{
            dispatch(authFail());
        });
    };
}

export const authRefresh = (token) =>{
    return{
        type: actionTypes.AUTH_REFRESH,
        token: token
    }
}


export const regSuccess = (token,userId) =>{
    return{
        type:actionTypes.REG_SUCCESS,
        token: token,
        userId: userId
    }
}

export const authFail = (error) =>{
    return{
        type:actionTypes.AUTH_FAIL,
        error: error
    };
}


export const authResetFail = (error) =>{
    return{
        type:actionTypes.AUTH_RESET_FAIL,
        error: error
    };
}

export const logout = () =>{
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('loginDate');
    localStorage.removeItem('user');
    return{
        type:actionTypes.AUTH_LOGOUT
    };
}
//https://securetoken.googleapis.com/v1/token?key=[API_KEY]

/*
export const checkAuthTimeout =  (expirationTime) =>{
    return dispatch =>{
        setTimeout(() =>{
            ////console.log('calling logout from timer function');
            dispatch(logout());
        }, expirationTime * 1000);
    }
}
*/



export const checkAuthTimeout =  (expirationTime) =>{
    return dispatch =>{
        setTimeout(() =>{
            ////console.log('attempting refresh token')
            const authData = {
                grant_type: "refresh_token",
                refresh_token: localStorage.getItem('refreshToken')
            }
            const APIKEY = process.env.REACT_APP_KEY;
            axios.post('https://securetoken.googleapis.com/v1/token?key=' + APIKEY, authData)
            .then(response =>{
                ////console.log(response);
                const expirationDate = new Date(new Date().getTime() + response.data.expires_in * 1000);
                localStorage.setItem('token', response.data.id_token);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('refreshToken', response.data.refresh_token);
                dispatch(authRefresh(response.data.id_token));
                dispatch(checkAuthTimeout(response.data.expires_in));
            })
            .catch(err =>{

                const expirationDate = localStorage.getItem('expirationDate');
                ////console.log('refresh failed');
                const currentDate = new Date();
                if(currentDate > expirationDate){
                    ////console.log('currentDate > expirationDate', currentDate,expirationDate);
                    ////console.log('dispatch logout');
                    dispatch(logout());
                }
                else{
                    ////console.log('currentDate < expirationDate', currentDate,expirationDate);
                    dispatch(checkAuthTimeout(300000));
                }
            })


        },
        //check this more often then when it will actually expire to make sure it stays alive
        expirationTime * 100);
        //expirationTime * 1000);
    }
}



export const alias = (token, id)=>{
    return dispatch =>{
        axios.get('/users/'+id +'.json?auth=' + token)
        .then(response => {
            
            let user = null;
            if(response.data !== null){
                user = {
                    ...response.data,
                    id: id
                };
                dispatch(authSuccess(token, user.userId));
                dispatch(loadUserObject(token, user.userId))
            }
            else{
                dispatch(authFail('no results found for id ' + id));
            }
        })
        .catch(err =>{
            dispatch(authFail(err.response.data.error.message));
        });
    }
}


//reset password
//https://identitytoolkit.googleapis.com/v1/accounts:update?key=[API_KEY]
//{"idToken":"[FIREBASE_ID_TOKEN]","password":"[NEW_PASSWORD]","returnSecureToken":true}

/*returns
{
  "localId": "tRcfmLH7o2...",
  "email": "[user@example.com]",
  "passwordHash": "...",
  "providerUserInfo": [
    {
      "providerId": "password",
      "federatedId": "[user@example.com]"
    }
  ],
  "idToken": "[NEW_ID_TOKEN]",
  "refreshToken": "[NEW_REFRESH_TOKEN]",
  "expiresIn": "3600"
}


*/

export const resetPassword = (token, currentPassword, password, email) =>{
    return dispatch =>{
        dispatch(authStart());
        
        const APIKEY = process.env.REACT_APP_KEY;

        const authData = {
            email: email,
            password: currentPassword,
            returnSecureToken: true
        }
        axios.post('https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=' + APIKEY, authData)
        .then(response =>{
            const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000);
            const loginDate = new Date(new Date().getTime());
            localStorage.setItem('token', response.data.idToken);
            localStorage.setItem('loginDate', loginDate);
            localStorage.setItem('expirationDate', expirationDate);
            localStorage.setItem('refreshToken', response.data.refreshToken);

            const data = {
                idToken: response.data.idToken,
                password: password,
                returnSecureToken: true
            }

            axios.post('https://identitytoolkit.googleapis.com/v1/accounts:update?key=' + APIKEY, data)
            .then(response =>{
                ////console.log(response);
                const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000);
                const loginDate = new Date(new Date().getTime());
                localStorage.setItem('token', response.data.idToken);
                localStorage.setItem('loginDate', loginDate);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('refreshToken', response.data.refreshToken);
                dispatch(authSuccess(response.data.idToken, response.data.localId));
                dispatch(checkAuthTimeout(response.data.expiresIn));
                //dispatch(loadUserObject(response.data.idToken, response.data.localId, email));
                dispatch(resetPasswordSuccess())
            })
            .catch(err =>{
                ////console.log('error message:');
                ////console.log(err.response.data.error.message);
                //dispatch(authFail(err.response.data.error.message));
            })
        
        
        })
        .catch(err =>{
            ////console.log('error message:', err.response);
            //console.log(err.response.data.error.message);
            dispatch(authResetFail(err.response.data.error.message));
        })
    }
}

export const auth = (email, password) =>{
    ////console.log('calling auth in auth.js');
    return dispatch =>{
        dispatch(authStart());
        const authData = {
            email: email,
            password: password,
            returnSecureToken: true
        }
        const APIKEY = process.env.REACT_APP_KEY;
        axios.post('https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=' + APIKEY, authData)
        .then(response =>{
            ////console.log(response);
            const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000);
            const loginDate = new Date(new Date().getTime());
            localStorage.setItem('token', response.data.idToken);
            localStorage.setItem('loginDate', loginDate);
            localStorage.setItem('expirationDate', expirationDate);
            localStorage.setItem('refreshToken', response.data.refreshToken);
            dispatch(authSuccess(response.data.idToken, response.data.localId));
            dispatch(checkAuthTimeout(response.data.expiresIn));
            dispatch(loadUserObject(response.data.idToken, response.data.localId, email))
        })
        .catch(err =>{
            ////console.log('error message:');
            ////console.log(err.response.data.error.message);
            dispatch(authFail(err.response.data.error.message));
        })
    };
}

/*
register(
                email,
                password,
                userObj,
                orgName,
                createOrg
                )
*/


//updateUser(token, data, orgName, createOrg)
export const authUpdateUser = (token, data, orgName, createOrg) =>{
    return dispatch =>{
        //dispatch(updateUserStart(data.id));
        const id = data.id;
        delete data['id'];
        if(createOrg){
            let orgData = {
                name: orgName,
                creator: data.userId
            }
            axios.post('organizations.json?auth=' + token, orgData)
            .then(response =>{
                //console.log("id="+response.data.name);
                data.organizationId = response.data.name;
                axios.patch('users/'+id+'.json?auth=' + token, data)
                .then(response =>{
                    ////console.log("id="+response.data.name);
                    //dispatch(updateUserSuccess(id, data));
                    dispatch(loadUserObject(token,  data.userId))
                })
                .catch(error =>{
                    ////console.log(error.response);
                    //dispatch(updateUserFail(error.response.data.error));
                });
            })
            .catch(error =>{
                //console.log(error.response);
            });
        }
        else{
            axios.patch('users/'+id+'.json?auth=' + token, data)
                .then(response =>{
                    ////console.log("id="+response.data.name);
                    dispatch(loadUserObject(token,  data.userId))
                })
                .catch(error =>{
                    ////console.log(error.response);
                    //dispatch(updateUserFail(error.response.data.error));
                });
        }
    }
}


export const register = (email, password, userObj, orgName, createOrg, teamType) =>{
    return dispatch =>{
        dispatch(regStart());
        const authData = {
            email: email,
            password: password,
            returnSecureToken: true
        }
        const APIKEY = process.env.REACT_APP_KEY;
        let token = '';
        let userId = '';
        axios.post('https://www.googleapis.com/identitytoolkit/v3/relyingparty/signupNewUser?key=' + APIKEY, authData)
        .then(response =>{
            ////console.log('REGISTER RESPONSE');
            ////console.log(response);
            //const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000);
            // localStorage.setItem('token', response.data.idToken);
            // localStorage.setItem('expirationDate', expirationDate);
            token = response.data.idToken;
            const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000);
            const loginDate = new Date(new Date().getTime());
            localStorage.setItem('token', response.data.idToken);
            localStorage.setItem('loginDate', loginDate);
            localStorage.setItem('expirationDate', expirationDate);
            localStorage.setItem('refreshToken', response.data.refreshToken);
            dispatch(regSuccess(response.data.idToken, response.data.localId));
            //dispatch(loadUserObject(response.data.idToken, response.data.localId))
            dispatch(checkAuthTimeout(response.data.expiresIn));
            userId = response.data.localId;
            userObj.userId = userId;
            if(createOrg){
                let orgData = {
                    name: orgName,
                    teamType: teamType
                }
                axios.post('organizations.json?auth=' + token, orgData)
                .then(response =>{
                    //console.log("id="+response.data.name);
                    userObj.organizationId = response.data.name;
                    axios.post('users.json?auth=' + token, userObj)
                    .then(response =>{
                        dispatch(loadUserObject(token,  userId))
                    })
                    .catch(error =>{
                        //console.log(error.response);
                    });
                })
                .catch(error =>{
                    //console.log(error.response);
                    //dispatch(createOrgFail(error.response.data.error));
                });
            }
            else{
                axios.post('users.json?auth=' + token, userObj)
                .then(response =>{
                    dispatch(loadUserObject(token, userId))
                })
                .catch(error =>{
                    //console.log(error.response);
                });
            }
        })
        .catch(err =>{
            ////console.log(err.response.data.error.message);
            dispatch(regFail(err.response.data.error.message));
        })
    };
}



export const regStart = () =>{
    return{
        type:actionTypes.REG_START
    };
}


export const regFail = (error) =>{
    return{
        type:actionTypes.REG_FAIL,
        error: error
    };
}

export const setAuthRedirectPath = (path) =>{
    return{
        type:actionTypes.SET_AUTH_REDIRECT,
        path: path
    };
}


export const authCheckState = () =>{
    return dispatch =>{
        dispatch(authStart());
        const token = localStorage.getItem("token");
        if(!token){
            dispatch(logout());
        }
        else{
            const expirationDate = new Date(localStorage.getItem("expirationDate"));
            const payload = {
                idToken: token
            }
            if(expirationDate > new Date()){
                const APIKEY = process.env.REACT_APP_KEY;
                //console.log(APIKEY);
                axios.post('https://www.googleapis.com/identitytoolkit/v3/relyingparty/getAccountInfo?key=' + APIKEY, payload)
                .then(response =>{

                    if(response.data.users.length === 1){
                        ////console.log('token ' + token);
                        ////console.log(response);
                        dispatch(authSuccess(token, response.data.users[0].localId));
                        dispatch(loadUserObject(token, response.data.users[0].localId))
                        ////console.log(expirationDate.getTime() / 1000 - new Date().getTime() / 1000);
                        dispatch(checkAuthTimeout(expirationDate.getTime() / 1000 - new Date().getTime() / 1000));
                    }
                //
                })
                .catch(err=>{
                    ////console.log(err);
                })
                //
            }
            else{
                dispatch(logout());
            }
        }

    }

}