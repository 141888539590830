import React from 'react';
import {connect} from 'react-redux';
import classes from './GameState.module.css';
import InningScoreTable from './InningScoreTable/InningScoreTable';
import Button from '../../components/UI/Button/Button';
import PlayerStats from './PlayerStats/PlayerStats';
import MobileGameState from './MobileGameState/MobileGameState';

const GameState = (props) =>{

    function canEdit(){
        if(props.auser && (props.auser.role === 'SysAdmin' ||
        props.auser.role === 'OrgAdmin' ||
        props.auser.role === 'Admin' ||
        props.auser.role === 'OrgAdminTraining' ||
        props.auser.role === 'AdminTraining')){
            return true;
        }
        return false;
    }
    /*let renderBaseRunners = null;
    
    if(props.baseRunners){
        renderBaseRunners = props.baseRunners.map((p,i) => {
            return(
                <span key={i}> Runner: {p.batter.firstName} {p.batter.lastName}, </span>
                
            )
        });
    }*/
    /*let pitcherName = null;
    let batterName = null;
    if(props.pitcher){
        pitcherName = "#" + props.pitcher.number+" " +props.pitcher.firstName + " "+ props.pitcher.lastName;
    }
    if(props.batter){
        batterName = "#" + props.batter.number+" " +props.batter.firstName + " " + props.batter.lastName;
    }*/
    let topBottom = <i className={classes.ArrowUp}></i>
    if(props.topBottom === "BOTTOM"){
        topBottom = <i className={classes.ArrowDown}></i>
    }

    let deleteGameButtonJSX = null;
    let quitButtonJSX = null;
    let playersAndInningInfoJSX = null;
    let endMessage = 'FORCE END GAME';
    deleteGameButtonJSX = <span className={classes.DeleteButton}>
                                <Button clicked={props.handleDelete} btnSize="Small" btnType="Red">DELETE GAME</Button>
                              </span>
    if(props.showQuitButton){
        

        if(canEdit()){
            
            quitButtonJSX = <span className={classes.QuitButton}>
            <Button clicked={props.handleShowForceQuit} btnSize="Small" btnType="Red">{endMessage}</Button>
            </span>
        }

        if(canEdit()){

        playersAndInningInfoJSX = 
        
            <>
            <div className={classes.SpanContainer}>
                <span className={classes.InningSpan}>{props.inning} {topBottom}</span> <span className={classes.BallStrikeSpan} >B: {props.balls} S: {props.strikes}</span> <span className={classes.OutSpan}>O: {props.outs}</span>
            </div>
            <PlayerStats    currentPitchArray={props.currentPitchArray} 
                            playerType={'pitcher'} 
                            player={props.pitcher} 
                            onClick={props.onChangePitcher}
                            showPitcherNotesHandler = {props.showPitcherNotesHandler}/>
            
                        
            <PlayerStats    currentPitchArray={props.currentPitchArray} 
                            playerType={'batter'} 
                            player={props.batter} 
                            onClick={props.onChangeBatter}
                            showBatterNotesHandler = {props.showBatterNotesHandler}
                            />


            </>
        }
        else{
            playersAndInningInfoJSX = 
        
                <>
                <div className={classes.SpanContainer}>
                    <span className={classes.InningSpan}>{props.inning} {topBottom}</span> <span className={classes.BallStrikeSpan} >B: {props.balls} S: {props.strikes}</span> <span className={classes.OutSpan}>O: {props.outs}</span>
                </div>
                <PlayerStats    currentPitchArray={props.currentPitchArray} 
                                playerType={'pitcher'} 
                                player={props.pitcher} 
                                onClick={null}
                                showPitcherNotesHandler = {null}/>
                
                            
                <PlayerStats    currentPitchArray={props.currentPitchArray} 
                                playerType={'batter'} 
                                player={props.batter} 
                                onClick={null}
                                showBatterNotesHandler = {null}
                                />


                </>
        }


    }


    
    return (
        <>
        <MobileGameState gsprops={props}/>
        <div className={classes.GameState}>
            <InningScoreTable inning={props.inning} topBottom={props.topBottom} />
            {/*<p>Pitcher: <span onClick={props.onChangePitcher} className={classes.SelectPlayer}>{pitcherName}</span> Batter: <span onClick={props.onChangeBatter} className={classes.SelectPlayer}>{batterName} </span></p>*/}
            
            {playersAndInningInfoJSX}
            {quitButtonJSX}
            {deleteGameButtonJSX}
                
            
            
           
            {/*<p>Batting Team: {props.battingTeam}</p>
            <p>Pitching Team: {props.pitchingTeam}</p>
            <p>Total Innings: {props.totalInnings}</p>
            <p>Current Inning: {props.inning}</p>
            <p>Top/Bottom: {props.topBottom}</p>
            <p>Outs: {props.outs}</p>
            <p>Pitch Number: {props.pitchNumber}</p>
            <p>Home Score: {props.homeScore}</p>
    <p>Away Score: {props.awayScore}</p>*/}
            {/*<BaseRunners 
               runners={props.baseRunners} 
               handleScore={props.handleScore}
               handleOut={props.handleOut}
            handleLeftOnBase={props.handleLeftOnBase}/>*/}
        </div>
        </>
    )
}



const mapStateToProps = state =>{
    return{
        isAuth: state.auth.token !== null,
        auser: state.auth.user
    };
}

export default connect(mapStateToProps, null) (GameState);

