import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';


const initialState = {
    loading: false,
    error: false,
    message: null,
    id: null,
    orgs: []
};




const createOrgStart = (state, action) =>{
    return updateObject(state, {loading: true, error:false, message:null});
}

const createOrgSuccess = (state, action) =>{
    const newOrg = updateObject(action.data, {id: action.id});


    return updateObject(state, {
        loading: false, 
        error:false, 
        id: newOrg.id,
        message:"create organization success " + action.id,
        orgs: state.orgs.concat(newOrg),
    });
}

const createOrgFail = (state, action) =>{
    return updateObject(state, {loading: false, error:true, message:action.error});
}

const updateOrgStart = (state, action) =>{
    return updateObject( state, {loading:true, error:false, message:null});
}

const updateOrgFail = (state, action) =>{
    return updateObject( state, {loading:false, error:true, message:action.error});
}

const updateOrgSuccess = (state, action) =>{
    return updateObject (state, {
        loading:false, 
        error:false, 
        message:"update org success " + action.id,
        id: action.id
    });
}


const getOrgsStart = (state, action) =>{
    return updateObject(state, {loading: true, error:false, message:null});
}

const getOrgsSuccess = (state, action) =>{
    return updateObject(state, {
        loading: false, 
        error:false, 
        message: null,
        orgs: action.data,
    });
}
const getOrgsFail = (state, action) =>{
    return updateObject(state, {loading: false, error:true, message:action.error});
}

//
const getOrgStart = (state, action) =>{
    return updateObject(state, {loading: true, error:false, message:null});
}

const getOrgSuccess = (state, action) =>{
    //////console.log('calling getGameSuccess ' + action.data);
    return updateObject(state, {
        loading: false, 
        error:false, 
        message: null,
        games: action.data,
    });
}
const getOrgFail = (state, action) =>{
    return updateObject(state, {loading: false, error:true, message:action.error});
}


const reducer = (state = initialState, action) =>{

    switch(action.type){
        case actionTypes.CREATE_ORG_START:{
            return createOrgStart(state, action);
        }
        case actionTypes.CREATE_ORG_SUCCESS:{
            return createOrgSuccess(state, action);
        }
        case actionTypes.CREATE_ORG_FAIL:{
            return createOrgFail(state, action);
        }
        case actionTypes.UPDATE_ORG_START:{
            return updateOrgStart(state, action);
        }
        case actionTypes.UPDATE_ORG_SUCCESS:{
            return updateOrgSuccess(state, action);
        }
        case actionTypes.UPDATE_ORG_FAIL:{
            return updateOrgFail(state, action);
        }
        case actionTypes.GET_ORGS_START:{
            return getOrgsStart(state, action);
        }
        case actionTypes.GET_ORGS_FAIL:{
            return getOrgsFail(state, action);
        }
        case actionTypes.GET_ORGS_SUCCESS:{
            return getOrgsSuccess(state, action);
        }
        case actionTypes.GET_ORG_START:{
            return getOrgStart(state, action);
        }
        case actionTypes.GET_ORG_FAIL:{
            return getOrgFail(state, action);
        }
        case actionTypes.GET_ORG_SUCCESS:{
            return getOrgSuccess(state, action);
        }

        default:
            return state;
    }
}

export default reducer;