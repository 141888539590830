import * as actionTypes from './actionTypes';
import axios from '../../axios';




export const gameStart = () =>{
    return{
        type:actionTypes.GAME_START
    };
}


export const createGameStart = () =>{
    return{
        type:actionTypes.CREATE_GAME_START
    };
}

export const createGameSuccess = (id, data) =>{
    return{
        type:actionTypes.CREATE_GAME_SUCCESS,
        id: id,
        data: data
    };
}

export const deleteGameSuccess = (id) =>{
    return{
        type:actionTypes.DELETE_GAME_SUCCESS,
        id: id
    }
}

export const createGameFail = (error) =>{
    return{
        type:actionTypes.CREATE_GAME_FAIL,
        error: error
    };
}

export const createGame = (token, data) =>{
    return dispatch =>{
        dispatch(createGameStart());
        axios.post('/games.json?auth=' + token, data)
            .then(response =>{
                ////console.log("id="+response.data.name);
                dispatch(createGameSuccess(response.data.name, data));
            })
            .catch(error =>{
                //console.log(error);
                dispatch(createGameFail(error));
            });
    }
}

export const deleteGame = (token, id) =>{
    return dispatch =>{
        //console.log('delete game state ' + id);
        //dispatch(createGameStart());
        axios.delete('games/'+id+'.json?auth=' + token)
            .then(response =>{
                //console.log("id=",response);
                dispatch(deleteGameSuccess(id));
            })
            .catch(error =>{
                //console.log('error', error);
                //dispatch(createGameFail(error.response.data.error));
            });
    }
}




export const getGamesStart = () =>{
    return{
        type:actionTypes.GET_GAMES_START
    };
}

export const getGamesSuccess = (data) =>{
    return{
        type:actionTypes.GET_GAMES_SUCCESS,
        data: data
    };
}

export const getGamesFail = (error) =>{
    return{
        type:actionTypes.GET_GAMES_FAIL,
        error: error
    };
}

export const getGames = (token, userId) =>{
    return dispatch =>{
        dispatch(getGamesStart());

        const queryParams = '?auth=' + token + '&orderBy="userId"&equalTo="' + userId + '"';
        //axios.get('/games.json?auth=' + token)
        axios.get('/games.json' + queryParams)
        .then(response => {
            //console.log('getGames action:', response.data);
            const games = [];
            for(let key in response.data){
                games.push({
                    ...response.data[key],
                    id: key
                });
            }
            for(let i=0; i < games.length; ++i){
                let t = games[i];
                t.pitchType = typeof t.pitchType === 'undefined' ? 'Softball' : t.pitchType;
            }
            dispatch(getGamesSuccess(games));
        })
        .catch(error =>{
            dispatch(getGamesFail());
        });
    }
}

export const getOrgGames = (token, organizationId) =>{
    return dispatch =>{
        dispatch(getGamesStart());

        const queryParams = '?auth=' + token + '&orderBy="organizationId"&equalTo="' + organizationId + '"';
        //axios.get('/games.json?auth=' + token)
        axios.get('/games.json' + queryParams)
        .then(response => {
            //console.log('getGames action:', response.data);
            const games = [];
            for(let key in response.data){
                games.push({
                    ...response.data[key],
                    id: key
                });
            }
            for(let i=0; i < games.length; ++i){
                let t = games[i];
                t.pitchType = typeof t.pitchType === 'undefined' ? 'Softball' : t.pitchType;
            }
            dispatch(getGamesSuccess(games));
        })
        .catch(error =>{
            dispatch(getGamesFail());
        });
    }
}


//



export const getGameStart = () =>{
    return{
        type:actionTypes.GET_GAME_START
    };
}

export const getGameSuccess = (data) =>{
    return{
        type:actionTypes.GET_GAME_SUCCESS,
        data: data
    };
}

export const getGameFail = (error) =>{
    return{
        type:actionTypes.GET_GAME_FAIL,
        error: error
    };
}

export const getGame = (token, id) =>{
    return dispatch =>{
        dispatch(getGameStart());
        axios.get('/games/'+id +'.json?auth=' + token)
        .then(response => {
            ////console.log('getGame action:', response.data);
            let games = [];
            if(response.data !== null){
                games.push({
                    ...response.data,
                    userID: response.data.userId,
                    id: id
                });
                dispatch(getGameSuccess(games));
            }
            else{
                dispatch(getGameFail('no results found for id ' + id));
            }
            
        })
        .catch(error =>{
            dispatch(getGameFail());
        });
    }
}

export const loadGameStateStart = () =>{
    return{
        type:actionTypes.LOAD_GAME_GAME_STATE_START
    };
}

export const loadGameStateFail = (error) =>{
    return{
        type:actionTypes.LOAD_GAME_GAME_STATE_FAIL,
        error
    };
}

export const loadGameStateSuccess = (data) =>{
    return{
        type:actionTypes.LOAD_GAME_GAME_STATE_SUCCESS,
        data: data
    };
}

export const loadGameGameState = (token, gameId) =>{
    return dispatch =>{
        dispatch(loadGameStateStart());
        const queryParams = '?auth=' + token + '&orderBy="gameID"&equalTo="'+gameId+'"';
        axios.get('/gamestate.json' + queryParams)
        .then(response => {
            //console.log('gamestate response', response);
            let gamestate = null;
            for(let key in response.data){
                gamestate = { ...response.data[key], atBatID: key}
            }
            //console.log('gamestate after spread', gamestate);
            if(gamestate){
                //console.log('game state not null, dispatching loadGameStateSuccess');
                dispatch(loadGameStateSuccess(gamestate));
            }
            else{

                dispatch(loadGameStateFail());
            }
            
        })
        .catch(error =>{
            //dispatch(getTeamPlayersFail());
        });
    }
}

//////



export const loadGameStatesStart = () =>{
    return{
        type:actionTypes.LOAD_GAME_STATES_START
    };
}

export const loadGameStatesFail = (error) =>{
    return{
        type:actionTypes.LOAD_GAME_STATES_FAIL,
        error
    };
}

export const loadGameStatesSuccess = (data) =>{
    return{
        type:actionTypes.LOAD_GAME_STATES_SUCCESS,
        data: data
    };
}

export const loadGameStates = (token, orgId) =>{
    return dispatch =>{
        dispatch(loadGameStatesStart());
        //console.log('loadGameStates', orgId);
        //const queryParams = '?auth=' + token + '&orderBy="userID"&equalTo="'+userID+'"';
        const queryParams = '?auth=' + token ;//+ '&orderBy="organizationId"&equalTo="'+orgId+'"';
        axios.get('gamestate.json' + queryParams)
        .then(response => {
            let gamestates = [];
            //console.log('organization id ' + orgId);
            //console.log('returend gamestate data ', response.data)
            for(let key in response.data){
                if(typeof response.data[key].game !== 'undefined' &&  typeof response.data[key].game.organizationId !== 'undefined' && response.data[key].game.organizationId === orgId){
                    gamestates.push( {...response.data[key], atBatID: key, userID: response.data[key].game.userId});
                }
            }
            //console.log('gamestates', gamestates)
            if(gamestates){
                dispatch(loadGameStatesSuccess(gamestates));
            }
            else{

                dispatch(loadGameStatesFail());
            }
            
        })
        .catch(error =>{
            //console.log(error)
            //dispatch(getTeamPlayersFail());
            dispatch(loadGameStatesFail());
        });
    }
}



