import React from 'react';
import styles from './StrikeZoneArea.module.css';

const StrikeZoneArea = (props) =>{

    
    /*
    style={
            top: '{props.top}',
            left: {props.left},
            width: {props.width},
            height: {props.height},
            backgroundColor: {props.color}
        }
    */
    return(
        <div className={styles.StrikeZoneArea} style={
            {top: props.top,
            left: props.left,
            width: props.width,
            height: props.height,
            backgroundColor: props.color,
            borderTop: props.borderTop,
            borderRight: props.borderRight,
            borderBottom: props.borderBottom,
            borderLeft: props.borderLeft}
           
            
        }></div>
    )
}

export default StrikeZoneArea;