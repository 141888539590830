import React from 'react';
import Aux from '../../../hoc/Aux';

import styles from './PitchLocation.module.css';


const PitchLocation = (props) => {
    

    const pitchLocationBlock = props.pitch !== null ?
    (
        <div className={styles.PitchLocation}>
        {/*Coordinates (x:{props.pitch !== null ? props.pitch.x: 0}, 
        y:{props.pitch !== null ? props.pitch.y: 0})*/}
        Location: {props.pitch !== null ? props.pitch.LocationName() : ''}
        </div>
    ) : (
        <div className={styles.PitchLocation}>
            Location: 
        </div>
    );
    

    return(
        <Aux>
            {pitchLocationBlock}
        </Aux>
    );
    
};


export default PitchLocation;