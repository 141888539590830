import React from 'react';
import styles from './FoulResultButton.module.css';

const FoulResultButton = (props) => {
    return (
        <div className={styles.FoulResultButton}
        onClick={() => props.click(props.type)}>
        {props.type}</div>
    )
}

export default FoulResultButton;