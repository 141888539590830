import React from 'react';
import styles from './PitchTypeButtons.module.css';
import PitchButton from './PitchButton/PitchButton';





const PitchTypeButtons = (props) => {
    let options = [
        'FB',
        'XCHANGE',
        'CURVE',
        'DROPCV',
        'DROP',
        'RISE',
        'SCREW'
    ];

    let optionAbv = [
        'FB',
        'XCH',
        'CRV',
        'DCV',
        'DRP',
        'RIS',
        'SCRW'
    ];

    

    if(props.pitchType === 'Baseball'){
        
        options = [
            'FB-4S',
            'FB-2S',
            'XCHANGE',
            'CURVE',
            'SLIDER',
            'CUTTER',
            'OTHER'
        ];

        optionAbv = [
            'FB-4S',
            'FB-2S',
            'XCHANGE',
            'CURVE',
            'SLIDER',
            'CUTTER',
            'OTHER'
        ];
    }
    let pitchButtons = [];
    for(let i=0;i<options.length; ++i){
        pitchButtons.push( <PitchButton pitchType={props.pitchType} key={i} type={options[i]} abv={optionAbv[i]} click={props.click(options[i])}/>)
    }
    
//    const pitchButtons = options.map((o,i) => {
//         return (
//             <PitchButton key={i} type={o} click={props.click(o)}/>
//         )
//     });

    return(
        <div className={styles.PitchTypeButtons}>
        {pitchButtons}
        </div>

    )

    /*return(
        <div className={styles.PitchTypeButtons}>
            <PitchButton type={'FB'}        click={props.click('FB')}/>
            <PitchButton type={'XCHANGE'}   click={props.click('XCHANGE')} />
            <PitchButton type={'CURVE'}     click={props.click('CURVE')}/>
            <PitchButton type={'DROPCV'}    click={props.click('DROPCV')}/>
            <PitchButton type={'DROP'}      click={props.click('DROP')}/>
            <PitchButton type={'RISE'}      click={props.click('RISE')}/>
            <PitchButton type={'SCREW'}     click={props.click('SCREW')}/>
        </div>
    )*/
}

export default PitchTypeButtons;