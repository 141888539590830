import React from 'react';
import {connect} from 'react-redux';
import classes from './MobileGameState.module.css';

const MobileGameState = (props) =>{

    
//className={[classes.Button, classes[props.btnType], classes[props.btnSize]].join(' ')}

    let ball1Classes = [classes.Ball].join(' ');
    let ball2Classes = [classes.Ball].join(' ');
    let ball3Classes = [classes.Ball].join(' ');
    if(props.gsprops.balls > 2){
        ball1Classes = [classes.Ball, classes.Active].join(' ');
        ball2Classes = [classes.Ball, classes.Active].join(' ');
        ball3Classes = [classes.Ball, classes.Active].join(' ');
    }
    else if(props.gsprops.balls > 1){
        ball1Classes = [classes.Ball, classes.Active].join(' ');
        ball2Classes = [classes.Ball, classes.Active].join(' ');
    }
    else if(props.gsprops.balls > 0){
        ball1Classes = [classes.Ball, classes.Active].join(' ');
    }

    let strikes1Classes = [classes.Ball].join(' ');
    let strikes2Classes = [classes.Ball].join(' ');
    if(props.gsprops.strikes > 1){
        strikes1Classes = [classes.Ball, classes.Active].join(' ');
        strikes2Classes = [classes.Ball, classes.Active].join(' ');
    }
    else if(props.gsprops.strikes > 0){
        strikes1Classes = [classes.Ball, classes.Active].join(' ');
    }
   

    //let strikes1Classes = [classes.Ball, classes.Active].join(' ');
    //let strikes2Classes = [classes.Ball, classes.Active].join(' ');

    //let outs1Classes = [classes.Ball, classes.Active].join(' ');
    //let outs2Classes = [classes.Ball, classes.Active].join(' ');
    let outs1Classes = [classes.Ball].join(' ');
    let outs2Classes = [classes.Ball].join(' ');
    if(props.outs > 1){
        outs1Classes = [classes.Ball,  classes.Active].join(' ');
        outs2Classes = [classes.Ball,  classes.Active].join(' ');
    }
    else if(props.outs > 0){
        outs1Classes = [classes.Ball,  classes.Active].join(' ');
    }
    
    let output = null;

    
    if(props.homeTeam){

        let inningEnd = null;
        let actualInning =  Math.floor((props.currentInning / 2.0) + 1);

        if(actualInning  === 1){
            inningEnd = 'st';
        }
        else if (actualInning ===2){
            inningEnd = 'nd';
        }
        else if (actualInning ===3){
            inningEnd = 'rd';
        }
        else if (actualInning  >= 4){
            inningEnd = 'th';
        }
        

        let topBottom = props.currentInning % 2 === 0 ? "Top" : "Bottom";

        output = <div className={classes.MobileGameState}>
                    <div className={classes.GameHeaderContainer}>
                        <div className={classes.AwayTeam}>
                            {props.homeTeam.name.substring(0,4).toUpperCase()}
                        </div>
                        <div className={classes.Score}>
                            {props.homeScore}
                        </div>


                        <div className={classes.InningOuts}>
                            <div className={classes.Inning}>
                                {topBottom} of {actualInning}{inningEnd}
                            </div>
                            <div className={classes.Outs}>
                                {props.outs} outs
                            </div>
                        </div>


                        <div className={classes.Score}>
                        {props.awayScore}
                        </div>
                        <div className={classes.HomeTeam}>
                        {props.awayTeam.name.substring(0,4).toUpperCase()}
                        </div>
                    </div>
                    <hr/>
                    <div className={classes.GameStatsContainer}>
                        <div className={classes.Balls}>
                            <span>BALLS</span>
                            <div className={ball1Classes}>
                            </div>
                            <div className={ball2Classes}>
                            </div>
                            <div className={ball3Classes}>
                            </div>
                            
                        </div>

                        <div className={classes.Balls}>
                            <span>STRIKES</span>
                            <div className={strikes1Classes}>
                            </div>
                            <div className={strikes2Classes}>
                            </div>
                        </div>

                        <div className={classes.Balls}>
                            <span>OUTS</span>
                            <div className={outs1Classes}>
                            </div>
                            <div className={outs2Classes}>
                            </div>
                        </div>
                    </div>
                    <div className={classes.CurrentPlayersHandlers}>
                        <div className={classes.CurrentPlayer}>
                            <span onClick={props.gsprops.onChangePitcher}>
                                P: #{props.currentPitcher.number} {props.currentPitcher.firstName} {props.currentPitcher.lastName}
                            
                            </span>
                        </div>
                        <div className={classes.CurrentPlayer}>
                            <span onClick={props.gsprops.onChangeBatter}>
                            B: #{props.currentHitter.number} {props.currentHitter.firstName} {props.currentHitter.lastName}
                            
                            </span>
                        </div>
                    </div>
                </div>
    }


    return(
        <>
        {output}
        </>
    );
    
}


const mapStateToProps = state =>{
    return{
        /*gameStart: state.game.gameStart,
        isAuth: state.auth.token !== null,
        loading: state.auth.loading,*/
        gameLoading: state.game.loading,
        gameError: state.game.error,
        gameMessage: state.game.message,
        //games: state.game.games,
        game: state.atBat.game,
        homeTeam: state.atBat.homeTeam,
        awayTeam: state.atBat.awayTeam,
        homeTeamPlayers: state.atBat.homeTeamPlayers,
        awayTeamPlayers: state.atBat.awayTeamPlayers,
        currentInning: state.atBat.currentInning,
        currentPitcher: state.atBat.currentPitcher,
        currentHitter: state.atBat.currentHitter,
        battingRight: state.atBat.battingRight,
        atBatState: state.atBat,
        gameStateLoading: state.atBat.loading,
        token: state.auth.token,
        pitchArray: state.atBat.pitchArray,
        outs: state.atBat.outs,
        pitchNumber: state.atBat.pitchNumber,
        baseRunners: state.atBat.baseRunners,
        homeScore: state.atBat.homeScore,
        awayScore: state.atBat.awayScore,
        gameOver: state.atBat.gameOver,
        homeTeamLineup: state.atBat.homeTeamLineup,
        homeTeamCurrentPitcher: state.atBat.homeTeamCurrentPitcher,
        awayTeamLineup: state.atBat.awayTeamLineup,
        awayTeamCurrentPitcher: state.atBat.awayTeamCurrentPitcher,
        totalInnings: state.atBat.totalInnings,
        homeTeamCurrentBatterIndex: state.atBat.homeTeamCurrentBatterIndex,
        awayTeamCurrentBatterIndex: state.atBat.awayTeamCurrentBatterIndex,
        inningScores: state.atBat.inningScores,
        currentTopInningScore: state.atBat.currentTopInningScore,
        currentBottomInningScore: state.atBat.currentBottomInningScore,
        forceQuit: state.atBat.forceQuit,
        forceQuitMessage: state.atBat.forceQuitNotes,
        homeSubstitutions: state.atBat.homeSubstitutions,
        awaySubstitutions: state.atBat.awaySubstitutions,
        pitcherView: state.atBat.pitcherView
    }
}

export default connect(mapStateToProps) (MobileGameState);
