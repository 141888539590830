import React from 'react';
import {connect} from 'react-redux';
import StrikeZoneArea from './StrikeZoneArea/StrikeZoneArea';
import BatterIcon from './BatterIcon/BatterIcon';
import Aux from '../../../hoc/Aux';

const StrikeZone  = (props) => {

        let battingRight = null;
        let battingLeft = null;

        if(props.pitcherView){
                if(typeof props.showPitcherBatters !== 'undefined'){
                        if(props.showPitcherBatters ===0 || props.showPitcherBatters === 1){
                                battingRight  = <BatterIcon 
                                        pitcherView={props.pitcherView}
                                        top={0}
                                        left={221} //0.175
                                        width= {53} //0.325
                                        //left={-145} //0.175
                                        //width={139}
                                        
                                        
                                        borderTop={'5px solid red'}
                                        borderRight={'5px solid red'}
                                        borderBottom={'5px solid red'}
                                        borderLeft = {'5px solid red'}
                                        color={"red"}
                                        battingSide="right"
                                        />  ;
                        }
                        if(props.showPitcherBatters === 0 || props.showPitcherBatters === 2){
                                battingLeft = <BatterIcon 
                                        pitcherView={props.pitcherView}
                                        top={0}
                                        left={-87} //0.175
                                        width={40}
                                        //left={props.width + 5} //0.175
                                        //width= {139} //0.325
                                        
                                        
                                        height={props.height * 0.5}//0.325
                                        borderTop={'5px solid blue'}
                                        borderRight={'5px solid blue'}
                                        borderBottom={'5px solid blue'}
                                        borderLeft = {'5px solid blue'}
                                        color={"blue"}
                                        battingSide="left"
                                        />  ;
                        }
                }
                else{
                        if(props.battingRight){
                                battingRight  = <BatterIcon 
                                        pitcherView={props.pitcherView}
                                        top={0}
                                        left={221} //0.175
                                        width= {53} //0.325
                                        //left={-145} //0.175
                                        //width={139}
                                        
                                        
                                        borderTop={'5px solid red'}
                                        borderRight={'5px solid red'}
                                        borderBottom={'5px solid red'}
                                        borderLeft = {'5px solid red'}
                                        color={"red"}
                                        battingSide="right"
                                        />  ;
                        }
                        else{
                                battingLeft = <BatterIcon 
                                        pitcherView={props.pitcherView}
                                        top={0}
                                        left={-87} //0.175
                                        width={40}
                                        //left={props.width + 5} //0.175
                                        //width= {139} //0.325
                                        
                                        
                                        height={props.height * 0.5}//0.325
                                        borderTop={'5px solid blue'}
                                        borderRight={'5px solid blue'}
                                        borderBottom={'5px solid blue'}
                                        borderLeft = {'5px solid blue'}
                                        color={"blue"}
                                        battingSide="left"
                                        />  ;
                        }
                
                }
        }
        else{
                if(typeof props.showPitcherBatters !== 'undefined'){
                        if(props.showPitcherBatters ===0 || props.showPitcherBatters === 1){
                                battingRight  = <BatterIcon 
                                        top={0}
                                        left={-57} //0.175
                                        width={100}
                                        
                                        
                                        borderTop={'5px solid red'}
                                        borderRight={'5px solid red'}
                                        borderBottom={'5px solid red'}
                                        borderLeft = {'5px solid red'}
                                        color={"red"}
                                        battingSide="right"
                                        />  ;
                        }
                        if(props.showPitcherBatters === 0 || props.showPitcherBatters === 2){
                                battingLeft = <BatterIcon 
                                        top={0}
                                        //left={-145} //0.175
                                        //width={139}
                                        left={187} //0.175
                                        width= {139} //0.325
                                        
                                        
                                        height={props.height * 0.5}//0.325
                                        borderTop={'5px solid blue'}
                                        borderRight={'5px solid blue'}
                                        borderBottom={'5px solid blue'}
                                        borderLeft = {'5px solid blue'}
                                        color={"blue"}
                                        battingSide="left"
                                        />  ;
                        }
                }
                else{
                        if(props.battingRight){
                                battingRight  = <BatterIcon 
                                        top={0}
                                        left={-57} //0.175
                                        width={100}
                                        
                                        
                                        borderTop={'5px solid red'}
                                        borderRight={'5px solid red'}
                                        borderBottom={'5px solid red'}
                                        borderLeft = {'5px solid red'}
                                        color={"red"}
                                        battingSide="right"
                                        />  ;
                        }
                        else{
                                battingLeft = <BatterIcon 
                                        top={0}
                                        //left={-145} //0.175
                                        //width={139}
                                        left={187} //0.175
                                        width= {139} //0.325
                                        
                                        
                                        height={props.height * 0.5}//0.325
                                        borderTop={'5px solid blue'}
                                        borderRight={'5px solid blue'}
                                        borderBottom={'5px solid blue'}
                                        borderLeft = {'5px solid blue'}
                                        color={"blue"}
                                        battingSide="left"
                                        />  ;
                        }
                }
        }


    return(
        <Aux>
        <StrikeZoneArea 
                top={props.height * 0.175}
                left={props.width * 0.175}
                width= {props.width * 0.325}
                height={props.height * 0.325}
                borderTop={null}
                borderRight={null}
                borderBottom={null}
                borderLeft = {null}
                color={"#ddd"}
        />

        <StrikeZoneArea 
                top={props.height * 0.175}
                left={props.width * 0.5} //0.175
                width= {props.width * 0.325} //0.325
                height={props.height * 0.325}//0.325
                borderTop={null}
                borderRight={null}
                borderBottom={null}
                borderLeft = {'none'}
                color={"#ddd"}
        />
        <StrikeZoneArea 
                top={props.height * 0.5}
                left={props.width * 0.175} //0.175
                width= {props.width * 0.325} //0.325
                height={props.height * 0.325}//0.325
                borderTop={'none'}
                borderRight={null}
                borderBottom={null}
                borderLeft = {null}
                color={"#ddd"}
        />

        <StrikeZoneArea 
                top={props.height * 0.5}
                left={props.width * 0.5} //0.175
                width= {props.width * 0.325} //0.325
                height={props.height * 0.325}//0.325
                borderTop={'none'}
                borderRight={null}
                borderBottom={null}
                borderLeft = {'none'}
                color={"#ddd"}
        />

        <StrikeZoneArea 
                top={props.height * 0.0}
                left={props.width * 0.0} //0.175
                width= {props.width * 0.175} //0.325
                height={props.height * 0.175}//0.325
                borderTop={'none'}
                borderRight={null}
                borderBottom={null}
                borderLeft = {'none'}
                color={null}
        />  
        <StrikeZoneArea 
                top={props.height * 0.0}
                left={props.width * 0.175} //0.175
                width= {props.width * 0.325} //0.325
                height={props.height * 0.175}//0.325
                borderTop={'none'}
                borderRight={null}
                borderBottom={null}
                borderLeft = {null}
                color={null}
        />  
        <StrikeZoneArea 
                top={props.height * 0.0}
                left={props.width * 0.5} //0.175
                width= {props.width * 0.325} //0.325
                height={props.height * 0.175}//0.325
                borderTop={'none'}
                borderRight={null}
                borderBottom={null}
                borderLeft = {'none'}
                color={null}
        />  
        <StrikeZoneArea 
                top={props.height * 0.0}
                left={props.width * 0.825} //0.175
                width= {props.width * 0.175} //0.325
                height={props.height * 0.175}//0.325
                borderTop={'none'}
                borderRight={'none'}
                borderBottom={null}
                borderLeft = {null}
                color={null}
        />  
        <StrikeZoneArea 
                top={props.height * 0.175}
                left={props.width * 0.0} //0.175
                width= {props.width * 0.175} //0.325
                height={props.height * 0.325}//0.325
                borderTop={null}
                borderRight={'none'}
                borderBottom={null}
                borderLeft = {'none'}
                color={null}
        />  
        <StrikeZoneArea 
                top={props.height * 0.175}
                left={props.width * 0.825} //0.175
                width= {props.width * 0.175} //0.325
                height={props.height * 0.325}//0.325
                borderTop={null}
                borderRight={'none'}
                borderBottom={null}
                borderLeft = {'none'}
                color={null}
        />  
        <StrikeZoneArea 
                top={props.height * 0.5}
                left={props.width * 0.0} //0.175
                width= {props.width * 0.175} //0.325
                height={props.height * 0.325}//0.325
                borderTop={'none'}
                borderRight={'none'}
                borderBottom={null}
                borderLeft = {'none'}
                color={null}
        />  
        <StrikeZoneArea 
                top={props.height * 0.5}
                left={props.width * 0.825} //0.175
                width= {props.width * 0.175} //0.325
                height={props.height * 0.325}//0.325
                borderTop={'none'}
                borderRight={'none'}
                borderBottom={null}
                borderLeft = {'none'}
                color={null}
        />  
        <StrikeZoneArea 
                top={props.height * 0.825}
                left={props.width * 0.0} //0.175
                width= {props.width * 0.175} //0.325
                height={props.height * 0.175}//0.325
                borderTop={'none'}
                borderRight={null}
                borderBottom={'none'}
                borderLeft = {'none'}
                color={null}
        />  
         <StrikeZoneArea 
                top={props.height * 0.825}
                left={props.width * 0.175} //0.175
                width= {props.width * 0.325} //0.325
                height={props.height * 0.175}//0.325
                borderTop={'none'}
                borderRight={null}
                borderBottom={'none'}
                borderLeft = {null}
                color={null}
        />  
        <StrikeZoneArea 
                top={props.height * 0.825}
                left={props.width * 0.5} //0.175
                width= {props.width * 0.325} //0.325
                height={props.height * 0.175}//0.325
                borderTop={'none'}
                borderRight={null}
                borderBottom={'none'}
                borderLeft = {'none'}
                color={null}
        />  
        <StrikeZoneArea 
                top={props.height * 0.825}
                left={props.width * 0.825} //0.175
                width= {props.width * 0.175} //0.325
                height={props.height * 0.175}//0.325
                borderTop={'none'}
                borderRight={'none'}
                borderBottom={'none'}
                borderLeft = {null}
                color={null}
        /> 
        <StrikeZoneArea 
                top={props.height * 0.0}
                left={props.width * 0.0} //0.175
                width= {props.width * 0.5} //0.325
                height={props.height * 0.5}//0.325
                borderTop={'none'}
                borderRight={'1px solid black'}
                borderBottom={'1px solid black'}
                borderLeft = {'none'}
                color={null}
        />  

        <StrikeZoneArea 
                top={props.height * 0.0}
                left={props.width * 0.5} //0.175
                width= {props.width * 0.5} //0.325
                height={props.height * 0.5}//0.325
                borderTop={'none'}
                borderRight={'none'}
                borderBottom={'1px solid black'}
                borderLeft = {'none'}
                color={null}
        />  
        <StrikeZoneArea 
                top={props.height * 0.5}
                left={props.width * 0.0} //0.175
                width= {props.width * 0.5} //0.325
                height={props.height * 0.5}//0.325
                borderTop={'none'}
                borderRight={'1px solid black'}
                borderBottom={'none'}
                borderLeft = {'none'}
                color={null}
        />  
        <StrikeZoneArea 
                top={props.height * 0.4}
                left={props.width * 0.4} //0.175
                width= {props.width * 0.2} //0.325
                height={props.height * 0.2}//0.325
                borderTop={'1px dotted red'}
                borderRight={'1px dotted red'}
                borderBottom={'1px dotted red'}
                borderLeft = {'1px dotted red'}
                color={'rgba(252, 169, 169, 0.3)'}
        />  
        <StrikeZoneArea 
                top={props.height * 0.0}
                left={props.width * 0.4} //0.175
                width= {props.width * 0.2} //0.325
                height={props.height * 0.4}//0.325
                borderTop={'none'}
                borderRight={'1px dotted red'}
                borderBottom={'none'}
                borderLeft = {'1px dotted red'}
                color={null}
        />  
        <StrikeZoneArea 
                top={props.height * 0.6}
                left={props.width * 0.4} //0.175
                width= {props.width * 0.2} //0.325
                height={props.height * 0.4}//0.325
                borderTop={'none'}
                borderRight={'1px dotted red'}
                borderBottom={'none'}
                borderLeft = {'1px dotted red'}
                color={null}
        />  
        <StrikeZoneArea 
                top={props.height * 0.4}
                left={props.width * 0.0} //0.175
                width= {props.width * 0.4} //0.325
                height={props.height * 0.2}//0.325
                borderTop={'1px dotted red'}
                borderRight={'none'}
                borderBottom={'1px dotted red'}
                borderLeft = {'none'}
                color={null}
        />  
         <StrikeZoneArea 
                top={props.height * 0.4}
                left={props.width * 0.6} //0.175
                width= {props.width * 0.4} //0.325
                height={props.height * 0.2}//0.325
                borderTop={'1px dotted red'}
                borderRight={'none'}
                borderBottom={'1px dotted red'}
                borderLeft = {'none'}
                color={null}
        />  



        {battingLeft}
        {battingRight}

        
        </Aux>
    )
}

const mapStateToProps = state =>{
        return{
                battingRight: state.atBat.battingRight,
                pitcherView: state.atBat.pitcherView
        }

}

export default connect(mapStateToProps) (StrikeZone);