import React from 'react';
import styles from './BaseRunner.module.css';



const BaseRunner = (props) => {
    let baseRunners = [];

    if(props.runners && typeof props.runners !== 'undefined'){
        for(let i = 0; i<props.runners.length; ++ i){
            let runner = props.runners[i];
            let index = -1;
            let LOBButton = null;
            let SAFEButton = null;
            if(props.handleLeftOnBase){
                LOBButton = <button
                className={styles.BaseRunnerButton}
                onClick={()=>props.handleLeftOnBase(runner)}
                >LOB</button>
            }
            if(props.handleSafe){
                SAFEButton = <button
                className={styles.BaseRunnerButton}
                onClick={()=>props.handleSafe(runner)}
                >SAFE</button>
            }
            baseRunners.push(
                <div key={"baseRunner-" + i}>
                <span>{index}- #{runner.batter.number} {runner.batter.firstName} {runner.batter.lastName}</span> 
                <button
                    className={styles.BaseRunnerButton}
                    onClick={()=>props.handleScore(runner)}
                    >SCORED</button>
                <button
                    className={styles.BaseRunnerButton}
                    onClick={()=>props.handleOut(runner)}
                    >OUT</button>   
                {LOBButton}
                {SAFEButton}
                
                </div>)
        }
    }


    return(
        <div className={styles.BaseRunner}>
            {baseRunners}   
            
            


            
        </div>
    )
}


export default BaseRunner;