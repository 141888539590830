import React, {Component} from 'react';
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import Spinner from '../../components/UI/Spinner/Spinner';
import classes from './MyAccount.module.css';
import { connect } from 'react-redux';
import * as ActionTypes from '../../store/actions/index';
import {Redirect} from 'react-router-dom';


class MyAccount extends Component{
    state = {
        controls: {
            email: {
                elementType: 'input',
                elementConfig: {
                    label: 'Email',
                    type: 'email',
                    placeholder: 'E-Mail Address'
                },
                value: '',
                validation: {
                    required: false,
                    isEmail: true
                },
                valid: false,
                touched: false
            },
            firstName: {
                elementType: 'input',
                elementConfig: {
                    label: 'First Name',
                    type: 'firstName',
                    placeholder: 'First Name'
                },
                value: '',
                validation: {
                    required: false,
                },
                valid: false,
                touched: false
            }
            ,
            lastName: {
                elementType: 'input',
                elementConfig: {
                    label: 'Last Name',
                    type: 'lastName',
                    placeholder: 'Last Name'
                },
                value: '',
                validation: {
                    required: false,
                },
                valid: false,
                touched: false
            },
            city: {
                elementType: 'input',
                elementConfig: {
                    label: 'City',
                    type: 'city',
                    placeholder: 'City'
                },
                value: '',
                validation: {
                    required: false,
                },
                valid: false,
                touched: false
            },
            state: {
                elementType: 'input',
                elementConfig: {
                    label: 'State',
                    type: 'state',
                    placeholder: 'State'
                },
                value: '',
                validation: {
                    required: false,
                },
                valid: false,
                touched: false
            },
            
            zip: {
                elementType: 'input',
                elementConfig: {
                    label: 'Zip',
                    type: 'zip',
                    placeholder: 'Zip'
                },
                value: '',
                validation: {
                    required: false,
                },
                valid: false,
                touched: false
            }
            ,
            phone: {
                elementType: 'input',
                elementConfig: {
                    label: 'Phone',
                    type: 'phone',
                    placeholder: 'Phone'
                },
                value: '',
                validation: {
                    required: false,

                },
                valid: false,
                touched: false
            },
            organization:{
                
                elementType: 'select',
                elementConfig: {
                    
                    label: 'Organization',
                    options : [
                       
                    ]
                    
                },
                value:'-LuZjCH5K2h5jmpnqSC0',
                validation: {},
                valid:true
            },
            newOrg:{
                
                elementType: 'input',
                elementConfig: {
                    
                    label: 'New Organization',
                    type: "newOrg",
                    placeholder: 'New Organization'
                    
                },
                value:'',
                show:false,
                validation: {
                    isUnique : true
                },
                valid:true
            },
            alias:{
                
                elementType: 'input',
                elementConfig: {
                    
                    label: 'Allow Account Aliasing',
                    type: 'checkbox',
                    
                    
                },
                value:false ,
                validation: {},
                valid:false
            },
            
        },
        formIsValid: false
    }

    componentDidMount(){
        this.props.onGetOrgs();

        let updateProps = false;
        const updatedForm = {
            ...this.state.controls
        }
        if(this.props.auser && this.props.orgs)
        {
            updateProps = true;
            const updatedFormFirstName = {...updatedForm['firstName']};
            if(this.props.auser.firstName){
                updatedFormFirstName.value = this.props.auser.firstName;
                updatedFormFirstName.valid = this.checkValidity(updatedFormFirstName.value, updatedFormFirstName.validation);
            }
            
            const updatedFormLastName = {...updatedForm['lastName']};
            if(this.props.auser.lastName){
                updatedFormLastName.value = this.props.auser.lastName;
                updatedFormLastName.valid = this.checkValidity(updatedFormLastName.value, updatedFormLastName.validation);
            }

            const updatedFormEmail = {...updatedForm['email']};
            if(this.props.auser.email){
                updatedFormEmail.value = this.props.auser.email;
                updatedFormEmail.valid = this.checkValidity(updatedFormEmail.value, updatedFormEmail.validation);
            }

            const updatedFormCity = {...updatedForm['city']};
            if(this.props.auser.city){
                updatedFormCity.value = this.props.auser.city;
                updatedFormCity.valid = this.checkValidity(updatedFormCity.value, updatedFormCity.validation);
            }

            const updatedFormState = {...updatedForm['state']};
            if(this.props.auser.state){
                updatedFormState.value = this.props.auser.state;
                updatedFormState.valid = this.checkValidity(updatedFormState.value, updatedFormState.validation);
            }

            const updatedFormZip = {...updatedForm['zip']};
            if(this.props.auser.zip){
                updatedFormZip.value = this.props.auser.zip;
                updatedFormZip.valid = this.checkValidity(updatedFormZip.value, updatedFormZip.validation);
            }

            const updatedFormPhone = {...updatedForm['phone']};
            if(this.props.auser.phone){
                updatedFormPhone.value = this.props.auser.phone;
                updatedFormPhone.valid = this.checkValidity(updatedFormPhone.value, updatedFormPhone.validation);
            }

            const updatedFormOrg = {...updatedForm['organization']};
            if(this.props.auser.organizationId){
                updatedFormOrg.value = this.props.auser.organizationId;
                updatedFormOrg.valid = this.checkValidity(updatedFormOrg.value, updatedFormOrg.validation);
            }

            const updatedFormAlias = {...updatedForm['alias']};
            if(this.props.auser.alias !== null){
                updatedFormAlias.value = this.props.auser.alias;
                updatedFormAlias.valid = this.checkValidity(updatedFormAlias.value, updatedFormAlias.validation);
            }
            
            
            updatedForm['firstName'] = updatedFormFirstName;
            updatedForm['lastName'] = updatedFormLastName;
            updatedForm['email'] = updatedFormEmail;
            updatedForm['city'] = updatedFormCity;
            updatedForm['state'] = updatedFormState;
            updatedForm['zip'] = updatedFormZip;
            updatedForm['phone'] = updatedFormPhone;
            updatedForm['organization'] = updatedFormOrg;
            updatedForm['alias'] = updatedFormAlias;
            
            let formIsValid = true;
            for(let inputIds in updatedForm){
                formIsValid = updatedForm[inputIds].valid && formIsValid;
            }
            this.setState({controls: updatedForm, formIsValid: formIsValid});
        }
        if(updateProps){

            if(this.props.orgs.length > 0){
                
                const updatedFormOrgs = {...updatedForm['organization']};
                //updatedFormOrgs.value = this.props.auser.organizationId;//this.props.orgs[0].id;
                let updatedOptions = [];
                let org;
                let option = {
                    value: 'new',
                    displayValue: 'New'
                }
                updatedOptions.push(option);
                for(org in this.props.orgs){
                    let option = {
                        value: this.props.orgs[org].id,
                        displayValue: this.props.orgs[org].name
                    }
                    updatedOptions.push(option);
                }
                updatedFormOrgs.elementConfig.options = updatedOptions;
                updatedFormOrgs.valid = true;
                updatedFormOrgs.value = this.props.auser.organizationId;
                //console.log('set organization id to: ' + updatedFormOrgs.value );

                const updatedFormNewOrg = {...updatedForm['newOrg']};
                updatedFormNewOrg.show = false;


                updatedForm['organization'] = updatedFormOrgs;
                updatedForm['newOrg'] = updatedFormNewOrg;
                
                let formIsValid = true;
                for(let inputIds in updatedForm){
                    formIsValid = updatedForm[inputIds].valid && formIsValid;
                }
                this.setState({controls: updatedForm, formIsValid: formIsValid});
            }
        }
    }

    componentDidUpdate(prevProps){


        let updateProps = false;
        const updatedForm = {
            ...this.state.controls
        }
        if((this.props.auser && prevProps.auser !== this.props.auser) ||
        (this.props.auser && prevProps.orgs.length !== this.props.orgs.length)
        ){
            updateProps = true;
            this.props.onGetOrgs();
            const updatedFormFirstName = {...updatedForm['firstName']};
            if(this.props.auser.firstName){
                updatedFormFirstName.value = this.props.auser.firstName;
                updatedFormFirstName.valid = this.checkValidity(updatedFormFirstName.value, updatedFormFirstName.validation);
            }
            
            const updatedFormLastName = {...updatedForm['lastName']};
            if(this.props.auser.lastName){
                updatedFormLastName.value = this.props.auser.lastName;
                updatedFormLastName.valid = this.checkValidity(updatedFormLastName.value, updatedFormLastName.validation);
            }

            const updatedFormEmail = {...updatedForm['email']};
            if(this.props.auser.email){
                updatedFormEmail.value = this.props.auser.email;
                updatedFormEmail.valid = this.checkValidity(updatedFormEmail.value, updatedFormEmail.validation);
            }

            const updatedFormCity = {...updatedForm['city']};
            if(this.props.auser.city){
                updatedFormCity.value = this.props.auser.city;
                updatedFormCity.valid = this.checkValidity(updatedFormCity.value, updatedFormCity.validation);
            }

            const updatedFormState = {...updatedForm['state']};
            if(this.props.auser.state){
                updatedFormState.value = this.props.auser.state;
                updatedFormState.valid = this.checkValidity(updatedFormState.value, updatedFormState.validation);
            }

            const updatedFormZip = {...updatedForm['zip']};
            if(this.props.auser.zip){
                updatedFormZip.value = this.props.auser.zip;
                updatedFormZip.valid = this.checkValidity(updatedFormZip.value, updatedFormZip.validation);
            }

            const updatedFormPhone = {...updatedForm['phone']};
            if(this.props.auser.phone){
                updatedFormPhone.value = this.props.auser.phone;
                updatedFormPhone.valid = this.checkValidity(updatedFormPhone.value, updatedFormPhone.validation);
            }

            const updatedFormOrg = {...updatedForm['organization']};
            if(this.props.auser.organizationId){
                updatedFormOrg.value = this.props.auser.organizationId;
                updatedFormOrg.valid = this.checkValidity(updatedFormOrg.value, updatedFormOrg.validation);
            }

            const updatedFormAlias = {...updatedForm['alias']};
            if(this.props.auser.alias !== null){
                updatedFormAlias.value = this.props.auser.alias;
                updatedFormAlias.valid = this.checkValidity(updatedFormAlias.value, updatedFormAlias.validation);
            }
            
            
            updatedForm['firstName'] = updatedFormFirstName;
            updatedForm['lastName'] = updatedFormLastName;
            updatedForm['email'] = updatedFormEmail;
            updatedForm['city'] = updatedFormCity;
            updatedForm['state'] = updatedFormState;
            updatedForm['zip'] = updatedFormZip;
            updatedForm['phone'] = updatedFormPhone;
            updatedForm['organization'] = updatedFormOrg;
            updatedForm['alias'] = updatedFormAlias;
            
            let formIsValid = true;
            for(let inputIds in updatedForm){
                formIsValid = updatedForm[inputIds].valid && formIsValid;
            }
            this.setState({controls: updatedForm, formIsValid: formIsValid});
        }
        if(updateProps || (this.props.auser && prevProps.orgs !== this.props.orgs)){

            if(this.props.orgs.length > 0){
                
                const updatedFormOrgs = {...updatedForm['organization']};
                //updatedFormOrgs.value = this.props.auser.organizationId;//this.props.orgs[0].id;
                let updatedOptions = [];
                let org;
                let option = {
                    value: 'new',
                    displayValue: 'New'
                }
                updatedOptions.push(option);
                for(org in this.props.orgs){
                    let option = {
                        value: this.props.orgs[org].id,
                        displayValue: this.props.orgs[org].name
                    }
                    updatedOptions.push(option);
                }
                updatedFormOrgs.elementConfig.options = updatedOptions;
                updatedFormOrgs.valid = true;
                updatedFormOrgs.value = this.props.auser.organizationId;
                //console.log('set organization id to: ' + updatedFormOrgs.value );

                const updatedFormNewOrg = {...updatedForm['newOrg']};
                updatedFormNewOrg.show = false;


                updatedForm['organization'] = updatedFormOrgs;
                updatedForm['newOrg'] = updatedFormNewOrg;
                
                let formIsValid = true;
                for(let inputIds in updatedForm){
                    formIsValid = updatedForm[inputIds].valid && formIsValid;
                }
                this.setState({controls: updatedForm, formIsValid: formIsValid});
            }
        }
    }

    submitHandler = (event) =>{
        event.preventDefault();


        //console.log('checkbox value = ' + this.state.controls.alias.value);
        //console.log('auser id ' + this.props.auser.id);

        let createOrganization = false;
        let organizationName = this.state.controls.organization.value;
        let roleVal = this.props.auser.role;
        let organizationId = this.state.controls.organization.value;
        if(organizationName === 'new'){
            createOrganization = true;
            organizationName = this.state.controls.newOrg.value;
            roleVal = 'OrgAdmin';
            organizationId = '';
        }
        else if(this.props.auser.organizationId !== organizationId){
            roleVal = '';
        }
        
        let userObj = {
            email : this.state.controls.email.value,
            firstName: this.state.controls.firstName.value,
            lastName: this.state.controls.lastName.value,
            city: this.state.controls.city.value,
            state: this.state.controls.state.value,
            zip: this.state.controls.zip.value,
            phone: this.state.controls.phone.value,
            organizationId: organizationId,
            userId: this.props.auser.userId,
            role: roleVal,
            alias: this.state.controls.alias.value,
            id: this.props.auser.id
            
        }
        //console.log('organizationName = ' + organizationName + ' create new organization = ' + createOrganization);
        //console.log('userObj', userObj)
        
        let userObj2 = {...userObj}
        
        //this.props.onUpdateAuthUser(userObj);
        this.props.onAuthUpdateUser(this.props.token, userObj2, organizationName, createOrganization);

        // this.props.onRegister(
        //     this.state.regControls.email.value, 
        //     this.state.regControls.password.value,
        //     userObj,
        //     organizationName,
        //     createOrganization
        //     );

       
    }

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }
        
        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if(rules.isUnique) {
            //console.log('check isUnique ' + value);
            isValid = true;
            for(let i=0;i<this.props.orgs.length; ++i){
                if(this.props.orgs[i].name === value.trim()){
                    isValid = false;
                }
            }
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        if (rules.isNumeric) {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }

    onHandleResetPassword = () =>{
        this.props.history.push('/reset');
    }

    inputChangedHandler = (event, inputIdentifier) =>{
        //console.log(event.target.value);
        const updatedForm = {
            ...this.state.controls
        }
        let newOrgFormElement = null;
        
        if(inputIdentifier === 'organization'){
            newOrgFormElement = {...updatedForm['newOrg']};
            if(event.target.value === 'new'){
                newOrgFormElement.show = true;
            }
            else{
                newOrgFormElement.show = false;
            }
        }
        

        const updatedFormElement = {...updatedForm[inputIdentifier]};
        
        
        updatedFormElement.touched = true;
        //console.log('updatedFormElement.value ' + updatedFormElement.value);
        if(updatedFormElement.elementConfig.type === 'checkbox'){
            updatedFormElement.value = !updatedFormElement.value;
            
        }
        else{
            updatedFormElement.value = event.target.value;
        }
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        //////console.log(updatedFormElement.valid, updatedFormElement.validation ? true : false);
        updatedForm[inputIdentifier] = updatedFormElement;

        if(newOrgFormElement){
            updatedForm['newOrg'] = newOrgFormElement;
        }

        let formIsValid = true;
        for(let inputIds in updatedForm){
            formIsValid = updatedForm[inputIds].valid && formIsValid;
        }
        this.setState({controls: updatedForm, formIsValid: formIsValid});
    }

    render(){
        const formElementsArray = [];
        for(let key in this.state.controls){
            if(this.state.controls[key].show !== false){
                formElementsArray.push({
                    id: key,
                    config: this.state.controls[key],
                    validation: this.state.controls[key].validation,
                    valid: this.state.controls[key].valid,
                    touched: this.state.controls[key].touched
                });
            }
        }
        let header = null;

        if(this.props.auser){
            header = this.props.auser.firstName +" "+this.props.auser.lastName+" - Role: "
            + (this.props.auser.role === '' ? 'Not Set' : this.props.auser.role);
        }

        let errorMessage = null;
        if(this.props.error){
            errorMessage = <span className={classes.Error}>{this.props.message}</span>
        }

        let form = (
            <form onSubmit={this.submitHandler}>
                    {formElementsArray.map(el => (
                        <Input 
                            key={el.id}
                            elementType={el.config.elementType}
                            elementConfig={el.config.elementConfig}
                            label={el.config.elementConfig.label}
                            value={el.config.value}
                            invalid = {!el.valid}
                            shouldValidate ={el.validation && el.touched}
                            changed={(event)=> this.inputChangedHandler(event, el.id)}/>
                    ))}
                        
                    
                    <Button disabled={!this.state.formIsValid} btnType="Danger">SAVE</Button>
                </form>
        );
        return(
            <div className={classes.MyAccount}>
                <h2>{header}</h2>
                <Button btnType="Danger" clicked={this.onHandleResetPassword}>RESET PASSWORD</Button>
            {errorMessage}
            {form}
            </div>
        )
    }
}


const mapDispatchToProps = dispatch =>{
    return{
        onAuth: (email,password)=>dispatch(
            ActionTypes.auth(email,password)
        ),
        onRegister: (email,password)=>dispatch(
            ActionTypes.register(email,password)
        ),
        onUpdateAuthUser: (data)=>dispatch(
            ActionTypes.updateAuthUser(data)
        ),
        onAuthUpdateUser: (token,data, orgName, createOrg)=>dispatch(
            ActionTypes.authUpdateUser(token, data, orgName, createOrg)
        ),
        onGetOrgs: ()=>dispatch(
            ActionTypes.getOrgs()
        )
    }
}

const mapStateToProps = state =>{
    return{
        error: state.user.error,
        message: state.user.errorMessage,
        updateMessage:  state.auth.updateMessage,
        auser: state.auth.user,
        loading: state.user.loading,
        token: state.auth.token,
        isAuth: state.auth.token !== null,
        redirectPath: state.auth.authRedirectPath,
        orgs: state.org.orgs
    };
}

export default connect(mapStateToProps, mapDispatchToProps) (MyAccount);
