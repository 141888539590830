import Util from '../Util/Util';

class Pitch{
    constructor(id,number,x,y,result, battingRight, pitcher, batter, inning, outs, ballCount, 
                strikeCount, pitcherView, baseRunners, pitcherNotes, batterNotes){
        
        //this.id = id;
        this.id = Util.newID();
        this.number = number;
        this.x = x;
        this.y = y;
        this.result = result;
        this.pitchResult = '';
        this.runningResult = '';
        this.pitchType = '';
        this.battingRight = battingRight;
        this.batAction = '';
        this.batActionLocation = '';
        this.batActionType = '';
        this.hitAction = '';
        this.hitPower = '';
        this.hitResult = '';
        this.hitLocation = {};
        this.batter = batter;
        this.pitcher = pitcher;
        this.count = {
            balls: 0,
            strikes: 0
        }
        this.pitchLocation = '';
        this.strike = false;
        this.inning = inning;
        this.outs = outs;
        this.ballCount = ballCount;
        this.strikeCount = strikeCount;
        this.pitcherView = pitcherView;
        this.baseRunners = baseRunners;
        this.pitcherNotes = '';
        this.batterNotes = '';
        
    }
    static SetCount = (pitch, balls, strikes) => {
        pitch.count.balls = balls;
        pitch.count.strikes = strikes;
        return pitch;
    }

    static PitchTypeAbbrv = (p) =>{
        if(p.pitchType === 'FB')
            return 'FB';
        else if(p.pitchType === 'XCHANGE'){
            return 'XCH'
        }
        else if(p.pitchType === 'CURVE'){
            return 'CRV'
        }
        else if(p.pitchType === 'DROPCV'){
            return 'DCV'
        }
        else if(p.pitchType === 'DROP'){
            return 'DRP'
        }
        else if(p.pitchType === 'RISE'){
            return 'RIS'
        }
        else if(p.pitchType === 'SCREW'){
            return 'SCW'
        }
        return p.pitchType;
       
    }

    static LocationAbbrv = (p) =>{
         //IN/OUT/MIDDLE
         let battingRight = p.battingRight;
         let pitcherView = p.pitcherView;
         let ret = '';
         var x = p.x;
         var y = p.y;
         if(pitcherView){
             if(battingRight){
                 x = 1.0 - x;
             }
         }
         else{
             if(!battingRight){
                 x = 1.0 - x;
             }
         }
         
         
 
         if(y < 0.175){
             ret = 'H';
         }
         else if (y > 0.825){
             ret = 'L';
         }
         else if(y < 0.4){
             ret = 'H';//'MIDDLE-HIGH';
         }
         else if(y > 0.6){
             ret = 'L';//'MIDDLE-LOW';
         }
         else{
             ret = 'M';
         }
         //ret += '/';
         if(x < 0.175){
             ret += 'I';
         }
         else if(x > 0.825){
             ret += 'A';
         }
         else if(x < 0.4){
             ret += 'I';//'MIDDLE-IN';
         }
         else if(x > 0.6){
             ret += 'A';//'MIDDLE-OUT';
         }
         else{
             ret += 'M';
         }
         return ret;
    }

    static LocationName = (p) =>{

        return this.LocationAbbrv(p);
        

        // //IN/OUT/MIDDLE
        // let battingRight = this.battingRight;
        // let pitcherView = this.pitcherView;
        // let ret = '';
        // var x = this.x;
        // var y = this.y;
        // if(pitcherView){
        //     if(battingRight){
        //         x = 1.0 - x;
        //     }
        // }
        // else{
        //     if(!battingRight){
        //         x = 1.0 - x;
        //     }
        // }
        
        

        // if(y < 0.175){
        //     ret = 'HIGH';
        // }
        // else if (y > 0.825){
        //     ret = 'LOW';
        // }
        // else if(y < 0.4){
        //     ret = 'HIGH';//'MIDDLE-HIGH';
        // }
        // else if(y > 0.6){
        //     ret = 'LOW';//'MIDDLE-LOW';
        // }
        // else{
        //     ret = 'MIDDLE';
        // }
        // ret += '/';
        // if(x < 0.175){
        //     ret += 'IN';
        // }
        // else if(x > 0.825){
        //     ret += 'AWAY';
        // }
        // else if(x < 0.4){
        //     ret += 'IN';//'MIDDLE-IN';
        // }
        // else if(x > 0.6){
        //     ret += 'AWAY';//'MIDDLE-OUT';
        // }
        // else{
        //     ret += 'MIDDLE';
        // }
       
        // this.pitchLocation = ret;
        // return ret;
    }

    static isStrike= (p) => {
        if(p.x >= 0.175  &&
           p.x <= 0.825  &&
           p.y >= 0.175 &&
           p.y <= 0.825) {
           p.strike = true;
           return true;
        }
        p.strike = false;
        return false;
    }

    static LoadPitchType = (pitch, result) =>{
        ////console.log('setting pitch type: ' + result);
        pitch.pitchType = result;
        return pitch;

    }

    static LoadResult = (pitch, result) =>{
        pitch.result = result;
        /*if(this.result === 'Take'){
            if(this.x <= 0.825 && this.x >= 0.175 &&
               this.y <= 0.825 && this.y >= 0.175){
                   return 1;//strike
            }
            else{
                return 2;//ball
            } 
        }
        return 3;//in play or foul*/
        return pitch;

    }

    static GetResultType = (pitch) =>{

        if(pitch.result === 'FC'){
            return 5;// fielder's choice
        }
        if(pitch.result === 'HBP'){
            return 4;// hit by pitch
        }
        if(pitch.result === 'ROE'){
            return 6;
        }
        if(pitch.result === 'Take'){
            if(pitch.x <= 0.825 && pitch.x >= 0.175 &&
                pitch.y <= 0.825 && pitch.y >= 0.175){
                   return 1;//strike
            }
            else{
                return 2;//ball
            } 
        }
        if(pitch.result === 'DRP3-OUT'){
            return 7;//I think this is the same as a strike
        }
        if(pitch.result === 'DRP3-PB/WP'){
            return 8;
        }
        
        return 3;//in play or foul
    }

    

    static LoadBatAction = (pitch, action) => {
        pitch.batAction = action;
        return pitch;
    }

    static GetBatActionType = (p) => {
        if(p.batAction === 'Miss'){
            p.result = 'SWING AND MISS';
            return 1;//missed, onto next pitch
        }
        else if(p.batAction === 'Foul'){
            p.result = 'FOUL';
            return 2;//foul, need to plot on field
        }
        return 3;//in play, need to plot field

    }


    static LoadBatActionLocation = (pitch, action) => {
        pitch.batActionLocation = action;
        return pitch;
    }

    static GetBatActionLocationType = (pitch) => {
        if( pitch.batActionLocation === 'HR7' ||
            pitch.batActionLocation === 'HR8' ||
            pitch.batActionLocation === 'HR9'){
            pitch.batAction = "In Play";
            pitch.result = 'IN PLAY'
            pitch.hitResult = 'HR';   
            pitch.result = 'HR'; 
            return 1; //Home Run, new batter    
        }
        else if(pitch.batActionLocation === 'FOUL LEFT' ||
            pitch.batActionLocation === 'FOUL RIGHT' ||
            pitch.batActionLocation === 'FOUL BACK'){
            pitch.batAction = "Foul";
            pitch.result = 'FOUL';
            pitch.hitResult = 'FOUL';
            return 2; //Foul, new pitch
        }
        else if(pitch.batActionLocation === 'MISS'){
            pitch.result = 'SWING AND MISS';
            return 4;//miss
        }
        pitch.batAction = "In Play";
        pitch.result = 'IN PLAY'
        return 3;//in play to a fielder

    }
    static GetBatActionLocationQuadPoint = (p) => {
        if(p.batActionLocation === 'FOUL BACK'){
            return{x:280,y:350}
        }
        if(p.batActionLocation === 'FOUL LEFT'){
            return{x:200,y:257}
        }
        if(p.batActionLocation === 'FOUL RIGHT'){
            return{x:200,y:257}
        }
        if(p.batActionLocation === '2'){
            return{x:138,y:350}
        }
        if(p.batActionLocation === '1/2'){
            return{x:150,y:300}
        }
        if(p.batActionLocation === '2/3'){
            return{x:160,y:275}
        }
        if(p.batActionLocation === '3'){
            return{x:160,y:222}
        }
        if(p.batActionLocation === '2/5'){
            return{x:230,y:265}
        }
        if(p.batActionLocation === '1'){
            return{x:154,y:280}
        }
        if(p.batActionLocation === '5'){
            return{x:200,y:235}
        }
        if(p.batActionLocation === '5/6'){
            return{x:238,y:190}
        }
        if(p.batActionLocation === '6'){
            return{x:255,y:183}
        }
        if(p.batActionLocation === '4'){
            return{x:158,y:180}
        }
        if(p.batActionLocation === '3/4'){
            return{x:200,y:190}
        }
        if(p.batActionLocation === 'RL6'){
            return{x:200,y:200}
        }
        if(p.batActionLocation === '9'){
            return{x:176,y:171}
        }
        if(p.batActionLocation === '8/9'){
            return{x:135,y:171}
        }
        if(p.batActionLocation === '8'){
            return{x:125,y:180}
        }
        if(p.batActionLocation === '7/8'){
            return{x:264,y:166}
        }
        if(p.batActionLocation === '7'){
            return{x:200,y:200}
        }
        if(p.batActionLocation === 'LL7'){
            return{x:200,y:200}
        }
        if(p.batActionLocation === 'HR7'){
            return{x:200,y:200}
        }
        if(p.batActionLocation === 'HR8'){
            return{x:130,y:200}
        }
        if(p.batActionLocation === 'HR9'){
            return{x:200,y:200}
        }

        return {x:200,
                y:200};
    }


    static LoadHitAction = (pitch, action) => {
        pitch.hitAction = action;
        return pitch;
    }
    static LoadHitPower = (pitch, action) =>{
        pitch.hitPower = action;
        return pitch;
    }
    static LoadHitResult = (pitch, action) =>{
        pitch.result = action;
        pitch.hitResult = action;
        return pitch;

    }
};

export default Pitch;