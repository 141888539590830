import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as ActionTypes from '../../store/actions/index';
import classes from './BaseRuners.module.css';
import BaseRunner from './BaseRunner/BaseRunner';


class BaseRunners extends Component{

    canEdit = () =>{
        if(this.props.auser && (this.props.auser.role === 'SysAdmin' ||
        this.props.auser.role === 'OrgAdmin' ||
        this.props.auser.role === 'Admin')){
            return true;
        }
        return false;
    }

    handleDrag = () =>{
        ////console.log('handle Drag')
    }

    advanceSecondToThird = () =>{
        let newThirdBase = {...this.props.secondBaseRunner};
         this.props.onSetThirdBaseRunner(newThirdBase)
         this.props.onSetSecondBaseRunner(null);
    }

    advanceFirstToSecond = () =>{
        let newSecondBase = {...this.props.firstBaseRunner};
         this.props.onSetSecondBaseRunner(newSecondBase)
         this.props.onSetFirstBaseRunner(null);
    }

    retreatSecondToFirst = () =>{
        let newFirstBase = {...this.props.secondBaseRunner};
         this.props.onSetSecondBaseRunner(null)
         this.props.onSetFirstBaseRunner(newFirstBase);
    }

    retreatThirdToSecond = () =>{
        let newSecondBase = {...this.props.thirdBaseRunner};
         this.props.onSetSecondBaseRunner(newSecondBase)
         this.props.onSetThirdBaseRunner(null);
    }

    

    render(){

        let baseRunners = [];
        

        if(this.props.firstBaseRunner){
            baseRunners.push(<BaseRunner key={"baseRunner-1"} base={1} runner={this.props.firstBaseRunner} handleReconcileBaseRunner={this.props.handleReconcileBaseRunner}/>)
        }
        if(this.props.secondBaseRunner){
            baseRunners.push(<BaseRunner key={"baseRunner-2"} base={2} runner={this.props.secondBaseRunner} handleReconcileBaseRunner={this.props.handleReconcileBaseRunner}/>)
        }
        if(this.props.thirdBaseRunner){
            baseRunners.push(<BaseRunner key={"baseRunner-3"} base={3} runner={this.props.thirdBaseRunner} handleReconcileBaseRunner={this.props.handleReconcileBaseRunner}/>)
        }
        
        let firstToSecond = null;
        let secondToThird = null;
        if(this.canEdit()){
            if((this.props.firstBaseRunner && !this.props.secondBaseRunner)){
                firstToSecond = <i onClick={this.advanceFirstToSecond} className={classes.FirstToSecond}></i>
            }
            if( (this.props.secondBaseRunner && !this.props.thirdBaseRunner)){
                secondToThird = <i onClick={this.advanceSecondToThird} className={classes.SecondToThird}></i>
            }
            //retreat!
            if((this.props.secondBaseRunner && !this.props.firstBaseRunner)){
                firstToSecond = <i onClick={this.retreatSecondToFirst} className={classes.SecondToFirst}></i>
            }
            if( (this.props.thirdBaseRunner && !this.props.secondBaseRunner)){
                secondToThird = <i onClick={this.retreatThirdToSecond} className={classes.ThirdToSecond}></i>
            }
        }



        

        return(
            <div className={classes.BaseRunners}>
                <h3 style={{transform: 'translateY(-21px)'}}></h3>
                {firstToSecond}
                {secondToThird}
                
                {/* <BaseRunner base={1} />
                <BaseRunner base={2} />
                <BaseRunner base={3} /> */}
                {baseRunners}
            </div>
        )
    }
}


const mapStateToProps = state =>{
    return{
        /*gameStart: state.game.gameStart,
        isAuth: state.auth.token !== null,
        loading: state.auth.loading,*/
        gameLoading: state.game.loading,
        gameError: state.game.error,
        gameMessage: state.game.message,
        auser: state.auth.user,
        //games: state.game.games,
        game: state.atBat.game,
        homeTeam: state.atBat.homeTeam,
        awayTeam: state.atBat.awayTeam,
        homeTeamPlayers: state.atBat.homeTeamPlayers,
        awayTeamPlayers: state.atBat.awayTeamPlayers,
        currentInning: state.atBat.currentInning,
        currentPitcher: state.atBat.currentPitcher,
        currentHitter: state.atBat.currentHitter,
        battingRight: state.atBat.battingRight,
        atBatState: state.atBat,
        gameStateLoading: state.atBat.loading,
        token: state.auth.token,
        pitchArray: state.atBat.pitchArray,
        outs: state.atBat.outs,
        pitchNumber: state.atBat.pitchNumber,
        baseRunners: state.atBat.baseRunners,
        homeScore: state.atBat.homeScore,
        awayScore: state.atBat.awayScore,
        gameOver: state.atBat.gameOver,
        homeTeamLineup: state.atBat.homeTeamLineup,
        homeTeamCurrentPitcher: state.atBat.homeTeamCurrentPitcher,
        awayTeamLineup: state.atBat.awayTeamLineup,
        awayTeamCurrentPitcher: state.atBat.awayTeamCurrentPitcher,
        totalInnings: state.atBat.totalInnings,
        homeTeamCurrentBatterIndex: state.atBat.homeTeamCurrentBatterIndex,
        awayTeamCurrentBatterIndex: state.atBat.awayTeamCurrentBatterIndex,
        inningScores: state.atBat.inningScores,
        currentTopInningScore: state.atBat.currentTopInningScore,
        currentBottomInningScore: state.atBat.currentBottomInningScore,
        forceQuit: state.atBat.forceQuit,
        forceQuitMessage: state.atBat.forceQuitNotes,
        homeSubstitutions: state.atBat.homeSubstitutions,
        awaySubstitutions: state.atBat.awaySubstitutions,
        pitcherView: state.atBat.pitcherView,
        firstBaseRunner: state.atBat.firstBaseRunner,
        secondBaseRunner: state.atBat.secondBaseRunner,
        thirdBaseRunner: state.atBat.thirdBaseRunner
    }
}


const mapDispatchToProps = dispatch =>{
    return{
        onUpdatePitcherView: (data)=>dispatch(
            ActionTypes.updatePitcherView(data)
        ),
        onLoadGame:  (token, id)=>dispatch(
            ActionTypes.initializeGame(token,id)
        ),
        onSetPitcher: (data)=>dispatch(
            ActionTypes.setCurrentPitcher(data)
        ),
        onSetBatter: (data)=>dispatch(
            ActionTypes.setCurrentBatter(data)
        ),
        onSetBattingRight: (data)=>dispatch(
            ActionTypes.setBattingRight(data)
        ),
        onSaveGameState: (token, data)=>dispatch(
            ActionTypes.saveGameState(token, data)
        ),
        onLoadGameState: (token, id)=>dispatch(
            ActionTypes.loadGameState(token, id)
        ),
        onUpdatePitchArray: (data)=>dispatch(
            ActionTypes.updateGameStatePitchArray(data)
        ),
        onSetCurrentInning: (data)=>dispatch(
            ActionTypes.setCurrentInning(data)
        ),
        onSetOuts: (data)=>dispatch(
            ActionTypes.setOuts(data)
        ),
        onSetPitchNumber: (data)=>dispatch(
            ActionTypes.setPitchNumber(data)
        ),
        onUpdateBaseRunners: (data)=>dispatch(
            ActionTypes.updateBaseRunners(data)
        ),
        onSetHomeScore: (data)=>dispatch(
            ActionTypes.setHomeScore(data)
        ),
        onSetAwayScore: (data)=>dispatch(
            ActionTypes.setAwayScore(data)
        ),
        onSetGameOver: (data)=>dispatch(
            ActionTypes.setGameOver(data)
        ),
        onSetHomeTeamCurrentBatterIndex: (data)=>dispatch(
            ActionTypes.setHomeTeamCurrentBatterIndex(data)
        ),
        onSetAwayTeamCurrentBatterIndex: (data)=>dispatch(
            ActionTypes.setAwayTeamCurrentBatterIndex(data)
        ),
        onSetInningScores: (data)=>dispatch(
            ActionTypes.setInningScores(data)
        ),
        onSetCurrentTopInningScore: (data)=>dispatch(
            ActionTypes.setCurrentTopInningScore(data)
        ),
        onSetCurrentBottomInningScore: (data)=>dispatch(
            ActionTypes.setCurrentBottomInningScore(data)
        ),
        onSetAwayTeamLineup: (data)=>dispatch(
            ActionTypes.setAwayTeamLineup(data)
        ),
        onSetHomeTeamLineup: (data)=>dispatch(
            ActionTypes.setHomeTeamLineup(data)
        ),
        onSetAwayTeamCurrentPitcher: (data)=>dispatch(
            ActionTypes.setAwayTeamCurrentPitcher(data)
        ),
        onSetHomeTeamCurrentPitcher: (data)=>dispatch(
            ActionTypes.setHomeTeamCurrentPitcher(data)
        ),
        onSetForceQuit: (forceQuit, forceQuitNotes)=>dispatch(
            ActionTypes.setForceQuit(forceQuit, forceQuitNotes)
        ),
        onUpdateHomeSubs: (data) =>dispatch(
            ActionTypes.updateHomeSubs(data)
        ),
        onUpdateAwaySubs: (data) =>dispatch(
            ActionTypes.updateAwaySubs(data)
        ),
        onSetAwayTeamPlayers: (data) =>dispatch(
            ActionTypes.setAwayTeamPlayers(data)
        ),
        onSetHomeTeamPlayers: (data) =>dispatch(
            ActionTypes.setHomeTeamPlayers(data)
        ),
        onSetFirstBaseRunner: (data)=>dispatch(
            ActionTypes.setFirstBaseRunner(data)
        ),
        onSetSecondBaseRunner: (data)=>dispatch(
            ActionTypes.setSecondBaseRunner(data)
        ),
        onSetThirdBaseRunner: (data)=>dispatch(
            ActionTypes.setThirdBaseRunner(data)
        )
    }
};



export default connect(mapStateToProps, mapDispatchToProps) (BaseRunners);
