import * as actionTypes from './actionTypes';
import axios from '../../axios';



export const createGameStateArraySuccess = (id, gameState) =>{
    return{
        type:actionTypes.CREATE_GAME_STATE_ARRAY_SUCCESS,
        id: id,
        data: gameState
    }
}

export const createGameStateArrayStart = () =>{
    return{
        type:actionTypes.CREATE_GAME_STATE_ARRAY_START
    }
}

export const createGameStateArray = (token, data) =>{
    ////console.log('createGameState,', data);
    return dispatch =>{
        dispatch(createGameStateArrayStart());
        axios.post('/gamestatearray.json?auth=' + token, data)
            .then(response =>{
                ////console.log("id="+response.data.name);
                dispatch(createGameStateArraySuccess(response.data.name, data));
            })
            .catch(error =>{
                ////console.log(error.response);
                //dispatch(createGameFail(error.response.data.error));
            });
    }
}

export const updateGameStateArraySuccess = (id, data) =>{
    return{
        type:actionTypes.UPDATE_GAME_STATE_ARRAY_SUCCESS,
        id: id,
        data: data
    }
}

export const updateGameStateArray = (token, data) =>{
    //console.log('updateGameStateArray,', data);
    return dispatch =>{
        axios.patch('gamestatearray/'+data.atBatID+'.json?auth=' + token, data)
            .then(response =>{
                dispatch(updateGameStateArraySuccess(data.atBatID, data));
            })
            .catch(error =>{
                
            });
    }
}





export const loadGameStateArraySuccess = (data) =>{
    return{
        type:actionTypes.LOAD_GAME_STATE_ARRAY_SUCCESS,
        data: data
    }
}
export const loadGameStateArrayStart = () =>{
    return{
        type:actionTypes.LOAD_GAME_STATE_ARRAY_START
    }
}

export const loadGameStateArray = (token, gameId) =>{
    return dispatch =>{
        dispatch(loadGameStateArrayStart());
        const queryParams = '?auth=' + token + '&orderBy="gameID"&equalTo="'+gameId+'"';
        axios.get('/gamestatearray.json' + queryParams)
        .then(response => {
            ////console.log('gamestate response', response);
            let gamestate = null;
            for(let key in response.data){
                gamestate = { ...response.data[key], atBatID: key}
            }
            //console.log('gamestate after spread', gamestate);
            if(gamestate){
                
                dispatch(loadGameStateArraySuccess(gamestate));
            }
            else{
                ////console.log('game state is null, dispatching initializeGame');
                dispatch(initializeGameStateArray(token, gameId));
            }
            
        })
        .catch(error =>{
            //dispatch(getTeamPlayersFail());
        });
    }
}

export const initializeGameStateArrayStart = () =>{
    return{
        type:actionTypes.INITIALIZE_GAME_STATE_ARRAY_START
    }
}

export const initializeGameStateArrayFail = () =>{
    return{
        type:actionTypes.INITIALIZE_GAME_STATE_ARRAY_FAIL
    }
}

export const initializeGameStateArraySuccess = (gameID) =>{
    return{
        type:actionTypes.INITIALIZE_GAME_STATE_ARRAY_SUCCESS,
        gameID: gameID
    }
}


export const initializeGameStateArray = (token, id) =>{

    return dispatch =>{
        dispatch(initializeGameStateArrayStart());
        axios.get('/games/'+id +'.json?auth=' + token)
        .then(response => {
            //console.log('initializeGameStateArray action:', response.data);
            //let game = null;
            if(response.data !== null){
                /*game = {
                    ...response.data,
                    id: id
                };*/
                dispatch(initializeGameStateArraySuccess(id));
            }
            else{
                //if it failed  it might be a training
                axios.get('/trainings/'+id +'.json?auth=' + token)
                .then(response => {
                    if(response.data !== null){
                        dispatch(initializeGameStateArraySuccess(id));
                    }
                    else{
                        dispatch(initializeGameStateArrayFail('no results found for id ' + id));
                    }
                })
            }
            
        })
        .catch(error =>{
            //if it failed  it might be a training
            axios.get('/trainings/'+id +'.json?auth=' + token)
            .then(response => {
                if(response.data !== null){
                    dispatch(initializeGameStateArraySuccess(id));
                }
                else{
                    dispatch(initializeGameStateArrayFail('no results found for id ' + id));
                }
            })

        });
    }

}

export const saveGameStateArray = (token, data) =>{
    //console.log('saveGameStateArray',  data);
    if(typeof data.atBatID === 'undefined'){
        //console.log('saveGameStateArray atBatID is undefined, returning');
        return;
    }
    if(data.atBatID === null)
    {
        return dispatch =>{
            dispatch(createGameStateArray(token, data))
        }
    }
    else{
        ////console.log('action->atbat->saveGameState calling updateGameState')
        return dispatch =>{
            dispatch(updateGameStateArray(token, data))
        }
    }
}
