import React, {Component} from 'react';
import styles from './PitchHistory.module.css';

import PitchHistoryItem from './PitchHistoryItem/PitchHistoryItem';
import {CopyToClipboard} from 'react-copy-to-clipboard';


class PitchHistory extends Component {
    state = {
        value: '',
        copied: false,
      };

    render(){


        /*const renderPitches = this.props.pitches.map((p,i) => {
            return(

                <PitchHistoryItem key={i} pitch={p} />
                
            )
        });*/

        let renderPitches = [];

        //for(let i=0;i<this.props.pitches.length; ++i){
            for(let i=this.props.pitches.length-1;i>=0; --i){
            let p = {...this.props.pitches[i], index: i};

            renderPitches.push(
                <PitchHistoryItem key={i} pitch={p} />
            )
        }

        /*
        let csv = '<table>';
        csv = csv +'<tr><td>Location</td><td>Ball/StrikeZone</td><td>Balls</td><td>Strikes</td> \
        <td>Bat Action</td><td>Bat Action Location</td><td>Batter</td><td>Batting Side</td> \
        <td>Pitcher</td><td>Hit Action</td><td>Pitch Type</td><td>Result</td></tr>';
        csv = csv + '\n';

        // const pitchCSV = this.props.pitches.map((p,i) =>{
        //     return(
        //         p.pitchLocation +  ' '
        //     )
        // });

        let pitchCSV = '';
        for(let i=0;i<this.props.pitches.length; ++i){
            pitchCSV = pitchCSV + '<tr>';
            pitchCSV = pitchCSV + '<td>' +this.props.pitches[i].pitchLocation +'</td>';
            pitchCSV = pitchCSV + '<td>' +(this.props.pitches[i].strike ? "STRIKE" : "BALL")+'</td>';
            pitchCSV = pitchCSV + '<td>' +this.props.pitches[i].ballCount+'</td>';
            pitchCSV = pitchCSV + '<td>' +this.props.pitches[i].strikeCount+'</td>';
            pitchCSV = pitchCSV + '<td>' +this.props.pitches[i].batAction+'</td>';
            pitchCSV = pitchCSV + '<td>' +this.props.pitches[i].batActionLocation+'</td>';
            pitchCSV = pitchCSV + '<td>' +this.props.pitches[i].batter.firstName+' '+this.props.pitches[i].batter.lastName+' '+this.props.pitches[i].batter.number+'</td>';
            pitchCSV = pitchCSV + '<td>' +(this.props.pitches[i].battingRight ? "RIGHT" : "LEFT")+'</td>';
            pitchCSV = pitchCSV + '<td>' +this.props.pitches[i].pitcher.firstName+' '+this.props.pitches[i].pitcher.lastName+' '+this.props.pitches[i].pitcher.number+'</td>';
            pitchCSV = pitchCSV + '<td>' +this.props.pitches[i].hitAction+'</td>';
            pitchCSV = pitchCSV + '<td>' +this.props.pitches[i].pitchType+'</td>';
            pitchCSV = pitchCSV + '<td>' +this.props.pitches[i].result+'</td>';
            pitchCSV = pitchCSV + '</tr>';
        }
        
        csv = csv + pitchCSV + '</table>';
        */



        /*//const tab = '   ';
        let csv = 'Location,Ball/StrikeZone,Balls,Strikes,Bat Action,Bat Action Location,Batter,Batting Side,Pitcher,Hit Action,Pitch Type,Result';
        csv = csv + '\n';

        // const pitchCSV = this.props.pitches.map((p,i) =>{
        //     return(
        //         p.pitchLocation +  ' '
        //     )
        // });

        let pitchCSV = '';
        for(let i=0;i<this.props.pitches.length; ++i){
            pitchCSV = pitchCSV + this.props.pitches[i].pitchLocation +',';
            pitchCSV = pitchCSV + (this.props.pitches[i].strike ? "STRIKE" : "BALL")+',';
            pitchCSV = pitchCSV + this.props.pitches[i].ballCount+',';
            pitchCSV = pitchCSV + this.props.pitches[i].strikeCount+',';
            pitchCSV = pitchCSV + this.props.pitches[i].batAction+',';
            pitchCSV = pitchCSV + this.props.pitches[i].batActionLocation+',';
            pitchCSV = pitchCSV + this.props.pitches[i].batter.firstName+' '+this.props.pitches[i].batter.lastName+' '+this.props.pitches[i].batter.number+',';
            pitchCSV = pitchCSV + (this.props.pitches[i].battingRight ? "RIGHT" : "LEFT")+',';
            pitchCSV = pitchCSV + this.props.pitches[i].pitcher.firstName+' '+this.props.pitches[i].pitcher.lastName+' '+this.props.pitches[i].pitcher.number+',';
            pitchCSV = pitchCSV + this.props.pitches[i].hitAction+',';
            pitchCSV = pitchCSV + this.props.pitches[i].pitchType+',';
            pitchCSV = pitchCSV + this.props.pitches[i].result;
            pitchCSV = pitchCSV + '\n';
        }
        
        csv = csv + pitchCSV;*/

        return (
            <>
            {/*
            <CopyToClipboard text={csv}
            onCopy={() => this.setState({copied: true})} >
            <button>Copy to clipboard</button>
            </CopyToClipboard>
            
    
            {this.state.copied ? <span style={{color: 'red'}}>Copied.</span> : null}
            */}

            <div className={styles.PitchHistory}>
                <table className="pure-table">
                    <thead>
                    <tr>
                        <th>Index</th>
                        <th>ID</th>
                        <th>Inning</th>
                        <th>Location</th>
                        <th>Ball/StrikeZone</th>
                        <th>Balls</th>
                        <th>Strikes</th>
                        <th>Bat Action</th>
                        <th>Bat Action Location</th>
                        <th>Batter</th>
                        <th>Batting Side</th>
                        <th>Pitcher</th>
                        <th>Bat Action Type</th>
                        <th>Hit Action</th>
                        <th>Pitch Type</th>
                        <th>Pitch Result</th>
                        <th>Result</th>
                        <th>Running Result</th>
                    </tr>
                    </thead>
                    <tbody>
                    {renderPitches}
                    </tbody>
                </table>
                
            </div>
            </>
        )
    }
}


export default PitchHistory;


