import React from 'react';
import {connect} from 'react-redux';
import classes from './PlayerStats.module.css';
import PencilImg from '../../../../assets/pencil.png';


const PlayerStats = (props) =>{

    let IP = 0;
    let pitchCount = 0;
    let strikeCount = 0;
    let walks = 0;
    let earnedRuns = 0;
    let allowedRuns = 0;
    let hitCount = 0;
    let ERA = 0; //(earnedruns / innings pitched) * totalinnings

    //let totalHomeErrors = 0;
    //let totalAwayErrors = 0;

    //fielders choice - an out was attemptment made on the hit, but not the batter
    let PA = 0; //plate appearances
    //let PS = 0; //pitches scene
    //let PPA = 0;// pitches per plate appearance
    //let SWG = 0;//swing percentage
    let pSWG = 0;//pitcher swings against percentage
    //let SWGM = 0;
    let C = 0; //?
    //let BA = 0;//batting average - number of hits / number of at-bats
    let BB = 0;//walks
    let K = 0; //strikeouts
    let XBH = 0;//extra base hits, 2B, 3B, HR, IPHR
    let AB = 0;//at bats - PA - BB- HBP
    //let RBIs = 0;//runs batted in
    //let OBP = 0; //(hits + BB + HBP)/PA
    let HBPs = 0; //hit by pitches
    let SMPercentage = 0; //(# swings with misses (totalMisses))/ # PS
    let GroundBalls = 0;
    let GBPercentage = 0;//count # Ground Balls/ PA
    let FBPercentage = 0; //(count # Fly Balls + # POP Flies )/ PA

    let hitHardCount = 0;
    let HHPercentage = 0;//(count # hit HARD/PA)

    let BAA = 0; //(Battig average Against) number of hits/number AB's for the pitcher

    
    let PopFlys = 0;
    
   
    let totalBallContacts = 0; //hit the ball foul or in play
    let totalMisses = 0;//swing and miss
    //let totalSwings = 0;//total swings
    let pTotalSwings = 0; ///total swings against pitcher
    let RBIids = [];
    let RecordedScores = [];

    //let R = 0; //Total Runs scored while pitching in the game

    let SPercentage = 0;//# strikes (strikes plotted plus foul balls that are balls)/# P
    let SPercentageCount = 0;

    let FBPercent = 0;
    let XCHPercent = 0;
    let CRVPercent = 0;
    let DRPPercent = 0;
    let DPCPercent = 0;
    let RISPercent = 0;
    let SCRPercent = 0;

    function getPercent(type, pitchArray){
        let ret = 0;
        for(let i=0;i<pitchArray.length; ++i){
            const p = pitchArray[i];
            if(p.pitchType === type){
                ret ++;
            }
        }
        if(pitchArray.length > 0){
            ret = ret / parseFloat(pitchArray.length);
            ret = (ret * 100).toFixed(0);
        }
        return ret;
    }

    FBPercent = getPercent('FB', props.currentPitchArray);
    XCHPercent = getPercent('XCHANGE', props.currentPitchArray);
    CRVPercent = getPercent('CURVE', props.currentPitchArray);
    DRPPercent = getPercent('DROP', props.currentPitchArray);
    DPCPercent = getPercent('DROPCV', props.currentPitchArray);
    RISPercent = getPercent('RISE', props.currentPitchArray);
    SCRPercent = getPercent('SCREW', props.currentPitchArray);

    function isHard(pitch){
        if(pitch.hitPower === 'Hard'){
            return true;
        }
        return false;
    }
   
    function isHit(pitch){
        if(pitch.result === "1B" || 
        pitch.result === "2B" || 
        pitch.result === "3B" || 
        pitch.result === "4B" || 
        pitch.result === "HR" || 
        pitch.result === "IPHR"){
            return true;
        }
        return false;
    }

    function isExtraBaseHit(pitch){
        if(
        pitch.result === "2B" || 
        pitch.result === "3B" || 
        pitch.result === "4B" || 
        pitch.result === "HR" || 
        pitch.result === "IPHR"){
            return true;
        }
        return false;
    }

    function isGroundBall(pitch){
        if(typeof pitch.hitAction !== 'undefined' &&
            pitch.hitAction === "Ground"){
            return true;
        }
        return false;
    }

    function isWalk(pitch){
        if( pitch.pitchResult === "ball" &&
            pitch.ballCount === 3 && 
            pitch.result === "Take"){
                return true;

        }
        return false;
    }

    function isHomeRun(pitch){
        if( pitch.batActionLocation === 'HR7' ||
            pitch.batActionLocation === 'HR8' ||
            pitch.batActionLocation === 'HR9'){
            pitch.hitResult = 'HR';   
            pitch.result = 'HR'; 
            return true; //Home Run, new batter    
        }
        return false;//in play to a fielder

    }

    /*function RBITotal(pitch){
        let ret = 0;
        if(typeof pitch.baseRunners !== 'undefined'){
            for(let i=0;i<pitch.baseRunners.length; ++i){
                const br = pitch.baseRunners[i];
                if(br.runningResult === 'SCORE'){
                    
                    if(!RBIids.includes(br.id)){
                        RBIids.push(br.id);
                        ret ++;
                    }
                }
            }
        }
        if(pitch.runningResult === 'SCORE' && isHomeRun(pitch)){
            ret ++;
        }
        return ret;
    }*/



    

    function isPopOrFly(pitch){
        if(pitch.hitAction === 'Pop' || pitch.hitAction === 'Fly'){
            return true;
        }
        return false;
    }

    


    /*function calculateBatterStats(){
        let innings = [];
        //let pitchArray = props.pitchArray.concat(props.currentPitchArray);
        let _pitchArray = [...props.pitchArray];
        let RBIids = [];
        
        
        for(let i=0;i<props.currentPitchArray.length; ++i){
            let add = true;
            
            for(let j=0;j<_pitchArray.length; ++j){
                if(_pitchArray[j].id === props.currentPitchArray[i].id){
                    add = false;
                    continue;
                }
            }
            if(add){
                _pitchArray.push(props.currentPitchArray[i]);
            }
        }
        for(let i=0;i<_pitchArray.length; ++i){
            const pitch = _pitchArray[i];
            if(typeof pitch.batter === 'undefined')
                continue;
            ////console.log('batter ' + i + ' id = ' + pitch.batter.id);
            if((props.player === null && props.teamID === pitch.batter.teamId) || 
                (props.player && pitch.batter.id === props.player.id)){


                if(isHard(pitch)){
                    hitHardCount ++;
                }
                if(isPopOrFly(pitch)){
                    PopFlys ++;
                }

                if(isGroundBall(pitch)){
                    GroundBalls += 1;
                }
                RBIs += RBITotal(pitch);

                if(isExtraBaseHit(pitch)){
                    XBH += 1;
                }
                if(isHit(pitch)){
                    hitCount+= 1;
                }
                if(pitch.hitResult !== ""){
                    totalBallContacts ++;
                }
                if(pitch.result === "SWING AND MISS"){
                    totalMisses ++;
                }
                if(pitch.result !== "Take"){
                    totalSwings ++;
                }

                if(pitch.ballCount === 0 && pitch.strikeCount ===0){
                    PA += 1;
                    AB += 1;
                    //if(pitch.result !== 'HBP' && !isWalk(pitch)){
                    //    AB += 1;
                    //}
                }
                if(isWalk(pitch)){
                    BB ++;
                    AB -=1;
                }

                if(pitch.result === 'HBP'){
                    HBPs ++;
                    //PA -= 1;
                    AB -= 1;
                }

                if(pitch.strikeCount === 2 && pitch.strike &&
                    (pitch.result === "Take" || pitch.result === "SWING AND MISS")){
                        K++
                    
                }


                PS += 1;
                let pi = pitch.inning;
                let po = pitch.outs;
                let result = pitch.result;
                innings.push({
                    pi,
                    po,
                    result
                });
            }
        }

        if(PA > 0){
            OBP = (hitCount + BB + HBPs)/ parseFloat(PA);

            GBPercentage = (GroundBalls) / parseFloat(PA) * 100;

            FBPercentage = PopFlys / parseFloat(PA) * 100;

            HHPercentage = hitHardCount / parseFloat(PA) * 100;

            if(OBP < 1){
                OBP = "." + ((OBP % 1).toFixed(3) + "").split(".")[1]
            }else{
                OBP = OBP.toFixed(2) + "";
            }

            GBPercentage = GBPercentage.toFixed(0) + '%';
            FBPercentage = FBPercentage.toFixed(0) + '%';
            HHPercentage = HHPercentage.toFixed(0) + '%';
        }

        

        
        

         
        //PS = pitches scene
        //totalBallContacts = 0; //hit the ball foul or in play
        //totalMisses = 0;//swing and miss
        //totalSwings = 0;//total swings
        if(PS > 0){
            //SWG = totalSwings / parseFloat(PS);
            SWGM = totalMisses / parseFloat(PS);
            C = totalBallContacts / parseFloat(PS) * 100;
            C = C.toFixed(0) + "%";
            if(PA > 0){
                PPA = PS / parseFloat(PA);
            }

            SMPercentage = totalMisses/ parseFloat(PS) * 100; 
            SMPercentage = SMPercentage.toFixed(0) + "%";

            SWG = SWG/ parseFloat(PS) * 100; 
            SWG = SWG.toFixed(0) + "%";


            
        }
        if(hitCount > 0){
            ////console.log("BA " + hitCount +" "+ PA)
            BA = hitCount / parseFloat(AB);

            if(BA < 1){
                BA = "." + ((BA % 1).toFixed(3) + "").split(".")[1]
            }else{
                BA = BA.toFixed(2) + "";
            }
        }
    }*/

    function calculatePitcherStats(){
        let innings = [];
        //let _pitchArray = [...props.pitchArray.concat(props.currentPitchArray)];
        let _pitchArray = [...props.currentPitchArray];
        ////console.log(pitchArray.length);
        for(let i=0;i<_pitchArray.length; ++i){
            const pitch = _pitchArray[i];
            if(typeof pitch.pitcher === 'undefined')
                continue;
            ////console.log('pitch ' + i + ' id = ' + pitch.pitcher.id + 'runningResult ' + pitch.runningResult +' result ' + pitch.result);
           
            ////console.log('batter ' + i + ' id = ' + pitch.batter.id);
            if((props.player === null && props.teamID === pitch.pitcher.teamId) || 
                (props.player && pitch.pitcher.id === props.player.id)){

                if(isPopOrFly(pitch)){
                    PopFlys ++;
                }

                if(isHard(pitch)){
                    hitHardCount ++;
                }
            //if(pitch.pitcher.id === props.player.id){
                if(pitch.strike  || (pitch.batAction === 'Foul' && !pitch.strike)){
                    SPercentageCount += 1;
                }

                if(isGroundBall(pitch)){
                    GroundBalls += 1;
                }

                if(isExtraBaseHit(pitch)){
                    XBH += 1;
                }
                if(isHit(pitch)){
                    hitCount+= 1;
                }
                if(pitch.hitResult !== ""){
                    totalBallContacts ++;
                }
                if(pitch.strike){
                    strikeCount ++;
                }
                if(pitch.result === "SWING AND MISS"){
                    totalMisses ++;
                }
                if(pitch.ballCount === 3 && pitch.pitchResult === "ball"){
                    walks ++;
                }
                if(pitch.runningResult === "SCORE" && pitch.result !== "ROE"){

                    if(!RecordedScores.includes[pitch.id]){
                        RecordedScores.push(pitch.id);
                        earnedRuns ++;
                        allowedRuns ++;
                    }
                }
                else if(pitch.runningResult === "SCORE"){
                    if(!RecordedScores.includes[pitch.id]){
                        RecordedScores.push(pitch.id);
                        allowedRuns ++;
                    }
                }
                if(pitch.result !== "Take"){
                    pTotalSwings ++;
                }

                if(pitch.ballCount === 0 && pitch.strikeCount ===0){
                    PA += 1;
                    AB += 1;
                    //if(pitch.result !== 'HBP' && !isWalk(pitch)){
                    //    AB += 1;
                    //}
                }
                if(isWalk(pitch)){
                    BB ++;
                    AB -=1;
                }

                if(pitch.result === 'HBP'){
                    HBPs ++;
                    //PA -= 1;
                    AB -= 1;
                }

                if(pitch.strikeCount === 2 && pitch.strike &&
                    (pitch.result === "Take" || pitch.result === "SWING AND MISS")){
                        K++
                    
                }

                ////console.log("that's me!");
                pitchCount ++;
                let pi = pitch.inning;
                let po = pitch.outs;
                let result = pitch.result;
                innings.push({
                    pi,
                    po,
                    result
                });
            }
        }

        if(pitchCount > 0){
            pSWG = pTotalSwings / parseFloat(pitchCount);
            SPercentage = SPercentageCount / parseFloat(pitchCount) * 100;
            SPercentage = SPercentage.toFixed(0) + "%";
            //# strikes (strikes plotted plus foul balls that are balls)/# P


            SMPercentage = totalMisses / parseFloat(pitchCount) * 100;//# swings and misses/#P
            SMPercentage = SMPercentage.toFixed(0) + "%";

            C = totalBallContacts / parseFloat(pitchCount) * 100;
            C = C.toFixed(0) + "%";

            GBPercentage = (GroundBalls) / parseFloat(pitchCount) * 100;
            GBPercentage = GBPercentage.toFixed(0) + '%';

            FBPercentage = PopFlys / parseFloat(pitchCount) * 100;
            FBPercentage = FBPercentage.toFixed(0) + '%';

            HHPercentage = hitHardCount / parseFloat(pitchCount) * 100;
            HHPercentage = HHPercentage.toFixed(0) + '%';


        }

        
        
        ////console.log('calculate pitcher stats');
        ////console.log(innings);

        for(let i=0; i < innings.length; ++i){
            for(let j=1; j<200; ++j){
                ////console.log(IP);
                if(innings[i].pi === j){
                    IP = (j-1);
                    IP = IP +"."+props.outs;
                }
                
               
                
                /*else if(innings[i].pi === j && innings[i].po === 3){
                    IP = (j) + ".0"
                }*/

            }
        }

        if(AB > 0){
            BAA = hitCount / parseFloat(AB); //(Battig average Against) number of hits/number AB's for the pitcher
            if(BAA < 1){
                BAA = "." + ((BAA % 1).toFixed(3) + "").split(".")[1]
            }else{
                BAA = BAA.toFixed(2) + "";
            }
        }

        

        if( parseFloat(IP) > 0){
            ////console.log('allowed runs ' + allowedRuns + ' IP ' + IP);
            ERA = (earnedRuns / parseFloat(IP)) * props.totalInnings; //(earnedruns / innings pitched) * totalinnings
        }
        else{
            ERA = 0.0;
        }
        ////console.log("IP = " + IP)
    }



    
    let className = classes.PitcherStats;
    let tableColumns = null;
    let tableResults = null;
    let colSpan = "8"

    
        calculatePitcherStats();
        
        colSpan = "10";
        tableColumns =  <>
                            
                            <td>P</td>
                            <td>S</td>
                            <td>S%</td>
                            
                            <td style={props.filterPitcherPitchType === 'FB' ? {backgroundColor:'white', color:'black'} : {}} onClick={()=>props.onFilterPitchType("FB")}>FB</td>
                            <td style={props.filterPitcherPitchType === 'XCHANGE' ? {backgroundColor:'white', color:'black'} : {}} onClick={()=>props.onFilterPitchType("XCHANGE")}>XCH</td>
                            <td style={props.filterPitcherPitchType === 'CURVE' ? {backgroundColor:'white', color:'black'} : {}} onClick={()=>props.onFilterPitchType("CURVE")}>CRV</td>
                            <td style={props.filterPitcherPitchType === 'DROP' ? {backgroundColor:'white', color:'black'} : {}} onClick={()=>props.onFilterPitchType("DROP")}>DRP</td>
                            <td style={props.filterPitcherPitchType === 'DROPCV' ? {backgroundColor:'white', color:'black'} : {}} onClick={()=>props.onFilterPitchType("DROPCV")}>DPC</td>
                            <td style={props.filterPitcherPitchType === 'RISE' ? {backgroundColor:'white', color:'black'} : {}} onClick={()=>props.onFilterPitchType("RISE")}>RIS</td>
                            <td style={props.filterPitcherPitchType === 'SCREW' ? {backgroundColor:'white', color:'black'} : {}} onClick={()=>props.onFilterPitchType("SCREW")}>SCR</td>
                        </>


                      
                       
                       
                       
                       


         tableResults = <>
        
         <td>{pitchCount}</td>
         <td>{strikeCount}</td>
         <td>{ pitchCount > 0 ? (strikeCount / parseFloat(pitchCount) * 100).toFixed(0) : 0}%</td>
         <td  onClick={()=>props.onFilterPitchType("FB")}>{FBPercent}%</td>
        <td onClick={()=>props.onFilterPitchType("XCHANGE")}>{XCHPercent}%</td>
        <td  onClick={()=>props.onFilterPitchType("CURVE")}>{CRVPercent}%</td>
        <td onClick={()=>props.onFilterPitchType("DROP")}>{DRPPercent}%</td> 
        <td onClick={()=>props.onFilterPitchType("DROPCV")}>{DPCPercent}%</td> 
        <td onClick={()=>props.onFilterPitchType("RISE")}>{RISPercent}%</td> 
        <td onClick={()=>props.onFilterPitchType("SCREW")}>{SCRPercent}%</td> 
         </>

        
    


    let playerLink = null;

    let pitcherNoteJSX = null; 
    let batterNoteJSX = null;

    if(props.showPitcherNotesHandler !== null){
        pitcherNoteJSX = <img onClick={props.showPitcherNotesHandler} style={{width: '10px', display: 'inline', marginLeft: '10px'}} src={PencilImg} alt="pitcher note" />
    }
    if(props.showBatterNotesHandler !== null){
    
        batterNoteJSX = <img onClick={props.showBatterNotesHandler} style={{width: '10px', display: 'inline', marginLeft: '10px'}} src={PencilImg} alt="batter note" />
    }

    if(props.player){
        if(props.playerType === 'pitcher'){
            playerLink =   
                <>
                <span onClick={props.onClick} className={classes.PlayerLink}>"P" #{props.player.number} - {props.player.firstName} {props.player.lastName}</span>
                {pitcherNoteJSX}
                </>         
        }
        else{
            playerLink =   <>
                            <span onClick={props.onClick} className={classes.PlayerLink}>"B" 
                            #{props.player.number} - {props.player.firstName} {props.player.lastName}</span>
                            {batterNoteJSX}
                        </>
        }
    }

    return(
        <div className={className}>
            <table>
                
                <tbody>
                    <tr style={{backgroundColor: '#404040'}}>
                        <td colSpan={colSpan}>
                            {playerLink}
                        </td>
                    </tr>
                    <tr className={classes.Columns} style={{backgroundColor: '#404040'}}>
                    {tableColumns}
                    </tr>
                    <tr>
                    {tableResults}
                    </tr>


                </tbody>
            </table>
            
        </div>
    )
}


const mapStateToProps = state =>{
    return{
        game: state.atBat.game,
        /*pitchArray: state.atBat.pitchArray,*/
        totalInnings: state.atBat.totalInnings,
        homeTeamPlayers: state.atBat.homeTeamPlayers,
        awayTeamPlayers: state.atBat.awayTeamPlayers,
        outs: state.atBat.outs,

    }
}

export default connect(mapStateToProps) (PlayerStats);