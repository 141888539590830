import React from 'react';
//import styles from './BaseRunners.module.css';
import BaseRunner from './BaseRunner/BaseRunner';




const BaseRunners = (props) => {
    const renderRunners = props.runners.map((p,i) => {
        return(
            <BaseRunner key={i} runners={[{...p}]} 
                handleScore={()=>props.handleScore(p)}
                handleOut={()=>props.handleOut(p)}
                handleLeftOnBase={()=>props.handleLeftOnBase(p)}
            />
        )
    });

    let heading = null;
    if(props.runners && props.runners.length > 0){
        heading = <h3>{props.label}</h3>
    }
    return(
        <div>
            {heading}
            {renderRunners}
        </div>
    )
}


export default BaseRunners;