import React from 'react';
import {connect} from 'react-redux';
import classes from './InningScoreTable.module.css';


const InningScoreTable = (props) =>{

    




    let headerCols = [];
    let currentInningTotal = Math.floor((props.inningScores.length/ 2.0) + 1);
    let currentInningsLength = currentInningTotal > props.totalInnings ? currentInningTotal : props.totalInnings;
    headerCols.push(<td key={-1}>Teams</td>);
    for(let i = 0; i < currentInningsLength; ++i){
        if(props.inning === i+1){
            headerCols.push(<td key={i} className={classes.Selected}>{i+1}</td>)
        }
        else{
            headerCols.push(<td key={i}>{i+1}</td>)
        }
    }
   headerCols.push(<>
                <td key={"Runs" + currentInningsLength}>R</td>
                <td key={"Hits" + currentInningsLength}>H</td>
                <td key={"Errors" + currentInningsLength}>E</td>
                </>)

   function isPlayerHome(p){
        let player = props.homeTeamPlayers.find(x => x.teamId === p.teamId);
        if(typeof player !== "undefined"){
            return true;
        }
        return false;
    }

    let totalHomeErrors = 0;
    let totalAwayErrors = 0;
    let totalHomeHits = 0;
    let totalAwayHits = 0;

    function calculateErrors(){
        for(let i=0;i<props.pitchArray.length; ++i){
            const pitch = props.pitchArray[i];
            //if the result is ROE, the pitching team gets an error
            if(pitch.result === "ROE" || pitch.pitchResult === "DRP3-PB/WP"){
                if(isPlayerHome(pitch.pitcher)){
                    totalHomeErrors +=1;
                }
                else{
                    totalAwayErrors += 1;
                }
            }
        }
    }
    function isHit(pitch){
        if(pitch.result === "1B" || 
        pitch.result === "2B" || 
        pitch.result === "3B" || 
        pitch.result === "4B" || 
        pitch.result === "HR" || 
        pitch.result === "IPHR"){
            return true;
        }
        return false;
    }
    function calculateHits(){
        for(let i=0;i<props.pitchArray.length; ++i){
            const pitch = props.pitchArray[i];

            if(isHit(pitch)){
                if(!isPlayerHome(pitch.pitcher)){
                    totalHomeHits +=1;
                }
                else{
                    totalAwayHits += 1;
                }
            }
        }
    }

    calculateErrors();
    calculateHits();

   let homeTeamCol = props.topBottom === 'BOTTOM'? <td key="hometeamcol" className={classes.Selected}>{props.homeTeam.name.substring(0,5).toUpperCase()}</td> : <td>{props.homeTeam.name.substring(0,5).toUpperCase()}</td>
   let awayTeamCol = props.topBottom === 'TOP' ? <td key="awayteamcol" className={classes.Selected}>{props.awayTeam.name.substring(0,5).toUpperCase()}</td> : <td>{props.awayTeam.name.substring(0,5).toUpperCase()}</td>


   //let homeTeamBatPitch = props.topBottom === 'BOTTOM' ? <td></td> : <td></td>
   //let awayTeamBatPitch = props.topBottom === 'TOP' ? <td></td> : <td></td>

   let homeScores = [];
   let totalHomeScores = 0;
   for(let i=1; i < props.inningScores.length; i+=2){
       homeScores.push(<td key={'homescores='+i-1}>{props.inningScores[i]}</td>);
       totalHomeScores ++;
   }
   if(props.topBottom === 'BOTTOM'){
        homeScores.push(<td key={'BOTTOM-'+props.inningScores.length}>{props.currentBottomInningScore}</td>);
        totalHomeScores ++;
   }
   for(let i=totalHomeScores; i< currentInningsLength; ++i){
       homeScores.push(<td key={"addBlank" + (i+1)}></td>);
   }
   
   homeScores.push(<td key={"homescores-"+currentInningsLength+1}>{props.homeScore}</td>);
   homeScores.push(<td key={"hits-"+currentInningsLength+1}>{totalHomeHits}</td>);
   homeScores.push(<td key={"errors-"+currentInningsLength+1 }>{totalHomeErrors}</td>)

   let awayScores = [];
   let totalAwayScores = 0;
   for(let i=0;i<props.inningScores.length; i+=2){
       awayScores.push(<td key={"awayscores-"+i}>{props.inningScores[i]}</td>);
       totalAwayScores ++;
   }
   if(props.topBottom === 'TOP'){
        awayScores.push(<td key={"top-"+props.inningScores.length}>{props.currentTopInningScore}</td>);
        totalAwayScores ++;
   }
   for (let i=totalAwayScores; i<currentInningsLength; ++i){
       awayScores.push(<td key={"_awayscores-"+i} ></td>);
   }
   awayScores.push(<td key={"runs-"+currentInningsLength+1}>{props.awayScore}</td>);
   awayScores.push(<td key={"hits-"+currentInningsLength+1}>{totalAwayHits}</td>);
   awayScores.push(<td key={"errors-"+currentInningsLength+1}>{totalAwayErrors}</td>);



    return(
        <div className={classes.InningScoreTable}>
            <table>
                <thead>
                    <tr style={{backgroundColor: '#404040'}}>
                        
                       {headerCols}
                       
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {awayTeamCol}
                        {awayScores}
                        
                    </tr>
                    <tr>
                        {homeTeamCol}
                        {homeScores}
                       
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

const mapStateToProps = state =>{
    return{
        game: state.atBat.game,
        homeTeam: state.atBat.homeTeam,
        awayTeam: state.atBat.awayTeam,
        currentInning: state.atBat.currentInning,
        homeScore: state.atBat.homeScore,
        awayScore: state.atBat.awayScore,
        totalInnings: state.atBat.totalInnings,
        inningScores: state.atBat.inningScores,
        currentTopInningScore: state.atBat.currentTopInningScore,
        currentBottomInningScore: state.atBat.currentBottomInningScore,
        homeTeamPlayers: state.atBat.homeTeamPlayers,
        awayTeamPlayers: state.atBat.awayTeamPlayers,
        pitchArray: state.atBat.pitchArray,
    }
}

export default connect(mapStateToProps) (InningScoreTable);