import React, {Component} from 'react';
import {connect} from 'react-redux';
import classes from './PlayerScoutingReportForm.module.css';
import * as ActionTypes from '../../../store/actions/index';
import Button from '../../../components/UI/Button/Button';

class PlayerScoutingReportForm extends Component{

    state = {
        note: ''
    }

    componentDidMount(){
        // this.setState({
        //     note: this.props.gameNotes
        // })
        if(this.props.player){
            this.setState({
                note: typeof this.props.player.notes === 'undefined' ? '' : this.props.player.notes
            });
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.player !== this.props.player){
            this.setState({
                note: typeof this.props.player.notes === 'undefined' ? '' : this.props.player.notes
            });
            //this.props.onSaveTrainingState(this.props.token, this.props.atBatState);
        }
    }

    handleNoteChange = event =>{
        let newNote = event.target.value;
        this.setState({ 
            note : newNote
        });

    }
    handleNoteUpdate = () =>{
        //console.log('handle note update ', this.state.note);
        //this.props.onSetGameNotes(this.state.note);
        this.props.onSubmit(this.state.note);
    }


    render(){

        let playerMessage = null;
        if(this.props.player){
            playerMessage = <h3>Scouting Report for {this.props.player.firstName} {this.props.player.lastName}</h3>
        }
        return (
            <div className={classes.TrainingNote}>
            {playerMessage}
            <textarea className={classes.TextArea} value={this.state.note !== null ? this.state.note : ''} 
                                    onChange={this.handleNoteChange}>
            </textarea>
            <br/>
            <Button btnType="Danger" clicked={this.handleNoteUpdate}>Save</Button>
            </div>
        )
    }
}


const mapStateToProps = state =>{
    return{
        gameNotes: state.atBat.gameNotes,
        atBatState: state.atBat,
        token: state.auth.token,
    }
}
const mapDispatchToProps = dispatch =>{
    return{
        onSetGameNotes: (data)=>dispatch(
            ActionTypes.setGameNotes(data)
        ),
        onSaveTrainingState: (token, data)=>dispatch(
            ActionTypes.saveTrainingState(token, data)
        ),
    }
}
export default connect(mapStateToProps, mapDispatchToProps) (PlayerScoutingReportForm);