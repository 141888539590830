import * as actionTypes from './actionTypes';
import axios from '../../axios';
import axiosmap from 'axios';


export const getUserStart = () =>{
    return{
        type:actionTypes.GET_USER_START
    }
}

export const getUserSuccess = (user) =>{
    return{
        type:actionTypes.GET_USER_SUCCESS,
        user: user
    }
}

export const getUserFail = (error) =>{
    return{
        type:actionTypes.GET_USER_FAIL,
        error:error
    }
}

export const getUserByUserID = (token, userid) =>{
    ////console.log('getTeam ' + id);
    return dispatch =>{
        dispatch(getUserStart());
        //axios.get('/users/'+id +'.json?auth=' + token)
        const queryParams = '?auth=' + token + '&orderBy="userId"&equalTo="'+userid+'"';
        axios.get('/users.json' + queryParams)
        .then(response => {
            ////console.log('response from getTeam:');
            ////console.log(response.data);
            let users = [];
            if(response.data !== null){
                users.push({
                    ...response.data//,
                    //id: id
                });
                dispatch(getUserSuccess(users));
            }
            else{
                dispatch(getUserFail('no results found for userId ' + userid));
            }
        })
        .catch(error =>{
            dispatch(getUserFail());
        });
    }
}

export const getUser = (token, id) =>{
    ////console.log('getTeam ' + id);
    return dispatch =>{
        dispatch(getUserStart());
        axios.get('/users/'+id +'.json?auth=' + token)
        .then(response => {
            ////console.log('response from getTeam:');
            ////console.log(response.data);
            let users = [];
            if(response.data !== null){
                users.push({
                    ...response.data,
                    id: id
                });
                dispatch(getUserSuccess(users));
            }
            else{
                dispatch(getUserFail('no results found for id ' + id));
            }
        })
        .catch(error =>{
            dispatch(getUserFail());
        });
    }
}



export const createUserStart = () =>{
    return{
        type:actionTypes.CREATE_USER_START
    };
}

export const createUserSuccess = (id, data) =>{
    return{
        type:actionTypes.CREATE_USER_SUCCESS,
        id: id,
        user: data
    };
}

export const createUserFail = (error) =>{
    return{
        type:actionTypes.CREATE_USER_FAIL,
        error: error
    };
}


export const createUser = (token, data) =>{
    return dispatch =>{
        dispatch(createUserStart());
        axios.post('users.json?auth=' + token, data)
            .then(response =>{
                ////console.log("id="+response.data.name);
                dispatch(createUserSuccess(response.data.name, data));
            })
            .catch(error =>{
                ////console.log(error.response);
                dispatch(createUserFail(error.response.data.error));
            });
    }
}



////

export const getUsersStart = () =>{
    return{
        type:actionTypes.GET_USERS_START
    };
}

export const getUsersSuccess = (data) =>{
    return{
        type:actionTypes.GET_USERS_SUCCESS,
        data: data
    };
}

export const getUsersFail = (error) =>{
    return{
        type:actionTypes.GET_USERS_FAIL,
        error: error
    };
}



export const getAllUsers = (token) =>{
    return dispatch =>{
        dispatch(getUsersStart());

        const queryParams = '?auth=' + token;
        //axios.get('/Users.json?auth=' + token)
        axios.get('/users.json' + queryParams)
        .then(response => {
            //console.log('getUsers action:', response.data);
            let users = [];
            for(let key in response.data){
                users.push({
                    ...response.data[key],
                    id: key,
                    org:null
                });
            }
            var promises = []
            for(let i=0;i<users.length; ++i){
                promises.push(axios.get('/organizations/'+users[i].organizationId +'.json?auth=' + token));
            }
            //console.log('promises ', promises);
            axiosmap.all(promises).then(function(results) {
                let i=0;
                results.forEach(function(response) {
                    //console.log('promises response: ', response)
                    let org = null;
                    if(response.data != null){
                        org = {...response.data
                            }
                        org.teamType = typeof org.teamType === 'undefined' ? 'Softball' : org.teamType;
                        let user = {...users[i], org: org, orgName: org.name}
                        users.splice(i,1,user);
                    }
                    i++;
                })
                let updatedUsers = [...users];
                dispatch(getUsersSuccess(updatedUsers));
            });

            
        })
        .catch(error =>{
            //console.log(error);
            dispatch(getUsersFail());
        });
    }
}

export const getOrgUsers = (token, orgId) =>{
    return dispatch =>{
        dispatch(getUsersStart());
        //console.log('getOrgUsers');
        //const queryParams = '?auth=' + token;
        //axios.get('/Users.json?auth=' + token)
        const queryParams = '?auth=' + token + '&orderBy="organizationId"&equalTo="'+orgId+'"';
        axios.get('/users.json' + queryParams)
        .then(response => {
            //console.log('getUsers action:', response.data);
            let users = [];
            for(let key in response.data){
                users.push({
                    ...response.data[key],
                    id: key,
                    org:null
                });
            }
            var promises = []
            for(let i=0;i<users.length; ++i){
                promises.push(axios.get('/organizations/'+users[i].organizationId +'.json?auth=' + token));
            }
            //console.log('promises ', promises);
            axiosmap.all(promises).then(function(results) {
                let i=0;
                results.forEach(function(response) {
                    //console.log('promises response: ', response)
                    let org = null;
                    if(response.data != null){
                        org = {...response.data
                            }
                        let user = {...users[i], org: org, orgName: org.name}
                        users.splice(i,1,user);
                    }
                    i++;
                })
                let updatedUsers = [...users];
                dispatch(getUsersSuccess(updatedUsers));
            });

            
        })
        .catch(error =>{
            //console.log(error);
            dispatch(getUsersFail());
        });
    }
}




/////


export const updateUserStart = (id) =>{
    return{
        type:actionTypes.UPDATE_USER_START,
        id: id
    };
}

export const updateUserSuccess = (id, data) =>{
    return{
        type:actionTypes.UPDATE_USER_SUCCESS,
        id: id,
        data: data
    };
}

export const updateUserFail = (error) =>{
    return{
        type:actionTypes.UPDATE_USER_FAIL,
        error: error
    };
}
//updateUser(token, data, orgName, createOrg)
export const updateUser = (token, data, orgName, createOrg) =>{
    return dispatch =>{
        dispatch(updateUserStart(data.id));
        const id = data.id;
        //delete data['id'];
        if(createOrg){
            let orgData = {
                name: orgName
            }
            axios.post('organizations.json?auth=' + token, orgData)
            .then(response =>{
                //console.log("id="+response.data.name);
                data.organizationId = response.data.name;
                axios.patch('users/'+id+'.json?auth=' + token, data)
                .then(response =>{
                    ////console.log("id="+response.data.name);
                    dispatch(updateUserSuccess(id, data));
                })
                .catch(error =>{
                    ////console.log(error.response);
                    dispatch(updateUserFail(error.response.data.error));
                });
            })
            .catch(error =>{
                //console.log(error.response);
            });
        }
        else{
            //console.log('updateUser', data);
            axios.patch('users/'+id+'.json?auth=' + token, data)
                .then(response =>{
                    //console.log("updateUser success", data);
                    dispatch(updateUserSuccess(id, data));
                })
                .catch(error =>{
                    //console.log(error);
                    dispatch(updateUserFail(error));
                });
        }
    }
}