import axios from 'axios';

//PRODUCTION
const instance = axios.create({
    baseURL: process.env.REACT_APP_API
});


//DEVELOPMENT
// const instance = axios.create({
//     baseURL: 'https://softball-demo.firebaseio.com/'
// });


export default instance;
