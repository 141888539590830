import React from 'react';
import styles from './HitActionButtons.module.css';
import HitActionButton from './HitActionButton/HitActionButton';

const HitActionButtons = (props) => {
    const options = [
        'Line',
        'Ground',
        'Fly',
        'Pop'
    ];

    const buttons = options.map((o,i) => {
        
        return (
            <HitActionButton key={i} type={o} click={props.click(o)}
           
            />
        )
    });

    return (
    <div className={styles.HitActionButtons}>
    {buttons}
    </div>
    )
}

export default HitActionButtons;