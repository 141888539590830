import React, {Component} from 'react';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import Spinner from '../../../components/UI/Spinner/Spinner';
import classes from './PlayerForm.module.css';
import { connect } from 'react-redux';

class PlayerForm extends Component{
    state = {
        controls: {
            firstName: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'First Name',
                    label: 'First Name'
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
            lastName: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Last Name'
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
            number: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Number'
                },
                value: '',
                validation: {
                    required: true,
                    isNumeric: true,
                    minLength: 1,
                    maxLength: 2
                },
                valid: false,
                touched: false
            },
            gradYear: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Graduation Year'
                },
                value: '',
                validation: {
                    required: false,
                    
                },
                valid: true,
                touched: false
            },
            battingSide:{
                
                elementType: 'select',
                elementConfig: {
                    label: 'Batting Side',
                    options : [
                        {value: 'R', displayValue: 'Bats R'},
                        {value: 'L', displayValue: 'Bats L'},
                        {value: 'SL', displayValue: 'Bats SL'},
                        {value: 'SW', displayValue: 'Bats SW'},
                    ]
                    
                },
                value:'Right',
                validation: {},
                valid:true,
                disabled:true
            },
            speed:{
                
                elementType: 'select',
                elementConfig: {
                    label: 'Speed',
                    options : [
                        {value: 'FAST', displayValue: 'Speed Fast'},
                        {value: 'ABAVG', displayValue: 'Speed Above Average'},
                        {value: 'AVG', displayValue: 'Speed Average'},
                        {value: 'BELAVG', displayValue: 'Speed Below Average'},
                    ]
                    
                },
                value:'FAST',
                validation: {},
                valid:true,
                disabled:true
            },
            
        },
        formIsValid: false,
        id: null
    }

    componentDidUpdate(prevProps){
        if(this.props.player && prevProps.player !== this.props.player){
            ////console.log(this.props.player.firstName + ' ' 
            //+ this.props.player.lastName + ' '
            //+ this.props.player.number + ' '
           // + this.props.player.id);

           //console.log('PlayerForm update', this.props.player);

            const updatedForm = {
                ...this.state.controls
            }
            const updatedFormFirstName = {...updatedForm['firstName']};
            updatedFormFirstName.value = this.props.player.firstName;
            updatedFormFirstName.valid = this.checkValidity(updatedFormFirstName.value, updatedFormFirstName.validation);
        
            
            const updatedFormLastName = {...updatedForm['lastName']};
            updatedFormLastName.value = this.props.player.lastName;
            updatedFormLastName.valid = this.checkValidity(updatedFormLastName.value, updatedFormLastName.validation);
        
            const updatedFormNumber = {...updatedForm['number']};
            updatedFormNumber.value = this.props.player.number;
            updatedFormNumber.valid = this.checkValidity(updatedFormNumber.value, updatedFormNumber.validation);

            let gradYear = typeof this.props.player.gradYear !== 'undefined' ? this.props.player.gradYear : '';

            const updatedFormGradYear = {...updatedForm['gradYear']};
            updatedFormGradYear.value = gradYear;
            updatedFormGradYear.valid = this.checkValidity(updatedFormGradYear.value, updatedFormGradYear.validation);

            let battingSide = typeof this.props.player.battingSide !== 'undefined' ? this.props.player.battingSide : '';
            if(battingSide !== ''){
                const updatedBattingSide = {...updatedForm['battingSide']};
                updatedBattingSide.value = battingSide;
                updatedForm['battingSide'] = updatedBattingSide;

            }

            let speed = typeof this.props.player.speed !== 'undefined' ? this.props.player.speed : '';

            if(speed !== ''){
                const updatedSpeed = {...updatedForm['speed']};
                updatedSpeed.value = speed;
                updatedForm['speed'] = updatedSpeed;
            }
        
            
            updatedForm['firstName'] = updatedFormFirstName;
            updatedForm['lastName'] = updatedFormLastName;
            updatedForm['number'] = updatedFormNumber;
            updatedForm['gradYear'] = updatedFormGradYear;
            
            let formIsValid = true;
            for(let inputIds in updatedForm){
                formIsValid = updatedForm[inputIds].valid && formIsValid;
            }
            this.setState({controls: updatedForm, formIsValid: formIsValid, id: this.props.player.id});
        }
    }

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }
        
        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        if (rules.isNumeric) {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }


    inputChangedHandler = (event, inputIdentifier) =>{
        const updatedForm = {
            ...this.state.controls
        }
        const updatedFormElement = {...updatedForm[inputIdentifier]};
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        ////console.log(updatedFormElement.valid, updatedFormElement.validation ? true : false);
        updatedForm[inputIdentifier] = updatedFormElement;

        let formIsValid = true;
        for(let inputIds in updatedForm){
            formIsValid = updatedForm[inputIds].valid && formIsValid;
        }
        this.setState({controls: updatedForm, formIsValid: formIsValid});
    }

    submitHandler = (event) =>{
        event.preventDefault();
        ////console.log('calling submitHandler in TeamForm ' 
        
        //+ this.state.controls.firstName.value+' '
        //+ this.state.controls.lastName.value+' '
        //+ this.state.controls.number.value+' '
        //+ this.props.team.id);


        const playerData = {
            firstName: this.state.controls.firstName.value,
            lastName: this.state.controls.lastName.value,
            number: this.state.controls.number.value,
            gradYear: this.state.controls.gradYear.value,
            battingSide: this.state.controls.battingSide.value,
            speed: this.state.controls.speed.value,
            teamId: this.props.team.id,
            id: this.state.id
        }
        ////console.log(playerData);
        this.props.submit(event, playerData);

        //this.props.onCreateTeam(this.props.token, teamData);
    }


    render(){

        const formElementsArray = [];
        for(let key in this.state.controls){
            formElementsArray.push({
                id: key,
                config: this.state.controls[key],
                validation: this.state.controls[key].validation,
                valid: this.state.controls[key].valid,
                touched: this.state.controls[key].touched
            });
        }

        let form = (
            <form onSubmit={(event)=>this.submitHandler(event)}>
                    {formElementsArray.map(el => (
                        <Input 
                            key={el.id}
                            elementType={el.config.elementType}
                            elementConfig={el.config.elementConfig}
                            value={el.config.value}
                            invalid = {!el.valid}
                            shouldValidate ={el.validation && el.touched}
                            changed={(event)=> this.inputChangedHandler(event, el.id)}/>
                    ))}
                        
                    
                    <Button disabled={!this.state.formIsValid} btnType="Success">SUBMIT</Button>
                </form>
        );

        if(this.props.loading){
            form = <Spinner />;
        }
        let message = this.props.playerMessage;
        if(this.props.error){
            message = <span className={classes.Error}>{this.props.message}</span>
        }
        else if(this.props.message !== null){
            message = <span className={classes.Success}>{this.props.message}</span>
        }


        return (
            <div className={classes.PlayerForm}>
                <h4>{this.props.player === null? "Add Player" : "Update Player"} {this.props.team ? this.props.team.name : null}</h4>
                {/*message*/}
                {form}
            </div>
        )
    }
}

const mapStateToProps = state =>{
    return{
        error: state.player.error,
        token: state.auth.token,
        teams: state.team.teams,
        players: state.player.players
    };
}

export default connect(mapStateToProps, null) (PlayerForm);
