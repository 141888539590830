import React, {Component} from 'react';
import { connect } from 'react-redux';
import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import Footer from '../../components/Navigation/Footer/Footer';
import styles from './Layout.module.css';

class Layout extends Component{
    state = {
        showSideDrawer : false
    }

    sidebarClosedHandler = () =>{
        ////console.log('sidebarClosedHandler');
        this.setState({
            showSideDrawer: false
        })
    }
    sidebarOpenHandler = () =>{
        ////console.log('sidebarOpenHandler');
        this.setState({
            showSideDrawer: true
        })
    }

    render(){
        let role = '';
        if(this.props.auser){
            role = this.props.auser.role;
        }
        return(
            <React.Fragment>
                <Toolbar isAuth={this.props.isAuth} role={role} openSideBar={this.sidebarOpenHandler}/>
                <SideDrawer isAuth={this.props.isAuth} role={role} closed={this.sidebarClosedHandler} open={this.state.showSideDrawer}/>
                
                <main className={styles.Content}>
                    {this.props.children}
                </main>

                <Footer />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state =>{
    return{
        isAuth: state.auth.token !== null,
        auser: state.auth.user
    };
}

export default connect(mapStateToProps, null) (Layout);