import React from 'react';
import styles from './FieldLocations.module.css';
import FieldLocation from './FieldLocation/FieldLocation';

const FieldLocations = (props) =>{

    let missOption = null;

    if(props.showMiss  === true){
        missOption = <FieldLocation 
        top={246}
        left={225}
        width={80}
        color={'red'}
        text={'MISS'}
        clicked={props.clicked}
    />
    }
    return (
        <div className={styles.FieldLocations}>
            <FieldLocation 
                top={209}
                left={128}
                text={'2'}
                clicked={props.clicked}
            />
            <FieldLocation 
                top={175}
                left={128}
                text={'1/2'}
                clicked={props.clicked}
            />
            <FieldLocation 
                top={142}
                left={128}
                text={'1'}
                clicked={props.clicked}
            />
            <FieldLocation 
                top={40}
                left={128}
                text={'8'}
                clicked={props.clicked}
            />
            <FieldLocation 
                top={7}
                left={128}
                text={'HR8'}
                clicked={props.clicked}
            />
            <FieldLocation 
                top={170}
                left={100}
                text={'2/5'}
                clicked={props.clicked}
            />
             <FieldLocation 
                top={90}
                left={97}
                text={'6'}
                clicked={props.clicked}
            />
             <FieldLocation 
                top={51}
                left={87}
                text={'7/8'}
                clicked={props.clicked}
            />
             <FieldLocation 
                top={150}
                left={80}
                text={'5'}
                clicked={props.clicked}
            />
             <FieldLocation 
                top={74}
                left={64}
                text={'7'}
                clicked={props.clicked}
            />
            <FieldLocation 
                top={116}
                left={78}
                text={'5/6'}
                clicked={props.clicked}
            />
            <FieldLocation 
                top={95}
                left={34}
                text={'LL7'}
                clicked={props.clicked}
            />
             <FieldLocation 
                top={63}
                left={10}
                text={'HR7'}
                clicked={props.clicked}
            />
            <FieldLocation 
                top={90}
                left={160}
                text={'4'}
                clicked={props.clicked}
            />
            <FieldLocation 
                 top={116}
                 left={183}
                text={'3/4'}
                clicked={props.clicked}
            />
             <FieldLocation 
                 top={150}
                 left={175}
                text={'3'}
                clicked={props.clicked}
            />
            <FieldLocation 
                top={95}
                left={221}
                text={'RL6'}
                clicked={props.clicked}
            />
            <FieldLocation 
                top={74}
                left={190}
                text={'9'}
                clicked={props.clicked}
            />
            <FieldLocation 
                top={51}
                left={169}
                text={'8/9'}
                clicked={props.clicked}
            />
            <FieldLocation 
                top={63}
                left={248}
                text={'HR9'}
                clicked={props.clicked}
            />
            <FieldLocation 
                top={186}
                left={-1}
                width={100}
                text={'FOUL LEFT'}
                clicked={props.clicked}
            />
            <FieldLocation 
                top={186}
                left={185}
                width={100}
                text={'FOUL RIGHT'}
                clicked={props.clicked}
            />
             <FieldLocation 
                top={246}
                left={90}
                width={100}
                text={'FOUL BACK'}
                clicked={props.clicked}
            />

            

            <FieldLocation 
                top={170}
                left={156}
                text={'2/3'}
                clicked={props.clicked}
            />
            {missOption}
        </div>
    )
}

export default FieldLocations;