import React from 'react'
import styles from './PitchPlotter.module.css';
import StrikeZone from '../../../components/PitchPlotter/StrikeZone/StrikeZone';





const PitchPlotter = (props) => {
    return(
        <>
            
            <div id="pitcharea" className={styles.PitchArea}>
                <StrikeZone width={props.width} height={props.height} showPitcherBatters={props.showPitcherBatters} />
                {props.children}
            </div>
            
        </>
    );
};

export default PitchPlotter;