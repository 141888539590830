import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';


const initialState = {
    trainingStart: false,
    loading: false,
    error: false,
    message: null,
    id: null,
    trainings: [],
    trainingStates: [],
    userID: null
};


const loadTrainingStatesStart = (state, action) =>{
    return updateObject(state, {loading: true, error:false, message:null});
}
const loadTrainingStatesFail = (state, action) =>{
    return updateObject(state, {loading: false, error:true, message:null});
}

const loadTrainingStatesSuccess = (state, action) =>{
    return updateObject(state,
        {
            trainingStates: action.data,
            loading: false,
            error: false,
            message: null
        } );
}

const loadTrainingStateSuccess = (state, action) =>{

    

    //const player = this.props.players.find(x=>x.id === this.props.currentPlayer.id);
    let trainingStateObj = {
        atBatID: action.data.atBatID,
        trainingID: action.data.trainingID,
        training: action.data.training,
        team: action.data.team,
        teamPlayers: action.data.teamPlayers,
        pitchNumber: action.data.pitchNumber,
        currentHitter: (typeof action.data.currentHitter === 'undefined' ||
                        action.data.currentHitter === null)? null : action.data.currentHitter,
        currentPitcher: (typeof action.data.currentPitcher === 'undefined' ||
                        action.data.currentPitcher === null)? null : action.data.currentPitcher,
        battingRight: action.data.battingRight,
        
        loading: false,
        error: action.data.error,
        message: action.data.message,
        pitchArray: action.data.pitchArray === undefined? [] : action.data.pitchArray,
        trainingOver: action.data.trainingOver,
        pitcherView: typeof action.data.pitcherView === 'undefined' ? true : action.data.pitcherView,
        
    }

    let trainingIndex = state.trainings.findIndex(x=>x.id === action.data.trainingID);
    let updatedTrainings = [...state.trainings];
    if(trainingIndex > -1){
        let updatedTraining = {...state.trainings[trainingIndex], trainingState: trainingStateObj}
        ////console.log('loadTrainingStateSuccess - updating Training', updatedTraining);
        updatedTrainings.splice(trainingIndex, 1, updatedTraining);

    }


    return updateObject(state,
        {
            trainings: updatedTrainings,
            loading: false,
            error: false,
            message: null
        } );
}

const loadTrainingStateStart = (state, action) =>{
    return updateObject(state, {loading: true, error:false, message:null});
}
const loadTrainingStateFail = (state, action) =>{
    return updateObject(state, {loading: false, error:true, message:null});
}

const trainingStart = (state, action) =>{
    return updateObject(state, {trainingStart: true});
}


const createTrainingStart = (state, action) =>{
    return updateObject(state, {loading: true, error:false, message:null});
}

const createTrainingSuccess = (state, action) =>{
    const newTraining = updateObject(action.data, {id: action.id});

    let newTrainingArray = [...state.trainings];
    newTrainingArray = newTrainingArray.concat(newTraining);
    return updateObject(state, {
        loading: false, 
        error:false, 
        message: actionTypes.CREATE_TRAINING_SUCCESS,
        trainings: newTrainingArray,
        id: action.id//state.trainings.concat(newTraining),
    });
}

const createTrainingFail = (state, action) =>{
    return updateObject(state, {loading: false, error:true, message:action.error});
}

//


const getTrainingsStart = (state, action) =>{
    return updateObject(state, {loading: true, error:false, message:null});
}

const getTrainingsSuccess = (state, action) =>{
    return updateObject(state, {
        loading: false, 
        error:false, 
        message: null,
        trainings: action.data,
    });
}
const getTrainingsFail = (state, action) =>{
    return updateObject(state, {loading: false, error:true, message:action.error});
}

//
const getTrainingStart = (state, action) =>{
    return updateObject(state, {loading: true, error:false, message:null});
}

const getTrainingSuccess = (state, action) =>{
    //////console.log('calling getTrainingSuccess ' + action.data);
    return updateObject(state, {
        loading: false, 
        error:false, 
        message: null,
        trainings: action.data,
    });
}
const getTrainingFail = (state, action) =>{
    return updateObject(state, {loading: false, error:true, message:action.error});
}

const deleteTrainingSuccess = (state, action) =>{
    let id = action.id;
    let newTrainingArray = [...state.trainings];

    let index = newTrainingArray.findIndex(training =>training.id === id);

    if(index >= 0){
        newTrainingArray.splice(index,1);
    }
    return updateObject(state, {trainings: newTrainingArray});
            

}


const reducer = (state = initialState, action) =>{

    switch(action.type){
        case actionTypes.TRAINING_START:{
            return trainingStart(state, action);
        }
        case actionTypes.CREATE_TRAINING_START:{
            return createTrainingStart(state, action);
        }
        case actionTypes.CREATE_TRAINING_FAIL:{
            return createTrainingFail(state, action);
        }
        case actionTypes.CREATE_TRAINING_SUCCESS:{
            return createTrainingSuccess(state, action);
        }
        case actionTypes.DELETE_TRAINING_SUCCESS:{
            return deleteTrainingSuccess(state, action);
        }

        case actionTypes.GET_TRAININGS_START:{
            return getTrainingsStart(state, action);
        }
        case actionTypes.GET_TRAININGS_FAIL:{
            return getTrainingsFail(state, action);
        }
        case actionTypes.GET_TRAININGS_SUCCESS:{
            return getTrainingsSuccess(state, action);
        }

        case actionTypes.GET_TRAINING_START:{
            return getTrainingStart(state, action);
        }
        case actionTypes.GET_TRAINING_FAIL:{
            return getTrainingFail(state, action);
        }
        case actionTypes.GET_TRAINING_SUCCESS:{
            return getTrainingSuccess(state, action);
        }
        case actionTypes.LOAD_TRAINING_TRAINING_STATE_START:{
            return loadTrainingStateStart(state,action)
        }
        case actionTypes.LOAD_TRAINING_TRAINING_STATE_FAIL:{
            return loadTrainingStateFail(state,action)
        }
        case actionTypes.LOAD_TRAINING_TRAINING_STATE_SUCCESS:{
            return loadTrainingStateSuccess(state,action)
        }
        case actionTypes.LOAD_TRAINING_STATES_START:{
            return loadTrainingStatesStart(state,action)
        }
        case actionTypes.LOAD_TRAINING_STATES_FAIL:{
            return loadTrainingStatesFail(state,action)
        }
        case actionTypes.LOAD_TRAINING_STATES_SUCCESS:{
            return loadTrainingStatesSuccess(state,action)
        }
        default:
        {
            return state;
        }
    }
}

export default reducer;
