import React, {Component} from 'react';
import classes from './PlayerPosition.module.css';
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import Spinner from '../../components/UI/Spinner/Spinner';
import {connect} from 'react-redux';


class PlayerPosition extends Component{
    state = {
        controls: {
            
            positions:{
                
                elementType: 'select',
                elementConfig: {
                    label: 'Position',
                    options : [
                        
                    ]
                    
                },
                value:'',
                validation: {},
                valid:true
            },
            
        },
        formIsValid: false,
        id: null,

    }

    componentDidMount(){

        const updatedForm = {
            ...this.state.controls
        }
        const updatedFormPositions = {...updatedForm['positions']};

        let availablePositions = ['P', 'C', '1B', '2B', '3B', 'SS', 'LF', 'CF', 'RF', 'EH1','EH2','EH3','EH4', 'DP', 'FLEX'];
        if(this.props.pitchType === 'Baseball'){
            availablePositions = ['P', 'C', '1B', '2B', '3B', 'SS', 'LF', 'CF', 'RF', 'EH1','EH2','EH3','EH4', 'DH'];
        }

        let updatedOptions = [];
        for(let i=0;i<availablePositions.length; ++i){
            let option = {
                value: availablePositions[i],
                displayValue: availablePositions[i]
            }
            updatedOptions.push(option);
        }

        updatedFormPositions.elementConfig.options = updatedOptions;
        updatedFormPositions.valid = true;

        updatedForm['positions'] = updatedFormPositions;

        let formIsValid = true;
        for(let inputIds in updatedForm){
            formIsValid = updatedForm[inputIds].valid && formIsValid;
        }
        this.setState({controls: updatedForm, formIsValid: formIsValid});
        
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.currentPlayer !== this.props.currentPlayer){
            ////console.log('PlayerSelector componentDidUpdate = ', this.props.players);
            const updatedForm = {
                ...this.state.controls
            }
            const updatedFormPositions = {...updatedForm['positions']};

    
            let updatedPositions = [];
            let availablePositions = ['P', 'C', '1B', '2B', '3B', 'SS', 'LF', 'CF', 'RF', 'EH1','EH2','EH3','EH4', 'DP', 'FLEX'];
            if(this.props.pitchType === 'Baseball'){
                availablePositions = ['P', 'C', '1B', '2B', '3B', 'SS', 'LF', 'CF', 'RF', 'EH1','EH2','EH3','EH4', 'DH'];
            }

            
            ////console.log('updatedOptions',updatedOptions);
            updatedFormPositions.elementConfig.options = availablePositions;
            updatedFormPositions.valid = true;

            updatedForm['positions'] = updatedFormPositions;

            let formIsValid = true;
            for(let inputIds in updatedForm){
                formIsValid = updatedForm[inputIds].valid && formIsValid;
            }
            this.setState({controls: updatedForm, formIsValid: formIsValid});
        }
    }

    checkValidity(value, rules) {
        let isValid = true;
        return isValid;
    }

    inputChangedHandler = (event, inputIdentifier, type) =>{
        ////console.log('GameForm->inputChangeHandler ' + type);
        const updatedForm = {
            ...this.state.controls
        }
        const updatedFormElement = {...updatedForm[inputIdentifier]};
        let formIsValid = true;
        if(type === 'datepicker'){
            updatedFormElement.value = new Date(event);
            updatedFormElement.valid = true;
            ////console.log(updatedFormElement.value);
        }
        else{
            updatedFormElement.value = event.target.value;
            updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        
        }
        updatedFormElement.touched = true;
        updatedForm[inputIdentifier] = updatedFormElement;

        ////console.log(updatedForm);
        for(let inputIds in updatedForm){
            formIsValid = updatedForm[inputIds].valid && formIsValid;
        }
        this.setState({controls: updatedForm, formIsValid: formIsValid});
    }

    submitHandler = (event) =>{
        event.preventDefault();
        ////console.log('calling submitHandler in PlayerSelector '+
        //this.state.controls.players.value
        //);
        this.props.onSubmit(this.state.controls.positions.value);
    }

    render(){
        
        const formElementsArray = [];
        for(let key in this.state.controls){
            formElementsArray.push({
                id: key,
                config: this.state.controls[key],
                validation: this.state.controls[key].validation,
                valid: this.state.controls[key].valid,
                touched: this.state.controls[key].touched
            });
        }

        let form = (
            <form onSubmit={(event)=>this.submitHandler(event)}>
                    {formElementsArray.map(el => (
                        <Input 
                            key={el.id}
                            elementType={el.config.elementType}
                            elementConfig={el.config.elementConfig}
                            label={el.config.elementConfig.label}
                            value={el.config.value}
                            invalid = {!el.valid}
                            shouldValidate ={el.validation && el.touched}
                            changed={(event)=> this.inputChangedHandler(event, el.id, el.config.elementType)}/>
                    ))}
                        
                    
                    <Button disabled={!this.state.formIsValid} btnType="Success" btnSize="Small">SUBMIT</Button>
                </form>
        );

        if(this.props.loading){
            form = <Spinner />;
        }
        let message = null;
        if(this.props.error){
            message = <span className={classes.Error}>{this.props.message}</span>
        }
        else if(this.props.message !== null){
            message = <span className={classes.Success}>{this.props.message}</span>
        }


        return (
            <div className={classes.PlayerPosition}>
                <h4>{this.props.label}</h4>
                {message}
                {form}
            </div>
        )
    }
}


const mapStateToProps = state =>{
    return{
        pitchType: state.atBat.pitchType
    }

}
const mapDispatchToProps = dispatch =>{
    return{
    }
}


export default connect(mapStateToProps, mapDispatchToProps) (PlayerPosition);

